import {all, call, put, fork, take, select} from 'redux-saga/effects';
import {startSubmit, stopSubmit, reset} from 'redux-form';
import {fetchPostJson, fetchGet, fetchPatch} from './utills/fetch';
import {SubmissionError} from 'redux-form';
import {
  requestCompany,
  successCompany,
  requestCompanyEdit,
  requestCompanyEn,
  requestCompanyInfoEn,
  requestInitializeComapny,
  requestEditCompanyInfo
} from '../actions/company';
import {setModalSetting} from '../actions/task';
import {requestLatestTaskList} from '../actions/taskList';
import {requestCoReviews} from '../actions/review';

import {
  requestPaymentState,
  requestCheckCreditAuthorization
} from '../actions/payment';
import {setMessageType} from '../actions/dialog';
import store from 'store';
import {requestAdditionalClaims} from '../actions/firebase';
import company from '../reducers/company';
import {formatErrorResponse, joinHyphen, makeFormErrorFormat} from '../common';
import {history} from '../store';
import {requestMyCompanyInfo} from '../actions/myCompany';

export function* getCompany() {
  while (true) {
    const action = yield take(requestCompany);
    const [data, error] = yield call(fetchGet, `companies/${action.payload}/`);
    if (data && !error) {
      yield put(successCompany(data));
      // 支払先の設定状況を確認
      yield put(requestPaymentState());
      // GMOの仮売上げ決済画面に遷移した履歴があった場合は、決済結果を確認するAPIを叩く。
      const isMovingSettlement = store.get('isMovingSettlement');
      if (isMovingSettlement && isMovingSettlement.status) {
        yield put(requestCheckCreditAuthorization(isMovingSettlement.taskId));
      }
    }
  }
}

export function* initCompany() {
  while (true) {
    yield take(requestInitializeComapny);
    // 支払先の設定状況を確認
    yield put(requestPaymentState());
    // GMOの仮売上げ決済画面に遷移した履歴があった場合は、決済結果を確認するAPIを叩く。
    const isMovingSettlement = store.get('isMovingSettlement');
    if (isMovingSettlement && isMovingSettlement.status) {
      yield put(requestCheckCreditAuthorization(isMovingSettlement.taskId));
    }
  }
}

export function* patchCompany() {
  while (true) {
    const action = yield take(requestCompanyEdit);
    const {values} = action.payload;
    const payload = {
      url1: values.url1,
      introduce: values.introduce,
      twitter: values.twitter,
      facebook: values.facebook,
      note: values.note,
      qiita: values.qiita,
      img: values.img
    };
    yield put(startSubmit('company'));
    const [data, error] = yield call(
      fetchPatch,
      `companies/${action.payload.companyId}`,
      payload
    );
    if (data && !error) {
      yield put(requestMyCompanyInfo());
      yield put(
        setModalSetting({type: 'SUCCESS_COMPANY_EDIT', isVisible: true})
      );
    } else if (error) {
      yield put(stopSubmit('company', makeFormErrorFormat(error)));
    }
  }
}

export function* getCompanyEn() {
  while (true) {
    const action = yield take(requestCompanyEn);
    const [data, error] = yield call(fetchGet, `companies/${action.payload}/`);
    if (data && !error) {
      yield put(successCompany(data));
    }
  }
}

export function* getCompanyInfoEn() {
  while (true) {
    const action = yield take(requestCompanyInfoEn);
    yield put(requestCompanyEn(action.payload));
    // ↓会社の直近タスクとレビューを取得
    yield put(requestLatestTaskList(action.payload));
    yield put(requestCoReviews({id: action.payload}));
  }
}

export function* editCompanyDetail() {
  while (true) {
    const action = yield take(requestEditCompanyInfo);
    const companyId = yield select(state => state.setting.companyId);

    const {tel1, tel2, tel3, zipCode1, zipCode2} = action.payload;
    const payload = {
      ...action.payload,
      tel: joinHyphen([tel1, tel2, tel3]),
      zip_code: joinHyphen([zipCode1, zipCode2])
    };
    const [data, error] = yield call(
      fetchPostJson,
      `company-info-edit`,
      payload
    );

    if (data && !error) {
      yield put(requestMyCompanyInfo());
      history.push('/co/info');
    } else if (error) {
      yield put(stopSubmit('paidApplication', makeFormErrorFormat(error)));
    }
  }
}

const Company = [
  fork(getCompany),
  fork(patchCompany),
  fork(getCompanyEn),
  fork(getCompanyInfoEn),
  fork(initCompany),
  fork(editCompanyDetail)
];

export default Company;
