// 企業が 最終確認するためのモーダル
import React from 'react';
import PropTypes from 'prop-types';
import style from './complete.module.scss';
import faker from 'faker';
import {useDispatch, useSelector} from 'react-redux';
import ReviewForm from '../Form/ReviewForm';
import format from 'date-fns/format';
import {
  createMuiTheme,
  makeStyles,
  MuiThemeProvider
} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Avatar from '@material-ui/core/Avatar';
import {patchcomplete, setConfirmModalFlag} from '../../actions/complete';
import Button from '../Buttons/CompleteButton/Button';

const useStyles = makeStyles(theme => ({
  paperWidthSm: {
    width: '60rem'
  },
  bigAvatar: {
    width: '5rem',
    height: '5rem'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: '5rem',
    width: '100%'
  }
}));

const theme = createMuiTheme({
  overrides: {
    MuiDialog: {
      paperWidthSm: {
        width: '60rem'
      }
    },
    MuiButton: {
      outlinedPrimary: {
        color: '#093791',
        border: '0.1rem solid #093791',
        width: '30rem',
        height: '5rem',
        fontSize: '1.6rem',
        fontWeight: 'bold',
        marginBottom: '1rem'
      }
    },
    MuiOutlinedInput: {
      inputMultiline: {
        fontSize: '1.6rem',
        lineHeight: '3.2rem',
        color: '#999999'
      }
    }
  }
});

const OnHandleSubmit = {
  modalOff: dispatch => dispatch(setConfirmModalFlag(false)),
  patchComplete: (dispatch, taskId) =>
    dispatch(patchcomplete({task: taskId, status: 7}))
};

const Index = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const task = useSelector(state => state.task.detail);
  const modalFlag = useSelector(state => state.complete.completeModalFlag);
  const taskId = task.id;
  const taskStatus = task.status;

  return (
    <div>
      <MuiThemeProvider theme={theme}>
        <Dialog
          aria-labelledby="simple-dialog-title"
          open={modalFlag}
          scroll={'body'}
          onBackdropClick={() => OnHandleSubmit.modalOff(dispatch)}
        >
          <div className={style.dialog}>
            {taskStatus === 5 ? (
              <>
                <div className={style.mainText}>
                  <p>
                    成果物の確認を完了します <br />
                    本当によろしいですか?
                  </p>
                </div>

                <div className={style.divider} />
                <Button
                  kind={'pink'}
                  onClickHandler={() =>
                    OnHandleSubmit.patchComplete(dispatch, taskId)
                  }
                />
                <Button
                  kind={'gray'}
                  onClickHandler={() => OnHandleSubmit.modalOff(dispatch)}
                />
              </>
            ) : (
              <>
                <div className={style.mainText}>
                  <p>決済が正常に完了しました</p>
                  <p>エンジニアの評価をしてください</p>
                </div>
                <Button
                  kind={'gray'}
                  onClickHandler={() => OnHandleSubmit.modalOff(dispatch)}
                />
              </>
            )}
          </div>
        </Dialog>
      </MuiThemeProvider>
    </div>
  );
};

export default Index;
