export const makeSubCategoryList = (categoryList, formValues) => {
  if (formValues === undefined || categoryList.length === 0) {
    return [];
  }

  const preSubCategoryList = categoryList.filter(
    item => item.main_category_id == formValues.main_category_id
  );
  if (preSubCategoryList.length > 0) {
    return preSubCategoryList[0].sub_categories;
  }
  return [];
};