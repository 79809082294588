import React from 'react';
import HelpIcon from '../../../assets/help_blue.png';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import style from '../helpButton.module.scss';
import AllowIndentionArea from '../../AllowIndentionArea';
import {useStyles} from '../styles';
import BaseHelpField from '../BaseHelpField';

const HelpButton = ({text, placement}) => {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const classes = useStyles();
  return (
    <BaseHelpField
      text={text}
      placement={placement}
      handleTooltipClose={handleTooltipClose}
      open={open}
    >
      <IconButton
        onClick={handleTooltipOpen}
        classes={{
          root: classes.iconButton
        }}
      >
        <img src={HelpIcon} alt={'クエスチョン'} />
      </IconButton>
    </BaseHelpField>
  );
};

export default HelpButton;
