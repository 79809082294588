import React from 'react';
import FullScreenMessage from '../../../components/FullScreenMessage';

const PasswordResetResult = () => {
  return (
    <FullScreenMessage title={'メール送信完了'}>
      ご登録いただいているメールアドレスに
      <br />
      パスワード再設定用メールを送らせていただきました。
      <br />
      メールに記載されているURLにアクセスし
      <br />
      パスワードの再設定を行ってください。
      <br />
    </FullScreenMessage>
  );
};

export default PasswordResetResult;
