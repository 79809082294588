import {
  all,
  call,
  put,
  takeEvery,
  take,
  fork,
  select
} from 'redux-saga/effects';
import {startSubmit, stopSubmit, reset} from 'redux-form';
import {fetchPatch, fetchGet} from './utills/fetch';
import {
  requestNotificationList,
  successNotificationList,
  requestImportantNotifications,
  successImportantNotifications,
  requestDeleteImportantNotification,
  successDeleteImportantNotification
} from '../actions/notification';
import store from 'store';
import clonedeep from 'lodash/cloneDeep';
import {history} from '../store';
import {setPreSaveTask} from '../actions/task';

export function* getNotificationList() {
  while (true) {
    yield take(requestNotificationList);
    const [data, error] = yield call(fetchGet, `tasks/`);
    yield put(successNotificationList(data));
  }
}

// TOPページ画面上部に表示されるお知らせを作成するやつ
export function* getNotifications() {
  while (true) {
    yield take(requestImportantNotifications);
    const {importantNotifications} = yield select(state => state.notification);
    const {preSaveTask, failedTasks} = yield select(state => state.task);

    if (preSaveTask) {
      const newNotifications = clonedeep(importantNotifications);
      const notice = {
        notice: '作成中のタスクがあります',
        type: 'processingTask',
        onClick: () => {
          history.push({
            pathname: `/co/project/${preSaveTask.projectId}/task/create`,
            state: preSaveTask
          });
          store.remove('preSaveTask');
        }
      };
      yield put(setPreSaveTask(null));
      newNotifications.push(notice);
      yield put(successImportantNotifications(newNotifications));
    } else {
      yield put(requestDeleteImportantNotification('processingTask'));
    }
  }
}

export function* delImportantNotification() {
  // type を指定してあげれば、指定した通知を消すことができる
  while (true) {
    const action = yield take(requestDeleteImportantNotification);
    const {importantNotifications} = yield select(state => state.notification);
    let newNotifications = [];
    newNotifications = importantNotifications.filter(
      item => item.type !== action.payload
    );
    yield put(successDeleteImportantNotification(newNotifications));
  }
}

const Notification = [
  fork(getNotificationList),
  fork(getNotifications),
  fork(delImportantNotification)
];

export default Notification;
