import React from 'react';
import {useHistory} from 'react-router-dom';
import classNames from 'classnames';
import styles from '../taskList.module.scss';
import Label from '../../../Label';
import Text from '../../../Text';
import TaskInfo from '../../TaskInfo';
import Deadline from '../../../Deadline';
import formatDate from '../../../FormatDate/index';
import TaskListTheme from '../../../TaskListTheme';
import {makeTagsText, userType} from '../../../../common';
import dayjs from 'dayjs';
import {getIsExpired} from '../../../../common/funcs';

// material ui
import ListItem from '@material-ui/core/ListItem';
import TaskItemDetail from '../../TaskItemDetail';
import Reward from '../../Reward';

export const RecruitmentTask = props => {
  const {
    item,
    index,
    isEngineer,
    isAnonymousUser = false,
    onClick = null,
    isListItem = true
  } = props;
  const history = useHistory();

  const {
    id,
    status_info,
    status,
    updated_at,
    title,
    project_title,
    tags_info,
    delivery_date,
    closing_date,
    reward,
    entries,
    company,
    project
  } = item;
  let tagsText = tags_info.length > 0 ? makeTagsText(tags_info) : null;

  // エントリーしたけど、そのままアサインされずにTaskの応募締め切りがすぎた場合trueになる
  const isExpired = getIsExpired(closing_date);

  const handleOnclick = () => {
    if (onClick) {
      onClick(id);
    } else if (isAnonymousUser) {
      history.push(`/public/project/${project}/task/${id}`);
    } else {
      history.push(`/${userType(isEngineer)}/project/${project}/task/${id}`);
    }
  };

  return (
    <TaskListTheme key={index} index={index} isListItem={isListItem}>
      <ListItem disabled={false} button={true} onClick={handleOnclick}>
        <div className={classNames(styles.container)}>
          <div className={styles.title}>
            <div className={styles.flex}>
              <div className={styles.tag}>
                {isExpired ? (
                  <Label text={'応募終了'} isActive={false} />
                ) : (
                  <Label text={status_info} status={status} isActive={true} />
                )}
              </div>
              <Text
                children={`更新日 ${formatDate(
                  new Date(updated_at),
                  'date',
                  'str'
                )}`}
                component="text01"
              />
            </div>
            <Text className={styles.body} children={title} component="text02" />
            <div className={styles.companyName}>
              <Text
                children={company ? company : 'loading'}
                component="text01"
              />
            </div>
          </div>

          <div className={styles.taskItemDetail}>
            <TaskItemDetail project_title={project_title} tagsText={tagsText} />
            <Reward reward={reward} />
            {isExpired ? (
              <>
                <div className={styles.deadlineContainer}>
                  <div className={styles.expired}>応募終了</div>
                </div>
              </>
            ) : (
              <>
                <div className={styles.deadlineContainer}>
                  <div className={styles.deadline}>応募締切</div>
                  <Deadline deadline={closing_date} />
                  <div className={styles.apply}>
                    <div>
                      応募者数
                      <span className={styles.num}>
                        {entries !== null ? entries : '-'}
                      </span>
                      人
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </ListItem>
    </TaskListTheme>
  );
};
