import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {reduxForm, getFormSubmitErrors} from 'redux-form'
import PasswordResetMail from "../../../../components/Form/PasswordResetMail"
import PanelLayout from '../../../layout/PanelLayout'
import {requestPasswordResetMail} from '../../../../actions/reset'

class Index extends Component {

  render() {
    return (
      <PanelLayout>
        <PasswordResetMail {...this.props} />
      </PanelLayout>
    )
  }
}


const mapDispatchToProps = dispatch => ({
  onSubmit(value) {
    dispatch(requestPasswordResetMail(value));
  }
});

Index = reduxForm({
  enableReinitialize: true,
  form: 'passwordResetMail'
})(Index);

const mapStateToProps = (state) => {
  return {
    submitErrors: getFormSubmitErrors('passwordResetMail')( state )
  };
};

const option = { areMergePropsEqual: () => false };
export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  option
)(Index));
