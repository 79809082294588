import React from 'react';
import style from './advanceNotice.module.scss';
import infoIcon from '../../assets/icon_caution.svg';
import Link from '@material-ui/core/Link';
import classNames from 'classnames';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    color: '#ea397d'
  }
}));

const AdvanceNotice = ({contents}) => {
  const {title, text, url = null, linkText = null} = contents;
  const classes = useStyles();
  return (
    <div className={style.container}>
      <div className={style.iconContainer}>
        <img src={infoIcon} className={style.imgPosition} />
        <div className={style.iconMessage}>caution!</div>
      </div>
      <div className={style.contents}>
        {title && (
          <div>
            <p className={style.title}>{title}</p>
            <div className={style.divider} />
          </div>
        )}
        <p className={style.text}>{text}</p>
      </div>
      {url && linkText && (
        <div>
          <Link
            className={classNames(style.link)}
            href={url}
            TypographyClasses={classes}
          >
            {linkText}
          </Link>
        </div>
      )}
    </div>
  );
};

export default AdvanceNotice;
