import {createAction} from 'redux-actions';

// 自分が応募しているタスクの一覧を取得
export const REQUEST_MY_ENTRY_LIST = 'REQUEST_MY_ENTRY_LIST';
export const SUCCESS_MY_ENTRY_LIST = 'SUCCESS_MY_ENTRY_LIST';
export const requestMyEntryList = createAction(REQUEST_MY_ENTRY_LIST);
export const successMyEntryList = createAction(SUCCESS_MY_ENTRY_LIST);

// タスクに応募してきたエンジニア一覧を取得
export const REQUEST_ENTRY_LIST = 'REQUEST_ENTRY_LIST';
export const SUCCESS_ENTRY_LIST = 'SUCCESS_ENTRY_LIST';
export const requestEntryList = createAction(REQUEST_ENTRY_LIST);
export const successEntryList = createAction(SUCCESS_ENTRY_LIST);

// 応募する
export const REQUEST_POST_ENTRY = 'REQUEST_POST_ENTRY';
export const requestPostEntry = createAction(REQUEST_POST_ENTRY);

// 応募取り消し
export const REQUEST_DELETE_ENTRY = 'REQUEST_DELETE_ENTRY';
export const requestDeleteEntry = createAction(REQUEST_DELETE_ENTRY);

// アサインのPOSTが成功した際に、モーダルのフラグを変更する
export const SET_OPEN_FLAG = 'SET_OPEN_FLAG';
export const setOpenFlag = createAction(SET_OPEN_FLAG);
