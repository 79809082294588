import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Detail from '../co/Task/Detail';
import {requestResetTask, requestTaskDetail} from '../../actions/task';
import {useParams} from 'react-router-dom';
import useScrollToTop from '../../hooks/useScrollToTop';
import NotAvailable from '../../components/NotAvailable';

const TaskDetail = () => {
  const dispatch = useDispatch();
  const {id, projectId} = useParams();
  const {detail} = useSelector(state => state.task);

  useScrollToTop();
  useEffect(() => {
    dispatch(requestTaskDetail({id: id}));
    return () => dispatch(requestResetTask());
  }, []);

  // タスク詳細が読み込まれていることを保証したい
  if (detail.id) {
    return (
      <>
        <Detail taskId={id} />
      </>
    );
  } else {
    return <NotAvailable kind="workingTasks" />;
  }
};

export default TaskDetail;
