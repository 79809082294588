import React, {FC, useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styles from '../item.module.scss';
import commonStyles from '../../../../common/common.module.scss';
import formatDate from '../../../FormatDate';
import CommonButton from '../../../CommonButton';
import Blank from '../../../Blank';
import {RootState} from '../../../../models/RootModels';
import {SystemDocumentState} from '../../../../models/SystemNewsModels';
import {requestSystemNewsList} from '../../../../actions/systemNews';

const SystemNotificationContent: FC = () => {
  const dispatch = useDispatch();
  const {
    headSystemDocuments,
    systemDocuments,
    hasMoreSystemDocuments
  } = useSelector((state: RootState) => state.systemNews);

  const handleMoreFetch = () => {
    dispatch(requestSystemNewsList());
  };

  return (
    <>
      <div className={styles.background}>
        {headSystemDocuments.length > 0 ? (
          headSystemDocuments.map((item, index) => {
            return <RowContent item={item} key={index.toString()} />;
          })
        ) : (
          <div className={styles.nothing}>通知はありません。</div>
        )}
        {systemDocuments.length > 0 &&
          systemDocuments.map((item, index) => {
            return <RowContent item={item} key={index.toString()} />;
          })}
      </div>
      <Blank height={'2.4rem'} width={null} />
      <div className={commonStyles.buttonContainer}>
        {/*@ts-ignore*/}
        <CommonButton
          text={hasMoreSystemDocuments ? 'もっと見る' : 'これ以上ありません'}
          size={'medium'}
          color={'blue'}
          variant={'contained'}
          shape={'normal'}
          onClick={handleMoreFetch}
          disabled={!hasMoreSystemDocuments}
        />
      </div>
    </>
  );
};

type RowContentProps = {
  item: SystemDocumentState;
};

const RowContent: FC<RowContentProps> = ({item}) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>{item.title}</div>
      <div className={styles.text}>{item.message}</div>
      {item.created_at && (
        <div className={styles.date}>
          {formatDate(item.created_at.seconds * 1000, 'minute')}
        </div>
      )}
    </div>
  );
};

export default SystemNotificationContent;
