import { take, call, put, fork } from 'redux-saga/effects'

import { requestValuation, successValuation } from '../actions/valuation'

export function* getValuation() {
  while (true) {
    yield take(requestValuation);
    yield put(successValuation());
  }
}

const Valuation = [
  fork(getValuation)
];

export default Valuation
