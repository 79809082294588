import {
  SUCCESS_BOOKMARK_LIST,
  SET_BOOKMARK_ID,
  REQUEST_BOOKMARK_LIST
} from '../actions/bookmark';

const initialState = {
  bookmarkList: ['default'],
  deleteTarget: null
};

const bookmark = (state = initialState, action) => {
  switch (action.type) {
    case SUCCESS_BOOKMARK_LIST:
      return {
        ...state,
        bookmarkList: action.payload
      };
    default:
      return state;
  }
};

export default bookmark;
