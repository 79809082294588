import {createAction} from 'redux-actions';

export const REQUEST_PAYMENT_LIST = 'REQUEST_PAYMENT_LIST';
export const SUCCESS_PAYMENT_LIST = 'SUCCESS_PAYMENT_LIST';
export const requestPaymentList = createAction(REQUEST_PAYMENT_LIST);
export const successPaymentList = createAction(SUCCESS_PAYMENT_LIST);

// カード情報の登録・編集の為のURLを発行する
export const REQUEST_CREDIT_EDIT_URL = 'REQUEST_CREDIT_EDIT_URL';
export const SUCCESS_CREDIT_EDIT_URL = 'SUCCESS_CREDIT_EDIT_URL';
export const requestCreditEditUrl = createAction(REQUEST_CREDIT_EDIT_URL);
export const successCreditEditUrl = createAction(SUCCESS_CREDIT_EDIT_URL);

// カード限度額確保の為のURLを発行する
export const REQUEST_SECURE_CREDIT_LIMIT_URL =
  'REQUEST_SECURE_CREDIT_LIMIT_URL';
export const SUCCESS_SECURE_CREDIT_LIMIT_URL =
  'SUCCESS_SECURE_CREDIT_LIMIT_URL';
export const requestSecureCreditLimitUrl = createAction(
  REQUEST_SECURE_CREDIT_LIMIT_URL
);
export const successSecureCreditLimitUrl = createAction(
  SUCCESS_SECURE_CREDIT_LIMIT_URL
);

// 支払先の設定ができているか確認する
export const REQUEST_PAYMENT_STATE = 'REQUEST_PAYMENT_STATE';
export const SUCCESS_PAYMENT_STATE = 'SUCCESS_PAYMENT_STATE';
export const requestPaymentState = createAction(REQUEST_PAYMENT_STATE);
export const successPaymentState = createAction(SUCCESS_PAYMENT_STATE);

// GMOの企業の新規登録を行う
export const REQUEST_SAVE_AS_GMO_MEMBER = 'REQUEST_SAVE_AS_GMO_MEMBER';
export const requestSaveAsGmoMember = createAction(REQUEST_SAVE_AS_GMO_MEMBER);

// クレカの仮売上げに成功しているか確認する（"仮売上げ" は "オーソリ" のこと）
export const REQUEST_CHECK_CREDIT_AUTHORIZATION =
  'REQUEST_CHECK_CREDIT_AUTHORIZATION';
export const requestCheckCreditAuthorization = createAction(
  REQUEST_CHECK_CREDIT_AUTHORIZATION
);
export const SUCCESS_CHECK_CREDIT_AUTHORIZATION =
  'SUCCESS_CHECK_CREDIT_AUTHORIZATION';
export const successCheckCreditAuthorization = createAction(
  SUCCESS_CHECK_CREDIT_AUTHORIZATION
);

// Paid の利用申請を行う
export const REQUEST_PAID_APPLICATION = 'REQUEST_PAID_APPLICATION';
export const requestPaidApplication = createAction(REQUEST_PAID_APPLICATION);

// Paid 利用申請画面で使用するモーダル用
export const SET_PAID_MODAL_FLAG = 'SET_PAID_MODAL_FLAG';
export const setPaidModalFlag = createAction(SET_PAID_MODAL_FLAG);

// 元々の支払い方法はクレカだけど、Paid で決済したい時に使用
export const REQUEST_PAYMENT_USING_PAID = 'REQUEST_PAYMENT_USING_PAID';
export const requestPaymentUsingPaid = createAction(REQUEST_PAYMENT_USING_PAID);
export const SUCCESS_PAYMENT_USING_PAID = 'SUCCESS_PAYMENT_USING_PAID';
export const successPaymentUsingPaid = createAction(SUCCESS_PAYMENT_USING_PAID);

// 元々の支払い方法はPaidだけど、クレカ で決済したい時に使用
export const REQUEST_PAYMENT_USING_CREDIT = 'REQUEST_PAYMENT_USING_CREDIT';
export const requestPaymentUsingCredit = createAction(
  REQUEST_PAYMENT_USING_CREDIT
);
export const SUCCESS_PAYMENT_USING_CREDIT = 'SUCCESS_PAYMENT_USING_CREDIT';
export const successPaymentUsingCredit = createAction(
  SUCCESS_PAYMENT_USING_CREDIT
);

export const REQUEST_PAID_AUTHORI = 'REQUEST_PAID_AUTHORI';
export const requestPaidAuthori = createAction(REQUEST_PAID_AUTHORI);
