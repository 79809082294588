import React from 'react';

import styles from './messageField.module.scss';
import MoreFetchButton from '../../Buttons/MoreFetchButton';
import PrivateComment from '../../PrivateComment';
import MessageReplyForm from '../../Form/MessageReplyForm';
import NotAvailable from '../../NotAvailable';

const MessageField = ({
  requestMessageList,
  messages,
  message,
  setMessageText,
  requestPostMessage,
  user,
  isEngineer,
  me,
  defaultState
}) => {
  if (message.toId === null) {
    return <NotAvailable kind="NO_SELECT_TALKROOM" />;
  }

  return (
    <>
      {messages.length >= 5 && (
        <MoreFetchButton onClickHandler={requestMessageList} />
      )}
      <div className={styles.messageLists}>
        {messages
          .slice()
          .reverse()
          .map((message, index) => (
            <PrivateComment
              comment={message}
              user={isEngineer ? user.coUsers : user.enUsers}
              me={me}
              key={index.toString()}
              index={index}
            />
          ))}
      </div>

      <div className={styles.formContainer}>
        <MessageReplyForm
          initialValues={{content: defaultState && defaultState.content}}
          placeholder={'返信内容を入力してください'}
          message={message}
          onSubmit={values => {
            requestPostMessage({
              content: values.content,
              selectedRoomId: message.selectedRoomId,
              toId: message.toId
            });
          }}
        />
      </div>
    </>
  );
};

export default MessageField;
