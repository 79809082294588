import React from 'react';
import styles from '../../commonModal.module.scss';
import IconSearch from '../../../../assets/icon_task_search_blue.svg';
import {requestTaskEdit, setModalSetting} from '../../../../actions/task';

import CommonTaskButton from '../../../Buttons/CommonTaskButton';
import {TASK_STATUS} from '../../../../common';
import {useDispatch, useSelector} from 'react-redux';

const PaySuccessInfo = ({paymentMethod}) => {
  const dispatch = useDispatch();
  const {type} = useSelector(state => state.task.modalSetting);

  return (
    <div className={styles.inner}>
      <div className={styles.header}>
        <img className={styles.image} src={IconSearch} />
        <div className={styles.subTitle}>決済に成功しました。</div>
      </div>
      <div className={styles.message}>
        <p>{`${paymentMethod}払いを使用した仮売上げが成功しました。`}</p>
        <p>しばらく経ちましたら画面の再読み込みをお願いいたします。</p>
      </div>
      <div className={styles.button}>
        <CommonTaskButton
          text="確認"
          onClickHandler={() => {
            dispatch(setModalSetting({type: type, isVisible: false}));
          }}
          kind={'pink'}
        />
      </div>
    </div>
  );
};

export default PaySuccessInfo;
