export const PAYMENT_STATUS_CREDIT = {
  notMember: 0,
  existsCard: 1,
  notExistsCard: 2
};

export const PAYMENT_STATUS_PAID = {
  notMember: 0,
  underReview: 1,
  usable: 2,
  unusable: 3, // 審査は通ったものの、その後使用できなくなった場合
  reviewNg: 4 // 審査自体がNGで利用できない場合
};

// dark: Hover用
// カラバリが増えたら、都度数値を変更して追加して下さい
export const COLOR_PALLET = {
  pink1000: '#EA397D',
  pinkDark: '#b12a5e',
  blue1000: '#5a75b7',
  blue900: '#093791',
  blueDark: '#3c3f78',
  blueLight: '#00a8f0',
  orange1000: '#F48400',
  orangeDark: '#904900',
  green1000: '#77C000',
  white: '#FFFFFF',
  hoverBlue: 'rgb(6, 38, 101)',
  red: '#D10040'
};

export const PAYMENT_METHODS = {
  credit: 0,
  paid: 1
};

export const MENUES = {
  dashboard: 1,
  project: 2,
  search: 3,
  company: 4,
  member: 5,
  email: 6,
  job: 7,
  rewardAdmin: 8,
  paymentMethod: 9,
  offerAdmin: 10
};

// アサイン依頼時のメッセージに使用する
export const MESSAGE_TEMPLATE = `エントリー頂きましてありがとう御座います。\n下記のタスクの内容について、問題がなければこのまま業務を依頼したいと考えておりますが、質問等は御座いませんか？\n\nTASK_URL`;

// 〜日前 という表示から 日付表示に切り替わる日数
export const DEFAULT_SWITCH_DATE_TYPE_LIMIT = 7;

// 今後権限が増える事を想定して、この書式
export const PERMISSIONS = {
  superUser: 0
};

export const mediaQuery = {
  min: '(min-width: 601px)',
  max: '(max-width: 600px)'
};

// 営業資料設置URL
export const DOC_URL = 'download/docs_Task_co.pdf';

export const TAX = 10;
export const MAX_SYSTEM_FEE_RATIO = 10;

// クレカの仮売上げ期間
export const CREDIT_AUTHORI_DURATION = 60;
