import {
  SUCCESS_ROOM_LIST,
  SET_ROOM_CURRENT_PAGE,
  SET_SELECTED_ROOM_INFO,
  SUCCESS_MESSAGE_LIST,
  SET_MESSAGE_CURRENT_PAGE,
  SET_MESSAGE_TEXT,
  SET_OTHER_PERSON_ID,
  RESET_MESSAGES,
  SUCCESS_CHECK_ROOM_STATUS
} from '../actions/message';

const initialState = {
  rooms: [],
  hasMore: true,
  roomsCurrent: null,
  //↓一回でもSUCCESS_ROOM_LISTされているかの判断
  isFetched: false,
  selectedRoomId: '',
  selectedRoomTitle: '',

  messages: [],
  messagesCurrent: null,

  messageText: '',
  toId: null,
  isRoomExists: null
};

function message(state = initialState, action) {
  switch (action.type) {
    case SUCCESS_ROOM_LIST:
      if (action.payload.useRefresh) {
        return {
          ...state,
          rooms: action.payload.roomList,
          hasMore: action.payload.hasMore,
          isFetched: true
        };
      } else {
        return {
          ...state,
          rooms: [...state.rooms, ...action.payload.roomList],
          hasMore: action.payload.hasMore,
          isFetched: true
        };
      }

    case SET_ROOM_CURRENT_PAGE:
      return {
        ...state,
        roomsCurrent: action.payload
      };
    case SET_SELECTED_ROOM_INFO:
      return {
        ...state,
        selectedRoomId: action.payload.roomId,
        selectedRoomTitle: action.payload.title
      };
    case SUCCESS_MESSAGE_LIST:
      const newMessages = state.messages.concat(action.payload);
      return {
        ...state,
        messages: newMessages
      };
    case SET_MESSAGE_CURRENT_PAGE:
      return {
        ...state,
        messagesCurrent: action.payload
      };
    case SET_MESSAGE_TEXT:
      return {
        ...state,
        messageText: action.payload
      };
    case SET_OTHER_PERSON_ID:
      return {
        ...state,
        toId: action.payload
      };
    case RESET_MESSAGES:
      return {
        ...state,
        messages: [],
        messagesCurrent: null,
        messageText: ''
      };
    case SUCCESS_CHECK_ROOM_STATUS:
      return {
        ...state,
        isRoomExists: action.payload
      };
    default:
      return state;
  }
}

export default message;
