import React from 'react';
import {useSelector} from 'react-redux';
import {SCHEME, API_HOST} from '../../config';
import {MESSAGE_TEMPLATE} from '../../constants';

import styles from './requestModal.module.scss';
import PAPER_PLANE from '../../assets/paper_plane.svg';
import classNames from 'classnames';

import MoveToMessageButton from '../../components/MoveToMessageButton';
import store from 'store';
import Box from '@material-ui/core/Box';

const Send = ({onClick, nickName, userId}) => {
  let {id, project} = useSelector(state => state.task.detail);

  const readPreAssignTasks =
    store.get('readPreAssignTasks') === undefined
      ? []
      : store.get('readPreAssignTasks');

  const TASK_URL = `${SCHEME}://${API_HOST}/en/project/${project}/task/${id}`;
  const CONTENTS = MESSAGE_TEMPLATE.replace('TASK_URL', TASK_URL);
  const params = {title: 'アサインの依頼', content: CONTENTS};

  return (
    <>
      <div className={styles.paperPlane}>
        <img src={PAPER_PLANE} />
      </div>
      <p className={styles.textLarge}>
        アサイン依頼
        <br />
        メールの送信が完了しました
      </p>
      <p className={styles.textSmall}>
        エンジニアの{' '}
        <Box fontWeight="fontWeightBold" m={0} component={'span'}>
          {nickName}
        </Box>
        さんへ
        <br />
        アサイン依頼のメールを送りました。
        <br />
        メッセージでエンジニアと契約内容についての最終確認をお願いします。
      </p>
      <div className={classNames(styles.fullWidth, styles.marginTop)}>
        <MoveToMessageButton
          userId={userId}
          params={params}
          onClickSideEffect={() => {
            readPreAssignTasks.push(id);
            store.set('readPreAssignTasks', readPreAssignTasks);
          }}
        />
      </div>
    </>
  );
};

export default Send;
