import React from 'react';
import {useLocation} from 'react-router-dom';

import MenuAppBar from '../MenuAppBar';
import PageSubTitle from '../PageSubTitle';
import Breadcrumbs from '../Breadcrumbs';
import TableItem from './TableItem';
import FooterPage from '../Footer/FooterPage';
import CommonLayout from '../../containers/layout/CommonLayout';

import styles from './sctl.module.scss';

const SCTL = () => {
  const location = useLocation();
  return (
    <CommonLayout>
      <MenuAppBar />
      <div className={styles.pageTitleContainer}>
        <PageSubTitle title="特定商取引法に基づく表示" />
      </div>
      <main className={styles.main}>
        <div className={styles.inner}>
          <table className={styles.tableContainer}>
            <tbody>
              <TableItem header="販売者業者" data={['株式会社m-Lab']} />
              <TableItem header="代表者" data={['村上進也']} />
              <TableItem
                header="サイト"
                data={['Task (タスク)', 'https://www.task.site']}
              />
              <TableItem
                header="所在地"
                data={['〒104-0061', '東京都中央区銀座1-16-7銀座大栄ビル5階']}
              />
              <TableItem header="商品の名称" data={['サービス利用料課金']} />
              <TableItem
                header="販売価格"
                // TODO: 料金ページができたらリンクにする
                data={['別途ページにて記載しています。']}
              />
              <TableItem
                header="連絡先"
                data={['info@task.site / 03-4360-8752']}
              />
              <TableItem
                header="支払い方法と支払い期限"
                data={[
                  'クレジットカード（Visa、MasterCard、JCB、AMEX、Diners）・請求書払い(Paid)',
                  '請求書払いは利用審査があります。',
                  '支払い期限につきましては取引によって異なります。'
                ]}
              />
              <TableItem header="引渡し時期" data={['即時']} />
              <TableItem
                header="返品・交換について"
                data={['確定後の取引は原則として返品・変更は不可能です。']}
              />
            </tbody>
          </table>
        </div>
      </main>
      <FooterPage />
    </CommonLayout>
  );
};

export default SCTL;
