import {IS_ERROR, SET_ERROR_CONTENTS} from '../actions/errorHandler';

const initialState = {
  errorContents: null
};

//ErrorBoundaryに遷移しない、side effect用のエラーハンドリング
function errorHandler(state = initialState, action) {
  switch (action.type) {
    case SET_ERROR_CONTENTS:
      return {
        ...state,
        errorContents: action.payload
      };
    default:
      return state;
  }
}

export default errorHandler;
