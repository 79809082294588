import {
  SUCCESS_CO_USER_INFO,
  SUCCESS_CO_USERS_INFO,
  SUCCESS_EN_USER_INFO,
  SUCCESS_EN_USERS_INFO,
  SUCCESS_SEARCH_ENGINEERS
} from '../actions/user';

const initialState = {
  coUser: {default: null},
  coUsers: {default: null},
  enUser: {default: null},
  enUsers: {default: null},
  searchedEngineersResponse: {
    count: null,
    next: null,
    previous: null,
    results: []
  }
};

function user(state = initialState, action) {
  switch (action.type) {
    case SUCCESS_CO_USER_INFO:
      return {
        ...state,
        coUser: action.payload
      };
    case SUCCESS_CO_USERS_INFO:
      return {
        ...state,
        coUsers: action.payload.usersInfoList
      };
    case SUCCESS_EN_USER_INFO:
      state.enUsers[`${action.payload.userInfo.id}`] = action.payload.userInfo;
      return {
        ...state,
        enUser: action.payload.userInfo
      };
    case SUCCESS_EN_USERS_INFO:
      return {
        ...state,
        enUsers: action.payload.usersInfoList
      };
    case SUCCESS_SEARCH_ENGINEERS:
      return {
        ...state,
        searchedEngineersResponse: action.payload
      };
    default:
      return state;
  }
}

export default user;
