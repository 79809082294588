import {
  SUCCESS_ENTRY_LIST,
  SUCCESS_MY_ENTRY_LIST,
  SET_OPEN_FLAG
} from '../actions/entry';

const initialState = {
  entryList: [],
  deleteTarget: null,
  entryUserList: [],
  openFlag: false,
  count: null,
  next: null,
  previous: null
};

const entry = (state = initialState, action) => {
  switch (action.type) {
    case SUCCESS_MY_ENTRY_LIST:
      return {
        ...state,
        entryList: action.payload
      };
    case SUCCESS_ENTRY_LIST:
      return {
        ...state,
        entryUserList: action.payload.results,
        count: action.payload.count,
        next: action.payload.next,
        previous: action.previous
      };
    case SET_OPEN_FLAG:
      return {
        ...state,
        openFlag: action.payload
      };
    default:
      return state;
  }
};

export default entry;
