// TS への 移行のため、src/common/index.js の内容をここに移していく為に作成
import {changeDate} from './index';

export const getIsExpired = (closing_date: string): boolean => {
  return Date.now() > Date.parse(closing_date);
};

export const isDeadlineComingSoon = (closing_date: string): boolean => {
  const closingDate = new Date(closing_date);
  const ThreeDaysAgo = changeDate(closingDate, 3, 'past');
  const now = new Date();
  if (ThreeDaysAgo) {
    return now > ThreeDaysAgo && now < closingDate;
  }
  return false;
};

export const getPathFromUrl = (original_url: string) => {
  try {
    const url = new URL(original_url);
    return url.pathname;
  } catch (e) {
    return '';
  }
};
