import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {NavLink, useHistory} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';

//components
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import Menu from '@material-ui/core/Menu';
import Badge from '@material-ui/core/Badge';
import Drawer from '@material-ui/core/Drawer';
import MobileCoMenu from '../../containers/layout/MobileCoMenu';
import MobileEnMenu from '../../containers/layout/MobileEnMenu';

//img
import Logo from '../../assets/logo.svg';
import IconBell from '../../assets/icon_header_bell.svg';
import IconSignOut from '../../assets/icon_log_out.svg';
import IconMenuSetting from '../../assets/icon_menu_setting.svg';
import {AvatarImg, userType} from '../../common';

// css
import style from './menuAppBar.module.scss';
import {requestSignout} from '../../actions/sign';
import MediaQuery from 'react-responsive';
import DehazeIcon from '@material-ui/icons/Dehaze';
import {mediaQuery} from '../../constants';
import CommonButton from '../CommonButton';

const useStyles = makeStyles(theme => ({
  root: {
    padding: 'unset'
  },
  menu: {
    boxShadow: '5px 5px 10px rgba(9, 55, 145, 0.2)'
  },
  svgIcon: {
    color: '#093791',
    width: '3rem',
    height: '3rem'
  },
  enDrawerPaper: {
    backgroundColor: '#093791',
    '&:hover': {
      backgroundColor: ' #093791'
    }
  },
  coDrawerPaper: {
    backgroundColor: '#00A8F0',
    '&:hover': {
      backgroundColor: '#00A8F0'
    }
  }
}));

const MenuAppBar = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [auth, setAuth] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [drawer, toggleDrawer] = useState({
    left: false
  });
  const open = Boolean(anchorEl);

  const userImg = useSelector(state => state.setting.me.img);
  const nickName = useSelector(state => state.setting.me.nick_name);
  const id = useSelector(state => state.setting.me.id);
  const {isEngineer, isAnonymousUser} = useSelector(state => state.setting);

  const {isReadAllPersonalNotification, headPersonalDocument} = useSelector(
    state => state.personalNews
  );
  const {isReadAllSystemNotification, headSystemDocuments} = useSelector(
    state => state.systemNews
  );

  const isBadgeOn =
    (!isReadAllPersonalNotification && headPersonalDocument.length > 0) ||
    (!isReadAllSystemNotification && headSystemDocuments.length > 0);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <div className={style.root}>
      <AppBar position="fixed" className={style.appbar}>
        <Toolbar className={classes.root}>
          <div
            className={
              isAnonymousUser
                ? style.logoContainerForAnonymous
                : style.logoContainer
            }
          >
            <NavLink
              exact
              to={makeOnClickLogoPath(isAnonymousUser, isEngineer)}
              className={style.logo}
            >
              <div className={style.betaLogoContainer}>
                <img src={Logo} />
                <div className={style.betaLogo}>BETA</div>
              </div>
            </NavLink>
          </div>
          {!isAnonymousUser ? (
            <div className={style.menu}>
              <div className={style.sidemenu}>
                <MediaQuery query={mediaQuery.max}>
                  <IconButton
                    color="inherit"
                    aria-label="Menu"
                    onClick={() => toggleDrawer({left: true})}
                  >
                    <DehazeIcon classes={{root: classes.svgIcon}} />
                  </IconButton>
                  <Drawer
                    open={drawer.left}
                    onClose={() => toggleDrawer({left: false})}
                    classes={
                      isEngineer
                        ? {paper: classes.enDrawerPaper}
                        : {paper: classes.coDrawerPaper}
                    }
                  >
                    <div
                      tabIndex={0}
                      role="button"
                      onClick={() => toggleDrawer({left: false})}
                      onKeyDown={() => toggleDrawer({left: false})}
                    >
                      {isEngineer ? <MobileEnMenu /> : <MobileCoMenu />}
                    </div>
                  </Drawer>
                </MediaQuery>
              </div>
              <IconButton
                aria-owns={open ? 'menu-appbar' : undefined}
                aria-haspopup="true"
                color="inherit"
                onClick={() => handleClose()}
                className={style.iconButton}
              >
                {isBadgeOn ? (
                  <Badge color="secondary" variant="dot" invisible={false}>
                    <NavLink exact to={`/${userType(isEngineer)}/news`}>
                      <img src={IconBell} className={style.IconBell} />
                    </NavLink>
                  </Badge>
                ) : (
                  <NavLink exact to={`/${userType(isEngineer)}/news`}>
                    <img src={IconBell} className={style.IconBell} />
                  </NavLink>
                )}
              </IconButton>
              <IconButton
                aria-owns={open ? 'menu-appbar' : undefined}
                aria-haspopup="true"
                onClick={e => handleMenu(e)}
                color="inherit"
                className={style.rightIconButton}
              >
                <AvatarImg
                  img={userImg}
                  size="minimum"
                  className={style.IconAvatar}
                />
              </IconButton>
              <Menu
                classes={{paper: classes.menu}}
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
                open={open}
                onClose={() => handleClose()}
              >
                <MenuItem>
                  <NavLink
                    exact
                    to={isEngineer ? `/en/en-user/${id}` : `/co/co-user/${id}`}
                    onClick={() => handleClose()}
                    className={style.maxWidth}
                  >
                    <p className={style.contextMenuNickName}>{nickName}</p>
                  </NavLink>
                </MenuItem>
                <Divider />
                <MenuItem
                  onClick={() =>
                    history.push(isEngineer ? `/en/setting` : `/co/setting`)
                  }
                >
                  <ListItemWithIcon
                    icon={IconMenuSetting}
                    text={'アカウント設定'}
                  />
                </MenuItem>
                <MenuItem onClick={() => history.push('/signout-loading')}>
                  <ListItemWithIcon icon={IconSignOut} text={'ログアウト'} />
                </MenuItem>
              </Menu>
            </div>
          ) : (
            <UnLoggedInLayout history={history} />
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
};

const ListItemWithIcon = ({icon, text}) => {
  return (
    <div className={style.listItemContainer}>
      <img alt={'画像が表示できません'} src={icon} />
      <p className={style.menuText}>{text}</p>
    </div>
  );
};

const UnLoggedInLayout = ({history}) => {
  return (
    <div className={style.menu}>
      <CommonButton
        text={'新規登録 / ログイン'}
        size={'preMinimum'}
        color={'blue'}
        variant={'outlined'}
        shape={'normal'}
        onClick={() => history.push('/signin')}
      />
    </div>
  );
};

const makeOnClickLogoPath = (isAnonymousUser, isEngineer) => {
  if (isAnonymousUser) {
    return '/public/tasks';
  } else if (isEngineer) {
    return '/en';
  } else {
    return '/co';
  }
};

export default MenuAppBar;
