import {
  SUCCESS_SYSTEM_NEWS_LIST,
  SUCCESS_HEAD_SYSTEM_NEWS_LIST,
  SET_SYSTEM_NEWS_LAST_SNAPSHOT,
  SET_IS_READ_ALL_SYSTEM_NOTIFICATION
} from '../actions/systemNews';
import {MAX_GET_DOCS_COUNT} from '../common';

const initialState = {
  headSystemDocuments: [],
  systemDocuments: [],
  hasMoreSystemDocuments: true,
  systemNewsLastSnapshot: null,
  isReadAllSystemNotification: null
};

const systemNews = (state = initialState, action) => {
  switch (action.type) {
    case SUCCESS_HEAD_SYSTEM_NEWS_LIST:
      return {
        ...state,
        headSystemDocuments: action.payload,
        hasMoreSystemDocuments: action.payload.length === MAX_GET_DOCS_COUNT
      };
    case SUCCESS_SYSTEM_NEWS_LIST:
      return {
        ...state,
        systemDocuments: action.payload,
        hasMoreSystemDocuments: action.payload.length === MAX_GET_DOCS_COUNT
      };
    case SET_SYSTEM_NEWS_LAST_SNAPSHOT:
      return {
        ...state,
        systemNewsLastSnapshot: action.payload
      };
    case SET_IS_READ_ALL_SYSTEM_NOTIFICATION:
      return {
        ...state,
        isReadAllSystemNotification: action.payload
      };
    default:
      return state;
  }
};

export default systemNews;
