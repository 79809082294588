import React from 'react';
import {useHistory} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core';

import styles from './addNewButton.module.scss';
import PlusIcon from '../../../assets/icon_plus_button.svg';

const useStyles = makeStyles({
  button: {
    width: '100%',
    border: '0.1rem solid #093791',
    borderRadius: '2rem',
    backgroundColor: '#FFF',
    padding: '0.7rem 2rem'
  }
});

const AddNewButton = ({text, path}) => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <Button
      variant="outlined"
      className={classes.button}
      onClick={() => history.push(path)}
    >
      <img src={PlusIcon} alt="" />
      <p className={styles.text}>{text}</p>
    </Button>
  );
};

export default AddNewButton;
