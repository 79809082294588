import React from 'react';
import style from './subTitle.module.scss';
import Divider from '@material-ui/core/Divider';
import {withStyles} from '@material-ui/core/styles';
import HelpButton from '../HelpButtons/HelpButton';

const styles = {
  divider: {
    height: '0.2rem',
    background: '#093791',
    marginTop: '1.5rem'
  }
};

const Index = ({classes, headerText, helpText = null}) => {
  return (
    <div>
      <div className={style.main}>
        {headerText}
        {helpText && <HelpButton text={helpText} placement={'right-end'} />}
      </div>
      <Divider
        classes={{
          root: classes.divider
        }}
      />
    </div>
  );
};

export default withStyles(styles)(Index);
