import React from 'react';
import styles from './taskItemDetail.module.scss';
import TaskInfo from '../TaskInfo';

const TaskItemDetail = ({project_title, tagsText}) => {
  return (
    <div className={styles.taskItem}>
      <TaskInfo img="project" text={project_title} />
      <TaskInfo img="tags" text={tagsText} />
    </div>
  );
};

export default TaskItemDetail;
