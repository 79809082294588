import React from 'react';
import CommonLayout from '../../layout/CommonLayout';
import {Redirect, Route, Switch} from 'react-router-dom';
import PublicTasks from '../PublicTasks';
import TaskDetail from '../../../containers/TaskDetail';

const PublicRoute = () => {
  return (
    <CommonLayout>
      <Switch>
        <Redirect exact from="/public" to={`/public/tasks`} />
        <Route exact path="/public/tasks" component={PublicTasks} />
        <Route
          exact
          path="/public/project/:projectId/task/:id/"
          component={TaskDetail}
        />
      </Switch>
    </CommonLayout>
  );
};

export default PublicRoute;
