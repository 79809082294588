import React, {FC} from 'react';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import styles from '../../common/common.module.scss';
import Blank from '../Blank';

const WithOpenInNewWindow: FC = ({children}) => {
  return (
    <div className={styles.centeringContainer}>
      {children}
      <Blank width={'0.8rem'} height={null} /> <OpenInNewIcon />
    </div>
  );
};

export default WithOpenInNewWindow;
