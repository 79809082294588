import React from 'react';
// material-ui
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import {SIZE, SHAPE, COLOR, ICON_SIZE} from './constants';

const CommonButton = ({
  text,
  size,
  color,
  shape,
  variant,
  icon,
  disabled,
  onClick = e => {},
  type = 'button'
}) => {
  const useStyles = makeStyles({
    button: {
      ...SHAPE[shape],
      ...SIZE[size],
      ...COLOR[variant][color],
      fontWeight: 'bold'
    }
  });

  const classes = useStyles();

  return (
    <Button
      type={type}
      variant={variant}
      classes={{
        root: classes.button
      }}
      disabled={disabled}
      onClick={e => onClick((e = e))}
    >
      {icon ? (
        <img src={icon} style={{marginRight: '1rem', ...ICON_SIZE[size]}} />
      ) : null}
      {text}
    </Button>
  );
};

export default CommonButton;
