import firebase from 'firebase';
import {makeRoomId} from '../common';

export const fetchRoom = async (meId, msgToId) => {
  try {
    const firebaseDb = firebase.firestore();
    const roomList = [];
    let isExists = null;
    const targetRoomId = makeRoomId(meId, msgToId);

    const doc = await firebaseDb.collection('rooms').doc(targetRoomId).get();

    if (doc.exists) {
      roomList.push({id: doc.id, data: doc.data(), doc: doc});
      isExists = true;
    } else {
      isExists = false;
    }

    return [isExists, roomList, null];
  } catch (e) {
    return [null, null, e];
  }
};

export const fetchRooms = async (userId, currentRoom, useRefresh) => {
  try {
    const firebaseDb = firebase.firestore();
    const roomList = [];

    let querySnapshot;
    const MAX_GET_DOC_COUNT = 10;

    if (currentRoom && !useRefresh) {
      querySnapshot = await firebaseDb
        .collection('rooms')
        .where('users', 'array-contains', userId)
        .orderBy('created_at', 'desc')
        .startAfter(currentRoom)
        .limit(MAX_GET_DOC_COUNT)
        .get();
    } else {
      querySnapshot = await firebaseDb
        .collection('rooms')
        .where('users', 'array-contains', userId)
        .orderBy('created_at', 'desc')
        .limit(MAX_GET_DOC_COUNT)
        .get();
    }
    // ↓ドキュメントを取得中か判断、6個取れなくなったgetを最後にするためにfalseを返す
    let hasMore = querySnapshot.docs.length !== 0;

    querySnapshot.forEach(doc => {
      roomList.push({id: doc.id, data: doc.data(), doc: doc});
    });

    return [roomList, null, hasMore];
  } catch (err) {
    return [null, err];
  }
};

export const fetchMessages = async (room_id, currentMessageId) => {
  const messageList = [];
  const firebaseDb = firebase.firestore();
  try {
    let querySnapshot;
    const NumberOfDocuments = 5;
    if (currentMessageId === null) {
      querySnapshot = await firebaseDb
        .collection('rooms')
        .doc(room_id)
        .collection('messages')
        .orderBy('created_at', 'desc')
        .limit(NumberOfDocuments)
        .get();
    } else {
      querySnapshot = await firebaseDb
        .collection('rooms')
        .doc(room_id)
        .collection('messages')
        .orderBy('created_at', 'desc')
        .startAfter(currentMessageId)
        .limit(NumberOfDocuments)
        .get();
    }

    querySnapshot.forEach(doc => {
      messageList.push({id: doc.id, data: doc.data(), doc: doc});
    });

    return [messageList, null];
  } catch (err) {
    return [null, err];
  }
};
