import React from 'react';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import {history} from '../../../store';

const useStyles = makeStyles(theme => ({
  button: {
    width: '12.2rem',
    color: '#093791',
    fontSize: '1.6rem',
    border: '0.1rem solid #093791',
    fontWeight: 'bold'
  },
  input: {
    display: 'none'
  }
}));

const EditButton = ({
  link,
  text = '編集する',
  onClick = () => history.push(link)
}) => {
  const classes = useStyles();

  return (
    <Button className={classes.button} variant="outlined" onClick={onClick}>
      {text}
    </Button>
  );
};

export default EditButton;
