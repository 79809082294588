import { REQUEST_BATCH } from '../actions/batch'

const isNews = true;

const batch = (state = isNews, action) => {
  switch (action.type) {
    case 'REQUEST_BATCH':
      return state;
    default:
      return state;
  }
};

export default batch
