import React, {FC, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import MailIcon from '../../assets/icon_mail_blue.svg';
import {useHistory} from 'react-router-dom';
import {requestInitRooms, requestCheckRoomStatus} from '../../actions/message';
import {userType} from '../../common';
import CommonButton from '../../components/CommonButton';
import {makeRoomId} from '../../common';
import {RootState} from '../../models/RootModels';

type Params = {
  title: string;
  content: string;
};

type Props = {
  userId: string | number;
  params?: Params;
  onClickSideEffect?: (args?: any) => void;
  size?: string;
  ChildComponent?: any;
};

const MoveToMessageButton: FC<Props> = ({
  userId,
  params = {title: '', content: ''},
  onClickSideEffect = () => {},
  size = 'medium',
  ChildComponent = null
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isRoomExists = useSelector(
    (state: RootState) => state.message.isRoomExists
  );
  const {me, isEngineer} = useSelector((state: RootState) => state.setting);

  useEffect(() => {
    if (me.id) {
      dispatch(requestCheckRoomStatus({meId: me.id, msgToId: userId}));
    }
  }, [me]);

  const handleOnClick = (params: Params) => {
    if (isRoomExists) {
      // あらかじめルームを発火させて、message一覧ページへ遷移
      dispatch(
        requestInitRooms({
          roomId: makeRoomId(me.id, userId),
          toId: userId,
          title: null
        })
      );
      history.push(`/${userType(isEngineer)}/message`, params);
    } else {
      history.push(`/${userType(isEngineer)}/message/create/${userId}`, params);
    }
    onClickSideEffect();
  };

  if (ChildComponent) {
    return (
      <ChildComponent
        disabled={isRoomExists === null}
        onClick={(e: any) => {
          handleOnClick(params);
          e.stopPropagation();
        }}
      />
    );
  } else {
    return (
      <CommonButton
        disabled={isRoomExists === null}
        text={'メッセージ'}
        size={size}
        icon={MailIcon}
        shape={'normal'}
        variant="outlined"
        color={'blue'}
        onClick={e => {
          handleOnClick(params);
          e.stopPropagation();
        }}
      />
    );
  }
};

export default MoveToMessageButton;
