import {all, call, put, fork, take, select} from 'redux-saga/effects'
import {fetchGet} from './utills/fetch';
import {
  requestTag,
  successTag
} from '../actions/tag';

export function* getTags() {
  while (true) {
    const action = yield take(requestTag);
    const keyword = action.payload;
    const [data,error] = yield call(fetchGet, `suggest/?keyword=${keyword}`);
    if(data && !error) {
      yield put(successTag(data))
    }
  }
}
const Tag = [
  fork(getTags)
]

export default Tag;
