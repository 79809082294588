import store from 'store';
import {store as reduxStore} from '../../store';
import {syncLoalStorageBaseValues} from '../../actions/setting';

export const isRedirectCancelUrl = (pathName: string, urls: string[]) => {
  return urls.some(path => pathName.match(path));
};

export const setSigninData = (
  access_token: string,
  refresh_token: string,
  isEngineer: boolean
) => {
  store.set('token', access_token);
  store.set('refreshToken', refresh_token);
  store.set('isEngineer', isEngineer);
  reduxStore.dispatch(
    syncLoalStorageBaseValues({
      isAnonymousUser: false,
      isEngineer: isEngineer
    })
  );
};
