import {
  SUCCESS_PROJECT_LIST,
  SUCCESS_OWN_PROJECT_LIST,
  SUCCESS_PROJECT_DETAIL,
  SET_PROJECT_ID
} from '../actions/project';

const initialState = {
  list: null,
  count: null,
  next: null,
  previous: null,
  detail: {
    main_category: {
      main_category: null
    },
    sub_category: {
      name: null
    }
  },
  projectId: '',
  ownProjectList: {}
};

function project(state = initialState, action) {
  switch (action.type) {
    case SUCCESS_PROJECT_LIST:
      return {
        ...state,
        list: action.payload.results,
        count: action.payload.count,
        next: action.payload.next,
        previous: action.previous
      };
    case SUCCESS_PROJECT_DETAIL:
      return {
        ...state,
        detail: action.payload
      };
    case SUCCESS_OWN_PROJECT_LIST:
      return {
        ...state,
        list: action.payload.results,
        count: action.payload.count,
        next: action.payload.next,
        previous: action.payload.previous
      };
    case SET_PROJECT_ID:
      return {
        ...state,
        projectId: action.payload.id
      };
    default:
      return state;
  }
}

export default project;
