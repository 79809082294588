import React, {useEffect, useRef} from 'react';
import {
  Switch,
  Route,
  Redirect,
  useLocation,
  useHistory
} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import CoTemplate from './co';
import EnTemplate from './en';
import {requestMe} from '../actions/setting';
import store from 'store';
import {
  requestCustomToken,
  requestFirebaseLoginStatus,
  requestAdditionalClaims
} from '../actions/firebase';
import {userType} from '../common';

const AppRoutes = () => {
  const dispatch = useDispatch();
  const setting = useSelector(state => state.setting);
  const isEngineer = setting.isEngineer;
  const isAuthenticated = Boolean(store.get('token'));
  const {pathname} = useLocation();
  const history = useHistory();

  useEffect(() => {
    dispatch(requestMe());
  }, []);

  const mounted = useRef(false);
  useEffect(() => {
    if (mounted.current) {
      if (setting.me.username && isAuthenticated) {
        dispatch(requestCustomToken());
        dispatch(requestFirebaseLoginStatus());
      }
    } else {
      mounted.current = true;
    }
  }, [setting.me.username]);

  if (isEngineer && pathname.includes('/co/')) {
    history.push(pathname.replace('/co/', '/en/'));
    return <></>;
  } else if (isEngineer === false && pathname.includes('/en/')) {
    history.push(pathname.replace('/en/', '/co/'));
    return <></>;
  }

  return (
    <div>
      <Switch>
        <Redirect exact from="/" to={`/${userType(isEngineer)}`} />

        {!isAuthenticated && (
          <Redirect
            exact
            from={`/en/project/:projectId/task/:id/`}
            to={'/public/project/:projectId/task/:id/'}
          />
        )}
        {!isAuthenticated && (
          <Redirect
            exact
            from={`/co/project/:projectId/task/:id/`}
            to={'/public/project/:projectId/task/:id/'}
          />
        )}

        <Route path="/en" component={EnTemplate} />
        <Route path="/co" component={CoTemplate} />
      </Switch>
    </div>
  );
};

export default AppRoutes;
