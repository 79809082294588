import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {requestPaidRewardTasks} from '../../../actions/reward';
import styles from '../rewardAdmin.module.scss';
// components
import AppBar from '../../../components/MenuAppBar';
import PageTitle from '../../../components/PageTitle';
import Title from '../../../components/Title';
import PaidRewardTask from '../../../components/Task/TaskLists/Rewards/PaidRewardTask';
import Blank from '../../../components/Blank';
import NotAvailable from '../../../components/NotAvailable';
import Paginate from '../../../components/Paginate';

const RewardAdminCo = props => {
  const dispatch = useDispatch();
  const {paidRewardTasks} = useSelector(state => state.rewardAdmin);

  const {count, next, previous, results} = paidRewardTasks;

  useEffect(() => {
    dispatch(requestPaidRewardTasks(1));
  }, []);

  return (
    <>
      <AppBar />
      <div className={styles.container}>
        <PageTitle title="報酬管理" />
        <Blank height={'5rem'} />
        <div>
          <Title component="h2" text="報酬支払い済み情報" />
          <div className={styles.taskList}>
            {paidRewardTasks.results.length === 0 && <NotAvailable kind="NO_PAID_TASKS" />}
            {paidRewardTasks.results.map((item, index) => {
              return <PaidRewardTask key={index} item={item} index={index} />;
            })}
            <Paginate
              limit={10}
              total={count}
              {...props}
              method={page => dispatch(requestPaidRewardTasks(page))}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default RewardAdminCo;
