import React from 'react'
import Button from '@material-ui/core/Button';
import {withStyles} from '@material-ui/core/styles';
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import Header from '../Header/'
import style from './others.module.scss'
import RightArrow from '../../../assets/icon_right_arrow.svg'


const styles = theme => ({
  button: {
    margin: theme.spacing(1),
    padding: "5rem",
  },
  input: {
    display: 'none',
  },
});

const theme = createMuiTheme({
  overrides: {
    MuiButton: {
      root: {
        textTransform: "none",
        padding: "2rem 2rem 2rem 3rem",
        marginRight: "1rem",
        marginBottom: "1rem",
        width: "30rem"
      },
      containedPrimary: {
        backgroundColor: "#093791",
      }
    }
  }
});

// APIが完成するまでのテストデータ
const testData = {
  twitter: "https://twitter.com",
  facebook: "https://facebook.com",
  note: "https://note.com",
  qiita: "https://qiita.com",
  blog: "https://brog.com",
  ポートフォリオサイト: "https://my_portforio.com"
};

const testDataKeys = Object.keys(testData)

const Index = () => {
  return (
    <div className={style.main}>
      <Header headerText={"その他"}/>
      <p className={style.title}>自己PR</p>
      <span>
          ここにテキストが入りますここにテキストが入りますここにテキストが入りますここにテキストが入りますここにテキストが入りますここにテキストが入りますここ
        </span>
      <div>
        <p className={style.title}>SNS・その他</p>
        <div className={style.buttons}>
          <MuiThemeProvider theme={theme}>
            {testDataKeys.map(key => (
              <Button variant="contained" color="primary" className={styles.button}>
                <div className={style.forFlex}>
                  <div className={style.innerTextWrapper}>
                    <span className={style.innerText}>{key}</span>
                  </div>
                  <div className={style.imgWrapper}>
                    <img className={style.arrow} src={RightArrow}/>
                  </div>
                </div>
              </Button>
            ))}
          </MuiThemeProvider>
        </div>
      </div>
    </div>
  )
};

export default withStyles(styles)(Index)
