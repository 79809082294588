import {call, fork, put, take, select} from 'redux-saga/effects';
import {reset, startSubmit, stopSubmit} from 'redux-form';
import {push} from 'connected-react-router';
import store from 'store';
import {history, store as reduxStore} from '../store';
import {fetchGet, fetchPostJson} from './utills/fetch';
import {CLIENT_ID} from '../config';
import {
  requestGithubSignIn,
  requestMemberActivation,
  requestRefreshToken,
  requestSignIn,
  requestSignUp,
  requestSignout,
  setAccessToken,
  setOpenTwoFactorAuthInput,
  requestTwoFactorAuthUri,
  successTwoFactorAuthUri,
  failedTwoFactorAuthUri
} from '../actions/sign';
import {setIsEngineer} from '../actions/setting';
import {setSigninData} from '../sagas/utills/common';
import {firebaseSignOut} from '../firebase/common';
import {setModalSetting} from '../actions/task';
import CautionIcon from '../assets/icon_caution.svg';
import {formatErrorResponse, makeFormErrorFormat} from '../common';

// エンジニアサインイン Github サインイン用 アクセストークンの取得
export function* postGitHubSignin() {
  while (true) {
    const action = yield take(requestGithubSignIn);
    const [data, error] = yield call(
      fetchPostJson,
      'oauth/github',
      action.payload
    );
    if (data && !error) {
      // 取得したアクセストークン類をlocalStorageに歩人
      setSigninData(data.access_token, data.refresh_token, true);
      yield put(setAccessToken(data.access_token));
    } else {
      yield put(
        setModalSetting({
          type: 'FAILED_SIGNIN',
          isVisible: true,
          params: {
            title: 'ログインに失敗しました',
            message: `${error.detail}`,
            icon: CautionIcon,
            buttonProps: {
              text: '閉じる',
              onClick: () => {
                reduxStore.dispatch(
                  setModalSetting({
                    type: 'FAILED_SIGNIN',
                    isVisible: false
                  })
                );
                history.push(`/signin`);
              }
            }
          }
        })
      );
    }
  }
}

// 企業側ログイン
export function* postSignin() {
  while (true) {
    const action = yield take(requestSignIn);
    yield put(startSubmit('signin'));

    action.payload['grant_type'] = 'password';
    action.payload['client_id'] = CLIENT_ID;

    let result = [];

    if (action.payload.one_time_token) {
      result = yield call(fetchPostJson, 'totp/login', action.payload);
    } else {
      result = yield call(
        fetchPostJson,
        'totp/login-or-require-token',
        action.payload
      );
    }

    const [data, error] = result;

    if (data && !error) {
      if (data === 'required_two_factor_code') {
        yield put(setOpenTwoFactorAuthInput(true));
      } else {
        setSigninData(data.access_token, data.refresh_token, false);
        yield put(stopSubmit('signin'));
        yield put(reset('signin'));
        yield put(push('/co'));
      }
    } else {
      if (error.one_time_token) {
        yield put(stopSubmit('signin', makeFormErrorFormat(error)));
      } else {
        yield put(
          stopSubmit(
            'signin',
            Object.assign({
              validationError: 'メールアドレスかパスワードが間違っています。'
            })
          )
        );
      }
    }
  }
}

// アクセストークンの更新
export function* postRefreshToken() {
  while (true) {
    yield take(requestRefreshToken);
    const param = {
      grant_type: 'refresh_token',
      client_id: CLIENT_ID,
      refresh_token: store.get('refreshToken') || null
    };
    const [data, error] = yield call(fetchPostJson, 'auth/token', param, true);
    if (data && !error) {
      store.set('token', data.access_token);
      store.set('refreshToken', data.refresh_token);
      window.location.reload();
    } else {
      history.push('/signin');
    }
  }
}

// 企業側新規作成画面（スーパユーザ作成はAPIで行うことになった）
export function* postSignUp() {
  while (true) {
    const action = yield take(requestSignUp);
    action.payload['tel'] = joinHyphen(action.payload);
    yield put(startSubmit('signup'));
    try {
      // 企業の新規作成
      const [data, error] = yield call(
        fetchPostJson,
        'companies',
        action.payload
      );
      if (data && !error) {
        yield put(stopSubmit('signup'));
        yield put(push('/co-signup/result'));
      } else if (error) {
        yield put(stopSubmit('signup', makeFormErrorFormat(error)));
      }
    } catch (e) {
      yield put(push('/sorry'));
      yield put(stopSubmit('signup'));
      yield put(reset('signup'));
    }
  }
}

export function* getTwoFactorAuthUri() {
  while (true) {
    yield take(requestTwoFactorAuthUri);
    const [data, error] = yield call(fetchGet, 'totp/create/');

    if (data && !error) {
      yield put(successTwoFactorAuthUri(data));
    } else {
      yield put(failedTwoFactorAuthUri(error.detail));
    }
  }
}

const joinHyphen = payload => {
  return `${payload.tel1}-${payload.tel2}-${payload.tel3}`;
};

// 企業側招待ユーザー新規登録
export function* postSignUpCoUser() {
  while (true) {
    const action = yield take(requestMemberActivation);
    action.payload.formData['token'] = action.payload.token;

    const [data, error] = yield call(
      fetchPostJson,
      'activation',
      action.payload.formData
    );

    if (data && !error) {
      setSigninData(data.access_token, data.refresh_token, false);
      yield put(push('/co'));
    } else {
      yield put(stopSubmit('invitedUser', makeFormErrorFormat(error)));
    }
  }
}

export function* signout() {
  // ローカルストレージを空にする && firebase からサインアウトする && signin 画面に遷移する &&firestoreのonSnapShotによるリスナーをデタッチする
  while (true) {
    yield take(requestSignout);

    try {
      const {
        personalNotificationFunction,
        systemNotificationFunction
      } = yield select(state => state.personalNotification);
      if (personalNotificationFunction && systemNotificationFunction) {
        personalNotificationFunction.forEach(unsubscribe => {
          unsubscribe();
        });
        systemNotificationFunction();
      } else {
      }

      // RFC 7009 に則り、リフレッシュトークンの無効化を実行
      const param = {
        client_id: CLIENT_ID,
        token: store.get('refreshToken')
      };

      const [_, revoke_error] = yield call(
        fetchPostJson,
        'auth/revoke-token',
        param,
        true
      );

      const [data, error] = yield call(firebaseSignOut);
      if (data && !error) {
        store.clearAll();
      }
      yield put(setIsEngineer(null));
    } catch (error) {
      console.error(error);
      history.push('/sorry');
    }
  }
}

const Sign = [
  fork(postGitHubSignin),
  fork(postSignin),
  fork(postSignUp),
  fork(postSignUpCoUser),
  fork(postRefreshToken),
  fork(signout),
  fork(getTwoFactorAuthUri)
];

export default Sign;
