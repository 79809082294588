import React from 'react'

import styles from './contentText.module.scss'

const ContentText = ({title, content, contentExtra}) => {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.title}>{title}</div>
        <div>
          <div className={styles.body}>{content}</div>
          <div className={styles.body}>{contentExtra}</div>
        </div>
      </div>
      <div className={styles.border} />
    </>
  )
};

export default ContentText
