import React, {useEffect, useRef} from 'react';
import {Switch, Route, useHistory} from 'react-router-dom';
import {createMuiTheme, makeStyles} from '@material-ui/core/styles';

// en containers
import SearchTask from './Task/SearchTask';
import Dashboard from './Dashboard';
import Search from './Search';
import User from './User';
import Profile from './Profile';
import TaskDetail from '../TaskDetail';
import Notification from './Notification';
import TaskScore from './Task/Score';
import MessageBox from '../../components/MessageBox';
import MessageCreator from '../../components/MessageCreator';
import AccountSetting from '../AccountSetting';
import AccountEmailPassword from './AccountEmailPassword';
import CommonLayout from '../../containers/layout/CommonLayout';
import CorporateInfo from '../../components/CorporateInfo';
import EngineerInfo from '../../components/EngineerInfo';
import RewardAdminEn from '../../containers/RewardAdmins/RewardAdminEn';
import News from '../News';
import CompanyInfo from '../co/Info';
import CoProjectDetail from '../co/Project/Detail';
import TermsOfServiceAccept from '../../containers/en/TermsOfServiceAccept';
import {useSelector} from 'react-redux';

const EnTemplate = () => {
  const history = useHistory();
  const {me} = useSelector(state => state.setting);

  useEffect(() => {
    if (!me.is_accept_terms_of_service) {
      history.push('/en/accept-termsofservice');
    }
  }, [me]);

  return (
    <div>
      <CommonLayout>
        <Switch>
          <Route exact path="/en/" component={Dashboard} />
          <Route exact path="/en/search" component={Search} />
          <Route exact path="/en/user" component={User} />
          <Route path="/en/user/edit" component={Profile} />
          <Route exact path="/en/task/" component={SearchTask} />
          <Route exact path="/en/project/:id" component={CoProjectDetail} />
          <Route
            exact
            path="/en/project/:projectId/task/:id/"
            component={TaskDetail}
          />
          <Route
            exact
            path="/en/project/:projectId/task/:id/score"
            component={TaskScore}
          />
          <Route exact path="/en/notification" component={Notification} />
          <Route exact path="/en/message/" component={MessageBox} />
          <Route
            exact
            path="/en/message/create/:id"
            component={MessageCreator}
          />
          <Route exact path="/en/setting" component={AccountSetting} />
          <Route
            path="/en/setting/emailpassword"
            component={AccountEmailPassword}
          />
          <Route exact path="/en/co-user/:id" component={CorporateInfo} />
          <Route path="/en/en-user/:id" component={EngineerInfo} />
          <Route exact path="/en/co-info/:id" component={CompanyInfo} />
          <Route exact={true} path="/en/news" component={News} />
          <Route
            exact={true}
            path="/en/rewardAdmin"
            component={RewardAdminEn}
          />
          <Route
            exact
            path="/en/accept-termsofservice"
            component={TermsOfServiceAccept}
          />
        </Switch>
      </CommonLayout>
    </div>
  );
};

export default EnTemplate;
