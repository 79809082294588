import React, {Component, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {reduxForm, getFormSubmitErrors, getFormValues} from 'redux-form';
import ProjectForm from '../../../components/Form/co/ProjectForm/ProjectForm';
import {requestProjectCreate} from '../../../actions/project';
import {requestCategoryList} from '../../../actions/category';
import style from './project.module.scss';
import MainTitle from '../../../components/MainTitle';
import {makeSubCategoryList} from './utils';
import {NEW_PROJECT_PHRASE} from '../../../constants/phrase';

const Create = props => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(requestCategoryList());
  }, []);

  const categoryList = useSelector(state => state.category.list);
  const submitErrors = useSelector(state => {
    return getFormSubmitErrors('projects')(state);
  });
  const formValues = useSelector(state => {
    return getFormValues('projects')(state);
  });

  let subCategoryList = makeSubCategoryList(categoryList, formValues);

  // サブカテゴリは使用しなくなったが、id を送る必要はある為 デフォルト値 1 を設定

  return (
    <>
      <div className={style.mainTitleContainer}>
        <MainTitle titleStr={'新規プロジェクト'} pattern={false} />
        <div className={style.information}>
          <p className={style.informationText}>
            ※プロジェクトは作成後も編集できます
          </p>
        </div>
      </div>
      <ProjectForm
        onSubmit={value => dispatch(requestProjectCreate(value))}
        submitErrors={submitErrors}
        categoryList={categoryList}
        subCategoryList={subCategoryList}
        submitText={'作成する'}
        initialValues={{
          main_category_id: '0',
          sub_category_id: '1',
          description: NEW_PROJECT_PHRASE
        }}
        {...props}
      />
    </>
  );
};

export default Create;
