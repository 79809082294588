import React from 'react';
import format from 'date-fns/format';
import clsx from 'clsx';

// material-ui
import {makeStyles} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import style from './talkRoom.module.scss';

//toUserInfoでuser情報がちゃんと渡されていない時の共通アバター
import IconAvater from '../../assets/icon_header_avater.svg';
import {AvatarImg, postTime} from '../../common';

const useStyles = makeStyles({
  minimumAvatar: {
    margin: 0
  },
  container: {
    padding: 0
  },
  generalCard: {
    backgroundColor: '#FFFFFF'
  },
  selectingCard: {
    backgroundColor: '#FFFEEE'
  },
  root: {
    borderRadius: 0,
    height: '10.5rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
});

const TalkRoom = ({
  room,
  onClickHandler,
  selectedRoomId,
  toId,
  toUserInfo,
  message
}) => {
  const classes = useStyles();
  let isSelecting = message.selectedRoomId === room.id;
  let userImgSrc = null;

  //toUserInfoでuser情報がちゃんと渡されているか分岐。undefinedの時は仮置きでIconAvaterを表示させる
  if (toUserInfo) {
    //↓ユーザーがトプ画を設定しているかで分岐、していなかったら共通のマークIconAvater
    //↓まだ、APIからのレスポンスにimgが含まれていない。含まれるまで全て IconAvaterになるように(要相談)
    userImgSrc = toUserInfo.img;
  } else {
    userImgSrc = IconAvater;
  }

  return (
    <Card
      className={isSelecting ? classes.selectingCard : classes.generalCard}
      classes={{root: classes.root}}
    >
      <CardActionArea
        onClick={() => {
          onClickHandler({
            roomId: room.id,
            toId: toId,
            title: room.data.title
          });
        }}
      >
        <CardContent className={classes.container}>
          <div>
            <div className={style.listItemContainer}>
              <div className={classes.minimumAvatar}>
                <AvatarImg img={userImgSrc} size="minimum" />
              </div>
              <div className={style.items}>
                <p
                  className={
                    isSelecting
                      ? clsx(style.fromName, style.fromNameActive)
                      : clsx(style.fromName, style.fromNameNotActive)
                  }
                >
                  {toUserInfo ? toUserInfo.nick_name : '読み込み中...'}
                </p>
                <p className={style.companyName}>
                  {toUserInfo ? toUserInfo.company_name : null}
                </p>
                <p className={style.date}>
                  {postTime(room.data.created_at.toDate())}
                </p>
              </div>
            </div>
          </div>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default TalkRoom;
