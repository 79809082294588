import React, {useEffect, useMemo} from 'react';
import {useDispatch} from 'react-redux';

import styles from './valuationItem.module.scss';
import {requestValuation} from '../../actions/valuation';
import Text from '../Text';
import ValuationScore from '../ValuationScore';
import formatDate from '../../components/FormatDate';
import {AvatarImg} from '../../common';

const ValuationItem = ({item}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(requestValuation());
  }, []);

  const params = useMemo(() => makeParams(item), [item]);

  return item.reviewer_company_info ? (
    <>
      <div className={styles.dateTitle}>
        <Text
          children={formatDate(item.delivery_date, 'date', 'str')}
          component="text04"
        />
      </div>
      <div className={styles.taskTitle}>
        <Text children={item.title} component="text05" />
      </div>
      <div className={styles.container}>
        <div className={styles.commentContainer}>
          <div className={styles.flex}>
            <AvatarImg img={item.reviewer_company_info.img} size="minimum" />
            <div className={styles.name}>
              <Text
                children={item.reviewer_company_info.name}
                component="text09"
              />
            </div>
          </div>

          <div className={styles.text}>
            <Text children={item.comment} component="text08" />
          </div>
        </div>
        <div className={styles.verticalVar} />
        <ValuationScore params={params.en} />
      </div>
    </>
  ) : (
    <>
      <div className={styles.dateTitle}>
        <Text
          children={formatDate(item.delivery_date, 'date', 'str')}
          component="text04"
        />
      </div>
      <div className={styles.taskTitle}>
        <Text children={item.title} component="text05" />
      </div>
      <div className={styles.container}>
        <div className={styles.commentContainer}>
          <div className={styles.flex}>
            <AvatarImg img={item.reviewer_engineer.img} size="minimum" />
            <div className={styles.name}>
              <Text
                children={item.reviewer_engineer.nick_name}
                component="text09"
              />
            </div>
          </div>
          <div className={styles.text}>
            <Text children={item.comment} component="text08" />
          </div>
        </div>
        <div className={styles.verticalVar} />
        <ValuationScore params={params.co} />
      </div>
    </>
  );
};

const makeParams = item => {
  return {
    en: [
      {name: 'スケジュール', ratio: convertToPercentile(item.review1)},
      {name: 'レスポンス', ratio: convertToPercentile(item.review2)},
      {name: 'リピート', ratio: convertToPercentile(item.review3)},
      {name: '説明力', ratio: convertToPercentile(item.review4)},
      {name: 'クオリティ', ratio: convertToPercentile(item.review5)}
    ],
    co: [
      {name: 'スケジュール', ratio: convertToPercentile(item.review1)},
      {name: 'レスポンス', ratio: convertToPercentile(item.review2)},
      {name: 'リピート', ratio: convertToPercentile(item.review3)},
      {name: '安定感', ratio: convertToPercentile(item.review4)},
      {name: '協力', ratio: convertToPercentile(item.review5)}
    ]
  };
};

// 評価を100%の形式に変換する
const convertToPercentile = val => val * 2 * 10;

export default ValuationItem;
