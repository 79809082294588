import React from 'react';
import styles from './registerFooter.module.scss';
import CommonButton from '../CommonButton';
import {useHistory} from 'react-router';
import EngineerIcon from '../../assets/engineer_icon.svg';
import {makeStyles} from '@material-ui/core/styles';
import {COLOR_PALLET} from '../../constants';
import Blank from '../Blank';

const useStyles = makeStyles({
  root: {
    position: 'fixed',
    bottom: 0,
    right: 0,
    width: '100%',
    height: '7.5rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: COLOR_PALLET.blue900,
    padding: 'unset',
    borderRadius: 'unset'
  }
});

const RegisterFooter = () => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <div className={classes.root} onClick={() => history.push('/signin')}>
      <div className={styles.itemContainer}>
        <div className={styles.imgContainer}>
          <img src={EngineerIcon} alt={'エンジニア'} className={styles.img} />
        </div>
        <div className={styles.titleContainer}>
          <div className={styles.itText}>IT専門</div>
          <p className={styles.cloudText}>クラウドソーシング</p>
        </div>
        <div className={styles.buttonContainer}>
          <div className={styles.normalText}>\ 副業に最適！/</div>
          <Blank height={'0.5rem'} />
          <CommonButton
            text={'無料で登録する'}
            size={'minimum'}
            color={'pink'}
            variant={'contained'}
            shape={'normal'}
            onClick={() => history.push('/signin')}
          />
        </div>
      </div>
    </div>
  );
};

export default RegisterFooter;
