import React, {Fragment} from 'react';
import {TASK_STATUS, currentTime, changeDate} from '../../common';
import Infomation from '../Infomation';
import AdvanceNotice from '../advanceNotice';
import style from '../../containers/co/Task/Detail/detail.module.scss';
import StatusLabel from '../StatusLabel';
import {getIsExpired, isDeadlineComingSoon} from '../../common/funcs';

const RelatedStatusInfo = ({
  detail,
  isEngineer,
  myId,
  isReviewWaiting,
  isMyCompanyTask
}) => {
  if (detail.status === TASK_STATUS.failed) {
    return (
      <>
        <Infomation
          contents={{
            title: 'タスクの状態について',
            text:
              'このタスクの決済は失敗しました、他の決済方法でもう一度お試しください。'
          }}
        />
        <div className={style.blank} />
      </>
    );
  } else if (detail.status === TASK_STATUS.checked && isReviewWaiting) {
    return (
      <>
        <Infomation
          contents={{
            title: 'タスクの状態について',
            text: '取引相手のユーザが評価するのを待っています'
          }}
        />
        <div className={style.blank} />
      </>
    );
  } else if (detail.status === TASK_STATUS.wanted) {
    return (
      <Fragment>
        {isEngineer ? (
          <WantedTaskInfoEn detail={detail} myId={myId} />
        ) : (
          <WantedTaskInfoCo detail={detail} isMyCompanyTask={isMyCompanyTask} />
        )}
      </Fragment>
    );
  } else if (detail.status === TASK_STATUS.confirming && !isEngineer) {
    return (
      <>
        <AdvanceNotice
          contents={{
            title: '納品確認について',
            text:
              'エンジニアの作業確認依頼後、30日以内に「確認完了」または「修正を以来する」を行なってください。\n30日経過すると自動的に確認完了とみなされます。',
            url: '/terms/co',
            linkText: '利用規約'
          }}
        />
        <div className={style.blank} />
      </>
    );
  } else if (
    detail.status === TASK_STATUS.under_review &&
    !detail.is_task_checked &&
    !isEngineer
  ) {
    return (
      <>
        <Infomation
          contents={{
            title: 'タスクの状態について',
            text:
              'このタスクは審査中です。\n審査が完了しましたら、メールにてお知らせします。\n審査は翌営業日までに完了します。'
          }}
        />
        <div className={style.blank} />
      </>
    );
  } else if (detail.status === TASK_STATUS.working && !isEngineer) {
    return (
      <>
        <Infomation
          contents={{
            title: 'タスクの状態について',
            text:
              '現在エンジニアが作業をしています。\n作業メモを活用し、エンジニアと連絡を取り合いながら作業の終了をお待ちください。'
          }}
        />
        <div className={style.blank} />
      </>
    );
  } else if (detail.status === TASK_STATUS.working && isEngineer) {
    return (
      <>
        <Infomation
          contents={{
            title: 'タスクの状態について',
            text:
              '成果物の作成を開始して下さい。\n要件について相談があれば、作業メモを活用して下さい。\n成果物が完成しましたら企業に指定された方法で納品し、「成果物確認依頼」を押下して下さい。'
          }}
        />
        <div className={style.blank} />
      </>
    );
  } else if (detail.status === TASK_STATUS.reject && !isEngineer) {
    return (
      <>
        <Infomation
          contents={{
            title: 'タスクの状態について',
            text:
              '現在エンジニアが成果物を修正しています。\n作業メモを活用し、エンジニアと連絡を取り合いながら修正の完了をお待ちください。'
          }}
        />
        <div className={style.blank} />
      </>
    );
  } else if (detail.status === TASK_STATUS.closed) {
    return (
      <>
        <Infomation
          contents={{
            title: 'タスクの状態について',
            text: 'このタスクは完了しました。\nこれ以上の対応は必要ありません。'
          }}
        />
        <div className={style.blank} />
      </>
    );
  } else {
    return <></>;
  }
};

const WantedTaskInfoEn = ({detail, myId}) => {
  if (detail.pre_worker === myId) {
    return (
      <>
        <Infomation
          contents={{
            title: 'タスクの状態について',
            text:
              'アサイン依頼が来ています。\nメッセージにて企業担当者と契約について相談してください。\n問題がなければ「アサイン依頼を承諾」を押下してください。'
          }}
        />
        <div className={style.blank} />
      </>
    );
  } else if (detail.worker) {
    return (
      <>
        <Infomation
          contents={{
            title: 'タスクの状態について',
            text: `既にこのタスクにアサインされた状態です。\n企業が仮払いを行うことで「作業中」へ変わります。\n\n仮払いが完了するまで、しばらくお待ちください。`
          }}
        />
        <div className={style.blank} />
      </>
    );
  } else {
    return (
      <Fragment>
        <Infomation
          contents={{
            title: 'タスクの状態について',
            text:
              '案件詳細を確認し、ご興味があればエントリーをお願いします。\n追加で欲しい情報等があれば、「質問タブ」より投稿をお願いします。\n\nエントリー後、企業からアサイン依頼が来るか、他の応募者への依頼が決まりましたらメールでお知らせいたします。'
          }}
        />
        <div className={style.blank} />
      </Fragment>
    );
  }
};

const WantedTaskInfoCo = ({detail, isMyCompanyTask}) => {
  const closing_date = detail.closing_date;

  if (!isMyCompanyTask) {
    return <></>;
  }

  if (detail.is_failed_pay) {
    return (
      <>
        <Infomation
          contents={{
            title: 'タスクの状態について',
            text: `仮払いに失敗しました、別のお支払い方法をお試し下さい。`
          }}
        />
        <div className={style.blank} />
      </>
    );
  } else if (detail.worker) {
    return (
      <>
        <Infomation
          contents={{
            title: 'タスクの状態について',
            text: `エンジニアがアサイン依頼を承諾しました、仮払いを行うことで「作業中」ステータスへと移行します。`
          }}
        />
        <div className={style.blank} />
      </>
    );
  } else {
    return (
      <Fragment>
        {!getIsExpired(closing_date) && (
          <Fragment>
            <Infomation
              contents={{
                title: 'タスクの状態について',
                text: `応募者を確認して、アサイン依頼をお願いします。\nエンジニアがアサイン依頼を承諾し、企業様が仮払いを行うことで作業中ステータスに移行します。`
              }}
            />
            <div className={style.blank} />
          </Fragment>
        )}
        {getIsExpired(closing_date) && (
          <Fragment>
            <AdvanceNotice
              contents={{
                title: '応募期間が終了しました',
                text:
                  '現在、このタスクは募集を終了している状態です。\n募集を継続する場合は右上の「応募期間を延長する」ボタンを押下してください。'
              }}
            />
            <div className={style.blank} />
          </Fragment>
        )}
        {isDeadlineComingSoon(closing_date) && (
          <Fragment>
            <AdvanceNotice
              contents={{
                title: '応募期限が近くなっています！',
                text: '右上の「応募期間を延長する」ボタンより、延長が可能です。'
              }}
            />
            <div className={style.blank} />
          </Fragment>
        )}
      </Fragment>
    );
  }
};

export default RelatedStatusInfo;
