import {all, call, put, takeEvery, take, fork} from 'redux-saga/effects';
import {startSubmit, stopSubmit, reset} from 'redux-form';
import {push} from 'connected-react-router';
import {fetchPostJson, fetchPatch, fetchGet, fetchDelete} from './utills/fetch';
import {
  requestAccountList,
  successAccountList,
  requestAccountDetail,
  successAccountDetail,
  requestAccountCreate,
  requestAccountEdit,
  requestAccountDelete
} from '../actions/account';
import {setMessageType} from '../actions/dialog';

// アカウント一覧取得
export function* getAccountList() {
  while (true) {
    const action = yield take(requestAccountList);
    const [data, error] = yield call(fetchGet, `tasks/`);
    yield put(successAccountList(data));
  }
}

// アカウント一覧取得
export function* getAccountDetail() {
  while (true) {
    const action = yield take(requestAccountDetail);
    const [data, error] = yield call(fetchGet, `tasks/${action.payload.id}/`);
    yield put(successAccountDetail(data));
  }
}

// アカウント新規作成
export function* postAccount() {
  while (true) {
    const action = yield take(requestAccountCreate);
    yield put(startSubmit('accounts'));
    const [data, error] = yield call(fetchPostJson, `accounts`, action.payload);
    if (data && !error) {
      yield put(reset('accounts'));
      yield put(setMessageType('sendMail')); //dialog表示
      yield put(push(`/co/accounts/`));
    } else {
      yield put(
        stopSubmit(
          'accounts',
          Object.assign(error, {_error: error.non_field_errors})
        )
      );
    }
  }
}

// アカウント権限編集
export function* patchAccount() {
  while (true) {
    const action = yield take(requestAccountEdit);
    yield put(startSubmit('accounts'));
    const [data, error] = yield call(
      fetchPatch,
      `accounts/${action.payload.id}`,
      action.payload
    );
    if (data && !error) {
      yield put(reset('accounts'));
      yield put(setMessageType('post')); //dialog表示
      yield put(push(`/co/accounts/`));
    } else {
      yield put(
        stopSubmit(
          'accounts',
          Object.assign(error, {_error: error.non_field_errors})
        )
      );
    }
  }
}

// アカウント削除
export function* deleteAccount() {
  while (true) {
    const action = yield take(requestAccountDelete);
    //const [data,error] = yield call(fetchDelete, `accounts/${action.payload.id}`);
    //if(data && !error){
    yield put(push(`/co/account/`));
    //}else{
    yield put(setMessageType('error')); //dialog表示
    //}
  }
}

const Account = [
  fork(getAccountList),
  fork(getAccountDetail),
  fork(postAccount),
  fork(patchAccount),
  fork(deleteAccount)
];

export default Account;
