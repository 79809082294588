import {fetchPostJson, fetchPatch, fetchGet, fetchDelete} from './utills/fetch';
import {take, put, fork, call} from 'redux-saga/effects';
import {requestCategoryList, successCategoryList} from '../actions/category';

// カテゴリ一覧取得
export function* getCategoryList() {
  while (true) {
    const action = yield take(requestCategoryList);
    const [data, error] = yield call(fetchGet, 'categories/', false);
    yield put(successCategoryList(data.results));
  }
}

const Category = [fork(getCategoryList)];

export default Category;
