import {all, call, put, takeEvery, take, fork} from 'redux-saga/effects';
import {fetchPatch} from './utills/fetch';
import {PATCH_TASKMODIFY} from '../actions/modify';
import {successPatch} from '../actions/modify';
import {effect, array, iterator, promise, task} from '@redux-saga/is';

// 企業側taskのstatusを5→6にpatch
export function* modifypatch() {
  while (true) {
    const action = yield take(PATCH_TASKMODIFY);
    const status = {status: action.payload.status};
    const [data, error] = yield call(
      fetchPatch,
      `tasks/${action.payload.task}`,
      status
    );
    if (data && !error) {
      yield put(successPatch(data));
    } else {
      console.log('error!');
    }
  }
}

const modify = [fork(modifypatch)];

export default modify;
