import {combineReducers} from 'redux';
import {reducer as formReducer} from 'redux-form';
import {connectRouter} from 'connected-react-router';

// common
import errorBoundary from './errorBoundary';
import errorHandler from './errorHandler';
import company from './company';
import sign from './sign';
import project from './project';
import category from './category';
import task from './task';
import tag from './tag';
import account from './account';
import menu from './menu';
import notification from './notification';
import dialog from './dialog';
import setting from './setting';
import co_setting from './co_setting';
import paginate from './paginate';
import comment from './comment';
import entry from './entry';
import sagaMonitor from './sagaMonitor';
import bookmark from './bookmark';
import payment from './payment';
import format from './format';
import suggest from './suggest';
import personalNews from './personalNews';
import systemNews from './systemNews';
import batch from './batch';
import profile from './profile';
import member from './member';
import taskList from './taskList';
import valuation from './valuation';
import firebase from './firebase';
import message from './message';
import user from './user';
import coTaskList from './coTaskList';
import review from './review';
import complete from './complete';
import rewardAdmin from './rewardAdmin';
import snackbar from './snackbar';
import personalNotification from './personalNotification';
import page from './page';
import common from './common';
import myCompany from './myCompany';

export default history =>
  combineReducers({
    router: connectRouter(history),
    form: formReducer,
    errorBoundary,
    errorHandler,
    company,
    sign,
    task,
    tag,
    project,
    account,
    menu,
    notification,
    dialog,
    setting,
    co_setting,
    category,
    paginate,
    comment,
    entry,
    sagaMonitor,
    bookmark,
    payment,
    format,
    suggest,
    personalNews,
    systemNews,
    batch,
    profile,
    member,
    taskList,
    valuation,
    firebase,
    message,
    user,
    coTaskList,
    review,
    complete,
    personalNotification,
    page,
    rewardAdmin,
    snackbar,
    common,
    myCompany
  });
