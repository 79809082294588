import React from 'react';
import Header from '../Header';
import style from './level.module.scss';
import Divider from '@material-ui/core/Divider';
import ArrowForward from '@material-ui/icons/ArrowForwardIos';
import {withStyles} from '@material-ui/core/styles';
import Logo from '../../../assets/icon_logo.svg';
import Level from '../../../assets/icon_level.svg';

const styles = theme => ({
  root: {
    fontSize: '1rem',
    color: '#093791'
  }
});

const Index = props => {
  const {classes} = props;

  // API叩けるようになるまでのテストデータ
  const langLevels = [
    {
      lang: 'Github',
      level: 20
    },
    {
      lang: 'Ruby on Rails',
      level: 3
    },
    {
      lang: 'Python',
      level: 30
    },
    {
      lang: 'PHP',
      level: 14
    },
    {
      lang: 'Java',
      level: 17
    }
  ];

  return (
    <div className={style.box}>
      <Header headerText={'実績レベル'} />
      <div className={style.rootflexBox}>
        <div className={style.leftBox}>
          <div className={style.main}>
            <div className={style.level}>
              <div className={style.logos}>
                <img src={Logo} />
                <img src={Level} />
              </div>
              <div className={style.levelText}>200</div>
            </div>
          </div>
          <Divider />
          <div className={style.nextLevelSection}>
            <div className={style.nextLevelContent}>
              <span>NEXT LEVEL</span>
            </div>
            <div className={style.progress}>
              <div className={style.progressOver} />
            </div>
          </div>
          <Divider />
          <div className={style.bottomLinkWrapper}>
            <span className={style.bottomLink}>タスクレベルとは</span>{' '}
            <ArrowForward
              classes={{
                root: classes.root
              }}
            />
          </div>
        </div>
        <div className={style.rightBox}>
          <div className={style.flexBoxLang}>
            {langLevels.map((elem, index) => (
              <div className={style.langAndLevel} key={index.toString()}>
                <div className={style.upperBox}>{elem.lang}</div>
                <Divider />
                <div className={style.lowerBox}>
                  <span className={style.lv}>lv.</span>{' '}
                  <span className={style.levelNum}>{elem.level}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(Index);
