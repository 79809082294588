import React from 'react';
import {useHistory} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import {useSelector} from "react-redux"
import styles from './cardButton.module.scss';

const useStyles = makeStyles({
  button: {
    backgroundColor: '#FFF',
    width: '100%',
    height: '100%',
    boxShadow: '0px 0px 6px rgba(9, 55, 145, 0.2)',
    borderRadius: '0.8rem'
  }
});

const CardButton = ({text, link, image}) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Button
      variant="contained"
      onClick={() => history.push(link)}
      className={classes.button}
    >
      <div>
        <img src={image} />
        <div className={styles.text}>{text}</div>
      </div>
    </Button>
  );
};

export default CardButton;
