import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import styles from './coDashboard.module.scss';
import commonStyles from '../../../common/common.module.scss';

import {requestOwnProjectList} from '../../../actions/project';
import {requestMustReviewTasks} from '../../../actions/review';
import {
  requestProcessingTasks,
  requestWantedTasks,
  requestUnderReviewTasks,
  requestDraftTasks,
  requestFailedTasks,
  requestAllTasksCo,
  requestPreAssignedTasks
} from '../../../actions/task';
import {getProjectId, TASK_STATUS} from '../../../common';
import Title from '../../../components/Title';
import CoTaskList from '../../../components/Task/CoTaskList';
import ImportantNotice from '../../../components/ImportantNotice';
import NotAvailable from '../../../components/NotAvailable';
import {requestImportantNotifications} from '../../../actions/notification';
import HelpBtnNextToItem from '../../../components/HelpButtons/HelpBtnNextToItem';
import {topCo} from '../../../components/HelpButtons/constants';
import AboutStatus from '../../../components/AboutStatus';
import Blank from '../../../components/Blank';
import CommonButton from '../../../components/CommonButton';
import {useHistory} from 'react-router';
import PaidAlertSnackbar from '../../../components/snackbars/PaidAlertSnackbar';
import NoDataContainer from '../../../components/NoDataContainer';
import altStyles from '../../../components/NoDataContainer/noDataContainer.module.scss';
import PlusIcon from '../../../assets/icon_plus_white.svg';
import TaskCreateButton from '../../../components/Buttons/TaskCreateButton';

const CoDashboard = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  let mustReviewTaskList = useSelector(state => state.review.tasks);
  let {
    processingTasks,
    wantedTasks,
    draftTasks,
    underReviewTasks,
    allTasksCo,
    requiredPayTasks
  } = useSelector(state => state.task);

  //全てのtaskを取ってくる仕様にするまでの暫定的な処理(それぞれのTaskListのresponseに終了済みのタスクが含まれていたりするので↓でより厳しく除外する。)
  processingTasks = processingTasks.filter(
    ele => ele.status < TASK_STATUS['closed']
  );
  wantedTasks = wantedTasks.filter(ele => ele.status == TASK_STATUS['wanted']);
  draftTasks = draftTasks.filter(ele => ele.status == TASK_STATUS['draft']);
  underReviewTasks = underReviewTasks.filter(
    ele => ele.status == TASK_STATUS['under_review']
  );
  mustReviewTaskList = mustReviewTaskList.filter(
    ele => ele.status == TASK_STATUS['checked']
  );

  const {importantNotifications} = useSelector(state => state.notification);
  const projects = useSelector(state => state.project.list);
  const companyIdSelector = useSelector(state => state.setting.companyId);

  useEffect(() => {
    dispatch(requestFailedTasks());
    dispatch(requestMustReviewTasks());
    dispatch(requestProcessingTasks());
    dispatch(requestWantedTasks());
    dispatch(requestUnderReviewTasks());
    dispatch(requestDraftTasks());
    dispatch(requestAllTasksCo());
    dispatch(requestImportantNotifications());
    dispatch(requestPreAssignedTasks({openModal: true}));
  }, []);

  useEffect(() => {
    if (companyIdSelector !== null) {
      dispatch(requestOwnProjectList());
    }
  }, [companyIdSelector]);

  const isTaskExists =
    mustReviewTaskList.length === 0 &&
    processingTasks.length === 0 &&
    wantedTasks.length === 0 &&
    underReviewTasks.length === 0 &&
    draftTasks.length === 0 &&
    allTasksCo.length === 0;

  return (
    <>
      <PaidAlertSnackbar />
      <AboutStatus />
      <Blank height={'5rem'} />
      {projects && (
        <ProjectStatusHandler
          projects={projects}
          isTaskExists={isTaskExists}
          history={history}
        />
      )}
      {importantNotifications.length ? (
        <div className={styles.noticeWrapper}>
          <ImportantNotice importantNotifications={importantNotifications} />
        </div>
      ) : null}
      <main className={styles.main}>
        {!isTaskExists && <TaskCreateButton projects={projects} />}
        {requiredPayTasks.length !== 0 && (
          <HelpBtnNextToItem text={topCo.requiredPay}>
            <Title component={'h2'} text={'仮払いが必要なタスク'} />
          </HelpBtnNextToItem>
        )}
        <div className={commonStyles.taskList}>
          <CoTaskList task={requiredPayTasks} />
        </div>
        {mustReviewTaskList.length !== 0 && (
          <HelpBtnNextToItem text={topCo.review}>
            <Title component={'h2'} text={'タスクを評価'} />
          </HelpBtnNextToItem>
        )}
        <div className={commonStyles.taskList}>
          <CoTaskList task={mustReviewTaskList} />

          {/* ↓念の為取っておく */}
          {/* {mustReviewTaskList.map((task, index) => {
            return <EvaluationTaskList key={index} index={index} task={task} />;
          })} */}
        </div>
        {processingTasks.length !== 0 && (
          <HelpBtnNextToItem text={topCo.processing}>
            <Title component={'h2'} text={'進行中のタスク'} />
          </HelpBtnNextToItem>
        )}
        <div className={commonStyles.taskList}>
          <CoTaskList task={processingTasks} />
        </div>
        {wantedTasks.length !== 0 && (
          <HelpBtnNextToItem text={topCo.wanted}>
            <Title component={'h2'} text={'募集中のタスク'} />
          </HelpBtnNextToItem>
        )}
        <div className={commonStyles.taskList}>
          <CoTaskList task={wantedTasks} />
        </div>

        <div className={styles.title}>
          {underReviewTasks.length !== 0 && (
            <HelpBtnNextToItem text={topCo.underReview}>
              <Title component={'h2'} text={'審査中のタスク'} />
            </HelpBtnNextToItem>
          )}
        </div>
        <div className={commonStyles.taskList}>
          <CoTaskList task={underReviewTasks} />
        </div>
        <div className={styles.title}>
          {draftTasks.length !== 0 && (
            <HelpBtnNextToItem text={topCo.draft}>
              <Title component={'h2'} text={'下書き中のタスク'} />
            </HelpBtnNextToItem>
          )}
        </div>
        <div className={commonStyles.taskList}>
          <CoTaskList task={draftTasks} />
        </div>
        <div className={styles.title}>
          {allTasksCo.length !== 0 && (
            <HelpBtnNextToItem text={topCo.all}>
              <Title component={'h2'} text={'全てのタスク'} />
            </HelpBtnNextToItem>
          )}
        </div>
        <div className={commonStyles.taskList}>
          <CoTaskList task={allTasksCo} />
        </div>
      </main>
    </>
  );
};

const ProjectStatusHandler = ({projects, isTaskExists, history}) => {
  const projectId = getProjectId(projects);
  return (
    <>
      {projects.length === 0 && <NotAvailable kind="project-create" />}
      {projects.length > 0 && isTaskExists && (
        <NoDataContainer>
          <p className={altStyles.title}>
            さっそくタスクを作成して、お仕事を発注しましょう
          </p>
          <Blank height={'2.4rem'} />
          <CommonButton
            text={'新しいタスクを作成する'}
            size={'large'}
            color={'blue'}
            variant={'contained'}
            shape={'radius'}
            onClick={() => history.push(`/co/project/${projectId}/task/create`)}
            disabled={false}
            icon={PlusIcon}
          />
        </NoDataContainer>
      )}
    </>
  );
};

export default CoDashboard;
