import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {requestCompanyEdit} from '../../../actions/company';
import Edit from '../Edit';

import {handleRemoveImg} from '../../../common';

const Index = props => {
  const companyId = useSelector(state => state.setting.companyId);
  const dispatch = useDispatch();
  const company = useSelector(state => state.myCompany);
  const {isImgChanged} = useSelector(state => state.common);

  const {
    name,
    company_name_kana,
    representative_sei,
    representative_mei,
    representative_sei_kana,
    representative_mei_kana,
    prefecture,
    address1,
    address2,
    address3,
    tel,
    url1,
    introduce,
    facebook,
    twitter,
    note,
    qiita,
    img
  } = company;

  const handleSubmit = (values, _, props) => {
    values = handleRemoveImg(values, isImgChanged);
    dispatch(requestCompanyEdit({values, companyId}));
  };

  return (
    <Edit
      onSubmit={handleSubmit}
      initialValues={{
        name,
        company_name_kana,
        representative_sei,
        representative_mei,
        representative_sei_kana,
        representative_mei_kana,
        prefecture,
        address1,
        address2,
        address3,
        tel,
        url1,
        introduce,
        facebook,
        twitter,
        note,
        qiita,
        img
      }}
      {...props}
    />
  );
};

export default Index;
