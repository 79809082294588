import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import style from './button.module.scss';
import PinWhite from '../../../../assets/icon_pin_white.svg';
import PinBlue from '../../../../assets/icon_pin_blue.svg';
import Clear from '../../../../assets/icon_clear.svg';

const Index = props => {
  const {kind, onClickHandler} = props;
  let applyBgColor = '';
  let applyHoverColor = '';
  let applyBorderColor = 'none';
  let valiant = 'contained';
  let applyColor = '#FFFFFF';
  let applyFontWeight = '';
  let text = '';
  let srcImg = null;
  let applyFontSize = '1.6rem';
  let disabled = false;
  let disableRipple = false;

  switch (kind) {
    case 'pinkOutlined':
      valiant = 'outlined';
      applyBorderColor = '0.1rem solid #EA397D';
      applyColor = '#EA397D';
      text = '確認依頼を取り消す';
      applyHoverColor = '#ffe7ef';
      applyFontSize = '1.4rem';
      disableRipple = false;
      disabled = false;
      break;

    case 'gray':
      applyColor = 'rgba(0, 0, 0, 0.26)';
      applyBgColor = 'rgba(0, 0, 0, 0.12)';
      applyHoverColor = '#1b4a71';
      applyFontWeight = 'bold';
      text = '企業が確認中';
      disableRipple = true;
      disabled = true;
      break;
  }
  const useStyles = makeStyles(theme => ({
    button: {
      margin: theme.spacing(1),
      backgroundColor: applyBgColor,
      border: applyBorderColor,
      color: applyColor,
      fontWeight: applyFontWeight,
      width: '20rem',
      height: '5rem',
      fontSize: applyFontSize,
      '&:hover': {
        backgroundColor: applyHoverColor
      },
      disabled: disabled
    }
  }));

  const classes = useStyles();
  return (
    <div className="stage">
      <Button
        variant={valiant}
        className={classes.button}
        onClick={() => onClickHandler()}
        disabled={disabled}
        disableRipple={disableRipple}
      >
        <div className={style.buttonContainer}>
          <img src={srcImg} />
          <div className={style.text}>{text}</div>
        </div>
      </Button>
    </div>
  );
};

export default Index;
