import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {useParams, useLocation, useHistory} from 'react-router-dom';

import {requestPasswordResetConfirm} from '../../actions/reset';
import PasswordResetConfirmForm from '../../components/Form/PasswordResetConfirmForm';
import PanelLayout from '../../containers/layout/PanelLayout';

import style from './passwordResetConfirm.module.scss';
import CommonModal from '../Modal/CommonModal';

const handleClick = (values, uid, token, dispatch) => {
  dispatch(
    requestPasswordResetConfirm({
      new_password1: values.new_password1,
      new_password2: values.new_password2,
      uid: uid,
      token: token
    })
  );
};

const PasswordResetConfirm = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  try {
    const queryParams = location.search.split('&');
    const [uid, token] = queryParams.map(param => param.split('=')[1]);
    return (
      <PanelLayout>
        <div className={style.container}>
          <div className={style.subTitle}>新しいパスワードを設定する</div>
          <PasswordResetConfirmForm
            onSubmit={values => handleClick(values, uid, token, dispatch)}
          />
        </div>
        <CommonModal />
      </PanelLayout>
    );
  } catch (e) {
    // TODO: 不正なアクセスです 系の エラー
    history.push('/sorry');
  }
};

export default PasswordResetConfirm;
