import {createAction} from 'redux-actions';

export const REQUEST_CUSTOM_TOKEN = 'REQUEST_CUSTOM_TOKEN';
export const requestCustomToken = createAction(REQUEST_CUSTOM_TOKEN);
export const SUCCESS_CUSTOM_TOKEN = 'SUCCESS_CUSTOM_TOKEN';
export const successCustomToken = createAction(SUCCESS_CUSTOM_TOKEN);

// ログイン状態をセット
export const SET_LOGGEDIN = 'SET_LOGGEDIN';
export const setLoggedIn = createAction(SET_LOGGEDIN);

// ログイン状態の確認
export const REQUEST_FIREBASE_LOGIN_STATUS = 'REQUEST_FIREBASE_LOGIN_STATUS';
export const requestFirebaseLoginStatus = createAction(
  REQUEST_FIREBASE_LOGIN_STATUS
);
export const SUCEESS_FIREBASE_LOGIN_STATUS = 'SUCEESS_FIREBASE_LOGIN_STATUS';
export const successFirebaseLoginStatus = createAction(
  SUCEESS_FIREBASE_LOGIN_STATUS
);

export const REQUEST_ADDITIONAL_CLAIMS = 'REQUEST_ADDITIONAL_CLAIMS';
export const requestAdditionalClaims = createAction(REQUEST_ADDITIONAL_CLAIMS);
export const SUCCESS_ADDITIONAL_CLAIMS = 'REQUEST_ADDITIONAL_CLAIMS';
export const successAdditionalClaims = createAction(SUCCESS_ADDITIONAL_CLAIMS);

export const REQUEST_NOTIFICATION_LISTENERS = 'REQUEST_NOTIFICATION_LISTENERS';
export const requestNotificationListeners = createAction(
  REQUEST_NOTIFICATION_LISTENERS
);
