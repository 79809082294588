import React from 'react';
import {useSelector} from 'react-redux';
import {useLocation, useParams} from 'react-router-dom';
import styles from './commonLayout.module.scss';

import AppBar from '../../../components/MenuAppBar';
import CoMenu from '../../layout/CoMenu';
import EnMenu from '../../layout/EnMenu';
import Breadcrumbs from '../../../components/Breadcrumbs';
import FooterPage from '../../../components/Footer/FooterPage';
import CommonModal from '../../../components/Modal/CommonModal';
import RegisterFooter from '../../../components/RegisterFooter';

const DEFAULT_LAYOUT = 'DEFAULT_LAYOUT';
const FULL_SCREEN_LAYOUT = 'FULL_SCREEN_LAYOUT';
const PUBLIC_LAYOUT = 'PUBLIC_LAYOUT';

// レイアウトで囲まない path のリスト
const FULL_SCREEN_URLS = [
  new RegExp('/co-signup'),
  new RegExp('/co-signup/preview'),
  new RegExp('/co-signup/result'),
  new RegExp('/co/member/[a-z\\d]*'),
  new RegExp('/co/firstuser/[a-z\\d]*'),
  new RegExp('/faq'),
  new RegExp('/sctl'),
  new RegExp('/activate/firstuser/'),
  new RegExp('/activate/member/'),
  new RegExp('/accept-termsofservice'),
  new RegExp('/contact')
];

const PUBLIC_LAYOUT_URLS = [new RegExp('/public*.')];

const layoutKind = pathname => {
  let kind = DEFAULT_LAYOUT;
  FULL_SCREEN_URLS.forEach(item => {
    if (pathname.match(item)) {
      kind = FULL_SCREEN_LAYOUT;
    }
  });
  PUBLIC_LAYOUT_URLS.forEach(item => {
    if (pathname.match(item)) {
      kind = PUBLIC_LAYOUT;
    }
  });
  return kind;
};

const isMessageScreen = pathname => {
  return !!pathname.match(new RegExp('/[a-z]*/message$'));
};

const CommonLayout = ({children}) => {
  const location = useLocation();
  const pathname = location.pathname;
  const isEngineer = useSelector(state => state.setting.isEngineer);
  switch (layoutKind(pathname)) {
    case DEFAULT_LAYOUT:
      return (
        <>
          <AppBar />
          <CommonModal />
          <div>
            <div className={styles.blank} />
            <div
              className={
                isMessageScreen(pathname)
                  ? styles.messageContainer
                  : styles.normalContainer
              }
            >
              {isEngineer ? <EnMenu /> : <CoMenu />}
              <div
                className={
                  isMessageScreen(pathname)
                    ? styles.subMessageContainer
                    : styles.subNormalContainer
                }
              >
                {isMessageScreen(pathname) ? null : (
                  <Breadcrumbs isEngineer={isEngineer} currentPath={pathname} />
                )}
                <div
                  className={
                    isMessageScreen(pathname)
                      ? styles.messageContent
                      : styles.normalContent
                  }
                >
                  <div
                    className={
                      isMessageScreen(pathname) ? 'false' : styles.inner
                    }
                  >
                    {children}
                  </div>
                  {isMessageScreen(pathname) ? null : (
                    <FooterPage isEngineer={isEngineer} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      );
    case FULL_SCREEN_LAYOUT:
      return (
        <>
          <div>
            <div className={styles.dontUseContainer}>
              <div className={styles.content}>{children}</div>
            </div>
          </div>
        </>
      );
    case PUBLIC_LAYOUT:
      return (
        <>
          <AppBar />
          <div className={styles.blank} />
          <div className={styles.inner}>{children}</div>
          <FooterPage />
          <RegisterFooter />
        </>
      );
    default:
      return null;
  }
};

export default CommonLayout;
