import React from 'react';
import {createMuiTheme, makeStyles} from '@material-ui/core/styles';
import {MuiThemeProvider} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import style from './evalButton.module.scss';

const useStyles = makeStyles(theme => ({
  button: {
    width: '30rem',
    height: '5rem'
  },
  input: {
    display: 'none'
  }
}));

const theme = createMuiTheme({
  overrides: {
    MuiButton: {
      contained: {
        color: '#FFFFFF',
        fontWeight: 'bold',
        backgroundColor: '#EA397D'
      },
      root: {
        fontSize: '1.6rem'
      }
    }
  }
});

const Index = ({text}) => {
  const classes = useStyles();

  return (
    <MuiThemeProvider theme={theme}>
      <Button variant="contained" className={classes.button} type={'submit'}>
        <div className={style.text}>{text}</div>
      </Button>
    </MuiThemeProvider>
  );
};

export default Index;
