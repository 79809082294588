import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation
} from 'react-router-dom';

import MenuAppBar from '../MenuAppBar';
import PageSubTitle from '../PageSubTitle';
import Nav from './Nav';
import Content from './Content';
import styles from './faq.module.scss';
import CommonLayout from '../../containers/layout/CommonLayout';

import {contents} from './constants';

const FAQ = () => {
  return (
    <CommonLayout>
      <MenuAppBar />
      <div className={styles.pageTitleContainer}>
        <PageSubTitle title="よくある質問" />
      </div>
      <div className={styles.container}>
        <div className={styles.inner}>
          <div className={styles.nav}>
            <Nav contents={contents} />
          </div>
          <div className={styles.main}>
            <Switch>
              {contents.map((content, index) => (
                <Route exact={true} key={index.toString()} path={content.path}>
                  <Content header={content.text}>{content.component}</Content>
                </Route>
              ))}
            </Switch>
          </div>
        </div>
      </div>
    </CommonLayout>
  );
};

export default FAQ;
