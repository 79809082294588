import {createAction} from 'redux-actions';

export const IS_SHOW_ENTRY_SNACKBAR = 'IS_SHOW_ENTRY_SNACKBAR';
export const isShowEntrySnackbar = createAction(IS_SHOW_ENTRY_SNACKBAR);

export const IS_SHOW_BOOKMARK_SNACKBAR = 'IS_SHOW_BOOKMARK_SNACKBAR';
export const isShowBookmarkSnackbar = createAction(IS_SHOW_BOOKMARK_SNACKBAR);

export const SET_SNACKBAR_TYPE = 'SET_SNACKBAR_TYPE';
export const setSnackbarType = createAction(SET_SNACKBAR_TYPE);

export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
export const enqueueSnackbar = notification => {
  const key = notification.options && notification.options.key;

  return {
    type: ENQUEUE_SNACKBAR,
    notification: {
      ...notification,
      key: key || new Date().getTime() + Math.random()
    }
  };
};

export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';
export const removeSnackbar = key => ({
  type: REMOVE_SNACKBAR,
  key
});

export const SET_LATEST_NEWS_ID = 'SET_LATEST_NEWS_ID';
export const setLatestNewsId = createAction(SET_LATEST_NEWS_ID);
