import React from 'react';
import {Field, reduxForm, change, Form} from 'redux-form';
import {makeStyles} from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';

import styles from './accountEmailPassword.module.scss';
import PageTitle from '../../../components/PageTitle';
import RequiredForm from '../../../components/Form/RequiredForm';
import SubmitButton from '../../../components/Button/SubmitButton';
import {fetchPatch} from '../../../sagas/utills/fetch';

const useStyles = makeStyles({
  controlLabel: {
    marginLeft: '23rem'
  },
  checkbox: {
    width: 'auto',
    color: '#DDD'
  }
});

const AccountEmailPassword = props => {
  const classes = useStyles();
  const {handleSubmit, pristine, reset, submitting} = props;

  const fetch = data => {
    // fetchPatch(URL ,data )
    // To Dos
    // フォームに入力されたdataを使ってAPI叩く
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.main}>
            <PageTitle title="アカウント設定" />
            <article>
              <h2>メールアドレス・パスワード編集</h2>
              <p className={styles.text}>
                メールアドレスまたはパスワードの編集には、『現在のパスワード』の入力が必須です。
              </p>

              <form
                onSubmit={handleSubmit(data => {
                  fetch(data);
                })}
              >
                <div className={styles.formItem}>
                  <Field
                    name="e-mail"
                    type="text"
                    title="メールアドレス"
                    required={true}
                    component={RequiredForm}
                  />
                  <FormControlLabel
                    className={classes.controlLabel}
                    control={
                      <Checkbox className={classes.checkbox} value="checkedC" />
                    }
                    label={
                      <p className={styles.formControlLabel}>
                        メール配信を希望する
                      </p>
                    }
                  />
                </div>
                <div className={styles.formItem}>
                  <Field
                    name="password"
                    type="password"
                    title="現在のパスワード"
                    required={true}
                    component={RequiredForm}
                  />
                </div>

                <div className={styles.formItem}>
                  <Field
                    name="new_password"
                    type="password"
                    title="新しいパスワード"
                    required={false}
                    placeholder="半角英から6文字から30文字以内"
                    component={RequiredForm}
                  />
                </div>

                <div className={styles.formItem}>
                  <Field
                    name="confirm_password"
                    type="password"
                    title="新しいパスワード(確認用)"
                    placeholder="半角英から6文字から30文字以内"
                    required={false}
                    component={RequiredForm}
                  />
                </div>

                <div className={styles.buttonWrapper}>
                  <Button
                    className={styles.submitButton}
                    type="submit"
                    variant="contained"
                    color="secondary"
                    disabled={pristine || submitting}
                  >
                    {'保存'}
                  </Button>
                </div>
              </form>
            </article>
          </div>
        </div>
      </div>
    </>
  );
};

export default reduxForm({form: 'content'})(AccountEmailPassword);
