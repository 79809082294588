import React from 'react';
import {useDispatch} from 'react-redux';

import styles from './coLogin.module.scss';
import LOGO from '../../assets/logo_white.svg';
import CommonLayout from '../../containers/layout/CommonLayout';
import {requestMemberActivation} from '../../actions/sign';
import FormContent from './formContent';
import Copyright from '../Copyright';

const CoLogin = ({match}) => {
  const isMember = 'memberToken' in match.params;
  let token = isMember ? match.params.memberToken : match.params.firstToken;
  const dispatch = useDispatch();

  const handleSubmit = value => {
    dispatch(
      requestMemberActivation({
        formData: value,
        token: token
      })
    );
  };

  return (
    <CommonLayout>
      <div className={styles.background}>
        <div className={styles.logo}>
          <img src={LOGO} />
        </div>
        <div className={styles.main}>
          <div className={styles.inner}>
            <p className={styles.title}>
              {isMember
                ? '個人情報を入力してください'
                : 'パスワードを設定してください'}
            </p>
            <FormContent onSubmit={handleSubmit} isMember={isMember} />
          </div>
        </div>
        <div className={styles.copyright}>
          <Copyright />
        </div>
      </div>
    </CommonLayout>
  );
};

export default CoLogin;
