import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {setError, setErrorInfo} from '../actions/errorBoundary';
import styles from './errorBoundary.module.scss';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
  }

  componentDidCatch(error, errorInfo) {
    this.props.setError(error);
    this.props.setError(errorInfo);
  }

  render() {
    const {error, errorInfo} = this.props.errorBoundary;
    if (error) {
      return (
        <div>
          <section className={styles.background}>
            <div className={styles.container}>
              <h3 className={styles.notFound}>エラーが発生しました</h3>
              <h2 className={styles.header}>少々お待ちください</h2>
            </div>
          </section>

          {/*<div style={{ whiteSpace: 'pre-wrap' }}>*/}
          {/*{error && error.toString()}*/}
          {/*<br />*/}
          {/*{error.componentStack}*/}
          {/*</div>*/}
        </div>
      );
    }
    return this.props.children;
  }
}

const mapStateToProps = state => state;

const mapDispatchToProps = dispatch => ({
  setError(bool) {
    dispatch(setError(bool));
  },
  setErrorInfo(bool) {
    dispatch(setErrorInfo(bool));
  }
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary)
);
