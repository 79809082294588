import {createAction} from 'redux-actions';

// 企業ユーザ情報を一件取得
export const REQUEST_CO_USER_INFO = 'REQUEST_CO_USER_INFO';
export const SUCCESS_CO_USER_INFO = 'SUCCESS_CO_USER_INFO';
export const requestCoUserInfo = createAction(REQUEST_CO_USER_INFO);
export const successCoUserInfo = createAction(SUCCESS_CO_USER_INFO);

// 企業ユーザ情報をトークルームに紐づいている人数分複数取得
export const REQUEST_CO_USERS_INFO = 'REQUEST_CO_USERS_INFO';
export const SUCCESS_CO_USERS_INFO = 'SUCCESS_CO_USERS_INFO';
export const requestCoUsersInfo = createAction(REQUEST_CO_USERS_INFO);
export const successCoUsersInfo = createAction(SUCCESS_CO_USERS_INFO);

// エンジニアユーザ情報を一件取得
export const REQUEST_EN_USER_INFO = 'REQUEST_EN_USER_INFO';
export const SUCCESS_EN_USER_INFO = 'SUCCESS_EN_USER_INFO';
export const requestEnUserInfo = createAction(REQUEST_EN_USER_INFO);
export const successEnUserInfo = createAction(SUCCESS_EN_USER_INFO);

// エンジニアユーザ情報をトークルームに紐づいている人数分複数取得
export const REQUEST_EN_USERS_INFO = 'REQUEST_EN_USERS_INFO';
export const SUCCESS_EN_USERS_INFO = 'SUCCESS_EN_USERS_INFO';
export const requestEnUsersInfo = createAction(REQUEST_EN_USERS_INFO);
export const successEnUsersInfo = createAction(SUCCESS_EN_USERS_INFO);

// エンジニアユーザ一覧を取得
export const REQUEST_SEARCH_ENGINEERS = 'REQUEST_SEARCH_ENGINEERS';
export const requestSearchEngineers = createAction(REQUEST_SEARCH_ENGINEERS);
export const SUCCESS_SEARCH_ENGINEERS = 'SUCCESS_SEARCH_ENGINEERS';
export const successSearchEngineers = createAction(SUCCESS_SEARCH_ENGINEERS);
