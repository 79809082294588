import {
  SUCCESS_TAG,
  CLEAR_TAG,
  SET_TAG
} from '../actions/tag';

const initialState = {
  isFetching: false,
  list: [],
  value: ''
};

function tag(state = initialState, action) {
  switch (action.type) {
    case SUCCESS_TAG:
      return {
        ...state,
        list: action.payload
      }
    case CLEAR_TAG:
      return {
        ...state,
        list: []
      }
    case SET_TAG:
      return {
        ...state,
        value: action.payload
      }
    default:
      return state
  }
}

export default tag