import React from 'react';
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles';

const theme = createMuiTheme({
  shadows: Array(25).fill('none'),
  palette: {
    primary: {
      main: '#093791'
    },
    secondary: {
      main: '#EA397D',
      contrastText: '#fff'
    }
  },
  typography: {
    fontSize: 'initial'
  },
  overrides: {
    MuiPaper: {
      root: {
        backgroundColor: '#FFFFFF',
        border: '0.1rem solid #d7e2f4',
        '&:hover': {
          backgroundColor: '#FFFFFF',
          border: '0.1rem solid #d7e2f4'
        }
      }
    },
    MuiTab: {
      root: {
        maxWidth: 'none',
        minWidth: 'none'
      },
      textColorInherit: {
        color: '#00A8F0',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '2.6rem'
      }
    },
    MuiButton: {
      root: {
        textTransform: 'none'
      }
    },
    MuiMenuItem: {
      root: {
        fontSize: '1.6rem'
      }
    },
    MuiCard: {
      root: {
        backgroundColor: 'FFFFFF',
        boxShadow: 'none',
        borderLeft: '0.1rem solid #d7e2f4',
        borderRight: '0.1rem solid #d7e2f4',
        borderBottom: '0.1rem solid #d7e2f4',
        borderTop: 'none',
        '&:first-child': {
          borderTop: '0.1rem solid #D7E2F4'
        },
        '&:hover': {
          boxShadow: 'none',
          backgroundColor: '#FFFEEE',
          borderLeft: '0.1rem solid #d7e2f4',
          borderRight: '0.1rem solid #d7e2f4',
          borderBottom: '0.1rem solid #d7e2f4',
          borderTop: 'none',
          '&:first-child': {
            borderTop: '0.1rem solid #D7E2F4'
          }
        }
      }
    },
    MuiCardActionArea: {
      focusHighlight: {
        backgroundColor: 'transparent'
      }
    },
    MuiList: {
      padding: {
        paddingTop: 0,
        paddingBottom: 0
      }
    },
    MuiPickersToolbarText: {
      toolbarTxt: {
        fontSize: '2rem'
      }
    },
    MuiPickersDatePickerRoot: {
      toolbar: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-evenly'
      }
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#093791',
        height: '6rem'
      }
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: '#093791'
      }
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: '#F7F9FC',
        padding: '2rem'
      }
    }
  }
});

const WithMuiThemeProvider = ({children}) => {
  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
};

export default WithMuiThemeProvider;
