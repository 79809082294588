import React from 'react';
import style from '../contacts.module.scss';
import SubTitle from '../../../SubTitle';
import classNames from 'classnames';
import {Field, reduxForm} from 'redux-form';
import {renderSelect, renderText, renderTextArea} from '../../../Field';
import * as Validator from '../../utils/validate';
import Divider from '@material-ui/core/Divider';
import CommonButton from '../../../CommonButton';
import {makeStyles} from '@material-ui/core';
import useScrollToTop from '../../../../hooks/useScrollToTop';
import MenuItem from '@material-ui/core/MenuItem';
import Blank from '../../../Blank';
import Link from '@material-ui/core/Link';
import {useHistory} from 'react-router';

const useStyles = makeStyles({
  divider: {
    margin: '0 3rem'
  }
});

const ContactForm = ({pristine, submitting, handleSubmit}) => {
  const classes = useStyles();
  const history = useHistory();

  useScrollToTop();
  return (
    <form onSubmit={handleSubmit}>
      <div className={style.descriptionContainer}>
        <p className={style.description}>
          Task
          について詳細な資料をご希望のお客様は、下記に情報を入力いただいた上で資料のダウンロードが可能です。
        </p>
        <br />
        <p>
          御不明な点があればお気軽にお問い合わせ下さい、担当者が速やかに御対応致します。
        </p>
      </div>
      <Blank height={'4rem'} />
      <div className={style.mainContent}>
        <div className={style.main}>
          <SubTitle headerText={'資料ダウンロードフォーム'} />
          <div className={style.mainCategory}>
            <Divider classes={{root: classes.divider}} />
            <div className={classNames(style.row, style.itemPadding)}>
              <div className={classNames(style.rowTitle, style.CategoryText)}>
                <p>企業名</p>
              </div>
              <div className={style.rowRequire}>
                <label className={style.required}>必須</label>
              </div>
              <div className={style.rowInputFullWidth}>
                <Field
                  name="company_name"
                  required={true}
                  component={renderText}
                  fullWidth={true}
                  validate={[Validator.RequiredItem]}
                  placeholder={'例：株式会社m-Lab'}
                />
              </div>
            </div>
            <Divider classes={{root: classes.divider}} />
            <div className={classNames(style.row, style.itemPadding)}>
              <div className={classNames(style.rowTitle, style.CategoryText)}>
                <p>担当者名</p>
              </div>
              <div className={style.rowRequire}>
                <label className={style.required}>必須</label>
              </div>
              <div className={style.rowInputFullWidth}>
                <Field
                  name="sender"
                  required={true}
                  component={renderText}
                  fullWidth={true}
                  validate={[Validator.RequiredItem]}
                  placeholder={'田中 太郎'}
                />
              </div>
            </div>
            <Divider classes={{root: classes.divider}} />
            <div className={classNames(style.row, style.itemPadding)}>
              <div className={classNames(style.rowTitle, style.CategoryText)}>
                <p>Eメール</p>
              </div>
              <div className={style.rowRequire}>
                <label className={style.required}>必須</label>
              </div>
              <div className={style.rowInputFullWidth}>
                <Field
                  name="email"
                  required={true}
                  component={renderText}
                  fullWidth={true}
                  validate={[Validator.RequiredItem]}
                  placeholder={'example@mlabs.jp'}
                />
              </div>
            </div>
            <Divider classes={{root: classes.divider}} />
          </div>
        </div>
      </div>
      <div className={style.submits}>
        <div className={style.submit}>
          <CommonButton
            type={'submit'}
            text={'資料のダウンロード'}
            size={'medium'}
            color={'blue'}
            variant={'contained'}
            shape={'normal'}
            disabled={pristine || submitting}
          />
        </div>
      </div>
    </form>
  );
};

export default reduxForm({
  form: 'contact'
})(ContactForm);
