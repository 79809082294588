import React from 'react';
import CoMenuLayout from '../../layout/CoMenuLayout'
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Title from "../../../components/Title/index";
import NotificationList from "../../../components/Notification/List"
import { requestNotificationList } from '../../../actions/notification'

class Index extends React.Component {

  componentDidMount() {
    this.props.requestNotificationList()
  }

  render() {
    const { list } = this.props
    return (
      <CoMenuLayout>
        <Title component="h2" text='お知らせ'/>
        <NotificationList list={list}/>
      </CoMenuLayout>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  requestNotificationList(value) {
    dispatch(requestNotificationList(value));
  }
});

const mapStateToProps = (state) => {
  return {
    list: state.notification.list
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Index));
