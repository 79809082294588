import React, {Component, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {
  reduxForm,
  getFormSubmitErrors,
  getFormValues,
  formValues,
  formValueSelector
} from 'redux-form';
import {makeSubCategoryList} from './utils';
// components
import ProjectForm from '../../../components/Form/co/ProjectForm/ProjectForm';
import MainTitle from '../../../components/MainTitle';
// actions
import {
  requestProjectDetail,
  requestProjectEdit
} from '../../../actions/project';
import {requestCategoryList} from '../../../actions/category';

const Edit = props => {
  const params = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(requestProjectDetail(params.id));
    dispatch(requestCategoryList());
  }, []);

  const categoryList = useSelector(state => state.category.list);
  const detail = useSelector(state => state.project.detail);
  const submitErrors = useSelector(state =>
    getFormSubmitErrors('projects')(state)
  );
  const formValues = useSelector(state => {
    return getFormValues('projects')(state);
  });

  let subCategoryList = makeSubCategoryList(categoryList, formValues);

  return (
    <>
      <MainTitle titleStr={'プロジェクト編集'} pattern={true} />
      <ProjectForm
        {...props}
        initialValues={detail}
        detail={detail}
        categoryList={categoryList}
        subCategoryList={subCategoryList}
        // formValues={projects}
        submitErrors={submitErrors}
        onSubmit={value => {
          dispatch(
            requestProjectEdit({
              projectId: value.project_id,
              title: value.title,
              description: value.description,
              main_category_id: value.main_category_id
            })
          );
        }}
        submitText={'保存する'}
      />
    </>
  );
};

export default Edit;
