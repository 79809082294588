import React from 'react';
import style from '../contacts.module.scss';
import SubTitle from '../../../SubTitle';
import classNames from 'classnames';
import {Field, reduxForm} from 'redux-form';
import {renderSelect, renderText, renderTextArea} from '../../../Field';
import * as Validator from '../../utils/validate';
import Divider from '@material-ui/core/Divider';
import CommonButton from '../../../CommonButton';
import {makeStyles} from '@material-ui/core';
import useScrollToTop from '../../../../hooks/useScrollToTop';
import MenuItem from '@material-ui/core/MenuItem';
import Blank from '../../../Blank';
import Link from '@material-ui/core/Link';
import {useHistory} from 'react-router';

const useStyles = makeStyles({
  divider: {
    margin: '0 3rem'
  },
  link: {
    fontSize: '2.4rem'
  }
});

const CONTACT_TYPES = [
  {id: 1, name: 'お仕事の発注でのご相談'},
  {id: 2, name: 'サービスの詳細な説明を希望'},
  {id: 3, name: 'その他'}
];

const ContactForm = ({pristine, submitting, handleSubmit}) => {
  const classes = useStyles();
  const history = useHistory();

  useScrollToTop();
  return (
    <form onSubmit={handleSubmit}>
      <div className={style.descriptionContainer}>
        <Link
          classes={{root: classes.link}}
          onClick={e => {
            history.push('/contact/download');
            e.preventDefault();
          }}
          href="#"
        >
          ▶ 資料のダウンロードをご希望の方はこちら
        </Link>
        <Blank height={'2rem'} />
        <p className={style.description}>
          お仕事の発注でのご相談・サービスの詳細なご説明 をご希望のお客様は、
          下記より情報をお送りいただければ、専任担当がオンラインで速やかにご対応いたします。
        </p>
        <Blank height={'1.5rem'} />
        <p className={style.description}>
          オンラインでのお打ち合わせは30分前後を想定しております。
        </p>
      </div>
      <Blank height={'3rem'} />
      <div className={style.mainContent}>
        <div className={style.main}>
          <SubTitle headerText={'お問い合わせ内容'} />
          <div className={style.mainCategory}>
            <div className={classNames(style.row, style.itemPadding)}>
              <div className={classNames(style.rowTitle, style.CategoryText)}>
                <p>お問い合わせの種類</p>
              </div>
              <div className={style.rowRequire}>
                <label className={style.required}>必須</label>
              </div>
              <div className={style.rowInputFullWidth}>
                <Field
                  name="contact_type"
                  required={true}
                  component={renderSelect}
                  validate={[Validator.RequiredChoice]}
                >
                  <MenuItem disabled value={'default'}>
                    選択してください
                  </MenuItem>
                  {CONTACT_TYPES.map((item, index) => (
                    <MenuItem value={item.id} key={index}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Field>
              </div>
            </div>
            <Divider classes={{root: classes.divider}} />
            <div className={classNames(style.row, style.itemPadding)}>
              <div className={classNames(style.rowTitle, style.CategoryText)}>
                <p>企業名</p>
              </div>
              <div className={style.rowRequire}>
                <label className={style.required}>必須</label>
              </div>
              <div className={style.rowInputFullWidth}>
                <Field
                  name="company_name"
                  required={true}
                  component={renderText}
                  fullWidth={true}
                  validate={[Validator.RequiredItem]}
                  placeholder={'例：株式会社m-Lab'}
                />
              </div>
            </div>
            <Divider classes={{root: classes.divider}} />
            <div className={classNames(style.row, style.itemPadding)}>
              <div className={classNames(style.rowTitle, style.CategoryText)}>
                <p>担当者名</p>
              </div>
              <div className={style.rowRequire}>
                <label className={style.required}>必須</label>
              </div>
              <div className={style.rowInputFullWidth}>
                <Field
                  name="sender"
                  required={true}
                  component={renderText}
                  fullWidth={true}
                  validate={[Validator.RequiredItem]}
                  placeholder={'田中 太郎'}
                />
              </div>
            </div>
            <Divider classes={{root: classes.divider}} />
            <div className={classNames(style.row, style.itemPadding)}>
              <div className={classNames(style.rowTitle, style.CategoryText)}>
                <p>Eメール</p>
              </div>
              <div className={style.rowRequire}>
                <label className={style.required}>必須</label>
              </div>
              <div className={style.rowInputFullWidth}>
                <Field
                  name="email"
                  required={true}
                  component={renderText}
                  fullWidth={true}
                  validate={[Validator.RequiredItem]}
                  placeholder={'example@mlabs.jp'}
                />
              </div>
            </div>
            <Divider classes={{root: classes.divider}} />
            <div className={classNames(style.row, style.itemPadding)}>
              <div className={classNames(style.rowTitle, style.CategoryText)}>
                <p>電話番号</p>
              </div>
              <div className={style.rowRequire}>
                <label className={style.required}>必須</label>
              </div>
              <div className={style.rowInputFullWidth}>
                <Field
                  name="tel1"
                  required={true}
                  component={renderText}
                  fullWidth={true}
                  validate={[Validator.RequiredItem]}
                  placeholder={'03'}
                />
              </div>
              <p className={style.hyphen}>-</p>
              <div className={style.rowInputFullWidth}>
                <Field
                  name="tel2"
                  required={true}
                  component={renderText}
                  fullWidth={true}
                  validate={[Validator.RequiredItem]}
                  placeholder={'1234'}
                />
              </div>
              <p className={style.hyphen}>-</p>
              <div className={style.rowInputFullWidth}>
                <Field
                  name="tel3"
                  required={true}
                  component={renderText}
                  fullWidth={true}
                  validate={[Validator.RequiredItem]}
                  placeholder={'5678'}
                />
              </div>
            </div>
            <Divider classes={{root: classes.divider}} />
            <div className={classNames(style.rowOverview, style.itemPadding)}>
              <div className={classNames(style.rowTitle, style.CategoryText)}>
                <p>内容</p>
              </div>
              <div className={style.rowRequire}>
                <label className={style.required}>必須</label>
              </div>
              <div className={style.rowInputFullWidth}>
                <Field
                  name="content"
                  required={true}
                  component={renderTextArea}
                  fullWidth={true}
                  validate={[Validator.RequiredItem]}
                />
              </div>
            </div>
            <Divider classes={{root: classes.divider}} />
          </div>
        </div>
      </div>
      <div className={style.submits}>
        <div className={style.submit}>
          <CommonButton
            type={'submit'}
            text={'送信'}
            size={'medium'}
            color={'blue'}
            variant={'contained'}
            shape={'normal'}
            disabled={pristine || submitting}
          />
        </div>
      </div>
    </form>
  );
};

export default reduxForm({
  form: 'contact'
})(ContactForm);
