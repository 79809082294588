import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {requestPasswordReset} from '../../actions/reset';
import Button from '../Buttons/ConfirmButton/Button';
import style from './passwordResetMail.module.scss';
import PanelLayout from '../../containers/layout/PanelLayout';
import PasswordResetForm from '../Form/PasswordResetForm';

const PasswordResetMail = () => {
  const dispatch = useDispatch();

  return (
    <PanelLayout>
      <div className={style.container}>
        <div className={style.subTitle}>パスワード再設定</div>
        <div className={style.textContainer}>
          <p className={style.text}>
            登録いただいたメールアドレスを入力ください。
          </p>
          <p className={style.text}>
            パスワード再設定用のURLをメールでお送りいたします。
          </p>
        </div>
        <PasswordResetForm
          onSubmit={value => dispatch(requestPasswordReset(value))}
        />
      </div>
    </PanelLayout>
  );
};

export default PasswordResetMail;
