import {
  REQUEST_PRIVATE_COMMENT_LIST,
  SUCCESS_PRIVATE_COMMENT_LIST,
  SUCCESS_NEXT_PRIVATE_COMMENT_LIST,
  UPDATE_PRIVATE_OFFSET,
  SET_PRIVATE_COMMENT,
  SET_PUBLIC_COMMENT,
  PUSH_PRIVATE_ATTACHMENT,
  DELETE_PRIVATE_ATTACHMENT,
  SUCCESS_PUBLIC_COMMENT_CREATE,
  SET_PUBLIC_COMMENT_LIST,
  CLEAR_PRIVATE_ATTACHMENT,
  SUCCESS_PROJECT_COMMENT_LIST
} from '../actions/comment';

const initialState = {
  comments: [],
  publicComments: [],
  projectComments: [],
  projectCommentsCount: null,
  projectCommentsNext: null,
  offset: 0,
  count: 0,
  next: '',
  commonCommentText: null,
  privateCommentAttachedFiles: [],
  publicCommentText: {},
  publicPostSuccess: false
};

function comment(state = initialState, action) {
  switch (action.type) {
    case SUCCESS_PRIVATE_COMMENT_LIST:
      return {
        ...state,
        comments: action.payload.results,
        count: action.payload.count,
        next: action.payload.next
      };
    case SUCCESS_NEXT_PRIVATE_COMMENT_LIST:
      return {
        ...state,
        comments: state.comments.concat(action.payload.results),
        count: action.payload.count,
        next: action.payload.next === null ? '' : action.payload.next
      };
    case UPDATE_PRIVATE_OFFSET:
      let currentOffset = state.offset + 5;
      return {
        ...state,
        offset: currentOffset
      };
    // プライベートコメントの投稿
    case SET_PRIVATE_COMMENT:
      return {
        ...state,
        commonCommentText: action.payload
      };
    case SET_PUBLIC_COMMENT_LIST:
      return {
        ...state,
        publicComments: action.payload
      };
    // パブリックコメントテキストエリア
    case SET_PUBLIC_COMMENT:
      // 最初の画面読み込み時のみ、コメントの数に応じて初期化したステートを作成する
      if (Object.keys(state.publicCommentText).length === 0) {
        const newPublicCommentText = {master: ''};
        action.payload.commentList.forEach(comments => {
          newPublicCommentText[comments.comment.id] = '';
        });
        return {
          ...state,
          publicCommentText: newPublicCommentText
        };
      } else {
        const newPublicCommentText = {...state.publicCommentText};
        newPublicCommentText[action.payload.parent] = action.payload.text;
        return {
          ...state,
          publicCommentText: newPublicCommentText
        };
      }
    // プライベートコメントのファイルの添付
    case PUSH_PRIVATE_ATTACHMENT:
      state.privateCommentAttachedFiles = state.privateCommentAttachedFiles.concat(
        action.payload
      );
      return {
        ...state
      };

    case CLEAR_PRIVATE_ATTACHMENT:
      return {
        ...state,
        privateCommentAttachedFiles: initialState.privateCommentAttachedFiles
      };

    case DELETE_PRIVATE_ATTACHMENT:
      // reducer の再描画のフックの為には filter で配列を作り直す必要がある
      return {
        ...state,
        privateCommentAttachedFiles: state.privateCommentAttachedFiles.filter(
          (item, index, array) => index !== action.payload
        )
      };
    case SUCCESS_PROJECT_COMMENT_LIST:
      return {
        ...state,
        projectComments: action.payload.results,
        projectCommentsCount: action.payload.count,
        projectCommentsNext: action.payload.next
      };
    default:
      return state;
  }
}

export default comment;
