import React from 'react';
import style from '../../containers/co/Project/project.module.scss';
import commonStyle from '../../common/common.module.scss';

// components
import Header from '../../components/Project/Header';
import MainTitle from '../../components/MainTitle';
import AllowIndentionArea from '../AllowIndentionArea';

// material-ui
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import {useHistory} from 'react-router';
import Blank from '../Blank';

const useStyles = makeStyles({
  button: {
    width: '12rem',
    border: '0.1rem solid #093791',
    color: '#093791',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '1.5rem',
    lineHeight: '2rem',
    textAlign: 'center',
    marginLeft: 'auto'
  }
});

const Info = ({detail, projectId, isEngineer}) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={style.main}>
      <MainTitle
        titleStr={detail.title}
        pattern={false}
        component={
          isEngineer ? null : (
            <Button
              variant="outlined"
              className={classes.button}
              onClick={() => history.push(`/co/project/${projectId}/edit`)}
            >
              編集する
            </Button>
          )
        }
      />
      <div>
        <Header headerText={'カテゴリー'} />
        <p className={commonStyle.content}>
          {detail.main_category.main_category}
        </p>
      </div>
      <Blank height={'3rem'} />
      <div>
        <Header headerText={'概要'} />
        <AllowIndentionArea>
          <p className={commonStyle.content}>{detail.description}</p>
        </AllowIndentionArea>
      </div>
    </div>
  );
};

const AlertDialog = ({isOpen, handleClose, handleDetail}) => {
  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText className={style.dialogContentText}>
            プロジェクトを削除してよろしいでしょうか？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="primary">
            キャンセル
          </Button>
          <Button
            onClick={() => handleDetail()}
            variant="contained"
            autoFocus
            color="secondary"
          >
            削除する
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Info;
