import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import style from './dialog.module.scss';

//今後はModal系はTaskDetailModalsに共通化するので↓使いません。デグレが怖いのでしばらく取っておきます。
const Index = ({isShow, closeHandler, messageType}) => {
  const useStyles = makeStyles(theme => ({
    root: {
      color: '#00A8F0',
      fontSize: '3.2rem',
      fontWeight: 'bold',
      lineHeight: ' 4.1rem',
      color: '#00A8F0'
    },
    paperWidthSm: {
      width: '60rem',
      height: '30rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    buttonRoot: {
      margin: theme.spacing(6),
      backgroundColor: '#EA397D',
      border: 'none',
      color: '#FFFFFF',
      fontWeight: 'bold',
      width: '20rem',
      height: '5rem',
      fontSize: '1.6rem',
      '&:hover': {
        backgroundColor: '#b12a5e'
      }
    }
  }));

  const classes = useStyles();

  const messageList = {
    post: '保存しました',
    send: '送信設定しました。',
    sendMail: 'メールを送信しました。',
    error: 'エラーが発生しました。'
  };

  const messageSubList = {
    post: '',
    send: '送信までに時間がかかる場合がございます。',
    sendMail: '',
    error: '時間をおいて再度お試しください。'
  };

  const successDialog = (
    <Dialog
      open={isShow}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{
        root: classes.root,
        paperWidthSm: classes.paperWidthSm
      }}
    >
      <DialogContent>
        <div className={style.dialogContent}>
          <DialogContentText
            id="alert-dialog-description"
            classes={{
              root: classes.root
            }}
          >
            {messageList[messageType]}
          </DialogContentText>
          <DialogContentText id="alert-dialog-description">
            <Typography variant="caption">
              {messageSubList[messageType]}
            </Typography>
          </DialogContentText>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => closeHandler()}
          color="primary"
          classes={{root: classes.buttonRoot}}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );

  return <div>{successDialog}</div>;
};

export default Index;
