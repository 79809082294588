import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import More from '../../assets/icon_more.svg'
import Trash from '../../assets/icon_trash.svg'
import Edit from '../../assets/icon_edit.svg'
import {withStyles} from '@material-ui/core/styles';
import style from './more.module.scss'
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';

const styles = theme => ({
  padding: {
    padding: 0
  }
});

const theme = createMuiTheme({
  overrides: {
    MuiList: {
      padding: {
        paddingTop: "0.8rem",
        paddingBottom: "0.8rem",
      }
    },
    MuiMenuItem: {
      gutters: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: "1.5rem",
        paddingRight: "8rem",

      },
      root: {
        minHeight: "3rem"
      }
    },
    MuiButton: {
      text: {
        padding: "2rem",
      },
    }
  }
});


const Index = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <div>
      <MuiThemeProvider theme={theme}>
      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        <img src={More}/>
      </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleClose}>
            <div className={style.flexItem}>
              <div className={style.icon}>
                <img src={Edit}/>
              </div>
              <div className={style.menuTextEdit}>
                編集
              </div>
            </div>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <div className={style.flexItem}>
              <div className={style.icon}>
                <img src={Trash}/>
              </div>
              <div className={style.menuTextDelete}>
                削除
              </div>
            </div>
          </MenuItem>
        </Menu>
      </MuiThemeProvider>
    </div>
  );
}

export default Index
