import React from 'react'
import style from "./mainTitle.module.scss";


class Index extends React.Component {

  render() {
    return (
      <div>
        {renderTitle(this.props)}
      </div>
    )
  }
}

const renderTitle = ({titleStr, pattern, component}) => {
  switch (pattern) {
    case true:
      return onlyTitle(titleStr);
    case false:
      return titleWithButton(titleStr, component)
  }
};

const titleWithButton = (titleStr, component) => {
  return (
    <div className={style.title}>
      <p className={style.titleText}>
        {titleStr}
      </p>
      {component}
    </div>
  )
};

const onlyTitle = (titleStr) => {
  return (
    <div className={style.title}>
      <p className={style.titleText}>
        {titleStr}
      </p>
    </div>
  )
};

export default Index




