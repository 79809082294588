import {all, call, put, fork, take, select} from 'redux-saga/effects'
import {startSubmit, stopSubmit, reset} from 'redux-form'
import {history} from '../store'
import {fetchPostJson, fetchGet, fetchPatch} from './utills/fetch';
import {
  requestCoMe,
  successCoMe,
  requestCoMeEdit,
  requestPassword,
} from '../actions/co_setting';
import {
  setMessageType
} from '../actions/dialog';


export function* getMe() {
  while (true) {
    yield take(requestCoMe);
    const [data,error] = yield call(fetchGet, `tasks/`);
    yield put(successCoMe(data))
  }
}

export function* patchMe() {
  while (true) {
    const action = yield take(requestCoMeEdit);
    yield put(startSubmit('me'));
    const [data,error] = yield call(fetchPatch, `tasks`, action.payload);
    if(data && !error){
      yield put(reset('me'));
      yield put(setMessageType('post'));//dialog表示
    }else{
      yield put(stopSubmit('me', Object.assign(error, {_error: error.non_field_errors})));
    }
  }
}

export function* postPassword() {
  while (true) {
    const action = yield take(requestPassword);
    yield put(startSubmit('password'));
    const { password, password2 } = action.payload
    if(password !== password2){
      yield put(stopSubmit('password', {_error: 'パスワードが一致していません。'}));
    } else {
      const [data, error] = yield call(fetchPostJson, `password`, action.payload);
      if (data && !error) {
        yield put(reset('password'));
        yield put(setMessageType('post'));//dialog表示
      } else {
        yield put(stopSubmit('password', Object.assign(error, {_error: error.non_field_errors})));
      }
    }
  }
}


const Setting = [
  fork(getMe),
  fork(patchMe),
  fork(postPassword),
]

export default Setting;
