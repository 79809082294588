import React from 'react'

import styles from './pageSubTitle.module.scss'

const PageSubTitle = ({title}) => {
  return (
    <div className={styles.background}>
      <div className={styles.title}>{title}</div>
    </div>
  )
};

export default PageSubTitle
