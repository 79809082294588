import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import {requestDownloadAuthFile} from '../../../actions/common';
import IconDownload from '../../../assets/icon_download.svg';
import {useDispatch} from 'react-redux';

const DownloadButton = ({url, filename}) => {
  const dispatch = useDispatch();
  return (
    <IconButton
      onClick={() =>
        dispatch(
          requestDownloadAuthFile({
            url: url,
            filename: filename
          })
        )
      }
    >
      <img src={IconDownload} />
    </IconButton>
  );
};

export default DownloadButton;
