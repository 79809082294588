import React from 'react';
import {useSelector} from 'react-redux';
import {NavLink, useLocation, useParams} from 'react-router-dom';
import classNames from 'classnames';

import styles from './breadcrumbs.module.scss';

const Breadcrumbs = ({isEngineer, currentPath}) => {
  let {id, project} = useSelector(state => state.task.detail);
  let {project_id} = useSelector(state => state.project.detail);
  const projectId = project === undefined ? project_id : project;
  const {company} = useSelector(state => state.user.coUser);
  const pathHandler = new PathHandler(
    currentPath,
    id,
    projectId,
    isEngineer,
    company
  );
  const linkList = pathHandler.makeLink();

  if (linkList.length === 0) {
    return (
      <div className={styles.container}>
        <NavLink exact to={isEngineer ? `/en` : `/co`}>
          <div className={styles.addFlex}>
            <div className={styles.img} />
            <div
              className={classNames(styles.inactive, styles.blankHorizontal)}
            >
              TOP
            </div>
          </div>
        </NavLink>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      {linkList.map((link, index) => {
        if (index === 0) {
          return (
            <NavLink exact to={link} key={index.toString()}>
              <div className={styles.addFlex}>
                <div className={styles.img} />
                <div
                  className={classNames(
                    styles.activeLink,
                    styles.blankHorizontal
                  )}
                >
                  {pathHandler.makeBindName(link)}
                </div>
              </div>
            </NavLink>
          );
        } else {
          return (
            <NavLink exact to={link} key={index} className={styles.addFlex}>
              /
              <div
                className={classNames(
                  styles.activeLink,
                  styles.blankHorizontal
                )}
              >
                {pathHandler.makeBindName(link)}
              </div>
            </NavLink>
          );
        }
      })}

      <NavLink exact to={currentPath} className={styles.addFlex}>
        /
        <div className={styles.currentLink}>
          {pathHandler.makeBindName(currentPath)}
        </div>
      </NavLink>
    </div>
  );
};

export default Breadcrumbs;

class PathHandler {
  constructor(currentPath, taskId, projectId, isEngineer, companyId) {
    this.currentPath = currentPath;
    const rootPath = isEngineer ? `/en` : `/co`;
    this.PATH_LIST = [
      {regex: `${rootPath}$`, to: [], bindName: `TOP`},
      {regex: `${rootPath}/task$`, to: [rootPath], bindName: `仕事を探す`},
      {
        regex: `${rootPath}/engineers$`,
        to: [rootPath],
        bindName: `エンジニア一覧`
      },
      {
        regex: `${rootPath}/offers$`,
        to: [rootPath],
        bindName: `オファー管理`
      },
      {
        regex: `${rootPath}/project/\\d+/task/\\d+/entry$`,
        to: [rootPath],
        bindName: `エントリーエンジニア一覧`
      },
      {
        regex: `${rootPath}/payment/cardlimit$`,
        to: [rootPath],
        bindName: `仮売上げ画面`
      },
      {
        regex: `${rootPath}/co-user/\\d+$`,
        to: [
          `${rootPath}`,
          isEngineer ? `/en/co-info/${companyId}` : `/co/info`
        ],
        bindName: `企業ユーザ詳細`
      },
      {
        regex: `/co/info/edit$`,
        to: [`/co`, `/co/info`],
        bindName: `企業情報編集`
      },
      {
        regex: `/co/info/edit/base$`,
        to: [`/co`, `/co/info`],
        bindName: `基本情報編集`
      },
      {
        regex: `/co/info/edit/other$`,
        to: [`/co`, `/co/info`],
        bindName: `その他情報編集`
      },
      {
        regex: `${rootPath}/en-user/\\d+$`,
        to: [`${rootPath}`],
        bindName: `エンジニア詳細`
      },
      {
        regex: `/co/project$`,
        to: [`${rootPath}`],
        bindName: `プロジェクト一覧`
      },
      {
        regex: `/co/project/\\d+$`,
        to: [`${rootPath}`, `${rootPath}/project`],
        bindName: `プロジェクト詳細`
      },
      {
        regex: `/en/project/\\d+$`,
        to: [`${rootPath}`],
        bindName: `プロジェクト`
      },
      {
        regex: `/co/project/\\d+/edit$`,
        to: [
          `${rootPath}`,
          `${rootPath}/project`,
          `${rootPath}/project/${projectId}`
        ],
        bindName: `プロジェクト編集`
      },
      {
        regex: `/co/project/create$`,
        to: [`${rootPath}`, `${rootPath}/project`],
        bindName: `プロジェクト新規作成`
      },
      {
        regex: `/co/project/\\d+/task/\\d+$`,
        to: [
          `${rootPath}`,
          `${rootPath}/project`,
          `${rootPath}/project/${projectId}`
        ],
        bindName: `タスク`
      },
      {
        regex: `/co/project/\\d+/task/\\d+/edit$`,
        to: [
          `${rootPath}`,
          `${rootPath}/project`,
          `${rootPath}/project/${projectId}`
        ],
        bindName: `タスク編集`
      },
      {
        regex: `/co/project/\\d+/task/\\d+/draft-edit$`,
        to: [
          `${rootPath}`,
          `${rootPath}/project`,
          `${rootPath}/project/${projectId}`
        ],
        bindName: `タスク編集`
      },
      {
        regex: `/co/project/\\d+/task/\\d+/authorization$`,
        to: [
          `${rootPath}`,
          `${rootPath}/project`,
          `${rootPath}/project/${projectId}`,
          `${rootPath}/project/${projectId}/task/${taskId}`
        ],
        bindName: `報酬の仮払い`
      },
      {
        regex: `/en/project/\\d+/task/\\d+$`,
        to: [`${rootPath}`, `${rootPath}/project/${projectId}`],
        bindName: `タスク`
      },
      {
        regex: `${rootPath}/project/\\d+/task/create$`,
        to: projectId
          ? [
              `${rootPath}`,
              `${rootPath}/project`,
              `${rootPath}/project/${projectId}`
            ]
          : [`${rootPath}`, `${rootPath}/project`],
        bindName: `タスク新規作成`
      },
      {regex: `/co/info$`, to: [`${rootPath}`], bindName: `企業情報`},
      {
        regex: `${rootPath}/co-info/\\d+$`,
        to: [`${rootPath}`],
        bindName: `企業情報`
      },
      {
        regex: `${rootPath}/message$`,
        to: [`${rootPath}`],
        bindName: `メッセージ`
      },
      {
        regex: `${rootPath}/message/create$`,
        to: [`${rootPath}`],
        bindName: `メッセージ新規作成`
      },
      {
        regex: `${rootPath}/message/create/\\d+$`,
        to: [`${rootPath}`],
        bindName: `メッセージ新規作成`
      },
      {
        regex: `${rootPath}/setting$`,
        to: [`${rootPath}`],
        bindName: `アカウント設定`
      },
      {
        regex: `${rootPath}/member$`,
        to: [`${rootPath}`],
        bindName: `メンバー管理`
      },
      {
        regex: `${rootPath}/user/edit/profile$`,
        to: [`${rootPath}`, `${rootPath}/setting`],
        bindName: `プロフィール設定`
      },
      {
        regex: `${rootPath}/user/edit/select$`,
        to: [`${rootPath}`, `${rootPath}/setting`],
        bindName: `ログイン情報`
      },
      {
        regex: `co/two-factor-auth-setting$`,
        to: [`${rootPath}`, `${rootPath}/setting`],
        bindName: `２段階認証設定`
      },
      {
        regex: `${rootPath}/setting/payment$`,
        to: [`${rootPath}`, `${rootPath}/setting`],
        bindName: `お支払い設定`
      },
      {
        regex: `${rootPath}/payment/setting$`,
        to: [`${rootPath}`, `${rootPath}/setting`],
        bindName: `お支払い設定`
      },
      {
        regex: `${rootPath}/init-paid`,
        to: [
          `${rootPath}`,
          `${rootPath}/setting`,
          `${rootPath}/setting/payment`
        ],
        bindName: `Paid利用申請`
      },
      {
        regex: `${rootPath}/user/edit/id-info`,
        to: [`${rootPath}`, `${rootPath}/setting`],
        bindName: `本人確認情報`
      },
      {regex: `/news`, to: [`${rootPath}`], bindName: `お知らせ`},
      {regex: `/faq`, to: [`${rootPath}`], bindName: `良くある質問`},
      {regex: `/terms`, to: [`/co`], bindName: `利用規約`},
      {regex: `/policy`, to: [`/co`], bindName: `プライバシーポリシー`},
      {regex: `/sctl`, to: [`/co`], bindName: `特定商取引法`},
      {regex: `/rewardAdmin`, to: [`${rootPath}`], bindName: `報酬管理`}
    ];
  }

  makeLink = () => {
    try {
      const val = this.PATH_LIST.find(item =>
        this.currentPath.match(item.regex)
      );
      return val.to;
    } catch (e) {
      console.error(
        'パンくずリストに未定義のルーティングです。\nsrc/components/Breadcrumbs/index.js >> PathHandler を確認してください。'
      );
    }
  };

  makeBindName = basePath => {
    try {
      const val = this.PATH_LIST.find(item => basePath.match(item.regex));
      return val.bindName;
    } catch (e) {
      return `プロジェクト詳細`;
    }
  };
}
