import React from 'react';
import Button from './Button';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

const SubmitButton = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {id, projectId} = props;

  const PushAssignlist = () => {
    history.push(`/co/project/${projectId}/task/${id}/entry`);
  };

  const onClickHandler = {
    push: PushAssignlist
  };

  return <Button kind={'pink'} onClickHandler={() => onClickHandler.push()} />;
};

export const AssignButton = ({id, projectId}) => {
  return (
    <div>
      <SubmitButton id={id} projectId={projectId} />
    </div>
  );
};
