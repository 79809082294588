import React from 'react';
import styles from './mobileEnMenu.module.scss';
import MenuButton from '../../../components/Button/MenuButton';
import {MENUES} from '../../../constants';

const MobileEnMenu = () => {
  return (
    <>
      <div className={styles.underBackground} />
      <div className={styles.background}>
        <MenuButton name="TOP" page={MENUES.dashboard} kind={'en'} />
        <MenuButton name="仕事を探す" page={MENUES.job} kind={'en'} />
        <MenuButton name="メッセージ" page={MENUES.email} kind={'en'} />
        <MenuButton name="報酬管理" page={MENUES.rewardAdmin} />
      </div>
    </>
  );
};

export default MobileEnMenu;
