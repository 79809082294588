import React from 'react';
import style from './PublicCommentItems.module.scss';
import {makeStyles} from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import PublicCommentForm from '../Form/PublicCommentForm';
import {AvatarImg} from '../../common';
import {postTime} from '../../common';
import {useDispatch, useSelector} from 'react-redux';
import {requestPublicCommentCreate} from '../../actions/comment';
import {getFormValues} from 'redux-form';

const useStyles = makeStyles({
  root: {
    marginTop: '3rem'
  },
  avatar: {
    margin: 10
  }
});

const Index = props => {
  const {public_comment, taskId} = props;
  const dispatch = useDispatch();
  const formValues = useSelector(state => {
    return getFormValues(`PublicCommentForm_${public_comment.comment.id}`)(
      state
    );
  });
  return (
    <div className={style.items}>
      <Comment {...props} />
      <PublicCommentForm
        initialValues={{
          parent: public_comment.comment.id
        }}
        form={`PublicCommentForm_${public_comment.comment.id}`}
        onSubmit={values => {
          dispatch(
            requestPublicCommentCreate({
              ...values,
              formName: `PublicCommentForm_${public_comment.comment.id}`,
              id: taskId
            })
          );
        }}
        parent={public_comment.comment.id}
        formValues={formValues}
      />
    </div>
  );
};

const Comment = React.memo(
  props => {
    const classes = useStyles();

    const {public_comment} = props;
    return (
      <div className={style.commentsContainer}>
        <div className={style.userInfo}>
          <div className={style.userInfoItem}>
            <div className={style.avatar}>
              <AvatarImg
                img={public_comment.comment.user.img}
                size="minimum"
                className={style.IconAvatar}
              />
            </div>
            <div>
              <p className={style.name}>
                {public_comment.comment.user.nick_name}
              </p>
              <p className={style.datetime}>
                {postTime(public_comment.comment.datetime)}
              </p>
            </div>
          </div>
          {/* <p className={style.dateContainer}>
            <span className={style.date}>
             {format(public_comment.comment.datetime, 'YYYY年MM月DD日')}
            </span>
          </p> */}
        </div>
        <div className={style.commentContainer}>
          {public_comment.comment.content}
        </div>
        <Divider className={classes.root} />
        <div>
          {public_comment.replies.map((reply, index) => (
            <Reply key={index.toString()} {...reply} />
          ))}
        </div>
      </div>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.public_comment.replies.length ===
      nextProps.public_comment.replies.length
    );
  }
);

const Reply = ({reply}) => {
  const classes = useStyles();

  return (
    <div>
      <p className={style.reply}>
        <div className={style.userInfo}>
          <div className={style.userInfoItem}>
            <div className={style.avatar}>
              <AvatarImg
                img={reply.user.img}
                size="minimum"
                className={style.IconAvatar}
              />
            </div>
            <div>
              <p className={style.name}>{reply.user.nick_name}</p>
              <p className={style.datetime}>{postTime(reply.datetime)}</p>
            </div>
          </div>
          <p className={style.dateContainer}>
            {/*<span className={style.date}>*/}
            {/*  {format(reply.datetime, 'YYYY年MM月DD日')}*/}
            {/*</span>*/}
          </p>
        </div>
        <div className={style.commentContainer}>{reply.content}</div>
        <Divider className={classes.root} />
      </p>
    </div>
  );
};

export default Index;
