import React from 'react';
import styles from '../commonModal.module.scss';
import IconSearch from '../../../assets/icon_task_search_blue.svg';

import SubmitButton from '../../Button/SubmitButton';
import {useHistory} from 'react-router-dom';

const CreateTaskResult = () => {
  const history = useHistory();

  return (
    <div className={styles.inner}>
      <div className={styles.header}>
        <img className={styles.image} src={IconSearch} />
        <div className={styles.subTitle}>Paid利用申請が完了しました。</div>
      </div>
      <div className={styles.message}>
        <p>審査は当日〜3営業日以内に完了し</p>
        <p>結果がメールにて通知されますのでご確認ください。</p>
      </div>
      <div className={styles.button}>
        <SubmitButton text="完了" onClick={() => history.push('/co')} />
      </div>
    </div>
  );
};

export default CreateTaskResult;
