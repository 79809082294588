import React from 'react';
import style from './title.module.scss'

const Title = ({text, component}) => {

  const renderTitle = (text, component) =>  {
    switch (component) {
      case 'h1':
        return <h1>{text}</h1>
      case 'h2':
        return <h2>{text}</h2>
      case 'h3':
        return <h3>{text}</h3>
      case 'h4':
        return <h4>{text}</h4>
      default:
        return <p>{text}</p>
    }
  }

  return (
      <div className={style.title}>
        {renderTitle(text, component)}
      </div>
  );
}

export default Title;
