import React from 'react';
import {useDispatch} from 'react-redux';
import style from './field.module.scss';
import Dropzone from 'react-dropzone';
import {setIsImgChanged} from '../../actions/common';
import ClipWhite from '../../assets/icon_clip_white.svg';
// constants
import {COLOR_PALLET} from '../../constants';
import {HELPER_TEXT} from './constants';
import {changeDate} from '../../common';
// material-ui
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import {makeStyles} from '@material-ui/core/styles';
import {KeyboardDatePicker} from '@material-ui/pickers';
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import Button from '@material-ui/core/Button';

const theme = createMuiTheme({
  overrides: {
    MuiSvgIcon: {
      root: {
        fill: 'darkgray',
        width: '2em',
        height: '2em',
        display: 'inline-block',
        fontSize: '1.5rem',
        transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        userSelect: 'none',
        flexShrink: 0,
        position: 'absolute',
        top: '1.4rem',
        right: '1rem'
      }
    },
    MuiOutlinedInput: {
      inputMultiline: {
        lineHeight: '2.6rem',
        fontSize: '1.6rem'
      },
      root: {
        backgroundColor: '#F7F7F7',
        minHeight: '5rem'
      }
    },
    MuiInputBase: {
      root: {
        lineHeight: '2.6rem',
        fontSize: '1.6rem'
      }
    },
    MuiFormControl: {
      root: {
        width: '50%'
      }
    },
    MuiSelect: {
      selectMenu: {
        display: 'flex'
      }
    },
    MuiFormHelperText: {
      root: {
        color: '#EA397D',
        lineHeight: '2.6rem',
        fontSize: '1.7rem',
        '&$error': {
          color: '#EA397D',
          fontSize: '20rem'
        }
      }
    },
    MuiMenuItem: {
      root: {
        fontSize: '1.6rem'
      }
    }
  }
});

const useStyles = makeStyles(theme => ({
  selectMenu: {
    display: 'flex',
    lineHeight: '2.6rem',
    fontSize: '2.6rem',
    backgroundColor: '#F7F7F7',
    minHeight: '5rem'
  },
  fontSizeNormal: {
    fontSize: '1.6rem'
  },
  calender: {
    [theme.breakpoints.down('xs')]: {
      margin: '0.5rem auto'
    }
  }
}));

const renderPassword = ({
  input,
  id,
  label,
  showPassword,
  meta: {touched, error}
}) => {
  return (
    <TextField
      error={touched && error}
      {...input}
      id={id}
      label={label}
      type={'password'}
      fullWidth
      variant="outlined"
      helperText={touched && error}
    />
  );
};

const renderText = ({
  className,
  input,
  id,
  label,
  placeholder,
  fullWidth,
  width,
  meta: {touched, error},
  size = 'middle',
  type = 'text'
}) => {
  const useStyles = makeStyles(theme => ({
    textField: {
      width: width
    },
    contained: {},
    small: {
      fontSize: '1.2rem',
      backgroundColor: COLOR_PALLET.white
    },
    middle: {
      fontSize: '1.6rem',
      backgroundColor: COLOR_PALLET.white
    }
  }));

  const classes = useStyles();
  return (
    <TextField
      {...input}
      id={id}
      label={label}
      fullWidth
      type={type}
      variant="outlined"
      placeholder={placeholder}
      onChange={e => {
        input.onChange(e.target.value);
      }}
      helperText={touched && error}
      value={input.value}
      FormHelperTextProps={{classes: {contained: classes.small}}}
      InputProps={{
        classes: {
          input: classes[size]
        }
      }}
      error={touched && error}
    />
  );
};

const renderTextArea = ({
  input,
  id,
  label,
  placeholder,
  rows,
  rowsMax,
  meta: {touched, error},
  ...custom
}) => {
  const useStyles = makeStyles(theme => ({
    notchedOutline: {
      backgroundColor: COLOR_PALLET.white,
      fontSize: '1.6rem'
    },
    textArea: {
      lineHeight: '2.6rem'
    },
    helperText: {
      ...HELPER_TEXT
    }
  }));
  const classes = useStyles();

  return (
    <TextField
      {...input}
      id={id}
      label={label}
      multiline={true}
      fullWidth
      variant="outlined"
      placeholder={placeholder}
      rows={rows}
      rowsMax={rowsMax}
      InputProps={{
        classes: {
          inputMultiline: classes.textArea,
          root: classes.notchedOutline
        }
      }}
      error={touched && error}
      helperText={touched && error}
      FormHelperTextProps={{
        className: classes.helperText
      }}
    />
  );
};

const renderDate = ({input, id, label, meta: {touched, error}}) => {
  return <TextField {...input} id={id} label={label} fullWidth type={'date'} />;
};

const renderHidden = ({input, id, label, meta: {touched, error}}) => {
  return <Input {...input} label={label} id={id} type={'hidden'} />;
};

const renderFile = ({input, id, label, meta: {touched, error}}) => {
  return (
    <Input
      label={label}
      id={id}
      type={'file'}
      accept={'*'}
      onChange={e => {
        e.preventDefault();
        input.onChange(e.target.files);
      }}
    />
  );
};

const renderImage = ({input, id, name, label, meta: {touched, error}}) => {
  const dispatch = useDispatch();
  return (
    <Input
      style={{display: 'none'}}
      label={'label'}
      id={id}
      name={name}
      type={'file'}
      accept={'*'}
      onChange={e => {
        dispatch(setIsImgChanged(true));
        const reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = () => {
          input.onChange(reader.result);
        };
      }}
    />
  );
};

const renderMultilineText = ({input, id, label, meta: {touched, error}}) => {
  return (
    <TextField
      {...input}
      id={id}
      type={'text'}
      label={label}
      multiline
      fullWidth
      variant="outlined"
      className={style.textarea}
    />
  );
};

// Select
const renderSelect = ({
  input,
  label,
  width,
  className,
  meta: {touched, error},
  children,
  fullWidth = true,
  size = 'middle',
  ...custom
}) => {
  const useSelectStyles = makeStyles(theme => ({
    root: {
      '& .MuiSelect-icon': {
        top: 'unset'
      }
    },
    small: {
      fontSize: '1.2rem',
      display: 'flex',
      alignItems: 'flex-end',
      backgroundColor: COLOR_PALLET.white
    },
    middle: {
      fontSize: '1.6rem',
      display: 'flex',
      alignItems: 'center',
      backgroundColor: COLOR_PALLET.white
    },
    select: {
      '&:focus': {
        backgroundColor: '#FFFFFF'
      }
    }
  }));

  const classes = useSelectStyles();

  return (
    <>
      <TextField
        select
        SelectProps={{
          value: input.value,
          classes: {select: classes.select}
        }}
        className={classes.root}
        label={label}
        variant="outlined"
        onChange={e => {
          input.onChange(e.target.value);
        }}
        fullWidth={fullWidth}
        InputProps={{
          classes: {
            input: classes[size]
          }
        }}
        error={touched && error}
        helperText={touched && error}
      >
        {children}
      </TextField>
    </>
  );
};
// const renderSelect = ({input, label, meta: {touched, error}, children, ...custom}) => (
//   <Select
//     className={style.select}
//     native
//     {...input}
//     label={label}
//     onChange={(event, index, value) => input.onChange(event)}
//     children={children}
//     {...custom}
//     variant="outlined"
//   />
// )
//
//
// // Select Multiple
// const renderMultipleSelect = ({input, label, options, meta: { touched, error }, children, ...custom }) => {
//
//   return (<Select
//     className={style.multipleselect}
//     multiple
//     {...input}
//     onChange={(event) => input.onChange(event)}
//     input={<Input id="select-multiple-checkbox"/>}
//     renderValue={selected => {
//       const result = []
//       selected.map(select => {
//         options.map(option => {
//           if (option.id === select) result.push(option.name)
//         })
//       })
//       return result.join(', ')
//     }}>
//     {options.map((val, index) => {
//       if (val.name) {
//         return (<MenuItem key={val.id} value={val.id}>
//           <Checkbox checked={input.value.indexOf(val.id) > -1}/>
//           <ListItemText primary={val.name}/>
//         </MenuItem>);
//       }
//     })}
//   </Select>)
// }

// checkbox
const renderCheckbox = ({
  input,
  label,
  value,
  disabled,
  meta: {touched, error}
}) => {
  return (
    <Checkbox
      disabled={disabled}
      checked={input.value ? true : false}
      onChange={(event, index, value) => input.onChange(event)}
    />
  );
};

const renderCalender = ({input, custom, className, meta: {touched, error}}) => {
  const classes = useStyles();
  const convertISOString = value => {
    if (typeof value !== 'string') {
      return value.toISOString();
    }
    return value;
  };
  return (
    <KeyboardDatePicker
      minDate={changeDate(new Date(), 1, 'future')}
      margin="normal"
      format="yyyy 年 MM 月 dd 日"
      value={convertISOString(input.value)}
      onChange={date => input.onChange(convertISOString(date))}
      KeyboardButtonProps={{
        'aria-label': 'change date'
      }}
      disablePast={true}
      InputProps={{
        classes: {
          root: classes.fontSizeNormal
        }
      }}
      className={classes.calender}
    />
  );
};

// Select Multiple
const renderTagsMultipleSelect = ({
  input,
  label,
  options,
  meta: {touched, error},
  children,
  ...custom
}) => {
  return (
    <Select
      className={style.select}
      multiple
      {...input}
      onChange={event => input.onChange(event)}
      input={<Input id="select-multiple-checkbox" />}
      renderValue={selected => {
        const result = [];
        selected.map(select => {
          options.map(option => {
            if (option === select) result.push(option);
          });
        });
        return result.join(', ');
      }}
    >
      {options.map((val, index) => {
        if (val) {
          return (
            <MenuItem key={index.toString()} value={val}>
              <Checkbox checked={input.value.indexOf(val) > -1} />
              <ListItemText primary={val} />
            </MenuItem>
          );
        }
      })}
    </Select>
  );
};

const renderDropzone = ({
  input,
  label,
  options,
  meta: {touched, error},
  children,
  ...custom
}) => {
  let preview = '';
  if (input.value) {
    const img = Object.assign(
      input.value.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      )
    );
    preview = img[0].preview;
  }

  const dropzoneStyle = {backgroundImage: `url(${preview})`};

  return (
    <aside>
      <div className={style.avatar} style={dropzoneStyle}>
        <Dropzone
          accept="image/*"
          onDrop={(filesToUpload, e) => input.onChange(filesToUpload)}
        >
          {({getRootProps, getInputProps}) => (
            <div {...getRootProps()} className={style.btn}>
              <input {...getInputProps()} />
              <p />
            </div>
          )}
        </Dropzone>
      </div>
    </aside>
  );
};

const renderRadio = ({
  input: {value, onChange},
  children,
  meta: {touched, error},
  onFieldChange,
  row = true,
  required = false,
  rootClass = ''
}) => {
  return (
    <FormControl
      classes={{root: rootClass}}
      required={required}
      component="fieldset"
      error={!!(touched && error)}
    >
      <RadioGroup
        row={row}
        value={value}
        onChange={e => {
          onChange(Number(e.target.value));
          onFieldChange && onFieldChange(Number(e.target.value));
        }}
      >
        {children}
      </RadioGroup>

      {touched && error && <div className={style.requiredChoice}>{error}</div>}
    </FormControl>
  );
};

const renderFiles = ({fields, meta: {error, submitFailed}, setFields}) => {
  const useStyles = makeStyles(theme => ({
    buttonRoot: {
      fontSize: '1.6rem',
      width: '100%',
      color: COLOR_PALLET.white,
      backgroundColor: COLOR_PALLET.blue900,
      lineHeight: 'normal',
      height: '5rem',
      borderRadius: '0.3rem',
      '&:hover': {
        backgroundColor: COLOR_PALLET.hoverBlue
      },
      [theme.breakpoints.up('xs')]: {
        fontSize: '1.2rem',
        width: '14rem',
        borderRadius: '0.3rem',
        height: 'auto',
        padding: '1rem 0'
      }
    },
    inputRoot: {
      opacity: 0,
      position: 'absolute',
      overflow: 'hidden',
      right: 0,
      top: 0,
      margin: 0,
      width: 0
    }
  }));
  const classes = useStyles();
  setFields(fields);
  return (
    <>
      <Button
        classes={{root: classes.buttonRoot}}
        component="label"
        type="button"
        color="primary"
        variant="outlined"
        onClick={e => (e.target.value = null)}
      >
        <img src={ClipWhite} />
        <span>&nbsp;ファイルを添付</span>
        <Input
          classes={{root: classes.inputRoot}}
          label={'label'}
          id="attachedFiles"
          name="attachedFiles"
          type={'file'}
          accept={'*'}
          multiple="multiple"
          onChange={e => {
            fields.push(e.target.files[0]);
          }}
        />
      </Button>
      {submitFailed && error && <span>{error}</span>}
    </>
  );
};

export {
  renderMultilineText,
  renderPassword,
  renderText,
  renderTextArea,
  renderDate,
  renderHidden,
  renderFile,
  renderFiles,
  renderImage,
  renderSelect,
  renderCheckbox,
  renderCalender,
  renderTagsMultipleSelect,
  renderDropzone,
  renderRadio
};
