import {firebaseConfigPrd, firebaseConfigStg} from '../firebase/config';

export let API_HOST = 'non-select';
export let SCHEME = 'http';
export let GITHUB_CLIENT_ID = '';
export let firebaseConfig = null;
// For Django (ここの secret は 見られても問題ない)
export let CLIENT_ID = '';

switch (process.env.REACT_APP_TARGET_ENV) {
  case 'local':
    API_HOST = '127.0.0.1:8000';
    SCHEME = 'http';
    firebaseConfig = firebaseConfigStg;
    CLIENT_ID = 'k8FYjWH1MbVqb3r6KztrAcQbZKbWYHPYofGpMbJK';
    GITHUB_CLIENT_ID = '911866472e640464e26b';
    break;
  case 'development':
    API_HOST = 'dummy-host.com';
    break;
  case 'staging':
    API_HOST = '34.95.70.180';
    SCHEME = 'http';
    break;
  case 'gae-staging':
    API_HOST = 'stg.task.site';
    SCHEME = 'https';
    GITHUB_CLIENT_ID = '1967208f8b52ba645c65';
    firebaseConfig = firebaseConfigStg;
    CLIENT_ID = 'k8FYjWH1MbVqb3r6KztrAcQbZKbWYHPYofGpMbJK';
    break;
  case 'production':
    API_HOST = 'task.site';
    SCHEME = 'https';
    GITHUB_CLIENT_ID = '19d210be6f2d6866e9b8';
    firebaseConfig = firebaseConfigPrd;
    CLIENT_ID = '22HhfilPfYYhrtgniJIx49o0LJpReGAadZF6jPZh';
    break;
  default:
    API_HOST = 'dont-much-every-case';
}

export const API_KEY = '1LgMZnJbFY-YbofLzffQ7MhzyZc';
export const SCOPE = 'user';
export const API_URL = `${SCHEME}://${API_HOST}/api/`;
