import React from 'react';
import style from './textLabel.module.scss'

const TextLabel = ({text}) => {

  return (
    <div className={style.text}>{text}</div>
  );
};

export default TextLabel;
