import React from 'react';
import SwipeableViews from 'react-swipeable-views';

class SwipableViewsWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.swipeableViewsRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    this.swipeableViewsRef.current
      .getChildContext()
      .swipeableViews.slideUpdateHeight();
  }

  render() {
    return <SwipeableViews ref={this.swipeableViewsRef} {...this.props} />;
  }
}

export default SwipableViewsWrapper;
