import React from 'react';
import style from '../co/ProfileEditForm/profileEditForm.module.scss';
import styles from './inputImgField.module.scss';
import {AvatarImg, BASE_COLOR} from '../../../common';
import Camera from '../../../assets/icon_camera_circle.svg';
import {Field} from 'redux-form';
import {renderImage} from '../../Field';
// material-ui
import IconButton from '@material-ui/core/IconButton';
import {makeStyles} from '@material-ui/core/styles';
import {useSelector} from 'react-redux';

const useStyles = makeStyles(theme => ({
  imgButton: {
    width: '18rem'
  },
  cameraImgButton: {
    minWidth: '4rem',
    position: 'absolute',
    top: '13rem',
    right: '0.5rem',
    width: '4rem'
  }
}));

const InputImgField = ({img, name, id}) => {
  const classes = useStyles();
  return (
    <div className={style.avatarContainer}>
      <div className={style.buttonContainer}>
        <IconButton
          className={classes.imgButton}
          size="small"
          component="label"
          type="button"
        >
          <AvatarImg img={img} size="big" />
          <Field name={name} id={id} component={renderImage} fullWidth={true} />
        </IconButton>
        <IconButton
          className={classes.cameraImgButton}
          size="small"
          component="label"
          type="button"
        >
          <img src={Camera} className={style.camera} />
          <Field name={name} id={id} component={renderImage} fullWidth={true} />
        </IconButton>
      </div>
    </div>
  );
};

export default InputImgField;
