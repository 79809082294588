import React, {useMemo, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionActions from '@material-ui/core/AccordionActions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Label from '../Label';
import style from './aboutStatus.module.scss';
import CommonButton from '../CommonButton';
import AllowIndentionArea from '../../components/AllowIndentionArea';
import IconArrow from '../../assets/arrow_bottom.svg';
import {mediaQuery} from '../../constants';
import MediaQuery from 'react-responsive';
import Blank from '../Blank';
import {getStatusDetails} from './constants';
import {useSelector} from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  accordionActions: {
    justifyContent: 'center'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15)
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20
  },
  detailsPc: {
    alignItems: 'center',
    padding: 0
  },
  detailsSp: {
    alignItems: 'center',
    padding: 0,
    flexDirection: 'column'
  },
  arrowPc: {
    width: '15rem',
    display: 'flex',
    justifyContent: 'center',
    padding: '2rem 0'
  },
  arrowSp: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1rem 0'
  },
  column: {
    flexBasis: '33.33%'
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2)
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}));

export default function DetailedAccordion() {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const isEngineer = useSelector(state => state.setting.isEngineer);
  const statusDetails = useMemo(() => getStatusDetails(isEngineer), [
    isEngineer
  ]);

  return (
    <div className={classes.root}>
      <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1c-content"
          id="panel1c-header"
        >
          <div className={style.title}>
            <p>タスクの進行とステータスについて</p>
          </div>
        </AccordionSummary>
        <div className={style.detailsContainer}>
          {statusDetails.map((item, index) => {
            return (
              <DetailRow
                key={index}
                item={item}
                index={index}
                classes={classes}
                statusDetails={statusDetails}
              />
            );
          })}
        </div>
        <Blank height={'1.5rem'} />
        <AccordionActions classes={{root: classes.accordionActions}}>
          <CommonButton
            text={'閉じる'}
            size={'medium'}
            color={'blue'}
            variant={'contained'}
            shape={'normal'}
            onClick={() => {
              setExpanded(false);
              window.scrollTo(0, 0);
            }}
            disabled={false}
          />
        </AccordionActions>
      </Accordion>
    </div>
  );
}

const DetailRow = ({item, classes, index, statusDetails}) => {
  return (
    <>
      <div className={style.detailsInner}>
        <MediaQuery query={mediaQuery.min}>
          <AccordionDetails className={classes.detailsPc}>
            <div className={style.labelContainer}>
              <div className={style.label}>
                <Label text={item.text} status={item.status} isActive={true} />
              </div>
            </div>
            <AllowIndentionArea>{item.description}</AllowIndentionArea>
          </AccordionDetails>
        </MediaQuery>
        <MediaQuery query={mediaQuery.max}>
          <AccordionDetails className={classes.detailsSp}>
            <div className={style.labelContainer}>
              <div className={style.label}>
                <Label text={item.text} status={item.status} isActive={true} />
              </div>
            </div>
            <Blank height={'1.5rem'} />
            <AllowIndentionArea>{item.description}</AllowIndentionArea>
          </AccordionDetails>
        </MediaQuery>
      </div>
      {statusDetails.length > index + 1 && (
        <>
          <MediaQuery query={mediaQuery.min}>
            <div className={classes.arrowPc}>
              <img alt={'矢印'} src={IconArrow} />
            </div>
          </MediaQuery>
          <MediaQuery query={mediaQuery.max}>
            <div className={classes.arrowSp}>
              <img alt={'矢印'} src={IconArrow} />
            </div>
          </MediaQuery>
        </>
      )}
    </>
  );
};
