import {
  all,
  call,
  put,
  takeEvery,
  take,
  fork,
  select
} from 'redux-saga/effects';
import {fetchPostJson, fetchPatch, fetchGet, fetchDelete} from './utills/fetch';
import {
  requestCustomToken,
  successCustomToken,
  setLoggedIn,
  requestFirebaseLoginStatus,
  successFirebaseLoginStatus,
  requestAdditionalClaims,
  successAdditionalClaims
} from '../actions/firebase';
import {
  firebaseSignIn,
  firebaseGetAdditionalClaims,
  getFirebaseLoginStatus
} from '../firebase/common';
import {setCompanyId} from '../actions/setting';
import {requestNotificationListeners} from '../actions/firebase';
import {
  personalNotificationDetachFunction,
  setLastPersonalNotification
} from '../actions/personalNotification';
import {systemNotificationDetachFunction} from '../actions/systemNotification';
import {
  ListenPersonalNotification,
  fetchPersonalNotification
} from '../firebase/personalNotification.js';
import {ListenSystemNotification} from '../firebase/systemNotification.js';
import store from 'store';
import {setSystemNewsLastSnapshot} from '../actions/systemNews';
import {setPersonalNewsLastSnapshot} from '../actions/personalNews';

// カスタムトークンの 取得と firebase signin
export function* getMyCustomToken() {
  while (true) {
    yield take(requestCustomToken);
    const {isLoggedIn} = yield select(state => state.firebase);

    // カスタムトークンでのログインが初回
    //isLoggedIn!=trueを満たすのは2パターン。(i)まだ一回もログインしていない →isLoggedIn==null  (ii)一回ログインしたけどログアウトした→isLoggedIn==false
    if (isLoggedIn != true) {
      const [data, error] = yield call(fetchGet, `my-custom-token/`);
      if (data && !error) {
        yield call(firebaseSignIn, data);
        yield put(successCustomToken(data.token));
      } else {
        console.error('カスタムトークンの取得に失敗しました');
        console.error(error);
      }
    }
  }
}

export function* getAdditionalClaims() {
  while (true) {
    yield take(requestAdditionalClaims);
    const [claims, claimsError] = yield call(firebaseGetAdditionalClaims);

    if (claims && !claimsError) {
      yield put(setCompanyId(claims.company_id));
    } else {
      console.log(claimsError);
    }
  }
}

export function* firebaseLoginStatus() {
  while (true) {
    yield take(requestFirebaseLoginStatus);
    try {
      const data = yield call(getFirebaseLoginStatus);
    } catch (e) {
      console.error(e);
    }
  }
}

export function* setNotificationListeners() {
  while (true) {
    yield take(requestNotificationListeners);
    const settingMe = yield select(state => state.setting.me);
    try {
      //個人通知リスナー呼び出し
      const [
        personalDetachFunction,
        personalLastSnapshot,
        personalErr
      ] = yield call(ListenPersonalNotification, settingMe.username);
      if (!personalErr) {
        yield put(personalNotificationDetachFunction(personalDetachFunction));
        yield put(setPersonalNewsLastSnapshot(personalLastSnapshot));
      }

      //システム通知リスナー呼び出し
      const [systemDetachFunction, systemLastSnapshot, systemErr] = yield call(
        ListenSystemNotification
      );
      if (!systemErr) {
        yield put(systemNotificationDetachFunction(systemDetachFunction));
        yield put(setSystemNewsLastSnapshot(systemLastSnapshot));
      } else {
        console.error('システム通知のリッスンに失敗しました');
      }
    } catch (e) {}
  }
}

const firebase = [
  fork(getMyCustomToken),
  fork(firebaseLoginStatus),
  fork(getAdditionalClaims),
  fork(setNotificationListeners)
];

export default firebase;
