import React from 'react';
import styles from './reward.module.scss';
import classNames from 'classnames';

const Reward = ({reward, title = '報酬金額', isNormal = true}) => {
  const color = isNormal ? styles.red : styles.blue;
  return (
    <div className={styles.rewardContainer}>
      <div className={styles.reward}>
        <p className={classNames(styles.itemTitleAmount, color)}>{title}</p>
        <div className={styles.rewardItem}>
          <p className={classNames(styles.price, color)}>
            {Number(reward).toLocaleString()}
          </p>
          <p className={classNames(styles.unit, color)}>円</p>
        </div>
      </div>
    </div>
  );
};

export default Reward;
