import React from 'react';
import Button from './Button';
import {useDispatch, useSelector} from 'react-redux';
import {patchTaskModify} from '../../../actions/modify';

const SubmitButton = props => {
  const dispatch = useDispatch();
  const id = props.id;

  const ModifyRequest = () => {
    dispatch(patchTaskModify({task: id, status: 6}));
  };

  const onClickHandler = {
    patch: ModifyRequest
  };

  return <Button kind={'blue'} onClickHandler={() => onClickHandler.patch()} />;
};

export const ModifyRequestButton = props => {
  const id = props.id;
  return (
    <div>
      <SubmitButton id={id} />
    </div>
  );
};
