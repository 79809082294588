import React from 'react';

import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';

import styles from './plusButton.module.scss';
import PlusIcon from '../../../assets/icon_plus_button.svg';
import {history} from '@/store';

const useStyles = makeStyles({
  button: {
    width: 'auto',
    padding: '0 2.5rem',
    backgroundColor: '#FFF',
    border: '0.1rem solid #093791',
    color: '#093791',
    fontSize: '1.4rem',
    borderRadius: '4rem',
    height: '4rem'
  }
});

const PlusButton = ({text, handleOnClick}) => {
  const classes = useStyles();

  return (
    <Button className={classes.button} onClick={handleOnClick}>
      <img className={styles.plusIcon} src={PlusIcon} />
      {text}
    </Button>
  );
};

export default PlusButton;
