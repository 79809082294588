import React from 'react';
import {SnackbarProvider} from 'notistack';
import SnackbarContent from '../SnackbarContent';
import Notifier from '../Notifier';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    top: '5rem'
  }
}));

const SnackbarProviderWrapper = ({children}) => {
  const classes = useStyles();
  return (
    <SnackbarProvider
      classes={{root: classes.root}}
      maxSnack={3}
      autoHideDuration={4000}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      content={(key, message) => <SnackbarContent id={key} message={message} />}
    >
      <Notifier />
      {children}
    </SnackbarProvider>
  );
};

export default SnackbarProviderWrapper;
