import React from 'react';
import Plane from '../../assets/icon_plane.svg';
import style from './fullScreenMessage.module.scss';
import PanelLayout from '../../containers/layout/PanelLayout';

const FullScreenMessage = ({title, children}) => {
  return (
    <PanelLayout>
      <div className={style.container}>
        <div className={style.item}>
          <div className={style.main}>
            <div className={style.image}>
              <img src={Plane} />
            </div>
            <p className={style.title}>{title}</p>
            <div className={style.mainContent}>{children}</div>
          </div>
        </div>
      </div>
    </PanelLayout>
  );
};

export default FullScreenMessage;
