import React from 'react'

import style from './moreFetchButton.module.scss'

import Button from "@material-ui/core/Button/index";
import {createMuiTheme} from "@material-ui/core";
import {MuiThemeProvider} from "@material-ui/core/styles/index";

// img
import Arrow from "../../../assets/icon_arrow_up.svg";

const theme = createMuiTheme({
  overrides: {
    MuiButtonBase: {
      root: {
        width: "50%"
      }
    },
    MuiButton: {
      root: {
        borderRadius: "none",

      },
      containedSecondary: {
        color: "#093791",
        fontSize: "1.4rem",
        backgroundColor: "white",
        width: "100%",
        "&:hover": {
          backgroundColor: "#ebebeb",
        },
      },
      contained: {
        boxShadow: "none"
      }
    },
    MuiIconButton: {
      root: {
        padding: 0
      }
    }
  }
});

const Index = ({onClickHandler, selectedRoomId, messagesCurrent}) => {
  return (
    <>
      <MuiThemeProvider theme={theme}>
        <Button variant="contained"
                component="span"
                color="secondary"
                onClick={() => onClickHandler({
                  roomId: selectedRoomId,
                  messagesCurrent: messagesCurrent
                })}>
          <img src={Arrow}/>
          <p>
            &nbsp;&nbsp;過去の5件を表示
          </p>
        </Button>
      </MuiThemeProvider>
    </>
  )
}

export default Index
