import React, {Component, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {withRouter, useHistory} from 'react-router-dom';
// my components
import {
  requestProjectList,
  requestOwnProjectList
} from '../../../actions/project';
import List from '../../../components/Project/List';
import MainTitle from '../../../components/MainTitle/';
import PlusButton from '../../../assets/icon_plus_button.svg';
import style from './project.module.scss';
import Paginate from '../../../components/Paginate';
// material ui
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core';
import NotAvailable from '../../../components/NotAvailable';
import AboutProject from '../../../components/AboutProject';
import Blank from '../../../components/Blank';

const useStyles = makeStyles(theme => ({
  button: {
    border: '0.1rem solid #093791',
    color: '#093791',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '1.5rem',
    lineHeight: '2rem',
    textAlign: 'center',
    marginLeft: 'auto',
    borderRadius: '4rem',
    height: '4rem',
    width: 'fit-content',
    padding: '2.2rem 1.5rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.1rem',
      lineHeight: '1.5rem',
      marginLeft: '1rem'
    }
  },
  outlined: {
    [theme.breakpoints.down('xs')]: {
      padding: '1rem 0.6rem'
    }
  }
}));

const Project = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const projectSelector = useSelector(state => state.project);
  const companyIdSelector = useSelector(state => state.setting.companyId);
  const classes = useStyles();

  useEffect(() => {
    if (companyIdSelector !== null) {
      dispatch(requestOwnProjectList());
    }
  }, [companyIdSelector]);

  const {list, count, next, previous} = projectSelector;

  return (
    <>
      <div className={style.container}>
        <MainTitle
          titleStr={'プロジェクト一覧'}
          pattern={false}
          component={
            <Button
              variant="outlined"
              classes={{root: classes.button, outlined: classes.outlined}}
              onClick={() => {
                history.push('/co/project/create');
              }}
            >
              <img src={PlusButton} className={style.plusButtonImg} />
              新規プロジェクト
            </Button>
          }
        />
        <AboutProject />
        <Blank height={'2rem'} />
        {list && list.length === 0 && <NotAvailable kind="project-create" />}
        {list && <List list={list} />}
        <Paginate
          limit={10}
          total={count}
          {...props}
          method={page => dispatch(requestOwnProjectList(page))}
        />
      </div>
    </>
  );
};

export default Project;
