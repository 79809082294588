import React from 'react';
import styles from '../../commonModal.module.scss';
import IconSearch from '../../../../assets/icon_task_search_blue.svg';
import {requestTaskEdit, setModalSetting} from '../../../../actions/task';
import {moveToMessage} from '../../../../common';

import CommonTaskButton from '../../../Buttons/CommonTaskButton';
import {TASK_STATUS} from '../../../../common';
import {useDispatch, useSelector} from 'react-redux';
import store from 'store';
import {API_HOST, SCHEME} from '../../../../config';
import {MESSAGE_TEMPLATE} from '../../../../constants';
import MoveToMessageButton from '../../../MoveToMessageButton';

const PreAssignConfirmCo = ({params}) => {
  const dispatch = useDispatch();
  const {detail} = useSelector(state => state.task);

  return (
    <div className={styles.inner}>
      <div className={styles.header}>
        <img className={styles.image} src={IconSearch} />
        <div className={styles.subTitle}>仮アサインの確認</div>
      </div>
      <div className={styles.message}>
        <p>下記のタスクが仮アサインの状態です。</p>
        <p>
          契約について、担当のエンジニアとメッセージでやりとりをしてください。
        </p>
        <p>エンジニアがアサイン依頼を承諾した時点で、タスクが開始されます。</p>
      </div>
      {/* paramsの初期値が空のオブジェクトなので 配列であること確認しないとエラー起こす*/}
      {Array.isArray(params) ? (
        params.map((item, index) => (
          <Content task={item} key={index.toString()} />
        ))
      ) : (
        <></>
      )}
    </div>
  );
};

const Content = ({task}) => {
  const roomList = useSelector(state => state.message.rooms);
  const dispatch = useDispatch();
  const TASK_URL = `${SCHEME}://${API_HOST}/en/project/${task.project}/task/${task.id}`;
  const CONTENTS = MESSAGE_TEMPLATE.replace('TASK_URL', TASK_URL);
  const params = {title: 'アサインの依頼', content: CONTENTS};
  const readPreAssignTasks =
    store.get('readPreAssignTasks') === undefined
      ? []
      : store.get('readPreAssignTasks');
  return (
    <div className={styles.row}>
      <p className={styles.rowTitle}>{task.title}</p>
      <MoveToMessageButton
        userId={task.pre_worker}
        params={params}
        onClickSideEffect={() => {
          readPreAssignTasks.push(task.id);
          store.set('readPreAssignTasks', readPreAssignTasks);
          dispatch(
            setModalSetting({
              type: 'UNREAD_PRE_ASSIGN_CONFIRM_CO',
              isVisible: false,
              params: []
            })
          );
        }}
      />
    </div>
  );
};

export default PreAssignConfirmCo;
