import {COLOR_PALLET} from '../../constants';

export const COLOR = {
  pink: {
    backgroundColor: COLOR_PALLET.pink1000,
    color: COLOR_PALLET.white
  },
  blue: {
    backgroundColor: COLOR_PALLET.blue900,
    color: COLOR_PALLET.white
  },
  green: {
    backgroundColor: COLOR_PALLET.green1000,
    color: COLOR_PALLET.white
  },
  orange: {
    backgroundColor: COLOR_PALLET.orange1000,
    color: COLOR_PALLET.white
  }
};

export const SHAPE = {
  normal: {
    borderRadius: '0.4rem'
  }
};

export const SIZE = {
  medium: {
    width: '6rem',
    height: '2rem'
  }
};

export const FONT_SIZE = {
  medium: {
    fontSize: '1.2rem'
  }
};
