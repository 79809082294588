import React from 'react';

import styles from './mobileCoMenu.module.scss';
import MenuButton from '../../../components/Button/MenuButton';
import {MENUES} from '../../../constants';
import MediaQuery from 'react-responsive';
import DehazeIcon from '@material-ui/icons/Dehaze';
import IconButton from '@material-ui/core/IconButton';

// TODO: 「エンジニアを探す」は２次以降
const MobileCoMenu = () => {
  return (
    <>
      <div className={styles.underBackground} />
      <div className={styles.background}>
        <MenuButton name="TOP" page={MENUES.dashboard} kind={'co'} />
        <MenuButton name="プロジェクト" page={MENUES.project} kind={'co'} />
        <MenuButton name="企業情報" page={MENUES.company} kind={'co'} />
        <MenuButton name="企業メンバー" page={MENUES.member} kind={'co'} />
        <MenuButton name="メッセージ" page={MENUES.email} kind={'co'} />
        <MenuButton name="報酬管理" page={MENUES.rewardAdmin} />
        <MenuButton
          name="お支払い方法の設定"
          page={MENUES.paymentMethod}
          kind={'co'}
        />
      </div>
    </>
  );
};

export default MobileCoMenu;
