import {call, put, take, fork} from 'redux-saga/effects';
import {startSubmit, stopSubmit, reset} from 'redux-form';
import {push} from 'connected-react-router';
import {
  fetchPostJson,
  fetchPatch,
  fetchGet,
  fetchDelete,
  fetchPostTask
} from './utills/fetch';
import {
  requestPostAssign,
  requestDeleteAssign,
  requestPreAssign,
  requestAcceptPreAssign,
  requestRejectPreAssign,
  requestOffer
} from '../actions/assign';
import {
  setOpenFlag,
  requestEntryList,
  requestDeleteEntry
} from '../actions/entry';
import {setModalSetting, requestTaskDetail} from '../actions/task';
import {setIsSubmitting, setModalErrorMsg} from '../actions/common';

// タスクにエンジニアをアサインする
export function* postEngineer() {
  while (true) {
    const action = yield take(requestPostAssign);
    const payload = {worker: action.payload.worker};
    const [data, error] = yield call(
      fetchPatch,
      `tasks/${action.payload.taskId}`,
      payload
    );
    if (data && !error) {
      console.log('success');
      yield put(setOpenFlag(true));
    } else {
      console.log('error');
    }
  }
}

// 企業ユーザがタスクにエンジニアを仮アサインする
export function* preAssign() {
  while (true) {
    const action = yield take(requestPreAssign);

    const payload = {pre_worker: action.payload.worker};
    const [data, error] = yield call(
      fetchPostJson,
      `tasks/${action.payload.taskId}/pre-assign`,
      payload
    );
    if (data && !error) {
      yield put(setOpenFlag(true));
      yield put(requestTaskDetail({id: action.payload.taskId}));
    } else {
      console.log('error');
    }
  }
}

// 企業ユーザがタスクにエンジニアを仮アサインする（エンジニア検索画面）
export function* postOffer() {
  while (true) {
    const action = yield take(requestOffer);
    const payload = {pre_worker: action.payload.worker};
    yield put(setIsSubmitting(true));
    const [data, error] = yield call(
      fetchPostJson,
      `tasks/${action.payload.taskId}/pre-assign`,
      payload
    );

    if (data && !error) {
      action.payload.setIndex();
      yield put(setIsSubmitting(false));
    } else {
      yield put(setModalErrorMsg(error.detail));
    }
  }
}

// エンジニアが仮アサインを承諾する
export function* acceptPreAssign() {
  while (true) {
    const action = yield take(requestAcceptPreAssign);
    const [data, error] = yield call(
      fetchPostJson,
      `tasks/${action.payload.taskId}/assign`,
      {assign: true}
    );
    if (data && !error) {
      yield put(
        setModalSetting({type: 'ACCEPT_ASSIGN_OFFER', isVisible: false})
      );
      yield put(requestTaskDetail({id: action.payload.taskId}));
      yield put(setIsSubmitting(false));
    } else {
      console.log('error');
    }
  }
}

// エンジニアが仮アサインを辞退する
export function* rejectPreAssign() {
  while (true) {
    const action = yield take(requestRejectPreAssign);
    const {taskId, entryId} = action.payload;
    const [data, error] = yield call(fetchPostJson, `tasks/${taskId}/assign`, {
      assign: false
    });

    if (data && !error) {
      yield put(
        setModalSetting({type: 'REJECT_ASSIGN_OFFER', isVisible: false})
      );
      yield put(requestTaskDetail({id: action.payload.taskId}));
    } else {
      console.log('error');
    }
  }
}

// アサインの取り消し
export function* deleteEngineer() {
  while (true) {
    const action = yield take(requestDeleteAssign);
    yield put(startSubmit('tasks'));
    const [data, error] = yield call(
      fetchDelete,
      `tasks/${action.payload.id}`,
      action.payload
    );
    if (data && !error) {
      yield put(reset('tasks'));
      yield put(push(`/co/tasks/`));
    } else {
      yield put(
        stopSubmit(
          'Tasks',
          Object.assign(error, {_error: error.non_field_errors})
        )
      );
    }
  }
}

const Assign = [
  fork(postEngineer),
  fork(deleteEngineer),
  fork(preAssign),
  fork(acceptPreAssign),
  fork(rejectPreAssign),
  fork(postOffer)
];

export default Assign;
