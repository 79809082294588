import React from 'react';
import FadeIn from 'react-fade-in';
import {useHistory} from 'react-router-dom';
import style from './panelLayout.module.scss';
import FooterPage from '../../components/Footer/FooterPage';
import CommonButton from '../../components/CommonButton';
import {mediaQuery} from '../../constants';
import MediaQuery from 'react-responsive';

import Logo from '../../assets/logo_white.svg';

const PanelLayout = ({children}) => {
  const history = useHistory();
  return (
    <div>
      <FadeIn>
        <div className={style.panel}>
          <div className={style.headerContainer}>
            <MediaQuery query={mediaQuery.min}>
              <div className={style.requestDocument}>
                <CommonButton
                  text={'案件を見てみる'}
                  size={'small'}
                  color={'blueLight'}
                  variant={'contained'}
                  shape={'normal'}
                  onClick={() => history.push('/public/tasks')}
                  disabled={false}
                />
              </div>
              <div className={style.ContactUs}>
                <CommonButton
                  text={'お問い合わせ'}
                  size={'small'}
                  color={'pink'}
                  variant={'contained'}
                  shape={'normal'}
                  onClick={() => history.push('/contact/main')}
                  disabled={false}
                />
              </div>
            </MediaQuery>
            <MediaQuery query={mediaQuery.max}>
              <div className={style.requestDocument}>
                <CommonButton
                  text={'案件を見てみる'}
                  size={'minimum'}
                  color={'blueLight'}
                  variant={'contained'}
                  shape={'normal'}
                  onClick={() => history.push('/public/tasks')}
                  disabled={false}
                />
              </div>
              <div className={style.blank}></div>
              <div>
                <CommonButton
                  text={'お問い合わせ'}
                  size={'minimum'}
                  color={'pink'}
                  variant={'contained'}
                  shape={'normal'}
                  onClick={() => history.push('/contact/main')}
                  disabled={false}
                />
              </div>
            </MediaQuery>
          </div>
          <div className={style.logo}>
            <img src={Logo} />
          </div>
          <main className={style.inner}>{children}</main>
          <FooterPage isNormal={false} />
        </div>
      </FadeIn>
    </div>
  );
};

export default PanelLayout;
