// TODO: タスク詳細画面とメッセージ画面で共通して使える様に綺麗にリファクタリングする
import style from './privateComment.module.scss';
import Avatar from '@material-ui/core/Avatar';
import faker from 'faker';
import More from '../More';
import React from 'react';
import FormatDate from '../FormatDate';
import {AvatarImg} from '../../common';
import AllowIndentionArea from '../AllowIndentionArea';
import {postTime} from '../../common';
import UrlIncludeArea from '../UrlIncludeArea';

const Index = ({comment, user, me}) => {
  let otherUserName = '';
  let otherUserImg = null;
  if (user[comment.data.from_id]) {
    otherUserName = user[comment.data.from_id].nick_name;
    otherUserImg = user[comment.data.from_id].img;
  }
  const username =
    comment.data.from_id === me.id ? me.nick_name : otherUserName;
  const userImg = comment.data.from_id === me.id ? me.img : otherUserImg;

  return (
    <li className={style.mainPrivateMemo}>
      <div>
        <div className={style.privateMsgTextContainer}>
          <div className={style.privateMsgTextItems}>
            <div className={style.flexContainerPrivateMsg}>
              <div className={style.avatar}>
                <AvatarImg img={userImg} size="minimum" />
              </div>
              <div>
                <p className={style.name}>
                  {comment.user ? comment.user.username : username}
                </p>
                <span className={style.date}>
                  {postTime(comment.data.created_at.toDate())}
                </span>
              </div>
            </div>
            <AllowIndentionArea>
              <UrlIncludeArea
                text={comment.text ? comment.text : comment.data.content}
              />
            </AllowIndentionArea>
          </div>
        </div>
      </div>
      <div className={style.more}>
        <div className={style.moreAbsolute}>
          {/*TODO: メッセージのBookMarkとかしたくなった時に使うと思うのでコメントアウトにしておく*/}
          {/*<More />*/}
        </div>
      </div>
    </li>
  );
};

export default Index;
