import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {requestGithubSignIn} from '../../actions/sign';
import {useHistory, useLocation} from 'react-router-dom';
import JumpPage from '../../components/Button/OutlinedButton/JumpPage';
import styles from './oauth.module.scss';
import NotFoundImg from '../../assets/404.svg';

const Oauth = () => {
  // GithubサインインとFirebaseサインインが完了するまで待機するコンポーネントです
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const code = location.search.split('=')[1];
  const isLoggedIn = useSelector(state => state.firebase.isLoggedIn);

  useEffect(() => {
    dispatch(
      requestGithubSignIn({
        code: code
      })
    );
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      history.push('/en');
    }
  });

  return (
    <section className={styles.background}>
      <div className={styles.container}>
        <h3 className={styles.notFound}>ログイン中</h3>
        <h2 className={styles.header}>少々お待ちください</h2>
      </div>
    </section>
  );
};

export default Oauth;
