import React from 'react';
import {useDispatch} from 'react-redux';
import style from '../../containers/co/Task/Detail/detail.module.scss';
import {AvatarImg, postTime} from '../../common';
import AllowIndentionArea from '../AllowIndentionArea';
import ClipBlue from '../../assets/icon_clip_blue.svg';
import {requestDownloadAuthFile} from '../../actions/common';
import Link from '@material-ui/core/Link';

const CommentItem = ({comment}) => {
  return (
    <li className={style.mainPrivateMemo}>
      <div>
        <div className={style.privateMsgTextContainer}>
          <div className={style.privateMsgTextItems}>
            <div className={style.flexContainerPrivateMsg}>
              <div className={style.avatar}>
                <div>
                  <AvatarImg img={comment.user.img} size="minimum" />
                </div>
              </div>
              <div>
                <p className={style.name}>{comment.user.nick_name}</p>
                <span className={style.date}>
                  {postTime(comment.created_at)}
                </span>
                <CommentAttachments files={comment.file_info} />
              </div>
            </div>
            <AllowIndentionArea>
              <p className={style.privateMsgText}>{comment.text}</p>
            </AllowIndentionArea>
          </div>
        </div>
      </div>
    </li>
  );
};

const CommentAttachments = ({files}) => {
  const dispatch = useDispatch();
  return (
    <div>
      {files &&
        files.map((item, index) => {
          return (
            <div className={style.addFlex} key={index.toString()}>
              <img src={ClipBlue} className={style.iconMargin} />
              <Link
                className={style.addFlex}
                component="button"
                variant="body2"
                onClick={() =>
                  dispatch(
                    requestDownloadAuthFile({
                      url: item.file.replace(':3000', ':8000'),
                      filename: item.file_name
                    })
                  )
                }
              >
                <p>{item.file_name}</p>
              </Link>
            </div>
          );
        })}
    </div>
  );
};

export default CommentItem;
