import {createAction} from 'redux-actions';

// 自身のユーザ種別をセット(isEngineer)
export const SET_IS_ENGINEER = 'SET_IS_ENGINEER';
export const setIsEngineer = createAction(SET_IS_ENGINEER);

// プロフィール取得
export const REQUEST_ME = 'REQUEST_ME';
export const SUCCESS_ME = 'SUCCESS_ME';
export const requestMe = createAction(REQUEST_ME);
export const successMe = createAction(SUCCESS_ME);

// プロフィール編集
export const REQUEST_ME_EDIT = 'REQUEST_ME_EDIT';
export const SUCCESS_ME_EDIT = 'SUCCESS_ME_EDIT';
export const requestMeEdit = createAction(REQUEST_ME_EDIT);
export const successMeEdit = createAction(SUCCESS_ME_EDIT);

// 本人確認情報の編集
export const REQUEST_EDIT_SECURE_INFO = 'REQUEST_EDIT_SECURE_INFO';
export const requestEditSecureInfo = createAction(REQUEST_EDIT_SECURE_INFO);

// 設定取得(?)
export const REQUEST_SETTINGS = 'REQUEST_SETTINGS';
export const SUCCESS_SETTINGS = 'SUCCESS_SETTINGS';
export const requestSettings = createAction(REQUEST_SETTINGS);
export const successSettings = createAction(SUCCESS_SETTINGS);

// 自分のタスク
export const REQUEST_MY_TASK = 'REQUEST_MY_TASK';
export const SUCCESS_MY_TASK = 'SUCCESS_MY_TASK';
export const requestMyTask = createAction(REQUEST_MY_TASK);
export const successMyTask = createAction(SUCCESS_MY_TASK);

// 自分がエントリーしているタスク
export const REQUEST_ENTRY_TASK = 'REQUEST_ENTRY_TASK';
export const SUCCESS_ENTRY_TASK = 'SUCCESS_ENTRY_TASK';
export const requestEntryTask = createAction(REQUEST_ENTRY_TASK);
export const successEntryTask = createAction(SUCCESS_ENTRY_TASK);

// 検討中リスト
export const REQUEST_BOOKMARK_TASK = 'REQUEST_BOOKMARK_TASK';
export const SUCCESS_BOOKMARK_TASK = 'SUCCESS_BOOKMARK_TASK';
export const requestBookmarkTask = createAction(REQUEST_BOOKMARK_TASK);
export const successBookmarkTask = createAction(SUCCESS_BOOKMARK_TASK);

// 所属している企業のIDをセット
export const SET_COMPANY_ID = 'SET_COMPANY_ID';
export const setCompanyId = createAction(SET_COMPANY_ID);

// 自分が仮アサインされているタスク一覧をセット
export const REQUEST_PRE_ASSIGN_LIST = 'REQUEST_PRE_ASSIGN_LIST';
export const requestPreAssignList = createAction(REQUEST_PRE_ASSIGN_LIST);
export const SUCCESS_PRE_ASSIGN_LIST = 'SUCCESS_PRE_ASSIGN_LIST';
export const successPreAssignList = createAction(SUCCESS_PRE_ASSIGN_LIST);

export const REQUEST_ACCEPT_TERMS_OF_SERVICE =
  'REQUEST_ACCEPT_TERMS_OF_SERVICE';
export const requestAcceptTermsOfService = createAction(
  REQUEST_ACCEPT_TERMS_OF_SERVICE
);

export const SYNC_LOCAL_STORAGE_BASE_VALUES =
  'SYNC_LOCAL_STORAGE_BASE_VALUES';
export const syncLoalStorageBaseValues = createAction(
  SYNC_LOCAL_STORAGE_BASE_VALUES
);
