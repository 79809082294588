export function getBlob(url, name) {
  return fetch(url)
    .then(response => response.blob())
    .then(blob => {
      const blobURL = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = blobURL;
      a.style = 'display: none';

      if (name && name.length) a.download = name;
      document.body.appendChild(a);
      a.click();
      a.remove();
      return ['success', null];
    })
    .catch(() => [null, 'error']);
}
