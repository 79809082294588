import {
  all,
  call,
  put,
  takeEvery,
  take,
  fork,
  select
} from 'redux-saga/effects';
import {history} from '../store';
import {fetchPostTask, fetchGet} from './utills/fetch';
import {
  requestPostReviewCo,
  requestPostReviewEn,
  setReviewModalFlag,
  requestMustReviewTasks,
  successMustReviewTasks,
  requestCoReviews,
  requestEnReviews,
  successCoReviews,
  successEnReviews
} from '../actions/review';
import {requestTaskDetail} from '../actions/task';

// エンジニアが企業を評価するPOST
export function* postEvalCompany() {
  while (true) {
    const action = yield take(requestPostReviewCo);
    const [data, error] = yield call(
      fetchPostTask,
      `companies/${action.payload.companyId}/reviews`,
      action.payload
    );
    if (data && !error) {
      yield put(requestTaskDetail({id: action.payload.task}));
      yield put(setReviewModalFlag(false));
    } else {
      history.push('/sorry');
    }
  }
}

// 企業がエンジニアを評価するPOST
export function* postEvalEngineer() {
  while (true) {
    const action = yield take(requestPostReviewEn);
    const [data, error] = yield call(
      fetchPostTask,
      `en-users/${action.payload.userId}/reviews`,
      action.payload
    );
    if (data && !error) {
      yield put(requestTaskDetail({id: action.payload.task}));
      yield put(setReviewModalFlag(false));
    } else {
      history.push('/sorry');
    }
  }
}

// 評価が必要なタスク一覧を取得（企業）
export function* getMustReviewTasks() {
  while (true) {
    yield take(requestMustReviewTasks);
    const [data, error] = yield call(fetchGet, `review-required-tasks`);
    if (data && !error) {
      yield put(successMustReviewTasks(data.results));
    } else {
      if (error === 401) continue;
      history.push('/sorry');
    }
  }
}

// 企業の評価一覧を取得
export function* getCoReviews() {
  while (true) {
    const action = yield take(requestCoReviews);
    const [data, err] = yield call(
      fetchGet,
      `companies/${action.payload.id}/reviews/`
    );
    if (data && !err) {
      yield put(successCoReviews(data.results));
    } else {
      history.push('/sorry');
    }
  }
}

//ユーザの評価一覧を取得
export function* getEnReviews() {
  while (true) {
    const action = yield take(requestEnReviews);
    // const companyId = yield select(state => state.setting.companyId)
    const [data, err] = yield call(
      fetchGet,
      `en-users/${action.payload.id}/reviews/`
    );
    if (data && !err) {
      yield put(successEnReviews(data.results));
    } else {
      history.push('/sorry');
    }
  }
}

const Review = [
  fork(postEvalCompany),
  fork(postEvalEngineer),
  fork(getMustReviewTasks),
  fork(getCoReviews),
  fork(getEnReviews)
];

export default Review;
