import {call, put, take, fork, select} from 'redux-saga/effects';
import {
  requestSystemNewsList,
  successSystemNewsList,
  successHeadSystemNewsList,
  setSystemNewsLastSnapshot,
  requestHandleOnSnapshot
} from '../actions/systemNews';
import {
  fetchSystemNotification,
} from '../firebase/systemNotification';
import {setIsReadAllSystemNotification} from '../actions/systemNews';
import {formatQuerySnapshot} from '../firebase/common'

export function* getSystemNewsList() {
  while (true) {
    yield take(requestSystemNewsList);
    const systemNewsLastSnapshot = yield select(
      state => state.systemNews.systemNewsLastSnapshot
    );
    const [systemNewsList, lastSnapshot, err] = yield call(
      fetchSystemNotification,
      systemNewsLastSnapshot
    );
    if (!err) {
      yield put(successSystemNewsList(systemNewsList));
      yield put(setSystemNewsLastSnapshot(lastSnapshot));
    }
  }
}

export function* handleOnSnapshot() {
  while (true) {
    const {payload: querySnapshot} = yield take(requestHandleOnSnapshot);
    const systemNewsList = formatQuerySnapshot(querySnapshot);
    yield put(successHeadSystemNewsList(systemNewsList));

    const isReadAll = systemNewsList.every(item => {
      // TODO: システム通知の未読・既読 機能は未実装だということが判明
      return true;
    });
    yield put(setIsReadAllSystemNotification(isReadAll));
  }
}

const SystemNews = [fork(getSystemNewsList), fork(handleOnSnapshot)];

export default SystemNews;
