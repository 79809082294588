import React, {FC, MouseEventHandler} from 'react';
import CommonButton from '../../CommonButton';
import Blank from '../../Blank';
import MoveToMessageButton from '../../MoveToMessageButton';
import {EngineerItemState} from '../../../models/UserModels';

type Props = {
  item: EngineerItemState;
  onClick: any;
};

const EngineerSearchButtons: FC<Props> = ({item, onClick}) => {
  const handleOnClick = (e: MouseEvent) => {
    onClick(item);
    e.stopPropagation();
  };

  return (
    <React.Fragment>
      {/*@ts-ignore*/}
      <CommonButton
        text={'オファー'}
        size={'preMinimum'}
        color={'pink'}
        variant={'contained'}
        shape={'normal'}
        onClick={handleOnClick}
        disabled={false}
      />
      <Blank height={'1rem'} width={null} />
      <MoveToMessageButton userId={item.id} size={'preMinimum'} />
    </React.Fragment>
  );
};

export default EngineerSearchButtons;
