import {createBrowserHistory} from 'history';
import {applyMiddleware, compose, createStore} from 'redux';
import {routerMiddleware} from 'connected-react-router';
import createRootReducer from './reducers';
import createSagaMiddleware from 'redux-saga';
import sagaMonitor from './common/sagaMonitor';
import rootSaga from './sagas';

export const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware({sagaMonitor: sagaMonitor});

const bindMiddleware = () => {
  if (process.env.REACT_APP_TARGET_ENV !== 'production') {
    const {composeWithDevTools} = require('redux-devtools-extension');
    return composeWithDevTools(
      compose(applyMiddleware(routerMiddleware(history), sagaMiddleware))
    );
  }
  return compose(applyMiddleware(routerMiddleware(history), sagaMiddleware));
};

export let store = null;

export const rootReducer = createRootReducer(history);

export default function configureStore(preloadedState) {
  store = createStore(rootReducer, preloadedState, bindMiddleware());

  sagaMiddleware.run(rootSaga);

  return store;
}
