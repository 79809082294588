import {createAction} from 'redux-actions';

// firestore からトークルーム一覧を取得
export const REQUEST_ROOM_LIST = 'REQUEST_ROOM_LIST';
export const SUCCESS_ROOM_LIST = 'SUCCESS_ROOM_LIST';
export const requestRoomList = createAction(REQUEST_ROOM_LIST);
export const successRoomList = createAction(SUCCESS_ROOM_LIST);

// firestore のトークルーム一覧で、どこまで取得したかを保存
export const SET_ROOM_CURRENT_PAGE = 'SET_ROOM_CURRENT_PAGE';
export const setRoomCurrentPage = createAction(SET_ROOM_CURRENT_PAGE);

// firestore から メッセージ一覧を取得
export const REQUEST_MESSAGE_LIST = 'REQUEST_MESSAGE_LIST';
export const SUCCESS_MESSAGE_LIST = 'SUCCESS_MESSAGE_LIST';
export const requestMessageList = createAction(REQUEST_MESSAGE_LIST);
export const successMessageList = createAction(SUCCESS_MESSAGE_LIST);

// firestore のメッセージ一覧で、どこまで取得したかを保存
export const SET_MESSAGE_CURRENT_PAGE = 'SET_MESSAGE_CURRENT_PAGE';
export const setMessageCurrentPage = createAction(SET_MESSAGE_CURRENT_PAGE);

// 選択中のトークルーム情報を保持
export const SET_SELECTED_ROOM_INFO = 'SET_SELECTED_ROOM_INFO';
export const setSelectedRoomInfo = createAction(SET_SELECTED_ROOM_INFO);

// 選択中のトークルームのやり取りの相手を保持
export const SET_OTHER_PERSON_ID = 'SET_OTHER_PERSON_ID';
export const setOtherPersonId = createAction(SET_OTHER_PERSON_ID);

// トークルームを選択した際に行う初期設定
export const REQUEST_INIT_ROOMS = 'REQUEST_INIT_ROOMS';
export const requestInitRooms = createAction(REQUEST_INIT_ROOMS);

// 選択中のトークルームを変更した際に、動かすクリーナー
export const RESET_MESSAGES = 'RESET_MESSAGES';
export const resetMessages = createAction(RESET_MESSAGES);

// テキストフィールドに入力した値を保持
export const SET_MESSAGE_TEXT = 'SET_MESSAGE_TEXT';
export const setMessageText = createAction(SET_MESSAGE_TEXT);

// firestore にPOST
export const REQUEST_POST_MESSAGE = 'REQUEST_POST_MESSAGE';
export const requestPostMessage = createAction(REQUEST_POST_MESSAGE);

// 新規でメッセージをPOST
export const REQUEST_POST_NEW_MESSAGE = 'REQUEST_POST_NEW_MESSAGE';
export const requestPostNewMessage = createAction(REQUEST_POST_NEW_MESSAGE);

export const REQUEST_CHECK_ROOM_STATUS = 'REQUEST_CHECK_ROOM_STATUS';
export const requestCheckRoomStatus = createAction(REQUEST_CHECK_ROOM_STATUS);
export const SUCCESS_CHECK_ROOM_STATUS = 'SUCCESS_CHECK_ROOM_STATUS';
export const successCheckRoomStatus = createAction(SUCCESS_CHECK_ROOM_STATUS);
