import {
  SET_ERROR,
  SET_ERROR_INFO
} from '../actions/errorBoundary';

const initialState = {
  error: false,
  errorInfo: [],
};

function errorBoundary(state = initialState, action) {
  switch (action.type) {
    case SET_ERROR:
      return {
        ...state,
        error :action.payload
      }
    case SET_ERROR_INFO:
      return {
        ...state,
        errorInfo :action.payload
      }
    default:
      return state
  }
}

export default errorBoundary
