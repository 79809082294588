import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
// action
import {
  requestCreditEditUrl,
  requestPaymentState
} from '../../../actions/payment';
// my
import styles from './paymentSetting.module.scss';
import PageTitle from '../../../components/PageTitle';
import ContentTitle from '../../../components/ContentTitle';
import Blank from '../../../components/Blank';
import OutlineButton from '../../../components/Buttons/OutlineButton';
import {useHistory, useLocation} from 'react-router-dom';
import {PAYMENT_STATUS_PAID} from '../../../constants';

const PaymentSetting = () => {
  const dispatch = useDispatch();
  const companyId = useSelector(state => state.setting.companyId);
  const {editUrl, paymentState} = useSelector(state => state.payment);
  const history = useHistory();
  const {state} = useLocation();

  useEffect(() => {
    dispatch(requestPaymentState());
    if (companyId && state && state.TaskId) {
      // 「お支払い方法の設定」を押下して遷移してきた時の処理
      dispatch(requestCreditEditUrl({taskId: state.TaskId}));
    } else if (companyId) {
      dispatch(requestCreditEditUrl({}));
    }
  }, [companyId]);

  return (
    <div>
      <PageTitle title="お支払い方法の設定" />
      <div className={styles.container}>
        <div className={styles.item}>
          <ContentTitle title="クレジットカード" isButton={false} />
          <Blank height={20} />
          <div className={styles.itemContainer}>
            <div className={styles.titleContainer}>
              <div className={styles.comNameContainer}>設定状況</div>
              <div className={styles.infoContainer}>
                {paymentState.credit.text}
              </div>
            </div>
          </div>
          <div className={styles.itemContainer}>
            <div className={styles.titleContainer}>
              <div className={styles.comNameContainer}>カード情報</div>
              <div className={styles.infoContainer}>
                <OutlineButton kind={'checkCredit'} link={editUrl} />
              </div>
            </div>
          </div>
        </div>
        <div>
          <ContentTitle title="請求書（Paid）" isButton={false} />
          <Blank height={20} />
          <div className={styles.itemContainer}>
            <div className={styles.titleContainer}>
              <div className={styles.comNameContainer}>設定状況</div>
              <div className={styles.infoContainer}>
                {paymentState.paid.text}
              </div>
            </div>
          </div>
          {paymentState.paid.status_code === PAYMENT_STATUS_PAID.notMember ? (
            <div className={styles.lastItemContainer}>
              <div className={styles.titleContainer}>
                <div className={styles.comNameContainer}>詳細</div>
                <div className={styles.infoContainer}>
                  <OutlineButton
                    kind={'initializePaid'}
                    onClickHandler={() => history.push('/co/init-paid')}
                  />
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default PaymentSetting;
