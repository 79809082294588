import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import style from './button.module.scss';
import PinWhite from '../../../assets/icon_pin_white.svg';
import PinBlue from '../../../assets/icon_pin_blue.svg';
import Clear from '../../../assets/icon_clear.svg';
import Snackbar from './../../Snackbar';
import {SimpleAlert} from './../../Alert';
import {
  isShowEntrySnackbar,
  isShowBookmarkSnackbar
} from './../../../actions/snackbar';

const Index = props => {
  const {kind, onClickHandler, onClick, alertSeverity} = props;
  const {EntrySnackbar, BookmarkSnackbar, placement} = useSelector(
    state => state.snackbar
  );
  const {isSubmitting} = useSelector(state => state.common);

  const dispatch = useDispatch();

  let applyBgColor = '';
  let applyHoverColor = '';
  let applyBorderColor = 'none';
  let valiant = 'contained';
  let applyColor = '#FFFFFF';
  let applyFontWeight = '';
  let text = '';
  let srcImg = null;
  let applyFontSize = '1.6rem';
  let disabled = isSubmitting;
  let isSnackbarOpen = false;
  let snackbarText = '';

  switch (kind) {
    case 'pink':
      applyBgColor = '#EA397D';
      applyHoverColor = '#b12a5e';
      applyFontWeight = 'bold';
      text = 'エントリーする';
      isSnackbarOpen = EntrySnackbar.isOpen;
      snackbarText = EntrySnackbar.text;
      break;
    case 'pinkOutlined':
      valiant = 'outlined';
      applyBorderColor = '0.1rem solid #EA397D';
      applyColor = '#EA397D';
      text = 'エントリーを取り消す';
      applyHoverColor = '#ffe7ef';
      applyFontSize = '1.4rem';
      isSnackbarOpen = EntrySnackbar.isOpen;
      snackbarText = EntrySnackbar.text;
      break;
    case 'blueOutlined':
      valiant = 'outlined';
      applyBorderColor = '0.1rem solid #093791';
      applyColor = '#093791';
      text = '検討中';
      srcImg = PinBlue;
      applyFontSize = '1.4rem';
      isSnackbarOpen = BookmarkSnackbar.isOpen;
      snackbarText = BookmarkSnackbar.text;
      break;
    case 'blue':
      applyBgColor = '#6C8AC4';
      applyHoverColor = '#506590';
      text = '検討リストに入れる';
      srcImg = PinWhite;
      applyFontSize = '1.4rem';
      isSnackbarOpen = BookmarkSnackbar.isOpen;
      snackbarText = BookmarkSnackbar.text;
      break;
    case 'orange':
      applyBgColor = '#F48400';
      applyHoverColor = '#b76300';
      applyFontWeight = 'bold';
      text = '評価する';
      break;
    case 'loading':
      applyBgColor = '#F48400';
      applyHoverColor = '#b76300';
      applyFontWeight = 'bold';
      text = '読み込み中';
      disabled = true;
      break;
    case 'nonActive':
      applyBgColor = '#F48400';
      applyHoverColor = '#b76300';
      applyFontWeight = 'bold';
      text = 'エントリー中';
      disabled = true;
    default:
      applyBgColor = '';
      applyHoverColor = '';
      valiant = 'contained';
      break;
  }

  const useStyles = makeStyles(theme => ({
    root: {
      margin: theme.spacing(1),
      backgroundColor: applyBgColor,
      border: applyBorderColor,
      color: applyColor,
      fontWeight: applyFontWeight,
      width: '20rem',
      height: '5rem',
      fontSize: applyFontSize,
      '&:hover': {
        backgroundColor: applyHoverColor
      },
      [theme.breakpoints.down('xs')]: {width: '100%', margin: '0'},
      '&.Mui-disabled': {
        color: 'rgba(0, 0, 0, 0.26)',
        boxShadow: 'none',
        backgroundColor: ' rgba(0, 0, 0, 0.12)'
      }
    },
    snackbar: {
      margin: theme.spacing(1),
      backgroundColor: applyBgColor,
      border: applyBorderColor,
      color: applyColor,
      fontWeight: applyFontWeight,
      width: '20rem',
      height: '5rem',
      fontSize: applyFontSize,
      '&:hover': {
        backgroundColor: applyHoverColor
      }
    }
  }));

  const classes = useStyles();

  const onCloseSnackbar = (event, reason) => {
    dispatch(isShowEntrySnackbar({isOpen: false, text: ''}));
    dispatch(isShowBookmarkSnackbar({isOpen: false, text: ''}));
  };

  return (
    <div className="stage">
      <Button
        variant={valiant}
        classes={{root: classes.root}}
        disabled={disabled}
        onClick={() => onClickHandler()}
      >
        <div className={style.buttonContainer}>
          <img src={srcImg} />
          <div className={style.text}>{text}</div>
        </div>
      </Button>
      <Snackbar
        alertSeverity={alertSeverity}
        isOpen={isSnackbarOpen}
        text={snackbarText}
        snackPlacement={placement}
        onClose={onCloseSnackbar}
      />
    </div>
  );
};

export default Index;
