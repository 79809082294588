import {all, call, put, take, fork, select} from 'redux-saga/effects';
import {selectSetting, selectUser} from './selectors/index';
import {fetchGet} from './utills/fetch';
import {
  requestCoUserInfo,
  successCoUserInfo,
  requestCoUsersInfo,
  successCoUsersInfo,
  requestEnUserInfo,
  successEnUserInfo,
  requestEnUsersInfo,
  successEnUsersInfo,
  requestSearchEngineers,
  successSearchEngineers
} from '../actions/user';

// 単体の企業ユーザの詳細を取得
export function* getCoUserDetail() {
  while (true) {
    const action = yield take(requestCoUserInfo);
    const [data, error] = yield call(
      fetchGet,
      `co-users/${action.payload.toId}/`
    );

    if (data && !error) {
      yield put(successCoUserInfo(data));
    } else {
      console.log('error!');
    }
  }
}

// トークルームに紐付く複数の企業ユーザの詳細を一気に取得
export function* getCoUsersDetail() {
  while (true) {
    const action = yield take(requestCoUsersInfo);
    try {
      const settingState = yield select(selectSetting);
      const userState = yield select(selectUser);

      const usersInfo = yield action.payload.roomList.map(room => {
        const toId = room.data.users.filter(
          elem => elem !== settingState.me.id
        )[0];
        return call(fetchGet, `co-users/${toId}/`);
      });

      const usersInfoList = yield all(usersInfo);
      let newUsers = makeTalkUserInfo(usersInfoList, userState.coUsers);
      yield put(successCoUsersInfo({usersInfoList: newUsers}));
    } catch (e) {
      console.error(e);
    }
  }
}

// 単体のエンジニアユーザの詳細を取得
export function* getEnUserDetail() {
  while (true) {
    const action = yield take(requestEnUserInfo);
    const [data, error] = yield call(
      fetchGet,
      `en-users/${action.payload.id}/`
    );
    if (data && !error) {
      yield put(successEnUserInfo({userInfo: data}));
    } else {
      console.log('error!');
    }
  }
}

// トークルームに紐付く複数のエンジニアユーザの詳細を一気に取得
export function* getEnUsersDetail() {
  while (true) {
    const action = yield take(requestEnUsersInfo);
    const settingState = yield select(selectSetting);
    const userState = yield select(selectUser);

    const usersInfo = yield action.payload.roomList.map(room => {
      const toId = room.data.users.filter(
        elem => elem !== settingState.me.id
      )[0];
      return call(fetchGet, `en-users/${toId}/`);
    });

    const usersInfoList = yield all(usersInfo);
    let newUsers = makeTalkUserInfo(usersInfoList, userState.enUsers);
    yield put(successEnUsersInfo({usersInfoList: newUsers}));
  }
}

const makeTalkUserInfo = (usersInfoList, currentUsers) => {
  let newUsers = currentUsers;

  for (let n = 0; n < usersInfoList.length; n++) {
    if (typeof usersInfoList[n][0] === 'undefined') continue;
    const userId = usersInfoList[n][0].id;
    const username = usersInfoList[n][0].username;
    const nick_name = usersInfoList[n][0].nick_name;
    const company_name = usersInfoList[n][0].name;
    const img = usersInfoList[n][0].img;

    newUsers[`${userId}`] = {
      username: username,
      nick_name: nick_name,
      company_name: company_name,
      img: img
    };
  }
  return newUsers;
};

export function* getEngineers() {
  while (true) {
    const {payload} = yield take(requestSearchEngineers);
    const searchParams = makeSearchParams({...payload});
    const [data, err] = yield call(fetchGet, `en-users/?${searchParams}`);
    if (data && !err) {
      yield put(successSearchEngineers(data));
    }
  }
}

const makeSearchParams = payload => {
  if (!payload) return '';
  if (payload.tags) payload['tags'] = payload.tags.map(item => item.name);
  const keyList = Object.keys(payload);
  const paramList = keyList.map(key => {
    return `&${key}=${payload[key]}`;
  });
  return paramList.join('');
};

const User = [
  fork(getCoUsersDetail),
  fork(getCoUserDetail),
  fork(getEnUserDetail),
  fork(getEnUsersDetail),
  fork(getEngineers)
];

export default User;
