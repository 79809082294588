import React from 'react';
import styles from '../../commonModal.module.scss';
import IconSearch from '../../../../assets/icon_task_search_blue.svg';
import CommonTaskButton from '../../../Buttons/CommonTaskButton';

// モーダルという性質上、ボタンは 1個 or 2個 しか無いので共通化しています
// In：buttonProps = { text: "sample", onClick: () => sample() }
const CommonConfirm = ({
  message,
  title,
  icon = IconSearch,
  type,
  leftButtonProps = null,
  rightButtonProps = null,
  buttonProps = null,
}) => {
  return (
    <div className={styles.inner}>
      <div className={styles.header}>
        <img className={styles.image} alt={'アイコン'} src={icon} />
        <div className={styles.subTitle}>{title}</div>
      </div>
      <div className={styles.message}>{message}</div>
      <div className={styles.button}>
        <SwitchButtons
          buttonProps={buttonProps}
          leftButtonProps={leftButtonProps}
          rightButtonProps={rightButtonProps}
        />
      </div>
    </div>
  );
};

const SwitchButtons = ({leftButtonProps, rightButtonProps, buttonProps}) => {
  if (buttonProps !== null) {
    // ボタン１箇パターン
    return (
      <CommonTaskButton
        text={buttonProps.text}
        onClickHandler={buttonProps.onClick}
        kind={'pink'}
      />
    );
  } else {
    // ボタン２箇パターン
    return (
      <>
        <CommonTaskButton
          text={leftButtonProps.text}
          onClickHandler={leftButtonProps.onClick}
          kind={'blue'}
        />
        <CommonTaskButton
          text={rightButtonProps.text}
          onClickHandler={rightButtonProps.onClick}
          disabled={rightButtonProps.disabled}
          kind={'pink'}
        />
      </>
    );
  }
};

export default CommonConfirm;
