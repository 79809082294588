export const getStatusDetails = isEngineer => {
  return isEngineer ? statusDetailsEn : statusDetailsCo;
};

export const statusDetailsCo = [
  {
    text: '下書き保存',
    status: 1,
    description:
      '「審査に出す」ボタンを押下すると「審査中」に変わります。\nこの時点ではまだエンジニアには表示されません。'
  },
  {
    text: '審査中',
    status: 2,
    description:
      'Task運営局がタスクに対して審査を行っています。\n審査を通過すると「募集中」に変わります。\nこの時点ではまだエンジニアには表示されません。'
  },
  {
    text: '募集中',
    status: 3,
    description:
      'エンジニアがタスクを閲覧できる様になります。\nエンジニアのエントリーを待ち、アサイン依頼を行ってください。\nエンジニアがアサイン依頼を承諾し、企業が仮払いを行うと「作業中」変わります。'
  },
  {
    text: '作業中',
    status: 4,
    description:
      'アサインされたエンジニアが作業をしています。\nエンジニアが「成果物の確認依頼」を行う事で、「確認待ち」に変わります。'
  },
  {
    text: '確認待ち',
    status: 5,
    description:
      'エンジニアが成果物の確認を企業様に依頼している状態です。\n成果物に対して「修正依頼」を行うと「修正中」に、「承認」を行うと「評価」へ変わります。'
  },
  {
    text: '修正中',
    status: 6,
    description:
      'エンジニアが「成果物確認依頼」を行うと、再度「確認待ち」ステータスへと変化します。'
  },
  {
    text: '評価',
    status: 7,
    description: '相互に評価を行うことで「完了」へ変わります。'
  },
  {
    text: '完了',
    status: 8,
    description:
      '全ての工程が完了し、これ以上このタスクに対して作業を行う必要が無いステータスです。'
  }
];

export const statusDetailsEn = [
  {
    text: '下書き保存',
    status: 1,
    description:
      '作成途中のタスクです。この時点ではまだエンジニア樣には表示されません。'
  },
  {
    text: '審査中',
    status: 2,
    description:
      'Task運営局がタスクに対して審査を行っています。\nこの時点ではまだエンジニア樣には表示されません。'
  },
  {
    text: '募集中',
    status: 3,
    description:
      'エンジニア樣が閲覧できる様になります。\n「仕事を探す」画面より、お好みのタスクにエントリーして下さい。\nアサイン依頼が来ましたら、「アサイン依頼を承諾」を押下することで「作業中」へ変わります。'
  },
  {
    text: '作業中',
    status: 4,
    description:
      '「作業メモ」にて、企業と連絡を取り合いながら作業を進めます。\nエンジニア樣が「成果物の確認依頼」を行う事で、「確認待ち」へ変わります。'
  },
  {
    text: '確認待ち',
    status: 5,
    description:
      'エンジニア樣が成果物の確認を企業に依頼している状態です。\n成果物に対して企業が、「修正依頼」を行うと「修正中」に、「承認」を行うと「評価」に変わります。'
  },
  {
    text: '修正中',
    status: 6,
    description:
      '企業が「修正依頼」を行うとこのステータスとなります。\nエンジニア樣は作業メモにて修正が必要な箇所を確認し、修正して再度成果物確認依頼を行って下さい\n「確認待ち」ステータスへと変化します。'
  },
  {
    text: '評価',
    status: 7,
    description: '相互に評価を行うことで「完了」ステータスへ遷移します。'
  },
  {
    text: '完了',
    status: 8,
    description:
      '全ての工程が完了し、これ以上このタスクに対して作業を行う必要が無いステータスです。'
  }
];
