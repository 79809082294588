import React from 'react';
import {Route, Switch, BrowserRouter as Router} from 'react-router-dom';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import store, {history} from './store';
import ErrorBoundary from './containers/ErrorBoundary';
import App from './containers/App';
import {ConnectedRouter} from 'connected-react-router';

import DateFnsUtils from '@date-io/date-fns';
import jaLocale from 'date-fns/locale/ja';

import format from 'date-fns/format';

import firebase from 'firebase';
import {firebaseConfig} from './config';

import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
class LocalizedUtils extends DateFnsUtils {
  getDatePickerHeaderText(date) {
    return format(date, 'MM / dd', {locale: this.locale});
  }

  getCalendarHeaderText(date) {
    return format(date, 'yyyy年 MM月', {locale: this.locale});
  }
}

export const configureStore = store();

firebase.initializeApp(firebaseConfig);

ReactDOM.render(
  <Router>
    <Provider store={configureStore}>
      <ConnectedRouter history={history}>
        <ErrorBoundary>
          <MuiPickersUtilsProvider utils={LocalizedUtils} locale={jaLocale}>
            <Switch>
              <Route path="/" component={App} />
            </Switch>
          </MuiPickersUtilsProvider>
        </ErrorBoundary>
      </ConnectedRouter>
    </Provider>
  </Router>,
  document.getElementById('root')
);
