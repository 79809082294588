import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {requestCoUserInfo} from '../../actions/user';
import {requestCoReviews} from '../../actions/review';
import styles from './corporateInfo.module.scss';
import PageTitle from '../PageTitle';
import ContentTitle from '../ContentTitle';
import Text from '../Text';
import SnsButton from '../Button/SnsButton';
import ValuationItem from '../ValuationItem';
import StarRating from '../StarRating';
import {useHistory} from 'react-router-dom';
import {AvatarImg, userType} from '../../common';
import MoveToMessageButton from '../MoveToMessageButton';
import UserInfoItem from '../../components/UserInfoItem';
// material-ui
import {withStyles} from '@material-ui/core/styles/index';
import Divider from '@material-ui/core/Divider';

const style = theme => ({
  middleAvatar: {
    display: 'inline-block',
    marginRight: '4rem',
    marginTop: '3rem',
    marginLeft: '1rem',
    [theme.breakpoints.down('xs')]: {
      marginTop: '3rem',
      marginLeft: '0rem',
      marginRight: '0rem'
    }
  }
});

const Info = props => {
  const {classes, match} = props;
  const companyId = match.params.id;
  const roomList = useSelector(state => state.message.rooms);
  const history = useHistory();
  const companyReviews = useSelector(state => state.review.coReviews);
  const companyUserInfo = useSelector(state => state.user.coUser);
  const setting = useSelector(state => state.setting);
  const {me, isEngineer} = setting;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(requestCoUserInfo({toId: companyId}));
    dispatch(requestCoReviews({id: companyId}));
  }, []);

  const {
    id,
    username,
    nick_name,
    email,
    company_name,
    introduction,
    img
  } = companyUserInfo;

  const {average_rating} = companyReviews.length > 0 ? companyReviews[0] : 0;
  const EVAL_SCORE = average_rating * 20;
  return (
    <>
      <div className={styles.flex}>
        <div className={styles.mainInformations}>
          <PageTitle title="企業ユーザー" />
          <div className={styles.informationItem}>
            <ContentTitle
              title="基本情報"
              isButton={id == me.id}
              link={'/co/user/edit/profile'}
            />
            <div className={styles.flex}>
              <div className={styles.avatorContainer}>
                <div>
                  <div className={classes.middleAvatar}>
                    <AvatarImg img={img} size="middle" />
                  </div>
                  <div className={styles.buttonContainer}>
                    {setting.isEngineer && (
                      <MoveToMessageButton
                        userId={companyId}
                        size={'preMinimum'}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div>
                <UserInfoItem
                  title={'表示用の名前'}
                  mainContent={nick_name}
                  style={{margin: '4rem 0 2rem 0'}}
                />
                <Divider />
                <UserInfoItem
                  title={'会社名'}
                  mainContent={company_name}
                  style={{margin: '2rem 0'}}
                />
                <Divider />
                <UserInfoItem
                  title={'紹介文'}
                  mainContent={introduction ? introduction : '未設定'}
                  style={{margin: '2rem 0'}}
                />
                <Divider />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withStyles(style)(Info);
