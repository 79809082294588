import {createAction} from 'redux-actions';

// 一覧取得 :エンジニア
export const REQUEST_NOTIFICATION_LIST = 'REQUEST_NOTIFICATION_LIST';
export const SUCCESS_NOTIFICATION_LIST = 'SUCCESS_NOTIFICATION_LIST';
export const requestNotificationList = createAction(REQUEST_NOTIFICATION_LIST);
export const successNotificationList = createAction(SUCCESS_NOTIFICATION_LIST);

// 重要なお知らせの取得・追加
export const REQUEST_IMPORTANT_NOTIFICATIONS =
  'REQUEST_IMPORTANT_NOTIFICATIONS';
export const SUCCESS_IMPORTANT_NOTIFICATIONS =
  'SUCCESS_IMPORTANT_NOTIFICATIONS';
export const requestImportantNotifications = createAction(
  REQUEST_IMPORTANT_NOTIFICATIONS
);
export const successImportantNotifications = createAction(
  SUCCESS_IMPORTANT_NOTIFICATIONS
);

// 重要なお知らせの削除(1回押下したら削除したい時とかに使用)
export const REQUEST_DELETE_IMPORTANT_NOTIFICATION =
  'REQUEST_DELETE_IMPORTANT_NOTIFICATION';
export const requestDeleteImportantNotification = createAction(
  REQUEST_DELETE_IMPORTANT_NOTIFICATION
);
export const SUCCESS_DELETE_IMPORTANT_NOTIFICATION =
  'SUCCESS_DELETE_IMPORTANT_NOTIFICATION';
export const successDeleteImportantNotification = createAction(
  SUCCESS_DELETE_IMPORTANT_NOTIFICATION
);
