import {
  SUCCESS_CATEGORY_LIST
} from '../actions/category'

const initialState = {
  list: []
};


function category(state = initialState, action) {
  switch (action.type) {
    case SUCCESS_CATEGORY_LIST:
      return {
        ...state,
        list: action.payload
      }
    default :
      return state
  }
}

export default category
