import {UPDATE_PAGE_OFFSET} from '../actions/paginate'

const initialState = {
  offset: 0
}

function paginate(state = initialState, action) {
  switch (action.type) {
    case UPDATE_PAGE_OFFSET:
      return {
        ...state,
        offset: action.payload
      };
    default:
      return state
  }
}

export default paginate
