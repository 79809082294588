import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PublicCommentForm from '../../Form/PublicCommentForm';
import PublicCommentItems from '../../PublicCommentItems';
import {requestPublicCommentCreate} from '../../../actions/comment';
import {useParams} from 'react-router';
import {getFormValues} from 'redux-form';

const Question = props => {
  const {id} = useParams();
  const dispatch = useDispatch();
  const {publicComments} = useSelector(state => state.comment);
  const formValues = useSelector(state => {
    return getFormValues('PublicCommentForm')(state);
  });

  return (
    <div>
      <PublicCommentForm
        onSubmit={values => {
          dispatch(
            requestPublicCommentCreate({
              ...values,
              formName: 'PublicCommentForm',
              id: id
            })
          );
        }}
        form={'PublicCommentForm'}
        initialValues={{
          parent: null
        }}
        formValues={formValues}
        parent={null}
      />
      {publicComments.map((public_comment, index) => (
        <PublicCommentItems
          taskId={id}
          public_comment={public_comment}
          key={index.toString()}
        />
      ))}
    </div>
  );
};

export default Question;
