import React from 'react';
import Divider from '@material-ui/core/Divider/index';
import Table from '@material-ui/core/Table/index';
import TableBody from '@material-ui/core/TableBody/index';
import TableCell from '@material-ui/core/TableCell/index';
import TableHead from '@material-ui/core/TableHead/index';
import TableRow from '@material-ui/core/TableRow/index';
import Avatar from '@material-ui/core/Avatar/index';
import Button from '@material-ui/core/Button/index';
import {withStyles} from '@material-ui/core/styles/index';
import style from './profile.module.scss';
import Header from '../Header';
import {AvatarImg} from '../../../common';

const styles = {
  divider: {
    height: '0.2rem',
    background: '#093791',
    marginTop: '1.5rem'
  },
  bigAvatar: {
    display: 'inline-block',
    width: '10vw',
    height: '10vw',
    marginRight: '5rem'
  },
  leftCol: {
    color: '#093791',
    width: '7rem',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '1.5rem',
    padding: '2rem 0 2rem 2rem'
  },
  middleCol: {
    width: '2rem',
    padding: '1rem 0'
  },
  rightCol: {
    color: '#222222',
    width: '16rem',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '1.5rem',
    paddingLeft: 0
  },
  button: {
    border: '0.1rem solid #093791',
    color: '#093791',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '1.5rem',
    lineHeight: '2rem',
    textAlign: 'center',
    marginLeft: 'auto'
  }
};
const itemValues = {
  status: '受注可否',
  last_name: 'お名前',
  gender: '性別',
  address: '住所',
  email: 'メールアドレス'
};

const Index = props => {
  const {classes, dict} = props;
  return (
    <div className={style.backGround}>
      <div className={style.userInfo}>
        <Header headerText={'基本情報'} />
        <div className={style.main}>
          <div
            classes={{
              root: classes.bigAvatar
            }}
          >
            <AvatarImg img={dict.img} />
          </div>

          <div className={style.tableRows}>
            <Table>
              <TableBody className={style.tableBody}>
                {Object.keys(itemValues).map((item, index) => (
                  <TableRow key={index.toString()}>
                    <TableCell
                      component="th"
                      scope="row"
                      classes={{
                        body: classes.leftCol
                      }}
                    >
                      {itemValues[item]}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      classes={{
                        body: classes.rightCol
                      }}
                    >
                      {dict[item]}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(Index);
