import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {withStyles} from '@material-ui/core/styles';

import {requestEntryList} from '../../../actions/entry';
import {requestTaskDetail} from '../../../actions/task';

import style from './entry.module.scss';
import EngineerList from '../../../components/Entry/List';
import MainTitle from '../../../components/MainTitle';
import Paginate from '../../../components/Paginate';
import RequestModal from '../../../components/RequestModal';
import NotAvailable from '../../../components/NotAvailable';

const styles = {
  button: {
    border: '0.1rem solid #093791',
    color: '#093791',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '1.5rem',
    lineHeight: '2rem',
    textAlign: 'center',
    marginLeft: 'auto'
  }
};

class Assign extends Component {
  constructor(props) {
    super(props);
    this.state = {name: '', userId: '', userImg: ''};
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleBlankName = this.handleBlankName.bind(this);
  }

  componentDidMount() {
    this.setState({name: ''});
    this.props.requestEntryList({id: this.props.match.params.id});
  }

  handleOpenModal(name, userId, userImg) {
    this.setState({name: name, userId: userId, userImg: userImg});
  }

  handleBlankName() {
    this.setState({name: ''});
  }

  render() {
    const {entryUserList, count, open, detail, match} = this.props;

    return (
      <>
        <main className={style.main}>
          <MainTitle titleStr={'エントリーエンジニア一覧'} pattern={false} />
          {entryUserList.length === 0 ? (
            <NotAvailable kind="no-entry" />
          ) : entryUserList ? (
            <EngineerList
              engineers={entryUserList}
              detail={detail}
              onClick={this.handleOpenModal}
            />
          ) : null}
          <Paginate
            limit={10}
            total={count}
            {...this.props}
            method={this.props.requestEntryList}
          />
        </main>
        {this.state.name && (
          <RequestModal
            name={this.state.name}
            userId={this.state.userId}
            userImg={this.state.userImg}
            onClick={this.handleBlankName}
            taskId={match.params.id}
            requested={open}
          />
        )}
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  requestEntryList(value) {
    dispatch(requestEntryList(value));
  },
  requestTaskDetail(value) {
    dispatch(requestTaskDetail({id: value}));
  }
});

const mapStateToProps = state => {
  return {
    entryUserList: state.entry.entryUserList,
    detail: state.task.detail,
    open: state.entry.openFlag,
    count: state.entry.count,
    next: state.entry.next,
    previous: state.entry.previous
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Assign))
);
