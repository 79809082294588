import React from 'react';
import PropTypes from 'prop-types';
import {compose} from 'recompose';
import {connect} from 'react-redux'
import {NavLink} from 'react-router-dom'
import {withStyles} from '@material-ui/core/styles';
import style from './menu.module.scss'

// component
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import Toolbar from '@material-ui/core/Toolbar';
import Menu from '@material-ui/core/Menu';


import DrawerMenu from '../../components/DrawerMenu/En';
import Footer from '../../components/Footer'

// img
import IconBell from "../../assets/icon_header_bell.svg";
import Logo from '../../assets/logo.svg'
import IconAvater from "../../assets/icon_header_avater.svg";


const drawerWidth = 240;

const styles = theme => ({
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    }
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    backgroundColor: '#fff'
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  messageToolbar: {
    "@media (min-width:600px)": {
      "minHeight": 34
    }
  },
  drawerPaper: {
    width: drawerWidth,
    color: '#5d5959',
    backgroundColor: '#093791'
  }
});

class AppLayout extends React.Component {

  state = {
    mobileOpen: false,
    auth: true,
    anchorEl: null,
  };

  handleChange = event => {
    this.setState({auth: event.target.checked});
  };

  handleMenu = event => {
    this.setState({anchorEl: event.currentTarget});
  };

  handleClose = (e) => {
    this.setState({anchorEl: null});
  };

  handleDrawerToggle = () => {
    this.setState(state => ({mobileOpen: !state.mobileOpen}));
  };

  render() {
    const {classes, messageFlag} = this.props;
    const {auth, anchorEl} = this.state;
    const open = Boolean(anchorEl);

    return (
      <div className={style.root}>
        <CssBaseline/>
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="primary"
              aria-label="Open drawer"
              className={classes.menuButton}
              onClick={this.handleDrawerToggle}
            >
              <MenuIcon/>
            </IconButton>
            <NavLink exact to="/en/" className={style.logo}>
              <img src={Logo}/>
            </NavLink>
            <IconButton
              // aria-owns={open ? 'menu-appbar' : undefined}
              aria-haspopup="true"
              color="inherit"
              onClick={this.handleClose}
              className={style.iconButton}
            >
              <NavLink exact to="/co/notification">
                <img src={IconBell}/>
              </NavLink>
            </IconButton>
            <IconButton
              aria-owns={open ? 'menu-appbar' : undefined}
              aria-haspopup="true"
              onClick={this.handleMenu}
              color="inherit"
              className={style.iconButton}
            >
              <img src={IconAvater}/>
            </IconButton>
            <Menu
              className={style.menuList}
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              onClose={this.handleClose}
            >
              <NavLink exact to="/en/user" onClick={this.handleClose}>
                <MenuItem>
                  <ListItemText primary="アカウント情報" color="primary" className={style.iconMenuUser}/>
                </MenuItem>
              </NavLink>
              <Divider/>
              <NavLink exact to="/en/setting" onClick={this.handleClose}>
                <MenuItem>
                  <ListItemText primary="アカウント設定" color="primary" className={style.iconMenuSetting}/>
                </MenuItem>
              </NavLink>
              <Divider/>
              <NavLink exact to="/help" onClick={this.handleClose}>
                <MenuItem>
                  <ListItemText primary="ヘルプ" color="primary" className={style.iconMenHelp}/>
                </MenuItem>
              </NavLink>

              <NavLink exact to="/singin" onClick={this.handleClose}>
                <MenuItem>
                  <ListItemText primary="ログアウト" color="primary" className={style.iconMenuSignOut}/>
                </MenuItem>
              </NavLink>

            </Menu>
          </Toolbar>
        </AppBar>
        <nav className={classes.drawer}>
          <Hidden smUp implementation="css">
            <Drawer
              container={this.props.container}
              variant="temporary"
              anchor={'left'}
              open={this.state.mobileOpen}
              onClose={this.handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              <DrawerMenu/>
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              <DrawerMenu/>
            </Drawer>
          </Hidden>
        </nav>
        <main className={messageFlag ? style.msgMain : style.main}>
          <div className={messageFlag ? null : style.content}>
            <div className={messageFlag ? classes.messageToolbar : classes.toolbar}/>
            {this.props.children}
          </div>
          {messageFlag ? null : <Footer/>}
        </main>
      </div>
    );
  }
}


AppLayout.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles, {withTheme: true}),
  connect(null, null)
)(AppLayout);
