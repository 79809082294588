import React from 'react';
import style from './entry.module.scss';
import EntryListButtons from '../EngineerCard/EntryListButtons';
import EngineerCard from '../EngineerCard';

const EngineerList = ({onClick, engineers}) => {
  const handleSetKind = (userId, isPreWorker, engineers) => {
    const stopPreAssign = Boolean(
      engineers.find(item => item.is_preworker === true)
    );

    if (isPreWorker) {
      // 当該ユーザが仮アサイン済みのユーザだった場合
      return 'pink';
    } else if (!stopPreAssign) {
      // 当該タスクに仮アサインされているユーザがまだいない場合
      return 'pinkOutlined';
    } else if (stopPreAssign) {
      // 当該ユーザ以外に、アサイン済みのユーザがすでに存在した場合
      return 'disabled';
    }
  };

  return (
    <>
      <ul className={style.list}>
        {engineers.map((item, index) => {
          const {id, nick_name, img, summary_rating} = item;
          return (
            <li key={index.toString()}>
              <EngineerCard value={item} item={item}>
                <EntryListButtons
                  value={item}
                  id={id}
                  engineers={engineers}
                  handleSetKind={handleSetKind}
                  onClick={e => {
                    onClick(nick_name, id, img);
                    e.stopPropagation();
                  }}
                />
              </EngineerCard>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default EngineerList;
