import React, {FC} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PageTitle from '../../../components/PageTitle';
import styles from './twoFactorAuth.module.scss';
import CommonButton from '../../../components/CommonButton';
import {requestTwoFactorAuthUri} from '../../../actions/sign';
import {RootState} from '../../../models';
import QRCode from 'qrcode.react';
import Blank from '../../../components/Blank';
import SubTitle from '../../../components/SubTitle';
import Typography from '@material-ui/core/Typography';
import DialogContentText from '@material-ui/core/DialogContentText';
import Link from '@material-ui/core/Link';

const GOOGLE_AUTHENTICATOR_PAGE =
  'https://apps.apple.com/jp/app/google-authenticator/id388497605';

const TwoFactorAuth: FC = () => {
  const dispatch = useDispatch();
  const {twoFactorAuthUri, twoFactorAuthUriError} = useSelector(
    (state: RootState) => state.sign
  );

  return (
    <div>
      <PageTitle title="２段階認証設定" />
      <div className={styles.container}>
        <SubTitle headerText={'当サービスの２段階認証について'} />
        <Blank height={'3.2rem'} width={null} />
        <Typography>
          ２段階認証設定後は、サインインの際にワンタイムパスワードの入力を求められる様になります。
        </Typography>
        <Typography>
          ワンタイムパスワードは、専用のアプリケーションにて、当サービスが表示するQRコードを読み取ることで取得できます。
        </Typography>
        <Blank height={'2.4rem'} width={null} />
        <Typography>専用のアプリケーションの例</Typography>
        <Link href={GOOGLE_AUTHENTICATOR_PAGE}>Google Authenticator</Link>
        <Blank height={'3.2rem'} width={null} />
        <SubTitle headerText={'注意事項'} />
        <Blank height={'3.2rem'} width={null} />
        <Typography color={'secondary'}>
          ・︎２段階認証は、一度設定すると解除できません。
        </Typography>
        <Typography>
          ・端末を機種変更する際には、ご利用のTOTP生成アプリでアカウントの移行作業を行ってください。
        </Typography>
        <Blank height={'3.2rem'} width={null} />
        <Typography>
          設定するボタンを押下すると２段階認証の設定が完了し、QRコードが表示されます。
        </Typography>
        <Typography>
          セキュリティ上の観点から、このQRコードは再表示ができませんのでご注意下さい。
        </Typography>
        <Blank height={'3.2rem'} width={null} />
        <div className={styles.buttonsContainer}>
          {twoFactorAuthUriError && (
            <>
              <Typography color={'secondary'}>
                {twoFactorAuthUriError}
              </Typography>
              <Blank height={'1rem'} width={null} />
            </>
          )}
          {!twoFactorAuthUri && (
            <CommonButton
              type="button"
              icon={null}
              text={'設定する'}
              size={'small'}
              color={'blue'}
              variant={'contained'}
              shape={'normal'}
              onClick={() => {
                dispatch(requestTwoFactorAuthUri());
              }}
              disabled={false}
            />
          )}
          {twoFactorAuthUri && <QRCode value={twoFactorAuthUri} />}
        </div>
      </div>
    </div>
  );
};

export default TwoFactorAuth;
