import React, {FC} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';

type Props = {
  index: number;
  disabled: boolean;
  onClick: (e: any) => void;
  useOddStyle?: boolean;
  isListItem?: boolean;
};

const TaskItemContainer: FC<Props> = ({
  children,
  index,
  disabled,
  onClick,
  useOddStyle = true,
                                        isListItem = false
}) => {
  let colorCode;
  let borderTop;

  if (useOddStyle) {
    colorCode = index % 2 === 0 ? '#FFFFFF' : '#F7F9FC';
  } else {
    colorCode = '#FFFFFF';
  }

  if (isListItem) {
    borderTop = '0.1rem solid #d7e2f4';
  } else {
    borderTop = index == 0 ? '0.1rem solid #d7e2f4' : '';
  }

  const useStyles = makeStyles({
    root: {
      borderTop: borderTop,
      borderBottom: '0.1rem solid #D7E2F4',
      borderRight: '0.1rem solid #D7E2F4',
      borderLeft: '0.1rem solid #D7E2F4',
      backgroundColor: colorCode,
      '&:hover': {
        backgroundColor: '#FFFEEE'
      }
    }
  });

  const classes = useStyles();

  return (
    <ListItem
      className={classes.root}
      disabled={disabled}
      button={true}
      onClick={onClick}
    >
      {children}
    </ListItem>
  );
};

export default TaskItemContainer;
