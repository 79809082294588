export const firebaseConfigPrd = {
  apiKey: 'AIzaSyC4slUhl5P81sHZy3Afg9ufIoZt7MYYqP0',
  authDomain: 'task-cs-32fd7.firebaseapp.com',
  databaseURL: 'https://task-cs-32fd7.firebaseio.com',
  projectId: 'task-cs',
  storageBucket: 'task-cs.appspot.com',
  messagingSenderId: '300038336874',
  appId: '1:300038336874:web:8fcf50f10cc7da9c493513'
};

export const firebaseConfigStg = {
  apiKey: 'AIzaSyA2o1hOUHtSzpW6mo0LLeybB9jzFKEuTSQ',
  authDomain: 'task-cs-stg.firebaseapp.com',
  databaseURL: 'https://task-cs-stg.firebaseio.com',
  projectId: 'task-cs-stg',
  storageBucket: 'task-cs-stg.appspot.com',
  messagingSenderId: '784755507477',
  appId: '1:784755507477:web:6b97d142555728a11e691c'
};
