import React from 'react';
import Header from '../Header';
import style from './skill.module.scss';

const Index = ({me}) => {
  return (
    <div className={style.box}>
      <Header headerText={'スキル'} />
      <div className={style.flexContainer}>
        {me.tags.map((tag, index) => (
          <div className={style.tagContainer} key={index.toString()}>
            <label className={style.tags}>{tag}</label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Index;
