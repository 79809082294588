import React, {FC} from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import style from '../Entry/entry.module.scss';
import {AvatarImg} from '../../common';
import StarRating from '../StarRating';
import Box from '@material-ui/core/Box';
import {useHistory} from 'react-router';
import {makeStyles} from '@material-ui/core/styles';
import {EngineerItemState} from '../../models/UserModels';

const useStyles = makeStyles({
  smallAvatar: {
    margin: '1rem 3rem 1rem 1rem'
  }
});

type Props = {
  item: EngineerItemState | null;
};

const EngineerCard: FC<Props> = ({item, children}) => {
  const history = useHistory();
  const classes = useStyles();

  if (!item) return <></>;

  const {id, nick_name, img, summary_rating, exp, tags, introduction} = item;
  return (
    <Card>
      <CardContent onClick={() => history.push(`/co/en-user/${id}`)}>
        <div className={style.entryInfo}>
          <div className={style.avatarsContainer}>
            <div className={classes.smallAvatar}>
              <AvatarImg img={img} size="small" />
            </div>
            <div>
              <p className={style.username}>{nick_name}</p>
            </div>
          </div>
          <div className={style.rightContainer}>
            <div className={style.innerContainer}>
              <div className={style.prContainer}>
                <div className={style.eval}>
                  <div className={style.wholeEval}>総合評価</div>
                  <div className={style.rating}>
                    <StarRating evalValue={summary_rating * 20} />
                  </div>
                  <div className={style.wholeEval}>実績件数</div>
                  <div className={style.achievement}>{exp}件</div>
                </div>
                <div className={style.tagItems}>
                  {tags.map((tag, index) => {
                    return (
                      <div className={style.tagContainer} key={index}>
                        <div className={style.tags}>{tag}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className={style.requestButton}>{children}</div>
            </div>
            <div className={style.innerContainer}>
              <div className={style.prText}>
                <Box component="div" textOverflow="ellipsis" overflow="hidden">
                  {introduction}
                </Box>
              </div>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default EngineerCard;
