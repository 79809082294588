import {TAX, MAX_SYSTEM_FEE_RATIO} from '../../../constants';

export const ErrorMessages = {
  requiredItem: '※必須項目です',
  requiredInteger: '※整数で入力ください',
  requiredAvailableInteger:
    '※合計金額が1~999万円になる様に設定をお願い致します',
  requiredChoice: '※いずれかを選択してください',
  password: '異なるパスワードが入力されています',
  url: '今後urlのバルデーションで使う、仮置き',
  requiredPostalCode: '※郵便番号の形で入力してください(例:XXX-XXXX)',
  requiredPrefecture: '※正しい都道府県名を入力してください(例:神奈川県)'
};

//バリデーションで使う正規表現
const RegularExpressions = {
  postalCode: new RegExp('[0-9]{3}-[0-9]{4}'),
  prefecture: new RegExp('(東京都|北海道|(?:京都|大阪)府|.{2,3}県)')
  //必要に応じて追加
};

export const RequiredItem = value => {
  return value ? undefined : ErrorMessages.requiredItem;
};

export const RequiredInteger = value =>
  Number.isInteger(value - 0) ? undefined : ErrorMessages.requiredInteger;

export const RequiredValidPrice = value => {
  const reward = Number(value);
  const maxSystemFee = (reward * MAX_SYSTEM_FEE_RATIO) / 100;
  const taxFee = ((reward + maxSystemFee) * TAX) / 100;
  const maxPrice = reward + maxSystemFee + taxFee;
  return maxPrice > 0 && maxPrice < 10000000
    ? undefined
    : ErrorMessages.requiredAvailableInteger;
};

export const RequiredChoice = value => {
  return Number(value) ? undefined : ErrorMessages.requiredChoice;
};

export const RequiredPostalCode = value =>
  String(value).match(RegularExpressions.postalCode)
    ? undefined
    : ErrorMessages.requiredPostalCode;

export const RequiredPrefecture = value =>
  String(value).match(RegularExpressions.prefecture)
    ? undefined
    : ErrorMessages.requiredPrefecture;

// 審査に提出する時のみに使用するバリデーション
export const reviewPostValidate = data => {
  let errors = {};

  if (RequiredItem(data.title)) errors['title'] = ErrorMessages.requiredItem;
  if (RequiredItem(data.description))
    errors['description'] = ErrorMessages.requiredItem;
  if (RequiredItem(data.closing_date))
    errors['closing_date'] = ErrorMessages.requiredItem;
  if (RequiredItem(data.format_choose))
    errors['format_choose'] = ErrorMessages.requiredItem;
  if (RequiredValidPrice(data.reward))
    errors['reward'] = ErrorMessages.requiredAvailableInteger;

  return errors;
};

export const RequiredSamePassword = (data, values) => {
  return values.password === values.passwordConfirm
    ? undefined
    : ErrorMessages.password;
};
