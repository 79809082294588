import {all, call, put, takeEvery, take, fork} from 'redux-saga/effects';
import {fetchPatch} from './utills/fetch';
import {PATCH_CONFIRM} from '../actions/confirm';
import {successPatch} from '../actions/confirm';
import {effect, array, iterator, promise, task} from '@redux-saga/is';
import {setModalSetting} from '../actions/task';

// taskのstatusを4→５にpatch
export function* confirmpatch() {
  while (true) {
    const action = yield take(PATCH_CONFIRM);
    const [data, error] = yield call(
      fetchPatch,
      `tasks/${action.payload.task}`,
      action.payload.status
    );
    if (data && !error) {
      yield put(successPatch(data));
    } else {
      console.log('error!');
    }
  }
}

const confirm = [fork(confirmpatch)];

export default confirm;
