import React from 'react'
import style from './required.module.scss'

const Required = () => {
  return (
    <div className={style.required}>
      必須
    </div>
  )
}

export default Required
