import React, {FC, useEffect, useState, Fragment} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import clsx from 'clsx';
import rootStyles from '../modals.module.scss';
import styles from './offer.module.scss';
import commonStyles from '../../../common/common.module.scss';
import {RecruitmentTask} from '../../Task/TaskLists/recruitmentTask';
import CommonButton from '../../CommonButton';
import Blank from '../../Blank';
import Dialog from '@material-ui/core/Dialog';
import EngineerCard from '../../../components/EngineerCard';
import OfferAdminSampleImg from '../../../assets/offer_admin_sample.png';
// models
import {RootState} from '../../../models/RootModels';
import {
  PreAssignTaskItemState,
  TaskItemState
} from '../../../models/TaskModels';
import {EngineerItemState} from '../../../models/UserModels';
import Title from '../../Title';
import {requestOffer} from '../../../actions/assign';
import AssignedTask from '../../Task/TaskLists/AssignedTask';
import TaskCreateButton from '../../Buttons/TaskCreateButton';
import {ProjectItemState} from '../../../models/ProjectModels';
import {getIsExpired} from '../../../common/funcs';
import {useHistory} from 'react-router';

export const SCREEN_INDEX = {
  taskSelect: 0,
  assignWarning: 1,
  assignRequest: 2,
  assignResult: 3,
  sameUserAssignWarning: 4,
  deadlineWarning: 5
};

const OfferModal: FC<Props> = ({engineerItem, isOpen, setIsOpen}) => {
  const dispatch = useDispatch();
  const {wantedTasks, preAssignedTasks} = useSelector(
    (state: RootState) => state.task
  );
  const {modalErrorMsg, isSubmitting} = useSelector(
    (state: RootState) => state.common
  );
  const projects = useSelector((state: RootState) => state.project.list);
  const [index, setIndex] = useState<number>(SCREEN_INDEX.taskSelect);
  const [taskItem, setTaskItem] = useState<TaskItemState | null>(null);

  return (
    <Dialog
      onBackdropClick={() => setIsOpen(false)}
      onExited={() => setIndex(SCREEN_INDEX.taskSelect)}
      open={isOpen}
      maxWidth={false}
    >
      <div className={clsx(rootStyles.container, styles.countainer)}>
        {index === SCREEN_INDEX.taskSelect && (
          <TaskSelect
            wantedTasks={wantedTasks}
            setIndex={setIndex}
            setTaskItem={setTaskItem}
            setIsOpen={setIsOpen}
            engineerItem={engineerItem}
            projects={projects}
          />
        )}

        {index === SCREEN_INDEX.assignWarning && (
          <AssignWarning
            taskItem={taskItem}
            preAssignedTasks={preAssignedTasks}
            setIndex={setIndex}
          />
        )}

        {index === SCREEN_INDEX.deadlineWarning && (
          <DeadlineWarning
            setIsOpen={setIsOpen}
            taskItem={taskItem}
            setIndex={setIndex}
          />
        )}

        {index === SCREEN_INDEX.sameUserAssignWarning && (
          <SameUserAssignWarning
            taskItem={taskItem}
            preAssignedTasks={preAssignedTasks}
            setIndex={setIndex}
            setIsOpen={setIsOpen}
          />
        )}

        {index === SCREEN_INDEX.assignRequest && engineerItem && (
          <RequestAssign
            wantedTasks={wantedTasks}
            taskItem={taskItem}
            engineerItem={engineerItem}
            handleReturn={(): void => setIndex(0)}
            handleSubmit={values =>
              dispatch(
                requestOffer({
                  ...values,
                  setIndex: () => setIndex(SCREEN_INDEX.assignResult)
                })
              )
            }
            modalErrorMsg={modalErrorMsg}
            isSubmitting={isSubmitting}
          />
        )}

        {index === SCREEN_INDEX.assignResult && (
          <OfferResult setIsOpen={setIsOpen} />
        )}
      </div>
    </Dialog>
  );
};

const TaskSelect: FC<TaskSelectProps> = ({
  wantedTasks,
  setIndex,
  setTaskItem,
  engineerItem,
  setIsOpen,
  projects
}) => {
  if (wantedTasks.length === 0) {
    return (
      <Fragment>
        <p className={styles.title}>オファーできるタスクを保有していません</p>
        <Blank height={'2rem'} width={null} />
        <TaskCreateButton projects={projects} />
        <CommonButton
          icon={null}
          text={'閉じる'}
          size={'medium'}
          color={'blue'}
          variant={'contained'}
          shape={'normal'}
          onClick={() => setIsOpen(false)}
          disabled={false}
        />
      </Fragment>
    );
  }
  return (
    <>
      <p className={styles.title}>オファーしたいタスクを選択して下さい</p>
      <div className={styles.tasksContainer}>
        {wantedTasks.map((item, index) => {
          const isExpired = getIsExpired(item.closing_date);
          return (
            <RecruitmentTask
              isListItem={true}
              key={index}
              item={item}
              index={index}
              isEngineer={false}
              onClick={(value: number) => {
                setTaskItem(item);
                if (isExpired) {
                  setIndex(SCREEN_INDEX.deadlineWarning);
                } else if (item.pre_worker === null) {
                  setIndex(SCREEN_INDEX.assignRequest);
                } else {
                  if (
                    engineerItem !== null &&
                    item.pre_worker === engineerItem.id
                  ) {
                    setIndex(SCREEN_INDEX.sameUserAssignWarning);
                  } else {
                    setIndex(SCREEN_INDEX.assignWarning);
                  }
                }
              }}
            />
          );
        })}
      </div>
    </>
  );
};

const AssignWarning: FC<AssignWarningProps> = ({
  preAssignedTasks,
  setIndex,
  taskItem
}) => {
  if (!taskItem) return <></>;
  return (
    <>
      <p className={styles.title}>アサイン依頼済みのタスクです</p>
      <div className={styles.tasksContainer}>
        {preAssignedTasks.map((item, index) => {
          if (item.id === taskItem.id) {
            return (
              <AssignedTask
                index={index}
                item={item}
                isEngineer={false}
                isListItem={true}
              />
            );
          }
        })}
      </div>
      <Blank height={'2.4rem'} width={null} />
      <p className={styles.text}>
        アサイン依頼が可能なのは、１つのタスクに対して１名のエンジニアのみです
      </p>
      <p className={styles.text}>
        このタスクに対し、別のエンジニアへアサイン依頼を行うと、最初のアサイン依頼は削除されます
      </p>
      <p className={styles.text}>問題がなければ「次へ」を押下して下さい</p>
      <div className={styles.buttonsContainer}>
        {/*@ts-ignore*/}
        <CommonButton
          text={'戻る'}
          size={'small'}
          color={'blue'}
          variant={'contained'}
          shape={'normal'}
          onClick={() => setIndex(SCREEN_INDEX.taskSelect)}
          disabled={false}
        />
        <Blank width={'1.6rem'} height={null} />
        {/*@ts-ignore*/}
        <CommonButton
          text={'次へ'}
          size={'small'}
          color={'pink'}
          variant={'contained'}
          shape={'normal'}
          onClick={() => setIndex(SCREEN_INDEX.assignRequest)}
          disabled={false}
        />
      </div>
    </>
  );
};

type DeadlineWarningProps = {
  setIsOpen: React.Dispatch<any>;
  setIndex: React.Dispatch<any>;
  taskItem: TaskItemState | null;
};

const DeadlineWarning: FC<DeadlineWarningProps> = ({
  setIsOpen,
  taskItem,
  setIndex
}) => {
  if (!taskItem) return <></>;
  const history = useHistory();
  const handleClick = () => {
    setIsOpen(false);
    history.push(`/co/project/${taskItem.project}/task/${taskItem.id}`);
  };

  return (
    <Fragment>
      <p className={styles.title}>応募期限が切れているタスクです</p>
      <RecruitmentTask
        item={taskItem}
        isEngineer={false}
        onClick={handleClick}
        isListItem={false}
      />
      <Blank height={'2.4rem'} width={null} />
      <p className={styles.text}>
        このタスクにオファーを出すには、応募期限の延長を行ってください。
      </p>
      <div className={styles.buttonsContainer}>
        <CommonButton
          icon={null}
          text={'戻る'}
          size={'small'}
          color={'blue'}
          variant={'contained'}
          shape={'normal'}
          onClick={() => setIndex(SCREEN_INDEX.taskSelect)}
          disabled={false}
        />
        <Blank width={'1rem'} height={null} />
        <CommonButton
          icon={null}
          text={'延長する'}
          size={'small'}
          color={'pink'}
          variant={'contained'}
          shape={'normal'}
          onClick={handleClick}
          disabled={false}
        />
      </div>
    </Fragment>
  );
};

const SameUserAssignWarning: FC<SameUserWarningProps> = ({
  preAssignedTasks,
  setIndex,
  taskItem,
  setIsOpen
}) => {
  if (!taskItem) return <></>;
  return (
    <>
      <p className={styles.title}>同じエンジニアをアサインしようとしています</p>
      <div className={styles.tasksContainer}>
        {preAssignedTasks.map((item, index) => {
          if (item.id === taskItem.id) {
            return (
              <AssignedTask
                index={index}
                item={item}
                isEngineer={false}
                isListItem={true}
              />
            );
          }
        })}
      </div>
      <Blank height={'2.4rem'} width={null} />
      <p className={styles.text}>
        このタスクには既に同じエンジニアをアサインしています。別のタスクを選択してください。
      </p>
      <div className={styles.buttonsContainer}>
        {/*@ts-ignore*/}
        <CommonButton
          text={'閉じる'}
          size={'small'}
          color={'blue'}
          variant={'contained'}
          shape={'normal'}
          onClick={() => setIsOpen(false)}
          disabled={false}
        />
      </div>
    </>
  );
};

const RequestAssign: FC<RequestAssignProps> = ({
  wantedTasks,
  taskItem,
  engineerItem,
  handleReturn,
  handleSubmit,
  modalErrorMsg,
  isSubmitting
}) => {
  if (!engineerItem || !taskItem) return <></>;
  return (
    <>
      <p className={styles.title}>アサイン依頼を送信します</p>
      <div className={styles.subTitle}>
        <Title component={'h2'} text={'オファーするタスク'} />
      </div>
      <div className={styles.tasksContainer}>
        {wantedTasks.map((item, index) => {
          if (taskItem.id === item.id) {
            return (
              <RecruitmentTask
                item={item}
                index={index}
                isEngineer={false}
                isListItem={false}
              />
            );
          }
        })}
      </div>
      <Blank height={'2.4rem'} width={null} />
      <div className={styles.subTitle}>
        <Title component={'h2'} text={'アサインするエンジニア'} />
      </div>
      <div>
        <EngineerCard item={engineerItem} />
      </div>
      <Blank height={'2.4rem'} width={null} />
      <p className={commonStyles.errorMessage}>{modalErrorMsg}</p>
      <div className={styles.buttonsContainer}>
        {/*@ts-ignore*/}
        <CommonButton
          text={'戻る'}
          size={'small'}
          color={'blue'}
          variant={'contained'}
          shape={'normal'}
          onClick={handleReturn}
          disabled={false}
        />
        <Blank width={'1.6rem'} height={null} />
        {/*@ts-ignore*/}
        <CommonButton
          text={'決定'}
          size={'small'}
          color={'pink'}
          variant={'contained'}
          shape={'normal'}
          onClick={() =>
            handleSubmit({worker: engineerItem.id, taskId: taskItem.id})
          }
          disabled={isSubmitting}
        />
      </div>
    </>
  );
};

const OfferResult: FC<OfferResultPropr> = ({setIsOpen}) => {
  return (
    <>
      <p className={styles.title}>オファーが完了しました</p>
      <p className={styles.text}>
        エンジニアがオファーを承諾、または辞退するまでお待ち下さい。
      </p>
      <p className={styles.text}>
        承諾を待っている状態のタスクは「受注承諾待ちタスク」よりご確認頂けます。
      </p>
      <img src={OfferAdminSampleImg} alt={'画面イメージ'} />
      <Blank width={null} height={'2.4rem'} />
      {/*@ts-ignore*/}
      <CommonButton
        text={'閉じる'}
        size={'small'}
        color={'pink'}
        variant={'contained'}
        shape={'normal'}
        onClick={() => setIsOpen(false)}
        disabled={false}
      />
    </>
  );
};

type Props = {
  engineerItem: EngineerItemState | null;
  isOpen: boolean;
  setIsOpen: React.Dispatch<any>;
};

type OfferResultPropr = {
  setIsOpen: (value: boolean) => void;
};

type TaskSelectProps = {
  wantedTasks: TaskItemState[];
  setIndex: React.Dispatch<any>;
  setTaskItem: React.Dispatch<any>;
  setIsOpen: React.Dispatch<any>;
  engineerItem: EngineerItemState | null;
  projects: ProjectItemState[] | null;
};

type AssignWarningProps = {
  preAssignedTasks: PreAssignTaskItemState[];
  setIndex: React.Dispatch<any>;
  taskItem: TaskItemState | null;
};

type SameUserWarningProps = {
  preAssignedTasks: PreAssignTaskItemState[];
  setIndex: React.Dispatch<any>;
  taskItem: TaskItemState | null;
  setIsOpen: (value: boolean) => void;
};

type RequestAssignProps = {
  wantedTasks: TaskItemState[];
  taskItem: TaskItemState | null;
  engineerItem: EngineerItemState | null;
  handleReturn: () => void;
  handleSubmit: (values: {taskId: number; worker: number}) => void;
  modalErrorMsg: string | null;
  isSubmitting: boolean;
};

export default OfferModal;
