import React from 'react';
import {Switch, Route} from 'react-router-dom';
import style from './profile.module.scss';
import ProfileEdit from '../../../components/Form/en/ProfileEdit';
import IdInfoEditForm from '../../../components/Form/en/IdInfoEditForm/Identification';

const Index = () => {
  return (
    <div className={style.container}>
      <Switch>
        <Route exact path="/en/user/edit/profile" component={ProfileEdit} />
        <Route exact path="/en/user/edit/id-info" component={IdInfoEditForm} />
      </Switch>
    </div>
  );
};

export default Index;
