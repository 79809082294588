import {createAction} from 'redux-actions';

export const PATCH_COMPLETE = 'PATCH_COMPLETE';
export const SUCCESS_PATCH = 'SUCCESS_PATCH';

export const patchcomplete = payload => {
  return {type: PATCH_COMPLETE, payload: payload};
};

export const successPatch = payload => {
  return {type: SUCCESS_PATCH, payload: payload};
};

// 確認のためのモーダルフラグ
export const SET_CONFIRM_MODAL_FLAG = 'SET_CONFIRM_MODAL_FLAG';
export const setConfirmModalFlag = createAction(SET_CONFIRM_MODAL_FLAG);
