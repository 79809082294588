import React from 'react';

import styles from './info.module.scss';
import Text from '../../Text';
import BriefCase from '../../../assets/icon_briefcase.svg';
import Calendar from '../../../assets/icon_calendar.svg';
import {LocalOfferOutlined} from '@material-ui/icons';
import {COLOR_PALLET} from '../../../constants';

const Info = ({img, text, date}) => {
  const setParts = () => {
    switch (img) {
      case 'project':
        return [
          <img src={BriefCase} />,
          <Text children={`プロジェクト : ${text}`} component="text01" />
        ];
      case 'tags':
        text = text ? text : '未設定';
        return [
          <LocalOfferOutlined
            style={{fill: COLOR_PALLET.blue900, padding: '0.75rem'}}
          />,
          <Text children={`環境 : ${text}`} component="text01" />
        ];
      case 'date':
        return [
          <img src={Calendar} />,
          <Text children={`納期: ${text} ~ ${date}`} component="text01" />
        ];
      default:
        return '';
    }
  };

  const [icon, component] = setParts();

  return (
    <div className={styles.container}>
      {icon}
      <div className={styles.text}>{component}</div>
    </div>
  );
};

export default Info;
