import React from 'react';
import {useDispatch} from 'react-redux';
// components
import TermsOfServiceEn from '../../../components/MainTermsOfService/en';
import MenuAppBar from '../../../components/MenuAppBar';
import style from '../../../components/Terms/terms.module.scss';
import styles from './termsOfServiceAccept.module.scss';
import PageSubTitle from '../../../components/PageSubTitle';
import FooterPage from '../../../components/Footer/FooterPage';
import CommonButton from '../../../components/CommonButton';
import {requestAcceptTermsOfService} from '../../../actions/setting';

const TermsOfServiceAccept = () => {
  const dispatch = useDispatch();
  return (
    <>
      <MenuAppBar />
      <div className={style.pageTitleContainer}>
        <PageSubTitle title="利用規約に同意" />
      </div>
      <div className={styles.container}>
        <p>
          弊社の定める利用規約に同意いただいた上で、「同意する」ボタンの押下をお願いいたします。
        </p>
      </div>
      <TermsOfServiceEn />
      <div className={styles.container}>
        <CommonButton
          text={'同意する'}
          size={'large'}
          color={'pink'}
          variant={'contained'}
          shape={'normal'}
          onClick={() => dispatch(requestAcceptTermsOfService())}
          disabled={false}
        />
      </div>
      <FooterPage />
    </>
  );
};

export default TermsOfServiceAccept;
