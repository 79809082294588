import React from 'react';
import style from './app.module.scss'
import AppBar from '../../components/MenuAppBar'
import Footer from '../../components/Footer'


class AppLayout extends React.Component {

  state = {
    mobileOpen: false,
  };

  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }));
  };

  render() {

    return (
      <div>
        <AppBar/>
        <main className={style.main}>
          <div className={style.content}>
            {this.props.children}
          </div>
          <Footer/>
        </main>
      </div>
    );
  }
}

export default AppLayout
