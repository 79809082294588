import React from 'react';

import styles from './contentText.module.scss';
import EditButton from '../Button/EditButton';

const ContentTitle = ({title, isButton, link = null}) => {
  return (
    <>
      <div className={styles.flex}>
        <div className={styles.title}>{title}</div>
        {isButton ? <EditButton link={link} /> : <div />}
      </div>
      <div className={styles.border} />
    </>
  );
};

export default ContentTitle;
