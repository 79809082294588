import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles({
  iconButton: {
    padding: 0,
    marginLeft: '1rem'
  },
  tooltips: {
    width: '50rem',
    padding: '2rem',
    border: '0.1rem solid #093791'
  }
});
