import React from 'react';
import style from './text.module.scss';

class Text extends React.Component {
  renderClass = component => {
    switch (component) {
      case 'h1':
        return style.h1;
      case 'h2':
        return style.h2;
      case 'h3':
        return style.h3;
      case 'h4':
        return style.h4;
      case 'caption':
        return style.caption;
      case 'text01':
        return style.text01;
      case 'text02':
        return style.text02;
      case 'text03':
        return style.text03;
        return style.h4;
      case 'text':
        return style.text;
      case 'caption':
        return style.caption;
      case 'text04':
        return style.text04;
      case 'text05':
        return style.text05;
      case 'text06':
        return style.text06;
      case 'text07':
        return style.text07;
      case 'text08':
        return style.text08;
      case 'text09':
        return style.text09;
      case 'text10':
        return style.text10;
      case 'text11':
        return style.text11;
      default:
        return style.defalt;
    }
  };

  render() {
    const {children} = this.props;

    return (
      <div className={this.renderClass(this.props.component)}>{children}</div>
    );
  }
}

export default Text;
