import {all, call, put, takeEvery, take, fork} from 'redux-saga/effects';
import {startSubmit, stopSubmit, reset} from 'redux-form';
import {push} from 'connected-react-router';
import {fetchPostJson, fetchPatch, fetchGet, fetchDelete} from './utills/fetch';
import {
  requestMyEntryList,
  successMyEntryList,
  requestEntryList,
  successEntryList,
  requestPostEntry,
  requestDeleteEntry
} from '../actions/entry';
import {
  enqueueSnackbar,
  isShowEntrySnackbar,
  setSnackbarType
} from '../actions/snackbar';
import {effect, array, iterator, promise, task} from '@redux-saga/is';
import {
  requestTaskDetail,
  setModalSetting,
  successTaskDetail
} from '../actions/task';
import {setPublicComment, setPublicCommentList} from '../actions/comment';
import {requestBookmarkList} from '../actions/bookmark';
import {setErrorContent} from '../actions/errorHandler';
import {setIsSubmitting} from '../actions/common';

// 自分が応募しているタスク の一覧取得
export function* getMyEntryList() {
  while (true) {
    const action = yield take(requestMyEntryList);
    const [data, error] = yield call(fetchGet, `my-entry/`);
    yield put(successMyEntryList(data.results));
  }
}

// 当該タスクの応募者一覧取得
export function* getEntryList() {
  while (true) {
    const action = yield take(requestEntryList);
    let url = action.payload.page
      ? `tasks/${action.payload.id}/entry/?page=${action.payload.page}`
      : `tasks/${action.payload.id}/entry/`;
    const [data, error] = yield call(fetchGet, url);
    yield put(successEntryList(data));
  }
}

// 応募する
export function* postEntry() {
  while (true) {
    const action = yield take(requestPostEntry);
    yield put(setIsSubmitting(true));
    const [data, error] = yield call(
      fetchPostJson,
      `tasks/${action.payload.task}/entry`,
      action.payload
    );
    yield put(setIsSubmitting(false));
    if (data && !error) {
      yield put(requestTaskDetail({id: action.payload.task}));
      yield put(
        enqueueSnackbar({
          message: {
            text: 'エントリーが完了しました',
            subText: null
          },
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'info',
            action: key => {}
          }
        })
      );
    } else {
      yield put(setModalSetting({type: 'NEED_INPUT_ID', isVisible: true}));
    }
  }
}

// 応募取り消し
export function* deleteEntry() {
  while (true) {
    const action = yield take(requestDeleteEntry);
    yield put(setIsSubmitting(true));
    const [data, error] = yield call(
      fetchDelete,
      `tasks/${action.payload.taskId}/entry/${action.payload.targetId}/`
    );
    if (data && !error) {
      yield put(requestTaskDetail({id: action.payload.taskId}));
      yield put(
        enqueueSnackbar({
          message: {
            text: 'エントリーがキャンセルされました',
            subText: null
          },
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'info',
            action: key => {}
          }
        })
      );
    } else {
      yield put(setErrorContent(error));
      console.log('error!');
    }
  }
}

const entry = [
  fork(getMyEntryList),
  fork(getEntryList),
  fork(postEntry),
  fork(deleteEntry)
];

export default entry;
