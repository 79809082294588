import {all, call, put, fork, take, select} from 'redux-saga/effects';
import {startSubmit, stopSubmit, reset} from 'redux-form';
import {history, store} from '../store';
import {fetchPostJson, fetchGet, fetchPatch} from './utills/fetch';
import {selectReset} from './selectors/index';
import {
  requestPasswordReset,
  requestPasswordResetMail,
  requestPasswordResetCheckKey,
  successPasswordResetCheckKey,
  requestPasswordResetConfirm
} from '../actions/reset';
import {setMessageType} from '../actions/dialog';
import {setModalSetting} from '../actions/task';
import {formatErrorResponse, makeFormErrorFormat} from '../common';

export function* postPasswordReset() {
  while (true) {
    const action = yield take(requestPasswordReset);
    const [data, error] = yield call(
      fetchPostJson,
      `password_reset`,
      action.payload
    );
    if (data && !error) {
      yield call(history.push, '/password-reset-mail-result', data);
    } else {
      yield put(stopSubmit('passwordReset', makeFormErrorFormat(error)));
    }
  }
}

export function* postPasswordResetConfirm() {
  while (true) {
    const action = yield take(requestPasswordResetConfirm);

    if (action.payload.new_password1 !== action.payload.new_password2) {
      yield put(
        stopSubmit('passwordResetConfirm', {
          _error: '同じパスワードを入力して下さい。'
        })
      );
      continue;
    }

    const [data, error] = yield call(
      fetchPostJson,
      `password_reset/confirm`,
      action.payload
    );
    if (data && !error) {
      yield put(
        setModalSetting({
          type: '',
          isVisible: true,
          params: {
            title: 'パスワードの再設定が完了しました',
            message:
              'パスワードの再設定に成功しました。\n再度ログインをお試しください。',
            buttonProps: {
              text: '完了',
              onClick: () => {
                store.dispatch(setModalSetting({type: '', isVisible: false}));
                history.push(`/signin`);
              }
            }
          }
        })
      );
    } else {
      yield put(
        stopSubmit('passwordResetConfirm', makeFormErrorFormat(error))
      );
    }
  }
}

export function* postPasswordResetMail() {
  while (true) {
    const action = yield take(requestPasswordResetMail);
    yield put(startSubmit('passwordResetMail'));
    const [data, error] = yield call(
      fetchPostJson,
      'password_reset_mail',
      action.payload
    );
    if (data && !error) {
      yield put(reset('passwordResetMail'));
      yield put(setMessageType('sendMail')); //dialog表示
    } else {
      yield put(
        stopSubmit(
          'passwordResetMail',
          Object.assign(error, {_error: error.non_field_errors})
        )
      );
    }
  }
}

export function* postPasswordResetCheckKey() {
  while (true) {
    const action = yield take(requestPasswordResetCheckKey);
    const [data, error] = yield call(
      fetchGet,
      `password_reset_key/?hash=${action.payload}`
    );
    if (data && !error) {
      yield put(successPasswordResetCheckKey(data));
    } else {
      yield put(
        stopSubmit(
          'passwordReset',
          Object.assign(error, {_error: error.non_field_errors})
        )
      );
    }
  }
}

const Reset = [
  fork(postPasswordReset),
  fork(postPasswordResetConfirm),
  fork(postPasswordResetMail),
  fork(postPasswordResetCheckKey)
];

export default Reset;
