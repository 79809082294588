import React from 'react';

import styles from './coMenu.module.scss';
import MenuButton from '../../../components/Button/MenuButton';
import {MENUES} from '../../../constants';
import MediaQuery from 'react-responsive';
import {mediaQuery} from '../../../constants';

const CoMenu = () => {
  return (
    <>
      <MediaQuery query={mediaQuery.min}>
        <div className={styles.underBackground} />
        <div className={styles.background}>
          <MenuButton name="TOP" page={MENUES.dashboard} kind={'co'} />
          <MenuButton
            name="エンジニアを探す"
            page={MENUES.search}
            kind={'co'}
          />
          <MenuButton
            name="受注承諾待ちタスク"
            page={MENUES.offerAdmin}
            kind={'co'}
          />
          <MenuButton name="プロジェクト" page={MENUES.project} kind={'co'} />
          <MenuButton name="企業情報" page={MENUES.company} kind={'co'} />
          <MenuButton name="企業メンバー" page={MENUES.member} kind={'co'} />
          <MenuButton name="メッセージ" page={MENUES.email} kind={'co'} />
          <MenuButton name="報酬管理" page={MENUES.rewardAdmin} />
          <MenuButton
            name="お支払い方法の設定"
            page={MENUES.paymentMethod}
            kind={'co'}
          />
        </div>
      </MediaQuery>
    </>
  );
};

export default CoMenu;
