import React from 'react';
import styles from './tableItem.module.scss';

const TableItem = ({header, data}) => (
  <tr className={styles.record}>
    <th className={styles.header}>{header}</th>
    <td className={styles.data}>
      {data.map((datum, index) => (
        <p key={index.toString()}>{datum}</p>
      ))}
    </td>
  </tr>
);

export default TableItem;
