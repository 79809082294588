import React from 'react';

import styles from './item.module.scss';

const Item = ({text, required, children}) => (
  <section className={styles.container}>
    <div className={styles.header}>
      <h3 className={styles.text}>{text}</h3>
      {required && (
        <div>
          <label className={styles.required}>必須</label>
        </div>
      )}
    </div>
    <div className={styles.content}>{children}</div>
  </section>
);

export default Item;
