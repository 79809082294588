import React from 'react';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles({
  button: {
    boxShadow: 'none',
    backgroundColor: '#EA397D',
    color: '#FFF',
    fontSize: '1.6rem',
    fontWeight: 'bold',
    width: '100%',
    height: '100%',
    textTransform: 'none',

    '&:active': {
      boxShadow: 'none'
    }
  }
});

const SmallButton = ({text, onClick, pristine, submitting}) => {
  const classes = useStyles();

  return (
    <Button
      disabled={pristine || submitting}
      type="submit"
      onClick={onClick}
      className={classes.button}
      variant="contained"
      color="secondary"
    >
      {text}
    </Button>
  );
};

export default SmallButton;
