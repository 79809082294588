import React from 'react';
import {Field, getFormValues, reduxForm, getFormSubmitErrors} from 'redux-form';
import style from '../paidApplicationForm.module.scss';
import classNames from 'classnames';
import {renderText, renderSelect} from '../../../../Field';
import FormHelperText from '@material-ui/core/FormHelperText';
import Required from '../../../../Required';
import MenuItem from '@material-ui/core/MenuItem';
import OutlineButton from './../../../../../components/Buttons/OutlineButton';
import * as Validator from '../../../utils/validate';
import {useSelector} from 'react-redux';
import FormError from '../../../FormError';
import Blank from '../../../../Blank';

const PaidApplicationForm = props => {
  const {handleSubmit, pristine, reset, submitting, members, error} = props;

  const submitErrors = useSelector(state =>
    getFormSubmitErrors('paidApplication')(state)
  );

  const formValues = useSelector(state =>
    getFormValues('paidApplication')(state)
  );

  let disabledSubmit = true;

  if (formValues !== undefined) {
    disabledSubmit =
      !formValues.representative_sei ||
      !formValues.representative_mei ||
      !formValues.representative_sei_kana ||
      !formValues.representative_mei_kana ||
      !formValues.zipCode1 ||
      !formValues.zipCode2 ||
      !formValues.prefecture ||
      !formValues.address1 ||
      !formValues.address2 ||
      !formValues.email ||
      !formValues.clerk;
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className={style.container}>
        <div className={style.row}>
          <div className={style.rowItem}>
            <p className={style.subTitle}>代表取締役</p>
            <Required />
            <div className={classNames(style.formArea, style.addressFlex)}>
              <div className={style.titleName}>氏名</div>
              <div className={style.lastName}>
                <Field
                  name="representative_sei"
                  required={true}
                  component={renderText}
                  placeholder="山田"
                  fullWidth={true}
                  validate={[Validator.RequiredItem]}
                />
              </div>
              <div className={style.firstName}>
                <Field
                  name="representative_mei"
                  required={true}
                  component={renderText}
                  placeholder="太郎"
                  fullWidth={true}
                  validate={[Validator.RequiredItem]}
                />
              </div>
            </div>
          </div>
          <div className={classNames(style.rowItem, style.rowItemMarginTop)}>
            <p className={style.subTitle} />
            <Required />
            <div className={classNames(style.formArea, style.addressFlex)}>
              <div className={style.titleName}>カナ</div>
              <div className={style.lastName}>
                <Field
                  name="representative_sei_kana"
                  required={true}
                  component={renderText}
                  placeholder="ヤマダ"
                  fullWidth={true}
                  validate={[Validator.RequiredItem]}
                />
              </div>
              <div className={style.firstName}>
                <Field
                  name="representative_mei_kana"
                  required={true}
                  component={renderText}
                  placeholder="タロウ"
                  fullWidth={true}
                  validate={[Validator.RequiredItem]}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={style.row}>
          <div className={style.rowItem}>
            <p className={style.subTitle}>住所</p>
            <Required />
            <div className={classNames(style.formArea, style.addressFlex)}>
              <div className={style.titleName}>郵便番号</div>
              <div className={style.inputsContainer}>
                <div className={classNames(style.smallWidth)}>
                  <Field
                    name="zipCode1"
                    required={true}
                    component={renderText}
                    fullWidth={true}
                    placeholder="123"
                    validate={[Validator.RequiredItem]}
                  />
                </div>
                <p>ー</p>
                <div className={classNames(style.mediumWidth)}>
                  <Field
                    name="zipCode2"
                    required={true}
                    component={renderText}
                    fullWidth={true}
                    placeholder="4567"
                    validate={[Validator.RequiredItem]}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={classNames(style.rowItem, style.rowItemMarginTop)}>
            <p className={style.subTitle} />
            <Required />
            <div className={classNames(style.formArea, style.addressFlex)}>
              <div className={style.titleName}>都道府県</div>
              <div className={style.largeWidth}>
                <Field
                  name="prefecture"
                  required={true}
                  component={renderText}
                  fullWidth={true}
                  placeholder="東京都"
                  validate={[Validator.RequiredItem]}
                />
              </div>
            </div>
          </div>

          <div className={classNames(style.rowItem, style.rowItemMarginTop)}>
            <p className={style.subTitle} />
            <Required />
            <div className={classNames(style.formArea, style.addressFlex)}>
              <div className={style.titleName}>市区町村</div>
              <div className={style.largeWidth}>
                <Field
                  name="address1"
                  required={true}
                  component={renderText}
                  fullWidth={true}
                  placeholder="渋谷区神宮前"
                  validate={[Validator.RequiredItem]}
                />
              </div>
            </div>
          </div>

          <div className={classNames(style.rowItem, style.rowItemMarginTop)}>
            <p className={style.subTitle} />
            <Required />
            <div className={classNames(style.formArea, style.addressFlex)}>
              <div className={style.titleName}>番地</div>
              <div className={style.largeWidth}>
                <Field
                  name="address2"
                  required={true}
                  component={renderText}
                  fullWidth={true}
                  placeholder="0000"
                  validate={[Validator.RequiredItem]}
                />
              </div>
            </div>
          </div>

          <div className={classNames(style.rowItem, style.rowItemMarginTop)}>
            <div />
            <div className={classNames(style.formArea, style.addressFlex)}>
              <div className={style.titleName}>建物名</div>
              <div className={style.largeWidth}>
                <Field
                  name="address3"
                  required={true}
                  component={renderText}
                  fullWidth={true}
                  placeholder="建物名"
                />
              </div>
            </div>
          </div>
        </div>
        <div className={style.row}>
          <div className={style.rowItem}>
            <p className={style.subTitle}>部署名/支店名</p>
            <div className={style.formArea}>
              <div className={classNames(style.form)}>
                <Field
                  name="aux_name"
                  required={true}
                  component={renderText}
                  fullWidth={true}
                  placeholder="システム開発部"
                />
              </div>
            </div>
          </div>
        </div>
        <div className={style.row}>
          <div className={style.rowItem}>
            <p className={style.subTitle}>メールアドレス</p>
            <Required />
            <div className={style.formArea}>
              <div className={classNames(style.form)}>
                <Field
                  name="email"
                  required={true}
                  component={renderText}
                  fullWidth={true}
                  placeholder="sample@task.info"
                  validate={[Validator.RequiredItem]}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={style.row}>
          <div className={style.rowItem}>
            <p className={style.subTitle}>担当者</p>
            <Required />
            <div className={style.formArea}>
              <div className={classNames(style.form)}>
                <div className={style.smallWidth}>
                  <Field
                    name="clerk"
                    required={true}
                    component={renderSelect}
                    fullWidth={true}
                    placeholder="sample@task.info"
                    validate={[Validator.RequiredItem]}
                  >
                    <MenuItem value="">選択してください</MenuItem>
                    {members.map((elem, index) => (
                      <MenuItem value={elem.id} key={index.toString()}>
                        {elem.name}
                      </MenuItem>
                    ))}
                  </Field>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Blank height={'2.4rem'} />
        <FormError error={error} />
        <div className={style.buttonContainer}>
          <OutlineButton
            kind={'applicationPaid'}
            disabled={disabledSubmit || submitting}
          />
        </div>
      </div>
    </form>
  );
};

const GmoErrorHandler = ({errors}) => {
  return Object.values(errors).map((item, index) => (
    <div className={style.error} key={index.toString()}>
      {item}
    </div>
  ));
};

export default reduxForm({
  form: 'paidApplication'
})(PaidApplicationForm);
