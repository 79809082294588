import React from 'react';
import format from 'date-fns/format';

const Index = (timestamp, type, returnType = 'component') => {
  if (
    typeof timestamp === 'undefined' ||
    timestamp.toString() === 'Invalid Date'
  )
    return null;

  if (returnType === 'str') {
    switch (type) {
      case 'unsetDate':
        return timestamp ? format(new Date(timestamp), 'yyyy年MM月') : null;
      case 'unsetYear':
        return timestamp ? format(new Date(timestamp), 'MM月dd日') : null;
      case 'date':
        return timestamp ? format(new Date(timestamp), 'yyyy年MM月dd日') : null;
      case 'minute':
        return timestamp
          ? format(new Date(timestamp), 'yyyy年MM月dd日 H:mm')
          : null;
      case 'sec':
        return timestamp
          ? format(new Date(timestamp), 'yyyy年MM月dd日 H:mm:ss')
          : null;
    }
  } else {
    switch (type) {
      case 'date':
        return <>{format(new Date(timestamp), 'yyyy年MM月dd日')}</>;
      case 'minute':
        return <>{format(new Date(timestamp), 'yyyy年MM月dd日 H:mm')}</>;
      case 'sec':
        return <>{format(new Date(timestamp), 'yyyy年MM月dd日 H:mm:ss')}</>;
      case 'justMonth':
        return <>{format(new Date(timestamp), 'MM')}</>;
      case 'justDay':
        return <>{format(new Date(timestamp), 'dd')}</>;
    }
  }
};

export default Index;
