import React from 'react';
import {useSelector} from 'react-redux';
import {
  Field,
  FieldArray,
  change,
  reduxForm,
  formValueSelector,
  getFormValues
} from 'redux-form';
import classNames from 'classnames';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button/index';
import style from './profileEditForm.module.scss';
import commonStyle from '../../common.module.scss';
import SubTitle from '../../../SubTitle';
import {renderText, renderTextArea, renderSelect} from '../../../Field';
import InputImgField from '../../../../components/Form/InputImgField';
import * as Validator from '../../utils/validate';
import FormError from '../../FormError';
import Blank from '../../../Blank';

const useStyles = makeStyles({
  submitButton: {
    width: '30rem',
    height: '5rem',
    fontSize: '1.8rem',
    fontWeight: 'bold',
    color: '#093791',
    borderColor: '#093791',
    '&:hover': {
      background: '#D7E2F4'
    }
  }
});

const Content = props => {
  const classes = useStyles();
  const {img} = useSelector(state => getFormValues('editCoProfile')(state));

  const {error, handleSubmit, submitErrors, pristine, submitting} = props;

  return (
    <form className={style.formWrapper} onSubmit={handleSubmit}>
      <div className={commonStyle.main}>
        <SubTitle headerText={'プロフィール'} />
        <div className={style.rowImage}>
          <div
            className={classNames(
              style.rowTitle,
              style.subtitleText,
              style.imgText
            )}
          >
            <p>画像</p>
          </div>
          <InputImgField img={img} name={'img'} id={'img'} />
        </div>
        <div className={style.divider} />
        <div className={style.noneBottomRow}>
          <div className={style.rowNickName}>
            <div
              className={classNames(
                style.rowTitle,
                style.subtitleText,
                style.nicknameText
              )}
            >
              <p>表示用の名前</p>
            </div>
            <div className={commonStyle.rowRequire}>
              <label className={commonStyle.notRequired}>必須</label>
            </div>
            <div className={style.rowInput}>
              <Field
                name="nick_name"
                required={true}
                component={renderText}
                fullWidth={true}
                validate={[Validator.RequiredItem]}
              />
            </div>
          </div>
        </div>

        <div className={style.SubTitle}>
          <SubTitle headerText={'その他情報'} />
        </div>
        <div className={style.rowOther}>
          <div className={classNames(style.rowTitle, style.subtitleText)}>
            <p>自己紹介</p>
          </div>
          <div className={commonStyle.rowRequire}>
            <label className={commonStyle.notRequired}>必須</label>
          </div>
          <div className={style.rowInputFullWidth}>
            <Field
              name="introduction"
              required={true}
              component={renderTextArea}
              rows={7}
              fullWidth={true}
              placeholder={'株式会社〇〇で、システム開発を担当しています。'}
            />
          </div>
        </div>
        <Blank height={'2.4rem'} />
        <FormError error={error} />
        <div className={commonStyle.submits}>
          <div className={commonStyle.submit}>
            <Button
              className={classes.submitButton}
              type="submit"
              disabled={pristine || submitting}
              variant="outlined"
              color="primary"
            >
              {'保存する'}
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default reduxForm({
  enableReinitialize: true,
  form: 'editCoProfile',
  multipartForm: true
})(Content);
