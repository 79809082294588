import {call, put, take, fork} from 'redux-saga/effects';
import {startSubmit, stopSubmit, reset} from 'redux-form';
import {push} from 'connected-react-router';
import {fetchPostJson, fetchPatch, fetchGet, fetchDelete} from './utills/fetch';
import {
  requestBookmarkList,
  successBookmarkList,
  requestPostBookmark,
  requestDeleteBookmark
} from '../actions/bookmark';
import {requestTaskDetail} from '../actions/task';
import {
  enqueueSnackbar,
  isShowBookmarkSnackbar,
  setSnackbarType
} from '../actions/snackbar';
import {setIsSubmitting} from '../actions/common';

// ブックマーク一覧取得
export function* getBookmarkList() {
  while (true) {
    const action = yield take(requestBookmarkList);
    const [data, error] = yield call(fetchGet, `my-bookmark/`);
    yield put(successBookmarkList(data.results));
  }
}

// ブックマークに追加
export function* postBookmark() {
  while (true) {
    const action = yield take(requestPostBookmark);
    yield put(setIsSubmitting(true));
    const [data, error] = yield call(
      fetchPostJson,
      `tasks/${action.payload.taskId}/bookmarks`,
      action.payload
    );
    if (data && !error) {
      yield put(requestTaskDetail({id: action.payload.taskId}));
      yield put(
        enqueueSnackbar({
          message: {
            text: '検討リストに入れました',
            subText: null
          },
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'info',
            action: key => {}
          }
        })
      );
    } else {
      console.log('error!');
    }
  }
}

// ブックマークから削除
export function* deleteBookmark() {
  while (true) {
    const action = yield take(requestDeleteBookmark);
    yield put(setIsSubmitting(true));
    const [data, error] = yield call(
      fetchDelete,
      `tasks/${action.payload.taskId}/bookmarks/${action.payload.bookmarkId}/`
    );
    if (data && !error) {
      yield put(requestTaskDetail({id: action.payload.taskId}));
      yield put(
        enqueueSnackbar({
          message: {
            text: '検討リストからはずしました',
            subText: null
          },
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'info',
            action: key => {}
          }
        })
      );
    } else {
      console.log('error!');
    }
  }
}

const bookmark = [
  fork(getBookmarkList),
  fork(postBookmark),
  fork(deleteBookmark)
];

export default bookmark;
