import {call, put, take, fork, select} from 'redux-saga/effects';

import {
  requestPersonalNewsList,
  successPersonalNewsList,
  requestHandlePersonalOnSnapshot,
  successPersonalHeadNewsList,
  setPersonalNewsLastSnapshot
} from '../actions/personalNews';
import {
  fetchPersonalNotification,
  snackbarHandler
} from '../firebase/personalNotification';
import {setIsReadAllPersonalNotification} from '../actions/personalNews';
import {formatQuerySnapshot} from '../firebase/common'

export function* getPersonalNewsList() {
  while (true) {
    const {payload: username} = yield take(requestPersonalNewsList);
    const personalNewsLastSnapshot = yield select(
      state => state.personalNews.personalNewsLastSnapshot
    );
    const [personalNewsList, lastSnapshot, err] = yield call(
      fetchPersonalNotification,
      personalNewsLastSnapshot,
      username
    );
    if (!err) {
      yield put(successPersonalNewsList(personalNewsList));
      yield put(setPersonalNewsLastSnapshot(lastSnapshot));
    }
  }
}

export function* handlePersonalOnSnapshot() {
  while (true) {
    const {payload: querySnapshot} = yield take(
      requestHandlePersonalOnSnapshot
    );
    snackbarHandler(querySnapshot);
    const personalNewsList = formatQuerySnapshot(querySnapshot);
    yield put(successPersonalHeadNewsList(personalNewsList));
    const isReadAll = personalNewsList.every(item => item.already_read);
    yield put(setIsReadAllPersonalNotification(isReadAll));
  }
}

const personalNews = [
  fork(getPersonalNewsList),
  fork(handlePersonalOnSnapshot)
];

export default personalNews;
