import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import ImportantNotice from '../../../components/ImportantNotice';

import styles from './dashboard.module.scss';
import commonStyles from '../../../common/common.module.scss';
import {
  requestEntryTask,
  requestBookmarkTask,
  requestMyTask,
  requestPreAssignList
} from '../../../actions/setting';

import Title from '../../../components/Title';
import TaskList from '../../../components/Task/TaskList';
import EvaluationTaskList from '../../../components/Task/EvaluationTaskList';
import {requestMustReviewTasks} from '../../../actions/review';
import NotAvailable from '../../../components/NotAvailable';
import EnTaskList from '../../../components/Task/EnTaskList';
import {TASK_STATUS} from '../../../common';
import HelpBtnNextToItem from '../../../components/HelpButtons/HelpBtnNextToItem';
import {topEn} from '../../../components/HelpButtons/constants';
import AboutStatus from '../../../components/AboutStatus';
import Blank from '../../../components/Blank';

const DashBoard = () => {
  const dispatch = useDispatch();
  const setting = useSelector(state => state.setting);
  const me = setting.me;

  useEffect(() => {
    //自分がエントリーしているタスク一覧、さらにそこから仮アサインされているタスクも絞り込み、stateに入れる。
    if (me.username) {
      dispatch(requestEntryTask());
      dispatch(requestMustReviewTasks());
      dispatch(requestBookmarkTask());
      dispatch(requestMyTask());
      dispatch(requestPreAssignList());
    }
  }, [me]);

  let mustReviewTaskList = useSelector(state => state.review.tasks);
  mustReviewTaskList = mustReviewTaskList.filter(
    ele => ele.status == TASK_STATUS['checked']
  );
  const {importantNotifications} = useSelector(state => state.notification);

  let entryList = setting.entryList;
  entryList = entryList.filter(ele => ele.status == TASK_STATUS['wanted']);

  let bookmarkList = setting.bookmarkList;
  bookmarkList = bookmarkList.filter(
    ele => ele.status == TASK_STATUS['wanted']
  );

  let assignList = setting.myTaskList;
  assignList = assignList.filter(ele => ele.status < TASK_STATUS['closed']);

  const isTaskListEmpty =
    mustReviewTaskList.length === 0 &&
    assignList.length === 0 &&
    entryList.length === 0 &&
    bookmarkList.length === 0;

  if (isTaskListEmpty) {
    return <NotAvailable kind="task-search" />;
  }

  // TODO: １つ１つに表示非表示チェックつけてる今はナンセンス
  // TODO: 全て同じタスクリストコンポーネントを使用する様にしたら修正する
  return (
    <>
      <AboutStatus />
      <Blank height={'5rem'} />
      {importantNotifications.length ? (
        <ImportantNotice importantNotifications={importantNotifications} />
      ) : null}
      <main className={styles.main}>
        {mustReviewTaskList.length > 0 ? (
          <>
            <div className={styles.title}>
              <HelpBtnNextToItem text={topEn.review}>
                <Title component={'h2'} text={'タスクを評価'} />
              </HelpBtnNextToItem>
            </div>
            <div className={commonStyles.taskList}>
              <EnTaskList contents={mustReviewTaskList} />
            </div>
          </>
        ) : null}
        {setting.preAssignList.length > 0 ? (
          <>
            <div className={styles.title}>
              <HelpBtnNextToItem text={topEn.preAssigned}>
                <Title component={'h2'} text={'オファーを受けているタスク'} />
              </HelpBtnNextToItem>
            </div>
            <div className={commonStyles.taskList}>
              <EnTaskList contents={setting.preAssignList} />
            </div>
          </>
        ) : null}
        {assignList.length > 0 ? (
          <>
            <div className={styles.title}>
              <HelpBtnNextToItem text={topEn.assigned}>
                <Title component={'h2'} text={'アサインされたタスク'} />
              </HelpBtnNextToItem>
            </div>
            <div className={commonStyles.taskList}>
              <EnTaskList contents={assignList} />
            </div>
          </>
        ) : null}
        {entryList.length > 0 ? (
          <>
            <div className={styles.title}>
              <HelpBtnNextToItem text={topEn.entry}>
                <Title component={'h2'} text={'エントリー済みタスク'} />
              </HelpBtnNextToItem>
            </div>
            <div className={commonStyles.taskList}>
              <EnTaskList contents={entryList} />
            </div>
          </>
        ) : null}
        {bookmarkList.length > 0 ? (
          <>
            <div className={styles.title}>
              <HelpBtnNextToItem text={topEn.bookmark}>
                <Title component={'h2'} text={'検討中リスト'} />
              </HelpBtnNextToItem>
            </div>
            <div className={commonStyles.taskList}>
              <EnTaskList contents={bookmarkList} />
            </div>
          </>
        ) : null}
      </main>
    </>
  );
};

export default DashBoard;
