import {createAction} from 'redux-actions';

// タスクにエンジニアをアサイン
export const REQUEST_POST_ASSIGN = 'REQUEST_POST_ASSIGN';
export const requestPostAssign = createAction(REQUEST_POST_ASSIGN);

// アサインの取り消し
export const REQUEST_DELETE_ASSIGN = 'REQUEST_DELETE_ASSIGN';
export const requestDeleteAssign = createAction(REQUEST_DELETE_ASSIGN);

// タスクにエンジニアを仮アサイン
export const REQUEST_PRE_ASSIGN = 'REQUEST_PRE_ASSIGN';
export const requestPreAssign = createAction(REQUEST_PRE_ASSIGN);

// エンジニアが仮アサインを承諾
export const REQUEST_ACCEPT_PRE_ASSIGN = 'REQUEST_ACCEPT_PRE_ASSIGN';
export const requestAcceptPreAssign = createAction(REQUEST_ACCEPT_PRE_ASSIGN);

// エンジニアが仮アサインを辞退
export const REQUEST_REJECT_PRE_ASSIGN = 'REQUEST_REJECT_PRE_ASSIGN';
export const requestRejectPreAssign = createAction(REQUEST_REJECT_PRE_ASSIGN);

// エンジニアにオファー（実態は仮アサイン）
export const REQUEST_OFFER = 'REQUEST_OFFER';
export const requestOffer = createAction(REQUEST_OFFER);
