export const PATCH_CONFIRM = 'PATCH_CONFIRM';
export const SUCCESS_PATCH = 'SUCCESS_PATCH';

export const patchConfirm = payload => {
  return {type: PATCH_CONFIRM, payload: payload};
};

export const successPatch = payload => {
  return {type: SUCCESS_PATCH, payload: payload};
};
