import React from 'react';
import {useSelector} from 'react-redux';
import {Field, FieldArray, reduxForm, getFormValues} from 'redux-form';
import style from '../profileEditForm.module.scss';
import commonStyle from '../../../common.module.scss';
import classNames from 'classnames';
import SubTitle from '../../../../SubTitle';
import TagInput from '../../../../TagInput';
import InputImgField from '../../../../Form/InputImgField';
import {renderText, renderTextArea, renderSelect} from '../../../../Field';
import MenuItem from '@material-ui/core/MenuItem/index';
import * as Validator from '../../../utils/validate';
import CommonButton from '../../../../CommonButton';
import FormError from '../../../FormError';
import Blank from '../../../../Blank';

// マスタは設けていないので、ここで定数定義
const STATUS_CHOICES = [
  {id: 1, title: '休眠中'},
  {id: 2, title: '受付中'},
  {id: 3, title: 'スケジュール調整可案件のみ、受付中'},
  {id: 4, title: '多忙につき、受付不可'}
];

const ProfileEditForm = props => {
  const {error, handleSubmit, pristine, submitting} = props;
  const {img} = useSelector(state => getFormValues('editEnProfile')(state));

  return (
    <form className={style.formWrapper} onSubmit={handleSubmit}>
      <div className={commonStyle.main}>
        <SubTitle headerText={'プロフィール'} />
        <div className={style.rowImage}>
          <div
            className={classNames(
              style.rowTitle,
              style.subtitleText,
              style.imgText
            )}
          >
            <p>画像</p>
          </div>
          <div className={style.avatarContainer}>
            <div className={style.buttonContainer}>
              <InputImgField img={img} id={'img'} name={'img'} />
            </div>
          </div>
        </div>
        <div>
          <div className={style.rowProfileElement}>
            <div
              className={classNames(
                style.rowTitle,
                style.subtitleText,
                style.profileText
              )}
            >
              <p>受注状態</p>
            </div>
            <div className={commonStyle.rowRequire}>
              <label className={commonStyle.notRequired}>必須</label>
            </div>
            <div className={style.rowInput}>
              <Field name="status" required={true} component={renderSelect}>
                <MenuItem value="">選択してください</MenuItem>
                {STATUS_CHOICES.map((elem, index) => (
                  <MenuItem value={elem.id} key={index.toString()}>
                    {elem.title}
                  </MenuItem>
                ))}
              </Field>
            </div>
          </div>
        </div>
        <div className={style.divider} />
        <div className={style.noneBottomRow}>
          <div className={style.rowProfileElement}>
            <div
              className={classNames(
                style.rowTitle,
                style.subtitleText,
                style.profileText
              )}
            >
              <p>表示用の名前</p>
            </div>
            <div className={commonStyle.rowRequire}>
              <label className={commonStyle.notRequired}>必須</label>
            </div>
            <div className={style.rowInput}>
              <Field
                name="nick_name"
                required={true}
                component={renderText}
                fullWidth={true}
                validate={[Validator.RequiredItem]}
              />
            </div>
          </div>
        </div>
        <div className={style.rowProfileElement}>
          <div
            className={classNames(
              style.rowTitle,
              style.subtitleText,
              style.profileText
            )}
          >
            <p>スキル</p>
          </div>
          <div className={commonStyle.rowRequire}>
            <label className={commonStyle.notRequired}>必須</label>
          </div>
          <div className={style.rowInputTag}>
            <FieldArray name="tags" component={TagInput} />
          </div>
        </div>
        <div className={style.SubTitle}>
          <SubTitle headerText={'その他情報'} />
        </div>
        <div className={style.rowOther}>
          <div className={classNames(style.rowTitle, style.subtitleText)}>
            <p>紹介文</p>
          </div>
          <div className={commonStyle.rowRequire}>
            <label className={commonStyle.notRequired}>必須</label>
          </div>
          <div className={style.rowInputFullWidth}>
            <Field
              name="introduction"
              required={true}
              component={renderTextArea}
              rows={7}
              fullWidth={true}
              placeholder={'例：主にPythonを使用した御依頼を承っております。'}
            />
          </div>
        </div>
        <div className={style.rowOther}>
          <div className={classNames(style.rowTitle, style.subtitleText)}>
            <p>経歴</p>
          </div>
          <div className={commonStyle.rowRequire}>
            <label className={commonStyle.notRequired}>必須</label>
          </div>
          <div className={style.rowInputFullWidth}>
            <Field
              name="career"
              required={true}
              component={renderTextArea}
              rows={7}
              fullWidth={true}
            />
          </div>
        </div>
        <Blank height={'2.4rem'} />
        <FormError error={error} />
        <div className={commonStyle.submits}>
          <div className={commonStyle.submit}>
            <CommonButton
              type="submit"
              text={'保存する'}
              size={'medium'}
              color={'blue'}
              variant={'outlined'}
              shape={'normal'}
              disabled={pristine || submitting}
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default reduxForm({
  enableReinitialize: true,
  form: 'editEnProfile',
  multipartForm: true
})(ProfileEditForm);
