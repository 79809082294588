import {
  SUCCESS_FORMAT_LIST
} from '../actions/format'

const initialState = {
  list: []
};


function format(state = initialState, action) {
  switch (action.type) {
    case SUCCESS_FORMAT_LIST:
      return {
        ...state,
        list: action.payload
      }
    default :
      return state
  }
}

export default format
