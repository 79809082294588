import {
  all,
  call,
  put,
  takeEvery,
  take,
  fork,
  select
} from 'redux-saga/effects';
import {startSubmit, stopSubmit, reset} from 'redux-form';
import {push} from 'connected-react-router';
import {fetchPostJson, fetchPatch, fetchGet, fetchDelete} from './utills/fetch';
import {
  requestProjectList,
  requestOwnProjectList,
  successOwnProjectList,
  successProjectList,
  requestProjectDetail,
  successProjectDetail,
  requestProjectCreate,
  requestProjectEdit,
  requestProjectDelete
} from '../actions/project';
import {selectReset} from './selectors';
import {makeFormErrorFormat} from "../common";

// 全企業のプロジェクト一覧取得（エンジニアに見せる用のプロジェクト一覧ができたら使う）
// export function* getProjectList() {
//   while (true) {
//     const action = yield take(requestProjectList);
//     let url = action.payload === undefined ? `projects/` : `projects/?page=` + action.payload;
//     const [data, error] = yield call(fetchGet, url);
//
//     yield put(successProjectList(data))
//   }
// }

// 自社のプロジェクト一覧取得
export function* getOwnProjectList() {
  while (true) {
    const action = yield take(requestOwnProjectList);
    const companyId = yield select(state => state.setting.companyId);

    let url =
      action.payload === undefined
        ? `companies/${companyId}/projects/`
        : `companies/${companyId}/projects/?page=` + action.payload;
    const [data, error] = yield call(fetchGet, url);

    if (data && !error) {
      yield put(successOwnProjectList(data));
    } else {
      console.error('企業情報の読み込みに失敗しました');
    }
  }
}

// プロジェクト詳細取得
export function* getProjectDetail() {
  while (true) {
    const action = yield take(requestProjectDetail);
    const [data, error] = yield call(fetchGet, `projects/${action.payload}/`);
    yield put(successProjectDetail(data));
  }
}

// プロジェクト新規作成
export function* postProject() {
  while (true) {
    const action = yield take(requestProjectCreate);
    // 後々使用する可能性があるため、今は空文字を入れておく
    action.payload['regulation'] = '代替文字';
    yield put(startSubmit('projects'));
    const [data, error] = yield call(fetchPostJson, `projects`, action.payload);
    if (data && !error) {
      yield put(reset('projects'));
      yield put(push(`/co/project/${data.project_id}`));
    } else {
      yield put(stopSubmit('projects', makeFormErrorFormat(error)));
    }
  }
}

// プロジェクト編集
export function* patchProject() {
  while (true) {
    const action = yield take(requestProjectEdit);
    yield put(startSubmit('projects'));
    const [data, error] = yield call(
      fetchPatch,
      `projects/${action.payload.projectId}`,
      action.payload
    );
    if (data && !error) {
      yield put(reset('projects'));
      yield put(push(`/co/project/${action.payload.projectId}`));
    } else {
      yield put(stopSubmit('projects', makeFormErrorFormat(error)));
    }
  }
}

// プロジェクト削除
export function* deleteProject() {
  while (true) {
    const action = yield take(requestProjectDelete);
    yield put(startSubmit('projects'));
    const [data, error] = yield call(
      fetchDelete,
      `projects/${action.payload.id}`,
      action.payload
    );
    if (data && !error) {
      yield put(reset('projects'));
      yield put(push(`/co/project/`));
    } else {
      yield put(
        stopSubmit(
          'projects',
          Object.assign(error, {_error: error.non_field_errors})
        )
      );
    }
  }
}

const Project = [
  fork(getOwnProjectList),
  fork(getProjectDetail),
  fork(postProject),
  fork(patchProject),
  fork(deleteProject)
];

export default Project;
