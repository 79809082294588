import React from 'react';
import styles from './taskSearch.module.scss';
import {Field, FieldArray, reduxForm, reset} from 'redux-form';
import {renderSelect, renderText, renderTextArea} from '../../Field';
import CommonButton from '../../CommonButton';
import MenuItem from '@material-ui/core/MenuItem';
import Blank from '../../Blank';
import TagInput from '../../TagInput';
import {useDispatch} from 'react-redux';
import {requestPublicTaskList, requestTaskList} from '../../../actions/task';

const TaskSearchForm = ({
  pristine,
  submitting,
  handleSubmit,
  categoryList,
  searchOptions
}) => {
  const dispatch = useDispatch();

  return (
    <form onSubmit={handleSubmit}>
      <div className={styles.rootContainer}>
        <div className={styles.upperContainer}>
          <div className={styles.itemSet}>
            <p className={styles.name}>カテゴリ</p>
            <Field
              name="category"
              required={true}
              component={renderSelect}
              size={'small'}
            >
              {categoryList.map((item, index) => (
                <MenuItem value={item.main_category_id} key={index}>
                  {item.main_category}
                </MenuItem>
              ))}
            </Field>
          </div>
          <div className={styles.blankWidth}>{''}</div>
          <div className={styles.itemSet}>
            <p className={styles.name}>フリーワード</p>
            <Field
              name="freeword"
              required={true}
              component={renderText}
              fullWidth={true}
              placeholder={'例：決済機能'}
              size={'small'}
            />
          </div>
        </div>
        <Blank height={'0.8rem'} />
        <div className={styles.lowerContainer}>
          <div className={styles.itemSet}>
            <p className={styles.name}>開発環境</p>
            <FieldArray name="tags" component={TagInput} fullWidth={true} />
          </div>
          <div className={styles.buttons}>
            <CommonButton
              onClick={() => handleReset(dispatch, searchOptions)}
              text={'条件をクリア'}
              size={'minimum'}
              color={'blue'}
              variant={'outlined'}
              shape={'normal'}
              disabled={pristine || submitting}
            />
            <div className={styles.blankWidth} />
            <CommonButton
              type={'submit'}
              text={'検索'}
              size={'minimum'}
              color={'blue'}
              variant={'contained'}
              shape={'normal'}
              disabled={pristine || submitting}
            />
          </div>
        </div>
      </div>
    </form>
  );
};

const handleReset = (dispatch, searchOptions) => {
  dispatch(reset('taskSearch'));
  dispatch(requestPublicTaskList({page: 1, ...searchOptions}));
};

export default reduxForm({
  form: 'taskSearch'
})(TaskSearchForm);
