import React from 'react';
import styles from '../../commonModal.module.scss';
import {requestTaskEdit, setModalSetting} from '../../../../actions/task';
import {useDispatch, useSelector} from 'react-redux';
import CommonButton from '../../../CommonButton';
import IconYen from '../../../../assets/icon_yen.svg';
import {useHistory} from 'react-router';
import Blank from '../../../Blank';

const PrePaymentModal = ({params: {requiredPaymentTasks}}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <div className={styles.inner}>
      <div className={styles.header}>
        <img className={styles.image} src={IconYen} />
        <div className={styles.subTitle}>タスクの仮払い</div>
      </div>
      <div className={styles.message}>
        <p>下記のタスクは審査が完了しました。</p>
        <p>タスクをエンジニアに公開するためには仮払いが必要です</p>
      </div>
      {requiredPaymentTasks &&
        requiredPaymentTasks.map((item, index) => (
          <>
            <Blank height={'1rem'} />
            <Content
              task={item}
              key={index.toString()}
              dispatch={dispatch}
              history={history}
            />
          </>
        ))}
    </div>
  );
};

const Content = ({task, dispatch, history}) => {
  return (
    <div className={styles.row}>
      <p className={styles.rowTitle}>{task.title}</p>
      <CommonButton
        text={'仮払い画面へ移動'}
        size={'small'}
        color={'pink'}
        variant={'contained'}
        shape={'normal'}
        disabled={false}
        onClick={() => {
          history.push(`co/payment/cardlimit?taskId=${task.id}`);
          dispatch(
            setModalSetting({
              type: 'REQUIRED_PRE_PAYMENT',
              isVisible: false,
              params: {}
            })
          );
        }}
      />
    </div>
  );
};

export default PrePaymentModal;
