import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import classNames from 'classnames';
import {makeStyles} from '@material-ui/core/styles';

import styles from './selectTabs.module.scss';

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '2.4rem'
    }
  }
}));

const SelectTabs = ({
  tabLeft,
  tabRight,
  leftContent,
  rightContent,
  redDots,
  leftCallback = () => {},
  rightCallback = () => {}
}) => {
  const [value, setValue] = useState(0);
  const [isBatch, setIsBatch] = useState(false);
  const setting = useSelector(state => state.setting);
  const username = setting.me.username;
  const personalList = useSelector(state => state.personalNews.personalDocument);
  const systemList = useSelector(state => state.systemNews);
  const personalNotificationAlreadyRead = useSelector(
    state => state.personalNotification.personalNotificationAlreadyRead
  );
  const systemNotificationAlreadyRead = useSelector(
    state => state.personalNotification.systemNotificationAlreadyRead
  );

  const classes = useStyles();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = index => {
    setValue(index);
  };

  //↓バッチ(赤い点)の条件分け
  //①personalNotificationAlreadyReadがtrue →この時点で左側赤ポチなしが決定
  //②personalNotificationAlreadyReadがfalse → personalListが一つでもあるかで判断 1個以上 → / 左側赤ポチあり / 0個の時→左側赤ポチなし
  //③systemNotificationAlreadyReadがtrue → この時点で右側赤ポチなしが決定
  //④systemNotificationAlreadyReadがfalse →  systemListが一つでもあるかで判断 1個以上 →  /右側赤ポチあり / 0個の時→右側赤ポチなし

  return (
    <div>
      <div className={styles.parent}>
        {redDots ? (
          <>
            {personalNotificationAlreadyRead ? (
              <div />
            ) : personalList.length > 0 ? (
              <div
                className={classNames(styles.notificationBatch, styles.left)}
              />
            ) : (
              <div />
            )}

            {systemNotificationAlreadyRead ? (
              <div
                className={classNames(styles.notificationBatch, styles.right)}
              />
            ) : systemList.length > 0 ? (
              <div
                className={classNames(styles.notificationBatch, styles.right)}
              />
            ) : (
              <div
                className={classNames(styles.notificationBatch, styles.right)}
              />
            )}
          </>
        ) : (
          <div />
        )}
        {/* ↑バッチ(赤い点)つけるか否かだけの処理 */}
        {/* 赤ポチつけたいときはpropsのredDotsをtrueで受け取る(通知機能にて) */}
        {/* 報酬管理画面では赤ポチいらないのでredDotsをfalseで受け取る */}

        <Tabs
          value={value}
          variant={'fullWidth'}
          onChange={handleChange}
          indicatorColor="primary"
          className={styles.containerItem}
          TabIndicatorProps={{
            style: {
              backgroundColor: '#00A8F0'
            }
          }}
        >
          <Tab
            label={tabLeft}
            onClick={(() => setIsBatch(false), () => leftCallback(username))}
            classes={{root: classes.root}}
          />
          <Tab
            label={tabRight}
            onClick={(() => setIsBatch(true), () => rightCallback(username))}
            classes={{root: classes.root}}
          />
          {/* ↑changeSystemNotificationはNotificationのドキュメントたちを未読→既読にする関数
         タブがクリックされ切り替えられるとNotificationのドキュメントたちをユーザーが見るのでここで実行 */}
        </Tabs>
      </div>
      <div>
        <SwipeableViews index={value} onChangeIndex={handleChangeIndex}>
          <div>{leftContent}</div>
          <div>{rightContent}</div>
        </SwipeableViews>
      </div>
    </div>
  );
};

export default SelectTabs;
