import React from 'react';
import styles from './formError.module.scss';

const FormError = ({error}) => {
  if (error) {
    return (
      <div className={styles.errorContainer}>
        <p className={styles.error}>{error}</p>
      </div>
    );
  } else {
    return <></>;
  }
};

export default FormError;
