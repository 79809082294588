import {
  IS_SHOW_ENTRY_SNACKBAR,
  IS_SHOW_BOOKMARK_SNACKBAR,
  SET_SNACKBAR_TYPE,
  ENQUEUE_SNACKBAR,
  REMOVE_SNACKBAR,
  SET_LATEST_NEWS_ID
} from '../actions/snackbar';

const initialState = {
  EntrySnackbar: {isOpen: false, text: ''},
  BookmarkSnackbar: {isOpen: false, text: ''},
  placement: {vertical: 'top', horizontal: 'right'},
  notifications: [],
  latestNewsId: null
};

const snackbar = (state = initialState, action) => {
  switch (action.type) {
    case IS_SHOW_ENTRY_SNACKBAR:
      return {
        ...state,
        EntrySnackbar: action.payload
      };
    case IS_SHOW_BOOKMARK_SNACKBAR:
      return {
        ...state,
        BookmarkSnackbar: action.payload
      };

    case SET_SNACKBAR_TYPE:
      return {
        ...state,
        placement: action.payload
      };
    case ENQUEUE_SNACKBAR:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            key: action.key,
            ...action.notification
          }
        ]
      };
    case REMOVE_SNACKBAR:
      return {
        ...state,
        notifications: state.notifications.filter(
          notification => notification.key !== action.key
        )
      };
    case SET_LATEST_NEWS_ID:
      return {
        ...state,
        latestNewsId: action.payload
      };

    default:
      return state;
  }
};

export default snackbar;
