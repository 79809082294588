import React from 'react';
import style from './importantNotice.module.scss';
import IconCaution from '../../assets/icon_caution.svg';
import Link from '@material-ui/core/Link';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles({
  underline: {
    color: '#D10040'
  }
});

const ImportantNotice = ({importantNotifications}) => {
  const classes = useStyles();
  return (
    <div>
      <div className={style.titleContainer}>
        <img src={IconCaution} />
        <p className={style.title}>重要なお知らせ</p>
      </div>
      <div className={style.noticeContainer}>
        {importantNotifications.map((item, index) => {
          if ('link' in item) {
            return (
              <Link key={index.toString()} to={item.link}>
                <p key={index} className={style.notice}>
                  {'・ ' + item.notice}
                </p>
              </Link>
            );
          } else if (item.onClick) {
            return (
              <Link
                classes={{underlineHover: classes.underline}}
                className={style.linkButton}
                component="button"
                variant="body2"
                onClick={() => {
                  item.onClick();
                }}
              >
                <p key={index} className={style.notice}>
                  {'・ ' + item.notice}
                </p>
              </Link>
            );
          } else {
            return (
              <p key={index} className={style.notice}>
                {'・ ' + item.notice}
              </p>
            );
          }
        })}
      </div>
    </div>
  );
};

export default ImportantNotice;
