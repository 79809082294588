import React from 'react';

import styles from './deadline.module.scss';
import FormatDate from '../FormatDate';

const Deadline = ({deadline}) => {
  return (
    <>
      {deadline ? (
        <div className={styles.container}>
          <div className={styles.flex}>
            <div className={styles.bold}>
              {FormatDate(deadline, 'justMonth')}
            </div>
            <div className={styles.text}>月</div>
          </div>
          <div className={styles.flex}>
            <div className={styles.bold}>{FormatDate(deadline, 'justDay')}</div>
            <div className={styles.text}>日</div>
          </div>
        </div>
      ) : (
        <div className={styles.container}>
          <div className={styles.flex}>
            <div className={styles.nonDeadline}>期限未設定</div>
          </div>
        </div>
      )}
    </>
  );
};

export default Deadline;
