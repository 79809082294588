import React, {useEffect} from 'react';

import styles from './content.module.scss';

const Content = ({header, children}) => {
  return (
    <>
      <div className={styles.header}>
        <span className={styles.questionMark}>Q. </span>
        {header}
      </div>
      <div className={styles.answer}>
        <span className={styles.answerMark}>A.</span>
        {children}
      </div>
    </>
  );
};
export default Content;
