import {call, put, take, fork} from 'redux-saga/effects';
import {fetchPostJson} from './utills/fetch';
import {PATCH_COMPLETE} from '../actions/complete';
import {requestTaskDetail} from '../actions/task';

// 企業側taskのstatusを5→7にpatch
export function* completepatch() {
  while (true) {
    const action = yield take(PATCH_COMPLETE);
    const [data, error] = yield call(fetchPostJson, `gmo/sales`, {
      task_id: action.payload.task
    });
    if (data && !error) {
      yield put(requestTaskDetail({id: action.payload.task}));
    } else {
      console.log('error!');
    }
  }
}

const complete = [fork(completepatch)];

export default complete;
