import React from 'react';
import {createMuiTheme, makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {MuiThemeProvider} from '@material-ui/core';
import classNames from 'classnames';
import style from './button.module.scss';
import PinWhite from '../../../../assets/icon_pin_white.svg';
import PinBlue from '../../../../assets/icon_pin_blue.svg';
import Clear from '../../../../assets/icon_clear.svg';

export const DisableButton = props => {
  const {kind} = props;
  let applyBgColor = '';
  let applyHoverColor = '';
  let applyBorderColor = 'none';
  let valiant = 'contained';
  let applyColor = '#FFFFFF';
  let applyFontWeight = '';
  let text = '';
  let srcImg = null;
  let applyFontSize = '1.9rem';
  let disabled = false;

  switch (kind) {
    case 'examination':
      applyColor = '#C0C0C0';
      applyBgColor = '#1b4a71';
      applyHoverColor = '#1b4a71';
      applyFontWeight = 'bold';
      text = '審査中';
      break;

    case 'engineerModify':
      applyColor = '#C0C0C0';
      applyBgColor = '#1b4a71';
      applyHoverColor = '#1b4a71';
      applyFontWeight = 'bold';
      text = '修正中';
      break;

    case 'engineerWorking':
      applyColor = '#C0C0C0';
      applyBgColor = '#1b4a71';
      applyHoverColor = '#1b4a71';
      applyFontWeight = 'bold';
      text = 'エンジニアが作業中';
      applyFontSize = '1.6rem';
      break;
  }
  const useStyles = makeStyles(theme => ({
    button: {
      margin: theme.spacing(1),
      backgroundColor: applyBgColor,
      border: applyBorderColor,
      color: applyColor,
      fontWeight: applyFontWeight,
      width: '20rem',
      height: '5rem',
      fontSize: applyFontSize,
      '&:hover': {
        backgroundColor: applyHoverColor
      },
      cursor: 'auto'
    }
  }));

  const classes = useStyles();
  return (
    <div className="stage">
      <Button
        variant={valiant}
        className={classes.button}
        disabled={disabled}
        disableRipple={true}
      >
        <div className={style.buttonContainer}>
          <img src={srcImg} />
          <div className={style.text}>{text}</div>
        </div>
      </Button>
    </div>
  );
};
