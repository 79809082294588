import React from 'react';
import Link from '@material-ui/core/Link';

const PATTERN = /(https?:\/\/[\x21-\x7e]+)/g;

const UrlIncludeArea = ({text, className}) => {
  return <p className={className}>{makeComponent(text)}</p>;
};

export default UrlIncludeArea;

const makeComponent = text => {
  const splitedList = text.split(PATTERN);
  let componentList = [];
  if (splitedList) {
    splitedList.forEach((item, index) => {
      if (item.match(PATTERN)) {
        componentList.push(
          <Link
            href={item}
            target={'_blank'}
            rel={'noopener noreferrer'}
            key={index.toString()}
          >
            {item}
          </Link>
        );
      } else {
        componentList.push(item);
      }
    });
  } else {
    componentList = [{text}];
  }
  return componentList;
};
