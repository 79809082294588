import React, {FC, useEffect, useState} from 'react';
import MainTitle from '../../../components/MainTitle';
import {
  requestPreAssignedTasks,
  requestWantedTasks
} from '../../../actions/task';
import {requestSearchEngineers} from '../../../actions/user';
import {useDispatch, useSelector} from 'react-redux';
import OfferModal from '../../../components/modals/OfferModal';
import EngineerCard from '../../../components/EngineerCard';
import EngineerSearchButtons from '../../../components/EngineerCard/EngineerSearchButtons';
import EngineerSearchForm from '../../../components/Form/EngineerSearchForm';
// models
import {RootState} from '../../../models/RootModels';
import {EngineerItemState} from '../../../models/UserModels';
import style from '../../../components/Entry/entry.module.scss';
import Paginate from '../../../components/Paginate';
import {requestOwnProjectList} from '../../../actions/project';

const EngineerSearch: FC = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [engineerItem, setEngineerItem] = useState<EngineerItemState | null>(
    null
  );
  const {
    searchedEngineersResponse: {results, count}
  } = useSelector((state: RootState) => state.user);
  const companyId = useSelector((state: RootState) => state.setting.companyId);

  useEffect(() => {
    dispatch(requestWantedTasks());
    dispatch(requestPreAssignedTasks({openModal: false}));
    dispatch(requestSearchEngineers());
  }, []);

  useEffect(() => {
    if (companyId !== null) {
      dispatch(requestOwnProjectList());
    }
  }, [companyId]);

  return (
    <div>
      <OfferModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        engineerItem={engineerItem}
      />
      <MainTitle titleStr={'エンジニア検索'} pattern={false} />
      <EngineerSearchForm
        onSubmit={(values: object) => dispatch(requestSearchEngineers(values))}
      />
      <ul className={style.list}>
        {results.map((item, index) => {
          return (
            <li key={index}>
              <EngineerCard item={item}>
                <EngineerSearchButtons
                  item={item}
                  onClick={(engineerItem: EngineerItemState) => {
                    setIsOpen(true);
                    setEngineerItem(engineerItem);
                  }}
                />
              </EngineerCard>
            </li>
          );
        })}
      </ul>
      <Paginate
        // @ts-ignore
        limit={10}
        total={count}
        method={(page: number) =>
          dispatch(requestSearchEngineers({page: page}))
        }
      />
    </div>
  );
};

export default EngineerSearch;
