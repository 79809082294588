import {SUCCESS_LATEST_TASK_LIST} from '../actions/taskList';

const initialState = [{}];

const taskList = (state = initialState, action) => {
  switch (action.type) {
    case SUCCESS_LATEST_TASK_LIST:
      return action.payload.map(item => {
        return {
          id: item.id,
          status: item.status,
          status_info: item.status_info,
          updated_at: item.updated_at,
          title: item.title,
          project: item.project,
          project_title: item.project_title,
          tags: item.tags,
          tags_info: item.tags_info,
          delivery_date: item.delivery_date,
          closing_date: item.closing_date,
          reward: item.reward,
          private_comments: item.private_comments,
          company: ' '
          //latestTaskは会社情報で使われ、会社名は全て自明なので空欄。
        };
      });

    default:
      return state;
  }
};

export default taskList;
