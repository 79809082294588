import React from 'react';
import {connect} from 'react-redux';
import {withRouter, NavLink} from 'react-router-dom';
import {reduxForm, getFormSubmitErrors} from 'redux-form';
import {history} from '../../store';
import PanelLayout from '../layout/PanelLayout';
import SigninForm from '../../components/Form/SigninForm';
import {requestSignIn, requestGithubSignIn} from '../../actions/sign';
import style from './signin.module.scss';
import {API_KEY, SCOPE} from '../../config/index';
import Button from '@material-ui/core/Button';
import ImgGithub from '@/assets/img_github.svg';
import EngineerTitleBase from '@/assets/singin_engineer_title.svg';
import CompanyTitleBase from '@/assets/singin_company_title.svg';
import {GITHUB_CLIENT_ID} from '../../config';
import Link from '@material-ui/core/Link';
import CommonModal from '../../components/Modal/CommonModal';

class Index extends React.Component {
  componentDidMount() {
    const oauthScript = document.createElement('script');
    oauthScript.src =
      'https://cdn.rawgit.com/oauth-io/oauth-js/c5af4519/dist/oauth.js';
    document.body.appendChild(oauthScript);

    const code = history.location.search.split('=')[1];
    if (code) {
      this.props.requestGithubSignIn({
        code: code
      });
    } else {
      localStorage.clear();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.isEngineer !== prevProps.isEngineer) {
      history.push('/');
    }
  }

  render() {
    const authorizationEndpoint = `https://github.com/login/oauth/authorize?client_id=${GITHUB_CLIENT_ID}&scope=read:user user:email`;
    return (
      <PanelLayout>
        <CommonModal />
        <article className={style.table}>
          <section className={style.company}>
            <h3>
              <i className={style.iconCompany} />
              企業
              <img src={CompanyTitleBase} className={style.companyTitleBase} />
            </h3>
            <div className={style.buttons}>
              <NavLink exact to="/co-signup">
                <i className={style.arrow} />
                新規会員登録はこちら
              </NavLink>
            </div>
            <div className={style.paper}>
              <SigninForm {...this.props} />
              <NavLink exact to="/password-reset-mail" className={style.link}>
                パスワードをお忘れですか？
              </NavLink>
            </div>
          </section>
          <section className={style.engineer}>
            <h3>
              <i className={style.iconEngineer} />
              エンジニア
              <img
                src={EngineerTitleBase}
                className={style.engineerTitleBase}
              />
            </h3>
            <div className={style.paper}>
              <div className={style.githubInner}>
                <div className={style.githubDescription}>
                  会員登録すると
                  <br />
                  お仕事の情報が受け取れます！
                </div>
                <div>
                  <img src={ImgGithub} />
                </div>
                <Button
                  variant="contained"
                  color="primary"
                  className={style.githubButton}
                  // onClick={this.handleClick.bind(this)}

                  //↓認可エンドポイントに認可リクエストを送る。→ 認可code取得できる。
                  href={authorizationEndpoint}
                >
                  GitHub ログイン
                </Button>
              </div>
            </div>

            <div className={style.buttons}>
              <a
                href={'https://github.com/'}
                target={'_blank'}
                rel={'noopener noreferrer'}
              >
                <i className={style.arrow} />
                GitHubのアカウント登録はこちら
              </a>
            </div>
          </section>
        </article>
      </PanelLayout>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  onSubmit(value) {
    dispatch(requestSignIn(value));
  },
  requestGithubSignIn(value) {
    dispatch(requestGithubSignIn(value));
  }
});

Index = reduxForm({
  form: 'signin'
})(Index);

const mapStateToProps = state => {
  return {
    submitErrors: getFormSubmitErrors('signin')(state),
    accessToken: state.sign.accessToken,
    isEngineer: state.setting.isEngineer,
    isOpenTwoFactorAuthInput: state.sign.isOpenTwoFactorAuthInput
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Index));
