import React from 'react';
import style from './starRating.module.scss';
import YellowStar from '../../assets/icon_yellow_star.svg';
import GreyStar from '../../assets/icon_grey_star.svg';

const Index = ({evalValue}) => {
  return (
    <div className={style.starRating}>
      <div className={style.starRatingFront} style={{width: `${evalValue}%`}}>
        <img src={YellowStar} />
      </div>
      <div>
        <img src={GreyStar} />
      </div>
    </div>
  );
};

export default Index;
