import styles from './noDataContainer.module.scss';
import React from 'react';

const NoDataContainer = ({children}) => {
  return (
    <div className={styles.rootContainer}>
      <div className={styles.Container}>{children}</div>
    </div>
  );
};

export default NoDataContainer;
