import React, {useEffect, useState} from 'react';
import {Field, reduxForm} from 'redux-form';
import MenuItem from '@material-ui/core/MenuItem/index';
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles/index';
import Button from '@material-ui/core/Button/index';
import {makeStyles} from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import {subTitle} from '../../../HelpButtons/constants';

import SubTitle from '../../../SubTitle';
import Item from './Item';
import {renderText, renderTextArea, renderSelect} from '../../../Field';
import style from '../form.module.scss';
import projectFormStyle from './projectForm.module.scss';
import * as Validator from '../../utils/validate';
import FormError from '../../FormError';
import Blank from '../../../Blank';

const theme = createMuiTheme({
  overrides: {
    MuiSelect: {
      root: {
        width: '40rem'
      },
      icon: {
        top: '1.3rem'
      }
    },
    MuiButton: {
      containedPrimary: {
        backgroundColor: '#093791'
      },
      outlinedPrimary: {
        color: '#093791',
        border: '0.1rem solid #093791'
      },
      contained: {
        boxShadow: 'none'
      },
      root: {
        fontSize: '1.4rem',
        fontStyle: 'normal',
        fontWeight: 'bold',
        lineHeight: '2.6rem',
        textAlign: 'center',
        color: '#FFFFFF'
      }
    }
  }
});

const useStyles = makeStyles({
  input: {
    width: '31rem',
    height: '5rem'
  },
  button: {
    width: '30rem',
    height: '5rem',
    '&:hover': {
      background: '#002163'
    }
  },
  title: {
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  }
});

const ProjectForm = props => {
  const {
    error,
    handleSubmit,
    submitErrors,
    pristine,
    submitting,
    formValues,
    categoryList,
    subCategoryList,
    submitText
  } = props;

  const classesStyle = useStyles();

  if (categoryList.length === 0) {
    return <></>;
  }

  return (
    <MuiThemeProvider theme={theme}>
      <form onSubmit={handleSubmit}>
        <main className={style.container}>
          <SubTitle headerText={'カテゴリー'} helpText={subTitle.pjCategory} />
          <Item text={'メインカテゴリー'} required={true}>
            <Field
              className={classesStyle.input}
              name="main_category_id"
              required={true}
              component={renderSelect}
              validate={[Validator.RequiredChoice]}
            >
              <MenuItem value="0">選択してください</MenuItem>
              {categoryList.map((elem, index) => (
                <MenuItem value={elem.main_category_id} key={index}>
                  {elem.main_category}
                </MenuItem>
              ))}
            </Field>
          </Item>
          <div className={style.sectionConatainer}>
            <SubTitle
              headerText={'プロジェクト詳細'}
              helpText={subTitle.pjDetail}
            />
            <Item text={'タイトル'} required={true}>
              <Field
                name="title"
                required={true}
                component={renderText}
                fullWidth={false}
                placeholder={'例：ECサイトの構築'}
                validate={[Validator.RequiredItem]}
                className={classesStyle.title}
              />
            </Item>
            <Item text={'概要'} required={false}>
              <Field
                name="description"
                required={true}
                component={renderTextArea}
                rows={10}
              />
            </Item>
          </div>
        </main>
        <Blank height={'2.4rem'} />
        <FormError error={error} />
        <div className={projectFormStyle.submits}>
          <div className={projectFormStyle.submit}>
            <Button
              className={classesStyle.button}
              type="submit"
              disabled={pristine || submitting}
              variant="contained"
              color="primary"
            >
              {submitText}
            </Button>
          </div>
        </div>
      </form>
    </MuiThemeProvider>
  );
};

export default reduxForm({
  enableReinitialize: true,
  form: 'projects'
})(ProjectForm);
