import React from 'react';
import {Route, Switch} from 'react-router-dom';
import Terms from '../../components/Terms';
import TermsOfServiceCo from '../../components/MainTermsOfService/co';
import TermsOfServiceEn from '../../components/MainTermsOfService/en';

const TermsRouter = () => {
  return (
    <Switch>
      <Route
        exact
        path="/terms/co"
        render={() => (
          <Terms>
            <TermsOfServiceCo />
          </Terms>
        )}
      />
      <Route
        exact
        path="/terms/en"
        render={() => (
          <Terms>
            <TermsOfServiceEn />
          </Terms>
        )}
      />
    </Switch>
  );
};

export default TermsRouter;
