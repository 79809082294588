import React from 'react';
import styles from '../mainTermsOfService.module.scss';

const TermsOfServiceCo = () => {
  return (
    <main className={styles.main}>
      <div className={styles.inner}>
        <h1 className={styles.title}>利用規約</h1>
        <p className={styles.text}>
          利用規約（以下「本規約」といいます。）には、株式会社m-Lab（以下「当社」といいます。）の提供する本サービスのご利用にあたり、利用希望者及び企業ユーザーの皆様に遵守していただかなければならない事項及び当社と利用希望者及び企業ユーザーの皆様との間の権利義務関係が定められております。本サービスをご利用になる方は、本規約に同意する前に、必ず全文をお読み下さいますようお願い致します。
        </p>
        <h3 className={styles.header}>第1条 適用</h3>
        <p className={styles.text}>
          1.本規約は、本サービスの利用に関する当社と利用希望者及び企業ユーザーとの間の権利義務関係を定めることを目的とし、利用希望者及び企業ユーザーと当社の間の本サービスの利用に関わる一切の関係に適用されます。
        </p>
        <p className={styles.text}>
          2.当社が当社ウェブサイト上で随時掲載する本サービスに関するルール、諸規定等は本規約の一部を構成するものとします。
        </p>
        <h3 className={styles.header}>第2条 定義</h3>
        <p className={styles.text}>
          本規約において使用する以下の用語は、各々に定める意味を有するものとします。
        </p>
        <p className={styles.text}>
          (1)本サービスとは、当社が提供する「Task」という名称のサービス（理由の如何を問わずサービスの名称又は内容が変更された場合は、当該変更後のサービスを含みます。）で、業務委託に関する情報提供及び業務委託を遂行するための機能を提供することにより事業者間の直接取引を支援するサービスを意味します。
        </p>
        <p className={styles.text}>
          (2)当社ウェブサイトとは、そのドメインが「task.site」である当社が運営するウェブサイト（理由の如何を問わず当社のウェブサイトのドメイン又は内容が変更された場合は、当該変更後のウェブサイトを含みます。）及び付随するウェブサイトを意味します。
        </p>
        <p className={styles.text}>
          (3)利用希望者とは、第3条において定義された「利用希望者」を意味します。
        </p>
        <p className={styles.text}>
          (4)登録情報とは、第3条において定義された「登録情報」を意味します。
        </p>
        <p className={styles.text}>
          (5)企業ユーザーとは、第3条に基づき本サービスの利用登録がなされた個人、法人又は組織を意味します。
        </p>
        <p className={styles.text}>
          (6)企業メンバーとは、登録情報における管理者とは別に、企業ユーザー又は管理者が本サービスを使用することを認めた管理者と同一の会社に所属する者を意味します。
        </p>
        <p className={styles.text}>
          (7)メンバーとは、本サービスを通してメンバーに対し業務を受託し、又は受託しようとする個人、法人又は組織を意味します。
        </p>
        <p className={styles.text}>
          (8)プロジェクトとは、企業ユーザーが設定した目的についての総称を意味します。
        </p>
        <p className={styles.text}>
          (9)タスクとはプロジェクトを遂行するための、1つ1つの作業であり、企業ユーザーがメンバーに依頼するために本サービスに掲載される業務を意味します。
        </p>
        <p className={styles.text}>
          (10)エントリーとは、メンバーが本サービスに掲載されるタスクに対して応募することを意味します。
        </p>
        <p className={styles.text}>
          (11)Paid（ペイド）とは、株式会社ラクーンホールディングスが提供する、請求等を代行するサービスの総称を意味します。
        </p>
        <p className={styles.text}>
          (12)知的財産権とは、著作権、特許権、実用新案権、商標権、意匠権その他の知的財産権（それらの権利を取得し、又はそれらの権利につき登録等を出願する権利を含みます。）を意味します。
        </p>
        <h3 className={styles.header}>第3条 登録</h3>
        <p className={styles.text}>
          1.本サービスの利用を希望する者（以下「利用希望者」といいます。）は、本規約を遵守することに同意し、かつ当社の定める一定の情報（以下「登録情報」といいます。）を当社の定める方法で当社に提供することにより、当社に対し、本サービスの利用の新規会員登録を申請することができます。
        </p>
        <p className={styles.text}>
          2.利用希望者は、登録の申請にあたり、真実、正確かつ最新の情報を当社に提供しなければなりません。
        </p>
        <p className={styles.text}>
          3.18歳未満の方は本サービスをご利用いただけません。また、18歳以上の未成年である場合は、メンバーとなること及び本規約に従って本サイトを利用することを、登録申請する前に親権者の同意を得なければなりません。
        </p>
        <p className={styles.text}>
          4.当社は、第1項に基づき申込を行った利用希望者が、以下の各号のいずれかの事由に該当する場合は、登録を拒否することがあります。
        </p>
        <p className={styles.text}>
          (1)本規約に違反するおそれがあると当社が判断した場合
        </p>
        <p className={styles.text}>
          (2)当社に提供された登録情報の全部又は一部につき虚偽、誤記又は記載漏れがあった場合
        </p>
        <p className={styles.text}>
          (3)過去に本サービスの利用の登録を取り消された者である場合
        </p>
        <p className={styles.text}>
          (4)反社会的勢力等（暴力団、暴力団員、右翼団体、反社会的勢力、その他これに準ずる者を意味します。以下同じ。）である、又は資金提供その他を通じて反社会的勢力等の維持、運営若しくは経営に協力若しくは関与する等反社会的勢力等との何らかの交流若しくは関与を行っていると当社が判断した場合
        </p>
        <p className={styles.text}>
          (5)その他、当社が登録を適当でないと判断した場合
        </p>
        <p className={styles.text}>
          5.利用希望者の新規会員登録の申請手続きにおいてアカウントの作成が完了した時点で、利用希望者を企業ユーザーとみなします。また、アカウントの作成が完了した時点において、サービス利用契約（以下「利用契約」といいます。）が企業ユーザーと当社の間に成立します。
        </p>
        <p className={styles.text}>
          6.企業ユーザーが登録申請した日を利用開始日とします。
        </p>
        <p className={styles.text}>
          7.企業ユーザーは、利用開始後も、決済方法など本サービスを受けるための情報を、その必要に応じて随時提供する必要があります。
        </p>
        <p className={styles.text}>
          8.企業ユーザーは、登録情報に変更があった場合は、遅滞なく、当社の定める方法により、当該変更事項を当社に通知し、当社から要求された情報及び資料を提出するものとします。
        </p>
        <h3 className={styles.header}>第4条 契約期間</h3>
        <p className={styles.text}>
          企業ユーザーに対する本サービスの利用期限は、本サービスの終了日又はユーザーに対する本契約の解約若しくは解除の日までとします。
        </p>
        <h3 className={styles.header}>第5条 サービスの利用</h3>
        <p className={styles.text}>
          1.企業ユーザーは、利用契約の有効期間中、本規約に従って、当社の定める方法に従い、本サービスを利用することができます。
        </p>
        <p className={styles.text}>
          2.メンバーと求人企業間の契約について、当社は当該契約の当事者とならず、当該契約について一切責任を負わないものとします。
        </p>
        <h3 className={styles.header}>第6条 企業メンバーの管理</h3>
        <p className={styles.text}>
          1.企業ユーザーの管理者は、企業メンバー全てに、本規約を遵守させる義務を負います。企業ユーザーの管理者は、メンバーが本サービスを利用する前に、メンバーに本規約及びプライバシーポリシーの内容について伝えるとともに、メンバーが本規約に同意し、遵守することの誓約を得なければなりません。
        </p>
        <p className={styles.text}>
          2.企業メンバーが本サービスを利用して行った行為は、全て企業ユーザーが行ったものとみなします。企業ユーザーは、管理者を含む全ての企業メンバーが本サービスを利用することについて、全ての責任を負うものとします。企業メンバーの行為が原因で生じたクレームなどにより、当社に損害（弁護士費用その他の費用負担や賠償金の支払いを含みます）が発生した場合、企業ユーザーは当社の損害を補償するものとします。
        </p>
        <p className={styles.text}>
          3.企業ユーザーは、本サービスの企業メンバー情報に含まれる個人情報の利用及び管理について、すべて企業ユーザーの責任により取扱うものとします。当社はかかる個人情報の利用及び管理について責任を負いません。
        </p>
        <h3 className={styles.header}>第7条 アカウントの管理</h3>
        <p className={styles.text}>
          1.企業ユーザーは、本サービスで使用されるログイン用のメールアドレスとそれに紐づくパスワード（管理用アカウントのみではなく、企業メンバーのものも含みます。以下「アカウント」といいます。）を企業ユーザーの責任で管理及び保管するものとします。
        </p>
        <p className={styles.text}>
          2.アカウントの管理不十分、使用上の過誤、第三者の使用等による損害の責任は企業ユーザーが負うものとし、当社は一切の責任を負いません。
        </p>
        <p className={styles.text}>
          3.企業ユーザーは、アカウントが盗まれ、又は第三者に使用されていることが判明した場合には、直ちにその旨を当社に通知するとともに、当社からの指示に従うものとします。
        </p>
        <h3 className={styles.header}>
          第8条 企業ユーザー情報、個人情報の取扱い
        </h3>
        <p className={styles.text}>
          1.当社は原則として、本サービスで開示される企業ユーザー情報以外の企業ユーザー情報について、本利用規約に別段の定めがある場合を除き、事前の同意なく第三者に対し開示することはありません。ただし、公的機関からの照会及び当社が法令によって開示義務を負う場合などはこの限りではありません。
        </p>
        <p className={styles.text}>
          2.当社は、本サービスの向上、請求及び当社のマーケティングなどの目的で企業ユーザー情報の集計及び分析などをすることができるものとします。
        </p>
        <p className={styles.text}>
          3.当社は、当社のプライバシーポリシーに従い、企業ユーザー情報及びメンバー情報を管理します。
        </p>
        <p className={styles.text}>
          4.企業ユーザーは、本サービスで取得した個人情報について、本サービスのためだけに使用するものとし、個人情報保護法の定めに従い個人情報を管理するものとします。また、必要に応じて適切に本人の同意を得るものとします。
        </p>
        <p className={styles.text}>
          5.企業ユーザーが本サービスの利用を終了した場合、当社は当該企業ユーザー及び企業メンバーのアカウントを削除します。ただし、企業ユーザーの情報のうち、第2項のマーケティングなどの目的で使用する、個人及び法人を特定できない情報については、利用の終了後もなお当社が保有し、使用し続けるものとし、企業ユーザーはこれを予め承諾するものとします。
        </p>
        <p className={styles.text}>
          6.本条の規定は、本サービス終了後も有効に存続するものとします。
        </p>
        <h3 className={styles.header}>第9条 外部委託</h3>
        <p className={styles.text}>
          当社は、本サービスの運営について、必要に応じて当社の業務の全部又は一部を外部委託することができるものとします。
        </p>
        <h3 className={styles.header}>第10条 タスクの申請</h3>
        <p className={styles.text}>
          1.企業ユーザーは、プロジェクトを作成することができ、そのプロジェクトごとにタスクを作成することができます。タスクにはタイトル、内容、納品形式などの仕様のほか、報酬金額と支払い方法を設定して、当社に対して審査に出す必要があります。
        </p>
        <p className={styles.text}>
          2.当社は企業ユーザーのタスクについて審査を行い、当社の設定する所定の基準満たすものについて、本サービスのメンバーが閲覧できる状態にします。
        </p>
        <p className={styles.text}>
          3.前項において、審査の結果、当社が掲載を認めなかったタスクについて、当社はその府掲載の理由及び審査基準について一切お伝えしないものとし、それらの質問に対して返答する義務もないものとします。
        </p>
        <h3 className={styles.header}>第11条 契約の成立</h3>

        <p className={styles.text}>
          1.前条において掲載されたタスクについて、企業ユーザーは、メンバーからエントリーを受けることができます。その後メンバーと企業ユーザーとの間で交わされる契約によって、業務委託契約が締結されるものとします。
        </p>
        <p className={styles.text}>
          2.前項の契約締結に際して、当社はその合意の存否及び内容について関知せず、その結果生じた損害について一切の責任を負わないものとします。
        </p>
        <h3 className={styles.header}>第12条 タスクの遂行</h3>

        <p className={styles.text}>
          1.メンバーは、企業ユーザーとの契約に従った成果物の引渡し義務を負います。
        </p>
        <p className={styles.text}>
          2.企業ユーザーは、本サービスにおいて委託したタスクについて、法律上の規定その他業務委託に関する法律を遵守しなければなりません。また、委託したタスクが、下請代金支払遅延等防止法の対象となる場合、企業ユーザーは同法を遵守しなければなりません。
        </p>
        <p className={styles.text}>
          3.企業ユーザーがメンバーから成果物の納品とともに作業終了又は修正完了の報告を受けた場合、企業ユーザーは納品された成果物を検収し、メンバーに対して検収結果（合格または不合格）を通知する義務を負うものとします。
        </p>
        <p className={styles.text}>
          4.前項において、検収の結果、メンバーによる修正が必要な場合は、企業ユーザーはメンバーに対し、本サービスを通じてその修正の依頼を行わなければいけません。
        </p>
        <p className={styles.text}>
          5.メンバーによる成果物の納品後、30日以内に、企業ユーザーが検収結果を合理的な理由なく報告しない場合、当該成果物の検収の結果は、企業ユーザーによって合格とされたものとみなします。
        </p>
        <p className={styles.text}>
          6.企業ユーザーは、タスクの納品物についての確認が完了して案件が終了したときは、メンバーに対して評価を行わなくてはいけません。メンバーと企業ユーザーの相互の評価に伴い、タスクは完了となります。
        </p>
        <p className={styles.text}>
          7.企業ユーザーとメンバー間で第11条の契約が締結したタスクについて、契約締結後かつタスク完了前に企業ユーザーの都合により契約締結したタスクの依頼をキャンセルした場合、企業ユーザーは当該タスクの進捗に応じた報酬を支払わなければならあにものとします。
        </p>
        <h3 className={styles.header}>第13条 利用料金及び支払方法</h3>
        <p className={styles.text}>
          1.企業ユーザーは、タスクが完了するごとに、タスクの報酬金額に当該報酬金額の１０％のシステム利用料金を加算した金額（以下、「利用料金」といいます。）を、タスクの設定時に選択した支払方法で支払うものとします。支払方法については、クレジットカード決済又はPaid所定の請求書払いのいずれかにより支払うものとし、支払手数料その他の支払いに必要な手数料は乙が負担するものとします。
        </p>
        <p className={styles.text}>
          2.クレジットカードによるお支払い及びPaid
          を通じた請求書払いについて、当社は決済に係る取扱いを決済代行事業者に委託しているため、企業ユーザーの支払情報または決済に関する情報を保有しておらず、かかる情報の取扱いまたは当社に起因しない決済に関する不具合等について一切の責任を負いません。
        </p>
        <p className={styles.text}>
          3.当社は企業ユーザーに対する売掛金請求権をPaidその他の第三者に譲渡できるものとし、企業ユーザーはこれをあらかじめ了承するものとします。
        </p>
        <h3 className={styles.header}>第14条 禁止行為等</h3>

        <p className={styles.text}>
          1.企業ユーザーは、本サービスの利用にあたり、以下の各号のいずれかに該当する行為をしてはなりません。
        </p>
        <p className={styles.text}>(1)本規約の規定に違反する行為</p>
        <p className={styles.text}>(2)メンバーと個人情報をやり取りする行為</p>
        <p className={styles.text}>
          (3)本サービスを通じて知り合ったメンバーに対し、本サービス外で業務を直接委託する行為、又は直接委託することを目的として誘導する行為
        </p>
        <p className={styles.text}>
          (4)当社、他の企業ユーザーその他の第三者の知的財産権、肖像権、プライバシーの権利、名誉、著作権、その他の権利又は利益を侵害する行為（かかる侵害を直接又は間接に惹起する行為を含みます。）
        </p>
        <p className={styles.text}>
          (5)企業ユーザーが、社外の第三者に本サービスを使用させる行為
        </p>
        <p className={styles.text}>(6)当社又は第三者を誹謗中傷する行為</p>
        <p className={styles.text}>
          (7)犯罪行為に関連する行為又は公序良俗に反する行為
        </p>
        <p className={styles.text}>
          (8)猥褻な情報又は青少年に有害な情報を送信する行為
        </p>
        <p className={styles.text}>
          (9)法令又は企業ユーザー若しくは企業ユーザーが所属する業界団体の内部規則に違反する行為
        </p>
        <p className={styles.text}>
          (10)コンピューター・ウィルスその他の有害なコンピューター・プログラムを含む情報を送信する行為
        </p>
        <p className={styles.text}>
          (11)本サービスに関し利用しうる情報を改ざんする行為
        </p>
        <p className={styles.text}>
          (12)当社が定める一定のデータ容量以上のデータを、本サービスを通じて送信する行為
        </p>
        <p className={styles.text}>
          (13)当社による本サービスの運営を妨害するおそれのある行為
        </p>
        <p className={styles.text}>(14)その他当社が不適切と判断する行為</p>
        <p className={styles.text}>
          2.企業ユーザー又は企業ユーザーが発行したログインIDを利用した者による情報の送信行為が前項第1号から第13号までの各号のいずれかに該当し、又は該当するおそれがあると当社が判断した場合には、企業ユーザーに通知し改善を依頼します。改善依頼後も企業ユーザーに改善が見られない場合、又は企業ユーザーの改善を待つ時間的猶予がない場合、当社は、企業ユーザーによる本サービス上の情報の全部又は一部を削除し、またアカウントを使用できなくすることができるものとします。当社は、本項に基づき当社が行った措置によって企業ユーザー及び第三者に生じた損害について一切の責任を負いません。
        </p>
        <h3 className={styles.header}>第15条 本サービスの停止等</h3>
        <p className={styles.text}>
          1.当社は、以下のいずれかに該当する場合には、企業ユーザーに事前に通知することなく、本サービスの利用の全部又は一部を停止又は中断することができるものとします。
        </p>
        <p className={styles.text}>
          (1)本サービスに係るコンピューター・システムの点検又は保守作業を定期的又は緊急に行う場合
        </p>
        <p className={styles.text}>
          (2)コンピューター、通信回線等が事故により停止した場合
        </p>
        <p className={styles.text}>
          (3)火災、停電、天災地変などの不可抗力により本サービスの運営ができなくなった場合
        </p>
        <p className={styles.text}>
          (4)外部サービスに、トラブル、サービス提供の中断又は停止、本サービスとの連携の停止、仕様変更等が生じた場合
        </p>
        <p className={styles.text}>
          (5)その他、当社が停止又は中断を必要と判断した場合
        </p>
        <p className={styles.text}>
          2.当社は、本条に基づき当社が行った措置に基づき企業ユーザーに生じた損害について一切の責任を負いません。
        </p>
        <h3 className={styles.header}>第16条 設備の負担等</h3>
        <p className={styles.text}>
          1.本サービスの提供を受けるために必要な、コンピューター、ソフトウェアその他の機器、通信回線その他の通信環境等の準備及び維持は、企業ユーザーの費用と責任において行うものとします。
        </p>
        <p className={styles.text}>
          2.企業ユーザーは自己の本サービスの利用環境に応じて、コンピューター・ウィルスの感染の防止、不正アクセス及び情報漏洩の防止等のセキュリティ対策を自らの費用と責任において講じるものとします。
        </p>
        <p className={styles.text}>
          3.企業ユーザーは、本サービスの利用中に、企業ユーザーが保有する情報の消滅若しくは改変又は機器の故障、損傷等が生じないよう十分な注意を払うものとし、当社は企業ユーザーに発生したかかる損害について一切責任を負わないものとします。
        </p>
        <h3 className={styles.header}>第17条 権利帰属</h3>
        <p className={styles.text}>
          1.本サービスに関する知的財産権は全て当社又は当社にライセンスを許諾している者に帰属しており、本規約に基づく本サービスの利用許諾は、当社ウェブサイト又は本サービスに関する当社又は当社にライセンスを許諾している者の知的財産権の使用許諾を意味するものではありません。
        </p>
        <p className={styles.text}>
          2.本サービスを通じてメンバーが企業ユーザーに対して納品した成果物に関する知的財産権は、タスクが完了するまでの間はメンバーに帰属するものとし、タスクが完了した段階で企業ユーザーに移転するものとします。ただし、メンバーが本取引開始前より有している知的財産権（以下「留保知的財産権」といいます。）は移転しないものとします。留保知的財産権について、メンバーは企業ユーザーに対し、当該成果物を利用するために必要な範囲で留保知的財産権の利用（第三者への使用許諾を含みます。）を無償で許諾するものとします。
        </p>
        <p className={styles.text}>
          3.第三者の保有する知的財産権について、第三者の許可を得た上でメンバーが成果物に利用した場合、該当する知的財産権は、第三者に帰属し、企業ユーザーに移転しないものとします。また、メンバーは企業ユーザーに対して、当該成果物にかかる著作者人格権を行使しないものとします。
        </p>
        <p className={styles.text}>
          4.本条の第2項及び第3項について、メンバーと企業ユーザー間において別途の取決めがある場合は、同取決めを優先するものとします。
        </p>
        <h3 className={styles.header}>第18条 （反社会的勢力の排除について）</h3>
        <p className={styles.text}>
          1.企業ユーザーは本サービスを利用するにあたって、自身及び管理者を含む企業メンバーの全てが反社会的勢力（「暴力団員による不当な行為の防止等に関する法律」に定義する暴力団及びその関係団体をいうほか、総会屋、社会運動や政治運動を標ぼうするゴロ、特殊知能暴力団等、その他反社会的勢力の総称をいい、本規約では以後「反社会的勢力」といいます）ではないことを表明し、将来にわたっても、所属もしくは該当、又は関与していないことを確約するものとします。
        </p>
        <p className={styles.text}>
          2.当社は、企業ユーザー又は管理者を含む企業メンバーのいずれかが反社会的勢力に所属もしくは該当する、又は関与していると判断した場合、当該企業ユーザーに事前に通知等を行うことなく、当該企業ユーザーによる本サービスの利用停止、当該企業ユーザーの企業ユーザー資格の取消しなど必要な措置をとることができるものとします。
        </p>
        <h3 className={styles.header}>第19条 退会</h3>

        <p className={styles.text}>
          1.本サービスにおいて、企業ユーザーはいつでも退会できます。また、退会により登録の抹消が行われた時点で、本サービスの全部が利用できなくなるものとします。
        </p>
        <p className={styles.text}>
          2.
          企業ユーザーがメンバーにタスクを委託している場合は、タスクが完了するまで退会をすることはできません。
        </p>
        <p className={styles.text}>
          3.企業ユーザーの退会時に企業ユーザーが当社に対して負っている債務がある場合は、その一切について当然に期限の利益を失います。また、企業ユーザーが債務の支払を遅滞した場合、企業ユーザーは登録抹消された日の翌日から起算して年14.6％の割合による遅延損害金を当社に支払うものとします。
        </p>
        <p className={styles.text}>
          4.退会後のメンバー情報の取扱については、第8条（企業ユーザー情報、個人情報の取扱い）の規定に従うものとします。
        </p>
        <h3 className={styles.header}>第20条 登録取消等</h3>
        <p className={styles.text}>
          1.当社は、企業ユーザーが、以下の各号のいずれかの事由に該当する場合は、事前に通知又は催告することなく、当該企業ユーザーについて本サービスの利用を一時的に停止し、又は企業ユーザーとしての登録を取り消すことができます。
        </p>
        <p className={styles.text}>(1)本規約のいずれかの条項に違反した場合</p>
        <p className={styles.text}>
          (2)登録情報に虚偽の事実があることが判明した場合
        </p>
        <p className={styles.text}>
          (3)手段の如何を問わず、本サービスの運営を妨害した場合
        </p>
        <p className={styles.text}>
          (4)支払停止若しくは支払不能となり、又は破産手続開始、民事再生手続開始、会社更生手続開始、特別清算開始若しくはこれらに類する手続の開始の申立てがあった場合
        </p>
        <p className={styles.text}>
          (5)自ら振出し、若しくは引受けた手形若しくは小切手につき、不渡りの処分を受けた場合、又は手形交換所の取引停止処分その他これに類する措置を受けたとき
        </p>
        <p className={styles.text}>
          (6)差押、仮差押、仮処分、強制執行又は競売の申立てがあった場合
        </p>
        <p className={styles.text}>(7)租税公課の滞納処分を受けた場合</p>
        <p className={styles.text}>
          (8)企業ユーザーが個人である場合又は取締役が代表取締役一名のみの法人において、その個人もしくは代表取締役が死亡した場合又は後見開始、保佐開始若しくは補助開始の審判を受けた場合
        </p>
        <p className={styles.text}>(9)第3条（登録）第4項各号に該当する場合</p>
        <p className={styles.text}>
          (10)その他、当社が企業ユーザーとしての登録の継続を適当でないと判断した場合
        </p>
        <p className={styles.text}>
          2.前項各号のいずれかの事由に該当した場合、企業ユーザーは、当社に対して負っている債務の一切について当然に期限の利益を失います。また、企業ユーザーが債務の支払を遅滞した場合、企業ユーザーは登録抹消された日の翌日から起算して年14.6％の割合による遅延損害金を当社に支払うものとします。
        </p>
        <p className={styles.text}>
          3.当社は、本条に基づき当社が行った行為により企業ユーザー及び第三者に生じた損害について一切の責任を負いません。
        </p>
        <p className={styles.text}>
          4.本条に基づく登録抹消後の企業ユーザー情報の取扱については、第8条（企業ユーザー情報、個人情報の取扱い）の規定に従うものとします。
        </p>
        <h3 className={styles.header}>第21条 保証の否認及び免責</h3>

        <p className={styles.text}>
          1.当社は、企業ユーザーが本サービスを利用することについて如何なる保証も行うものではありません。本サービスは現状有姿で提供されるものであり、当社は本サービスについて、特定の目的への適合性、商業的有用性、完全性、継続性等を含め、一切保証を致しません。
        </p>
        <p className={styles.text}>
          2.企業ユーザーは、本サービスを利用することが、企業ユーザーに適用のある法令、業界団体の内部規則等に違反するか否かを自己の責任と費用に基づいて調査するものとし、当社は、企業ユーザーによる本サービスの利用が、企業ユーザーに適用のある法令、業界団体の内部規則等に適合することを何ら保証するものではありません。
        </p>
        <p className={styles.text}>
          3.当社は、本サービス上で行われるメンバーと企業ユーザー間の取引を管理する責任を負わず、メンバーと企業ユーザー間の取引によって生じた不利益・損害について一切の責任を負いません。
        </p>
        <p className={styles.text}>
          4.当社は、メンバー及び企業ユーザーが本サービス上で取引を完了することを保証するものでもありません。当社は、本サービスを利用する企業ユーザーの業務遂行について、適法性、有用性、完全性及び正確性等の確認及び保証を行わないとともに、その瑕疵に関して一切の責任を負いません。
        </p>
        <p className={styles.text}>
          5.本サービス又は当社ウェブサイトに関連して企業ユーザーと第三者との間において生じた取引、連絡、紛争等については、企業ユーザーの責任において処理及び解決するものとし、当社はかかる事項について一切責任を負いません。
        </p>
        <p className={styles.text}>
          6.本サービス上でやりとりされるメッセージや送受信されるファイルに個人情報等が含まれていた場合、それによって会員が被った不利益・損害について、当社は一切責任を負いません。
        </p>
        <p className={styles.text}>
          7.本サービスは、インターネット環境に合わせるため及び利便性の向上を目的として、日々仕様が変化していきます。仕様が日々変わっていくことについて、企業ユーザーは予め承知するものとします。
        </p>
        <p className={styles.text}>
          8.当社は、本サービスに関連して企業ユーザー及び第三者が被った損害について、一切賠償の責任を負いません。
        </p>
        <h3 className={styles.header}>第22条 企業ユーザーの賠償等の責任</h3>

        <p className={styles.text}>
          1.企業ユーザーは、本規約に違反することにより、又は本サービスの利用に関連して当社に損害を与えた場合、当社に対しその損害を賠償しなければなりません。
        </p>
        <p className={styles.text}>
          2.企業ユーザーが、本サービスに関連して他の企業ユーザーその他の第三者からクレームを受け又はそれらの者との間で紛争を生じた場合には、直ちにその内容を当社に通知するとともに、企業ユーザーの費用と責任において当該クレーム又は紛争を処理し、当社からの要請に基づき、その経過及び結果を当社に報告するものとします。
        </p>
        <p className={styles.text}>
          3.企業ユーザーによる本サービスの利用に関連して、当社が、他の企業ユーザーその他の第三者から権利侵害その他の理由により何らかの請求を受けた場合は、企業ユーザーは当該請求に基づき当社が当該第三者に支払を余儀なくされた金額を賠償しなければなりません。
        </p>
        <h3 className={styles.header}>第23条 秘密保持</h3>

        <p className={styles.text}>
          当社及び企業ユーザーは本サービスに関連して相手方が自己に対して秘密に取り扱うことを求めて開示した非公知の情報について、相手方の事前の書面による承諾がある場合を除き、秘密に取り扱うものとします。
        </p>
        <h3 className={styles.header}>
          第24条 本規約等の変更及び本サービスの終了
        </h3>
        <p className={styles.text}>
          1.当社は、必要が生じたときに、民法（制定：明治29年4月27日法律第89号
          改正：令和元年6月14日法律第34号）第548条の4の規定に基づいて、本規約（当社ウェブサイトに掲載する本サービスに関するルール、諸規定等を含みます。以下本項において同じ。）を随時変更することがあります。なお、この場合には、企業ユーザーの利用条件その他利用契約の内容は、変更後の新しい本規約を適用するものとします。
        </p>
        <p className={styles.text}>
          2.当社は、前項の変更を行う場合は、その旨及び変更後の規定の内容並びにその効力発生時期は、効力発生時期が到来するまでに本サービス上に掲載すること、電子メールの送信又はその他相当の方法により周知して、変更後の新しい本規約の内容を企業ユーザーに通知するものとします。
        </p>
        <p className={styles.text}>
          3.当社は、当社の都合により、本サービスの提供を、一定期間の予告をもって終了することができます。当社が本サービスの提供を終了する場合、当社は事前に企業ユーザーに対して通知するものとします。
        </p>
        <p className={styles.text}>
          4.本規約の変更及び終了により企業ユーザー又は第三者に発生したすべての損害について、当社は一切の責任を負いません。
        </p>
        <h3 className={styles.header}>第25条 連絡/通知</h3>

        <p className={styles.text}>
          1.本サービスに関する問い合わせその他企業ユーザーから当社に対する連絡又は通知、及び本規約の変更に関する通知その他当社から企業ユーザーに対する連絡又は通知は、当社の定める方法で行うものとします。
        </p>
        <p className={styles.text}>
          2.企業ユーザーは、本サービスへの登録申請の際に当社に提供した企業ユーザーのメールアドレスまたは電話番号が企業ユーザーに対する連絡先として有効であることを当社に対し保証するものとします。当社が当該メールアドレス宛てに企業ユーザーに対する通知を行った場合、又は当該電話番号宛に電話した場合において、当社の責に帰さない事由により連絡が到達しなかったとしても、企業ユーザーはこれについて何ら異議を述べないものとします。
        </p>
        <h3 className={styles.header}>第26条 本サービスに係る譲渡等</h3>

        <p className={styles.text}>
          1.企業ユーザーは、当社の書面による事前の承諾なく、利用契約上の地位又は本規約に基づく企業ユーザーとしての権利若しくは義務につき、第三者に対し、譲渡、移転、担保設定、その他の処分をすることはできません。
        </p>
        <p className={styles.text}>
          2.当社は本サービスにかかる事業を第三者に譲渡（事業譲渡、会社分割その他態様の如何を問わないものとします。）した場合には、当該譲渡に伴い利用契約上の地位、本規約に基づく権利及び義務並びに企業ユーザーの登録情報その他の情報を当該譲渡の譲受人に譲渡することができるものとし、企業ユーザーは、かかる譲渡につき本項において予め同意したものとします。
        </p>
        <h3 className={styles.header}>第27条 分離可能性</h3>

        <p className={styles.text}>
          本規約のいずれかの条項又はその一部が、法令等により無効又は執行不能と判断された場合であっても、本規約の残りの規定及び一部が無効又は執行不能と判断された規定の残りの部分は、継続して完全に効力を有し、当社及び企業ユーザーは、当該無効若しくは執行不能の条項又は部分を適法とし、執行力を持たせるために必要な範囲で修正し、当該無効若しくは執行不能な条項又は部分の趣旨並びに法律的及び経済的に同等の効果を確保できるように努めるものとします。
        </p>
        <h3 className={styles.header}>第28条 準拠法及び管轄裁判所</h3>

        <p className={styles.text}>
          本規約の準拠法は日本法とし、本規約に起因し又は関連する一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
        </p>
        <h3 className={styles.header}>第29条 協議解決</h3>
        <p className={styles.text}>
          当社及び企業ユーザーは、本規約に定めのない事項又は本規約の解釈に疑義が生じた場合には、互いに信義誠実の原則に従って協議の上速やかに解決を図るものとします。
        </p>
        <br />
        <p className={styles.text}>2020年09月24日　制定</p>
      </div>
    </main>
  );
};

export default TermsOfServiceCo;
