import React from 'react';

import styles from './reqiredForm.module.scss';

const RequiredForm = ({
  input,
  title,
  required,
  placeholder,
  type,
  name,
  requiredLabel = true
}) => (
  <div className={styles.container}>
    {requiredLabel ? (
      <label>
        <p>{title}</p>
        {required && <span>必須</span>}
      </label>
    ) : null}
    <div className={styles.inputContainer}>
      <input name={name} placeholder={placeholder} type={type} {...input} />
    </div>
  </div>
);

export default RequiredForm;
