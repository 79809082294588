import React from 'react';
import {Field, reduxForm, getFormSubmitErrors, getFormValues} from 'redux-form';
import {useSelector} from 'react-redux';
import styles from './edit.module.scss';
// material-ui
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
// components
import itemStyles from '../../../components/ContentItem/contentItem.module.scss';
import PageTitle from '../../../components/PageTitle';
import ContentTitle from '../../../components/ContentTitle';
import Text from '../../../components/Text';
import InputImgField from '../../../components/Form/InputImgField';
import {
  renderText,
  renderTextArea,
  renderFile,
  renderImage
} from '../../../components/Field';
import {AvatarImg} from '../../../common';
import Camera from '../../../assets/icon_camera_circle.svg';
import FormError from '../../../components/Form/FormError';
import Blank from '../../../components/Blank';

const useStyles = makeStyles({
  button: {
    width: '40rem',
    height: '7rem',
    borderRadius: '0.8rem',
    fontSize: '1.8rem',
    fontWeight: 'bold'
  }
});

const Edit = ({handleSubmit, pristine, submitting, error}) => {
  const classes = useStyles();
  const {img} = useSelector(state => getFormValues('company')(state));

  return (
    <form onSubmit={handleSubmit}>
      <div className={styles.flex}>
        <div className={styles.container}>
          <PageTitle title="企業情報の編集" />
          <div className={styles.containerItem}>
            <ContentTitle title="その他情報" />
            <div className={styles.fullWidth}>
              <div className={styles.fieldContainer}>
                <div className={styles.title}>{'企業ロゴ'}</div>
                <InputImgField img={img} id={'img'} name={'img'} />
              </div>
              <div className={styles.border} />
            </div>

            <div className={styles.fullWidth}>
              <div className={styles.fieldContainer}>
                <div className={styles.title}>{'公式HP'}</div>
                <Field
                  name={'url1'}
                  component={renderText}
                  type="text"
                  fullWidth={true}
                  placeholder={'https://example.com'}
                />
              </div>
              <div className={styles.border} />
            </div>
            <div className={styles.fullWidth}>
              <div className={styles.fieldContainer}>
                <div className={styles.title}>{'企業紹介'}</div>
                <Field
                  name={'introduce'}
                  component={renderTextArea}
                  type="text"
                />
              </div>
              <div className={styles.border} />
            </div>
          </div>
          <Blank height={'2.4rem'} />
          <FormError error={error} />
          <div className={styles.buttonContainer}>
            <Button
              className={classes.button}
              type="submit"
              disabled={pristine || submitting}
              variant="outlined"
              color="primary"
            >
              {'決定'}
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default reduxForm({
  enableReinitialize: true,
  form: 'company',
  multipartForm: true
})(Edit);
