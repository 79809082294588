import React, {Component, useEffect} from 'react';
import {connect, useDispatch, useSelector} from 'react-redux';
import {change} from 'redux-form';
// actions
import {requestTaskCreate} from '../../../../actions/task';
import {requestCategoryList} from '../../../../actions/category';
import {requestFormatList} from '../../../../actions/format';
// components
import MainTitle from '../../../../components/MainTitle';
import TaskForm from '../../../../components/Form/co/taskForms/TaskForm';
// constants
import {NEW_TASK_PHRASE} from '../../../../constants/phrase';
import {
  selectCheapCouponId,
  makeDefaultClosingDate,
  cleanFields
} from '../../../../common';
// hooks
import useScrollToTop from '../../../../hooks/useScrollToTop';
import useRequestCompanyInfo from '../../../../hooks/useRequestCompanyInfo';
import {useParams} from 'react-router';

const TaskCreate = () => {
  const dispatch = useDispatch();
  const {paymentState} = useSelector(state => state.payment);
  const {coupons} = useSelector(state => state.myCompany);
  const {projectId} = useParams();
  useScrollToTop();
  useEffect(() => {
    dispatch(requestCategoryList());
    dispatch(requestFormatList());
  }, []);

  useEffect(() => {
    if (selectCheapCouponId(coupons)) {
      dispatch(change('task', 'coupon', selectCheapCouponId(coupons)));
    }
  }, [coupons]);
  useRequestCompanyInfo();

  // 応募締め切りのデフォルトを２週間後に
  const future = makeDefaultClosingDate();

  let initialValues = {
    format_choose: 'default',
    project: projectId,
    closing_date: future.toISOString(),
    description: NEW_TASK_PHRASE
  };

  return (
    <div>
      <MainTitle titleStr={'新規タスク'} pattern={false} />
      <TaskForm
        onSubmit={values => {
          dispatch(
            requestTaskCreate({
              ...cleanFields(values)
            })
          );
        }}
        paymentState={paymentState}
        initialValues={initialValues}
        preSaveBehavior={'POST'}
      />
    </div>
  );
};

export default TaskCreate;
