import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getFormValues} from 'redux-form';
import classNames from 'classnames';
import {
  Field,
  FieldArray,
  reduxForm,
  formValueSelector,
  getFormSubmitErrors
} from 'redux-form';
import style from '../taskForm.module.scss';
import commonStyle from '../../../../../common/common.module.scss';
// components
import SubTitle from '../../../../SubTitle';
import TagInput from '../../../../TagInput';
import {
  requestRewardDetail,
  requestTaskCreate,
  requestTaskEdit
} from '../../../../../actions/task';
import {
  renderText,
  renderTextArea,
  renderSelect,
  renderCalender,
  renderRadio,
  renderFiles
} from '../../../../Field';
import {
  NumberWithDelimiter,
  TASK_STATUS,
  cleanFields
} from '../../../../../common';
import CommonButton from '../../../../../components/CommonButton';
import BaseHelpField from '../../../../HelpButtons/BaseHelpField';
import {formField} from '../../../../HelpButtons/constants';
// material-ui
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem/index';
import IconButton from '@material-ui/core/IconButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
// images
import Cross from '../../../../../assets/icon_cross.svg';
import ClipBlue from '../../../../../assets/icon_clip_blue.svg';
import ClipWhite from '../../../../../assets/icon_clip_white.svg';
//validate関数
import * as Validator from '../../../utils/validate';
import {
  PAYMENT_STATUS_CREDIT,
  PAYMENT_STATUS_PAID
} from '../../../../../constants';
import {makeStyles} from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Blank from '../../../../Blank';
import {requestCompany} from '../../../../../actions/company';
import FormatDate from '../../../../FormatDate';
import {
  RenderDraftFiles,
  RewardDetail,
  CouponSelector
} from '../commonComponents';
import FormError from '../../../FormError';

const useStyles = makeStyles(theme => ({
  button: {
    width: '22rem',
    height: '5rem',
    backgroundColor: '#999999',
    overflow: 'hidden'
  },
  attachedButton: {
    width: '22rem',
    height: '5rem',
    backgroundColor: '#093791',
    overflow: 'hidden'
  },
  inputHalf: {
    width: '50%'
  },
  saveButton: {
    width: '30rem',
    height: '5rem',
    background: '#093791',
    '&:hover': {
      background: '#002163'
    },
    fontSize: '1.8rem',
    fontWeight: 'bold'
  },
  preSaveButton: {
    width: '30rem',
    height: '5rem',
    '&:hover': {
      background: '#D7E2F4'
    }
  },
  divider: {
    margin: '0 3rem'
  }
}));

const TaskForm = ({
  paymentState,
  handleSubmit,
  pristine,
  submitting,
  error,
  onSubmit,
  preSaveBehavior
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [fields, setFields] = useState({
    getAll: () => []
  });
  const files = fields.getAll() !== undefined ? fields.getAll() : [];
  const formatList = useSelector(state => state.format.list);
  const formValues = useSelector(state => getFormValues('task')(state));
  const submitErrors = useSelector(state => {
    return getFormSubmitErrors('task')(state);
  });
  const rewardDetail = useSelector(state => state.task.rewardDetail);
  const isUsableCredit =
    paymentState.credit.status_code === PAYMENT_STATUS_CREDIT.ok;
  const isUsablePaid = paymentState.paid.status_code === PAYMENT_STATUS_PAID.ok;
  const {coupons} = useSelector(state => state.myCompany);

  return (
    <form onSubmit={handleSubmit}>
      <div className={style.mainContent}>
        <div className={style.main}>
          <SubTitle headerText={'タスク詳細'} />
          <div className={style.mainCategory}>
            <div className={classNames(style.row, style.itemPadding)}>
              <div className={style.itemNameContainer}>
                <div className={classNames(style.rowTitle, style.CategoryText)}>
                  <p>タイトル</p>
                </div>
                <div className={style.rowRequireContainer}>
                  <div className={style.rowRequire}>
                    <label className={style.required}>必須</label>
                  </div>
                </div>
              </div>
              <div className={style.rowInputFullWidth}>
                <Field
                  name="title"
                  required={true}
                  component={renderText}
                  fullWidth={true}
                  validate={[Validator.RequiredItem]}
                  placeholder={'例：決済機能の実装'}
                />
              </div>
            </div>
            <Divider classes={{root: classes.divider}} />
            <div className={classNames(style.rowOverview, style.itemPadding)}>
              <div
                className={classNames(
                  commonStyle.content,
                  style.itemNameContainer
                )}
              >
                <div className={classNames(style.rowTitle, style.CategoryText)}>
                  <p>概要</p>
                </div>
                <div className={style.rowRequire}>
                  <label className={style.required}>必須</label>
                </div>
              </div>
              <BaseHelpField placement={'top'} text={formField.taskDescription}>
                <div className={style.rowInputFullWidth}>
                  <Field
                    name="description"
                    required={true}
                    component={renderTextArea}
                    fullWidth={true}
                    validate={[Validator.RequiredItem]}
                  />
                </div>
              </BaseHelpField>
            </div>
            <Divider classes={{root: classes.divider}} />
            <div className={style.attachedContainer}>
              <div>
                <div className={style.rowSubCategory}>
                  <div
                    className={classNames(style.rowTitle, style.CategoryText)}
                  >
                    <p>添付ファイル</p>
                  </div>
                  <div
                    className={classNames(style.rowRequire, style.notRequired)}
                  >
                    <label className={style.required}>必須</label>
                  </div>
                  <BaseHelpField placement={'top'} text={formField.file}>
                    <div className={style.rowInput}>
                      <FieldArray
                        name="file"
                        component={renderFiles}
                        setFields={setFields}
                      />
                    </div>
                  </BaseHelpField>
                </div>
              </div>
              <div className={style.attachmentContainer}>
                <RenderDraftFiles formValues={formValues} />
                {files.map((file, index, array) => (
                  <div className={style.attachmentItem} key={index}>
                    <img src={ClipBlue} />
                    <span className={style.attachmentText}>
                      {`${file.name} (${file.size}B)`}
                    </span>
                    <IconButton onClick={() => fields.remove(index)}>
                      <img src={Cross} />
                    </IconButton>
                  </div>
                ))}
              </div>
            </div>
            <Divider classes={{root: classes.divider}} />
            <div className={style.subCategoryContainer}>
              <div className={style.rowSubCategory}>
                <div className={classNames(style.rowTitle, style.CategoryText)}>
                  <p>開発環境</p>
                </div>
                <div className={style.rowRequire}>
                  <label className={style.notRequired}>必須</label>
                </div>
                <BaseHelpField placement={'top'} text={formField.tag}>
                  <div className={style.rowInputTag}>
                    <FieldArray name="tags" component={TagInput} />
                  </div>
                </BaseHelpField>
              </div>
              <div className={style.leftBlankContent}>
                <div className={style.attachButtonComment}>
                  エンジニアが、このタグを元に検索を行います
                  <br />
                  記入例: Python, Django, GCP
                </div>
              </div>
            </div>
            <Divider classes={{root: classes.divider}} />
          </div>
          <SubTitle headerText={'納品形式'} />
          <div className={classNames(style.row, style.itemPadding)}>
            <div className={classNames(style.rowTitle, style.CategoryText)}>
              <p></p>
            </div>
            <div className={style.nonTitleContainer}>
              <div className={style.rowRequire}>
                <label className={style.required}>必須</label>
              </div>
            </div>
            <BaseHelpField placement={'top'} text={formField.format}>
              <div className={style.rowInput}>
                <Field
                  name="format_choose"
                  required={true}
                  component={renderSelect}
                  className={classes.inputHalf}
                  validate={[Validator.RequiredChoice]}
                >
                  <MenuItem value="0">選択してください</MenuItem>
                  {formatList.map((elem, index) => (
                    <MenuItem value={elem.id} key={index}>
                      {elem.name}
                    </MenuItem>
                  ))}
                </Field>
              </div>
            </BaseHelpField>
          </div>
          <Divider classes={{root: classes.divider}} />
          <Blank height={'5rem'} />
          <SubTitle headerText={'報酬'} />

          {coupons && coupons.length > 0 && (
            <CouponSelector coupons={coupons} classes={classes} />
          )}

          <div className={style.scheduleContainer}>
            <div className={classNames(style.row, style.itemPadding)}>
              <div className={style.itemNameContainer}>
                <div className={classNames(style.rowTitle, style.CategoryText)}>
                  <p>成果報酬額</p>
                </div>
                <div className={style.rowRequire}>
                  <label className={style.required}>必須</label>
                </div>
              </div>
              <BaseHelpField placement={'top'} text={formField.reward}>
                <div className={style.rowInputReward}>
                  <Field
                    name="reward"
                    required={true}
                    component={renderText}
                    onBlur={e => {
                      dispatch(
                        requestRewardDetail({
                          reward: e.target.value,
                          coupon: formValues.coupon
                        })
                      );
                    }}
                    width={'23rem'}
                    placeholder={'整数を入力してください'}
                    validate={[Validator.RequiredValidPrice]}
                  />
                  <p>円</p>
                </div>
              </BaseHelpField>
            </div>
            <RewardDetail rewardDetail={rewardDetail} />
            <Divider classes={{root: classes.divider}} />
          </div>
          <Blank height={'5rem'} />
        </div>
      </div>
      <Blank height={'2.4rem'} />
      <FormError error={error} />
      <div className={style.submits}>
        <div className={style.submit}>
          <CommonButton
            type={'submit'}
            text={'保存する'}
            size={'medium'}
            color={'blue'}
            variant={'contained'}
            shape={'normal'}
            disabled={pristine || submitting}
          />
        </div>
      </div>
    </form>
  );
};

export default reduxForm({
  form: 'task'
})(TaskForm);
