import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {requestInviteMember} from '../../../actions/member';
import {useSelector} from 'react-redux';
import {Field, reduxForm, getFormValues, getFormSubmitErrors} from 'redux-form';
import {reset} from 'redux-form';
import {renderText} from '../../Field';
import styles from './inviteMember.module.scss';
import IconMember from '../../../assets/icon_member.svg';
import IconEmail from '../../../assets/icon_email_black.svg';
import SubmitButton from '../../Button/SubmitButton';
import DeleteButton from '../../Button/DeleteButton';
import CommonButton from '../../CommonButton';

const InviteMember = props => {
  const {handleSubmit} = props;
  const [members, setMembers] = useState([]);
  const [entireError, setEntireErrors] = useState(null);
  const {email} = useSelector(state => getFormValues('InviteMember')(state));
  const submitErrors = useSelector(state =>
    getFormSubmitErrors('InviteMember')(state)
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (submitErrors.error) {
      if (submitErrors.error.email) {
        setMembers(
          members.map(member => {
            return {...member, error: submitErrors.error.email[member.id]};
          })
        );
      } else {
        setEntireErrors(submitErrors.error);
      }
    }
  }, [submitErrors]);

  const handleAdd = () => {
    setMembers([...members, {id: members.length, email: email}]);
    dispatch(reset('InviteMember'));
  };

  const handleDelete = item => {
    const result = members.filter(member => item.id !== member.id);
    setMembers(result);
  };

  return (
    <form>
      <div className={styles.inner}>
        <div className={styles.header}>
          <img src={IconMember} />
          <div className={styles.subTitle}>メンバーを招待</div>
        </div>
        <div className={styles.emailList}>
          {members.map((item, index) => {
            return (
              <>
                <div key={item.id} className={styles.mailItem}>
                  <div className={styles.addedEmail}>
                    <img src={IconEmail} />
                    <p className={styles.emailText}>{item.email}</p>
                  </div>
                  <DeleteButton onClick={() => handleDelete(item)} />
                </div>
                <p className={styles.emailError}>{item.error}</p>
              </>
            );
          })}
        </div>
        <div className={styles.form}>
          <div className={styles.inputItem}>
            <Field
              name="email"
              required={true}
              component={renderText}
              rows={7}
              fullWidth={true}
            />
          </div>

          <div className={styles.addButton}>
            <CommonButton
              text={'追加'}
              size={'preMinimum'}
              color={'blue'}
              variant={'contained'}
              shape={'normal'}
              onClick={handleAdd}
              disabled={!email}
            />
          </div>
        </div>
        <p className={styles.entireError}>{entireError}</p>
      </div>
      <div className={styles.button}>
        <SubmitButton
          text="招待を送信する"
          disabled={members.length === 0}
          onClick={handleSubmit(() => dispatch(requestInviteMember(members)))}
        />
      </div>
    </form>
  );
};

export default reduxForm({
  enableReinitialize: true,
  form: 'InviteMember',
  multipartForm: true,
  initialValues: {email: null}
})(InviteMember);
