import {
  IS_SHOW_DIALOG,
  SET_MESSAGE_TYPE
} from '../actions/dialog';

const initialState = {
  isShow: false,
  messageType: ''
};

function dialog(state = initialState, action) {
  switch (action.type) {
    case IS_SHOW_DIALOG:
      return {
        ...state,
        isShow: action.payload
      }
    case SET_MESSAGE_TYPE:
      return {
        ...state,
        isShow: true,
        messageType: action.payload
      }
    default:
      return state
  }
}

export default dialog;
