import React from 'react';
import {useHistory} from 'react-router-dom';
import classNames from 'classnames';
import styles from '../../taskList.module.scss';
import rewardStyle from '../rewards.module.scss';
import CommonLabel from '../../../../CommonLabel';
import Text from '../../../../Text';
import formatDate from '../../../../FormatDate/index';
import TaskListTheme from '../../../../TaskListTheme';
import Reward from '../../../Reward';
import {PAYMENT_METHODS} from '../../../../../constants';

// material ui
import ListItem from '@material-ui/core/ListItem';

const makePaymentDay = sales_at => {
  const salesYear = new Date(sales_at).getFullYear();
  const salesMonth = new Date(sales_at).getMonth() + 1;
  const salesDate = new Date(sales_at).getDate();

  let paymentDay;

  if (salesDate < 16) {
    paymentDay = `${salesYear}年 ${salesMonth}月 末日`;
  } else {
    const paymentDate = 15;
    const paymentMonth = salesMonth + 1;
    const formatedPaymentMonth = paymentMonth === 13 ? 1 : paymentMonth;
    const formatedPaymentYear = paymentMonth === 13 ? salesYear + 1 : salesYear;
    paymentDay = `${formatedPaymentYear}年 ${formatedPaymentMonth}月 ${paymentDate}日`;
  }

  return paymentDay;
};

const ScheduledRewardTask = props => {
  const item = props.item;
  const index = props.index;
  const history = useHistory();
  const {
    id,
    sales_at,
    title,
    company_name,
    project,
    reward_detail: {reward, sum, tax}
  } = item;

  const paymentDay = makePaymentDay(sales_at);

  return (
    <TaskListTheme key={index} index={index}>
      <ListItem
        button={true}
        onClick={() => history.push(`/en/project/${project}/task/${id}`)}
      >
        <div className={classNames(styles.container)}>
          <div className={styles.title}>
            <div className={styles.flex}>
              <Text children={`振込予定日 ${paymentDay}`} component="text01" />
            </div>
            <Text className={styles.body} children={title} component="text02" />
            <div className={styles.companyName}>
              <Text
                children={company_name ? company_name : 'loading'}
                component="text01"
              />
            </div>
          </div>
          <Reward reward={reward} />
          <p className={rewardStyle.operator}>+</p>
          <Reward reward={tax} title={'消費税'} isNormal={false} />
          <p className={rewardStyle.operator}>=</p>
          <Reward reward={sum} title={'合計'} />
        </div>
      </ListItem>
    </TaskListTheme>
  );
};

export default ScheduledRewardTask;
