import { SUCCESS_VALUATION } from '../actions/valuation'

const initialState = '';

const valuation = (state = initialState, action) => {
  switch (action.type) {
    case SUCCESS_VALUATION:
      return state;
    default:
      return state;
  }
};

export default valuation
