import React from 'react';
import PropTypes from 'prop-types';
import {Switch, Route, Redirect} from 'react-router-dom';
import {compose} from 'recompose';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import 'normalize.css';
import './app.module.scss';

//components
import Dialog from '../components/Dialog/index';
import ProgressOverlay from '../components/ProgressOverlay';
import Signin from './Signin';
import Sorry from './Sorry';
import SCTL from '../components/SCTL';
import TermsRouter from '../containers/TermsRouter';
import Policy from '../components/Policy';
import FAQ from '../components/FAQ';
import PasswordResetMail from '../components/PasswordResetMail';
import PasswordResetResult from '../containers/co/PasswordResetResult';
import PasswordResetConfirm from '../components/PasswordResetConfirm';
import CoLogin from '../components/CoLogin';
import MemberList from './co/MemberList';
import Info from './co/Info';
import Oauth from './Oauth';
import SignInLoading from './signLoading/signInLoading';
import SignOutLoading from './signLoading/signOutLoading';
import NotFound from './NotFound';
import WithMuiThemeProvider from '../containers/WithMuiThemeProvider';
import Contact from '../containers/Contact';
import store from 'store';
import {isRedirectCancelUrl} from '../sagas/utills/common';
import {history} from '../store';
import AppRoutes from './AppRoutes';
import PublicRoute from './publics/PublicRoute';
import Terms from '../components/Terms';
// actions
import {isShowDialog} from '../actions/dialog';
import {setModalSetting} from '../actions/task';
import {isLoadingFlag} from '../actions/sagaMonitor';
import CoSignup from './co/Signup/CoSignup';
import SnackbarProviderWrapper from '../components/CommonSnackbar/SnackbarProviderWrapper';
import {userType} from '../common';

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  hide: {
    display: 'none'
  }
});

class App extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps) {
    //ErrorBoundaryに遷移したくない小さなエラーがあったら(==errorContentsがnullでなければ) ユーザーにmodalで表示する
    if (this.props.Error.errorContents) {
      this.props.setErrorModal(this.props.Error.errorContents);
    }
  }

  render() {
    const {dialog, closeDialog, effectStartFlag} = this.props;
    const isAuthenticated = Boolean(store.get('token'));

    return (
      <WithMuiThemeProvider>
        <SnackbarProviderWrapper>
          <div>
            <Switch>
              {!isAuthenticated && <Redirect exact from="/" to={`/signin`} />}
              <Route exact={true} path="/signin" component={Signin} />
              <Route path="/co-signup" component={CoSignup} />
              <Route path="/public" component={PublicRoute} />
              <Route
                exact={true}
                path="/password-reset-mail"
                component={PasswordResetMail}
              />
              <Route
                exact={true}
                path="/password-reset-mail-result"
                component={PasswordResetResult}
              />
              <Route
                exact={true}
                path="/password-reset-confirm"
                component={PasswordResetConfirm}
              />
              <Route exact={true} path="/sorry" component={Sorry} />
              <Route exact={true} path="/sctl" component={SCTL} />
              <Route path="/terms" component={TermsRouter} />
              <Route exact={true} path="/policy" component={Policy} />
              <Route path="/faq" component={FAQ} />
              <Route exact={true} path="/oauth" component={Oauth} />
              <Route path={'/contact'} component={Contact} />
              <Route
                exact={true}
                path="/signin-loading"
                component={SignInLoading}
              />
              <Route
                exact={true}
                path="/signout-loading"
                component={SignOutLoading}
              />
              <Route
                exact={true}
                path="/activate/firstuser/:firstToken"
                component={CoLogin}
              />
              <Route
                exaxt
                path="/activate/member/:memberToken"
                component={CoLogin}
              />
              <Route exact path="/activate/member" component={MemberList} />
              <Route path="/" component={AppRoutes} />
              <Route component={NotFound} />
            </Switch>
            <Dialog
              isShow={dialog.isShow}
              closeHandler={closeDialog}
              messageType={dialog.messageType}
            />
            {effectStartFlag ? <ProgressOverlay /> : ''}
          </div>
        </SnackbarProviderWrapper>
      </WithMuiThemeProvider>
    );
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const mapDispatchToProps = dispatch => ({
  closeDialog() {
    dispatch(isShowDialog(false));
  },
  setErrorModal: errorContents =>
    dispatch(
      setModalSetting({
        type: 'ACCEPT_ERROR',
        isVisible: true,
        params: errorContents
      })
    )
});

const mapStateToProps = state => {
  return {
    routing: state.routing,
    dialog: state.dialog,
    effectIdList: state.sagaMonitor.list,
    effectStartFlag: state.sagaMonitor.isLoading,
    Error: state.errorHandler
  };
};

export default compose(
  withStyles(styles, {withTheme: true}),
  connect(mapStateToProps, mapDispatchToProps)
)(App);
