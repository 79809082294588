import React from 'react';
import {useHistory} from 'react-router-dom';
import classNames from 'classnames';
import styles from '../../taskList.module.scss';
import rewardStyle from '../rewards.module.scss';
import CommonLabel from '../../../../CommonLabel';
import Text from '../../../../Text';
import formatDate from '../../../../FormatDate/index';
import TaskListTheme from '../../../../TaskListTheme';
import Reward from '../../../Reward';
import {PAYMENT_METHODS} from '../../../../../constants';

// material ui
import ListItem from '@material-ui/core/ListItem';

const RecievedRewardTask = ({item, index}) => {
  const history = useHistory();
  const {
    id,
    sales_at,
    title,
    company_name,
    project,
    reward_detail: {reward, sum, transfer_fee, tax, created_at}
  } = item;

  return (
    <TaskListTheme key={index} index={index}>
      <ListItem
        button={true}
        onClick={() => history.push(`/en/project/${project}/task/${id}`)}
      >
        <div className={classNames(styles.container)}>
          <div className={styles.title}>
            <div className={styles.flex}>
              <Text
                children={`振込日 ${formatDate(
                  new Date(created_at),
                  'date',
                  'str'
                )}`}
                component="text01"
              />
            </div>
            <Text className={styles.body} children={title} component="text02" />
            <div className={styles.companyName}>
              <Text
                children={company_name ? company_name : 'loading'}
                component="text01"
              />
            </div>
          </div>
          <Reward reward={reward} />
          <p className={rewardStyle.operator}>+</p>
          <Reward reward={tax} title={'消費税'} isNormal={false} />
          <p className={rewardStyle.operator}>-</p>
          <Reward reward={transfer_fee} title={'振込手数料'} isNormal={false} />
          <p className={rewardStyle.operator}>=</p>
          <Reward reward={sum - transfer_fee} title={'合計'} />
        </div>
      </ListItem>
    </TaskListTheme>
  );
};

export default RecievedRewardTask;
