import React from 'react';
import style from './createMessageForm.module.scss';
import {Field, reduxForm, submit} from 'redux-form';
import {renderText, renderTextArea} from '../../../Field';
import classNames from 'classnames';
import SubmitButton from '../../../Button/SubmitButton';
import Required from '../../../Required';
import TextLabel from '../../../Labels/TextLabel';
import {useDispatch} from 'react-redux';
import FormError from "../../FormError";

let Index = ({handleSubmit, userInfo, error}) => {
  const dispatch = useDispatch();

  return (
    <form onSubmit={handleSubmit}>
      <div className={style.rootContainer}>
        <div className={style.row}>
          <label className={style.subtitleContainer}>
            <TextLabel text={'送信先'} />
          </label>
          <div className={style.nickname}>{userInfo.nick_name}</div>
          <div className={style.blackText}>さん</div>
        </div>
        <div className={style.divider} />
        <div className={style.divider} />
        <div className={classNames(style.row, style.rowTextArea)}>
          <div className={style.subtitleContainer}>
            <TextLabel text={'メッセージ'} />
            <Required />
          </div>
          <div className={style.inputContainer}>
            <Field
              name="content"
              required={true}
              component={renderTextArea}
              fullWidth={true}
            />
          </div>
        </div>
        <div className={style.divider} />
        <FormError error={error}/>
        <div className={style.submitContainer}>
          <SubmitButton
            text={'メッセージ送信'}
            onClick={() => dispatch(submit('createMessage'))}
          />
        </div>
      </div>
    </form>
  );
};

Index.defaultProps = {
  userInfo: {nick_name: ''}
};
Index = reduxForm({
  form: 'createMessage'
})(Index);

export default Index;
