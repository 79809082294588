import { createAction } from 'redux-actions';

// 一覧取得
export const REQUEST_ACCOUNT_LIST = 'REQUEST_ACCOUNT_LIST';
export const SUCCESS_ACCOUNT_LIST = 'SUCCESS_ACCOUNT_LIST';
export const requestAccountList = createAction(REQUEST_ACCOUNT_LIST);
export const successAccountList = createAction(SUCCESS_ACCOUNT_LIST);

// 詳細取得
export const REQUEST_ACCOUNT_DETAIL = 'REQUEST_ACCOUNT_DETAIL';
export const SUCCESS_ACCOUNT_DETAIL = 'SUCCESS_ACCOUNT_DETAIL';
export const requestAccountDetail = createAction(REQUEST_ACCOUNT_DETAIL);
export const successAccountDetail = createAction(SUCCESS_ACCOUNT_DETAIL);

// 新規作成
export const REQUEST_ACCOUNT_CREATE = 'REQUEST_ACCOUNT_CREATE';
export const SUCCESS_ACCOUNT_CREATE = 'SUCCESS_ACCOUNT_CREATE';
export const requestAccountCreate = createAction(REQUEST_ACCOUNT_CREATE);
export const successAccountCreate = createAction(SUCCESS_ACCOUNT_CREATE);

// 権限編集
export const REQUEST_ACCOUNT_EDIT = 'REQUEST_ACCOUNT_EDIT';
export const SUCCESS_ACCOUNT_EDIT= 'SUCCESS_ACCOUNT_EDIT';
export const requestAccountEdit = createAction(REQUEST_ACCOUNT_EDIT);
export const successAccountEdit = createAction(SUCCESS_ACCOUNT_EDIT);

// 削除
export const REQUEST_ACCOUNT_DELET = 'REQUEST_ACCOUNT_DELET';
export const SUCCESS_ACCOUNT_DELET= 'SUCCESS_ACCOUNT_DELET';
export const requestAccountDelete = createAction(REQUEST_ACCOUNT_DELET);
export const successAccountDelete = createAction(SUCCESS_ACCOUNT_DELET);
