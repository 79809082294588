import {
  DELETE_EFFECT,
  SET_EFFECT,
  IS_LOADING_FLAG
} from '../actions/sagaMonitor';

const initialState = {
  list: [],
  isLoading: false
};

function sagaMonitor(state = initialState, action) {
  switch (action.type) {
    case SET_EFFECT:
      let effectId = action.payload;
      let newList = state.list;
      newList.push({effectId: effectId});
      return {
        ...state,
        list: newList
      };
    case DELETE_EFFECT:
      effectId = action.payload;
      newList = state.list.filter(n => n.effectId !== action.payload);
      return {
        ...state,
        list: newList
      };
    case IS_LOADING_FLAG:
      return {
        ...state,
        isLoading: action.payload
      };
    default:
      return state;
  }
}

export default sagaMonitor;
