import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';

import {requestCoUserInfo, requestEnUserInfo} from '../../actions/user';
import {requestPostNewMessage} from '../../actions/message';

import styles from './messageCreator.module.scss';
import MainTitle from '../MainTitle';
import CreateMessageForm from '../Form/en/CreateMessageForm';

const MessageCreator = ({match}) => {
  const dispatch = useDispatch();
  const userID = match.params.id;
  const location = useLocation();
  const {title, content} = location.state;

  //自身の情報
  const isEngineer = useSelector(state => state.setting.isEngineer);

  //相手の情報
  const userInfo = isEngineer
    ? useSelector(state => state.user.coUser)
    : useSelector(state => state.user.enUser);

  useEffect(() => {
    isEngineer
      ? dispatch(requestCoUserInfo({toId: userID}))
      : dispatch(requestEnUserInfo({id: userID}));
  }, []);

  const handleSubmit = value => {
    value['to_id'] = userID;
    dispatch(requestPostNewMessage(value));
  };

  return (
    <div className={styles.main}>
      <MainTitle titleStr={'メッセージを送る'} pattern={false} />
      <CreateMessageForm
        onSubmit={handleSubmit}
        userInfo={userInfo}
        initialValues={{title: title, content: content}}
      />
    </div>
  );
};

export default MessageCreator;
