import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import {getFormValues, reduxForm} from 'redux-form';
import {makeStyles} from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import MainTitle from '../../../../components/MainTitle';
import {
  requestPrivateCommentList,
  requestNextPrivateCommentList,
  requestPrivateCommentCreate,
  requestCreateAdditionalText,
  requestProjectCommentList
} from '../../../../actions/comment';
import style from './detail.module.scss';
import SwipeableViewsWrapper from '../../../../components/SwipeableViewsWrapper';
import TaskDetail from '../../../../components/Task/TaskDetail';
import Question from '../../../../components/Task/Question';
import Arrow from '../../../../assets/icon_arrow_up.svg';
import CommonCommentForm from '../../../../components/Form/CommonCommentForm/';
import EntryDetail from '../../../../components/EntryDetail';
import Review from '../../../../components/Review';
import Complete from '../../../../components/Complete';
import {AvatarImg, TASK_STATUS, userType} from '../../../../common';
import Blank from '../../../../components/Blank';
import EditButton from '../../../../components/Button/EditButton';
import RelatedStatusInfo from '../../../../components/RelatedStatusInfo';
import Caption from '../../../../components/Caption';
import CommentItem from '../../../../components/CommentItem';
import {setReviewModalFlag} from '../../../../actions/review';
import {useMediaQuery} from 'react-responsive';
import Divider from '@material-ui/core/Divider';
import CommonTabs from '../../../../components/CommonTabs';
import CommonLink from '../../../../components/CommonLink';
import Link from '@material-ui/core/Link';
import StatusLabel from '../../../../components/StatusLabel';

const Detail = ({taskId}) => {
  const dispatch = useDispatch();
  const params = useParams();
  const {pathname} = useLocation();
  const {
    comments,
    offset,
    count,
    next,
    commonCommentText,
    privateCommentAttachedFiles
  } = useSelector(state => state.comment);
  let {isEngineer, isAnonymousUser} = useSelector(state => state.setting);
  if (isAnonymousUser) isEngineer = true;
  const myId = useSelector(state => state.setting.me.id);
  const {detail, isAllowReading} = useSelector(state => state.task);
  const isMobile = useMediaQuery({query: '(max-width: 600px)'});
  const [index, setIndex] = useState(0);
  const [isReviewWaiting, setIsReviewWaiting] = useState(false);
  const _ = useSelector(state => {
    return getFormValues('commonCommentForm')(state);
  });
  const history = useHistory();
  const setting = useSelector(state => state.setting);
  const isMyCompanyTask = setting.me.company === detail.owner.company;
  const isWorker = setting.me.id === detail.worker;
  const isRelatedUser = isMyCompanyTask || isWorker;

  useEffect(() => {
    // タスクが作業中以降であれば、タブを「作業メモ」にフォーカスする。
    setIndex(detail.status > TASK_STATUS.wanted && isRelatedUser ? 2 : 0);
    reviewModalHandler(detail, isEngineer, dispatch, isRelatedUser);
    setIsReviewWaiting(setIsReviewWaitingFlag(detail, isEngineer));
  }, []);

  useEffect(() => {
    reviewModalHandler(detail, isEngineer, dispatch, isRelatedUser);
  }, [detail.status, isRelatedUser]);

  useEffect(() => {
    setIsReviewWaiting(setIsReviewWaitingFlag(detail, isEngineer));
  }, [detail.finished_review_status]);

  useEffect(() => {
    if (detail.status > TASK_STATUS.wanted && isRelatedUser) {
      dispatch(
        requestPrivateCommentList({
          id: taskId,
          next: next
        })
      );
    }
  }, [detail, isRelatedUser]);

  const handleChange = (event, value) => {
    setIndex(value);
  };

  const handleSwipeableChange = value => {
    setIndex(value);
  };

  // お互いの height が引っ張られてしまうので、見えていないタブの要素は none にする。
  const detailDisplay = index === 0 ? 'block' : 'none';
  const questionDisplay = index === 1 ? 'block' : 'none';
  const workMemoDisplay = index === 2 ? 'flex' : 'none';

  const useStyles = makeStyles({
    flexContainer: {
      justifyContent: 'space-around'
    },
    root: {
      maxWidth: 'none',
      minWidth: 'none'
    },
    divider: {
      height: '0.2rem',
      background: '#093791',
      marginTop: '1.5rem'
    },
    tab: {
      color: '#00A8F0',
      fontSize: '1.8rem',
      backgroundColor: 'white',
      '&:hover': {
        backgroundColor: '#ebebeb'
      },
      width: '33.33%'
    },
    moreFetchButton: {
      width: '100%',
      borderRadius: 'unset',
      color: '#093791'
    },
    disabled: {
      color: '#999999'
    }
  });

  const classes = useStyles();

  const slideStyle = {
    slide: {
      padding: 15,
      minHeight: 100,
      color: '#222222'
    },
    slideDetail: {
      backgroundColor: '#FFFFFF',
      display: detailDisplay
    },
    slideQuestion: {
      backgroundColor: '#FFFFFF',
      display: questionDisplay
    },
    slideWorkMemo: {
      backgroundColor: '#FFFFFF',
      display: workMemoDisplay,
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%'
    }
  };
  const mobileSlideStyle = {
    slide: {
      padding: 15,
      minHeight: 100,
      color: '#222222'
    },
    slideDetail: {
      backgroundColor: '#FFFFFF'
    },
    slideQuestion: {
      backgroundColor: '#FFFFFF'
    },
    slideWorkMemo: {
      backgroundColor: '#FFFFFF',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      padding: '0rem'
    }
  };

  return (
    <div className={style.contents}>
      <div>
        <div className={style.main}>
          <Review />
          <Complete />
          <div className={style.mainTitleContainer}>
            <RenderTitle
              detail={detail}
              isEngineer={isEngineer}
              history={history}
              pathname={pathname}
              isMyCompanyTask={isMyCompanyTask}
            />
          </div>
          <div className={style.Tabs}>
            <section>
              <StatusLabel />
              {isRelatedUser && (
                <RelatedStatusInfo
                  isRelatedUser={isRelatedUser}
                  detail={detail}
                  isEngineer={isEngineer}
                  myId={myId}
                  isReviewWaiting={isReviewWaiting}
                  isMyCompanyTask={isMyCompanyTask}
                />
              )}
            </section>

            {isMobile ? (
              <>
                <EntryDetail
                  detail={detail}
                  isEngineer={isEngineer}
                  isAnonymousUser={isAnonymousUser}
                  isMyCompanyTask={isMyCompanyTask}
                />
                <Blank height={'5rem'} />
                <Divider />
              </>
            ) : (
              <></>
            )}
            <CommonTabs onChange={handleChange} index={index}>
              <Tab label="案件詳細" classes={{textColorInherit: classes.tab}} />
              <Tab
                label="質問"
                classes={{
                  textColorInherit: classes.tab,
                  disabled: classes.disabled
                }}
                disabled={
                  (!isRelatedUser && detail.status > TASK_STATUS.wanted) ||
                  isAnonymousUser
                }
              />
              <Tab
                label="作業メモ"
                classes={{
                  textColorInherit: classes.tab,
                  disabled: classes.disabled
                }}
                disabled={
                  !(isRelatedUser && detail.status > TASK_STATUS.wanted) ||
                  isAnonymousUser
                }
              />
            </CommonTabs>
            <div className={style.Tabs}>
              <SwipeableViewsWrapper
                index={index}
                onChangeIndex={handleSwipeableChange}
                animateHeight={true}
              >
                <div
                  style={
                    isMobile
                      ? {...slideStyle.slide, ...mobileSlideStyle.slideDetail}
                      : {...slideStyle.slide, ...slideStyle.slideDetail}
                  }
                >
                  <TaskDetail
                    detail={detail}
                    isEngineer={isEngineer}
                    isAnonymousUser={isAnonymousUser}
                    isMyCompanyTask={isMyCompanyTask}
                  />
                </div>
                <div
                  style={
                    isMobile
                      ? {...slideStyle.slide, ...mobileSlideStyle.slideQuestion}
                      : {...slideStyle.slide, ...slideStyle.slideQuestion}
                  }
                >
                  <Question params={params} />
                </div>
                <div
                  style={
                    isMobile
                      ? {...slideStyle.slide, ...mobileSlideStyle.slideWorkMemo}
                      : {...slideStyle.slide, ...slideStyle.slideWorkMemo}
                  }
                >
                  <Caption>
                    「アサインされたエンジニア」と「タスクを発注した企業のユーザ」のみが閲覧可能です
                  </Caption>
                  <Blank height={'1.6rem'} />
                  <CommonLink
                    onClick={() =>
                      history.push(
                        `/${userType(isEngineer)}/project/${params.projectId}`
                      )
                    }
                    underline={'always'}
                    color={'red'}
                    size={'small'}
                  >
                    他のタスクのユーザと相談する場合はコチラ
                  </CommonLink>
                  <div style={{height: '1.5rem'}} />
                  <WorkMemo
                    comments={comments}
                    detail={detail}
                    classes={classes}
                    next={next}
                    isAllowReading={isAllowReading}
                    params={params}
                  />
                </div>
              </SwipeableViewsWrapper>
            </div>
          </div>
        </div>
        <Blank height={'5rem'} />
        {/*<AdditionalTextForm*/}
        {/*  isEngineer={isEngineer}*/}
        {/*  detail={detail}*/}
        {/*  classes={classes}*/}
        {/*  onSubmit={values =>*/}
        {/*    dispatch(*/}
        {/*      requestCreateAdditionalText({*/}
        {/*        id: detail.id,*/}
        {/*        text: values.commonCommentText,*/}
        {/*        attachedFiles: values.attachedFiles*/}
        {/*      })*/}
        {/*    )*/}
        {/*  }*/}
        {/*  privateCommentAttachedFiles={privateCommentAttachedFiles}*/}
        {/*/>*/}
      </div>
      {isMobile ? (
        <></>
      ) : (
        <EntryDetail
          detail={detail}
          isEngineer={isEngineer}
          isAnonymousUser={isAnonymousUser}
          isMyCompanyTask={isMyCompanyTask}
        />
      )}
    </div>
  );
};

const RenderTitle = ({
  detail,
  isEngineer,
  history,
  pathname,
  isMyCompanyTask
}) => {
  if (detail.status === TASK_STATUS.wanted && !isEngineer && isMyCompanyTask) {
    return (
      <MainTitle
        titleStr={detail.title}
        pattern={false}
        component={
          <EditButton
            text={'編集する'}
            onClick={() => history.push(`${pathname}/edit`)}
          />
        }
      />
    );
  } else if (
    detail.status === TASK_STATUS.under_review &&
    !isEngineer &&
    isMyCompanyTask
  ) {
    return (
      <MainTitle
        titleStr={detail.title}
        pattern={false}
        component={
          <EditButton
            text={'編集する'}
            onClick={() => history.push(`${pathname}/draft-edit`)}
          />
        }
      />
    );
  } else {
    return <MainTitle titleStr={detail.title} pattern={true} />;
  }
};

const AdditionalTextForm = ({isEngineer, detail, classes, onSubmit}) => {
  if (detail.status === TASK_STATUS.wanted && !isEngineer) {
    return (
      <>
        <MainTitle titleStr={'追記'} pattern={false} />
        <div className={style.privateMsg}>
          <CommonCommentForm
            detail={detail}
            classes={classes}
            onSubmit={onSubmit}
            initialValues={{
              commonCommentText: '',
              attachedFiles: []
            }}
          />
        </div>
      </>
    );
  } else {
    return <></>;
  }
};

//　TODO: 命名は暫定
const WorkMemo = ({
  comments,
  detail,
  isAllowReading,
  classes,
  next,
  params
}) => {
  if (isAllowReading && detail.status > TASK_STATUS.wanted) {
    const dispatch = useDispatch();
    return (
      <div className={style.workMemoContainer}>
        <ul>
          {comments.length >= 5 ? (
            <li className={style.mainPrivateMemoHeader}>
              <Button
                variant="text"
                component="span"
                onClick={() =>
                  dispatch(
                    requestNextPrivateCommentList({id: params.id, next: next})
                  )
                }
                classes={{root: classes.moreFetchButton}}
              >
                <img src={Arrow} />
                <p className={style.pastPrivateMsgTxt}>過去の5件を表示</p>
              </Button>
            </li>
          ) : null}
          {comments
            .slice()
            .reverse()
            .map((comment, index) => (
              <CommentItem
                key={index.toString()}
                comment={comment}
                classes={classes}
              />
            ))}
        </ul>
        <div className={style.privateMsg}>
          <CommonCommentForm
            detail={detail}
            classes={classes}
            onSubmit={values => {
              dispatch(
                requestPrivateCommentCreate({
                  id: detail.id,
                  text: values.text,
                  attachedFiles: values.attachedFiles
                })
              );
            }}
            initialValues={{
              commonCommentText: '',
              attachedFiles: []
            }}
          />
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

const setIsReviewWaitingFlag = (detail, isEngineer) => {
  // 相手のレビューを待っている状態かどうかのフラグを生成する
  const {isEngineerFinished, isCompanyFinished} = detail.finished_review_status;
  if (isEngineer) {
    return isEngineerFinished && isCompanyFinished === false;
  } else {
    return isCompanyFinished && isEngineerFinished === false;
  }
};

const reviewModalHandler = (detail, isEngineer, dispatch, isRelatedUser) => {
  const {isEngineerFinished, isCompanyFinished} = detail.finished_review_status;
  const isReviewed = isEngineer ? isEngineerFinished : isCompanyFinished;
  // 自分がレビュー書いてない状態なら評価モーダル出現
  if (detail.status === TASK_STATUS.checked && !isReviewed && isRelatedUser) {
    dispatch(setReviewModalFlag(true));
  }
};

export default reduxForm({
  enableReinitialize: true,
  form: 'task',
  multipartForm: true
})(Detail);
