import {all} from 'redux-saga/effects';
import Account from './account';
import CoSetting from './co_setting';
import Company from './company';
import Notification from './notification';
import Project from './project';
import Category from './category';
import Reset from './reset';
import Tag from './tag';
import Setting from './setting';
import Sign from './sign';
import Task from './task';
import Comment from './comment';
import entry from './entry';
import bookmark from './bookmark';
import Payment from './payment';
import suggest from './suggest';
import format from './format';
import Assign from './assign';
import personalNews from './personalNews';
import SystemNews from './systemNews';
import Member from './member';
import TaskList from './taskList';
import Valuation from './valuation';
import firebase from './firebase';
import message from './message';
import user from './user';
import CoTaskList from './coTaskList';
import confirm from './confirm';
import Review from './review';
import complete from './complete';
import modify from './modify';
import Reward from './reward';
import statusPatch from './patchStatus';
import common from './common';
import myCompany from './myCompany';

export default function* rootSaga() {
  yield all([
    ...Account,
    ...CoSetting,
    ...Company,
    ...Notification,
    ...Project,
    ...Reset,
    ...Tag,
    ...Setting,
    ...Sign,
    ...Task,
    ...Category,
    ...Comment,
    ...entry,
    ...bookmark,
    ...Payment,
    ...suggest,
    ...format,
    ...Assign,
    ...personalNews,
    ...SystemNews,
    ...Member,
    ...TaskList,
    ...Valuation,
    ...firebase,
    ...message,
    ...user,
    ...CoTaskList,
    ...confirm,
    ...Review,
    ...complete,
    ...modify,
    ...Reward,
    ...statusPatch,
    ...common,
    ...myCompany
  ]);
}
