import {COLOR_PALLET} from '../../constants';

export const SIZE = {
  small: {
    fontSize: '1.2rem'
  },
  medium: {
    fontSize: '1.6rem'
  }
};

export const COLOR = {
  red: {
    color: COLOR_PALLET.red
  },
  blue: {
    color: COLOR_PALLET.blue900
  }
};
