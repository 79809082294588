import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Field} from 'redux-form';
import {NavLink, useHistory} from 'react-router-dom';
import style from './signupForm.module.scss';
import {renderText} from '../Field/index';
import classNames from 'classnames';
import SubTitle from '../SubTitle';
import {requestPaymentList} from '../../actions/payment';
import CommonButton from '../CommonButton';
import {subTitle} from '../HelpButtons/constants';

// material-ui
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {API_HOST, SCHEME} from '../../config';

//validate関数
import * as Validator from './utils/validate';
import GitHubIcon from '../../assets/img_github.svg';
import {makeStyles} from '@material-ui/core/styles';
import FormError from './FormError';
import Blank from '../Blank';

const useStyles = makeStyles({
  link: {
    color: '#ea397d',
    display: 'flex'
  }
});

const SignUpForm = props => {
  const classes = useStyles();
  const {
    error,
    submitErrors,
    pristine,
    submitting,
    handleSubmit,
    formValues,
    change,
    invalid
  } = props;
  const [status, setStatus] = useState(false);
  const [value, setValue] = useState('same');
  const dispatch = useDispatch();
  const history = useHistory();
  const ORIGIN = window.location.origin.toString();

  useEffect(() => {
    dispatch(requestPaymentList());
  }, []);

  return (
    <>
      <div className={style.form}>
        <div className={style.formContent}>
          <div>
            <form onSubmit={handleSubmit}>
              <SubTitle headerText={'企業情報'} />
              <div className={style.item}>
                <div className={style.position}>
                  <p className={style.itemTitle}>企業名</p>
                  <div className={style.required} />
                  <div className={style.formArea}>
                    <div className={classNames(style.fieldContainer)}>
                      <p className={style.fieldLabel}>企業名</p>
                      <div className={style.normalTextField}>
                        <Field
                          name="name"
                          required={true}
                          component={renderText}
                          fullWidth={true}
                          placeholder="株式会社 m-Lab"
                          validate={[Validator.RequiredItem]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={style.position}>
                  <div />
                  <div className={style.formArea}>
                    <div className={classNames(style.fieldContainer)}>
                      <p className={style.fieldLabel}>カナ</p>
                      <div className={style.normalTextField}>
                        <Field
                          name="company_name_kana"
                          required={true}
                          component={renderText}
                          fullWidth={true}
                          placeholder="カブシキガイシャ エムラボ"
                          validate={[Validator.RequiredItem]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={style.item}>
                <div className={style.position}>
                  <p className={style.itemTitle}>電話番号</p>
                  <div className={style.required} />
                  <div
                    className={classNames(
                      style.formArea,
                      style.phoneNumberForm
                    )}
                  >
                    <div className={style.formPhoneNumber}>
                      <Field
                        name="tel1"
                        required={true}
                        component={renderText}
                        placeholder="000"
                        fullWidth={true}
                        validate={[Validator.RequiredInteger]}
                      />
                    </div>
                    <hr />
                    <div className={style.formPhoneNumber}>
                      <Field
                        name="tel2"
                        required={true}
                        component={renderText}
                        placeholder="0000"
                        fullWidth={true}
                        validate={[Validator.RequiredInteger]}
                      />
                    </div>
                    <hr />
                    <div className={style.formPhoneNumber}>
                      <Field
                        name="tel3"
                        required={true}
                        component={renderText}
                        placeholder="0000"
                        fullWidth={true}
                        validate={[Validator.RequiredInteger]}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className={style.item}>
                <div className={style.position}>
                  <p className={style.itemTitle}>公式HP</p>
                  <div className={style.required} />
                  <div className={style.formArea}>
                    <div className={classNames(style.form)}>
                      <div className={style.normalTextField}>
                        <Field
                          name="url1"
                          required={true}
                          component={renderText}
                          fullWidth={true}
                          placeholder="https://www.abcdef.com"
                          validate={[Validator.RequiredItem]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={style.subTitle}>
                <SubTitle
                  headerText={'管理者情報'}
                  helpText={subTitle.adminInfo}
                />
              </div>

              <div className={style.item}>
                <div className={style.position}>
                  <p className={style.itemTitle}>お名前</p>
                  <div className={style.required} />
                  <div className={style.formArea}>
                    <div
                      className={classNames(
                        style.fieldContainer,
                        style.fullName,
                        style.formName
                      )}
                    >
                      <p className={style.fieldLabel}>氏名</p>
                      <div className={style.minimumTextField}>
                        <Field
                          name="last_name_kanji"
                          required={true}
                          component={renderText}
                          placeholder="田中"
                          fullWidth={true}
                          validate={[Validator.RequiredItem]}
                        />
                      </div>
                      <div style={{width: '2rem'}} />
                      <div className={style.minimumTextField}>
                        <Field
                          name="first_name_kanji"
                          required={true}
                          component={renderText}
                          placeholder="花子"
                          fullWidth={true}
                          validate={[Validator.RequiredItem]}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className={style.position}>
                  <div className={style.lowerNameBlank} />
                  <div
                    className={classNames(
                      style.fullName,
                      style.formName,
                      style.lowerName
                    )}
                  >
                    <p className={style.fieldLabel}>カナ</p>
                    <div className={style.minimumTextField}>
                      <Field
                        name="last_name_kana"
                        required={true}
                        component={renderText}
                        placeholder="タナカ"
                        fullWidth={true}
                        validate={[Validator.RequiredItem]}
                      />
                    </div>
                    <div style={{width: '2rem'}} />
                    <div className={style.minimumTextField}>
                      <Field
                        name="first_name_kana"
                        required={true}
                        component={renderText}
                        placeholder="ハナコ"
                        fullWidth={true}
                        validate={[Validator.RequiredItem]}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className={style.item}>
                <div className={style.position}>
                  <p className={style.itemTitle}>メールアドレス</p>
                  <div className={style.required} />
                  <div className={style.formArea}>
                    <div className={classNames(style.form)}>
                      <div className={style.normalTextField}>
                        <Field
                          name="email"
                          required={true}
                          component={renderText}
                          fullWidth={true}
                          placeholder="abcdef@mail.com"
                          validate={[Validator.RequiredItem]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={style.text}>
                <div className={style.required2}></div>
                <Link
                  classes={{root: classes.link}}
                  target="_blank"
                  rel={'noopener noreferrer'}
                  href={`${ORIGIN}/terms/co`}
                >
                  利用規約
                  <div className={style.linkImage} />
                </Link>
                <Link
                  classes={{root: classes.link}}
                  className={style.link}
                  target="_blank"
                  rel={'noopener noreferrer'}
                  href={`${ORIGIN}/policy`}
                >
                  プライバシーポリシー
                  <div className={style.linkImage} />
                </Link>
                <div className={style.agree}>
                  <span>に同意します</span>
                  <div
                    className={style.checkBox}
                    onClick={() => setStatus(preStatus => !preStatus)}
                  >
                    {status ? (
                      <CheckCircleIcon className={style.checkedCircleIcon} />
                    ) : (
                      <CheckCircleIcon className={style.checkCircleIcon} />
                    )}
                  </div>
                </div>
              </div>
              <Blank height={'2.4rem'} />
              <FormError error={error} />
              <div className={style.submit}>
                <CommonButton
                  text={'登録する'}
                  type={'submit'}
                  size={'medium'}
                  color={'pink'}
                  variant={'contained'}
                  shape={'normal'}
                  disabled={
                    pristine || submitting || invalid || status == false
                  }
                />
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className={style.toSingIn}>
        <Button
          className={style.button}
          variant="outlined"
          onClick={() => history.push('/signin')}
        >
          ログイン画面へ戻る
        </Button>
      </div>
      <div className={style.aboutTask}>
        <Link
          target="_blank"
          rel={'noopener noreferrer'}
          href={`${ORIGIN}/faq/task`}
        >
          ▶ Task とは？
        </Link>
      </div>
    </>
  );
};

export default SignUpForm;
