import React, {useEffect} from 'react';
import {Route, Switch} from 'react-router-dom';
import BaseInfoEditForm from './BaseInfoEditForm';
import {requestCompany} from '../../../actions/company';
import {useDispatch, useSelector} from 'react-redux';
import CompanyEdit from '../../../containers/co/Company';
import {requestMyCompanyInfo} from "../../../actions/myCompany";

const CompanyEditRouter = () => {
  const dispatch = useDispatch();

  const {companyId} = useSelector(state => state.setting);

  useEffect(() => {
    if (companyId) {
      dispatch(requestMyCompanyInfo());
    }
  }, [companyId]);

  return (
    <Switch>
      <Route
        exact
        path="/co/info/edit/base"
        render={() => <BaseInfoEditForm />}
      />
      <Route exact path="/co/info/edit/other" render={() => <CompanyEdit />} />
    </Switch>
  );
};

export default CompanyEditRouter;
