import React from 'react';
import styles from '../../commonModal.module.scss';
import IconSearch from '../../../../assets/icon_task_search_blue.svg';
import {setModalSetting, requestTaskCancel} from '../../../../actions/task';

import CommonTaskButton from '../../../Buttons/CommonTaskButton';
import {TASK_STATUS} from '../../../../common';
import {useDispatch, useSelector} from 'react-redux';

const TaskCancelConfirm = () => {
  const dispatch = useDispatch();
  const {detail} = useSelector(state => state.task);

  return (
    <div className={styles.inner}>
      <div className={styles.header}>
        <img className={styles.image} src={IconSearch} />
        <div className={styles.subTitle}>募集取り下げの確認</div>
      </div>
      <div className={styles.message}>
        <p>取り下げたタスクは元に戻すことができません。</p>
        <p>本当にこの募集をキャンセルしてよろしいですか？</p>
      </div>
      <div className={styles.button}>
        <CommonTaskButton
          text="戻る"
          onClickHandler={() => {
            dispatch(
              setModalSetting({type: 'cancelConfirm', isVisible: false})
            );
          }}
          kind={'blue'}
        />
        <CommonTaskButton
          text="実行"
          onClickHandler={() => {
            dispatch(requestTaskCancel(detail.id));
          }}
          kind={'pink'}
        />
      </div>
    </div>
  );
};

export default TaskCancelConfirm;
