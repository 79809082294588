import React from 'react';

import MenuAppBar from '../MenuAppBar';
import PageSubTitle from '../PageSubTitle';
import FooterPage from '../Footer/FooterPage';
import useScrollToTop from '../../hooks/useScrollToTop';

import styles from './terms.module.scss';

const Terms = ({children}) => {
  useScrollToTop();
  return (
    <div className={styles.rootContainer}>
      <MenuAppBar />
      <div className={styles.pageTitleContainer}>
        <PageSubTitle title="利用規約" />
      </div>
      <div className={styles.termsContainer}>{children}</div>
      <FooterPage />
    </div>
  );
};

export default Terms;
