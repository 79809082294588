import React from 'react';
import {useDispatch} from 'react-redux';

import styles from './requestModal.module.scss';
import ICON_TRIANGLE from '../../assets/icon_triangle.svg';

import {requestPreAssign} from '../../actions/assign';
import SubmitButton from '../Button/SubmitButton';
import {AvatarImg} from '../../common';

const Request = ({name, onClick, userId, taskId, userImg}) => {
  const dispatch = useDispatch();
  const handleSubmit = () => {
    dispatch(requestPreAssign({worker: userId, taskId: taskId}));
  };

  return (
    <>
      <div className={styles.header}>
        <div className={styles.subTitle}>業務を依頼する</div>
      </div>
      <div className={styles.profile}>
        {/*TODO: 画像が入ったらstyle消す*/}
        <div className={styles.image}>
          <AvatarImg img={userImg} size="small" />
        </div>
        <div className={styles.name}>{name}</div>
        {/*TODO: role追加待ち*/}
        <div className={styles.role}>システムエンジニア</div>
      </div>
      <div className={styles.button}>
        <SubmitButton
          text="決定"
          onClick={e => {
            handleSubmit();
            e.stopPropagation();
          }}
        />
      </div>
      <div
        className={styles.triangle}
        onClick={() => {
          onClick();
        }}
      >
        <img src={ICON_TRIANGLE} />
        選び直す
      </div>
    </>
  );
};

export default Request;
