import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import {makeStyles} from '@material-ui/core/styles';
import {SimpleAlert} from './../Alert';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: '6rem',
    [theme.breakpoints.down('sm')]: {
      left: 'auto'
    }
  }
}));

const Index = ({alertSeverity, isOpen, text, snackPlacement, onClose}) => {
  const {vertical, horizontal} = snackPlacement;
  const classes = useStyles();

  return (
    <Snackbar
      autoHideDuration={4000}
      open={isOpen}
      onClose={onClose}
      anchorOrigin={{vertical, horizontal}}
      className={classes.root}
    >
      <SimpleAlert onClose={onClose} severity={alertSeverity} text={text} />
    </Snackbar>
  );
};

export default Index;
