import React from 'react';
import styles from './notAvailable.module.scss';
import RoundedButton from '../../components/Buttons/RoundedButton';
import SearchTaskIcon from '../../assets/icon_task_search.svg';
import {useSelector} from 'react-redux';
import {NavLink, useHistory} from 'react-router-dom';
import Box from '@material-ui/core/Box';
import ProjectDetailImage from '../../assets/project_description.png';
import classNames from 'classnames';
import Blank from '../Blank';
import Link from '@material-ui/core/Link';

const displayTitle = (kind, isInputedIdInfo = null) => {
  const history = useHistory();
  switch (kind) {
    case 'workingTasks':
      return (
        <div>
          <p className={styles.title}>タスクが見つかりません</p>
          <div className={classNames(styles.message, styles.messageCentering)}>
            <p>お探しのタスクは存在しないか</p>
            <p>既に他のエンジニアが作業中です</p>
          </div>
        </div>
      );
    case 'notExistsTasks':
      return (
        <div>
          <p className={styles.title}>受注可能なタスクがありません</p>
          <div className={classNames(styles.message, styles.messageCentering)}>
            <p>現在、受注可能なタスクがありません。</p>
            <p>企業がタスクを発注するまでお待ちください。</p>
          </div>
        </div>
      );
    case 'task-search':
      return (
        <>
          <p className={styles.title}>受注中のタスクはありません</p>
          <div className={styles.message}>
            <p>「仕事を探す」画面から、お仕事にエントリーして下さい。</p>
            <br />
            {isInputedIdInfo ? (
              <>
                <p>
                  本人確認情報の入力が完了している為、すぐにお仕事の受注が可能です
                </p>
              </>
            ) : (
              <>
                <p>
                  本人確認情報の入力が完了後にお仕事の受注が可能になります。
                </p>
                <p>
                  入力は
                  <NavLink
                    exact
                    to="/en/user/edit/id-info"
                    className={styles.link}
                  >
                    こちら
                  </NavLink>
                  から行うことができます。
                </p>
                <br />
                <p>お仕事情報の閲覧については、いつでもご利用可能です。</p>
              </>
            )}
          </div>
        </>
      );
    case 'task-create':
      return (
        <>
          <p className={styles.title}>タスクがありません</p>
        </>
      );
    case 'project-create':
      return (
        <>
          <p className={styles.title}>プロジェクトがありません</p>
          <div className={styles.roundedButton}>
            <RoundedButton kind={kind} />
          </div>
          <p className={classNames(styles.message)}>
            <Box fontWeight="fontWeightBold" m={0} component={'span'}>
              「プロジェクト（案件の入れ物）」
            </Box>
            を新規作成する事で、
            <Box fontWeight="fontWeightBold" m={0} component={'span'}>
              「タスク（案件）」
            </Box>
            を作成する事ができる様になります。
            <br />
            プロジェクトは
            <Box fontWeight="fontWeightBold" m={0} component={'span'}>
              親
            </Box>
            、タスクは
            <Box fontWeight="fontWeightBold" m={0} component={'span'}>
              子
            </Box>
            として扱われ、タスクに対してのみエンジニアをアサインすることができます。
            <br />
            <br />
            発注にお困りの方は、Task運営がサポートさせて頂きますので{' '}
            <strong>画面右下のチャット</strong> または{' '}
            <Link
              onClick={e => {
                history.push('/contact/main');
                e.preventDefault();
              }}
              href="#"
            >
              お問い合わせフォーム
            </Link>
            より
            <br />
            お気軽にお問い合わせ下さい。
          </p>
          <Blank height={'3rem'} />
          <div className={styles.imgContainer}>
            <img
              alt={'プロジェクトイメージ'}
              src={ProjectDetailImage}
              className={styles.projectImg}
            />
          </div>
        </>
      );
    case 'only-project':
      return (
        <>
          <p className={styles.title}>タスクがありません</p>
          <p className={styles.message}>
            プロジェクトは存在しますが、まだタスクが作られていません。
          </p>
        </>
      );
    case 'add-member':
      return <p className={styles.title}>メンバーがいません</p>;
    case 'no-entry':
      return (
        <>
          <p className={styles.title}>エントリーしているエンジニアがいません</p>
          <p className={styles.message}>エントリーがあるまでお待ちください。</p>
        </>
      );
    case 'no-message':
      return <p className={styles.title}>メッセージがありません</p>;
    case 'NO_SELECT_TALKROOM':
      return <p className={styles.title}>メッセージルームが未選択です</p>;
    case 'notExistsPaymentMethod':
      return (
        <>
          <p className={styles.title}>お支払い方法が設定されていません</p>
          <p className={styles.message}>
            クレジットカードか請求書払いを設定してください。
          </p>
        </>
      );
    case 'NO_PAID_TASKS':
      return <p className={styles.title}>完了しているタスクがありません</p>;
    default:
  }
};

const NotAvailable = ({kind}) => {
  const {isInputedIdInfo} = useSelector(state => state.setting.me);
  return (
    <div className={styles.rootContainer}>
      <div className={styles.Container}>
        {displayTitle(kind, isInputedIdInfo)}
        {kind !== 'project-create' && <RoundedButton kind={kind} />}
      </div>
    </div>
  );
};

export default NotAvailable;
