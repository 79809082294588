import React from 'react';
import {Field, reduxForm} from 'redux-form';
import style from '../../Review/review.module.scss';
import Button from '../../Buttons/EvalButton';
import StarRatingInput from './StarRatingInput';
import ReviewTextField from './ReviewTextField';
import * as Validator from '../utils/validate';
import {renderTextArea} from '../../../components/Field';
import Blank from '../../Blank';

const BASE_CATEGORIES = {
  company: [
    {
      category: 'スケジュール',
      text: 'スケジュールは守っていましたか？',
      name: 'review1'
    },
    {
      category: 'レスポンス',
      text: '回答速度や回答率は満足のいくものでしたか？',
      name: 'review2'
    },
    {
      category: 'リピート',
      text: 'また依頼したいと思えましたか？',
      name: 'review3'
    },
    {
      category: '説明力',
      text: '説明や会話はわかりやすかったですか？',
      name: 'review4'
    },
    {
      category: 'クオリティ',
      text: '成果物の品質はどうでしたか？',
      name: 'review5'
    }
  ],
  engineer: [
    {
      category: 'スケジュール',
      text: 'スケジュールに見合った内容でしたか？',
      name: 'review1'
    },
    {
      category: 'レスポンス',
      text: '回答速度や回答率は満足のいくものでしたか？',
      name: 'review2'
    },
    {
      category: 'リピート',
      text: 'また依頼を受けたいと思えましたか？',
      name: 'review3'
    },
    {
      category: '安定感',
      text: '要件のブレや仕様変更などは許容の範囲でしたか？',
      name: 'review4'
    },
    {
      category: '協力',
      text: 'タスクの進行に協力的でしたか？',
      name: 'review5'
    }
  ]
};

const ReviewForm = ({handleSubmit, isEngineer}) => {
  return (
    <form onSubmit={handleSubmit}>
      {BASE_CATEGORIES[isEngineer ? 'engineer' : 'company'].map(
        (item, index) => {
          return (
            <div className={style.flex} key={index.toString()}>
              <div className={style.ratingContainer} key={index}>
                <p className={style.ratingTitle}>{item.category}</p>
                <p className={style.ratingDetail}>{item.text}</p>
                <Field
                  name={item.name}
                  component={StarRatingInput}
                  validate={[Validator.RequiredItem]}
                />
              </div>
            </div>
          );
        }
      )}
      <Blank height={'2rem'} />
      <p className={style.textAreaHeader}>
        <span>レビューを記入してください</span>
      </p>
      <Blank height={'2rem'} />
      <div className={style.textFormContainer}>
        <Field
          name={'comment'}
          placeholder={'特に何か優れていたか詳細にレビューをお願いいたします。'}
          rows={4}
          component={renderTextArea}
          validate={[Validator.RequiredItem]}
        />
        <Blank height={'3rem'} />
        <Button text={'評価する'} />
      </div>
    </form>
  );
};

export default reduxForm({
  form: 'review'
})(ReviewForm);
