import React from 'react';
import {useHistory} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import {COLOR, SIZE} from './constants';
import Link from '@material-ui/core/Link';

const CommonLink = ({to, size, color, children, underline, onClick}) => {
  const useStyles = makeStyles({
    link: {
      ...SIZE[size],
      ...COLOR[color]
    }
  });
  const classes = useStyles();
  return (
    <Link
      component="button"
      onClick={onClick}
      underline={underline}
      className={classes.link}
    >
      {children}
    </Link>
  );
};

export default CommonLink;
