import {createAction} from 'redux-actions';

// ユーザのイメージ or 企業のロゴ などで「変更したかどうか」をセットする
export const SET_IS_IMG_CHANGED = 'SET_IS_IMG_CHANGED';
export const setIsImgChanged = createAction(SET_IS_IMG_CHANGED);

export const REQUEST_DOWNLOAD_AUTH_FILE = 'REQUEST_DOWNLOAD_AUTH_FILE';
export const requestDownloadAuthFile = createAction(REQUEST_DOWNLOAD_AUTH_FILE);
export const SET_IS_SUBMITTING = 'SET_IS_SUBMITTING';
export const setIsSubmitting = createAction(SET_IS_SUBMITTING);

export const REQUEST_DOWNLOAD_CORS_FILE = 'REQUEST_DOWNLOAD_CORS_FILE';
export const requestDownloadCorsFile = createAction(REQUEST_DOWNLOAD_CORS_FILE);

export const REQUEST_CONTACT = 'REQUEST_CONTACT';
export const requestContact = createAction(REQUEST_CONTACT);

export const SET_MODAL_ERROR_MSG = 'SET_MODAL_ERROR_MSG';
export const setModalErrorMsg = createAction(SET_MODAL_ERROR_MSG);
