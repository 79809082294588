import React from 'react';
import classNames from 'classnames';
import {useSelector, useDispatch} from 'react-redux';
import styles from './memberListItem.module.scss';
import TrashBox from '../../assets/icon_trashbox.svg';
import Email from '../../assets/icon_email_into_circle.svg';
import formatDate from '../../components/FormatDate';
import Button from '@material-ui/core/Button';
import {RemoveCandidateMember} from '../../actions/member';
import {useHistory} from 'react-router-dom';
import {AvatarImg} from '../../common';
import {PERMISSIONS} from '../../constants';
import CommonLabel from '../CommonLabel';
import List from '@material-ui/core/List';
import DefaultListItem from '../../components/Lists/ListItems/DefaultListItem';

const MemberListItem = props => {
  const {member, handleOnClick, me} = props;
  const dispatch = useDispatch();
  const history = useHistory();

  const handleRemove = (item, e) => {
    //ゴミ箱を押されたを取り除く候補者の配列に入れる
    dispatch(RemoveCandidateMember(item));
    handleOnClick();
    e.stopPropagation();
  };

  return (
    <List>
      {member.map((item, index) => (
        <DefaultListItem
          button
          key={index}
          onClick={() => {
            history.push(`/co/co-user/${item.id}`);
          }}
        >
          <div className={styles.contentItem}>
            <div className={styles.profileImage}>
              {item.isActive ? (
                <AvatarImg img={item.img} size="verySmall" />
              ) : (
                <AvatarImg img={Email} size="verySmall" />
              )}
            </div>
            <p className={styles.name}>
              {item.isActive ? item.name : item.email}
            </p>
            {/*{item.permission === PERMISSIONS.superUser && (*/}
            {/*  <CommonLabel*/}
            {/*    color={'pink'}*/}
            {/*    text={'管理者'}*/}
            {/*    shape={'normal'}*/}
            {/*    size={'medium'}*/}
            {/*    addStyle={{marginLeft: '2rem'}}*/}
            {/*  />*/}
            {/*)}*/}
          </div>
          <div
            className={classNames(styles.contentItem, styles.contentItemRight)}
          >
            {item.name !== '' ? (
              <>
                <div className={classNames(styles.date, styles.flex)}>
                  <p className={styles.marginRight}>参加日時</p>
                  <p>{formatDate(item.date_joined, 'date', 'str')}</p>
                </div>
              </>
            ) : (
              <div />
            )}
            {/*管理者権限を持っており、自分以外のユーザにのみ表示*/}
            {me.permission === PERMISSIONS.superUser && item.id !== me.id && (
              <div>
                <Button
                  onClick={e => {
                    handleRemove(item, e);
                  }}
                >
                  <img src={TrashBox} />
                </Button>
              </div>
            )}
          </div>
        </DefaultListItem>
      ))}
    </List>
  );
};

export default MemberListItem;
