import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styles from '../rewardAdmin.module.scss';
import AppBar from '../../../components/MenuAppBar';
import SelectTabs from '../../../components/SelectTabs';
import {
  requestReceivedRewardAdmin,
  requestScheduledRewardAdmin
} from '../../../actions/reward';
import PageTitle from '../../../components/PageTitle';
import Title from '../../../components/Title';
import ScheduledRewardTask from '../../../components/Task/TaskLists/Rewards/ScheduledRewardTask';
import RecievedRewardTask from '../../../components/Task/TaskLists/Rewards/RecievedRewardTask';
import Blank from '../../../components/Blank';
import Paginator from '../../../components/Paginator';

const RewardAdminEn = () => {
  const dispatch = useDispatch();
  const receivedRewardList = useSelector(
    state => state.rewardAdmin.receivedReward
  );
  const scheduledRewardList = useSelector(
    state => state.rewardAdmin.scheduledReward
  );

  useEffect(() => {
    dispatch(requestReceivedRewardAdmin(1));
    dispatch(requestScheduledRewardAdmin(1));
  }, []);

  return (
    <>
      <AppBar />
      <div className={styles.container}>
        <div className={styles.titleContainer}>
          <PageTitle title="報酬管理" />
        </div>
        <main className={styles.content}>
          <SelectTabs
            tabLeft="受取り済み"
            tabRight="受け取り予定"
            leftContent={
              <ReceivedItems receivedRewardList={receivedRewardList} name={0} />
            }
            rightContent={
              <ScheduledItems
                scheduledRewardList={scheduledRewardList}
                name={1}
              />
            }
            redDots={false}
          />
        </main>
        <div className={styles.container}>
          <div className={styles.title}>
            <Title component="h2" text="報酬の振込について" />
          </div>
          <div className={styles.rewardAttention}>
            <div>
              <p>
                Taskでは、報酬確定後15日、月末の月2回の締日を設けており、
                <br />
                それぞれ当月末、翌月15日にメンバーの皆さまにご登録いただいている銀行口座に
                <br />
                報酬額をお振り込みさせていただいております。
              </p>
              <strong>
                ご登録されている口座情報に相違があり、振込が完了しなかった場合も振込手数料が発生いたします。
                <br />
                そのため、必ずご入力いただいた口座情報に相違がないことをご確認の上、ご登録をお願いいたします。
              </strong>
              <p>
                なお、振込予定日が土・日・祝日の場合は
                直前の営業日にて振込をさせて頂きます。
              </p>
              {/*<p>*/}
              {/*  ※発生した振込手数料は「次回出金時に出金される報酬金額から引かれる」ため、あらかじめ未出金の報酬金額より振込手数料分が引かれた金額が「未出金合計金額」に記載されるようにしております。*/}
              {/*</p>*/}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const ReceivedItems = ({receivedRewardList}, props) => {
  const dispatch = useDispatch();
  const {count} = receivedRewardList;
  return (
    <>
      <div className={styles.taskList}>
        {receivedRewardList.results.length === 0 && (
          <div className={styles.noTask}>該当するタスクがありません</div>
        )}
        {receivedRewardList.results.map((item, index) => {
          return <RecievedRewardTask item={item} key={index} index={index} />;
        })}
        <Paginator
          limit={10}
          total={count}
          offset={receivedRewardList.offset}
          handleClickPagenate={page => dispatch(requestReceivedRewardAdmin(page))}
        />
      </div>
    </>
  );
};

const ScheduledItems = ({scheduledRewardList}, props) => {
  const dispatch = useDispatch();
  const {count} = scheduledRewardList;
  return (
    <>
      <div className={styles.taskList}>
        {scheduledRewardList.results.length === 0 && (
          <div className={styles.noTask}>該当するタスクがありません</div>
        )}
        {scheduledRewardList.results.map((item, index) => {
          return <ScheduledRewardTask item={item} key={index} index={index} />;
        })}
        <Paginator
          limit={10}
          total={count}
          offset={scheduledRewardList.offset}
          handleClickPagenate={page => dispatch(requestScheduledRewardAdmin(page))}
        />
      </div>
    </>
  );
};

export default RewardAdminEn;
