import React, {FC} from 'react';
// import messageReplyFormStyle from './messageReplyForm.module.scss';
import {
  Field,
  getFormSubmitErrors,
  reduxForm,
  InjectedFormProps
} from 'redux-form';
import {renderTextArea, renderCalender} from '../../Field';
import * as Validator from '../utils/validate';
import formStyle from '../form.module.scss';
import CommonButton from '../../CommonButton';
import Blank from '../../Blank';
import commonStyles from '../../../common/common.module.scss';

const ExtendClosingDateForm: FC<InjectedFormProps> = ({
  handleSubmit,
  submitting,
  error
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <div className={commonStyles.centeringContainer}>
        <Field
          name="closing_date"
          component={renderCalender}
          validate={[Validator.RequiredItem]}
        />
      </div>
      <Blank height={'2.4rem'} width={null} />
      <div className={formStyle.alignCentering}>
        <p className={formStyle.error}>{error}</p>
        <CommonButton
          icon={null}
          type={'submit'}
          text={'延長する'}
          size={'medium'}
          color={'blue'}
          variant={'contained'}
          shape={'normal'}
          disabled={submitting}
        />
      </div>
    </form>
  );
};

export default reduxForm({
  form: 'ExtendClosingDateForm'
})(ExtendClosingDateForm);
