import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import {addDays} from 'date-fns';

// action
import {
  requestCreditEditUrl,
  requestSecureCreditLimitUrl
} from '../../../actions/payment';
import {requestRewardDetail, requestTaskDetail} from '../../../actions/task';
// my
import styles from './secureCreditCardLimit.module.scss';
import PageTitle from '../../../components/PageTitle';
import ContentTitle from '../../../components/ContentTitle';
import Blank from '../../../components/Blank';
import OutlineButton from '../../../components/Buttons/OutlineButton';
import formatDate from '../../../components/FormatDate';
import classNames from 'classnames';

import style from '../../../components/PasswordResetConfirm/passwordResetConfirm.module.scss';
import PasswordResetConfirmForm from '../../../components/Form/PasswordResetConfirmForm';
import store from 'store';
import Link from '@material-ui/core/Link';

const PAYMENT_METHOD = {
  0: 'クレジットカード',
  1: 'Paid'
};

const PaymentSetting = () => {
  const company = useSelector(state => state.myCompany);
  const dispatch = useDispatch();
  const companyId = useSelector(state => state.setting.companyId);
  const {payment_method} = company;
  const {creditLimitUrl} = useSelector(state => state.payment);
  const {detail} = useSelector(state => state.task);
  const history = useHistory();
  const location = useLocation();
  const queryParam = location.search;
  const taskId = queryParam.split('=')[1];
  const rewardDetail = useSelector(state => state.task.rewardDetail);
  const ORIGIN = window.location.origin.toString();

  useEffect(() => {
    dispatch(requestTaskDetail({id: taskId}));
    dispatch(requestSecureCreditLimitUrl(taskId));
    store.remove('isMovingSettlement');
  }, []);

  useEffect(() => {
    if (detail.reward) {
      dispatch(
        requestRewardDetail({
          reward: detail.reward,
          coupon: detail.coupon_info.id
        })
      );
    }
  }, [detail]);

  return (
    <div>
      <PageTitle title="クレジットカードの仮払い" />
      <Blank height={'2rem'} />
      <Link
        href={`${ORIGIN}/faq/pre_payment`}
        target={'_blank'}
        rel={'noopener noreferrer'}
      >
        ▶ 仮払い とは？
      </Link>
      <div className={styles.container}>
        <ContentTitle title="お支払い詳細" isButton={false} />
        <Blank height={20} />
        <div className={styles.itemContainer}>
          <div className={styles.titleContainer}>
            <div className={styles.comNameContainer}>報酬金額</div>
            <div className={styles.infoContainer}>
              {detail.reward
                ? '¥ ' + detail.reward.toLocaleString()
                : 'Loading...'}
            </div>
          </div>
        </div>
        <div className={styles.itemContainer}>
          <div className={styles.titleContainer}>
            <div className={styles.comNameContainer}>システム手数料</div>
            <div className={styles.infoContainer}>
              {rewardDetail.system_fee
                ? '¥ ' + rewardDetail.system_fee.toLocaleString()
                : 'Loading...'}
            </div>
          </div>
        </div>
        <div className={styles.itemContainer}>
          <div className={styles.titleContainer}>
            <div className={styles.comNameContainer}>消費税</div>
            <div className={styles.infoContainer}>
              {rewardDetail.tax
                ? '¥ ' + rewardDetail.tax.toLocaleString()
                : 'Loading...'}
            </div>
          </div>
        </div>
        <div className={styles.itemContainer}>
          <div className={styles.titleContainer}>
            <div className={styles.comNameContainer}>合計金額</div>
            <div className={classNames(styles.infoContainer, styles.sumText)}>
              {rewardDetail.tax
                ? '¥ ' + rewardDetail.sum.toLocaleString()
                : 'Loading...'}
            </div>
          </div>
        </div>
        <Blank height={'4rem'} />
        <ContentTitle title="仮払い詳細" isButton={false} />
        <div className={styles.itemContainer}>
          <div className={styles.titleContainer}>
            <div className={styles.comNameContainer}>仮払い期間</div>
            <div className={styles.infoContainer}>
              {formatDate(new Date(), 'date', 'str')} ~{' '}
              {formatDate(addDays(new Date(), 60), 'date', 'str')}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.confirmContainer}>
        <div className={styles.confirmTextContainer}>
          <p>上記の内容で、仮払いを行います。</p>
          <p>仮払いの期間は最大で60日間です。</p>
          <p>下記のボタンを押下すると、決済画面へ遷移します</p>
          <p>決済後は「サイトに戻る」を押下してください。</p>
        </div>
        <OutlineButton
          kind={'secureCreditLimit'}
          link={creditLimitUrl}
          onClickHandler={() => onClickHandler(creditLimitUrl, taskId)}
        />
      </div>
    </div>
  );
};

const onClickHandler = (creditLimitUrl, taskId) => {
  // 決済画面への遷移時、履歴を残す
  store.set('isMovingSettlement', {status: true, taskId: taskId});
  window.open(creditLimitUrl, '_blank');
};

export default PaymentSetting;
