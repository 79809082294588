import React from 'react';
import style from './snackbar.module.scss';

const SnackbarContent = React.forwardRef((props, ref) => {
  const {
    id,
    message: {text, subText}
  } = props;

  return (
    <div ref={ref} className={style.container}>
      <p>{text}</p>
      <div className={style.subText}>{subText}</div>
    </div>
  );
});

export default SnackbarContent;
