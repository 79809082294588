import {all, call, put, fork, take, select} from 'redux-saga/effects';
import {startSubmit, stopSubmit, reset} from 'redux-form';
import {
  fetchPostJson,
  fetchGet,
  fetchGetBlob,
  fetchPatch
} from './utills/fetch';
import {getBlob} from './utills/simpleFetch';
import {
  requestDownloadAuthFile,
  requestContact,
  requestDownloadCorsFile
} from '../actions/common';
import {history} from '../store';
import {DOC_URL} from '../constants';
import {API_URL} from '../config';

export function* getAuthFile() {
  while (true) {
    const action = yield take(requestDownloadAuthFile);
    const {filename, url} = action.payload;
    const [data, error] = yield call(fetchGetBlob, `${url}`);
    if (data && !error) {
      let url = window.URL.createObjectURL(data);
      let a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      a.remove();
    } else {
      console.error('ファイルのダウンロードに失敗しました。');
    }
  }
}

export function* getCorsProtectFile() {
  while (true) {
    const action = yield take(requestDownloadCorsFile);
    const {filename, url} = action.payload;
    const [data, error] = yield call(getBlob, url, filename);
    if (data && !error) {
    } else {
      console.error('ファイルのダウンロードに失敗しました。');
    }
  }
}

export function* postContact() {
  while (true) {
    const action = yield take(requestContact);
    const isDocDownload = action.payload.contact_type === 0;
    let payload = {};

    if (!isDocDownload) {
      const {tel1, tel2, tel3} = action.payload;
      const tel = `${tel1}-${tel2}-${tel3}`;
      payload = {...action.payload, tel: tel};
    } else {
      payload = action.payload;
    }

    yield put(startSubmit('contact'));
    const [data, error] = yield call(fetchPostJson, `contact`, payload);

    if (data && !error) {
      yield put(stopSubmit('contact'));

      if (isDocDownload) {
        yield call(history.push, '/contact/download-result');
        yield put(
          requestDownloadCorsFile({
            filename: 'Task資料.pdf',
            url: `${API_URL}${DOC_URL}`
          })
        );
      } else {
        history.push('/contact/result');
      }
    } else {
      yield put(
        stopSubmit('contact', {
          ...error.validation_error,
          _error: error.validation_error
        })
      );
    }
  }
}

const common = [fork(getAuthFile), fork(postContact), fork(getCorsProtectFile)];

export default common;
