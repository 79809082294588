import React, {useEffect} from 'react';
import {connect, useDispatch, useSelector} from 'react-redux';
import ReactTags from 'react-tag-autocomplete';
import './styles.scss';
import {requestSuggestList} from '../../actions/suggest';

const TagInput = ({fields, meta: {error, submitFailed}}) => {
  useEffect(() => {
    dispatch(requestSuggestList());
  }, []);

  const dispatch = useDispatch();
  const suggest = useSelector(state => state.suggest.list);

  return (
    <ReactTags
      tags={fields.getAll() ? fields.getAll() : []}
      suggestions={suggest}
      onAddition={tag => fields.push(tag)}
      onDelete={index => fields.remove(index)}
      onValidate={tag => tag.name.length >= 1}
      onInput={query => dispatch(requestSuggestList(query))}
      allowNew={true}
      delimiters={['Enter', 'Tab', ',']}
      placeholderText={'カンマ ( , ) 区切りで入力して下さい'}
      minQueryLength={0}
      maxSuggestionsLength={5}
      autoresize={true}
      addOnBlur={true}
      handleDelete={() => {}}
      handleAddition={() => {}}
    />
  );
};

export default TagInput;
