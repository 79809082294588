import React, {useState, FC} from 'react';
import {useHistory} from 'react-router';
import styles from '../../../containers/co/CoDashboard/coDashboard.module.scss';
import CommonButton from '../../CommonButton';
import PlusButton from '../../../assets/icon_plus_button.svg';
import {getProjectId} from '../../../common';
import Blank from '../../Blank';
import BaseModal from '../../Modal/BaseModal';
import IconSearch from '../../../assets/icon_task_search_blue.svg';
import {renderSelect} from '../../Field';
import {ProjectItemState} from '../../../models/ProjectModels';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';

type Props = {
  projects: ProjectItemState[] | null;
};

const TaskCreateButton: FC<Props> = ({projects}) => {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const initialValue = '0';
  const [projectId, setProjectId] = useState<string>(initialValue);
  if (projects && projects.length === 1) {
    return (
      <>
        <div className={styles.createButtonContainer}>
          <CommonButton
            icon={PlusButton}
            text={'タスクを作成する'}
            size={'large'}
            color={'blue'}
            variant={'outlined'}
            shape={'radius'}
            onClick={() => {
              history.push(`/co/project/${getProjectId(projects)}/task/create`);
            }}
            disabled={false}
          />
        </div>
        <Blank height={'3.2rem'} width={null} />
      </>
    );
  } else if (projects && projects.length > 0) {
    return (
      <>
        <div className={styles.createButtonContainer}>
          <CommonButton
            icon={PlusButton}
            text={'タスクを作成する'}
            size={'large'}
            color={'blue'}
            variant={'outlined'}
            shape={'radius'}
            onClick={() => {
              setIsOpen(true);
            }}
            disabled={false}
          />
          <BaseModal
            isOpen={isOpen}
            onBackdropClick={() => setIsOpen(false)}
            title={'タスク新規作成'}
            message={'親プロジェクトを選択して下さい。'}
            icon={IconSearch}
          >
            <div className={styles.createButtonInnerContainer}>
              <Box
                component={renderSelect}
                // @ts-ignore
                input={{
                  value: projectId,
                  onChange: (value: string) => setProjectId(value)
                }}
                meta={{touched: true, error: null}}
                m={1}
              >
                <MenuItem value={'0'}>選択してください</MenuItem>
                {projects.map((item, index) => {
                  return (
                    <MenuItem value={item.project_id} key={index.toString()}>
                      {item.title}
                    </MenuItem>
                  );
                })}
              </Box>
              <Blank height={'1rem'} width={null} />
              <CommonButton
                icon={null}
                text={'新規タスクを作成する'}
                size={'small'}
                color={'blue'}
                variant={'contained'}
                shape={'normal'}
                onClick={() => {
                  history.push(`/co/project/${projectId}/task/create`);
                }}
                disabled={projectId === initialValue}
              />
            </div>
          </BaseModal>
        </div>
        <Blank height={'3.2rem'} width={null} />
      </>
    );
  } else {
    return <></>;
  }
};

export default TaskCreateButton;
