import {
  SUCCESS_TASK_LIST,
  SUCCESS_TASK_DETAIL,
  SET_TASK_TAGS,
  DELETE_TASK_TAGS,
  SET_TASK_FILES,
  CLEAN_TASK_ATTACHED,
  DELETE_TASK_FILES,
  SUCCESS_PROCESSING_TASKS,
  SUCCESS_WANTED_TASKS,
  SUCCESS_PROJECT_TASKS,
  SET_IS_ALLOW_READING,
  SUCCESS_DRAFT_TASKS,
  SUCCESS_UNDER_REVIEW_TASKS,
  SET_MODAL_FLAG,
  SET_MODAL_SETTING,
  SET_PRESAVE_TASK,
  SUCCESS_FAILED_TASKS,
  SUCCESS_ALL_TASKS_CO,
  SUCCESS_PRE_ASSIGNED_TASKS,
  SUCCESS_REWARD_DETAIL,
  REQUEST_RESET_TASK,
  SET_REQUIRED_PAY_TASKS
} from '../actions/task';
import store from 'store';

import {SUCCESS_PATCH} from '../actions/confirm';

const initialState = {
  list: [],
  detail: {
    additional_texts: [],
    tags_info: [],
    description: '',
    format_free: '',
    format_info: {name: ''},
    reception: 0,
    status: 0,
    //↓entriesはユーザーがEnの時エントリーしている人の数が入り、Coの時、エントリーしているエンジニア達の配列が入る。
    entries: null,
    //↓エントリーしている時や、ブックマークしているときはそれぞれのテーブルのIDが入る。していないときはnullが入る
    entry_no: null,
    bookmark_no: null,
    delivery_date: null,
    owner: {
      name: '',
      company: null
    },
    worker: {
      name: ''
    },
    worker_info: {
      nick_name: ''
    },
    file_info: [],
    finished_review_status: {
      isCompanyFinished: null,
      isEngineerFinished: null
    }
  },
  rewardDetail: {},
  tags: [],
  attachFiles: [],
  processingTasks: [],
  wantedTasks: [],
  projectTasks: [],
  isAllowReading: false,
  draftTasks: [],
  underReviewTasks: [],
  isOpenModal: false,
  modalSetting: {
    type: '',
    isVisible: false,
    params: {},
    onCloseHandler: null
  },
  preSaveTask: store.get('preSaveTask'),
  failedTasks: [],
  allTasksCo: [],
  preAssignedTasks: [],
  requiredPayTasks: [],
  isLoadingTaskList: true
};

function task(state = initialState, action) {
  switch (action.type) {
    case SUCCESS_TASK_LIST:
      return {
        ...state,
        list: action.payload.results,
        count: action.payload.count,
        isLoadingTaskList: false
      };
    case SUCCESS_TASK_DETAIL:
      return {
        ...state,
        detail: action.payload
      };
    case REQUEST_RESET_TASK:
      return {
        ...state,
        detail: initialState.detail
      };
    case SET_TASK_TAGS:
      return {
        ...state,
        tags: state.tags.concat(action.payload)
      };
    case DELETE_TASK_TAGS:
      return {
        ...state,
        tags: action.payload
      };
    case SET_TASK_FILES:
      let newArray = [];
      for (let i = 0; i < action.payload.length; i++) {
        newArray.push(action.payload[i]);
      }
      return {
        ...state,
        attachFiles: state.attachFiles.concat(newArray)
      };
    case DELETE_TASK_FILES:
      // reducer の再描画のフックの為には filter で配列を作り直す必要がある
      return {
        ...state,
        attachFiles: state.attachFiles.filter(
          (item, index, array) => index !== action.payload
        )
      };
    case CLEAN_TASK_ATTACHED:
      return {
        ...state,
        attachFiles: []
      };
    case SUCCESS_PROCESSING_TASKS:
      return {
        ...state,
        processingTasks: action.payload
      };
    case SUCCESS_WANTED_TASKS:
      return {
        ...state,
        wantedTasks: action.payload
      };
    case SUCCESS_PATCH:
      return {
        ...state,
        detail: action.payload
      };
    case SUCCESS_PROJECT_TASKS:
      return {
        ...state,
        projectTasks: action.payload
      };
    case SET_IS_ALLOW_READING:
      return {
        ...state,
        isAllowReading: action.payload
      };
    case SUCCESS_DRAFT_TASKS:
      return {
        ...state,
        draftTasks: action.payload
      };
    case SUCCESS_UNDER_REVIEW_TASKS:
      return {
        ...state,
        underReviewTasks: action.payload
      };
    case SUCCESS_FAILED_TASKS:
      return {
        ...state,
        failedTasks: action.payload
      };
    case SET_MODAL_FLAG:
      return {
        ...state,
        isOpenModal: action.payload
      };
    case SET_MODAL_SETTING:
      return {
        ...state,
        modalSetting: action.payload
      };
    case SET_PRESAVE_TASK:
      return {
        ...state,
        preSaveTask: action.payload
      };
    case SUCCESS_ALL_TASKS_CO:
      return {
        ...state,
        allTasksCo: action.payload
      };
    case SUCCESS_PRE_ASSIGNED_TASKS:
      return {
        ...state,
        preAssignedTasks: action.payload
      };
    case SUCCESS_REWARD_DETAIL:
      return {
        ...state,
        rewardDetail: action.payload
      };
    case SET_REQUIRED_PAY_TASKS:
      return {
        ...state,
        requiredPayTasks: action.payload
      };
    default:
      return state;
  }
}

export default task;
