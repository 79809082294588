import {
  SET_REVIEW_MODAL_FLAG,
  SUCCESS_MUST_REVIEW_TASKS,
  SUCCESS_CO_REVIEWS,
  SUCCESS_EN_REVIEWS
} from '../actions/review';

const initialState = {
  modalFlag: false,
  tasks: [],
  coReviews: [],
  enReviews: [
    {
      average_rating: null,
      comment: '',
      delivery_date: '',
      reviewer_company_info: true
    }
  ]
};

function review(state = initialState, action) {
  switch (action.type) {
    case SET_REVIEW_MODAL_FLAG:
      return {
        ...state,
        modalFlag: action.payload
      };
    case SUCCESS_MUST_REVIEW_TASKS:
      return {
        ...state,
        tasks: action.payload
      };
    case SUCCESS_CO_REVIEWS:
      return {
        ...state,
        coReviews: action.payload
      };
    case SUCCESS_EN_REVIEWS:
      return {
        ...state,
        enReviews: action.payload
      };
    default:
      return state;
  }
}

export default review;
