import React from 'react';
import {createMuiTheme, makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import style from './button.module.scss';

const Index = props => {
  const {kind, onClickHandler} = props;
  let applyBgColor = '';
  let applyHoverColor = '';
  let applyBorderColor = 'none';
  let valiant = 'contained';
  let applyColor = '#FFFFFF';
  let applyFontWeight = '';
  let text = '';
  let srcImg = null;
  let applyFontSize = '1.6rem';
  let disabled = false;

  switch (kind) {
    case 'pink':
      applyBgColor = '#EA397D';
      applyHoverColor = '#b12a5e';
      applyFontWeight = 'bold';
      text = '応募者を確認';
      break;
    case 'pinkOutlined':
      valiant = 'outlined';
      applyBorderColor = '0.1rem solid #EA397D';
      applyColor = '#EA397D';
      text = '確認依頼を取り消す。';
      applyHoverColor = '#ffe7ef';
      applyFontSize = '1.4rem';
      break;
  }
  const useStyles = makeStyles(theme => ({
    button: {
      margin: theme.spacing(1),
      backgroundColor: applyBgColor,
      border: applyBorderColor,
      color: applyColor,
      fontWeight: applyFontWeight,
      width: '20rem',
      height: '5rem',
      fontSize: applyFontSize,
      '&:hover': {
        backgroundColor: applyHoverColor
      }
    }
  }));

  const classes = useStyles();
  return (
    <div className="stage">
      <Button
        variant={valiant}
        className={classes.button}
        disabled={disabled}
        onClick={() => onClickHandler()}
      >
        <div className={style.buttonContainer}>
          <img src={srcImg} />
          <div className={style.text}>{text}</div>
        </div>
      </Button>
    </div>
  );
};

export default Index;
