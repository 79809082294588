import { createAction } from 'redux-actions';

// ユーザ編集時のタグを一時保存
export const SET_USER_TAGS = 'SET_USER_TAGS';
export const setUserTags = createAction(SET_USER_TAGS);

// ユーザ編集時のタグを一時保存したものを削除
export const DELETE_USER_TAGS = 'DELETE_USER_TAGS';
export const deleteUserTags = createAction(DELETE_USER_TAGS);

// ユーザ編集時のファイルを一時保存
export const SET_USER_FILES = 'SET_USER_FILES';
export const setUserFiles = createAction(SET_USER_FILES);

// ユーザ編集時のファイルを一時保存したものを削除
export const DELETE_USER_FILES = 'DELETE_USER_FILES';
export const deleteUserFiles = createAction(DELETE_USER_FILES);
