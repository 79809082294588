import React, {useState, FC} from 'react';
import {useSelector} from 'react-redux';
import styles from '../../tabs.module.scss';
import SwipeableViews from 'react-swipeable-views';
import {RootState} from '../../../../models/RootModels';
import {readSystemNotification} from '../../../../firebase/systemNotification';
import {readPersonalNotification} from '../../../../firebase/personalNotification';
import PersonalNotificationContent from '../PersonalNotificationContent';
import SystemNotificationContent from '../SystemNotificationContent';
// material-ui
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {makeStyles} from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '2.4rem'
    }
  },
  badge: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%'
  }
}));

const NotificationTabs: FC = () => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const setting = useSelector((state: RootState) => state.setting);
  const username = setting.me.username;

  const {isReadAllPersonalNotification, headPersonalDocument} = useSelector(
    (state: RootState) => state.personalNews
  );
  const {isReadAllSystemNotification, headSystemDocuments} = useSelector(
    (state: RootState) => state.systemNews
  );

  const handleChangeTab = (event: any, index: number) => {
    setValue(index);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  const isActivePersonalDot =
    !isReadAllPersonalNotification && headPersonalDocument.length > 0;
  const isActiveSystemDot =
    !isReadAllSystemNotification && headSystemDocuments.length > 0;

  return (
    <div>
      <div className={styles.parent}>
        <Tabs
          value={value}
          variant={'fullWidth'}
          onChange={handleChangeTab}
          indicatorColor="primary"
          className={styles.containerItem}
          TabIndicatorProps={{
            style: {
              backgroundColor: '#00A8F0'
            }
          }}
        >
          <Tab
            label={
              <Badge
                color="secondary"
                variant="dot"
                invisible={!isActivePersonalDot}
                classes={{root: classes.badge}}
              >
                ユーザー
              </Badge>
            }
            onClick={() => readPersonalNotification(username)}
            classes={{root: classes.root}}
          />

          <Tab
            label={
              <Badge
                color="secondary"
                variant="dot"
                invisible={!isActiveSystemDot}
                classes={{root: classes.badge}}
              >
                システム
              </Badge>
            }
            onClick={() => readSystemNotification()}
            classes={{root: classes.root}}
          />
        </Tabs>
      </div>
      <div>
        <SwipeableViews index={value} onChangeIndex={handleChangeIndex}>
          <PersonalNotificationContent />
          <SystemNotificationContent />
        </SwipeableViews>
      </div>
    </div>
  );
};

export default NotificationTabs;
