import {COLOR_PALLET} from '../../constants';

export const SIZE = {
  minimum: {
    fontSize: '1.2rem',
    width: '12rem',
    height: '3rem'
  },
  preMinimum: {
    fontSize: '1.2rem',
    width: '16rem',
    height: '3rem'
  },
  small: {
    fontSize: '1.6rem',
    width: '20rem',
    height: '5rem'
  },
  medium: {
    fontSize: '1.6rem',
    width: '30rem',
    height: '5.5rem'
  },
  large: {
    fontSize: '1.8rem',
    width: '40rem',
    height: '7rem'
  }
};

export const ICON_SIZE = {
  minimum: {
    width: '1.5rem'
  },
  preMinimum: {
    width: '2rem'
  },
  small: {
    width: ' 2rem'
  },
  medium: {
    width: '2rem'
  },
  large: {
    width: '2rem'
  }
};

export const SHAPE = {
  normal: {},
  radius: {
    borderRadius: '5rem'
  }
};

export const COLOR = {
  contained: {
    pink: {
      backgroundColor: COLOR_PALLET.pink1000,
      color: COLOR_PALLET.white,
      '&:hover': {
        backgroundColor: COLOR_PALLET.pink1000
      }
    },
    blue: {
      backgroundColor: COLOR_PALLET.blue900,
      color: COLOR_PALLET.white,
      '&:hover': {
        backgroundColor: COLOR_PALLET.blue900
      }
    },
    blueLight: {
      backgroundColor: COLOR_PALLET.blueLight,
      color: COLOR_PALLET.white,
      '&:hover': {
        backgroundColor: COLOR_PALLET.blueLight
      }
    },
    white: {
      backgroundColor: COLOR_PALLET.white,
      color: COLOR_PALLET.blue900,
      '&:hover': {
        backgroundColor: '#c3c3c3'
      }
    }
  },
  outlined: {
    pink: {
      border: `0.1rem solid ${COLOR_PALLET.pink1000}`,
      color: COLOR_PALLET.pink1000
    },
    blue: {
      border: `0.1rem solid ${COLOR_PALLET.blue900}`,
      color: COLOR_PALLET.blue900
    }
  }
};
