import React from 'react'
import {Field} from 'redux-form'
import {renderPassword,renderText} from "@/components/Field/index"
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import style from './form.module.scss'

const PasswordReset = (props) => {

  const {error, handleSubmit, submitErrors, pristine, submitting } = props;

  return (
    <div className={style.panel}>
      {error && <p className='error'>{error}</p>}

      <form onSubmit={handleSubmit}>
        <ul>
          <li>
            <div className={style.items}>
              <Field name="password"
                     id="password"
                     required={true}
                     label="パスワード"
                     component={renderPassword}/>
              {submitErrors && submitErrors.password1  && (<FormHelperText><span className='error'>{submitErrors.password1}</span></FormHelperText>)}
            </div>
          </li>
          <li>
            <div className={style.items}>
              <Field name="password2"
                     id="password2"
                     required={true}
                     label="確認用パスワード"
                     component={renderPassword}/>
              {submitErrors && submitErrors.password2  && (<FormHelperText><span className='error'>{submitErrors.password2}</span></FormHelperText>)}
            </div>
          </li>
        </ul>

        <div className="submitBtn">
          <input
            id="submit"
            multiple
            type='submit'
            value={''}
            disabled={pristine || submitting}
          />
          <label htmlFor="submit">
            <Button
              disabled={pristine || submitting}
              variant="contained" component="span" color="secondary">
              更新
            </Button>
          </label>
        </div>
      </form>
    </div>
  )
};

export default PasswordReset
