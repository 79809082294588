import React, {useEffect} from 'react';
import {requestCompany} from '../actions/company';
import {useDispatch, useSelector} from 'react-redux';
import {requestMyCompanyInfo} from "../actions/myCompany";

const useRequestCompanyInfo = () => {
  const dispatch = useDispatch();
  const {companyId} = useSelector(state => state.setting);

  useEffect(() => {
    if (companyId) {
      dispatch(requestMyCompanyInfo());
    }
  }, [companyId]);
};

export default useRequestCompanyInfo;
