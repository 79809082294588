import React from 'react';
import {Field, getFormValues, reduxForm, getFormSubmitErrors} from 'redux-form';
import style from '../paidApplicationForm.module.scss';
import classNames from 'classnames';
import {renderText, renderSelect} from '../../../../Field';
import Required from '../../../../Required';
import MenuItem from '@material-ui/core/MenuItem';
import OutlineButton from './../../../../../components/Buttons/OutlineButton';
import * as Validator from '../../../utils/validate';
import ContentTitle from '../../../../ContentTitle';
import Blank from '../../../../Blank';
import FormError from '../../../FormError';

const PaidEditForm = props => {
  const {
    handleSubmit,
    pristine,
    reset,
    submitting,
    members,
    isOpenPaidFields,
    error
  } = props;

  return (
    <form onSubmit={handleSubmit}>
      <div className={style.container}>
        <div className={style.row}>
          <ContentTitle title="基本情報" />
          <Blank height={'5rem'} />
          <div className={style.rowItem}>
            <p className={style.subTitle}>企業名</p>
            <div className={classNames(style.formArea, style.addressFlex)}>
              <div className={style.titleName}></div>
              <Field
                name="name"
                required={true}
                component={renderText}
                placeholder="山田"
                fullWidth={true}
              />
            </div>
          </div>
          <div className={classNames(style.rowItem, style.rowItemMarginTop)}>
            <p className={style.subTitle} />
            <div className={classNames(style.formArea, style.addressFlex)}>
              <div className={style.titleName}>カナ</div>
              <Field
                name="company_name_kana"
                required={true}
                component={renderText}
                placeholder="ヤマダ"
                fullWidth={true}
              />
            </div>
          </div>
        </div>
        <div className={style.row}>
          <div className={style.rowItem}>
            <p className={style.subTitle}>電話番号</p>
            <div className={classNames(style.formArea, style.addressFlex)}>
              <div className={style.titleName} />
              <div className={style.telsContainer}>
                <div className={classNames(style.mediumWidth)}>
                  <Field
                    name="tel1"
                    required={true}
                    component={renderText}
                    fullWidth={true}
                    placeholder="080"
                    validate={[Validator.RequiredItem]}
                  />
                </div>
                <p className={style.hyphen}>ー</p>
                <div className={classNames(style.mediumWidth)}>
                  <Field
                    name="tel2"
                    required={true}
                    component={renderText}
                    fullWidth={true}
                    placeholder="1234"
                    validate={[Validator.RequiredItem]}
                  />
                </div>
                <p className={style.hyphen}>ー</p>
                <div className={classNames(style.mediumWidth)}>
                  <Field
                    name="tel3"
                    required={true}
                    component={renderText}
                    fullWidth={true}
                    placeholder="5678"
                    validate={[Validator.RequiredItem]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {isOpenPaidFields && <PaidFields members={members} />}
        <Blank height={'2.4rem'} />
        <FormError error={error} />
        <div className={style.buttonContainer}>
          <OutlineButton kind={'applicationPaid'} disabled={pristine} />
        </div>
      </div>
    </form>
  );
};

const PaidFields = ({members}) => {
  return (
    <>
      <div className={style.row}>
        <div className={style.rowItem}>
          <p className={style.subTitle}>代表取締役</p>
          <div className={classNames(style.formArea, style.addressFlex)}>
            <div className={style.titleName}>氏名</div>
            <div className={style.lastName}>
              <Field
                name="representative_sei"
                required={true}
                component={renderText}
                placeholder="山田"
                fullWidth={true}
              />
            </div>
            <div className={style.firstName}>
              <Field
                name="representative_mei"
                required={true}
                component={renderText}
                placeholder="太郎"
                fullWidth={true}
              />
            </div>
          </div>
        </div>
        <div className={classNames(style.rowItem, style.rowItemMarginTop)}>
          <p className={style.subTitle} />
          <div className={classNames(style.formArea, style.addressFlex)}>
            <div className={style.titleName}>カナ</div>
            <div className={style.lastName}>
              <Field
                name="representative_sei_kana"
                required={true}
                component={renderText}
                placeholder="ヤマダ"
                fullWidth={true}
              />
            </div>
            <div className={style.firstName}>
              <Field
                name="representative_mei_kana"
                required={true}
                component={renderText}
                placeholder="タロウ"
                fullWidth={true}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={style.row}>
        <div className={style.rowItem}>
          <p className={style.subTitle}>住所</p>
          <div className={classNames(style.formArea, style.addressFlex)}>
            <div className={style.titleName}>郵便番号</div>
            <div className={style.inputsContainer}>
              <div className={classNames(style.smallWidth)}>
                <Field
                  name="zipCode1"
                  required={true}
                  component={renderText}
                  fullWidth={true}
                  placeholder="123"
                />
              </div>
              <p>ー</p>
              <div className={classNames(style.mediumWidth)}>
                <Field
                  name="zipCode2"
                  required={true}
                  component={renderText}
                  fullWidth={true}
                  placeholder="4567"
                />
              </div>
            </div>
          </div>
        </div>
        <div className={classNames(style.rowItem, style.rowItemMarginTop)}>
          <p className={style.subTitle} />
          <div className={classNames(style.formArea, style.addressFlex)}>
            <div className={style.titleName}>都道府県</div>
            <div className={style.largeWidth}>
              <Field
                name="prefecture"
                required={true}
                component={renderText}
                fullWidth={true}
                placeholder="東京都"
              />
            </div>
          </div>
        </div>

        <div className={classNames(style.rowItem, style.rowItemMarginTop)}>
          <p className={style.subTitle} />
          <div className={classNames(style.formArea, style.addressFlex)}>
            <div className={style.titleName}>市区町村</div>
            <div className={style.largeWidth}>
              <Field
                name="address1"
                required={true}
                component={renderText}
                fullWidth={true}
                placeholder="渋谷区神宮前"
              />
            </div>
          </div>
        </div>

        <div className={classNames(style.rowItem, style.rowItemMarginTop)}>
          <p className={style.subTitle} />
          <div className={classNames(style.formArea, style.addressFlex)}>
            <div className={style.titleName}>番地</div>
            <div className={style.largeWidth}>
              <Field
                name="address2"
                required={true}
                component={renderText}
                fullWidth={true}
                placeholder="0000"
              />
            </div>
          </div>
        </div>

        <div className={classNames(style.rowItem, style.rowItemMarginTop)}>
          <div />
          <div className={classNames(style.formArea, style.addressFlex)}>
            <div className={style.titleName}>建物名</div>
            <div className={style.largeWidth}>
              <Field
                name="address3"
                required={true}
                component={renderText}
                fullWidth={true}
                placeholder="建物名"
              />
            </div>
          </div>
        </div>
      </div>
      <div className={style.row}>
        <div className={style.rowItem}>
          <p className={style.subTitle}>部署名/支店名</p>
          <div className={style.formArea}>
            <div className={classNames(style.form)}>
              <Field
                name="aux_name"
                required={true}
                component={renderText}
                fullWidth={true}
                placeholder="システム開発部"
              />
            </div>
          </div>
        </div>
      </div>
      <div className={style.row}>
        <div className={style.rowItem}>
          <p className={style.subTitle}>メールアドレス</p>
          <div className={style.formArea}>
            <div className={classNames(style.form)}>
              <Field
                name="email"
                required={true}
                component={renderText}
                fullWidth={true}
                placeholder="sample@task.info"
              />
            </div>
          </div>
        </div>
      </div>
      <div className={style.row}>
        <div className={style.rowItem}>
          <p className={style.subTitle}>担当者</p>
          <Required />
          <div className={style.formArea}>
            <div className={classNames(style.form)}>
              <div className={style.smallWidth}>
                <Field
                  name="clerk"
                  required={true}
                  component={renderSelect}
                  fullWidth={true}
                  placeholder="sample@task.info"
                  validate={[Validator.RequiredItem]}
                >
                  <MenuItem value="">選択してください</MenuItem>
                  {members.map((elem, index) => (
                    <MenuItem value={elem.id} key={index.toString()}>
                      {elem.name}
                    </MenuItem>
                  ))}
                </Field>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default reduxForm({
  form: 'paidApplication'
})(PaidEditForm);
