import React from 'react';
import BaseModal from '../BaseModal';
import CommonButton from '../../CommonButton';
import CautionIcon from '../../../assets/icon_caution.svg';
import Blank from '../../Blank';
import {requestAcceptPreAssign} from '../../../actions/assign';
import {useDispatch} from 'react-redux';

const AssignAcceptModal = ({isOpen, setIsOpen, taskId}) => {
  const dispatch = useDispatch();
  return (
    <BaseModal
      title={'アサイン依頼承諾についての注意'}
      message={
        'このタスクにエントリーした時点の報酬金額と、現在の報酬金額が異なっています。\nアサイン依頼を承諾しますか？\n一度承諾すると取り消しはできません。'
      }
      icon={CautionIcon}
      isOpen={isOpen}
      onBackdropClick={() => setIsOpen(false)}
    >
      <CommonButton
        text={'閉じる'}
        size={'small'}
        color={'blue'}
        variant={'contained'}
        shape={'normal'}
        onClick={() => setIsOpen(false)}
        disabled={false}
      />
      <Blank width={'1.6rem'} />
      <CommonButton
        text={'承諾する'}
        size={'small'}
        color={'pink'}
        variant={'contained'}
        shape={'normal'}
        onClick={() => dispatch(requestAcceptPreAssign({taskId: taskId}))}
        disabled={false}
      />
    </BaseModal>
  );
};

export default AssignAcceptModal;
