import {createAction} from 'redux-actions';

export const REQUEST_MEMBER = 'REQUEST_MEMBER';
export const SUCCESS_MEMBER = 'SUCCESS_MEMBER';
export const requestMember = createAction(REQUEST_MEMBER);
export const successMember = createAction(SUCCESS_MEMBER);

export const REQUEST_SPECIFIC_MEMBER = 'REQUEST_SPECIFIC_MEMBER';
export const SUCCESS_SPECIFIC_MEMBER = 'SUCCESS_SPECIFIC_MEMBER';
export const requestSpecificMember = createAction(REQUEST_SPECIFIC_MEMBER);
export const successSpecificMember = createAction(SUCCESS_SPECIFIC_MEMBER);

export const REQUEST_INVITE_MEMBER = 'REQUEST_INVITE_MEMBER';
export const SUCCESS_INVITE_MEMBER = 'SUCCESS_INVITE_MEMBER';
export const requestInviteMember = createAction(REQUEST_INVITE_MEMBER);
export const successInviteMember = createAction(SUCCESS_INVITE_MEMBER);

export const REQUEST_REMOVE_MEMBER = 'REQUEST_REMOVE_MEMBER';
export const SUCCESS_REMOVE_MEMBER = 'SUCCESS_REMOVE_MEMBER';
export const requestRemoveMember = createAction(REQUEST_REMOVE_MEMBER);
export const successRemoveMember = createAction(SUCCESS_REMOVE_MEMBER);

export const REMOVE_CANDIDATE_MEMBER = 'REMOVE_CANDIDATE_MEMBER';
export const RemoveCandidateMember = createAction(REMOVE_CANDIDATE_MEMBER);

export const SET_MODAL_FLAG = 'SET_MODAL_FLAG';
export const setModalFlag = createAction(SET_MODAL_FLAG);

export const SET_SECOND_MODAL_FLAG = 'SET_SECOND_MODAL_FLAG';
export const setSecondModalFlag = createAction(SET_SECOND_MODAL_FLAG);
