import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import {connect} from 'react-redux'
import {NavLink} from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles';
import style from './menu.module.scss'

// component
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';


import Menu from '../../components/DrawerMenu';

// img
import IconBell from "../../assets/icon_header_bell.svg";
import Logo from '../../assets/logo.svg'

const drawerWidth = 240;

const styles = theme => ({
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    }
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    backgroundColor: '#fff'
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    color: '#5d5959',
    backgroundColor: '#093791'
  }
});

class AppLayout extends React.Component {

  state = {
    mobileOpen: false,
  };

  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }));
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={style.root}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="primary"
              aria-label="Open drawer"
              className={classes.menuButton}
              onClick={this.handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
            <NavLink exact to="/co/" className={style.logo}>
              <img src={Logo}/>
            </NavLink>
            <IconButton
              // aria-owns={open ? 'menu-appbar' : undefined}
              aria-haspopup="true"
              color="inherit"
              onClick={this.handleClose}
              className={style.iconButton}
            >
              <NavLink exact to="/co/notification">
                <img src={IconBell}/>
              </NavLink>
            </IconButton>
          </Toolbar>
        </AppBar>
        <nav className={classes.drawer}>
          <Hidden smUp implementation="css">
            <Drawer
              container={this.props.container}
              variant="temporary"
              anchor={'left'}
              open={this.state.mobileOpen}
              onClose={this.handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              <Menu/>
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              <Menu/>
            </Drawer>
          </Hidden>
        </nav>
        <main className={style.main}>
          <div className={style.content}>
            <div className={classes.toolbar} />
            {this.props.children}
          </div>
          {/*<Footer/>*/}
        </main>
      </div>
    );
  }
}


AppLayout.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(null, null)
)(AppLayout);
