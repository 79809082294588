import React from 'react';
import Button from './Button';
import {useDispatch, useSelector} from 'react-redux';
import {patchConfirm} from '../../../actions/confirm';

const SubmitCancelButton = props => {
  const dispatch = useDispatch();
  const id = props.id;

  const requestPatchConfirm = () => {
    dispatch(patchConfirm({task: id, status: {status: 4}}));
  };

  const onClickHandler = {
    patch: requestPatchConfirm
  };

  return (
    <div>
      <Button kind={'gray'} onClickHandler={() => {}} />
      {/* ↑企業が確認するまでお待ちください的なsnackbarありかも 2次以降 */}
      {/* 後回し */}
      {/* <Button
        kind={'pinkOutlined'}
        onClickHandler={() => onClickHandler.patch()}
      /> */}
    </div>
  );
};

export const ConfirmCancelButton = props => {
  const id = props.id;
  return (
    <div>
      <SubmitCancelButton id={id} />
    </div>
  );
};
