import React from 'react';
import StarRatingComponent from 'react-star-rating-component';
import style from './starRatingInput.module.scss';
import formStyle from '../../../../components/Form/form.module.scss';

const StartRatingInput = ({input, meta}) => {
  const {value, onChange} = input;
  const {touched, error} = meta;
  const intValue = parseInt(value);

  return (
    <div>
      <div className={style.starContainer}>
        <StarRatingComponent
          name={input.name}
          starCount={5}
          value={intValue}
          starColor={'#FFCE00'}
          emptyStarColor={'#DDDDDD'}
          onStarClick={(nextValue, prevValue, name) => onChange(nextValue)}
        />
      </div>
      <span className={formStyle.error}>{touched && error}</span>
    </div>
  );
};

export default StartRatingInput;
