import React from 'react';
import IconButton from '@material-ui/core/IconButton';

import IconTrashBox from '../../../assets/icon_trashbox.svg';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  trashButton: {
    width: 'unset'
  }
}));

const DeleteButton = ({onClick}) => {
  const classes = useStyles();

  return (
    <IconButton
      aria-label="delete"
      onClick={onClick}
      className={classes.trashButton}
    >
      <img src={IconTrashBox} />
    </IconButton>
  );
};

export default DeleteButton;
