import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styles from './user.module.scss'

import { requestSpecificMember } from '../../../actions/member'

import AppBar from '../../../components/MenuAppBar'
import CoMenu from '../../layout/CoMenu'
import Breadcrumbs from '../../../components/Breadcrumbs'
import FooterPage from '../../../components/Footer/FooterPage'
import PageTitle from '../../../components/PageTitle'
import ContentTitle from '../../../components/ContentTitle'
import ContentText from '../../../components/ContentText'

const User = ({ match }) => {
  const companyId = match.params.id;
  const userId = match.params.userId;
  const dispatch = useDispatch();
  const member = useSelector(state => state.member);

  useEffect(() => {
    dispatch(requestSpecificMember({companyId, userId}))
  }, []);

  return (
    <>
      <AppBar />
      <div className={styles.container}>
        <CoMenu />
        <div className={styles.content}>
          <Breadcrumbs currentPage={[{'id': 1, 'page': 'プロフィール', 'link': `/co/${companyId}/user/${userId}/`}]} />
          <div className={styles.main}>
            <PageTitle title="プロフィール" />
            <div className={styles.contentItem}>
              <ContentTitle title="基本情報" />
              <div className={styles.profile}>
                {/*TODO: styleは画像いれたら消す*/}
                <div style={{backgroundColor:'#91D720'}} className={styles.profileImage}>
                  <img src="" />
                </div>
                <div className={styles.profileText}>
                  <ContentText title="お名前" content={member.name} />
                  <ContentText title="フリガナ" content={member.kana} />
                  <ContentText title="職業" content={member.position} />
                  <ContentText title="所属企業" content={member.company} />
                </div>
              </div>
            </div>
          </div>
          <FooterPage />
        </div>
      </div>
    </>
  )
};

export default User
