import {createAction} from 'redux-actions';

// ブックマーク一覧
export const REQUEST_BOOKMARK_LIST = 'REQUEST_BOOKMARK_LIST';
export const SUCCESS_BOOKMARK_LIST = 'SUCCESS_BOOKMARK_LIST';
export const requestBookmarkList = createAction(REQUEST_BOOKMARK_LIST);
export const successBookmarkList = createAction(SUCCESS_BOOKMARK_LIST);

// ブックマーク一覧に追加
export const REQUEST_POST_BOOKMARK = 'REQUEST_POST_BOOKMARK';
export const requestPostBookmark = createAction(REQUEST_POST_BOOKMARK);

// ブックマーク取り消し
export const REQUEST_DELETE_BOOKMARK = 'REQUEST_DELETE_BOOKMARK';
export const requestDeleteBookmark = createAction(REQUEST_DELETE_BOOKMARK);
