import React from 'react';
import style from '../../../containers/co/Task/Detail/detail.module.scss';
import Button from '@material-ui/core/Button';
import {
  requestNextPrivateCommentList,
  requestProjectCommentList
} from '../../../actions/comment';
import Arrow from '../../../assets/icon_arrow_up.svg';
import {makeStyles} from '@material-ui/core/styles';
import Blank from '../../Blank';
import {useDispatch} from 'react-redux';

const useStyles = makeStyles(theme => ({
  button: {
    display: 'flex',
    justifyContent: 'center',
    background: '#ffffff',
    border: '0.1rem solid #d7e2f4',
    boxSizing: 'border-box',
    borderBottom: 'none',
    fontSize: '1.4rem',
    lineHeight: '2rem',
    color: '#093791',
    borderRadius: 0
  }
}));

const MemoHeaderButton = ({onClick}) => {
  const classes = useStyles();
  return (
    <Button
      classes={{root: classes.button}}
      variant="text"
      component="span"
      onClick={onClick}
    >
      <img alt={'arrow'} src={Arrow} />
      <Blank width={'1rem'} />
      <p className={style.pastPrivateMsgTxt}>過去の5件を表示</p>
    </Button>
  );
};

export default MemoHeaderButton;
