import React, {useState, Fragment} from 'react';
import {
  PAYMENT_METHODS,
  PAYMENT_STATUS_CREDIT,
  PAYMENT_STATUS_PAID
} from '../../../constants';
import {DisableButton} from './Button';
import {useDispatch, useSelector} from 'react-redux';
import EntryButton from '../EntryButton';
import BookmarkButton from '../BookmarkButton';
import {ConfirmButton} from '../ConfirmButton';
import {ConfirmCancelButton} from '../ConfirmCancelButton';
import {AssignButton} from '../AssignButton';
import CommonTaskButton from '../CommonTaskButton';
import {requestTaskEdit, setModalSetting} from '../../../actions/task';
import {CompleteButton} from '../CompleteButton';
import {ModifyRequestButton} from '../ModifyRequestButton';
import {EvaluateTaskButton} from '../EvaluateTaskButton';
import {useHistory, useLocation} from 'react-router';
import {
  requestPaymentUsingPaid,
  requestPaymentUsingCredit
} from '../../../actions/payment';
import style from './taskDetailButtons.module.scss';
import CommonButton from '../../CommonButton';
import commonStyles from '../../../common/common.module.scss';
import AssignAcceptModal from '../../Modal/AssignAcceptModal';
import {getIsExpired, isDeadlineComingSoon} from '../../../common/funcs';
import {history, store as reduxStore} from '../../../store';
import ExtendClosingDateModal from '../../modals/ExtendClosingDateModal';
// タスク詳細画面の右上に表示するbutton達(EntryButtonやBookmarkButton)の親コンポーネント

// Taskdetailbuttons関数コンポーネント
export const TaskDetailButtons = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const detail = useSelector(state => state.task.detail);
  const setting = useSelector(state => state.setting);
  const {pathname} = useLocation();

  const status = detail.status;
  const entryUserList = detail.entries;
  const isEngineer = props.isEngineer;

  if (props.isAnonymousUser) {
    return (
      <div>
        <CommonButton
          text={'新規会員登録'}
          size={'small'}
          color={'pink'}
          variant={'contained'}
          shape={'normal'}
          onClick={() => history.push('/signin')}
          disabled={false}
        />
      </div>
    );
  }

  if (isEngineer === false && !props.isMyCompanyTask) {
    return (
      <div>
        <CommonButton
          text={'他社のタスクです'}
          disabled={true}
          size={'small'}
          color={'pink'}
          variant={'contained'}
          shape={'normal'}
        />
      </div>
    );
  }

  switch (status) {
    // ↓initialstateは0(taskデータをgetする前。)
    case 0:
      return <p>読み込み中です</p>;

    case 2:
      if (detail.is_task_checked) {
        return (
          <CommonTaskButton
            kind={'pink'}
            onClickHandler={() => {
              history.push(`/co/payment/cardlimit?taskId=${detail.id}`);
            }}
            marginValue={'0.3rem'}
            text={'仮売上げを行う'}
          />
        );
      } else {
        return <DisableButton kind={'examination'} />;
      }
    case 3:
      return (
        <SwitchWantedButton
          detail={detail}
          dispatch={dispatch}
          isEngineer={isEngineer}
          entryUserList={entryUserList}
          taskId={props.id}
          myId={setting.me.id}
          entryTasks={setting.me.tasks}
          history={history}
          pathname={pathname}
          isMyCompanyTask={props.isMyCompanyTask}
        />
      );
    case 4:
      return (
        <>
          {isEngineer ? (
            <CommonTaskButton
              kind={'pink'}
              onClickHandler={() => {
                dispatch(
                  setModalSetting({
                    type: 'REQUEST_TASK_REVIEW',
                    isVisible: true,
                    params: {taskId: props.id}
                  })
                );
              }}
              marginValue={'0.3rem'}
              text={'成果物確認依頼'}
            />
          ) : (
            <DisableButton kind={'engineerWorking'} />
          )}
        </>
      );
    case 5:
      return (
        <>
          {isEngineer && <ConfirmCancelButton id={props.id} />}
          {isEngineer || (
            <div>
              <CompleteButton id={props.id} />
              <ModifyRequestButton id={props.id} />
            </div>
          )}
        </>
      );

    case 6:
      return (
        <>
          {isEngineer ? (
            <CommonTaskButton
              kind={'pink'}
              onClickHandler={() => {
                dispatch(
                  setModalSetting({
                    type: 'REQUEST_TASK_REVIEW',
                    isVisible: true,
                    params: {taskId: props.id}
                  })
                );
              }}
              marginValue={'0.3rem'}
              text={'修正物確認依頼'}
            />
          ) : (
            <DisableButton kind={'engineerModify'} />
          )}
        </>
      );

    case 7:
      const reviewStatusKey = isEngineer
        ? 'isEngineerFinished'
        : 'isCompanyFinished';
      const myReviewStatus = detail.finished_review_status[reviewStatusKey];
      // 自分がレビュー完了している場合はボタンを非活性にする
      return (
        <>
          <EvaluateTaskButton disabled={myReviewStatus} />
        </>
      );
    case 8:
      return (
        <CommonTaskButton
          id={props.id}
          kind={'blue'}
          disabled={true}
          marginValue={'0.3rem'}
          text={'完了しました'}
        />
      );
    case 10:
      return (
        <SwitchSettleButton
          paymentMethod={detail.payment_method}
          detail={detail}
        />
      );
  }
};

const SwitchWantedButton = ({
  entryUserList,
  detail,
  isEngineer,
  taskId,
  dispatch,
  myId,
  entryTasks,
  history,
  pathname,
  isMyCompanyTask
}) => {
  const isExpired = getIsExpired(detail.closing_date);
  // エンジニアがアサイン済みなのに募集中のタスクは仮売上げが必要
  const isRequiredAuthori = detail.worker !== null;
  const isWaitingAuthori = isEngineer && myId === detail.worker;

  if (isEngineer) {
    if (myId === detail.pre_worker) {
      const entryDetail = entryTasks.find(
        item => Number(item.task_id) === Number(taskId)
      );
      const didChangeReward =
        entryDetail && entryDetail.reward !== detail.reward;
      const [isOpen, setIsOpen] = useState(false);
      // 自分が仮アサインされているパターン
      return (
        <div>
          <AssignAcceptModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            taskId={taskId}
          />
          {didChangeReward ? (
            <CommonTaskButton
              id={taskId}
              kind={'pink'}
              onClickHandler={() => {
                setIsOpen(true);
              }}
              marginValue={'0.3rem'}
              text={'アサイン依頼を承諾'}
            />
          ) : (
            <CommonTaskButton
              id={taskId}
              kind={'pink'}
              onClickHandler={() => {
                dispatch(
                  setModalSetting({
                    type: 'ACCEPT_ASSIGN_OFFER',
                    isVisible: true
                  })
                );
              }}
              marginValue={'0.3rem'}
              text={'アサイン依頼を承諾'}
            />
          )}
          <div>
            <CommonTaskButton
              id={taskId}
              kind={'blue'}
              onClickHandler={() => {
                dispatch(
                  setModalSetting({
                    type: 'REJECT_ASSIGN_OFFER',
                    isVisible: true
                  })
                );
              }}
              marginValue={'0.3rem'}
              text={'アサイン依頼を辞退'}
            />
          </div>
        </div>
      );
    } else if (isWaitingAuthori) {
      // 企業の仮払い待ち
      return (
        <CommonButton
          text={'仮払い待ち'}
          size={'small'}
          color={'pink'}
          variant={'contained'}
          shape={'normal'}
          onClick={() => history.push('/signin')}
          disabled={true}
        />
      );
    } else {
      // 自分が仮アサインされていないパターン
      return (
        <div className={style.buttonContainer}>
          <EntryButton entryUserList={entryUserList} />
          <div className={style.subButtonContainer}>
            <BookmarkButton />
          </div>
        </div>
      );
    }
  }
  if (!isEngineer) {
    if (isExpired) {
      return <ExtendClosingDateButton taskDetail={detail} />;
    } else if (isRequiredAuthori) {
      return (
        <div className={commonStyles.buttonContainer}>
          <CommonButton
            text={'仮払い'}
            size={'small'}
            color={'pink'}
            variant={'contained'}
            shape={'normal'}
            onClick={() => history.push(`${pathname}/authorization`)}
          />
        </div>
      );
    } else {
      return (
        <div>
          <AssignButton id={taskId} projectId={detail.project} />
          <CommonTaskButton
            id={taskId}
            kind={'blue'}
            onClickHandler={() => {
              dispatch(
                setModalSetting({type: 'cancelConfirm', isVisible: true})
              );
            }}
            marginValue={'0.3rem'}
            text={'募集取り下げ'}
          />
          {isDeadlineComingSoon(detail.closing_date) && (
            <ExtendClosingDateButton taskDetail={detail} />
          )}
        </div>
      );
    }
  }
};

const ExtendClosingDateButton = ({taskDetail}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className={commonStyles.buttonContainer}>
      <ExtendClosingDateModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        taskDetail={taskDetail}
      />
      <CommonButton
        text={'応募期間を延長する'}
        size={'small'}
        color={'pink'}
        variant={'outlined'}
        shape={'normal'}
        onClick={() => setIsOpen(true)}
      />
    </div>
  );
};

const SwitchSettleButton = ({paymentMethod, detail}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {paymentState} = useSelector(state => state.payment);
  if (
    paymentMethod === PAYMENT_METHODS.credit &&
    paymentState.paid.status_code === PAYMENT_STATUS_PAID.usable
  ) {
    return (
      <div>
        <CommonTaskButton
          kind={'pink'}
          onClickHandler={() =>
            history.push(`/co/payment/cardlimit?taskId=${detail.id}`)
          }
          marginValue={'0.3rem'}
          text={'別のカードで再決済'}
        />
        <CommonTaskButton
          kind={'blue'}
          onClickHandler={() => dispatch(requestPaymentUsingPaid(detail.id))}
          marginValue={'0.3rem'}
          text={'請求書払いで再決済'}
        />
      </div>
    );
  } else if (
    paymentMethod === PAYMENT_METHODS.credit &&
    paymentState.paid.status_code !== PAYMENT_STATUS_PAID.usable
  ) {
    return (
      <div>
        <CommonTaskButton
          kind={'pink'}
          onClickHandler={() =>
            history.push(`/co/payment/cardlimit?taskId=${detail.id}`)
          }
          marginValue={'0.3rem'}
          text={'別のカードで再決済'}
        />
        <CommonTaskButton
          kind={'blue'}
          onClickHandler={() => history.push('/co/init-paid')}
          marginValue={'0.3rem'}
          text={'請求書払いを申請'}
        />
      </div>
    );
  } else if (
    paymentMethod === PAYMENT_METHODS.paid &&
    paymentState.credit.status_code === PAYMENT_STATUS_CREDIT.existsCard
  ) {
    return (
      <div>
        <CommonTaskButton
          kind={'pink'}
          onClickHandler={() => dispatch(requestPaymentUsingCredit(detail.id))}
          marginValue={'0.3rem'}
          text={'カードで再決済'}
        />
      </div>
    );
  } else if (
    paymentMethod === PAYMENT_METHODS.paid &&
    paymentState.credit.status_code === PAYMENT_STATUS_CREDIT.notMember
  ) {
    return (
      <div>
        <CommonTaskButton
          kind={'pink'}
          onClickHandler={() => history.push('/co/setting/payment')}
          marginValue={'0.3rem'}
          text={'カード情報を設定する'}
        />
      </div>
    );
  } else {
    return <></>;
  }
};
