import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {reduxForm, getFormSubmitErrors} from 'redux-form'
import PasswordReset from "../../../../components/Form/PasswordReset"
import {requestPasswordReset, requestPasswordResetCheckKey} from '../../../../actions/reset'
import PanelLayout from '../../../layout/PanelLayout'

class Index extends Component {

  componentDidMount() {
    const {hash} = this.props.match.params
    this.props.requestPasswordResetCheckKey(hash);
  }

  render() {
    return (
      <PanelLayout>
        <PasswordReset {...this.props} />
      </PanelLayout>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  onSubmit(value) {
    dispatch(requestPasswordReset(value));
  },
  requestPasswordResetCheckKey(value) {
    dispatch(requestPasswordResetCheckKey(value));
  }
});

Index = reduxForm({
  enableReinitialize: true,
  form: 'passwordReset'
})(Index);

const mapStateToProps = (state) => {
  return {
    submitErrors: getFormSubmitErrors('passwordReset')(state)
  };
};

const option = {areMergePropsEqual: () => false};
export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  option
)(Index));
