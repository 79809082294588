import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import AppLayout from '../../layout/AppLayout'
import Title from "../../../components/Title/index";
import { requestMyTask, requestEntryTask} from '../../../actions/setting'

class Index extends Component {

  componentDidMount() {
    this.props.requestMyTask()
    this.props.requestEntryTask()
  }

  render() {
    const { entryList, myTaskList } = this.props

    return (
      <AppLayout>
        <article>
          <Title component="h2" text='検索'/>
        </article>
      </AppLayout>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  requestMyTask(value) {
    dispatch(requestMyTask(value));
  },
  requestEntryTask(value) {
    dispatch(requestEntryTask(value));
  }
});

const mapStateToProps = (state) => {
  return {
    myTaskList: state.setting.myTaskList,
    entryList: state.setting.entryList,
  };
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Index));
