import { createAction } from 'redux-actions';

export const REQUEST_TAG = 'REQUEST_TAG';
export const requestTag = createAction(REQUEST_TAG);
export const SUCCESS_TAG = 'SUCCESS_TAGS';
export const successTag = createAction(SUCCESS_TAG);

// サジェストクリア
export const CLEAR_TAG = 'CLEAR_TAG';
export const clearTag = createAction(CLEAR_TAG);

// サジェスト選択
export const SET_TAG = 'SET_TAG';
export const setTag = createAction(SET_TAG);