import React from 'react';
import styles from './passwordResetConfirmForm.module.scss';
import {Field, reduxForm} from 'redux-form';
import * as Validator from '../utils/validate';
import {renderPassword, renderText} from '../../Field';
import CommonButton from '../../CommonButton';
import Blank from '../../Blank';
import FormError from '../FormError';

const PasswordResetConfirmForm = props => {
  const {handleSubmit, pristine, error, reset, submitting, classes} = props;

  return (
    <form onSubmit={handleSubmit}>
      <div className={styles.formContainer}>
        <div className={styles.formItems}>
          <Field
            name="new_password1"
            type="password"
            placeholder="新しいパスワード"
            component={renderText}
            validate={[Validator.RequiredItem]}
          />
        </div>
        <Blank height={'1.2rem'} />
        <div className={styles.formItems}>
          <Field
            name="new_password2"
            type="password"
            placeholder="新しいパスワード(確認)"
            component={renderText}
            validate={[Validator.RequiredItem]}
          />
        </div>
        <Blank height={'2.4rem'} />
        <FormError error={error} />
        <div className={styles.submit}>
          <CommonButton
            text={'設定する'}
            type="submit"
            size={'medium'}
            color={'pink'}
            variant={'contained'}
            shape={'normal'}
            disabled={pristine || submitting}
          />
        </div>
      </div>
    </form>
  );
};

export default reduxForm({
  form: 'passwordResetConfirm'
})(PasswordResetConfirmForm);
