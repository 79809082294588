/*
削除予定、Paginatorを使う
*/
import React from 'react';
import Pagination from 'material-ui-flat-pagination';
import style from './paginate.module.scss';
import CssBaseline from '@material-ui/core/CssBaseline';
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles';
import {withStyles} from '@material-ui/core/styles';
import {updatePageOffset} from '../../actions/paginate';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

const styles = {
  standardButton: {
    color: '#093791',
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: '#093791',
      color: 'white'
    },
    borderRadius: '4rem'
  },
  currentButton: {
    color: 'white',
    backgroundColor: '#093791',
    borderRadius: '4rem'
  },
  text: {
    fontSize: '1.4rem'
  },
  rootEndButton: {
    color: '#093791',
    backgroundColor: 'none',
    '&:hover': {
      backgroundColor: '#093791',
      color: 'white'
    },
    borderRadius: '4rem'
  }
};

const theme = createMuiTheme({
  overrides: {
    MuiFlatPageButton: {
      root: {
        minWidth: '3.5rem'
      }
    }
  }
});

class Paginate extends React.Component {
  handleClick(e, offset, page) {
    this.props.updatePageOffset(offset);
    if (this.props.method.name === 'requestEntryList') {
      this.props.method({id: this.props.match.params.id, page: page});
    } else {
      this.props.method(page);
    }
  }

  render() {
    const {limit, total, classes, offset} = this.props;

    return (
      <div className={style.container}>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <Pagination
            limit={limit}
            offset={offset}
            total={total}
            previousPageLabel={'＜ 前へ'}
            nextPageLabel={'次へ ＞'}
            classes={{
              rootStandard: classes.standardButton,
              rootCurrent: classes.currentButton,
              text: classes.text,
              rootEnd: classes.rootEndButton
            }}
            onClick={(e, offset, page) => this.handleClick(e, offset, page)}
          />
        </MuiThemeProvider>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  updatePageOffset(offset) {
    dispatch(updatePageOffset(offset));
  }
});

const mapStateToProps = state => {
  return {
    offset: state.paginate.offset
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Paginate))
);
