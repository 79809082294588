import {BREADCRUMBS_SINGLE, BREADCRUMBS_MULTIPLE} from '../actions/page';

const initialState = {
  list: []
};

function page(state = initialState, action) {
  switch (action.type) {
    case BREADCRUMBS_SINGLE:
      return {
        ...state,
        list: [action.payload]
      };
    case BREADCRUMBS_MULTIPLE:
      return {
        ...state,
        list: action.payload
      };
    default:
      return state;
  }
}

export default page;
