import React from 'react';
import style from './statusLabel.module.scss';
import {useSelector} from 'react-redux';
import Label from '../Label';
import IconArrow from '../../assets/icon_arrow_gray2.svg';

const renderTitle = () => {
  //  ↑関数コンポーネント
  const status = useSelector(state => state.task.detail.status);
  const isEngineer = useSelector(state => state.setting.isEngineer);
  return (
    <div className={style.chip}>
      {isEngineer ? (
        <></>
      ) : (
        <>
          <Label text={'審査中'} isActive={status == 2} status={status} />
          <div className={style.arrow}>
            <img src={IconArrow} />
          </div>
        </>
      )}
      <Label text={'募集中'} isActive={status == 3} status={status} />
      <div className={style.arrow}>
        <img src={IconArrow} className={style.arrow3} />
      </div>
      <Label text={'作業中'} isActive={status == 4} status={status} />
      <div className={style.arrow}>
        <img src={IconArrow} />
      </div>
      <Label text={'確認待ち'} isActive={status == 5} status={status} />
      <div className={style.arrow}>
        <img src={IconArrow} />
      </div>
      <Label text={'修正'} isActive={status == 6} status={status} />
      <div className={style.arrow}>
        <img src={IconArrow} />
      </div>
      <Label text={'評価'} isActive={status == 7} status={status} />
      <div className={style.arrow}>
        <img src={IconArrow} />
      </div>
      <Label text={'完了'} isActive={status == 8} status={status} />
    </div>
  );
};

const StatusLabel = () => {
  return <div>{renderTitle()}</div>;
};

export default StatusLabel;
