import React from 'react';
import styles from './userInfoItem.module.scss';

const UserInfoItem = ({title, mainContent, subContent, style}) => {
  return (
    <div className={styles.itemWrraper} style={style}>
      <div className={styles.itemContents}>
        <div className={styles.itemTitle}>{title}</div>
        <div className={styles.itemMainContent}>{mainContent}</div>
      </div>
      {subContent ? (
        <div className={styles.subContent}>
          <div className={styles.blank} />
          <div>{subContent}</div>
        </div>
      ) : null}
    </div>
  );
};

export default UserInfoItem;
