import React, {FC} from 'react';
import RequestButton from '../../Buttons/RequestButton';
import Blank from '../../Blank';
import MoveToMessageButton from '../../MoveToMessageButton';

type Props = {
  onClick: any;
  handleSetKind: any;
  value: any;
  engineers: any;
  id: any;
};

const EntryListButtons: FC<Props> = ({
  onClick,
  handleSetKind,
  value,
  engineers,
  id
}) => {
  return (
    <>
      <RequestButton
        kind={handleSetKind(id, value.is_preworker, engineers)}
        userId={id}
        onClick={(e: any) => onClick(e)}
      />
      <Blank height={'1rem'} width={null} />
      <MoveToMessageButton userId={id} size={'preMinimum'} />
    </>
  );
};

export default EntryListButtons;
