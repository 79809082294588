import {all, call, put, takeEvery, take, fork} from 'redux-saga/effects';
import {fetchPatch} from './utills/fetch';
import {PATCH_STATUS} from '../actions/patchStatus';
import {successPatch} from '../actions/patchStatus';

// taskのstatusを任意にpatch。今はそれぞれの箇所で書いているがここで共通化
export function* patchStatus() {
  while (true) {
    const action = yield take(PATCH_STATUS);
    const [data, error] = yield call(
      fetchPatch,
      `tasks/${action.payload.task}`,
      action.payload.status
    );
    if (data && !error) {
      yield put(successPatch(data));
    } else {
      console.log('error!');
    }
  }
}

const statusPatch = [fork(patchStatus)];

export default statusPatch;
