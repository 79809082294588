import React from 'react';
import {connect} from 'react-redux';
import Button from '../../EntryDetail/Button';
import {withRouter} from 'react-router-dom';
import {
  requestDeleteEntry,
  requestPostEntry,
  setEntryId
} from '../../../actions/entry';
import {setIsSubmitting} from '../../../actions/common';
import {BASE_COLOR, SEVERITY} from './../../../common';

const ChooseButton = ({entryFlag, onClickHandler, entryUserList, entry_no}) => {
  if (entryUserList === null) {
    return <Button kind={'loading'} />;
  }

  if (entryFlag) {
    return (
      <Button
        kind={'pinkOutlined'}
        alertSeverity={SEVERITY.success}
        onClickHandler={() => onClickHandler.delete(entry_no)}
      />
    );
  } else {
    return (
      <Button
        kind={'pink'}
        alertSeverity={SEVERITY.success}
        onClickHandler={() => {
          onClickHandler.post();
        }}
      />
    );
  }
};

// withRouter を使いたいので class コンポーネント
class Index extends React.Component {
  componentDidMount() {
    id = this.props.match.params.id;
  }
  componentDidUpdate(prevProps) {
    if (this.props.entry_no !== prevProps.entry_no) {
      this.props.setIsSubmitting();
    }
  }

  render() {
    const {
      entryUserList,
      requestPostEntry,
      requestDeleteEntry,
      entry_no
    } = this.props;

    const onClickHandler = {
      post: requestPostEntry,
      delete: requestDeleteEntry
    };

    return (
      <ChooseButton
        entryFlag={Boolean(entry_no)}
        onClickHandler={onClickHandler}
        entry_no={entry_no}
        entryUserList={entryUserList}
      />
    );
  }
}

let id = '';

const mapDispatchToProps = dispatch => ({
  requestPostEntry() {
    dispatch(requestPostEntry({task: id}));
  },
  requestDeleteEntry(entry_no) {
    dispatch(requestDeleteEntry({targetId: entry_no, taskId: id}));
  },
  setIsSubmitting() {
    dispatch(setIsSubmitting(false));
  }
});

const mapStateToProps = state => {
  return {
    // deleteTarget: state.task.detail.entry_number,
    entry_no: state.task.detail.entry_no
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Index));
