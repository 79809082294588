import React from 'react';

import Content from './content';
import UserEditForm from '../ProfileCoEditForm';
import {handleRemoveImg} from '../../../../common';
import {useSelector, useDispatch} from 'react-redux';
import {requestMeEdit} from '../../../../actions/setting';

const handleSubmit = (values, isImgChanged, dispatch) => {
  values = handleRemoveImg(values, isImgChanged);
  dispatch(requestMeEdit(values));
};

const ProfileEditForm = () => {
  const dispatch = useDispatch();
  const me = useSelector(state => state.setting.me);
  const {isImgChanged} = useSelector(state => state.common);

  return (
    <>
      {/* <UserEditForm /> */}
      <Content
        onSubmit={values => handleSubmit(values, isImgChanged, dispatch)}
        initialValues={{
          nick_name: me.nick_name,
          introduction: me.introduction,
          tags: me.tags,
          career: me.career,
          img: me.img
        }}
      />
    </>
  );
};

export default ProfileEditForm;
