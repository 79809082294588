import React from 'react';
import {useHistory} from 'react-router-dom';
import classNames from 'classnames';
import styles from '../taskList.module.scss';
import Label from '../../../Label';
import Text from '../../../Text';
import TaskInfo from '../../TaskInfo';
import Deadline from '../../../Deadline';
import formatDate from '../../../FormatDate/index';
import TaskListTheme from '../../../TaskListTheme';
import {makeTagsText, userType} from '../../../../common';
// material ui
import ListItem from '@material-ui/core/ListItem';
import TaskItemDetail from '../../TaskItemDetail';
import Reward from '../../Reward';

export const ConfirmingTask = props => {
  const {item, index, isEngineer} = props;
  const history = useHistory();

  const {
    id,
    status_info,
    status,
    updated_at,
    title,
    project_title,
    tags_info,
    delivery_date,
    closing_date,
    reward,
    company,
    project
  } = item;

  let tagsText = tags_info.length > 0 ? makeTagsText(tags_info) : null;

  return (
    <TaskListTheme key={index} index={index}>
      <ListItem
        button={true}
        onClick={() =>
          history.push(`/${userType(isEngineer)}/project/${project}/task/${id}`)
        }
      >
        <div className={classNames(styles.container)}>
          <div className={styles.title}>
            <div className={styles.flex}>
              <div className={styles.tag}>
                <Label text={status_info} status={status} isActive={true} />
              </div>
              <Text
                children={`更新日 ${formatDate(
                  new Date(updated_at),
                  'date',
                  'str'
                )}`}
                component="text01"
              />
            </div>
            <Text className={styles.body} children={title} component="text02" />
            <div className={styles.companyName}>
              <Text
                children={company ? company : 'loading'}
                component="text01"
              />
            </div>
          </div>
          <TaskItemDetail project_title={project_title} tagsText={tagsText} />
          <Reward reward={reward} />
        </div>
      </ListItem>
    </TaskListTheme>
  );
};
