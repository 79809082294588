import {createAction} from 'redux-actions';

export const REQUEST_INITIALIZE_COMPANY = 'REQUEST_INITIALIZE_COMPANY';
export const requestInitializeComapny = createAction(
  REQUEST_INITIALIZE_COMPANY
);

// 取得
export const REQUEST_COMPANY = 'REQUEST_COMPANY';
export const SUCCESS_COMPANY = 'SUCCESS_COMPANY';
export const requestCompany = createAction(REQUEST_COMPANY);
export const successCompany = createAction(SUCCESS_COMPANY);

// Paid に関係無い企業情報の編集
export const REQUEST_COMPANY_EDIT = 'REQUEST_COMPANY_EDIT';
export const SUCCESS_COMPANY_EDIT = 'SUCCESS_COMPANY_EDIT';
export const requestCompanyEdit = createAction(REQUEST_COMPANY_EDIT);
export const successCompanyEdit = createAction(SUCCESS_COMPANY_EDIT);

// エンジニア用企業情報の取得
export const REQUEST_COMPANY_EN = 'REQUEST_COMPANY_EN';
export const requestCompanyEn = createAction(REQUEST_COMPANY_EN);

// エンジニアが見る企業詳細画面で使用する
export const REQUEST_COMPANY_INFO_EN = 'REQUEST_COMPANY_INFO_EN';
export const requestCompanyInfoEn = createAction(REQUEST_COMPANY_INFO_EN);

// Paid に関係する企業情報の編集に使用する
export const REQUEST_EDIT_COMPANY_DETAIL = 'REQUEST_EDIT_COMPANY_DETAIL';
export const SUCCESS_EDIT_COMPANY_DETAIL = 'SUCCESS_EDIT_COMPANY_DETAIL';
export const requestEditCompanyInfo = createAction(REQUEST_EDIT_COMPANY_DETAIL);
export const successEditCompanyInfo = createAction(SUCCESS_EDIT_COMPANY_DETAIL);
