import React from 'react';
import {useHistory} from 'react-router-dom';
import classNames from 'classnames';

import styles from './linkItem.module.scss';
import Link from '@material-ui/core/Link';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  normalLink: {
    color: '#6C8AC4'
  },
  whiteLink: {
    color: '#D7E2F4'
  }
}));

const LinkItem = ({path, text, isNormal, url}) => {
  const classes = useStyles();
  const history = useHistory();
  if (path) {
    return (
      <p
        className={classNames(
          styles.link,
          isNormal ? styles.linkColorNormal : styles.linkColorWhite
        )}
        onClick={() => history.push(path)}
      >
        {text}
      </p>
    );
  } else {
    return (
      <Link
        className={classNames(styles.link)}
        href={url}
        target={'_blank'}
        rel={'noopener noreferrer'}
        classes={{
          root: isNormal ? classes.normalLink : classes.whiteLink
        }}
      >
        {text}
      </Link>
    );
  }
};

export default LinkItem;
