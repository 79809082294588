import { createAction } from 'redux-actions';

export const SET_MENU_OPEN = 'SET_MENU_OPEN';
export const setMenuOpen = createAction(SET_MENU_OPEN);

export const SET_SNS_MENU_OPEN = 'SET_SNS_MENU_OPEN';
export const setSnsMenuOpen = createAction(SET_SNS_MENU_OPEN);

export const SET_MEDIA_MENU_OPEN = 'SET_MEDIA_MENU_OPEN';
export const setMediaMenuOpen = createAction(SET_MEDIA_MENU_OPEN);

export const SET_SETTING_MENU_OPEN = 'SET_SETTING_MENU_OPEN';
export const setSettingMenuOpen = createAction(SET_SETTING_MENU_OPEN);