import React from 'react';
import Button from './Button';
import {useDispatch, useSelector} from 'react-redux';
import {patchcomplete} from '../../../actions/complete';
import {push} from 'connected-react-router';
import {setConfirmModalFlag} from '../../../actions/complete';

const SubmitButton = props => {
  const dispatch = useDispatch();
  const id = props.id;

  const confirmModal = () => {
    dispatch(setConfirmModalFlag(true));
    // dispatch(patchcomplete({task: id, status: 7}));
  };

  const onClickHandler = {
    modal: confirmModal
  };

  return <Button kind={'pink'} onClickHandler={() => onClickHandler.modal()} />;
};

export const CompleteButton = props => {
  const id = props.id;
  return (
    <div>
      <SubmitButton id={id} />
    </div>
  );
};
