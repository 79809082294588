import {createAction} from 'redux-actions';

//報酬受け取り済一覧
export const REQUEST_RECEIVED_REWARD_ADMIN = 'REQUEST_RECEIVED_REWARD_ADMIN';
export const SUCCESS_RECEIVED_REWARD_ADMIN = 'SUCCESS_RECEIVED_REWARD_ADMIN';
export const requestReceivedRewardAdmin = createAction(
  REQUEST_RECEIVED_REWARD_ADMIN
);
export const successReceivedRewardAdmin = createAction(
  SUCCESS_RECEIVED_REWARD_ADMIN
);

//報酬受け取り予定一覧
export const REQUEST_SCHEDULED_REWARD_ADMIN = 'REQUEST_SCHEDULED_REWARD_ADMIN';
export const SUCCESS_SCHEDULED_REWARD_ADMIN = 'SUCCESS_SCHEDULED_REWARD_ADMIN';
export const requestScheduledRewardAdmin = createAction(
  REQUEST_SCHEDULED_REWARD_ADMIN
);
export const successScheduledRewardAdmin = createAction(
  SUCCESS_SCHEDULED_REWARD_ADMIN
);

// 報酬支払い済み一覧
export const REQUEST_PAID_REWARD_TASKS = 'REQUEST_PAID_REWARD_TASKS';
export const SUCCESS_PAID_REWARD_TASKS = 'SUCCESS_PAID_REWARD_TASKS';
export const requestPaidRewardTasks = createAction(REQUEST_PAID_REWARD_TASKS);
export const successPaidRewardTasks = createAction(SUCCESS_PAID_REWARD_TASKS);
