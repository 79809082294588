import React, {FC, useEffect} from 'react';
import MainTitle from '../../../components/MainTitle';
import {useDispatch, useSelector} from 'react-redux';
import {requestPreAssignedTasks} from '../../../actions/task';
import {RootState} from '../../../models/RootModels';
import AssignedTask from '../../../components/Task/TaskLists/AssignedTask';
import NoDataContainer from '../../../components/NoDataContainer';
import noDataStyles from '../../../components/NoDataContainer/noDataContainer.module.scss';
import styles from './offerAdmin.module.scss';

const OfferAdmin: FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(requestPreAssignedTasks({openModal: false}));
  }, []);

  const {preAssignedTasks} = useSelector((state: RootState) => state.task);

  return (
    <div>
      <MainTitle titleStr={'エンジニアの承諾待ちタスク一覧'} pattern={false} />
      <p className={styles.caption}>
        エンジニアがタスクの受注を「承諾」または「辞退」するまで待っているタスクの一覧です。
      </p>
      {preAssignedTasks.length === 0 && (
        <NoDataContainer>
          <p className={noDataStyles.title}>
            現在、承諾待ちのタスクはありません
          </p>
        </NoDataContainer>
      )}
      {preAssignedTasks.map((item, index) => {
        return <AssignedTask item={item} index={index} isEngineer={false} />;
      })}
    </div>
  );
};

export default OfferAdmin;
