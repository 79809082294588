import React from 'react';
import Text from '../Text';

import styles from './valuationScore.module.scss';
import StarRating from '../StarRating';

const ValuationScore = ({params}) => {
  return (
    <div className={styles.background}>
      <div className={styles.container}>
        {params.map((item, index) => {
          return (
            <div className={styles.flex} key={index}>
              <div className={styles.text}>
                <Text children={item.name} component="text07" />
              </div>
              <StarRating evalValue={item.ratio} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ValuationScore;
