export const PATCH_STATUS = 'PATCH_STATUS';
export const SUCCESS_PATCH = 'SUCCESS_PATCH';

export const patchStatus = payload => {
  return {type: PATCH_STATUS, payload: payload};
};

export const successPatch = payload => {
  return {type: SUCCESS_PATCH, payload: payload};
};
