import React from 'react';
import {createMuiTheme, makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import style from './button.module.scss';
import {COLOR_PALLET} from '../../../constants';

// TODO:Task 詳細画面で使用するボタンはできるだけここにまとめるリファクタリングがしたい、カラーリングだけスイッチさせたい
const CommonTaskButton = props => {
  const {
    kind,
    onClickHandler,
    text,
    marginValue = '1rem',
    disabled = false,
    width = '20rem',
    height = '5rem'
  } = props;
  let applyBgColor = '';
  let applyHoverColor = '';
  let applyBorderColor = 'none';
  let valiant = 'contained';
  let applyColor = '#FFFFFF';
  let applyFontWeight = '';
  let srcImg = null;
  let applyFontSize = '1.6rem';

  switch (kind) {
    case 'pink':
      applyBgColor = COLOR_PALLET.pink1000;
      applyHoverColor = COLOR_PALLET.pinkDark;
      applyFontWeight = 'bold';
      break;
    case 'pinkOutlined':
      valiant = 'outlined';
      applyBorderColor = '0.1rem solid #EA397D';
      applyColor = '#EA397D';
      applyHoverColor = '#ffe7ef';
      applyFontSize = '1.4rem';
      break;
    case 'blue':
      applyBgColor = COLOR_PALLET.blue1000;
      applyHoverColor = COLOR_PALLET.blueDark;
      applyFontWeight = 'bold';
      break;
    case 'blue900':
      applyBgColor = COLOR_PALLET.blue900;
      applyHoverColor = COLOR_PALLET.blueDark;
      applyFontWeight = 'bold';
      break;

    case 'orange':
      applyBgColor = COLOR_PALLET.orange1000;
      applyHoverColor = COLOR_PALLET.orangeDark;
      applyFontWeight = 'bold';
      break;

    default:
      applyBgColor = COLOR_PALLET.white;
      applyHoverColor = COLOR_PALLET.white;
      applyFontWeight = 'bold';
  }

  const useStyles = makeStyles(theme => ({
    button: {
      margin: marginValue,
      backgroundColor: applyBgColor,
      border: applyBorderColor,
      color: applyColor,
      fontWeight: applyFontWeight,
      width: width,
      height: height,
      fontSize: applyFontSize,
      '&:hover': {
        backgroundColor: applyHoverColor
      },
      [theme.breakpoints.down('xs')]: {width: '25rem'}
    }
  }));

  const classes = useStyles();
  return (
    <div className={style.buttonContainer}>
      <Button
        variant={valiant}
        className={classes.button}
        disabled={disabled}
        onClick={() => onClickHandler()}
      >
        <div className={style.buttonContainer}>
          <img src={srcImg} />
          <div className={style.text}>{text}</div>
        </div>
      </Button>
    </div>
  );
};

export default CommonTaskButton;
