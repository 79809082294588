import {createAction} from 'typesafe-actions';

export const REQUEST_MY_COMPANY_INFO = 'REQUEST_MY_COMPANY_INFO';
export const SUCCESS_MY_COMPANY_INFO = 'SUCCESS_MY_COMPANY_INFO';
export const requestMyCompanyInfo = createAction(REQUEST_MY_COMPANY_INFO)<
  void
>();
export const successMyCompanyInfo = createAction(SUCCESS_MY_COMPANY_INFO)<
  any
>();
