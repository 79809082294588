import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import {NavLink} from 'react-router-dom';

import StatusLabel from '../StatusLabel';

import style from './notification.module.scss';
import {history} from '@/store';

const Index = ({list}) => {
  return (
    <section className="clearfix">
      <List className={style.myList}>
        {list.map((value, index) => (
          <ListItem key={index.toString()} button className={style.listItem}>
            <NavLink exact to="/task/1">
              <p className={style.title}>{value.title}</p>
            </NavLink>
          </ListItem>
        ))}
      </List>
    </section>
  );
};

export default Index;
