import React from 'react';

import JumpPage from '../../components/Button/OutlinedButton/JumpPage';

import styles from './notFound.module.scss';
import NotFoundImg from '../../assets/404.svg';

const NotFound = () => (
  <section className={styles.background}>
    <div className={styles.container}>
      <img src={NotFoundImg} alt="" />
      <h3 className={styles.notFound}>Not Found</h3>
      <h2 className={styles.header}>お探しのページは見つかりませんでした。</h2>
      <p className={styles.text}>
        申し訳ございませんが、お探しのページが見つかりません。
        <br />
        お探しのページは削除されたか、URLが変更された可能性がございます。
      </p>
      <div className={styles.button}>
        <JumpPage path="/" text="TOPへ戻る" />
      </div>
    </div>
  </section>
);

export default NotFound;
