import {createAction} from 'redux-actions';

// 一覧取得
export const REQUEST_TASK_LIST = 'REQUEST_TASK_LIST';
export const SUCCESS_TASK_LIST = 'SUCCESS_TASK_LIST';
export const requestTaskList = createAction(REQUEST_TASK_LIST);
export const successTaskList = createAction(SUCCESS_TASK_LIST);

// 公開タスク一覧取得
export const REQUEST_PUBLIC_TASK_LIST = 'REQUEST_PUBLIC_TASK_LIST';
export const requestPublicTaskList = createAction(REQUEST_PUBLIC_TASK_LIST);

// 詳細取得
export const REQUEST_TASK_DETAIL = 'REQUEST_TASK_DETAIL';
export const SUCCESS_TASK_DETAIL = 'SUCCESS_TASK_DETAIL';
export const requestTaskDetail = createAction(REQUEST_TASK_DETAIL);
export const successTaskDetail = createAction(SUCCESS_TASK_DETAIL);

// 詳細初期化
export const REQUEST_RESET_TASK = 'REQUEST_RESET_TASK';
export const requestResetTask = createAction(REQUEST_RESET_TASK);

// 新規作成
export const REQUEST_TASK_CREATE = 'REQUEST_TASK_CREATE';
export const SUCCESS_TASK_CREATE = 'SUCCESS_TASK_CREATE';
export const requestTaskCreate = createAction(REQUEST_TASK_CREATE);
export const successTaskCreate = createAction(SUCCESS_TASK_CREATE);

// 下書きステータスのタスク編集
export const REQUEST_TASK_EDIT = 'REQUEST_TASK_EDIT';
export const SUCCESS_TASK_EDIT = 'SUCCESS_TASK_EDIT';
export const requestTaskEdit = createAction(REQUEST_TASK_EDIT);
export const successTaskEdit = createAction(SUCCESS_TASK_EDIT);

// 募集中ステータスのタスク編集
export const REQUEST_WANTED_TASK_EDIT = 'REQUEST_WANTED_TASK_EDIT';
export const SUCCESS_WANTED_TASK_EDIT = 'SUCCESS_WANTED_TASK_EDIT';
export const requestWantedTaskEdit = createAction(REQUEST_WANTED_TASK_EDIT);
export const successWantedTaskEdit = createAction(SUCCESS_WANTED_TASK_EDIT);

//募集取り下げ
export const REQUEST_TASK_CANCEL = 'REQUEST_TASK_CANCEL';
export const requestTaskCancel = createAction(REQUEST_TASK_CANCEL);

// 削除
export const REQUEST_TASK_DELETE = 'REQUEST_TASK_DELETE';
export const SUCCESS_TASK_DELETE = 'SUCCESS_TASK_DELETE';
export const requestTaskDelete = createAction(REQUEST_TASK_DELETE);
export const successTaskDelete = createAction(SUCCESS_TASK_DELETE);

// コメント
export const REQUEST_TASK_COMMENT_CREATE = 'REQUEST_TASK_COMMENT_CREATE';
export const SUCCESS_TASK_COMMENT_CREATE = 'SUCCESS_TASK_COMMENT_CREATE';
export const requestTaskCommentCreate = createAction(
  REQUEST_TASK_COMMENT_CREATE
);
export const successTaskCommentCreate = createAction(
  SUCCESS_TASK_COMMENT_CREATE
);

// 評価
export const REQUEST_TASK_SCORE = 'REQUEST_TASK_SCORE';
export const SUCCESS_TASK_SCORE = 'SUCCESS_TASK_SCORE';
export const requestTaskScore = createAction(REQUEST_TASK_SCORE);
export const successTaskScore = createAction(SUCCESS_TASK_SCORE);

// タスク新規作成時のタグを一時保存
export const SET_TASK_TAGS = 'SET_TASK_TAGS';
export const setTaskTags = createAction(SET_TASK_TAGS);

// タスク新規作成時のタグを一時保存したものを削除
export const DELETE_TASK_TAGS = 'DELETE_TASK_TAGS';
export const deleteTaskTags = createAction(DELETE_TASK_TAGS);

// タスク新規作成時のファイルを一時保存
export const SET_TASK_FILES = 'SET_TASK_FILES';
export const setTaskFiles = createAction(SET_TASK_FILES);

// タスク新規作成時のファイルを一時保存したものを削除
export const DELETE_TASK_FILES = 'DELETE_TASK_FILES';
export const deleteTaskFiles = createAction(DELETE_TASK_FILES);

// 進行中のタスク一覧取得（企業用）
export const REQUEST_PROCESSING_TASKS = 'REQUEST_PROCESSING_TASKS';
export const SUCCESS_PROCESSING_TASKS = 'SUCCESS_PROCESSING_TASKS';
export const requestProcessingTasks = createAction(REQUEST_PROCESSING_TASKS);
export const successProcessingTasks = createAction(SUCCESS_PROCESSING_TASKS);

// 募集中のタスク一覧取得（企業用）
export const REQUEST_WANTED_TASKS = 'REQUEST_WANTED_TASKS';
export const SUCCESS_WANTED_TASKS = 'SUCCESS_WANTED_TASKS';
export const requestWantedTasks = createAction(REQUEST_WANTED_TASKS);
export const successWantedTasks = createAction(SUCCESS_WANTED_TASKS);

// プロジェクトに紐づくタスク一覧取得（企業用）
export const REQUEST_PROJECT_TASKS = 'REQUEST_PROJECT_TASKS';
export const SUCCESS_PROJECT_TASKS = 'SUCCESS_PROJECT_TASKS';
export const requestProjectTasks = createAction(REQUEST_PROJECT_TASKS);
export const successProjectTasks = createAction(SUCCESS_PROJECT_TASKS);

// "プライベートコメント欄の表示を許可するか"フラグのセット
export const SET_IS_ALLOW_READING = 'SET_IS_ALLOW_READING';
export const setIsAllowReading = createAction(SET_IS_ALLOW_READING);

// 下書き中のタスク一覧取得（企業用）
export const REQUEST_DRAFT_TASKS = 'REQUEST_DRAFT_TASKS';
export const SUCCESS_DRAFT_TASKS = 'SUCCESS_DRAFT_TASKS';
export const requestDraftTasks = createAction(REQUEST_DRAFT_TASKS);
export const successDraftTasks = createAction(SUCCESS_DRAFT_TASKS);

// 仮売上げに失敗したタスク一覧取得（企業用）
export const REQUEST_FAILED_TASKS = 'REQUEST_FAILED_TASKS';
export const SUCCESS_FAILED_TASKS = 'SUCCESS_FAILED_TASKS';
export const requestFailedTasks = createAction(REQUEST_FAILED_TASKS);
export const successFailedTasks = createAction(SUCCESS_FAILED_TASKS);

// 審査中のタスク一覧取得（企業用）
export const REQUEST_UNDER_REVIEW_TASKS = 'REQUEST_UNDER_REVIEW_TASKS';
export const SUCCESS_UNDER_REVIEW_TASKS = 'SUCCESS_UNDER_REVIEW_TASKS';
export const requestUnderReviewTasks = createAction(REQUEST_UNDER_REVIEW_TASKS);
export const successUnderReviewTasks = createAction(SUCCESS_UNDER_REVIEW_TASKS);

export const SET_MODAL_FLAG = 'SET_MODAL_FLAG';
export const setModalFlag = createAction(SET_MODAL_FLAG);

export const SET_MODAL_SETTING = 'SET_MODAL_SETTING';
export const setModalSetting = createAction(SET_MODAL_SETTING);

// タスク作成画面で入力した内容の一時保存
export const SET_PRESAVE_TASK = 'SET_PRESAVE_TASK';
export const setPreSaveTask = createAction(SET_PRESAVE_TASK);

export const CLEAN_TASK_ATTACHED = 'CLEAN_TASK_ATTACHED';
export const creanTaskAttached = createAction(CLEAN_TASK_ATTACHED);

// 企業のタスクを全て取得
export const REQUEST_ALL_TASKS_CO = 'REQUEST_ALL_TASKS_CO';
export const SUCCESS_ALL_TASKS_CO = 'SUCCESS_ALL_TASKS_CO';
export const requestAllTasksCo = createAction(REQUEST_ALL_TASKS_CO);
export const successAllTasksCo = createAction(SUCCESS_ALL_TASKS_CO);

// 仮アサイン済みのタスク一覧を取得(企業用)
export const REQUEST_PRE_ASSIGNED_TASKS = 'REQUEST_PRE_ASSIGNED_TASKS';
export const requestPreAssignedTasks = createAction(REQUEST_PRE_ASSIGNED_TASKS);
export const SUCCESS_PRE_ASSIGNED_TASKS = 'SUCCESS_PRE_ASSIGNED_TASKS';
export const successPreAssignedTasks = createAction(SUCCESS_PRE_ASSIGNED_TASKS);

// 報酬内訳取得
export const REQUEST_REWARD_DETAIL = 'REQUEST_REWARD_DETAIL';
export const requestRewardDetail = createAction(REQUEST_REWARD_DETAIL);
export const SUCCESS_REWARD_DETAIL = 'SUCCESS_REWARD_DETAIL';
export const successRewardDetail = createAction(SUCCESS_REWARD_DETAIL);

// 仮売上げが必要なタスク一覧をセット
export const SET_REQUIRED_PAY_TASKS = 'SET_REQUIRED_PAY_TASKS';
export const setRequiredPayTasks = createAction(SET_REQUIRED_PAY_TASKS);

export const REQUEST_EXTEND_CLOSING_DATE = 'REQUEST_EXTEND_CLOSING_DATE';
export const requestExtendClosingDate = createAction(
  REQUEST_EXTEND_CLOSING_DATE
);
