import {take, call, put, fork, select, all} from 'redux-saga/effects';

import {
  requestMember,
  successMember,
  requestSpecificMember,
  successSpecificMember,
  requestInviteMember,
  requestRemoveMember,
  setModalFlag,
  setSecondModalFlag
} from '../actions/member';
import {reset, startSubmit, stopSubmit} from 'redux-form';
import {fetchGet, fetchPostJson, fetchDelete} from './utills/fetch';

export function* getCompanyMember() {
  while (true) {
    const action = yield take(requestMember);
    const [data, error] = yield call(fetchGet, `co-users/`);
    yield put(successMember(data.results));
  }
}

export function* getSpecificCompanyMember() {
  while (true) {
    const action = yield take(requestSpecificMember);
    const [data, error] = yield call(
      fetchGet,
      `companies/${action.payload.companyId}/users`
    );
    yield put(successSpecificMember(data.results[action.payload.userId]));
  }
}

export function* postInviteMember() {
  while (true) {
    // IN: 追加したいメンバーの配列
    const action = yield take(requestInviteMember);
    const companyId = yield select(state => state.setting.companyId);
    const mailList = action.payload.map(member => member.email);
    yield put(startSubmit('InviteMember'));
    const [data, error] = yield call(
      fetchPostJson,
      `companies/${companyId}/make-pre-account`,
      {
        email: mailList
      }
    );
    if (data && !error) {
      yield put(setModalFlag(false));
    } else {
      yield put(stopSubmit('InviteMember', Object.assign(error)));
    }
  }
}

export function* deleteRemoveCandidateMember() {
  while (true) {
    const action = yield take(requestRemoveMember);

    const [data, error] = yield call(
      fetchDelete,
      `co-users/${action.payload}/`
    );

    if (data && !error) {
      yield put(setSecondModalFlag(false));
      const [data, _] = yield call(fetchGet, `co-users/`);
      yield put(successMember(data.results));
    } else {
      console.log('error!');
    }
  }
}

const Member = [
  fork(getCompanyMember),
  fork(getSpecificCompanyMember),
  fork(postInviteMember),
  fork(deleteRemoveCandidateMember)
];

export default Member;
