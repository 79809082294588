import {call, put, take, fork, select} from 'redux-saga/effects';
import {requestMyCompanyInfo, successMyCompanyInfo} from '../actions/myCompany';
import {fetchGet} from './utills/fetch';
import {
  requestCheckCreditAuthorization,
  requestPaymentState
} from '../actions/payment';
import store from 'store';
import {useSelector} from 'react-redux';
import {RootState} from '../models/RootModels';

export function* getMyCompany(): Generator<any> {
  while (true) {
    yield take(requestMyCompanyInfo);
    const companyId = yield select(
      (state: RootState) => state.setting.companyId
    );
    if (companyId) {
      const [data, error]: any = yield call(
        fetchGet,
        `companies/${companyId}/`
      );
      if (data && !error) {
        yield put(successMyCompanyInfo(data));
        // 支払先の設定状況を確認
        yield put(requestPaymentState());
        // GMOの仮売上げ決済画面に遷移した履歴があった場合は、決済結果を確認するAPIを叩く。
        const isMovingSettlement = store.get('isMovingSettlement');
        if (isMovingSettlement && isMovingSettlement.status) {
          yield put(requestCheckCreditAuthorization(isMovingSettlement.taskId));
        }
      }
    } else {
      console.error('companyId を取得してから呼び出しを行ってください。');
    }
  }
}

const myCompany = [fork(getMyCompany)];

export default myCompany;
