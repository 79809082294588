import React from 'react';
import {useHistory} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
// component
import {setModalSetting} from '../../../actions/task';
// material-ui
import Dialog from '@material-ui/core/Dialog';
import {makeStyles} from '@material-ui/core/styles';
import styles from '../commonModal.module.scss';

const useStyles = makeStyles({
  root: {
    backgroundColor: 'rgba(0, 33, 99, 0.5)'
  }
});

const CommonModal = ({
  children,
  onBackdropClick,
  isOpen = false,
  icon,
  title,
  message
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  return (
    <Dialog
      classes={{root: classes.root}}
      onClose={() => {}}
      open={isOpen}
      maxWidth={false}
      onBackdropClick={onBackdropClick}
    >
      <div className={styles.innerContainer}>
        <div className={styles.header}>
          <img className={styles.image} alt={'アイコン'} src={icon} />
          <div className={styles.subTitle}>{title}</div>
        </div>
        <div className={styles.message}>{message}</div>
        <div className={styles.button}>{children}</div>
      </div>
    </Dialog>
  );
};

export default CommonModal;
