import React, {useEffect} from 'react';
import style from './attached.module.scss';
import DownloadButton from '../../components/Buttons/DownloadButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import {createMuiTheme} from '@material-ui/core';
import {MuiThemeProvider} from '@material-ui/core/styles';

const theme = createMuiTheme({
  overrides: {
    MuiTableCell: {
      body: {
        padding: 0
      }
    }
  }
});

const Index = ({fileInfo}) => {
  return (
    <div className={style.content}>
      <MuiThemeProvider theme={theme}>
        <Table>
          <TableBody>
            {fileInfo.length > 0 ? (
              fileInfo.map((data, index) => (
                <TableRow key={index.toString()}>
                  <TableCell>
                    <div className={style.downloadIconCol}>
                      <DownloadButton
                        url={data.file.replace(':3000', ':8000')}
                        filename={data.file_name}
                      />
                      {data.file_name}
                    </div>
                  </TableCell>
                  <TableCell>{`${data.file_size} byte`}</TableCell>
                </TableRow>
              ))
            ) : (
              <></>
            )}
          </TableBody>
        </Table>
      </MuiThemeProvider>
    </div>
  );
};

export default Index;
