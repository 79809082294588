import {createAction} from 'redux-actions';

export const DETACH_PERSONAL_NOTIFICATION_FUNCTION =
  'DETACH_PERSONAL_NOTIFICATION_FUNCTION';
export const personalNotificationDetachFunction = createAction(
  DETACH_PERSONAL_NOTIFICATION_FUNCTION
);

export const SET_LAST_PERSONAL_NOTIFICATION = 'SET_LAST_PERSONAL_NOTIFICATION';
export const setLastPersonalNotification = createAction(
  SET_LAST_PERSONAL_NOTIFICATION
);
