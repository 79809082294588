export const topCo = {
  processing: '作業中 〜 修正 までのタスクの一覧です。',
  underReview:
    '審査中のタスクのみが表示されます。\n運営局の審査が完了すると募集中に切り替わります。\nなお、クレジットカードでお支払いのタスクは、仮払いが完了するまでは審査中ステータスから募集中には切り替わりません。',
  all:
    '下書き保存 〜 完了 まで、全てのステータスのタスクがここに表示されます。',
  draft:
    '下書き保存中のタスクが表示されます、タスクの作成中にお支払い設定画面に移動した時もこちらに表示されます。',
  review:
    '成果物の確認が完了し、評価ステータスに切り替わったタスクのみが表示されます。お相手のエンジニアをお早めに評価して頂く事をお願いしております。',
  wanted:
    '募集中のタスクのみが表示されます。\nエンジニアがエントリーするのを待ちましょう。',
  requiredPay:
    '仮払いが必要なタスクの一覧です。\n仮払いを行うことで、タスクがエンジニアに公開されます。'
};

export const topEn = {
  review:
    '成果物の確認が完了し、評価ステータスに切り替わったタスクのみが表示されます。取引相手の企業をお早めに評価して頂く事をお願いしております。',
  assigned:
    'アサインされ、作業が開始されたタスクの一覧です。\nタスク詳細画面の作業メモを活用し、企業と連絡を取り合って作業を進めましょう。',
  preAssigned:
    'アサインの依頼が来ているタスクの一覧です。\nタスク詳細画面で依頼の承諾・辞退を選択して下さい。',
  entry:
    '現在エントリー中のタスク一覧です。\n企業からのアサイン依頼を待ちましょう。',
  bookmark:
    '検討中リストに追加したタスク一覧です。\nお好みの案件が見つかり次第、タスク詳細画面からエントリーができます。'
};

export const subTitle = {
  adminInfo:
    '案件の発注やエンジニアとの連絡を行うユーザの情報の入力をお願いします。\nメールアドレスはログイン時のIDとなります。',
  pjCategory:
    'プロジェクトの種別を選択してください。\nエンジニアがこのカテゴリを元に案件を検索することがあります。',
  pjDetail:
    'どの様なプロジェクトなのかプロジェクト自体の概要を御入力下さい。\n依頼する具体的な内容や報酬金額についてはこの後作成する「タスク」に御入力下さい。'
};

export const formField = {
  taskDescription:
    '依頼したい内容についての入力をお願いします。\n全てのエンジニアが閲覧可能ですので、公開可能な範囲での御入力をお願い致します。',
  file:
    'このタスクがどういった内容なのかを知る際に、参考になりそうなファイルがあれば添付をお願いします。\n全てのエンジニアが閲覧可能です。',
  tag:
    'エンジニアがタスクを探す際に、このタグを元に検索を行います。\nよくわからなければ未入力でも構いません。',
  format:
    'エンジニアの成果物を受け取る方法を選択して下さい、当てはまるものがなければ「その他」を選択して下さい。',
  reward: '適正だと思われるエンジニアへの報酬額を半角整数で御入力下さい。'
};
