import React from 'react';
import {useDispatch} from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';

import styles from './roomListScroll.module.scss';
import TalkRoom from '../../TalkRoom';

const RoomListScroll = ({
  rooms,
  me,
  isEngineer,
  user,
  requestRoomList,
  requestInitRooms,
  message,
  hasMore
}) => {
  const dispatch = useDispatch();

  return (
    <div className={styles.scrollContainer} id="infinityScrollId">
      <InfiniteScroll
        dataLength={rooms.length}
        next={() => dispatch(requestRoomList({useRefresh: false}))}
        hasMore={hasMore}
        loader={
          <p className={styles.loadingMessage}>
            <h3>読み込み中です</h3>
          </p>
        }
        endMessage={<p className={styles.loadingMessage}></p>}
        scrollThreshold={0.8}
        scrollableTarget="infinityScrollId"
      >
        {rooms.map((room, index) => {
          const members = room.data.users;
          let toIdList = members.filter(elem => elem !== me.id);
          let toId = null;
          if (toIdList.length > 0) {
            toId = toIdList[0];
          }
          const toUserInfo = isEngineer
            ? user.coUsers[toId]
            : user.enUsers[toId];
          return (
            <TalkRoom
              key={index.toString()}
              room={room}
              onClickHandler={requestInitRooms}
              toId={toId}
              toUserInfo={toUserInfo}
              message={message}
            />
          );
        })}
      </InfiniteScroll>
    </div>
  );
};

export default RoomListScroll;
