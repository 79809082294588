import style from './taskForm.module.scss';
import ClipBlue from '../../../../assets/icon_clip_blue.svg';
import React from 'react';
import {NumberWithDelimiter} from '../../../../common';
import Blank from '../../../Blank';
import classNames from 'classnames';
import {Field} from 'redux-form';
import {renderRadio} from '../../../Field';
import * as Validator from '../../utils/validate';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import FormatDate from '../../../FormatDate';
import Divider from '@material-ui/core/Divider';

export const RenderDraftFiles = ({formValues}) => {
  if (formValues && formValues.file_info) {
    return (
      <>
        {formValues.file_info.map((file, index, array) => (
          <div className={style.attachmentItem} key={index}>
            <img src={ClipBlue} />
            <span className={style.attachmentText}>
              {`${file.file_name} (${file.file_size}B)`}
            </span>
          </div>
        ))}
      </>
    );
  } else {
    return <></>;
  }
};

export const RewardDetail = ({rewardDetail}) => {
  if (rewardDetail.reward) {
    return (
      <div className={style.leftBlankContent}>
        <div className={style.rewardItemContainer}>
          <p className={style.rewardDetailTitle}>
            システム手数料 ({rewardDetail.system_fee_ratio}%)
          </p>
          <p className={style.rewardDetail}>
            {NumberWithDelimiter(rewardDetail.system_fee)}
          </p>
          <p className={style.yenText}>円</p>
        </div>
        <Blank height={'1rem'} />
        <div className={style.rewardItemContainer}>
          <p className={style.rewardDetailTitle}>消費税 (10%)</p>
          <p className={style.rewardDetail}>
            {NumberWithDelimiter(rewardDetail.tax)}
          </p>
          <p className={style.yenText}>円</p>
        </div>
        <Blank height={'1rem'} />
        <div className={style.sumDivider} />
        <Blank height={'1rem'} />
        <div className={style.rewardItemContainer}>
          <p className={style.rewardDetailTitle}>合計</p>
          <p className={style.rewardDetail}>
            {NumberWithDelimiter(rewardDetail.sum)}
          </p>
          <p className={style.yenText}>円</p>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

export const CouponSelector = ({coupons, classes}) => {
  return (
    <div className={style.scheduleContainer}>
      <div className={classNames(style.row, style.itemPadding)}>
        <div className={style.itemNameContainer}>
          <div className={classNames(style.rowTitle, style.CategoryText)}>
            <p>クーポン</p>
            <p className={style.couponAttention}>
              {' '}
              有効期間中は何回でも使用可能
            </p>
          </div>
          <div className={style.rowRequire}>
            <label className={style.notRequired}>必須</label>
          </div>
        </div>
        <div className={style.radioLabelText}>
          <Field
            name="coupon"
            component={renderRadio}
            required
            row={false}
            classes={{label: {fontSize: '1.6rem'}}}
            validate={[Validator.RequiredChoice]}
          >
            {coupons.map((item, index) => {
              return (
                <>
                  <FormControlLabel
                    value={item.id}
                    control={<Radio color="primary" />}
                    label={
                      <>
                        <p className={style.radioLabelText}>{item.name}</p>
                        <p className={style.couponDescription}>
                          ・システム手数料：10% → {item.ratio}%
                        </p>
                        <p className={style.couponDescription}>
                          ・有効期限：
                          {FormatDate(item.expired, 'date')}
                        </p>
                      </>
                    }
                  />
                  {coupons.length - 1 > index && <Blank height={'2rem'} />}
                </>
              );
            })}
          </Field>
        </div>
      </div>
      <Divider classes={{root: classes.divider}} />
    </div>
  );
};
