import {
  all,
  call,
  put,
  takeEvery,
  take,
  fork,
  select
} from 'redux-saga/effects';
import {startSubmit, stopSubmit, reset} from 'redux-form';
import {fetchPatch, fetchGet} from './utills/fetch';
import {
  requestMe,
  successMe,
  requestMeEdit,
  successMeEdit,
  requestMyTask,
  successMyTask,
  requestEntryTask,
  successEntryTask,
  requestBookmarkTask,
  successBookmarkTask,
  requestPreAssignList,
  successPreAssignList,
  requestAcceptTermsOfService,
  setCompanyId,
  requestEditSecureInfo
} from '../actions/setting';
// import {successPersonalnotification} from '../actions/personalNotification';
import {setUserTags} from '../actions/profile';
import {requestRoomList} from '../actions/message';
import {personalNotificationDetachFunction} from '../actions/personalNotification';
import {systemNotificationDetachFunction} from '../actions/systemNotification';
import {fetchPersonalNotification} from '../firebase/personalNotification.js';
import {fetchSystemNotification} from '../firebase/systemNotification.js';
import {history} from '../store';
import store from 'store';
import {requestRefreshToken} from '../actions/sign';
import {isRedirectCancelUrl} from '../sagas/utills/common';
import {setModalSetting} from '../actions/task';
import {formatErrorResponse, joinHyphen} from '../common';

// 自身の情報を取得
// export function* getMe() {
//   while (true) {
//     yield take(requestMe);
//     try {
//       const [data, error] = yield call(fetchGet, `en-users/me/`);
//       yield put(successMe(data))
//       let tags = []
//       data.tags.forEach(value => {
//         tags.push({name: value})
//       })
//       yield put(setUserTags(tags))
//     }catch {
//       console.log("An error has occurred in getMe")
//     }

//   }
// }

// 自身の情報を取得 かつ firestore PersonalNotificationsコレクションのドキュメント達の変化を監視する関数呼ぶ。
export function* getMeMonitor() {
  while (true) {
    yield take(requestMe);

    if (store.get('token')) {
      try {
        const isEngineer = store.get('isEngineer');
        const [data, error] = isEngineer
          ? yield call(fetchGet, `en-users/me/`)
          : yield call(fetchGet, `co-users/me/`);

        if (data && !error) {
          yield put(successMe(data));

          if (!isEngineer) {
            yield put(setCompanyId(data.company));
          }

          if (data.tags) {
            let tags = [];
            data.tags.forEach(value => {
              tags.push({name: value});
            });
            yield put(setUserTags(tags));
          }
        } else {
          if (error === 401) {
            yield put(requestRefreshToken());
          }
        }
      } catch (error) {}
    }
  }
}

// 自身の情報を書き換え
export function* patchMe() {
  while (true) {
    const action = yield take(requestMeEdit);
    const {tags} = yield select(state => state.profile);
    const userType = store.get('isEngineer') ? 'en' : 'co';
    const uppercaseUserType = store.get('isEngineer') ? 'En' : 'Co';
    yield put(startSubmit(`edit${uppercaseUserType}Profile`));
    yield put(startSubmit(`edit${uppercaseUserType}IdInfo`));

    const [data, error] = yield call(
      fetchPatch,
      `${userType}-users/me`,
      action.payload
    );
    if (data && !error) {
      yield put(reset(`edit${uppercaseUserType}Profile`));
      yield put(reset(`edit${uppercaseUserType}IdInfo`));
      yield put(successMeEdit(data));

      yield put(
        setModalSetting({type: 'SUCCESS_SAVE_PROFILE', isVisible: true})
      );
    } else {
      yield put(
        stopSubmit(`edit${uppercaseUserType}Profile`, {
          ...error,
          _error: error.detail
        })
      );
    }
  }
}

// 本人確認情報の修正
export function* patchSecureInfo() {
  while (true) {
    const action = yield take(requestEditSecureInfo);
    const {zipCode1, zipCode2} = action.payload;
    let payload = {
      ...action.payload,
      zip_code: joinHyphen([zipCode1, zipCode2])
    };
    const [data, error] = yield call(fetchPatch, `en-users/me`, payload);
    if (data && !error) {
      yield put(requestMe());
      yield put(
        setModalSetting({type: 'SUCCESS_SAVE_ID_INFO', isVisible: true})
      );
    } else {
      yield put(
        stopSubmit(`editEnIdInfo`, {
          ...formatErrorResponse(error),
          _error: error.detail
        })
      );
    }
  }
}

// 自分のタスク一覧取得
export function* getMyTaskList() {
  while (true) {
    const action = yield take(requestMyTask);

    try {
      const [data, error] = yield call(fetchGet, `my-assign/`);
      yield put(successMyTask(data));
    } catch (error) {}
  }
}

export function* getEntryTaskList() {
  while (true) {
    yield take(requestEntryTask);
    try {
      const {me} = yield select(state => state.setting);
      if (!me) continue;

      const [data, error] = yield call(fetchGet, `my-entry/`);

      if (data && !error) {
        yield put(successEntryTask(data));
      }
    } catch (error) {
      console.error('An error has occurred in getEntryTaskList');
    }
  }
}

export function* getPreAssignedTaskList() {
  while (true) {
    yield take(requestPreAssignList);
    try {
      const [data, error] = yield call(fetchGet, `my-preassign/`);
      if (data && !error) {
        yield put(successPreAssignList(data.results));
        const readPreAssignTasks =
          store.get('readPreAssignTasks') === undefined
            ? []
            : store.get('readPreAssignTasks');
        // モーダルから遷移したことが無い、仮アサイン済みタスク達を抽出
        const unReadPreAssignList = data.results.filter(item => {
          return !readPreAssignTasks.some(id => id === item.id);
        });

        if (unReadPreAssignList.length > 0) {
          yield put(
            setModalSetting({
              type: 'UNREAD_PRE_ASSIGN_CONFIRM',
              isVisible: true,
              params: {unReadPreAssignList: unReadPreAssignList}
            })
          );
        }
      }
    } catch (error) {
      console.error('An error has occurred in getPreAssignedTaskList');
    }
  }
}

// 自分の検討中リスト一覧を取得
export function* getBookmarkTaskList() {
  while (true) {
    const action = yield take(requestBookmarkTask);

    try {
      const [data, error] = yield call(fetchGet, 'my-bookmark/');
      yield put(successBookmarkTask(data));
    } catch (error) {
      console.log('error');
    }
  }
}

// 利用規約に同意する
export function* acceptTerms() {
  while (true) {
    yield take(requestAcceptTermsOfService);
    const [data, error] = yield call(fetchGet, 'terms-of-service-accept/');
    if (data && !error) {
      yield put(requestMe());
      history.push('/en');
    } else {
      console.log('error');
    }
  }
}

const Setting = [
  fork(getMeMonitor),
  fork(patchMe),
  fork(getMyTaskList),
  fork(getBookmarkTaskList),
  fork(getEntryTaskList),
  fork(acceptTerms),
  fork(patchSecureInfo),
  fork(getPreAssignedTaskList)
];

export default Setting;
