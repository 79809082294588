import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
// components
import MainTitle from '../../../components/MainTitle';
// hooks
import useScrollToTop from '../../../hooks/useScrollToTop';
import {
  requestResetTask,
  requestRewardDetail,
  requestTaskDetail
} from '../../../actions/task';
import {useHistory, useParams} from 'react-router';
import {
  PAYMENT_METHODS,
  PAYMENT_STATUS_CREDIT,
  PAYMENT_STATUS_PAID,
  CREDIT_AUTHORI_DURATION
} from '../../../constants';
// material-ui
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Link from '@material-ui/core/Link';

import commonStyles from '../../../common/common.module.scss';
import styles from './taskAuthorization.module.scss';
import ContentTitle from '../../../components/ContentTitle';
import Blank from '../../../components/Blank';
import CommonButton from '../../../components/CommonButton';
import {
  requestPaidAuthori,
  requestSecureCreditLimitUrl
} from '../../../actions/payment';
import formatDate from '../../../components/FormatDate';
import {addDays} from 'date-fns';
import clsx from 'clsx';
import Caption from '../../../components/Caption';
// @ts-ignore
import store from 'store';
import WithOpenInNewWindow from '../../../components/WithOpenInNewWindow';

const TaskAuthori: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedPayType, setSelectedPayType] = useState<number | null>(null);
  const {paymentState, creditLimitUrl} = useSelector(
    (state: {payment: any}) => state.payment
  );
  const {isSubmitting} = useSelector((state: {common: any}) => state.common);
  const {taskId} = useParams<{taskId: string}>();
  const {detail, rewardDetail} = useSelector(
    (state: {task: any}) => state.task
  );
  const isUsableCredit: boolean =
    paymentState.credit.status_code === PAYMENT_STATUS_CREDIT.existsCard;
  const isUsablePaid: boolean =
    paymentState.paid.status_code === PAYMENT_STATUS_PAID.usable;
  const ORIGIN = window.location.origin;

  useScrollToTop();

  useEffect(() => {
    dispatch(requestTaskDetail({id: taskId}));
    dispatch(requestSecureCreditLimitUrl(taskId));
    return () => dispatch(requestResetTask());
  }, []);

  useEffect(() => {
    if (detail.reward) {
      dispatch(
        requestRewardDetail({
          reward: detail.reward,
          coupon: detail.coupon_info.id
        })
      );
    }
  }, [detail]);

  return (
    <div>
      <MainTitle titleStr={'報酬の仮払い'} pattern={false} />
      <div>
        <Link
          href={`${ORIGIN}/faq/pre_payment`}
          target={'_blank'}
          rel={'noopener noreferrer'}
        >
          <WithOpenInNewWindow>仮払い とは？</WithOpenInNewWindow>
        </Link>
      </div>
      {/*@ts-ignore*/}
      <Blank height={'2.4rem'} />
      <div className={commonStyles.container}>
        <ContentTitle title="お支払い詳細" isButton={false} />
        {/*@ts-ignore*/}
        <Blank height={'1.6rem'} />
        <div className={styles.itemContainer}>
          <div className={styles.titleContainer}>
            <div className={styles.comNameContainer}>タスク名</div>
            <div className={styles.infoContainer}>{detail.title}</div>
          </div>
        </div>
        <div className={styles.itemContainer}>
          <div className={styles.titleContainer}>
            <div className={styles.comNameContainer}>報酬金額</div>
            <div className={styles.infoContainer}>
              {detail.reward
                ? `¥ ${detail.reward.toLocaleString()}`
                : 'Loading...'}
            </div>
          </div>
        </div>
        <div className={styles.itemContainer}>
          <div className={styles.titleContainer}>
            <div className={styles.comNameContainer}>システム手数料</div>
            <div className={styles.infoContainer}>
              {rewardDetail.system_fee
                ? `¥ ${rewardDetail.system_fee.toLocaleString()}`
                : 'Loading...'}
            </div>
          </div>
        </div>
        <div className={styles.itemContainer}>
          <div className={styles.titleContainer}>
            <div className={styles.comNameContainer}>消費税</div>
            <div className={styles.infoContainer}>
              {rewardDetail.tax
                ? `¥ ${rewardDetail.tax.toLocaleString()}`
                : 'Loading...'}
            </div>
          </div>
        </div>
        <div className={styles.itemContainer}>
          <div className={styles.titleContainer}>
            <div className={styles.comNameContainer}>合計金額</div>
            <div className={clsx(styles.infoContainer, styles.sumText)}>
              {rewardDetail.tax
                ? `¥ ${rewardDetail.sum.toLocaleString()}`
                : 'Loading...'}
            </div>
          </div>
        </div>
        <Blank height={'4rem'} width={null} />
        <ContentTitle title="お支払い方法" isButton={false} />
        <Blank height={'2.4rem'} width={null} />
        {!isUsableCredit && !isUsablePaid && (
          <Caption>お支払い方法が設定されていません</Caption>
        )}
        <Blank height={'1.6rem'} width={null} />
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="gender"
            name="gender1"
            value={selectedPayType}
            onChange={e => setSelectedPayType(Number(e.target.value))}
          >
            <FormControlLabel
              value={0}
              control={<Radio />}
              label="クレジットカード払い"
              disabled={!isUsableCredit}
            />
            <FormControlLabel
              value={1}
              control={<Radio />}
              label="請求書（Paid）払い"
              disabled={!isUsablePaid}
            />
            {/*@ts-ignore*/}
            <Blank height={'2.4rem'} />

            {!isUsableCredit && !isUsablePaid && (
              <>
                {/*@ts-ignore*/}
                <CommonButton
                  text={'お支払い方法の設定'}
                  size={'small'}
                  color={'blue'}
                  variant={'contained'}
                  shape={'normal'}
                  onClick={() => history.push('/co/setting/payment')}
                />
              </>
            )}
          </RadioGroup>
        </FormControl>
      </div>
      {selectedPayType === PAYMENT_METHODS.credit && (
        <div className={styles.confirmContainer}>
          <div className={styles.confirmTextContainer}>
            <p>上記の内容で、仮払いを行います。</p>
            <p>下記のボタンを押下すると、決済画面へ遷移します</p>
            <p>決済後は「サイトに戻る」を押下してください。</p>
          </div>
          {/*@ts-ignore*/}
          <CommonButton
            text={'決済画面へ移動する'}
            size={'medium'}
            color={'blue'}
            variant={'contained'}
            shape={'normal'}
            onClick={() => onClickHandler(creditLimitUrl, taskId)}
            disabled={!creditLimitUrl}
          />
        </div>
      )}
      {selectedPayType === PAYMENT_METHODS.paid && (
        <div className={styles.confirmContainer}>
          <div className={styles.confirmTextContainer}>
            <p>上記の内容で、仮払いを行います。</p>
          </div>
          {/*@ts-ignore*/}
          <CommonButton
            text={'仮売上げを実行'}
            size={'medium'}
            color={'blue'}
            variant={'contained'}
            shape={'normal'}
            onClick={() =>
              dispatch(
                requestPaidAuthori({
                  task_id: taskId,
                  payment_method: selectedPayType
                })
              )
            }
            disabled={isSubmitting}
          />
        </div>
      )}
    </div>
  );
};

const onClickHandler = (creditLimitUrl: string, taskId: string) => {
  // 決済画面への遷移時、履歴を残す
  store.set('isMovingSettlement', {status: true, taskId: taskId});
  window.open(creditLimitUrl, '_blank');
};

export default TaskAuthori;
