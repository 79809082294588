import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {requestLatestTaskList} from '../../../actions/taskList';
import {requestCoReviews} from '../../../actions/review';
import {requestCompanyInfoEn} from '../../../actions/company';
import {requestMyCompanyInfo} from '../../../actions/myCompany';

import styles from './info.module.scss';
import PageTitle from '../../../components/PageTitle';
import ContentTitle from '../../../components/ContentTitle';
import Text from '../../../components/Text';
import CoTaskList from '../../../components/Task/CoTaskList';
import ValuationItem from '../../../components/ValuationItem';
import Blank from '../../../components/Blank';
import AllowIndentionArea from '../../../components/AllowIndentionArea';
import {useParams} from 'react-router';
import UrlIncludeArea from '../../../components/UrlIncludeArea';
import Caption from '../../../components/Caption';
import {requestCompany} from '../../../actions/company';
import Divider from '@material-ui/core/Divider';
import useScrollToTop from '../../../hooks/useScrollToTop';

const Item = ({title, mainContent, subContent}) => {
  return (
    <div className={styles.itemContainer}>
      <div className={styles.titleContainer}>
        <div className={styles.comNameContainer}>{title}</div>
        <UrlIncludeArea className={styles.infoContainer} text={mainContent} />
      </div>
      {subContent ? (
        <div className={styles.subContent}>
          <div className={styles.blank} />
          <div>{subContent}</div>
        </div>
      ) : null}
    </div>
  );
};

const Info = () => {
  const {isEngineer} = useSelector(state => state.setting);
  const company = useSelector(state => state.company);
  const myCompany = useSelector(state => state.myCompany);
  const latestTask = useSelector(state => state.taskList);
  const coReviews = useSelector(state => state.review.coReviews);
  const dispatch = useDispatch();
  const companyId = useSelector(state => state.setting.companyId);
  const params = useParams();
  const isMyCompanyPage = params.id === undefined;

  const {
    company_name,
    company_name_kana,
    representative_name,
    representative_name_kana,
    zip_code,
    address,
    tel,
    homepage,
    introduce
  } = isMyCompanyPage ? myCompany : company;

  useScrollToTop();

  useEffect(() => {
    if (!isMyCompanyPage) {
      dispatch(requestCompanyInfoEn(params.id));
    }

    if (isMyCompanyPage && companyId) {
      dispatch(requestMyCompanyInfo());
      dispatch(requestLatestTaskList(companyId));
      dispatch(requestCoReviews({id: companyId}));
    }
    // firebase から company_id を取得してくるまでに少し時間がかかるのでレンダリング条件指定
  }, [companyId, params.id]);

  return (
    <>
      <div className={styles.flex}>
        <div className={styles.container}>
          <PageTitle title="企業情報" />
          {isMyCompanyPage && (
            <Caption>このページはエンジニアも閲覧することができます</Caption>
          )}
          <div className={styles.containerItem}>
            <ContentTitle
              title="基本情報"
              isButton={isMyCompanyPage}
              link={'/co/info/edit/base'}
            />
            <Blank height={20} />
            <Item
              title={'会社名'}
              mainContent={company_name}
              subContent={company_name_kana}
            />
            {representative_name !== null ? (
              <Item
                title={'代表取締役'}
                mainContent={representative_name}
                subContent={representative_name_kana}
              />
            ) : null}
            {zip_code ? (
              <Item
                title={'所在地'}
                mainContent={'〒' + zip_code}
                subContent={address}
              />
            ) : null}
            <Item title={'電話番号'} mainContent={tel} subContent={null} />
          </div>
          <div className={styles.containerItem}>
            <ContentTitle
              title="その他の情報"
              isButton={isMyCompanyPage}
              link={'/co/info/edit/other'}
            />
            <Text children="企業紹介" component="h1" />
            <AllowIndentionArea>
              <Text children={introduce} component="h3" />
            </AllowIndentionArea>
            <Blank height={'3rem'} />
            <Divider />
            <Item title={'公式HP'} mainContent={homepage} subContent={null} />
          </div>

          {isMyCompanyPage && (
            <div className={styles.containerItem}>
              <ContentTitle title="直近のタスク" />
              <Blank height={50} />
              <div className={styles.taskList}>
                {latestTask.some(ele => {
                  return Object.keys(ele).length;
                }) ? (
                  <CoTaskList task={latestTask} />
                ) : (
                  <p className={styles.noEvaluation}>
                    まだ、タスクがありません。
                  </p>
                )}
              </div>
            </div>
          )}

          <div className={styles.containerItem}>
            <ContentTitle title="評価・実績" />
            {coReviews.length === 0 ? (
              <p className={styles.noEvaluation}>
                まだ、お仕事のやりとりがありません。
              </p>
            ) : (
              coReviews.map((item, index) => {
                return <ValuationItem item={item} key={index.toString()} />;
              })
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Info;
