import React, {useEffect} from 'react';
import TaskSearchForm from '../Form/TaskSearchForm';
import {requestCategoryList} from '../../actions/category';
import {useDispatch, useSelector} from 'react-redux';
import Blank from '../Blank';
import {requestPublicTaskList, requestTaskList} from '../../actions/task';

const TaskSearch = ({searchOptions}) => {
  const dispatch = useDispatch();
  const {list} = useSelector(state => state.category);

  useEffect(() => {
    dispatch(requestCategoryList());
  }, []);

  return (
    <div>
      <TaskSearchForm
        searchOptions={searchOptions}
        categoryList={list}
        onSubmit={values =>
          dispatch(
            requestPublicTaskList({page: 1, ...values, ...searchOptions})
          )
        }
      />
      <Blank height={'2.4rem'} />
    </div>
  );
};

export default TaskSearch;
