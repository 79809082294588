import React, {useEffect, useState} from 'react';
import MemoHeaderButton from '../Buttons/MemoHeaderButton';
import CommentItem from '../CommentItem';
import CommonCommentForm from '../Form/CommonCommentForm';
import {
  requestCreateProjectComment,
  requestProjectCommentList
} from '../../actions/comment';
import {useDispatch, useSelector} from 'react-redux';
import Blank from '../Blank';
import Caption from '../Caption';

const ProjectMessage = ({detail, id}) => {
  const {projectComments} = useSelector(state => state.comment);
  const {projectCommentsNext} = useSelector(state => state.comment);
  const dispatch = useDispatch();
  const hasMore = Boolean(projectCommentsNext);
  const [page, setPage] = useState(1);

  useEffect(() => {
    requestProjectCommentListHandler();
  }, []);

  const requestProjectCommentListHandler = () => {
    dispatch(
      requestProjectCommentList({projectId: id, page: page, useRefresh: true})
    );
    setPage(page + 1);
  };

  return (
    <div>
      <Caption>
        {
          '「このプロジェクトを作成した企業のユーザ」と\n「このプロジェクトのタスクを受注しているエンジニア」のみが閲覧できます。'
        }
      </Caption>
      <Blank height={'1.6rem'} />
      {projectComments.length >= 5 && hasMore ? (
        <MemoHeaderButton
          onClick={() => {
            dispatch(requestProjectCommentList({projectId: id, page: page}));
            setPage(page + 1);
          }}
        />
      ) : null}
      <ul>
        {projectComments
          .slice()
          .reverse()
          .map((comment, index) => (
            <CommentItem key={index.toString()} comment={comment} />
          ))}
      </ul>
      <Blank height={'1.6rem'} />
      <CommonCommentForm
        detail={detail}
        onSubmit={values => {
          dispatch(
            requestCreateProjectComment({
              id: id,
              text: values.text
            })
          );
        }}
        initialValues={{
          commonCommentText: '',
          attachedFiles: []
        }}
        useFile={false}
      />
      <Blank height={'4rem'} />
    </div>
  );
};

export default ProjectMessage;
