import React from 'react';
import Typography from '@material-ui/core/Typography';
import style from './footer.module.scss'

const Index = () => {

    return (
        <footer className={style.footer}>
            {/*<div  className={style.list}>*/}
                {/*<ul>*/}
                    {/*<li>*/}
                        {/*<Typography variant="body2">*/}
                            {/*リンク*/}
                        {/*</Typography>*/}
                    {/*</li>*/}
                {/*</ul>*/}
                {/*<ul>*/}
                    {/*<li>*/}
                        {/*<Typography variant="body2">*/}
                            {/*リンク*/}
                        {/*</Typography>*/}
                    {/*</li>*/}
                    {/*<li>*/}
                        {/*<Typography variant="body2">*/}
                            {/*リンク*/}
                        {/*</Typography>*/}
                    {/*</li>*/}
                {/*</ul>*/}
            {/*</div>*/}
            <small>© m-Lab inc. All Rights Reserved.</small>
        </footer>
    );
}

export default Index;
