import React from 'react';

export const contents = [
  {
    text: 'Task とは',
    path: '/faq/task',
    component: (
      <div>
        WEB・アプリ開発に特化したクラウドソーシングサービスです。
        <br />
        <br />
        企業様に発注していただいた<strong>タスク(案件)</strong>
        に対して運営が審査を行うことで、適正な価格での発注・受注が可能です。
        <br />
        価格が適正なため、より質の高いエンジニアが集まりやすくなり、企業様は安心して発注を行うことができます。
        <br />
        <br />
        要件定義や適正価格の設定などが難しいと感じる企業様は、運営にサポートを依頼することも可能です。（画面右下緑色のチャットサポートボタンよりお気軽にお問い合わせ下さい。）
        <br />
        <br />
        また、タスク１つ１つの粒度が細かいため、エンジニアも「空いた時間」を活用したお仕事がしやすいサービスです。
      </div>
    )
  },
  {
    text: 'プロジェクトとは',
    path: '/faq/project',
    component: (
      <div>
        小さな<strong>タスク</strong>を同一のプロジェクトとしてまとめる
        <strong>親（入れ物）</strong>です。
      </div>
    )
  },
  {
    text: 'タスクとは',
    path: '/faq/task-ticket',
    component: (
      <div>
        プロジェクトの<strong>子</strong>として作成する小さな案件です。
        <br />
        実装する機能単位で企業様が発注します。
      </div>
    )
  },
  {
    text: 'お仕事発注の流れ',
    path: '/faq/create-task',
    component: (
      <ol>
        <li>プロジェクトを作成します。</li>
        <li>タスクの作成をします。</li>
        <li>タスクに対して運営が審査を行います。</li>
        <li>タスクが公開されます。</li>
        <li>エンジニアのエントリーを待ちます。</li>
        <li>
          エントリーしたエンジニア一覧から、依頼したいエンジニアを仮アサインします。
        </li>
        <li>
          仮アサインしたエンジニアとメッセージでやりとりを行い、スケジュール等の条件がマッチすればエンジニアがアサイン依頼を承諾し、アサインが確定します。
        </li>
        <li>お支払い方法の設定をします。</li>
        <li>
          仮払いを行います。仮払いが完了するとタスクのステータスが「作業中」となり、エンジニアが作業を開始します。
        </li>
        <li>
          タスク詳細画面の作業メモで、エンジニアとやりとりしながら成果物の確認依頼を待ちます。
        </li>
        <li>
          成果物の確認依頼が届きましたら、タスク新規作成時に指定された方法にて確認をお願いします。
        </li>
        <li>成果物に問題があれば、タスク詳細画面より修正依頼を行います。</li>
        <li>エンジニアが修正を行い、再度確認依頼を行うまで待ちます。</li>
        <li>
          成果物（修正物）に問題がなければ、成果物の受け取りボタンを押下します。
        </li>
        <li>
          エンジニアに対して評価を送信します、エンジニアも同様に貴社を評価します、両者の評価が完了した段階で、タスクは「完了」となります。
        </li>
        <li>
          完了したタスクは<strong>報酬管理画面</strong>
          より、お支払い履歴を確認することができます。
        </li>
      </ol>
    )
  },
  {
    text: 'お仕事受注の流れ',
    path: '/faq/recieve-task',
    component: (
      <ol>
        <li>
          サイドメニューの「お仕事を探す」を押下し、タスク一覧に移動のうえ、気になるタスクを押下してください。
        </li>
        <li>
          右上の「エントリー」ボタンから、タスクにエントリーすることが可能です。
        </li>
        <li>
          エントリーしたタスクに対する「アサイン依頼」が届き次第、契約についてメッセージにて企業とのやりとりを行ってください。条件等に問題がなければタスク詳細画面にて「アサイン依頼を承諾」ボタンを押下してください。これで受注完了です。
        </li>
        <li>
          アサイン依頼の承諾を行うと企業が「仮払い」を行います、「仮払い」が完了するとタスクのステータスが「作業中」へ変更され、作業開始となります。
        </li>
        <li>
          タスク詳細画面の「作業メモ」で企業と質疑応答をしながら作業を進めてください、成果物が完成しましたらタスクの納品形式に従い
          成果物を提出し「成果物確認依頼」ボタンを押下してください。
        </li>
        <li>
          成果物修正依頼が届きましたら修正を行い、修正物確認依頼ボタンを押下してください。
        </li>
        <li>
          成果物が承認されましたら取引相手の「評価」をお願いします、企業もエンジニアを評価しますので、相互の評価が終了しますとタスクは「完了」となります。
        </li>
        <li>
          完了したタスクで獲得した報酬については、サイドメニューの「報酬管理」より確認できます。
          「受け取り予定日」にて指定されている日付に、タスク運営より報酬のお支払いをさせていただきます。
        </li>
      </ol>
    )
  },
  {
    text: 'お支払い方法について',
    path: '/faq/payment-setting',
    component: (
      <div>
        <strong>クレジットカード払い</strong>と
        <strong>請求書払い（Paid）</strong>がご利用いただけます。
        <br />
        ご利用いただけるブランドは下記の通りです。
        <br />
        <strong>VISA / MASTER / JCB / AMEX / DINERS</strong>
        <br />
        <br />
        Paidのご利用には一定の審査があり、最大で３営業日程度いただいております。
      </div>
    )
  },
  {
    text: '報酬の受け取りについて',
    path: '/faq/recieve-reward',
    component: (
      <div>
        <strong>成果物確認依頼</strong>
        を企業が承認した時点で、報酬が確定します。
        <br />
        報酬確定後、振込予定日になりましたら、登録いただいている口座にTask運営が報酬の振り込みを行います。
        <br />
        <br />
        報酬確定後15日・月末の月2回の締日を設けており、
        <br />
        それぞれ当月末、翌月15日にメンバーの皆さまにご登録いただいている銀行口座に
        報酬額をお振り込みさせていただいております。
        <br />
        ご登録されている口座情報に相違があり、振込が完了しなかった場合も振込手数料が発生いたします。
        <br />
        そのため、必ずご入力いただいた口座情報に相違がないことをご確認の上、ご登録をお願いいたします。
        <br />
        <br />
        なお、振込予定日が土・日・祝日の場合は
        直前の営業日にて振込をさせて頂きます。
      </div>
    )
  },
  {
    text: 'タスクの審査基準について',
    path: '/faq/review',
    component: (
      <div>
        下記の基準を元にタスクの審査を行っております
        <br />
        <br />
        ・ 御依頼内容が明確に定まっていること。
        <br />
        ・ 報酬金額が適正であること。
        <br />
        ・ スケジュールが実現可能な範囲内であること。
        <br />
        <br />
        ご不明な点がある場合は画面右下緑色のボタンのチャットサポートから、お気軽にお問い合わせ下さい。
      </div>
    )
  },
  {
    text: 'タスクの作成自体を運営に依頼したい',
    path: '/faq/request-create-task',
    component: (
      <div>
        画面右下の「お困りですか？」チャットボタンよりご相談を承ります。
      </div>
    )
  },
  {
    text: '仮払いとは？',
    path: '/faq/pre_payment',
    component: (
      <div>
        <div>
          （１）エンジニアがアサインを承諾しましたら作業開始前に、企業がTaskへ支払い（仮払い）を行います
        </div>
        <div>
          （２）エンジニアがタスクにアサインされ、作業が進み納品を行います。
        </div>
        <div>
          （３）納品された成果物を企業が承認した段階で Task
          からエンジニアへ報酬が支払われます。
        </div>
        <br />
        <div>Task では上記の流れを「仮払い」と呼んでおります。</div>
        <br />
        <div>
          「エンジニアを募集し、誰もアサインしない状態で募集期間が終了した場合」
        </div>
        <div>
          または、「募集の取り下げを行った場合」は仮払いはキャンセルされ、お支払いは不要となります。
        </div>
      </div>
    )
  }
];
