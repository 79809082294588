import {createAction} from 'redux-actions';

// 企業に対する評価をPOSTする
export const REQUEST_POST_REVIEW_CO = 'REQUEST_POST_REVIEW_CO';
export const requestPostReviewCo = createAction(REQUEST_POST_REVIEW_CO);

// エンジニアに対する評価をPOSTする
export const REQUEST_POST_REVIEW_EN = 'REQUEST_POST_REVIEW_EN';
export const requestPostReviewEn = createAction(REQUEST_POST_REVIEW_EN);

// 評価のモーダルフラグ
export const SET_REVIEW_MODAL_FLAG = 'SET_REVIEW_MODAL_FLAG';
export const setReviewModalFlag = createAction(SET_REVIEW_MODAL_FLAG);

// 評価が必要なタスク一覧を取得（企業用）
export const REQUEST_MUST_REVIEW_TASKS = 'REQUEST_MUST_REVIEW_TASKS';
export const SUCCESS_MUST_REVIEW_TASKS = 'SUCCESS_MUST_REVIEW_TASKS';
export const requestMustReviewTasks = createAction(REQUEST_MUST_REVIEW_TASKS);
export const successMustReviewTasks = createAction(SUCCESS_MUST_REVIEW_TASKS);

// 当該企業の評価一覧を取得する
export const REQUEST_CO_REVIEWS = 'REQUEST_CO_REVIEWS';
export const SUCCESS_CO_REVIEWS = 'SUCCESS_CO_REVIEWS';
export const requestCoReviews = createAction(REQUEST_CO_REVIEWS);
export const successCoReviews = createAction(SUCCESS_CO_REVIEWS);

// 当該ユーザの評価一覧を取得する
export const REQUEST_EN_REVIEWS = 'REQUEST_EN_REVIEWS';
export const SUCCESS_EN_REVIEWS = 'SUCCESS_EN_REVIEWS';
export const requestEnReviews = createAction(REQUEST_EN_REVIEWS);
export const successEnReviews = createAction(SUCCESS_EN_REVIEWS);
