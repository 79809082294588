import React from 'react';
import {createMuiTheme, makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {MuiThemeProvider} from '@material-ui/core';
import classNames from 'classnames';
import style from './button.module.scss';

const Index = props => {
  const {kind, onClickHandler} = props;
  let applyBgColor = '';
  let applyHoverColor = '';
  let applyBorderColor = 'none';
  let valiant = 'contained';
  let applyColor = '#FFFFFF';
  let applyFontWeight = 'bold';
  let text = '';
  let srcImg = null;
  let applyFontSize = '1.6rem';
  let disabled = false;

  switch (kind) {
    case 'blue':
      applyBgColor = '#6C8AC4';
      applyHoverColor = '#506590';
      text = '修正を依頼する';
      applyFontSize = '1.7rem';
      break;

    default:
      applyBgColor = '';
      applyHoverColor = '';
      valiant = 'contained';
      break;
  }

  const useStyles = makeStyles(theme => ({
    button: {
      margin: theme.spacing(1),
      backgroundColor: applyBgColor,
      border: applyBorderColor,
      color: applyColor,
      fontWeight: applyFontWeight,
      width: '20rem',
      height: '5rem',
      fontSize: applyFontSize,
      '&:hover': {
        backgroundColor: applyHoverColor
      }
    }
  }));

  const classes = useStyles();
  return (
    <div className="stage">
      <Button
        variant={valiant}
        className={classes.button}
        disabled={disabled}
        onClick={() => onClickHandler()}
      >
        <div className={style.buttonContainer}>
          <img src={srcImg} />
          <div className={style.text}>{text}</div>
        </div>
      </Button>
    </div>
  );
};

export default Index;
