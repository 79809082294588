import React from 'react';
import {useHistory} from 'react-router-dom';
import style from './project.module.scss';
import RightArrow from '../../assets/icon_arrow_grey.svg';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import {withStyles} from '@material-ui/core/styles/index';
import {makeStyles} from '@material-ui/core/styles';
import IconArrow from '../../assets/icon_arrow_gray2.svg';

const useStyles = makeStyles(theme => ({
  button: {
    width: '12.2rem',
    color: '#093791',
    fontSize: '1.6rem',
    border: '0.1rem solid #093791',
    fontWeight: 'bold'
  },
  input: {
    display: 'none'
  },
  cardContent: {
    [theme.breakpoints.down('xs')]: {
      padding: '0.8rem'
    }
  }
}));

const List = ({list, start}) => {
  const history = useHistory();
  const classes = useStyles();
  return (
    <>
      <ul className={style.list}>
        {list.map((value, index) => {
          return (
            <li key={index}>
              <Card classes={{root: classes.mainCard}}>
                <CardActionArea
                  classes={{root: classes.cardActionArea}}
                  onClick={() => {
                    history.push(`/co/project/${value.project_id}`);
                  }}
                >
                  <CardContent classes={{root: classes.cardContent}}>
                    <div className={style.projectTitle}>{value.title}</div>
                    <div className={style.description}>{value.description}</div>
                    <div className={style.category}>
                      <div className={style.categoryItem}>
                        {value.main_category.main_category}
                      </div>
                    </div>

                    <div className={style.cardContentImg}>
                      <img src={RightArrow} className={style.cardArrow} />
                    </div>
                  </CardContent>
                </CardActionArea>
              </Card>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default List;
