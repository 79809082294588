import React from 'react';
import {withRouter} from 'react-router-dom';
import Plane from '../../../../assets/icon_plane.svg';
import style from './coSignupResult.module.scss';

const Index = () => {
  return (
    <div className={style.form}>
      <div className={style.formContent}>
        <div className={style.main}>
          <div className={style.image}>
            <img src={Plane} />
          </div>
          <p className={style.title}>仮登録を受け付けました</p>
          <div className={style.mainContent}>
            ご登録いただいたメールアドレスに
            <br />
            登録確認用のご案内を送らせていただきました。
            <br />
            メールに記載されているURLにアクセスし
            <br />
            本登録を行ってください。
            <br />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Index);
