import {take, put, call, fork, select} from 'redux-saga/effects';

import {
  requestLatestTaskList,
  successLatestTaskList
} from '../actions/taskList';
import {fetchGet} from './utills/fetch';
import {selectSetting} from '../sagas/selectors';

//最近のタスク取得
export function* getLatestList() {
  while (true) {
    const action = yield take(requestLatestTaskList);
    const setting = yield select(selectSetting);
    const isEngineer = setting.isEngineer;
    const url = `own-company-tasks`;
    //↓2次以降でエンジニアから企業情報を見た時の直近タスクを表示させる(取っておいてほしいです！)
    // const url = isEngineer
    //   ? `company-task/${action.payload}`
    //   : `own-company-tasks`;
    if (!isEngineer) {
      const [data, error] = yield call(fetchGet, url);
      if (data && !error) {
        yield put(successLatestTaskList(data.results));
      } else {
        // pass
      }
    }
  }
}

const TaskList = [fork(getLatestList)];

export default TaskList;
