import {
  SUCCESS_ME,
  SUCCESS_ME_EDIT,
  SUCCESS_MY_TASK,
  SUCCESS_ENTRY_TASK,
  SUCCESS_BOOKMARK_TASK,
  SET_IS_ENGINEER,
  SET_COMPANY_ID,
  SUCCESS_PRE_ASSIGN_LIST,
  SYNC_LOCAL_STORAGE_BASE_VALUES
} from '../actions/setting';
import store from 'store';

const initialState = {
  me: {
    tags: [],
    username: '',
    img: null,
    isInputedIdInfo: null,
    is_accept_terms_of_service: true
  },
  myTaskList: [],
  entryList: [],
  bookmarkList: [],
  nick_name: '',
  introduction: '',
  isEngineer: store.get('isEngineer'),
  isAnonymousUser: !Boolean(store.get('token')),
  companyId: null,
  preAssignList: []
};

function setting(state = initialState, action) {
  switch (action.type) {
    case SUCCESS_ME:
      return {
        ...state,
        me: {
          ...action.payload,
          isInputedIdInfo: action.payload.is_inputed_id_info
        }
      };
    case SUCCESS_ME_EDIT:
      return {
        ...state,
        me: action.payload
      };
    case SUCCESS_MY_TASK:
      return {
        ...state,
        myTaskList: action.payload.results
      };
    case SUCCESS_ENTRY_TASK:
      return {
        ...state,
        entryList: action.payload.results
      };
    case SUCCESS_BOOKMARK_TASK:
      return {
        ...state,
        bookmarkList: action.payload.results
      };
    case SET_IS_ENGINEER:
      return {
        ...state,
        isEngineer: action.payload
      };
    case SET_COMPANY_ID:
      return {
        ...state,
        companyId: action.payload
      };
    case SUCCESS_PRE_ASSIGN_LIST:
      return {
        ...state,
        preAssignList: action.payload
      };
    case SYNC_LOCAL_STORAGE_BASE_VALUES:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
}

export default setting;
