import fetch from 'isomorphic-fetch';
import {history, store as reduxStore} from '../../store';
import {API_URL} from '../../config';
import store from 'store';
import {requestRefreshToken} from '../../actions/sign';

export function fetchGet(apiName, useToken = true) {
  let headers = {};
  if (useToken) {
    headers = {
      'Content-Type': 'application/json',
      Authorization: store.get('token') && `Bearer ${store.get('token')}`
    };
  } else {
    headers = {
      'Content-Type': 'application/json'
    };
  }
  return fetch(`${API_URL}${apiName}`, {
    method: 'GET',
    headers: headers
  })
    .then(response => {
      if (response.status === 204) {
        return {status: response.status, json: 'json'};
      } else {
        return response.json().then(json => ({
          status: response.status,
          json: json
        }));
      }
    })
    .then(({status, json}) => {
      return responseHandler(status, json, apiName);
    })
    .catch(() => {
      history.push('/sorry');
    });
}
export function fetchGetBlob(apiName, payload) {
  return fetch(`${apiName}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: store.get('token') && `Bearer ${store.get('token')}`
    }
  })
    .then(result => {
      return result.blob().then(myBlob => {
        return myBlob;
      });
    })
    .then(myBlob => {
      return [myBlob, null];
    })
    .catch(err => {
      console.error(err);
    });
}

export function fetchPostJson(apiName, payload, notUseToken = false) {
  let headers = {};
  if (notUseToken) {
    headers = {
      'Content-Type': 'application/json'
    };
  } else {
    headers = {
      'Content-Type': 'application/json',
      Authorization: store.get('token') && `Bearer ${store.get('token')}`
    };
  }

  const PostUrl =
    apiName == 'oauth/github'
      ? `${API_URL}${apiName}`
      : `${API_URL}${apiName}/`;

  return fetch(PostUrl, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: headers
  })
    .then(response => {
      if (response.status === 204) {
        return {status: response.status, json: 'json'};
      } else {
        return response
          .json()
          .catch(error => {
            return {status: response.status, json: 'no content'};
          })
          .then(json => ({
            status: response.status,
            json: json
          }));
      }
    })
    .then(({status, json}) => {
      return responseHandler(status, json, apiName);
    })
    .catch(err => {
      console.log(err);
      history.push('/sorry');
    });
}

export function fetchPostMulti(apiName, payload) {
  const formData = new FormData();
  formData.append('text', payload.text);
  // TODO: ファイル送信のKeyが異なるので、後でバックエンドを修正して適用する
  const end_point = apiName.split('/');
  if (end_point[end_point.length - 1] === 'additional-text') {
    payload.attachedFiles.forEach((file, index, array) => {
      formData.append('files', file);
    });
  } else {
    payload.attachedFiles.forEach((file, index, array) => {
      formData.append('set_file', file);
    });
  }

  return fetch(`${API_URL}${apiName}/`, {
    method: 'POST',
    body: formData,
    headers: {
      Authorization: store.get('token') && `Bearer ${store.get('token')}`
    }
  })
    .then(response => {
      if (response.status === 204) {
        return {status: response.status, json: 'json'};
      } else {
        return response.json().then(json => ({
          status: response.status,
          json: json
        }));
      }
    })
    .then(({status, json}) => {
      return responseHandler(status, json, apiName);
    })
    .catch(() => {
      history.push('/sorry');
    });
}

export function fetchPostTask(apiName, payload, method = 'POST') {
  const formData = CreateFormData(payload, []);

  return fetch(`${API_URL}${apiName}/`, {
    method: method,
    body: formData,
    headers: {
      Authorization: store.get('token') && `Bearer ${store.get('token')}`
    }
  })
    .then(response => {
      if (response.status === 204) {
        return {status: response.status, json: 'json'};
      } else {
        return response.json().then(json => ({
          status: response.status,
          json: json
        }));
      }
    })
    .then(({status, json}) => {
      return responseHandler(status, json, apiName);
    })
    .catch(() => {
      history.push('/sorry');
    });
}

export function fetchPatch(apiName, payload) {
  return fetch(`${API_URL}${apiName}/`, {
    method: 'PATCH',
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json',
      Authorization: store.get('token') && `Bearer ${store.get('token')}`
    }
  })
    .then(response => {
      if (response.status === 204) {
        return {status: response.status, json: 'json'};
      } else {
        return response.json().then(json => ({
          status: response.status,
          json: json
        }));
      }
    })
    .then(({status, json}) => {
      return responseHandler(status, json, apiName);
    })
    .catch(() => {
      history.push('/sorry');
    });
}

export function fetchPut(apiName, payload) {
  return fetch(`${API_URL}${apiName}/`, {
    method: 'PUT',
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json',
      Authorization: store.get('token') && `Token ${store.get('token')}`
    }
  })
    .then(response => {
      if (response.status === 204) {
        return {status: response.status, json: 'json'};
      } else {
        return response.json().then(json => ({
          status: response.status,
          json: json
        }));
      }
    })
    .then(({status, json}) => {
      return responseHandler(status, json, apiName);
    })
    .catch(() => {
      history.push('/sorry');
    });
}

export function fetchDelete(apiName, payload) {
  return fetch(`${API_URL}${apiName}`, {
    method: 'DELETE',
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json',
      Authorization: store.get('token') && `Bearer ${store.get('token')}`
    }
  })
    .then(response => {
      if (response.status === 204) {
        return {status: response.status, json: 'json'};
      } else {
        return response.json().then(json => ({
          status: response.status,
          json: json
        }));
      }
    })
    .then(({status, json}) => {
      return responseHandler(status, json, apiName);
    })
    .catch(() => {
      history.push('/sorry');
    });
}

const CreateFormData = (payload, noBinds) => {
  // noBinds には payload に紐付けたくないフィールド名を配列で渡してください
  const objectKeyList = Object.keys(payload);
  const keyList = objectKeyList.filter(key => !noBinds.includes(key));

  const formData = new FormData();

  for (let i = 0; i < keyList.length; i++) {
    if (keyList[i] !== 'id' && keyList[i] !== 'file') {
      formData.append(keyList[i], payload[keyList[i]]);
    } else if (keyList[i] === 'file') {
      payload.file.forEach(file => {
        formData.append('file', file);
      });
    }
  }
  return formData;
};

const responseHandler = (status, json, apiName) => {
  if (status < 300) {
    return [json, undefined];
  } else {
    if (status === 401) {
      if (store.get('refreshToken')) {
        reduxStore.dispatch(requestRefreshToken());
      } else {
        history.push('/signin');
      }
      return [undefined, status];
    }
    return [undefined, json];
  }
};
