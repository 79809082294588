import {createAction} from 'redux-actions';

// ファーストビューの一覧
export const SUCCESS_HEAD_SYSTEM_NEWS_LIST = 'SUCCESS_HEAD_SYSTEM_NEWS_LIST';
export const successHeadSystemNewsList = createAction(
  SUCCESS_HEAD_SYSTEM_NEWS_LIST
);

// もっと見るの一覧
export const REQUEST_SYSTEM_NEWS_LIST = 'REQUEST_SYSTEM_NEWS_LIST';
export const SUCCESS_SYSTEM_NEWS_LIST = 'SUCCESS_SYSTEM_NEWS_LIST';
export const requestSystemNewsList = createAction(REQUEST_SYSTEM_NEWS_LIST);
export const successSystemNewsList = createAction(SUCCESS_SYSTEM_NEWS_LIST);

// 最後に取得したドキュメントを保存
export const SET_SYSTEM_NEWS_LAST_SNAPSHOT = 'SET_SYSTEM_NEWS_LAST_SNAPSHOT';
export const setSystemNewsLastSnapshot = createAction(
  SET_SYSTEM_NEWS_LAST_SNAPSHOT
);

// リッスンで継続的に取得するスナップショットをハンドリング
export const REQUEST_HANDLE_ONSNAPSHOT = 'REQUEST_HANDLE_ONSNAPSHOT';
export const requestHandleOnSnapshot = createAction(REQUEST_HANDLE_ONSNAPSHOT);

// 全て読んだかどうかをセット
export const SET_IS_READ_ALL_SYSTEM_NOTIFICATION =
  'SET_IS_READ_ALL_SYSTEM_NOTIFICATION';
export const setIsReadAllSystemNotification = createAction(
  SET_IS_READ_ALL_SYSTEM_NOTIFICATION
);
