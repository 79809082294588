import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styles from './messageBox.module.scss';
import NotAvailable from '../NotAvailable';
import RoomListScroll from './RoomListScroll';
import MessageField from './MessageField';
import {requestCustomToken} from '../../actions/firebase';
import {
  requestRoomList,
  requestInitRooms,
  requestMessageList,
  requestPostMessage,
  setMessageText,
  setSelectedRoomInfo
} from '../../actions/message';
import {mediaQuery} from '../../constants';
import MediaQuery from 'react-responsive';
import classNames from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import ArrowBack from '@material-ui/icons/ArrowBack';
import {makeStyles} from '@material-ui/core/styles';
import {useLocation} from 'react-router-dom';

const useStyles = makeStyles({
  svgIcon: {
    fontSize: '2rem'
  }
});

const MessageBox = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const location = useLocation();

  const isEngineer = useSelector(state => state.setting.isEngineer);
  const rooms = useSelector(state => state.message.rooms);
  const hasMore = useSelector(state => state.message.hasMore);
  const me = useSelector(state => state.setting.me);
  const user = useSelector(state => state.user);
  const message = useSelector(state => state.message);
  const messages = message.messages;
  const {isLoggedIn} = useSelector(state => state.firebase);

  useEffect(() => {
    if (me.id && isLoggedIn !== null) {
      dispatch(requestRoomList({useRefresh: true}));
    }
  }, [me]);

  return (
    <>
      {rooms.length > 0 ? (
        <div className={styles.main}>
          <MainContents
            dispatch={dispatch}
            me={me}
            user={user}
            messages={messages}
            rooms={rooms}
            isEngineer={isEngineer}
            message={message}
            hasMore={hasMore}
            classes={classes}
            defaultState={location.state}
          />
        </div>
      ) : (
        <div className={styles.notAvailableWrapper}>
          <NotAvailable kind="no-message" />
        </div>
      )}
    </>
  );
};

const MainContents = ({
  rooms,
  me,
  user,
  messages,
  dispatch,
  isEngineer,
  message,
  hasMore,
  classes,
  defaultState
}) => {
  const isSelected = Boolean(message.selectedRoomId);
  return (
    <>
      <MediaQuery query={mediaQuery.min}>
        <div className={styles.roomListContainer}>
          <div className={styles.searchContainer}>
            <p className={styles.searchContainerTitle}>メッセージルーム</p>
          </div>
          <RoomListScroll
            isEngineer={isEngineer}
            rooms={rooms}
            me={me}
            user={user}
            requestRoomList={requestRoomList}
            requestInitRooms={value => dispatch(requestInitRooms(value))}
            message={message}
            hasMore={hasMore}
          />
          <div className={styles.loadingContainer} />
        </div>
        <div
          className={classNames(
            styles.BaseMsgFieldContainer,
            styles.msgFieldLayoutPc
          )}
        >
          <MessageField
            defaultState={defaultState}
            message={message}
            messages={messages}
            requestMessageList={() => dispatch(requestMessageList())}
            me={me}
            user={user}
            isEngineer={isEngineer}
            requestPostMessage={value => dispatch(requestPostMessage(value))}
            setMessageText={value => dispatch(setMessageText(value))}
          />
        </div>
      </MediaQuery>
      <MediaQuery query={mediaQuery.max}>
        <div className={isSelected ? styles.hide : styles.maxWidth}>
          <div className={styles.searchContainer}>
            <p className={styles.searchContainerTitle}>メッセージルーム</p>
          </div>
          <RoomListScroll
            isEngineer={isEngineer}
            rooms={rooms}
            me={me}
            user={user}
            requestRoomList={requestRoomList}
            requestInitRooms={value => dispatch(requestInitRooms(value))}
            message={message}
            hasMore={hasMore}
          />
          <div className={styles.loadingContainer} />
        </div>
        <div
          className={
            isSelected
              ? classNames(
                  styles.BaseMsgFieldContainer,
                  styles.msgFieldLayoutSp
                )
              : styles.hide
          }
        >
          <IconButton
            color={'primary'}
            className={styles.arrowBack}
            onClick={() =>
              dispatch(
                setSelectedRoomInfo({
                  roomId: '',
                  title: ''
                })
              )
            }
          >
            <ArrowBack classes={{root: classes.svgIcon}} />
          </IconButton>
          <MessageField
            defaultState={defaultState}
            message={message}
            messages={messages}
            requestMessageList={() => dispatch(requestMessageList())}
            me={me}
            user={user}
            isEngineer={isEngineer}
            requestPostMessage={value => dispatch(requestPostMessage(value))}
            setMessageText={value => dispatch(setMessageText(value))}
          />
        </div>
      </MediaQuery>
    </>
  );
};

export default MessageBox;
