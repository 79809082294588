import {
  DETACH_PERSONAL_NOTIFICATION_FUNCTION,
  SET_LAST_PERSONAL_NOTIFICATION
} from '../actions/personalNotification';
import {DETACH_SYSTEM_NOTIFICATION_FUNCTION} from '../actions/systemNotification';
const initialState = {
  isReadAllPersonalNotification: null,
  systemNotificationAlreadyRead: null,
  //リスナーデタッチ用の関数
  personalNotificationFunction: [],
  systemNotificationFunction: null,
  personalNotificationLastSnapshot: {id: null, exists: false}
};

const personalNotification = (state = initialState, action) => {
  switch (action.type) {
    case DETACH_PERSONAL_NOTIFICATION_FUNCTION:
      state.personalNotificationFunction.push(action.payload);
      return {
        ...state,
        personalNotificationFunction: state.personalNotificationFunction
      };

    case DETACH_SYSTEM_NOTIFICATION_FUNCTION:
      return {
        ...state,
        systemNotificationFunction: action.payload
      };

    case SET_LAST_PERSONAL_NOTIFICATION:
      return {
        ...state,
        personalNotificationLastSnapshot: action.payload
      };

    default:
      return state;
  }
};

export default personalNotification;
