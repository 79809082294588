import React from 'react';
import {connect} from 'react-redux';
import {
  Field,
  FieldArray,
  change,
  reduxForm,
  formValueSelector
} from 'redux-form';
import style from './idInfoEditForm.module.scss';
import commonStyle from '../../common.module.scss';
import ContentTitle from '../../../../components/ContentTitle';
import classNames from 'classnames';
import {renderText, renderTextArea, renderSelect} from '../../../Field';
import FormHelperText from '@material-ui/core/FormHelperText/index';
import MenuItem from '@material-ui/core/MenuItem/index';
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles/index';
import Button from '@material-ui/core/Button/index';
import {withStyles} from '@material-ui/core/styles/index';
import {
  requestMeEdit,
  requestEditSecureInfo
} from '../../../../actions/setting';
import Key from '../../../../assets/icon_key_blue.svg';
//validate関数
import * as Validator from '../../utils/validate';
import Blank from '../../../Blank';
import {splitHyphen} from '../../../../common';
import FormError from '../../FormError';

const styles = {
  inputHalf: {
    width: '50%',
    fontSize: '1.6rem'
  },
  submitButton: {
    width: '30rem',
    height: '5rem',
    fontSize: '1.8rem',
    fontWeight: 'bold',
    color: '#093791',
    borderColor: '#093791',
    '&:hover': {
      background: '#D7E2F4'
    }
  },
  bigAvatar: {
    width: '18rem',
    height: '18rem'
  },
  imgButton: {
    borderRadius: '9rem',
    overflow: 'hidden'
  },
  cameraImgButton: {
    borderRadius: '9rem',
    overflow: 'hidden',
    minWidth: '4rem',
    position: 'absolute',
    top: '13rem',
    right: '0.5rem'
  }
};

const theme = createMuiTheme({
  overrides: {
    MuiFormControl: {
      marginNormal: {
        marginTop: 0,
        marginBottom: 0,
        width: '20rem'
      }
    },
    MuiInputBase: {
      input: {
        fontSize: '1.6rem'
      }
    },
    MuiOutlinedInput: {
      root: {
        backgroundColor: '#F7F7F7'
      },
      inputMultiline: {
        lineHeight: '1.6rem'
      }
    },
    MuiButton: {
      root: {
        padding: 0
      },
      contained: {
        color: '#FFFFFF'
      }
    }
  }
});

// マスタは設けていないので、ここで定数定義
const KIND_CHOICES = [
  {id: 1, title: '普通'},
  {id: 2, title: '当座'}
];

class Index extends React.Component {
  render() {
    const {
      error,
      handleSubmit,
      submitErrors,
      pristine,
      submitting,
      classes,
      setUserFiles
    } = this.props;

    return (
      <MuiThemeProvider theme={theme}>
        <div className={style.maxWidth}>
          <div>
            <div className={style.privateMsg}>
              <div className={style.flexContainer}>
                <img src={Key} />
                <p
                  className={classNames(style.titleText, style.giveMarginLeft)}
                >
                  本人確認情報の登録
                </p>
              </div>
            </div>
            <div className={style.row}>
              <div className={style.attention}>
                このページに入力された内容が、他のユーザに表示されることはありません
              </div>
            </div>
          </div>
          <form onSubmit={handleSubmit} className={style.container}>
            <div className={classNames(commonStyle.main, style.infoContainer)}>
              {error && <p className={style.error}>{error}</p>}
              <ContentTitle title="本人確認情報" />
              <div className={style.mulchRowContainer}>
                <div className={style.firstRow}>
                  <div
                    className={classNames(style.rowTitle, style.subtitleText)}
                  >
                    <p>氏名</p>
                  </div>
                  <div
                    className={classNames(
                      commonStyle.rowRequire,
                      style.requiredContainer
                    )}
                  >
                    <label className={commonStyle.required}>必須</label>
                  </div>
                  <div className={style.rowInputFullWidth}>
                    <Field
                      name="last_name_kanji"
                      required={true}
                      component={renderText}
                      fullWidth={true}
                      placeholder={'佐藤'}
                      validate={[Validator.RequiredItem]}
                    />
                  </div>
                  <div
                    className={classNames(
                      style.rowInputFullWidth,
                      style.giveMarginLeft
                    )}
                  >
                    <Field
                      name="first_name_kanji"
                      required={true}
                      component={renderText}
                      fullWidth={true}
                      placeholder={'太郎'}
                      validate={[Validator.RequiredItem]}
                    />
                  </div>
                </div>
                <div className={style.otherRow}>
                  <div
                    className={classNames(style.rowTitle, style.subtitleText)}
                  >
                    <p></p>
                  </div>
                  <div
                    className={classNames(
                      commonStyle.rowRequire,
                      style.rowRequire
                    )}
                  >
                    <label className={commonStyle.notRequired}>必須</label>
                  </div>
                  <div className={style.rowInputFullWidth}>
                    <Field
                      name="last_name_kana"
                      required={true}
                      component={renderText}
                      fullWidth={true}
                      placeholder={'タスク'}
                    />
                  </div>
                  <div
                    className={classNames(
                      style.rowInputFullWidth,
                      style.giveMarginLeft
                    )}
                  >
                    <Field
                      name="first_name_kana"
                      required={true}
                      component={renderText}
                      fullWidth={true}
                      placeholder={'タロウ'}
                    />
                  </div>
                </div>
              </div>
              <div className={style.divider} />
              <div className={style.mulchRowContainer}>
                <div className={style.firstRow}>
                  <div
                    className={classNames(style.rowTitle, style.subtitleText)}
                  >
                    <p>住所</p>
                  </div>
                  <div className={commonStyle.rowRequire}>
                    <label className={commonStyle.required}>必須</label>
                  </div>
                  <div className={style.addressSubTitle}>郵便番号</div>
                  <div className={style.zipcodeContainer}>
                    <Field
                      name="zipCode1"
                      required={true}
                      component={renderText}
                      fullWidth={true}
                      placeholder={'123'}
                      validate={[Validator.RequiredItem]}
                    />
                    <Blank width={'1.6rem'} />
                    -
                    <Blank width={'1.6rem'} />
                    <Field
                      name="zipCode2"
                      required={true}
                      component={renderText}
                      fullWidth={true}
                      placeholder={'4567'}
                      validate={[Validator.RequiredItem]}
                    />
                  </div>
                </div>
                <div className={classNames(style.firstRow, style.marginTop)}>
                  <div
                    className={classNames(style.rowTitle, style.subtitleText)}
                  >
                    <p></p>
                  </div>
                  <div className={commonStyle.rowRequire}>
                    <label className={commonStyle.required}>必須</label>
                  </div>
                  <div className={style.addressSubTitle}>都道府県</div>
                  <div className={style.rowInputFullWidth}>
                    <Field
                      name="prefecture"
                      required={true}
                      component={renderText}
                      fullWidth={true}
                      placeholder={'東京都'}
                      validate={[Validator.RequiredPrefecture]}
                    />
                  </div>
                </div>
                <div className={classNames(style.firstRow, style.marginTop)}>
                  <div
                    className={classNames(style.rowTitle, style.subtitleText)}
                  >
                    <p></p>
                  </div>
                  <div className={commonStyle.rowRequire}>
                    <label className={commonStyle.required}>必須</label>
                  </div>
                  <div className={style.addressSubTitle}>市区町村</div>
                  <div className={style.rowInputFullWidth}>
                    <Field
                      name="address1"
                      required={true}
                      component={renderText}
                      fullWidth={true}
                      placeholder={'中央区'}
                      validate={[Validator.RequiredItem]}
                    />
                  </div>
                </div>
                <div className={classNames(style.firstRow, style.marginTop)}>
                  <div
                    className={classNames(style.rowTitle, style.subtitleText)}
                  >
                    <p></p>
                  </div>
                  <div className={commonStyle.rowRequire}>
                    <label className={commonStyle.required}>必須</label>
                  </div>
                  <div className={style.addressSubTitle}>番地</div>
                  <div className={style.rowInputFullWidth}>
                    <Field
                      name="address2"
                      required={true}
                      component={renderText}
                      fullWidth={true}
                      placeholder={'銀座１ー１６ー７'}
                      validate={[Validator.RequiredItem]}
                    />
                  </div>
                </div>
                <div className={classNames(style.firstRow, style.marginTop)}>
                  <div
                    className={classNames(style.rowTitle, style.subtitleText)}
                  >
                    <p></p>
                  </div>
                  <div className={commonStyle.rowRequire}>
                    <label className={commonStyle.notRequired}>必須</label>
                  </div>
                  <div className={style.addressSubTitle}>建物名</div>
                  <div className={style.rowInputFullWidth}>
                    <Field
                      name="address3"
                      required={true}
                      component={renderText}
                      fullWidth={true}
                    />
                  </div>
                </div>
              </div>
              <div className={style.divider} />
              <div className={style.mulchRowContainer}>
                <div className={style.firstRow}>
                  <div
                    className={classNames(style.rowTitle, style.subtitleText)}
                  >
                    <p>振込先</p>
                  </div>
                  <div className={commonStyle.rowRequire}>
                    <label className={commonStyle.required}>必須</label>
                  </div>
                  <div className={style.addressSubTitle}>銀行名</div>
                  <div className={style.rowInputFullWidth}>
                    <Field
                      name="bank_name"
                      required={true}
                      component={renderText}
                      fullWidth={true}
                      placeholder={'〇〇銀行'}
                      validate={[Validator.RequiredItem]}
                    />
                  </div>
                </div>
                <div className={classNames(style.firstRow, style.marginTop)}>
                  <div
                    className={classNames(style.rowTitle, style.subtitleText)}
                  >
                    <p></p>
                  </div>
                  <div className={commonStyle.rowRequire}>
                    <label className={commonStyle.required}>必須</label>
                  </div>
                  <div className={style.addressSubTitle}>支店名</div>
                  <div className={style.rowInputFullWidth}>
                    <Field
                      name="branch_name"
                      required={true}
                      component={renderText}
                      fullWidth={true}
                      placeholder={'〇〇支店'}
                      validate={[Validator.RequiredItem]}
                    />
                  </div>
                </div>
                <div className={classNames(style.firstRow, style.marginTop)}>
                  <div
                    className={classNames(style.rowTitle, style.subtitleText)}
                  >
                    <p></p>
                  </div>
                  <div className={commonStyle.rowRequire}>
                    <label className={commonStyle.required}>必須</label>
                  </div>
                  <div className={style.addressSubTitle}>口座種別</div>
                  <div className={style.rowInputFullWidth}>
                    <Field
                      name="account_type"
                      required={true}
                      component={renderSelect}
                      className={classes.inputHalf}
                      validate={[Validator.RequiredItem]}
                    >
                      <MenuItem value="">選択してください</MenuItem>
                      {KIND_CHOICES.map((elem, index) => (
                        <MenuItem value={elem.id} key={index.toString()}>
                          {elem.title}
                        </MenuItem>
                      ))}
                    </Field>
                  </div>
                </div>
                <div className={classNames(style.firstRow, style.marginTop)}>
                  <div
                    className={classNames(style.rowTitle, style.subtitleText)}
                  >
                    <p></p>
                  </div>
                  <div className={commonStyle.rowRequire}>
                    <label className={commonStyle.required}>必須</label>
                  </div>
                  <div className={style.addressSubTitle}>口座番号</div>
                  <div className={style.rowInputFullWidth}>
                    <Field
                      name="account_number"
                      required={true}
                      component={renderText}
                      fullWidth={true}
                      placeholder={'1234567'}
                      validate={[Validator.RequiredInteger]}
                    />
                  </div>
                </div>
              </div>
              <div className={style.divider} />
              <Blank height={'2.4rem'} />
              <FormError error={error} />
              <div className={commonStyle.submits}>
                <div className={commonStyle.submit}>
                  <Button
                    className={classes.submitButton}
                    type="submit"
                    disabled={pristine || submitting}
                    variant="outlined"
                    color="primary"
                  >
                    {'保存する'}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </MuiThemeProvider>
    );
  }
}

Index = reduxForm({
  enableReinitialize: true,
  form: 'editEnIdInfo',
  multipartForm: true
})(Index);

const mapStateToProps = state => {
  let initialValues = {
    first_name_kana: state.setting.me.first_name_kana,
    first_name_kanji: state.setting.me.first_name_kanji,
    last_name_kana: state.setting.me.last_name_kana,
    last_name_kanji: state.setting.me.last_name_kanji,
    zipCode1: state.setting.me.zip_code
      ? splitHyphen(state.setting.me.zip_code)[0]
      : null,
    zipCode2: state.setting.me.zip_code
      ? splitHyphen(state.setting.me.zip_code)[1]
      : null,
    prefecture: state.setting.me.prefecture,
    address1: state.setting.me.address1,
    address2: state.setting.me.address2,
    address3: state.setting.me.address3,
    bank_name: state.setting.me.bank_name,
    branch_name: state.setting.me.branch_name,
    account_number: state.setting.me.account_number,
    account_type: state.setting.me.account_type
  };

  return {
    initialValues: initialValues
  };
};

const mapDispatchToProps = dispatch => ({
  onSubmit(value) {
    dispatch(requestEditSecureInfo(value));
  }
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(Index)
);
