import React from 'react';
import {Field} from 'redux-form';
import {
  renderPassword,
  renderText,
  renderCheckbox
} from '@/components/Field/index';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import Blank from '../../components/Blank';
import IconPhone from '../../assets/icon_phone.svg';
import IconMail from '../../assets/icon_mail.svg';
import IconKey from '../../assets/icon_key.svg';

import style from './form.module.scss';

const SigninForm = props => {
  const {
    error,
    handleSubmit,
    submitErrors,
    pristine,
    submitting,
    isOpenTwoFactorAuthInput
  } = props;
  return (
    <div>
      {error && <p className={style.error}>{error}</p>}
      <form onSubmit={handleSubmit}>
        <div className={style.items}>
          <InputLabel htmlFor="custom-css-standard-input">
            <p className={style.label}>
              <img src={IconMail} alt={'mail'} className={style.labelIcon} />
              メールアドレス
            </p>
          </InputLabel>
          <div className={style.inputField}>
            <Field
              name="username"
              id="username"
              required={true}
              component={renderText}
              fullWidth={true}
            />
          </div>
          {submitErrors && submitErrors.username && (
            <FormHelperText>
              <span className={style.error}>{submitErrors.email}</span>
            </FormHelperText>
          )}
        </div>
        <div className={style.items}>
          <InputLabel htmlFor="custom-css-standard-input">
            <p className={style.label}>
              <img src={IconKey} alt={'password'} className={style.labelIcon} />
              パスワード
            </p>
          </InputLabel>
          <div className={style.inputField}>
            <Field
              name="password"
              id="password"
              required={true}
              component={renderPassword}
            />
          </div>
          {submitErrors && submitErrors.validationError && (
            <FormHelperText>
              <span className={style.error}>
                {submitErrors.validationError}
              </span>
            </FormHelperText>
          )}
        </div>
        {isOpenTwoFactorAuthInput && (
          <div className={style.items}>
            <InputLabel htmlFor="custom-css-standard-input">
              <p className={style.label}>
                <img
                  src={IconPhone}
                  alt={'phone'}
                  className={style.labelIcon}
                />
                ２段階認証トークン
              </p>
            </InputLabel>
            <div className={style.inputField}>
              <Field
                name="one_time_token"
                id="one_time_token"
                required={true}
                component={renderText}
              />
            </div>
          </div>
        )}
        <Blank height={'2.5rem'} />
        {/*<div className={style.checkbox}>*/}
        {/*<div>*/}
        {/*  <Field*/}
        {/*    name="password"*/}
        {/*    id="password"*/}
        {/*    required={true}*/}
        {/*    // label="次回から自動的にログイン"*/}
        {/*    component={renderCheckbox}*/}
        {/*  />*/}
        {/*</div>*/}
        {/*<p className={style.checkboxLabel}>次回から自動的にログイン</p>*/}
        {/*</div>*/}
        <div className={style.submit}>
          <input id="submit" type="submit" value={''} />
          <label htmlFor="submit">
            <Button
              disabled={pristine || submitting}
              variant="contained"
              component="span"
              color="secondary"
            >
              ログイン
            </Button>
          </label>
        </div>
      </form>
    </div>
  );
};

export default SigninForm;
