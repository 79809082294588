import React from 'react';
import style from './header.module.scss';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import {withStyles} from '@material-ui/core/styles/index';

const styles = {
  divider: {
    height: '0.2rem',
    background: '#093791',
    marginTop: '1.5rem'
  },
  button: {
    width: '12rem',
    border: '0.1rem solid #093791',
    color: '#093791',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '1.5rem',
    lineHeight: '2rem',
    textAlign: 'center',
    marginLeft: 'auto'
  }
};

const Index = props => {
  const {classes, headerText} = props;

  return (
    <div>
      <div className={style.main}>
        <p className={style.header}>{headerText}</p>
        <Button variant="outlined" className={classes.button}>
          編集する
        </Button>
      </div>
      <Divider
        classes={{
          root: classes.divider
        }}
      />
    </div>
  );
};

export default withStyles(styles)(Index);
