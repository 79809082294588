import {SET_CONFIRM_MODAL_FLAG} from '../actions/complete';

const initialState = {
  completeModalFlag: false
};

const complete = (state = initialState, action) => {
  switch (action.type) {
    case SET_CONFIRM_MODAL_FLAG:
      return {
        ...state,
        completeModalFlag: action.payload
      };

    default:
      return state;
  }
};

export default complete;
