import {
  SUCCESS_ACCOUNT_LIST,
  SUCCESS_ACCOUNT_DETAIL
} from '../actions/account';

const initialState = {
  list: [],
  detail: {}
};

function account(state = initialState, action) {
  switch (action.type) {
    case SUCCESS_ACCOUNT_LIST:
      return {
        ...state,
        list: action.payload
      }
    case SUCCESS_ACCOUNT_DETAIL:
      return {
        ...state,
        detail: action.payload
      }
    default:
      return state
  }
}

export default account
