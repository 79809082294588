import React from 'react';
import {COLOR, SHAPE, SIZE, FONT_SIZE} from './constants';

const CommonLabel = ({text, color, size, shape, addStyle}) => {
  let styles = {
    ...COLOR[color],
    ...SHAPE[shape],
    ...SIZE[size],
    ...FONT_SIZE[size],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    ...addStyle
  };

  return <div style={styles}>{text}</div>;
};

export default CommonLabel;
