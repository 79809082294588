// テキストエリアに使用する定型分の定義です

// プロジェクト新規作成 概要
export const NEW_PROJECT_PHRASE =
  '\n' +
  '【 依頼の目的/概要 】\n' +
  '・概要：ファッション関連のショッピングサイトを開発して貰いたい。\n' +
  '・依頼工程：要件定義 / 設計 / フロントエンド開発 / バックエンド開発 / 保守・運用 など\n' +
  '・欲しい機能：決済機能 / 商品情報一覧機能 / 商品レビュー機能 など\n' +
  '\n' +
  '\n' +
  '【 作業工程 】\n' +
  '要件定義：未 / 着手中 / 済\n' +
  '設計：未 / 着手中 / 済\n' +
  '実装：未 / 着手中 / 済\n' +
  'テスト：未 / 着手中 / 済\n' +
  '保守・運用：未 / 着手中 / 済\n' +
  '\n' +
  '\n';

// タスク新規作成　概要
export const NEW_TASK_PHRASE =
  '\n' +
  '【 概要 】\n' +
  '決済機能の開発をお願いします。\n' +
  '\n' +
  '\n' +
  '【 用意してあるもの 】\n' +
  '・仕様書：作成済 / 相談して決めたい\n' +
  '\n' +
  '\n' +
  '【 納期 】\n' +
  '◯月◯日まで / 相談して決めたい\n' +
  '\n' +
  '\n' +
  '【 重視する点・開発経験 】\n' +
  '◯◯を使った開発のご経験\n' +
  '〇〇という業界に興味を持って取り組める方 など\n' +
  '\n' +
  '\n' +
  '【 応募方法 】\n' +
  '・ご自身の自己紹介と経歴を入力頂いた上でのエントリーをお願い致します。\n' +
  '\n' +
  '\n' +
  'ご質問がありましたら、質問タブより気軽にお問い合わせください。\n' +
  '応募をお待ちしております！\n' +
  '\n' +
  '\n';
