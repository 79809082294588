import React, {useMemo} from 'react';
import ProfileEditForm from './ProfileEditForm';
import {useDispatch, useSelector} from 'react-redux';
import {requestMeEdit} from '../../../../actions/setting';
import {isGcsUrl} from '../../../../common';

const ProfileEdit = () => {
  const dispatch = useDispatch();
  const {me} = useSelector(state => state.setting);

  const initialTags = useMemo(
    () =>
      me.tags
        ? me.tags.map(tag => {
            return {name: tag};
          })
        : [],
    [me.tags]
  );

  if (me) {
    return (
      <ProfileEditForm
        onSubmit={values => {
          if (isGcsUrl(values.img)) delete values.img;
          values.tags = values.tags.map(item => item.name);
          dispatch(requestMeEdit(values));
        }}
        initialValues={{
          img: me.img,
          status: me.status,
          nick_name: me.nick_name,
          tags: initialTags,
          introduction: me.introduction,
          career: me.career
        }}
      />
    );
  } else {
    return <></>;
  }
};

export default ProfileEdit;
