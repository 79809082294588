import {fetchPostJson, fetchGet, fetchPatch} from './utills/fetch';
import {take, put, fork, call, select} from 'redux-saga/effects';
import {reset, startSubmit, stopSubmit} from 'redux-form';

import {
  requestPaymentList,
  successPaymentList,
  requestCreditEditUrl,
  successCreditEditUrl,
  requestPaymentState,
  successPaymentState,
  requestSaveAsGmoMember,
  requestSecureCreditLimitUrl,
  successSecureCreditLimitUrl,
  requestCheckCreditAuthorization,
  requestPaidApplication,
  setPaidModalFlag,
  requestPaymentUsingCredit,
  requestPaymentUsingPaid,
  requestPaidAuthori
} from '../actions/payment';
import {successImportantNotifications} from '../actions/notification';
import {history} from '../store';
import store from 'store';
import {PAYMENT_METHODS} from '../constants';
import {setModalSetting} from '../actions/task';
import {formatErrorResponse} from '../common';
import {setIsSubmitting} from '../actions/common';
import {store as reduxStore} from '../store';

const CREDIT_CARD = 0;
const PAID = 1;

export function* getPaymentList() {
  while (true) {
    const action = yield take(requestPaymentList);
    const [data, error] = yield call(fetchGet, 'payments/');
    yield put(successPaymentList(data.results));
  }
}

export function* getCreditEditUrl() {
  while (true) {
    const action = yield take(requestCreditEditUrl);
    let [data, error] = [null, null];
    if (action.payload.taskId) {
      [data, error] = yield call(
        fetchGet,
        `gmo/card_edit_url/?task_id=${action.payload.taskId}`
      );
    } else {
      [data, error] = yield call(fetchGet, 'gmo/card_edit_url/');
    }
    if (data && !error) {
      yield put(successCreditEditUrl(data));
    } else {
      // TODO: error process
    }
  }
}

export function* postSecureCreditLimitUrl() {
  while (true) {
    const action = yield take(requestSecureCreditLimitUrl);
    const [data, error] = yield call(fetchPostJson, 'gmo/task_settle_url', {
      task_id: action.payload
    });
    if (data && !error) {
      yield put(successSecureCreditLimitUrl(data));
    } else {
      // TODO: error process
    }
  }
}
export function* getPaymentState() {
  while (true) {
    yield take(requestPaymentState);
    const [data, error] = yield call(fetchGet, `gmo/confirm_client_info/`);
    if (data && !error) {
      yield put(successPaymentState(data));
    } else {
      console.error('決済APIとの疎通に失敗しました。');
    }
  }
}

// TODO: 今後の実装で使用
// export function* getSaveAsGmoMember() {
//   while (true) {
//     yield take(requestSaveAsGmoMember);
//     const {importantNotifications} = yield select(state => state.notification);
//     const [data, error] = yield call(fetchGet, `gmo/register_client/`);
//     if (data && !error) {
//       const newNotifications = clonedeep(importantNotifications);
//       let notice = {};
//       if (!data.result) {
//         // GMOが落ちている時のテキスト
//         notice = {
//           notice: '現在、支払先の設定を行う事ができません。'
//         };
//       }
//       newNotifications.push(notice);
//       yield put(successImportantNotifications(newNotifications));
//     } else {
//       TODO: エラーハンドリング実装
//     }
//   }
// }

export function* getCheckCreditAuthorization() {
  while (true) {
    const action = yield take(requestCheckCreditAuthorization);
    const {importantNotifications} = yield select(state => state.notification);
    const [data, error] = yield call(fetchPostJson, 'gmo/getSettlementInfo', {
      task_id: action.payload
    });
    if (data && !error) {
      if (data.completeSecureCreditLimit) {
        store.remove('isMovingSettlement');
      } else {
        // const newNotifications = clonedeep(importantNotifications);
        // const notice = {
        //   notice: '仮払いが最後まで完了していないタスクがあります。',
        //   onClick: () =>
        //     history.push(`/co/payment/cardlimit?taskId=${action.payload}`)
        // };
        // newNotifications.push(notice);
        // yield put(successImportantNotifications(newNotifications));
      }
    } else {
      // TODO: error process
    }
  }
}

export function* postPaidAuthorization() {
  while (true) {
    const action = yield take(requestPaidAuthori);
    yield put(setIsSubmitting(true));
    const [data, error] = yield call(
      fetchPostJson,
      'gmo/paid-authorization',
      action.payload
    );
    yield put(setIsSubmitting(false));

    if (data && !error) {
      yield put(
        setModalSetting({
          type: '',
          isVisible: true,
          params: {
            title: '仮払いが完了しました',
            message:
              '仮払いは正常に完了し、タスクのステータスが「作業中」に更新されました。\nタスク詳細画面の「作業メモ」を活用し、エンジニアに作業の指示をお願い致します。',
            buttonProps: {
              text: '閉じる',
              onClick: () => {
                reduxStore.dispatch(
                  setModalSetting({type: '', isVisible: false})
                );
                history.push(`/co`);
              }
            }
          }
        })
      );
    } else {
      yield put(
        setModalSetting({
          type: '',
          isVisible: true,
          params: {
            title: '仮払い処理に失敗しました。',
            message:
              '時間をおいて、再度お試し頂くか、Task運営局へお問い合わせ下さい。',
            buttonProps: {
              text: '閉じる',
              onClick: () => {
                reduxStore.dispatch(
                  setModalSetting({type: '', isVisible: false})
                );
                history.push(`/co`);
              }
            }
          }
        })
      );
    }
  }
}

export function* postPaidApplication() {
  while (true) {
    const action = yield take(requestPaidApplication);
    action.payload[
      'zip_code'
    ] = `${action.payload.zipCode1}-${action.payload.zipCode2}`;
    yield put(startSubmit('paidApplication'));
    const [data, error] = yield call(
      fetchPostJson,
      'gmo/initialize-paid',
      action.payload
    );
    if (data && !error) {
      yield put(requestPaymentState());
      yield put(setPaidModalFlag(true));
    } else {
      yield put(
        stopSubmit('paidApplication', {
          ...formatErrorResponse(error),
          _error: error.detail
        })
      );
    }
  }
}

export function* patchPaymentMethodToPaid() {
  while (true) {
    const action = yield take(requestPaymentUsingPaid);
    const payload = {
      payment_method: PAYMENT_METHODS.paid,
      task_id: action.payload
    };
    const [data, err] = yield call(
      fetchPostJson,
      `gmo/change_payment_method`,
      payload
    );
    if (data && !err) {
      yield put(
        setModalSetting({type: 'SUCCESS_PAYMENT_PAID', isVisible: true})
      );
    } else {
      yield put(
        setModalSetting({type: 'FAILED_PAYMENT_PAID', isVisible: true})
      );
    }
  }
}

export function* patchPaymentMethodToCredit() {
  while (true) {
    const action = yield take(requestPaymentUsingCredit);
    const payload = {
      payment_method: PAYMENT_METHODS.credit
    };
    const [data, err] = yield call(
      fetchPatch,
      `tasks/${action.payload}`,
      payload
    );
    if (data && !err) {
      yield put(
        setModalSetting({type: 'SUCCESS_PAYMENT_CREDIT', isVisible: true})
      );
    } else {
      yield put(
        setModalSetting({type: 'FAILED_PAYMENT_CREDIT', isVisible: true})
      );
    }
  }
}

const Payment = [
  fork(getPaymentList),
  fork(getCreditEditUrl),
  fork(getPaymentState),
  fork(postSecureCreditLimitUrl),
  fork(getCheckCreditAuthorization),
  fork(postPaidApplication),
  fork(patchPaymentMethodToPaid),
  fork(patchPaymentMethodToCredit),
  fork(postPaidAuthorization)
];

export default Payment;
