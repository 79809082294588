import React from 'react';
import {createMuiTheme} from '@material-ui/core';
import {MuiThemeProvider} from '@material-ui/core/styles';

const TaskListTheme = ({children, index, isListItem = true}) => {
  let colorCode;
  let borderTop;
  if (isListItem) {
    colorCode = index % 2 === 0 ? '#FFFFFF' : '#F7F9FC';
    borderTop = index == 0 ? '0.1rem solid #d7e2f4' : '';
  } else {
    colorCode = '#FFFFFF';
    borderTop = '0.1rem solid #d7e2f4';
  }

  const theme = createMuiTheme({
    overrides: {
      MuiListItem: {
        root: {
          paddingTop: 0,
          paddingBottom: 0,
          borderTop: borderTop,
          borderRight: '0.1rem solid #d7e2f4',
          borderBottom: '0.1rem solid #D7E2F4',
          display: ''
        },
        gutters: {
          paddingLeft: 0,
          paddingRight: 0
        },
        button: {
          backgroundColor: colorCode,
          display: '',

          '&:hover': {
            backgroundColor: '#FFFEEE'
          }
        }
      },
      MuiButtonBase: {
        root: {display: ''}
      }
    }
  });

  return (
    <MuiThemeProvider theme={theme} key={index}>
      {children}
    </MuiThemeProvider>
  );
};

export default TaskListTheme;
