import React from 'react';
import Button from './Button';
import {useDispatch, useSelector} from 'react-redux';
import {setReviewModalFlag} from '../../../actions/review';
import {push} from 'connected-react-router';

const SubmitButton = props => {
  const dispatch = useDispatch();
  const {id, disabled} = props;

  const CompleteTask = () => {
    dispatch(setReviewModalFlag(true));
  };

  const onClickHandler = {
    patch: CompleteTask
  };

  return (
    <Button
      kind={'pink'}
      disabled={disabled}
      onClickHandler={() => onClickHandler.patch()}
    />
  );
};

export const EvaluateTaskButton = props => {
  const {id, disabled} = props;
  return (
    <div>
      <SubmitButton id={id} disabled={disabled} />
    </div>
  );
};
