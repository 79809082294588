import React from 'react';
import {createMuiTheme, makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {MuiThemeProvider} from '@material-ui/core';
import classNames from 'classnames';
import style from './button.module.scss';
import PinWhite from '../../../../assets/icon_pin_white.svg';
import PinBlue from '../../../../assets/icon_pin_blue.svg';
import Clear from '../../../../assets/icon_clear.svg';

const Index = props => {
  const {kind, onClickHandler, disabled = false} = props;
  let applyBgColor = '';
  let applyHoverColor = '';
  let applyBorderColor = 'none';
  let valiant = 'contained';
  let applyColor = '#FFFFFF';
  let applyFontWeight = '';
  let text = '';
  let srcImg = null;
  let applyFontSize = '1.6rem';

  switch (kind) {
    case 'pink':
      applyBgColor = '#EA397D';
      applyHoverColor = '#b12a5e';
      applyFontWeight = 'bold';
      text = '評価する';
      break;
  }
  const useStyles = makeStyles(theme => ({
    button: {
      margin: theme.spacing(1),
      backgroundColor: applyBgColor,
      border: applyBorderColor,
      color: applyColor,
      fontWeight: applyFontWeight,
      width: '20rem',
      height: '5rem',
      fontSize: applyFontSize,
      '&:hover': {
        backgroundColor: applyHoverColor
      }
    }
  }));

  const classes = useStyles();
  return (
    <div className="stage">
      <Button
        variant={valiant}
        className={classes.button}
        disabled={disabled}
        onClick={() => onClickHandler()}
      >
        <div className={style.buttonContainer}>
          <img src={srcImg} />
          <div className={style.text}>{text}</div>
        </div>
      </Button>
    </div>
  );
};

export default Index;
