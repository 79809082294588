import React from 'react';
import Button from '../../EntryDetail/Button';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {
  requestDeleteBookmark,
  requestPostBookmark
} from '../../../actions/bookmark';
import {setIsSubmitting} from '../../../actions/common';
import {BASE_COLOR, SEVERITY} from './../../../common';

const ChooseButton = ({
  bookmarkFlag,
  onClickHandler,
  bookmarkList,
  bookmark_no,
  entry_no
}) => {
  if (entry_no) {
    //エントリーしているときは検討中ボタン押せないように。
    return (
      <Button
        kind={'nonActive'}
        onClickHandler={() => onClickHandler.delete(bookmark_no)}
        disabled={true}
      />
    );
  } else {
    if (bookmarkList == ['default']) {
      // ↑レスポンスが返ってきてるかの判別 返ってきてたらinitialstateが変更される
      return <Button kind={'loading'} />;
    }
    if (bookmarkFlag) {
      return (
        <Button
          kind={'blueOutlined'}
          onClickHandler={() => onClickHandler.delete(bookmark_no)}
          alertSeverity={SEVERITY.success}
        />
      );
    } else {
      return (
        <Button
          kind={'blue'}
          onClickHandler={() => onClickHandler.post()}
          alertSeverity={SEVERITY.success}
        />
      );
    }
  }
};

class Index extends React.Component {
  componentDidMount() {
    id = this.props.match.params.id;
  }
  componentDidUpdate(prevProps) {
    if (this.props.bookmark_no !== prevProps.bookmark_no) {
      this.props.setIsSubmitting();
    }
  }

  render() {
    const {
      bookmarkList,
      requestPostBookmark,
      requestDeleteBookmark,
      bookmark_no,
      entry_no
    } = this.props;

    const onClickHandler = {
      post: requestPostBookmark,
      delete: requestDeleteBookmark
    };

    return (
      <ChooseButton
        bookmarkFlag={Boolean(bookmark_no)}
        onClickHandler={onClickHandler}
        bookmarkList={bookmarkList}
        bookmark_no={bookmark_no}
        entry_no={entry_no}
      />
    );
  }
}

let id = '';

const mapDispatchToProps = dispatch => ({
  requestPostBookmark() {
    dispatch(requestPostBookmark({taskId: id}));
  },
  requestDeleteBookmark(bookmarkId) {
    dispatch(requestDeleteBookmark({bookmarkId: bookmarkId, taskId: id}));
  },
  setIsSubmitting() {
    dispatch(setIsSubmitting(false));
  }
});

const mapStateToProps = state => {
  return {
    bookmark_no: state.task.detail.bookmark_no,
    entry_no: state.task.detail.entry_no
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Index));
