import {SUCCESS_COMPANY} from '../actions/company';

const initialState = {
  company_name: '',
  company_name_kana: '',
  representative_name: '',
  representative_name_kana: '',
  zip_code: '',
  address: '',
  tel: '',
  homepage: '',
  name: '',
  representative_sei: '',
  representative_mei: '',
  representative_sei_kana: '',
  representative_mei_kana: '',
  prefecture: '',
  address1: '',
  address2: '',
  address3: '',
  url1: '',
  introduce: '',
  facebook: '',
  twitter: '',
  note: '',
  qiita: '',
  payment_method: null,
  is_save_member: null,
  summary_rating: null,
  img: null,
  coupons: null
};

function company(state = initialState, action) {
  switch (action.type) {
    case SUCCESS_COMPANY:
      return {
        ...state,
        company_name: action.payload.name,
        company_name_kana: action.payload.company_name_kana,
        representative_name: action.payload.representative_full_name_kanji,
        representative_name_kana: action.payload.representative_full_name_kana,
        zip_code: action.payload.zip_code,
        address:
          action.payload.address1 +
          action.payload.address2 +
          action.payload.address3,
        tel: action.payload.tel,
        homepage: action.payload.url1,
        name: action.payload.name,
        representative_sei: action.payload.representative_sei,
        representative_mei: action.payload.representative_mei,
        representative_sei_kana: action.payload.representative_sei_kana,
        representative_mei_kana: action.payload.representative_mei_kana,
        prefecture: action.payload.prefecture,
        address1: action.payload.address1,
        address2: action.payload.address2,
        address3: action.payload.address3,
        aux_name: action.payload.aux_name,
        email: action.payload.email,
        url1: action.payload.url1,
        introduce: action.payload.introduce,
        facebook: action.payload.facebook,
        twitter: action.payload.twitter,
        note: action.payload.note,
        qiita: action.payload.qiita,
        payment_method: action.payload.payment_method,
        is_save_member: action.payload.is_save_member,
        summary_rating: action.payload.summary_rating,
        img: action.payload.img,
        coupons: action.payload.coupons
      };
    default:
      return state;
  }
}

export default company;
