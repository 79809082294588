import {
  SUCCESS_SIGN_IN,
  SET_ACCESS_TOKEN,
  SET_OPEN_TWO_FACTOR_AUTH_INPUT,
  SUCCESS_TWO_FACTOR_AUTH_URI,
  FAILED_TWO_FACTOR_AUTH_URI
} from '../actions/sign';
import store from 'store';

const initialState = {
  isAuthenticated: false,
  accessToken: store.get('token'),
  isOpenTwoFactorAuthInput: false,
  twoFactorAuthUri: null,
  twoFactorAuthUriError: null
};

function sign(state = initialState, action) {
  switch (action.type) {
    case SUCCESS_SIGN_IN:
      return {
        ...state,
        isAuthenticated: true
      };

    case SET_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.payload
      };
    case SET_OPEN_TWO_FACTOR_AUTH_INPUT:
      return {
        ...state,
        isOpenTwoFactorAuthInput: action.payload
      };
    case SUCCESS_TWO_FACTOR_AUTH_URI:
      return {
        ...state,
        twoFactorAuthUri: action.payload
      };
    case FAILED_TWO_FACTOR_AUTH_URI:
      return {
        ...state,
        twoFactorAuthUriError: action.payload
      };
    default:
      return state;
  }
}

export default sign;
