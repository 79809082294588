import firebase from 'firebase';
import {requestHandlePersonalOnSnapshot} from '../actions/personalNews';
import {store} from '../store';
import {FIRESTORE_BATCH_LIMIT_NUMBER, MAX_GET_DOCS_COUNT} from '../common';
import {enqueueSnackbar, setLatestNewsId} from '../actions/snackbar';
import {getLastSnapshot, formatQuerySnapshot} from './common';

export const ListenPersonalNotification = async username => {
  try {
    const firebaseDb = firebase.firestore();
    const query = firebaseDb
      .collection('PersonalNotification')
      .doc(username)
      .collection('notifications')
      .orderBy('created_at', 'desc')
      .limit(MAX_GET_DOCS_COUNT);

    const [_, lastSnapshot] = await getLastSnapshot(query);

    const detachFunction = query.onSnapshot(
      querySnapshot => {
        store.dispatch(requestHandlePersonalOnSnapshot(querySnapshot));
      },
      err => {
        console.error(`Encountered error: ${err}`);
      }
    );
    return [detachFunction, lastSnapshot, null];
  } catch (err) {
    return [null, null, err];
  }
};

export const fetchPersonalNotification = async (prevLastSnapshot, username) => {
  try {
    const firebaseDb = firebase.firestore();
    const query = firebaseDb
      .collection('PersonalNotification')
      .doc(username)
      .collection('notifications')
      .orderBy('created_at', 'desc')
      .startAfter(prevLastSnapshot)
      .limit(MAX_GET_DOCS_COUNT);

    const [querySnapshot, lastSnapshot] = await getLastSnapshot(query);

    return [formatQuerySnapshot(querySnapshot), lastSnapshot, null];
  } catch (err) {
    return [null, null, err];
  }
};

export const readPersonalNotification = async username => {
  try {
    const firebaseDb = firebase.firestore();
    let batch = firebaseDb.batch();

    const querySnapshot = await firebaseDb
      .collection('PersonalNotification')
      .doc(username)
      .collection('notifications')
      .where('already_read', '==', false)
      .get();

    querySnapshot.docs.forEach((doc, index) => {
      if ((index + 1) % FIRESTORE_BATCH_LIMIT_NUMBER === 0) {
        batch.commit();
        batch = firebaseDb.batch();
      }
      batch.update(doc.ref, {already_read: true});
    });

    batch
      .commit()
      .then(() => {})
      .catch(err => console.error(`There was an error: ${err}`));
  } catch (err) {
    console.error(err);
    return [null, err];
  }
};

export const snackbarHandler = querySnapshot => {
  // 初回取得時は複数件の通知を受けるので条件をつけています。
  try {
    const newDoc = querySnapshot
      .docChanges()
      .find(item => item.type === 'added');
    const newId = newDoc.doc.id;
    const oldId = store.getState().snackbar.latestNewsId;
    const isNew = newId !== oldId;
    store.dispatch(setLatestNewsId(newId));
    if (oldId !== null && isNew) {
      store.dispatch(
        enqueueSnackbar({
          message: {
            text: '新着通知があります',
            subText: newDoc.doc.data().text
          },
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'info',
            action: key => {}
          }
        })
      );
    }
  } catch (e) {
    console.error('新着通知のお知らせに失敗しました。');
    console.error(e);
  }
};
