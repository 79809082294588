import React from 'react';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import classNames from 'classnames';

import styles from './submitButton.module.scss';

const useStyles = makeStyles({
  button: {
    backgroundColor: '#EA397D',
    fontSize: '1.8rem',
    fontWeight: 'bold'
  }
});

const SubmitButton = ({text, onClick, disabled = false}) => {
  const classes = useStyles();

  return (
    <Button
      className={classNames(styles.submitButton, classes.button)}
      onClick={onClick}
      type="submit"
      variant="contained"
      component="span"
      color="secondary"
      disabled={disabled}
    >
      {text}
    </Button>
  );
};

export default SubmitButton;
