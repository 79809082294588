import React, {FC, useEffect, useRef, Fragment} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router';
// sass
import styles from '../item.module.scss';
import commonStyles from '../../../../common/common.module.scss';
// component
import formatDate from '../../../FormatDate';
import CommonButton from '../../../CommonButton';
import Blank from '../../../Blank';
import {RootState} from '../../../../models/RootModels';
import {PersonalDocumentState} from '../../../../models/PersonalNewsModels';
import {requestPersonalNewsList} from '../../../../actions/personalNews';
import MoveToMessageButton from '../../../../components/MoveToMessageButton';
// material-ui
import {Divider} from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
// func
import {getPathFromUrl} from '../../../../common/funcs';

const PersonalNotificationContent: FC = () => {
  const dispatch = useDispatch();
  const {username} = useSelector((state: RootState) => state.setting.me);
  const {
    headPersonalDocument,
    personalDocument,
    hasMorePersonalDocument
  } = useSelector((state: RootState) => state.personalNews);

  const handleMoreFetch = () => {
    dispatch(requestPersonalNewsList(username));
  };

  return (
    <>
      <div className={styles.background}>
        {headPersonalDocument.length > 0 ? (
          headPersonalDocument.map((item, index) => {
            return <RowContent item={item} key={index.toString()} />;
          })
        ) : (
          <div className={styles.nothing}>通知はありません。</div>
        )}
        {personalDocument.length > 0 &&
          personalDocument.map((item, index) => {
            return <RowContent item={item} key={index.toString()} />;
          })}
      </div>
      <Blank height={'2.4rem'} width={null} />
      <div className={commonStyles.buttonContainer}>
        {/*@ts-ignore*/}
        <CommonButton
          text={hasMorePersonalDocument ? 'もっと見る' : 'これ以上ありません'}
          size={'medium'}
          color={'blue'}
          variant={'contained'}
          shape={'normal'}
          onClick={handleMoreFetch}
          disabled={!hasMorePersonalDocument}
        />
      </div>
    </>
  );
};

const RowContent: FC<NewsItemProps> = ({item, ...rest}) => {
  return (
    <Fragment>
      <ListItemLink item={item} {...rest}>
        <div className={styles.container}>
          <div className={styles.title}>{item.title}</div>
          <div className={styles.text}>{item.text}</div>
          {item.created_at && (
            <div className={styles.date}>
              {formatDate(item.created_at.seconds * 1000, 'minute')}
            </div>
          )}
        </div>
      </ListItemLink>
      <Divider variant={'middle'} />
    </Fragment>
  );
};

type NewsItemProps = {
  item: PersonalDocumentState;
};

const ListItemLink: FC<NewsItemProps> = ({item, ...rest}) => {
  const history = useHistory();
  if (item.type === 'message' && item.params && item.params.from_user_id) {
    return (
      <MoveToMessageButton
        userId={item.params.from_user_id}
        ChildComponent={(childProps: {disabled: boolean}) => {
          return <ListItem button {...rest} {...childProps} />;
        }}
      />
    );
  } else if (item.url) {
    const path = getPathFromUrl(item.url);
    return <ListItem onClick={() => history.push(path)} button {...rest} />;
  }
  return <ListItem {...rest} />;
};

export default PersonalNotificationContent;
