import {
  all,
  call,
  put,
  takeEvery,
  take,
  fork,
  select
} from 'redux-saga/effects';

import {fetchPatch, fetchGet} from './utills/fetch';
import {selectSetting, selectMessage} from '../sagas/selectors';
import {
  requestReceivedRewardAdmin,
  requestScheduledRewardAdmin,
  successReceivedRewardAdmin,
  successScheduledRewardAdmin,
  requestPaidRewardTasks,
  successPaidRewardTasks
} from '../actions/reward';

import {history} from '../store';
import store from 'store';
import {userType} from '../common';

//isEngineerがEnの時受け取り済みのpaidReward情報をget / isEngineerがCoの時受け取り済みのpaidReward情報をget
export function* getReceivedReward() {
  while (true) {
    const action = yield take(requestReceivedRewardAdmin);
    const setting = yield select(selectSetting);
    const isEngineer = setting.isEngineer;
    const url =
      `gmo/${userType(isEngineer)}/reward_admin/paid?page=` + action.payload;
    const [data, error] = yield call(fetchGet, url);
    if (data && !error) {
      yield put(successReceivedRewardAdmin({...data, offset: (action.payload - 1) * 10}));
    } else {
      // TODO: エラーハンドリング
    }
  }
}

//受け取り予定のTask情報をget(エンジニア側のみ)
export function* getScheduledReward() {
  while (true) {
    const action = yield take(requestScheduledRewardAdmin);
    const setting = yield select(selectSetting);
    const isEngineer = setting.isEngineer;
    const url = `gmo/en/reward_admin/scheduled?page=` + action.payload
    const [data, error] = yield call(fetchGet, url);
    if (data && !error) {
      yield put(successScheduledRewardAdmin({...data, offset: (action.payload - 1 ) * 10 }));
    } else {
      // TODO: エラーハンドリング
    }
  }
}

// 支払い済みタスク一覧
export function* getPaidReward() {
  while (true) {
    const action = yield take(requestPaidRewardTasks);
    const url = `gmo/co/reward_admin/paid?page=${action.payload}`;
    const [data, error] = yield call(fetchGet, url);
    if (data && !error) {
      yield put(successPaidRewardTasks(data));
    } else {
      // TODO: エラーハンドリング
    }
  }
}

const Reward = [
  fork(getReceivedReward),
  fork(getScheduledReward),
  fork(getPaidReward)
];

export default Reward;
