import { createAction } from 'redux-actions';

// 一覧取得（全社）
export const REQUEST_PROJECT_LIST = 'REQUEST_PROJECT_LIST';
export const SUCCESS_PROJECT_LIST = 'SUCCESS_PROJECT_LIST';
export const requestProjectList = createAction(REQUEST_PROJECT_LIST);
export const successProjectList = createAction(SUCCESS_PROJECT_LIST);

// 一覧取得（自社）
export const REQUEST_OWN_PROJECT_LIST = 'REQUEST_OWN_PROJECT_LIST';
export const SUCCESS_OWN_PROJECT_LIST = 'SUCCESS_OWN_PROJECT_LIST';
export const requestOwnProjectList = createAction(REQUEST_OWN_PROJECT_LIST);
export const successOwnProjectList = createAction(SUCCESS_OWN_PROJECT_LIST);

// 詳細取得
export const REQUEST_PROJECT_DETAIL = 'REQUEST_PROJECT_DETAIL';
export const SUCCESS_PROJECT_DETAIL = 'SUCCESS_PROJECT_DETAIL';
export const requestProjectDetail = createAction(REQUEST_PROJECT_DETAIL);
export const successProjectDetail = createAction(SUCCESS_PROJECT_DETAIL);

// 新規作成
export const REQUEST_PROJECT_CREATE = 'REQUEST_PROJECT_CREATE';
export const SUCCESS_PROJECT_CREATE = 'SUCCESS_PROJECT_CREATE';
export const requestProjectCreate = createAction(REQUEST_PROJECT_CREATE);
export const successProjectCreate = createAction(SUCCESS_PROJECT_CREATE);

// 編集
export const REQUEST_PROJECT_EDIT = 'REQUEST_PROJECT_EDIT';
export const SUCCESS_PROJECT_EDIT= 'SUCCESS_PROJECT_EDIT';
export const requestProjectEdit = createAction(REQUEST_PROJECT_EDIT);
export const successProjectEdit = createAction(SUCCESS_PROJECT_EDIT);

// 削除
export const REQUEST_PROJECT_DELETE = 'REQUEST_PROJECT_DELETE';
export const SUCCESS_PROJECT_DELETE = 'SUCCESS_PROJECT_DELETE';
export const requestProjectDelete = createAction(REQUEST_PROJECT_DELETE);
export const successProjectDelete = createAction(SUCCESS_PROJECT_DELETE);

// 現在のプロジェクトのIDをタスク新規作成画面に渡すために使用
export const SET_PROJECT_ID = 'SET_PROJECT_ID'
export const setProjectDetail = createAction(SET_PROJECT_ID)
