import { take, call, put, fork } from 'redux-saga/effects'

import { fetchGet } from './utills/fetch'
import { requestWantedTaskList, successWantedTaskList } from '../actions/coTaskList'

export function* getWantedTaskList() {
  while (true) {
    yield take(requestWantedTaskList);
   const [data, error] = yield call(fetchGet, 'tasks/?reception=3');
   yield put(successWantedTaskList(data))
  }
}

const CoTaskList = [
  fork(getWantedTaskList)
];



export default CoTaskList