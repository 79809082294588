import React from 'react';
import style from './infomation.module.scss';
import infoIcon from '../../assets/icon_infomation.svg';

const Infomation = ({contents}) => {
  return (
    <div className={style.container}>
      <img src={infoIcon} className={style.imgPosition} />
      <div className={style.contents}>
        <p className={style.title}>{contents.title}</p>
        <div className={style.divider} />
        <p className={style.text}>{contents.text}</p>
      </div>
    </div>
  );
};

export default Infomation;
