import React, {FC, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import ExtendClosingDateForm from '../../../components/Form/ExtendClosingDateForm';
// models
import {TaskItemState} from '../../../models/TaskModels';
import clsx from 'clsx';
import rootStyles from '../modals.module.scss';
import styles from '../OfferModal/offer.module.scss';
import {makeDefaultClosingDate} from '../../../common';
import {requestExtendClosingDate} from '../../../actions/task';

type Props = {
  taskDetail: TaskItemState;
  isOpen: boolean;
  setIsOpen: (value: boolean) => boolean;
};

const ExtendClosingDateModal: FC<Props> = ({taskDetail, isOpen, setIsOpen}) => {
  const dispatch = useDispatch();

  const initialValues = {
    closing_date: makeDefaultClosingDate()
  };

  return (
    <Dialog
      onBackdropClick={() => setIsOpen(false)}
      onExited={() => setIsOpen(false)}
      open={isOpen}
      maxWidth={false}
    >
      <div className={clsx(rootStyles.container, styles.countainer)}>
        <p className={styles.title}>延長期間を選択して下さい</p>
        <ExtendClosingDateForm
          initialValues={initialValues}
          onSubmit={value => {
            dispatch(
              requestExtendClosingDate({values: value, taskId: taskDetail.id})
            );
          }}
        />
      </div>
    </Dialog>
  );
};

export default ExtendClosingDateModal;
