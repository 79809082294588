import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
// component
import TaskEditForm from '../../../../components/Form/co/taskForms/TaskEditForm';
import {
  requestTaskEdit,
  requestTaskDetail,
  requestResetTask,
  requestWantedTaskEdit
} from '../../../../actions/task';
import PageTitle from '../../../../components/PageTitle';
import styles from '../task.module.scss';
import useScrollToTop from '../../../../hooks/useScrollToTop';
import {requestFormatList} from '../../../../actions/format';
import useRequestCompanyInfo from '../../../../hooks/useRequestCompanyInfo';
import {selectCheapCouponId} from '../../../../common';

const Edit = () => {
  useScrollToTop();
  const dispatch = useDispatch();

  const {id} = useParams();

  useEffect(() => {
    dispatch(requestTaskDetail({id: id}));
    dispatch(requestFormatList());
    return () => dispatch(requestResetTask());
  }, []);

  useRequestCompanyInfo();
  const {coupons} = useSelector(state => state.myCompany);

  const paymentState = useSelector(state => state.payment.paymentState);
  const detail = useSelector(state => state.task.detail);

  // クーポン情報の読み込み待ち
  if (coupons == null) return <></>;

  let initialValues = {
    ...detail,
    format_choose: detail.format_info && detail.format_info.id,
    tags: detail.tags_info.map(item => {
      return {
        name: item.name
      };
    })
  };

  if (selectCheapCouponId(coupons)) {
    initialValues['coupon'] = selectCheapCouponId(coupons);
  }

  return (
    <>
      <div className={styles.editTitle}>
        <PageTitle title="タスクの編集" />
      </div>
      {detail.id ? (
        <TaskEditForm
          paymentState={paymentState}
          initialValues={initialValues}
          onSubmit={value => {
            const params = {
              description: value.description,
              format_choose: value.format_choose,
              id: value.id,
              reward: value.reward,
              tags: value.tags,
              title: value.title,
              file: value.file
            };
            dispatch(requestWantedTaskEdit(params));
          }}
          preSaveBehavior={'PATCH'}
        />
      ) : null}
    </>
  );
};

export default Edit;
