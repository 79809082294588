import React, {useEffect} from 'react';
import {Switch, Route} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import CommonLayout from '../../containers/layout/CommonLayout';
// co containers
import CoDashboard from './CoDashboard';
import Info from './Info';
import CompanyEdit from './Company';
import CompanyEditRouter from '../../containers/co/CompanyEditRouter';
import CoProject from './Project';
import CoProjectDetail from './Project/Detail';
import CoProjectCreate from './Project/Create';
import CoProjectEdit from './Project/Edit';
import TaskDetail from '../TaskDetail';
import CoTaskCreate from './Task/Create';
import DraftEdit from './Task/DraftEdit';
import TaskEdit from './Task/Edit';
import TaskAuthori from './TaskAuthori';
import CoTaskScore from './Task/Score';
import Entry from './Entry';
import PasswordReset from './Reset/PasswordReset';
import PasswordResetMail from './Reset/PasswordResetMail';
import Notification from './Notification';
import User from './User';
import AccountSetting from '../AccountSetting';
import AccountEmailPassword from './AccountEmailPassword';
import MemberList from './MemberList';
import MessageBox from '../../components/MessageBox';
import CoLogin from '../../components/CoLogin';
import MessageCreator from '../../components/MessageCreator';
import EngineerInfo from '../../components/EngineerInfo';
import CorporateInfo from '../../components/CorporateInfo';
import RewardAdminCo from '../../containers/RewardAdmins/RewardAdminCo';
import NotFound from '../NotFound';
import CompanyInfo from '../co/Info';
import PaymentSetting from '../co/PaymentSetting';
import SecureCreditCardLimit from '../co/SecureCreditCardLimit';
import Profile from '../co/Profile';
import PaidApplication from '../co/PaidApplication';
import EngineerSearch from '../co/EngineerSearch';
import OfferAdmin from '../co/OfferAdmin';
import TwoFactorAuth from '../co/TwoFactorAuth';
// actions
import {requestInitializeComapny} from '../../actions/company';
import News from '../News';
import {requestAdditionalClaims} from '../../actions/firebase';
import {requestCompany} from '../../actions/company';
import {requestMe} from '../../actions/setting';
import {requestRoomList} from '../../actions/message';

const CoTemplate = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(state => state.firebase.isLoggedIn);
  useEffect(() => {
    if (isLoggedIn) {
      dispatch(requestInitializeComapny());
    }
  }, [isLoggedIn]);

  return (
    <CommonLayout>
      <Switch>
        <Route exact path="/co/" component={CoDashboard} />
        <Route exact path="/co/engineers" component={EngineerSearch} />
        <Route path="/co/user/edit" component={Profile} />
        <Route path="/co/en-user/:id" component={EngineerInfo} />
        <Route exact path="/co/co-user/:id" component={CorporateInfo} />
        <Route exact path="/co/setting" component={AccountSetting} />
        <Route
          exact
          path={'/co/two-factor-auth-setting'}
          component={TwoFactorAuth}
        />
        <Route exact path={'/co/offers'} component={OfferAdmin} />
        {/*<Route exact path="/co/setting/password" component={CoUserPassword} />*/}
        <Route exact path="/co/setting/payment" component={PaymentSetting} />
        <Route exact path="/co/project" component={CoProject} />
        <Route exact path="/co/project/create" component={CoProjectCreate} />
        <Route exact path="/co/project/:id/edit" component={CoProjectEdit} />
        <Route exact path="/co/project/:id" component={CoProjectDetail} />
        <Route
          exact
          path="/co/project/:projectId/task/create"
          component={CoTaskCreate}
        />
        <Route
          exact
          path="/co/project/:projectId/task/:id/"
          component={TaskDetail}
        />
        {/*<Route exact path="/co/task/:id/edit" component={CoTaskEdit} />*/}
        <Route
          exact
          path="/co/project/:projectId/task/:id/draft-edit"
          component={DraftEdit}
        />
        <Route
          exact
          path="/co/project/:projectId/task/:id/edit"
          component={TaskEdit}
        />
        <Route
          exact
          path="/co/project/:projectId/task/:id/entry"
          component={Entry}
        />
        <Route
          exact
          path="/co/project/:projectId/task/:taskId/authorization"
          component={TaskAuthori}
        />
        {/*<Route exact path="/co/task/:id/score" component={CoTaskScore} />*/}
        <Route
          exact
          path="/co/project/:projectId/task/:id/score"
          component={CoTaskScore}
        />

        {/*<Route exact path="/co/signupResult" component={CoSignUpResult}/>*/}
        <Route exact path="/co/passwordReset/:hash" component={PasswordReset} />
        <Route
          exact
          path="/co/passwordResetMail"
          component={PasswordResetMail}
        />
        <Route exact path="/co/notification" component={Notification} />
        <Route exact path="/co/:id/user/:userId" component={User} />
        <Route
          exact
          path="/co/setting/emailpassword"
          component={AccountEmailPassword}
        />
        <Route exact path="/co/member" component={MemberList} />
        <Route exact path="/co/message" component={MessageBox} />
        <Route exact path="/co/message/create/:id" component={MessageCreator} />
        <Route exact={true} path="/co/info" component={CompanyInfo} />
        <Route exact={true} path="/co/rewardAdmin" component={RewardAdminCo} />
        <Route exact={true} path="/co/news" component={News} />
        <Route path="/co/info/edit" component={CompanyEditRouter} />
        <Route
          exact
          path="/co/payment/cardlimit"
          component={SecureCreditCardLimit}
        />
        <Route exact path="/co/init-paid" component={PaidApplication} />
        <Route exact path="/co/:id" component={Info} />
        <Route exact path="/co/co-info/:id" component={CompanyInfo} />
        <Route component={NotFound} />
      </Switch>
    </CommonLayout>
  );
};

export default CoTemplate;
