import React from 'react';
import classNames from 'classnames';
// components
import LinkItem from './LinkItem';
import styles from './footerPage.module.scss';
// material-ui
import Divider from '@material-ui/core/Divider';

const FooterPage = ({isEngineer = null, isNormal = true}) => {
  return (
    <footer>
      <div
        className={classNames(
          styles.inner,
          isNormal ? styles.normalWidth : styles.otherWidth
        )}
      >
        {isNormal && <Divider color={'#D7E2F4'} />}
        <section className={styles.links}>
          {isEngineer !== null && (
            <TermsHandler isEngineer={isEngineer} isNormal={isNormal} />
          )}
          <LinkItem
            path="/policy"
            text="プライバシーポリシー"
            isNormal={isNormal}
          />
          <LinkItem path="/faq" text="よくある質問" isNormal={isNormal} />
          <LinkItem
            path="/contact/main"
            text="お問い合わせ"
            isNormal={isNormal}
          />
          <LinkItem
            path="/sctl"
            text="特定取引法に基づく表示"
            isNormal={isNormal}
          />
          <LinkItem
            url="https://m-lab.inc/"
            text="運営会社"
            isNormal={isNormal}
          />
        </section>
        <p
          className={classNames(
            styles.text,
            isNormal ? styles.textNormalColor : styles.textWhiteColor
          )}
        >
          <small>© m-Lab inc. All Rights Reserved..</small>
        </p>
      </div>
    </footer>
  );
};

const TermsHandler = ({isEngineer, isNormal}) => {
  return isEngineer ? (
    <LinkItem path="/terms/en" text="利用規約" isNormal={isNormal} />
  ) : (
    <LinkItem path="/terms/co" text="利用規約" isNormal={isNormal} />
  );
};

export default FooterPage;
