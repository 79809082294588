import {createAction} from 'redux-actions';

export const SET_EFFECT = 'SET_EFFECT';
export const setEffect = createAction(SET_EFFECT);

export const DELETE_EFFECT = 'DELETE_EFFECT';
export const deleteEffect = createAction(DELETE_EFFECT);

export const IS_LOADING_FLAG = 'IS_LOADING_FLAG';
export const isLoadingFlag = createAction(IS_LOADING_FLAG);
