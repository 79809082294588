import React from 'react';
import messageReplyFormStyle from './messageReplyForm.module.scss';
import {Field, getFormSubmitErrors, reduxForm} from 'redux-form';
import {renderTextArea} from '../../Field';
import * as Validator from '../utils/validate';
import formStyle from '../form.module.scss';
import CommonButton from '../../CommonButton';

const MessageReplyForm = ({
  placeholder,
  handleSubmit,
  pristine,
  submitting,
  error
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <Field
        name="content"
        component={renderTextArea}
        fullWidth={true}
        placeholder={placeholder}
        validate={[Validator.RequiredItem]}
        rows={2}
        rowsMax={6}
      />
      <p className={formStyle.error}>{error}</p>
      <div className={messageReplyFormStyle.buttonContainer}>
        <CommonButton
          type={'submit'}
          text={'送信'}
          size={'medium'}
          color={'blue'}
          variant={'contained'}
          shape={'normal'}
          disabled={pristine || submitting}
        />
      </div>
    </form>
  );
};

export default reduxForm({
  form: 'messageReplyForm'
})(MessageReplyForm);
