import { createAction } from 'redux-actions';

// プロフィール取得
export const REQUEST_CO_ME = 'REQUEST_CO_ME';
export const SUCCESS_CO_ME = 'SUCCESS_CO_ME';
export const requestCoMe = createAction(REQUEST_CO_ME);
export const successCoMe = createAction(SUCCESS_CO_ME);

// プロフィール編集
export const REQUEST_CO_ME_EDIT = 'REQUEST_CO_ME_EDIT';
export const SUCCESS_CO_ME_EDIT = 'SUCCESS_CO_ME_EDIT';
export const requestCoMeEdit = createAction(REQUEST_CO_ME_EDIT);
export const successCoMeEdit = createAction(SUCCESS_CO_ME_EDIT);

// パスワード再設定(ログイン済み) :企業
export const REQUEST_PASSWORD = 'REQUEST_PASSWORD';
export const SUCCESS_PASSWORD = 'SUCCESS_PASSWORD';
export const requestPassword = createAction(REQUEST_PASSWORD);
export const successPassword = createAction(SUCCESS_PASSWORD);
