import React from 'react'
import {Switch, Route} from 'react-router-dom'
import style from './profile.module.scss'
import ProfileEditForm from "../../../components/Form/co/ProfileEditForm"
import ChooseEditTarget from '../../../components/ChooseEditTarget'

const Index = () => {
  return (
    <div className={style.container}>
      <Switch>
        <Route exact path="/co/user/edit/profile" component={ProfileEditForm}/>
        <Route exact path="/co/user/edit/select" component={ChooseEditTarget}/>
        <Route exact path="/co/user/edit/email" component={ChooseEditTarget}/>
        <Route exact path="/co/user/edit/pass" component={ChooseEditTarget}/>
      </Switch>
    </div>
  )
}

export default Index
