import React, {useState, useEffect} from 'react';

import styles from './news.module.scss';
import PageTitle from '../../components/PageTitle';
import {useDispatch, useSelector} from 'react-redux';
import {readPersonalNotification} from '../../firebase/personalNotification';
import NotificationTabs from '../../components/tabs/notification/NotificationTabs';

const News = () => {
  const setting = useSelector(state => state.setting);
  const username = setting.me.username;

  useEffect(() => {
    if (username) {
      readPersonalNotification(username);
      //↑ドキュメントたちを未読→既読にする関数
    }
  }, [setting]);

  return (
    <>
      <div className={styles.container}>
        <PageTitle title="お知らせ" />
        <main className={styles.content}>
          <div className={styles.newsList}>
            <NotificationTabs />
          </div>
        </main>
      </div>
    </>
  );
};

export default News;
