import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import style from '../helpButton.module.scss';
import AllowIndentionArea from '../../AllowIndentionArea';
import {useStyles} from '../styles';
import MediaQuery from 'react-responsive';
import {mediaQuery} from '../../../constants';

const BaseHelpField = ({
  text,
  placement,
  children,
  handleTooltipClose,
  open
}) => {
  const classes = useStyles();
  return (
    <>
      <MediaQuery query={mediaQuery.max}>
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <Tooltip
            disableFocusListener
            disableTouchListener
            title={
              <AllowIndentionArea>
                <p className={style.tooltipText}>{text}</p>
              </AllowIndentionArea>
            }
            classes={{tooltip: classes.tooltips}}
            placement={placement}
            open={open}
          >
            {children}
          </Tooltip>
        </ClickAwayListener>
      </MediaQuery>
      <MediaQuery query={mediaQuery.min}>
        <Tooltip
          disableFocusListener
          disableTouchListener
          title={
            <AllowIndentionArea>
              <p className={style.tooltipText}>{text}</p>
            </AllowIndentionArea>
          }
          classes={{tooltip: classes.tooltips}}
          placement={placement}
        >
          {children}
        </Tooltip>
      </MediaQuery>
    </>
  );
};

export default BaseHelpField;
