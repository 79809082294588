import React from 'react'
import {Field} from 'redux-form'
import {renderPassword, renderMultilineText} from "@/components/Field/index"
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import style from './co/form.module.scss'
import {history} from '@/store'

const ScoreForm = (props) => {

  const {error, handleSubmit, submitErrors, pristine, submitting} = props;
  
  return (
    <div>
      {error && <p className={style.error}>{error}</p>}
      <form onSubmit={handleSubmit}>
        <div className={style.items}>
          <dl>
            <dt>
              <Field name="commnent"
                     required={true}
                     component={renderMultilineText}/>
              {submitErrors && submitErrors.introduce && (
                <FormHelperText><span className={style.error}>{submitErrors.introduce}</span></FormHelperText>)}
            </dt>
            <dd>
              <div className={style.submit}>
                <input
                  id="submit"
                  type='submit'
                  value={''}
                />
                <label htmlFor="submit">
                  <Button
                    disabled={pristine || submitting}
                    variant="contained" component="span" color="secondary">
                    更新
                  </Button>
                </label>
              </div>
            </dd>
          </dl>
        </div>

      </form>
    </div>
  )
};

export default ScoreForm
