import React from 'react';
import style from '../helpButton.module.scss';
import HelpButton from '../HelpButton';

const HelpBtnNextToItem = ({children, text}) => {
  return (
    <div className={style.sideQ}>
      {children} <HelpButton text={text} placement={'right-end'} />
    </div>
  );
};

export default HelpBtnNextToItem;
