import React from 'react';
import PropTypes from 'prop-types';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import CreateNewFolder from '@material-ui/icons/CreateNewFolder';
import People from '@material-ui/icons/People';
import DashboardRounded from '@material-ui/icons/DashboardRounded';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import {history} from '@/store'
import style from './menu.module.scss'
import {withStyles} from "@material-ui/core/styles/index";
import {compose} from "recompose";
import {connect} from "react-redux";
import {NavLink} from 'react-router-dom'

const styles = theme => ({
  toolbar: theme.mixins.toolbar
});

class Menu extends React.Component {

  state = {
    isUser: true,
    isProject: true,
    isAccount: true,
  };

  HandleLogout = () => {
    window.location.href = '/signin'
  }

  handleUserToggle = () => {
    this.setState(state => ({isUser: !state.isUser}));
  };

  handleProjectToggle = () => {
    this.setState(state => ({isProject: !state.isProject}));
  };

  handleAccountToggle = () => {
    this.setState(state => ({isAccount: !state.isAccount}));
  };

  render() {
    const {classes, theme} = this.props;

    return (
      <div className={style.menu}>
        <ListItem button onClick={this.handleUserToggle} className={style.title}>
          <ListItemAvatar>
            <Avatar
              alt=""
              src=""
            />
          </ListItemAvatar>
          <ListItemText primary={`shinya murakami`}/>
          {this.state.isUser ? <ExpandMore/> : <ExpandLess/>}
        </ListItem>

        <Collapse in={this.state.isUser} timeout="auto" unmountOnExit>
          <NavLink exact to="/co/setting" activeClassName={style.active}>
            <ListItem>
              <ListItemText primary="プロフィール設定" className={style.subTitle}/>
            </ListItem>
          </NavLink>
          <NavLink exact to="/co/setting/password" activeClassName={style.active}>
            <ListItem>
              <ListItemText primary="パスワードリセット" className={style.subTitle}/>
            </ListItem>
          </NavLink>
          <ListItem button onClick={() => {this.HandleLogout()}}>
            <ListItemText primary="ログアウト" className={style.subTitle}/>
          </ListItem>
        </Collapse>

        <Divider/>
        <NavLink exact to="/co/" activeClassName={style.active}>
          <ListItem className={style.title}>
            <ListItemIcon><DashboardRounded/></ListItemIcon>
            <ListItemText primary="TOP"/>
          </ListItem>
        </NavLink>
        <Divider/>


        <ListItem button onClick={this.handleProjectToggle} className={style.title}>
          <ListItemIcon><CreateNewFolder/></ListItemIcon>
          <ListItemText primary="プロジェクト"/>
          {this.state.isProject ? <ExpandMore/> : <ExpandLess/>}
        </ListItem>


        <Collapse in={this.state.isProject} timeout="auto" unmountOnExit>
          <NavLink exact to="/co/project" activeClassName={style.active}>
            <ListItem>
              <ListItemText primary="TOP" className={style.subTitle}/>
            </ListItem>
          </NavLink>
          <NavLink exact to="/co/project/create" activeClassName={style.active}>
            <ListItem>
              <ListItemText primary="新規作成" className={style.subTitle}/>
            </ListItem>
          </NavLink>
        </Collapse>
        <Divider/>

        <ListItem button onClick={this.handleAccountToggle} className={style.title}>
          <ListItemIcon><People/></ListItemIcon>
          <ListItemText primary="会社情報管理" className={style.title}/>
          {this.state.isAccount ? <ExpandMore/> : <ExpandLess/>}
        </ListItem>

        <Collapse in={this.state.isAccount} timeout="auto" unmountOnExit>
          <NavLink exact to="/co/company" activeClassName={style.active}>
            <ListItem>
              <ListItemText primary="会社情報" className={style.subTitle}/>
            </ListItem>
          </NavLink>
          <NavLink exact to="/co/account" activeClassName={style.active}>
            <ListItem>
              <ListItemText primary="担当者一覧" className={style.subTitle}/>
            </ListItem>
          </NavLink>
          <NavLink exact to="/co/account/create" activeClassName={style.active}>
            <ListItem>
              <ListItemText primary="新規作成" className={style.subTitle}/>
            </ListItem>
          </NavLink>
        </Collapse>
        <Divider/>
        <NavLink exact to="/co/notification" activeClassName={style.active}>
          <ListItem className={style.title}>
            <ListItemIcon><DashboardRounded/></ListItemIcon>
            <ListItemText primary="お知らせ"/>
          </ListItem>
        </NavLink>
        <Divider/>
        <NavLink exact to="/co/" activeClassName={style.active}>
          <ListItem className={style.title}>
            <ListItemIcon><DashboardRounded/></ListItemIcon>
            <ListItemText primary="チャット"/>
          </ListItem>
        </NavLink>
        <Divider/>
      </div>
    );
  }
}

Menu.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch => ({});

const mapStateToProps = (state) => {
};

export default compose(
  withStyles(styles, {withTheme: true}),
  connect(null, mapDispatchToProps)
)(Menu);

