import { createAction } from 'redux-actions';

// パスワードメール送信 :企業
export const REQUEST_PASSWORD_RESET_MAIL = 'REQUEST_PASSWORD_RESET_MAIL';
export const SUCCESS_PASSWORD_RESET_MAIL = 'SUCCESS_PASSWORD_RESET_MAIL';
export const requestPasswordResetMail = createAction(REQUEST_PASSWORD_RESET_MAIL);
export const successPasswordResetMail = createAction(SUCCESS_PASSWORD_RESET_MAIL);

// パスワードリセット :企業
export const REQUEST_PASSWORD_RESET = 'REQUEST_PASSWORD_REEST';
export const SUCCESS_PASSWORD_RESET = 'SUCCESS_PASSWORD_RESET_MAIL';
export const requestPasswordReset = createAction(REQUEST_PASSWORD_RESET);
export const successPasswordReset = createAction(SUCCESS_PASSWORD_RESET);

// パスワードリセット 新パスワードの入力
export const REQUEST_PASSWORD_RESET_CONFIRM = 'REQUEST_PASSWORD_RESET_CONFIRM';
export const SUCCESS_PASSWORD_RESET_CONFIRM = 'SUCCESS_PASSWORD_RESET_CONFIRM';
export const requestPasswordResetConfirm = createAction(REQUEST_PASSWORD_RESET_CONFIRM);
export const successPasswordResetConfirm = createAction(SUCCESS_PASSWORD_RESET_CONFIRM);

// パスワードリセットチェックキー :企業
export const REQUEST_PASSWORD_RESET_CHECK_KEY = 'REQUEST_PASSWORD_RESET_CHECK_KEY';
export const requestPasswordResetCheckKey = createAction(REQUEST_PASSWORD_RESET_CHECK_KEY);
export const SUCCESS_PASSWORD_RESET_CHECK_KEY = 'SUCCESS_PASSWORD_RESET_CHECK_KEY';
export const successPasswordResetCheckKey = createAction(SUCCESS_PASSWORD_RESET_CHECK_KEY);
