import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import {makeStyles} from '@material-ui/core/styles';
import Blank from '../../Blank';
import CommonButton from '../../CommonButton';
import {useSelector} from 'react-redux';
import tipStyles from './tipsSnackbar.module.scss';
import {useHistory} from 'react-router';
import store from 'store';

const useStyles = makeStyles({
  snackbarRoot: {
    maxWidth: '50rem'
  },
  root: {
    backgroundColor: '#FFF2F7',
    border: '0.1rem solid #FFF2F7',
    '&:hover': {
      backgroundColor: '#FFF2F7',
      border: '0.1rem solid #FFF2F7'
    }
  }
});

export default function PaidAlertSnackbar() {
  const [open, setOpen] = React.useState(true);
  const history = useHistory();
  const {isRequiredPaymentSetting} = useSelector(state => state.payment);
  const classes = useStyles();

  const handleClick = () => {
    setOpen(false);
    store.set('didReadPaymentCaution', true);
    history.push('/co/setting/payment');
  };

  const handleClose = (event, reason) => {
    setOpen(false);
    store.set('didReadPaymentCaution', true);
  };

  return (
    <div>
      <Snackbar
        classes={{root: classes.snackbarRoot}}
        ContentProps={{
          classes: {
            root: classes.root
          }
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        open={
          isRequiredPaymentSetting &&
          open &&
          !Boolean(store.get('didReadPaymentCaution'))
        }
        message={
          <div>
            <p className={tipStyles.title}>注意</p>
            <Blank height={'0.8rem'} />
            <p className={tipStyles.text}>
              お支払い方法を <strong>請求書払い（Paid）</strong> にする場合は
            </p>
            <p className={tipStyles.text}>
              利用申請から利用開始までに１〜３営業日程頂いております
            </p>
            <p className={tipStyles.text}>
              ご利用の際はお早めに設定をお願い致します。
            </p>
            <Blank height={'0.4rem'} />
          </div>
        }
        action={
          <React.Fragment>
            <CommonButton
              text={'閉じる'}
              size={'minimum'}
              color={'pink'}
              variant={'outlined'}
              shape={'normal'}
              onClick={() => handleClose()}
              disabled={false}
            />
            <Blank width={'0.8rem'} />
            <CommonButton
              text={'設定を開く'}
              size={'minimum'}
              color={'pink'}
              variant={'outlined'}
              shape={'normal'}
              onClick={() => handleClick()}
              disabled={false}
            />
          </React.Fragment>
        }
      />
    </div>
  );
}
