import { SUCCESS_WANTED_TASK_LIST } from '../actions/coTaskList'

const initialState = {
  wantedTaskList: []
};

function coTaskList(state = initialState, action) {
  switch (action.type) {
    case SUCCESS_WANTED_TASK_LIST:
      return {
        ...state,
        wantedTaskList: action.payload.results
      };
    default: {
      return state
    }
  }
}

export default coTaskList
