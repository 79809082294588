import React from 'react'
import Divider from "@material-ui/core/Divider";
import {withStyles} from '@material-ui/core/styles/index';
import style from "../../UserInformation/Header/header.module.scss";


const styles = {
  divider: {
    height: "0.2rem",
    background: "#093791",
    marginTop: "1.5rem"
  },
  button: {
    border: "0.1rem solid #093791",
    color: "#093791",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "1.5rem",
    lineHeight: "2rem",
    textAlign: "center",
    marginLeft: "auto",
  }
};

const Index = (props) => {
  const {classes, headerText} = props;
  return (
    <div>
      <p className={style.header}>{headerText}</p>
      <Divider
        classes={{
          root: classes.divider
        }}
      />
    </div>
  )
}

export default withStyles(styles)(Index)
