import React from 'react';
import {useSelector} from 'react-redux';

import styles from './accountSetting.module.scss';
import IconMailPassword from '../../assets/icon_mail_password.svg';
import IconBank from '../../assets/icon_bank.svg';
import IconID from '../../assets/icon_id.svg';
import IconCellphone from '../../assets/icon_cellphone.svg';
import IconConfidential from '../../assets/icon_confidential.svg';

import AppBar from '../../components/MenuAppBar';
import EnMenu from '../layout/EnMenu';
import Breadcrumbs from '../../components/Breadcrumbs';
import FooterPage from '../../components/Footer/FooterPage';
import PageTitle from '../../components/PageTitle';
import CardButton from '../../components/Button/CardButton';

const AccountSetting = ({match}) => {
  const {isEngineer, me} = useSelector(state => state.setting);
  const id = me.id;
  // TODO: link入れる
  const listForEngineer = [
    {
      text: '本人確認・入金先情報',
      link: '/en/user/edit/id-info',
      image: IconID
    },
    {
      text: 'アカウント情報',
      link: `/en/en-user/${id}`,
      image: IconCellphone
    }
  ];

  const listForCompany = [
    {
      text: 'ログイン情報',
      link: '/co/user/edit/select',
      image: IconMailPassword
    },
    {
      text: 'アカウント情報',
      link: `/co/co-user/${id}`,
      image: IconCellphone
    },
    {text: 'お支払方法の設定', link: '/co/setting/payment', image: IconID}
  ];

  const list = isEngineer ? listForEngineer : listForCompany;

  return (
    <>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.main}>
            <PageTitle title="アカウント設定" />
            <div className={styles.buttons}>
              {list.map((item, index) => {
                const {text, link, image} = item;
                return (
                  <div className={styles.button} key={index.toString()}>
                    <CardButton text={text} link={link} image={image} />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountSetting;
