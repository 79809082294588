import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import {makeStyles} from '@material-ui/core/styles';
import {COLOR_PALLET} from '../../../../constants';

const useStyles = makeStyles(theme => ({
  gutters: {
    padding: '3rem',
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '0.1rem solid #d7e2f4',
    backgroundColor: COLOR_PALLET.white,
    '&:hover': {
      backgroundColor: '#FFFEEE'
    }
  }
}));

const DefaultListItem = props => {
  const classes = useStyles();
  return (
    <ListItem
      {...props}
      classes={{
        gutters: classes.gutters
      }}
    />
  );
};

export default DefaultListItem;
