import {
  SET_USER_FILES,
  SET_USER_TAGS,
  DELETE_USER_FILES,
  DELETE_USER_TAGS
} from '../actions/profile';
import store from 'store';

const initialState = {
  tags: [],
  //↓localstorageに保存しておいてるものを利用
  attachFile:store.get('userImg')
};

function profile(state = initialState, action) {
  switch (action.type) {
    case SET_USER_TAGS:
      return {
        ...state,
        tags: state.tags.concat(action.payload)
      };
    case DELETE_USER_TAGS:
      return {
        ...state,
        tags: action.payload
      };
    case SET_USER_FILES:
      return {
        ...state,
        attachFile: action.payload
      };
    case DELETE_USER_FILES:
      // reducer の再描画のフックの為には filter で配列を作り直す必要がある
      return {
        ...state,
        attachFile: state.attachFile.filter(
          (item, index, array) => index !== action.payload
        )
      };
    default:
      return state;
  }
}

export default profile;
