import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import PaidApplicationForm from '../../../components/Form/co/Paid/PaidApplicationForm';
import PageTitle from '../../../components/PageTitle';
import {
  requestPaidApplication,
  setPaidModalFlag
} from '../../../actions/payment';
import {requestMember} from '../../../actions/member';
import style from './paidApplication.module.scss';
import Dialog from '@material-ui/core/Dialog';
import ApplicationPaidResult from '../../../components/Modal/ApplicationPaidResult';
import {makeStyles} from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import commonStyles from '../../../common/common.module.scss';
import WithOpenInNewWindow from '../../../components/WithOpenInNewWindow';

const useStyles = makeStyles({
  background: {
    backgroundColor: 'rgba(0,33,99,0.91)'
  }
});

const PaidApplication = () => {
  const dispatch = useDispatch();
  const {isOpenModal} = useSelector(state => state.payment);
  const {companyId} = useSelector(state => state.setting);
  const {members} = useSelector(state => state.member);
  const classes = useStyles();

  useEffect(() => {
    if (companyId) {
      dispatch(requestMember(companyId));
    }
  }, [companyId]);

  return (
    <div>
      <PageTitle title="Paid（請求書払い） 利用申請" />
      <div className={style.detail}>
        <p>
          タスク新規作成画面で、Paid（請求書払い）を指定するには、こちらの申請が必要です。
          <br />
          申請後「Paid（請求書払い）」を提供している株式会社ラクーンフィナンシャルより、
          <br />
          入力いただいたお電話番号またはメールアドレスにご連絡がございます。
          <br />
          審査は当日〜3営業日以内に完了し、結果がメールにて通知されますのでご確認ください。
          <br />
          <br />
          <Link
            href={'https://paid.jp/'}
            target={'_blank'}
            rel={'noopener noreferrer'}
          >
            <WithOpenInNewWindow>Paid について</WithOpenInNewWindow>
          </Link>
        </p>
      </div>
      <PaidApplicationForm
        members={members}
        onSubmit={values => {
          dispatch(requestPaidApplication(values));
        }}
      />
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        className={classes.background}
        onClose={() => dispatch(setPaidModalFlag(false))}
        open={isOpenModal}
        maxWidth={false}
      >
        <ApplicationPaidResult />
      </Dialog>
    </div>
  );
};

export default PaidApplication;
