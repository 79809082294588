import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
// components
import PageTitle from '../../../../components/PageTitle';
import {splitHyphen} from '../../../../common';
import {requestMember} from '../../../../actions/member';
import PaidEditForm from '../../../../components/Form/co/Paid/PaidEditForm';
import {requestEditCompanyInfo} from '../../../../actions/company';
import {PAYMENT_STATUS_PAID} from '../../../../constants';
import Caption from '../../../../components/Caption';
import Blank from '../../../../components/Blank';

const BaseInfoEditForm = () => {
  const dispatch = useDispatch();
  const {companyId} = useSelector(state => state.setting);
  const {members} = useSelector(state => state.member);
  const company = useSelector(state => state.myCompany);
  const {paymentState} = useSelector(state => state.payment);

  const isOpenPaidFields =
    paymentState.paid.status_code !== PAYMENT_STATUS_PAID.notMember;

  const initialValues = makeInitialValues(isOpenPaidFields, company);

  useEffect(() => {
    if (companyId) {
      dispatch(requestMember(companyId));
    }
  }, [companyId]);

  if (!company.company_name) {
    return <></>;
  }

  return (
    <>
      <PageTitle title="企業情報の編集" />
      {isOpenPaidFields && (
        <>
          <Blank height={'2rem'} />
          <Caption>
            このページを修正すると、請求書払い(Paid)の再審査が行われます。
          </Caption>
          <Caption>
            また、クレジットカードの再設定が必要になりますのでご注意ください。
          </Caption>
        </>
      )}
      <PaidEditForm
        members={members}
        onSubmit={values => {
          dispatch(requestEditCompanyInfo(values));
        }}
        initialValues={initialValues}
        isOpenPaidFields={isOpenPaidFields}
      />
    </>
  );
};

const makeInitialValues = (isOpenPaidFields, company) => {
  const zipCodeParts = splitHyphen(company.zip_code);
  const telParts = splitHyphen(company.tel);

  const baseInitialValues = {
    name: company.company_name,
    company_name_kana: company.company_name_kana,
    tel1: telParts[0],
    tel2: telParts[1],
    tel3: telParts[2]
  };

  if (isOpenPaidFields) {
    return {
      ...baseInitialValues,
      zipCode1: zipCodeParts[0],
      zipCode2: zipCodeParts[1],
      representative_sei: company.representative_sei,
      representative_mei: company.representative_mei,
      representative_sei_kana: company.representative_sei_kana,
      representative_mei_kana: company.representative_mei_kana,
      prefecture: company.prefecture,
      address1: company.address1,
      address2: company.address2,
      address3: company.address3,
      aux_name: company.aux_name,
      email: company.email
    };
  } else {
    return baseInitialValues;
  }
};

export default BaseInfoEditForm;
