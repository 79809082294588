import React from 'react';
import {useDispatch} from 'react-redux';
import SearchTaskIcon from '../../../assets/icon_task_search.svg';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import styles from './roundedButton.module.scss';
import {useHistory, useParams} from 'react-router-dom';
import {setModalFlag} from '../../../actions/member';
import CommonButton from '../../CommonButton';
import Blank from '../../Blank';

const useStyles = makeStyles({
  button: {
    fontSize: '1.5rem',
    lineHeight: '2rem',
    textAlign: 'center',
    borderRadius: '3rem',
    width: '30rem',
    height: '5rem',
    marginTop: '4rem'
  },
  editButton: {
    fontSize: '1.5rem',
    lineHeight: '2rem',
    textAlign: 'center',
    borderRadius: '3rem',
    width: '20rem',
    height: '5rem'
  }
});

// TODO: edit-pass と edit-mail は遷移先が異なるので、あとで適切に変更する

const DisplayContent = ({kind, classes, history, projectId}) => {
  const dispatch = useDispatch();
  switch (kind) {
    case 'task-search':
      return (
        <>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => history.push('/en/task')}
          >
            <img src={SearchTaskIcon} className={styles.icon} />
            <span className={styles.text}>仕事を探す</span>
          </Button>
        </>
      );
    case 'task-create':
      return (
        <>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => history.push(`/co/project/${projectId}/task/create`)}
          >
            <img src={SearchTaskIcon} className={styles.icon} />
            <span className={styles.text}>タスクを作成する</span>
          </Button>
        </>
      );
    case 'project-create':
      return (
        <div className={styles.buttons}>
          <CommonButton
            icon={SearchTaskIcon}
            text={'プロジェクトを作成する'}
            size={'medium'}
            color={'blue'}
            variant={'contained'}
            shape={'radius'}
            onClick={() => history.push('/co/project/create')}
            disabled={false}
          />
          <Blank height={'1rem'} />
          <CommonButton
            icon={SearchTaskIcon}
            text={'発注のサポートを依頼(無料)'}
            size={'medium'}
            color={'pink'}
            variant={'contained'}
            shape={'radius'}
            onClick={() => history.push('/contact/main')}
            disabled={false}
          />
        </div>
      );
    case 'edit-email':
      return (
        <>
          <Button
            variant="contained"
            color="primary"
            className={classes.editButton}
            onClick={() => history.push('/co')}
          >
            <img src={SearchTaskIcon} className={styles.icon} />
            <span className={styles.text}>編集する</span>
          </Button>
        </>
      );
    case 'edit-pass':
      return (
        <>
          <Button
            variant="contained"
            color="primary"
            className={classes.editButton}
            onClick={() => history.push('/password-reset-mail')}
          >
            <img src={SearchTaskIcon} className={styles.icon} />
            <span className={styles.text}>編集する</span>
          </Button>
        </>
      );
    case 'add-member':
      return (
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={() => dispatch(setModalFlag(true))}
        >
          <img src={SearchTaskIcon} className={styles.icon} />
          <span className={styles.text}>メンバーを追加する</span>
        </Button>
      );
    case 'notExistsPaymentMethod':
      return (
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={() => history.push('/co/setting/payment')}
        >
          <img src={SearchTaskIcon} className={styles.icon} />
          <span className={styles.text}>支払い方法を設定する</span>
        </Button>
      );
    case 'only-project':
      return (
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={() => history.push(`/co/project`)}
        >
          <img src={SearchTaskIcon} className={styles.icon} />
          <span className={styles.text}>プロジェクト一覧へ移動</span>
        </Button>
      );
    case 'no-entry':
      return <></>;
    default:
      return <></>;
  }
};

const RoundedButton = ({kind}) => {
  const classes = useStyles();
  const history = useHistory();
  const params = useParams();

  return (
    <DisplayContent
      kind={kind}
      classes={classes}
      history={history}
      projectId={params.id}
    />
  );
};

export default RoundedButton;
