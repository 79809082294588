import {
  SUCCESS_SUGGEST_LIST
} from '../actions/suggest'

const initialState = {
  list: []
};


function suggest(state = initialState, action) {
  switch (action.type) {
    case SUCCESS_SUGGEST_LIST:
      return {
        ...state,
        list: action.payload
      }
    default :
      return state
  }
}

export default suggest
