import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import {makeStyles} from '@material-ui/core/styles';
import {setModalFlag} from '../../../actions/member';
import {setSecondModalFlag} from '../../../actions/member';
import {requestMember} from '../../../actions/member';

import styles from './memberList.module.scss';

import PageTitle from '../../../components/PageTitle';
import MemberListItem from '../../../components/MemberListItem';
import PlusButton from '../../../components/Button/PlusButton';
import InviteMember from '../../../components/Modal/InviteMember';
import RemoveMember from '../../../components/Modal/RemoveMember';
import NotAvailable from '../../../components/NotAvailable';

const useStyles = makeStyles({
  background: {
    backgroundColor: 'rgba(0, 33, 99, 0.9)'
  }
});

const MemberList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {companyId, me} = useSelector(state => state.setting);
  const {isOpenModal, isOpenSecondModal, members} = useSelector(
    state => state.member
  );

  useEffect(() => {
    dispatch(requestMember(companyId));
  }, []);

  return (
    <>
      <div className={styles.flex}>
        <div className={styles.container}>
          <div className={styles.title}>
            <div>
              <PageTitle title="企業メンバー" />
            </div>
            <div className={styles.button}>
              <PlusButton
                text="メンバーを追加"
                handleOnClick={() => dispatch(setModalFlag(true))}
                modal={isOpenModal}
              />
            </div>
          </div>
          <div className={styles.content}>
            {members.length === 0 ? (
              <NotAvailable kind="add-member" />
            ) : (
              companyId && (
                <MemberListItem
                  member={members}
                  handleOnClick={() => dispatch(setSecondModalFlag(true))}
                  me={me}
                />
              )
            )}
          </div>
        </div>
      </div>
      <Dialog
        className={classes.background}
        onClose={() => dispatch(setModalFlag(false))}
        open={isOpenModal}
        maxWidth={false}
      >
        <InviteMember />
      </Dialog>

      <Dialog
        className={classes.background}
        onClose={() => dispatch(setSecondModalFlag(false))}
        open={isOpenSecondModal}
        maxWidth={false}
      >
        <RemoveMember disable={members.length < 2} />
      </Dialog>
    </>
  );
};

export default MemberList;
