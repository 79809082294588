import {
  SUCCESS_PAYMENT_LIST,
  SUCCESS_CREDIT_EDIT_URL,
  SUCCESS_SECURE_CREDIT_LIMIT_URL,
  SUCCESS_PAYMENT_STATE,
  SET_PAID_MODAL_FLAG
} from '../actions/payment';
import {PAYMENT_STATUS_CREDIT, PAYMENT_STATUS_PAID} from '../constants';

const initialState = {
  list: [],
  editUrl: '',
  creditLimitUrl: '',
  paymentState: {
    credit: {status_code: null, text: ''},
    paid: {status_code: null, text: ''}
  },
  isOpenModal: false,
  isRequiredPaymentSetting: false
};

const payment = (state = initialState, action) => {
  switch (action.type) {
    case SUCCESS_PAYMENT_LIST:
      return {
        ...state,
        list: action.payload
      };
    case SUCCESS_CREDIT_EDIT_URL:
      return {
        ...state,
        editUrl: action.payload
      };
    case SUCCESS_SECURE_CREDIT_LIMIT_URL:
      return {
        ...state,
        creditLimitUrl: action.payload
      };
    case SUCCESS_PAYMENT_STATE:
      const payload = action.payload;
      return {
        ...state,
        paymentState: payload,
        isRequiredPaymentSetting:
          payload.credit.status_code === PAYMENT_STATUS_CREDIT.notExistsCard &&
          payload.paid.status_code === PAYMENT_STATUS_PAID.notMember
      };
    case SET_PAID_MODAL_FLAG:
      return {
        ...state,
        isOpenModal: action.payload
      };
    default:
      return state;
  }
};

export default payment;
