import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';

import {BASE_COLOR} from './../../common';

const useStyles = makeStyles(theme => ({
  success: {
    backgroundColor: BASE_COLOR.yellowGreen,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '26px',
    textAlign: 'center',
    boxShadow: '2rem 1rem 2rem 0 rgba(0, 0, 0, 0.3)',
    borderRadius: '2rem',
    border: 'none',
    '&:hover': {
      backgroundColor: BASE_COLOR.yellowGreen,
      border: 'none'
    }
  },
  icon: {padding: '10px 0'}
}));

export const SimpleAlert = ({severity, text}) => {
  const classes = useStyles();

  return (
    <Alert
      variant="filled"
      severity={severity}
      classes={{
        root: classes[severity],
        icon: classes.icon
      }}
    >
      {text}
    </Alert>
  );
};
