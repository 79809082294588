import React, {useEffect} from 'react';
import style from './chooseEditTarget.module.scss';
import PageTitle from '../PageTitle';
import {useDispatch, useSelector} from 'react-redux';
import {requestMe} from '../../actions/setting';
import SearchTaskIcon from '../../assets/icon_task_search.svg';
import CommonButton from '../../components/CommonButton';
import {useHistory} from 'react-router';
import {useMediaQuery} from 'react-responsive';
import Divider from '@material-ui/core/Divider';

const ChooseEditTarget = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(requestMe());
  }, []);
  const me = useSelector(state => state.setting.me);
  const isMobile = useMediaQuery({query: '(max-width: 600px)'});
  const buttonSize = isMobile ? 'preMinimum' : 'small';

  return (
    <div className={style.main}>
      <div>
        <PageTitle title="ログイン情報" />
      </div>
      <div className={style.Container}>
        <div>
          <div className={style.lineContainer}>
            <p className={style.subtitle}>メールアドレス</p>
            <div className={style.emailContainer}>
              <p className={style.contents}>{me.email}</p>
              <div className={style.blankWidth} />
            </div>
          </div>
        </div>
        <div>
          <Divider />
          {isMobile ? (
            <div className={style.lineContainer}>
              <div className={style.subtitleContainer}>
                <p className={style.subtitle}>パスワード</p>
                <div className={style.button}>
                  <CommonButton
                    text={'編集する'}
                    size={buttonSize}
                    color={'blue'}
                    variant={'contained'}
                    shape={'radius'}
                    onClick={() => history.push('/password-reset-mail')}
                    disabled={false}
                    icon={SearchTaskIcon}
                  />
                </div>
              </div>
              <div className={style.passwordContainer}>
                <p className={style.contents}>●●●●●●●●</p>
                <div className={style.blankWidth} />
              </div>
            </div>
          ) : (
            <div className={style.lineContainer}>
              <p className={style.subtitle}>パスワード</p>
              <div className={style.passwordContainer}>
                <p className={style.contents}>●●●●●●●●</p>
                <CommonButton
                  text={'編集する'}
                  size={buttonSize}
                  color={'blue'}
                  variant={'contained'}
                  shape={'radius'}
                  onClick={() => history.push('/password-reset-mail')}
                  disabled={false}
                  icon={SearchTaskIcon}
                />
              </div>
            </div>
          )}
        </div>
        <div>
          <Divider />
          <div className={style.lineContainer}>
            <p className={style.subtitle}>２段階認証</p>
            <div className={style.passwordContainer}>
              <p className={style.contents}>
                {me.is_active_two_factor_auth ? '設定済' : '未設定'}
              </p>
              {me.is_active_two_factor_auth ? (
                <div className={style.blankWidth} />
              ) : (
                <CommonButton
                  text={'設定する'}
                  size={buttonSize}
                  color={'blue'}
                  variant={'contained'}
                  shape={'radius'}
                  onClick={() => history.push('/co/two-factor-auth-setting')}
                  disabled={false}
                  icon={SearchTaskIcon}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChooseEditTarget;
