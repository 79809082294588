import React from 'react';
import {useHistory} from 'react-router-dom';
import {RecruitmentTask} from '../TaskLists/recruitmentTask/index.js';
import {ProceedingTask} from '../TaskLists/proceedingTask/index.js';
import {UnderReviewTask} from '../TaskLists/underReviewTask/index.js';
import {ConfirmingTask} from '../TaskLists/confirmingTask/index.js';
import {RejectTask} from '../TaskLists/rejectTask/index.js';
import {CheckedTask} from '../TaskLists/checkedTask';
import {FailedTask} from '../TaskLists/failedTask';
import {FinishedTask} from '../TaskLists/finishedTask';
import {DraftTask} from '../TaskLists/DraftTask';
import {TASK_STATUS} from '../../../common';
import styles from './coTaskList.module.scss';

const CoTaskList = ({task}) => {
  const history = useHistory();
  // TODO: エンジニア名は仮です

  return task.map((item, index) => {
    const {
      status_info,
      status,
      updated_at,
      title,
      project_title,
      engineer = 'エンジニアの名前',
      closing_date,
      id
    } = item;

    switch (status) {
      case TASK_STATUS.draft:
        return (
          <div key={index.toString()}>
            <DraftTask item={item} index={index} isEngineer={false} />
          </div>
        );
        break;
      case TASK_STATUS.under_review:
        return (
          <div key={index.toString()}>
            <UnderReviewTask item={item} index={index} isEngineer={false} />
          </div>
        );
        break;
      case TASK_STATUS.wanted:
        return (
          <div key={index.toString()}>
            <RecruitmentTask item={item} index={index} isEngineer={false} />
          </div>
        );
        break;
      case TASK_STATUS.working:
        return (
          <div key={index.toString()}>
            <ProceedingTask item={item} index={index} isEngineer={false} />
          </div>
        );
        break;
      case TASK_STATUS.confirming:
        return (
          <div key={index.toString()}>
            <ConfirmingTask item={item} index={index} isEngineer={false} />
          </div>
        );
        break;

      case TASK_STATUS.reject:
        return (
          <div key={index.toString()}>
            <RejectTask item={item} index={index} isEngineer={false} />
          </div>
        );
        break;
      case TASK_STATUS.checked:
        return (
          <div key={index.toString()}>
            <CheckedTask item={item} index={index} isEngineer={false} />
          </div>
        );
        break;
      case TASK_STATUS.failed:
        return (
          <div key={index.toString()}>
            <FailedTask item={item} index={index} isEngineer={false} />
          </div>
        );
        break;
      case TASK_STATUS.closed:
        return (
          <div key={index.toString()}>
            <FinishedTask item={item} index={index} isEngineer={false} />
          </div>
        );
        break;
      default:
    }
  });
};

export default CoTaskList;
