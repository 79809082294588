import {all, call, put, fork, take, select} from 'redux-saga/effects';
import {startSubmit, stopSubmit, reset} from 'redux-form';
import {history} from '../store';
import {
  fetchPostJson,
  fetchGet,
  fetchPatch,
  fetchPostMulti
} from './utills/fetch';
import {
  requestPrivateCommentCreate,
  successPrivateCommentCreate,
  requestPrivateCommentList,
  successPrivateCommentList,
  requestNextPrivateCommentList,
  successNextPrivateCommentList,
  requestPublicCommentCreate,
  setPublicComment,
  requestCreateAdditionalText,
  setPrivateComment,
  pushPrivateAttachment,
  clearPrivateAttachment,
  requestProjectCommentList,
  successProjectCommentList,
  requestCreateProjectComment
} from '../actions/comment';
import {requestTaskDetail, setIsAllowReading} from '../actions/task';
import {requestTaskCommentCreate} from '../actions/task';
import {push} from 'connected-react-router';
import {makeFormErrorFormat} from '../common';

// タスクのプライベートコメント 最初の 5件 を取得する
export function* getPrivateComment() {
  while (true) {
    const action = yield take(requestPrivateCommentList);

    const [data, error] = yield call(
      fetchGet,
      `tasks/${action.payload.id}/private-comments/`
    );
    if (data && !error) {
      yield put(successPrivateCommentList(data));
      yield put(setIsAllowReading(true));
    } else {
      if (error === 403) yield put(setIsAllowReading(false));
    }
  }
}

// タスクのプライベートコメント 次の 5件 を取得する
export function* getNextPrivateComment() {
  while (true) {
    const action = yield take(requestNextPrivateCommentList);
    if (action.payload.next === '') {
      continue;
    }
    let nextPage = action.payload.next.split('api/');
    const [data, error] = yield call(fetchGet, nextPage[1]);
    yield put(successNextPrivateCommentList(data));
  }
}

// プロジェクトコメント一覧取得
export function* getProjectComments() {
  while (true) {
    const action = yield take(requestProjectCommentList);
    const {projectComments} = yield select(state => state.comment);

    const [data, error] = yield call(
      fetchGet,
      `projects/${action.payload.projectId}/project-comments/?page=${action.payload.page}`
    );
    if (data && !error) {
      const dataSet = {
        results: action.payload.useRefresh
          ? data.results
          : projectComments.concat(data.results),
        count: data.count,
        next: data.next
      };
      yield put(successProjectCommentList(dataSet));
    } else {
      if (error === 403) yield put(setIsAllowReading(false));
    }
  }
}

// プロジェクトコメントを投稿する
export function* postProjectComment() {
  while (true) {
    const action = yield take(requestCreateProjectComment);
    yield put(startSubmit('commonCommentForm'));
    try {
      const [data, error] = yield call(
        fetchPostJson,
        `projects/${action.payload.id}/project-comments`,
        action.payload
      );
      if (data && !error) {
        yield put(
          requestProjectCommentList({
            projectId: action.payload.id,
            page: 1,
            useRefresh: true
          })
        );
        yield put(reset('commonCommentForm'));
      } else {
        yield put(stopSubmit('commonCommentForm', makeFormErrorFormat(error)));
      }
    } catch (e) {
      yield put(push('/sorry'));
    }
  }
}

// タスクのプライベートコメントに投稿する
export function* postPrivateComment() {
  while (true) {
    const action = yield take(requestPrivateCommentCreate);

    yield put(startSubmit('commonCommentForm'));
    try {
      const [data, error] = yield call(
        fetchPostMulti,
        `tasks/${action.payload.id}/private-comments`,
        action.payload
      );
      if (data && !error) {
        yield put(setPrivateComment(''));
        yield put(clearPrivateAttachment());
        yield put(requestPrivateCommentList({id: action.payload.id}));
        yield put(reset('commonCommentForm'));
      } else if (error) {
        yield put(stopSubmit('commonCommentForm', makeFormErrorFormat(error)));
      }
    } catch (e) {
      yield put(push('/sorry'));
    }
  }
}

// タスク詳細の追記として投稿する
export function* postAdditionalText() {
  while (true) {
    const action = yield take(requestCreateAdditionalText);
    yield put(startSubmit('commnet'));
    try {
      const [data, error] = yield call(
        fetchPostMulti,
        `tasks/${action.payload.id}/additional-text`,
        action.payload
      );
      if (data && !error) {
        yield put(requestTaskDetail({id: action.payload.id}));
        yield put(setPrivateComment(''));
        yield put(clearPrivateAttachment());
        yield put(reset('commonCommentForm'));
      }
    } catch (e) {
      // yield put(push('/sorry'));
      console.error(e);
    }
  }
}

// パブリックコメントの場合、GETについてはイベント詳細エンドポイントから一緒に取得できるため、ここでは投稿のみを用意する
// タスクのパブリックコメントに投稿する
export function* postPublicComment() {
  while (true) {
    const action = yield take(requestPublicCommentCreate);
    try {
      const [data, error] = yield call(
        fetchPostJson,
        `tasks/${action.payload.id}/public-comments`,
        action.payload
      );
      if (data && !error) {
        yield put(requestTaskDetail({id: action.payload.id}));
        yield put(reset(action.payload.formName));
      } else if (error) {
        yield put(
          stopSubmit(action.payload.formName, makeFormErrorFormat(error))
        );
      }
    } catch (e) {
      yield put(push('/sorry'));
    }
  }
}

const Task = [
  fork(getPrivateComment),
  fork(getNextPrivateComment),
  fork(postPrivateComment),
  fork(postPublicComment),
  fork(postAdditionalText),
  fork(getProjectComments),
  fork(postProjectComment)
];

export default Task;
