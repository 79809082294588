import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import styles from './signLoading.module.scss';

const signInLoading = () => {
  // Firebaseサインアウトが完了するまで待機する企業用コンポーネント
  const history = useHistory();
  const isLoggedIn = useSelector(state => state.firebase.isLoggedIn);

  useEffect(() => {
    if (isLoggedIn) {
      history.push('/co');
    }
  });

  return (
    <section className={styles.background}>
      <div className={styles.container}>
        <h3 className={styles.notFound}>ログイン中</h3>
        <h2 className={styles.header}>少々お待ちください</h2>
      </div>
    </section>
  );
};

export default signInLoading;
