import {
  SUCCESS_CO_ME,
} from '../actions/co_setting';

const initialState = {
  me: []
}

function co_setting(state = initialState, action) {
  switch (action.type) {
    case SUCCESS_CO_ME:
      return {
        ...state,
        me: action.payload.results,
      }
    default:
      return state
  }
}

export default co_setting;
