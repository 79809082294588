import React, {FC} from 'react';
import Pagination from 'material-ui-flat-pagination';
import style from './paginate.module.scss';
import CssBaseline from '@material-ui/core/CssBaseline';
import {makeStyles} from '@material-ui/core/styles';
import {COLOR_PALLET} from '../../constants'

const useStyles = makeStyles({
  standardButton: {
    color: COLOR_PALLET.blue900,
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: COLOR_PALLET.blue900,
      color: 'white'
    },
    borderRadius: '4rem'
  },
  currentButton: {
    color: 'white',
    backgroundColor: COLOR_PALLET.blue900,
    borderRadius: '4rem'
  },
  text: {
    fontSize: '1.4rem'
  },
  rootEndButton: {
    color: COLOR_PALLET.blue900,
    backgroundColor: 'none',
    '&:hover': {
      backgroundColor: COLOR_PALLET.blue900,
      color: 'white'
    },
    borderRadius: '4rem'
  }
});
type Props = {
  limit: number;
  total: number;
  offset: number;
  handleClickPagenate: (page: number) => void;
}

const Paginator: FC<Props>=(props: Props) =>{
  const {limit, total, offset, handleClickPagenate} = props;
  const handleClick = (e: React.MouseEvent<HTMLElement>, offset: number, page: number) => {
    handleClickPagenate(page);
  }
  const classes = useStyles()
  return (
    <div className={style.container}>
        <CssBaseline />
        <Pagination
          limit={limit}
          offset={offset}
          total={total}
          previousPageLabel={'＜ 前へ'}
          nextPageLabel={'次へ ＞'}
          classes={{
            rootStandard: classes.standardButton,
            rootCurrent: classes.currentButton,
            text: classes.text,
            rootEnd: classes.rootEndButton
          }}
          onClick={handleClick}
        />
    </div>
  );
}

export default Paginator;
