import {createAction} from 'redux-actions';

// タスクの公開コメントをイベント詳細からセット
export const SET_PUBLIC_COMMENT_LIST = 'SET_PUBLIC_COMMENT_LIST';
// export const SUCCESS_PUBLIC_COMMENT_LIST = 'SUCCESS_PUBLIC_COMMENT_LIST';
export const setPublicCommentList = createAction(SET_PUBLIC_COMMENT_LIST);
// export const successPublicCommentList = createAction(SUCCESS_PUBLIC_COMMENT_LIST)

// タスクの公開コメントの次の分を取得
export const REQUEST_NEXT_PUBLIC_COMMENT_LIST =
  'REQUEST_NEXT_PUBLIC_COMMENT_LIST';
export const SUCCESS_NEXT_PUBLIC_COMMENT_LIST =
  'SUCCESS_NEXT_PUBLIC_COMMENT_LIST';
export const requestNextPublicCommentList = createAction(
  REQUEST_NEXT_PUBLIC_COMMENT_LIST
);
export const successNextPublicCommentList = createAction(
  SUCCESS_NEXT_PUBLIC_COMMENT_LIST
);

// タスクの公開コメントのテキストをリデューサーに追加
export const SET_PUBLIC_COMMENT = 'SET_PUBLIC_COMMENT';
export const setPublicComment = createAction(SET_PUBLIC_COMMENT);

// タスクの公開コメントを投稿
export const REQUEST_PUBLIC_COMMENT_CREATE = 'REQUEST_PUBLIC_COMMENT_CREATE';
export const SUCCESS_PUBLIC_COMMENT_CREATE = 'SUCCESS_PUBLIC_COMMENT_CREATE';
export const requestPublicCommentCreate = createAction(
  REQUEST_PUBLIC_COMMENT_CREATE
);
export const successPublicCommentCreate = createAction(
  SUCCESS_PUBLIC_COMMENT_CREATE
);

// タスクのプライベートコメントを取得
export const REQUEST_PRIVATE_COMMENT_LIST = 'REQUEST_PRIVATE_COMMENT_LIST';
export const SUCCESS_PRIVATE_COMMENT_LIST = 'SUCCESS_PRIVATE_COMMENT_LIST';
export const requestPrivateCommentList = createAction(
  REQUEST_PRIVATE_COMMENT_LIST
);
export const successPrivateCommentList = createAction(
  SUCCESS_PRIVATE_COMMENT_LIST
);

// タスクのプライベートコメントの次の10件を取得
export const REQUEST_NEXT_PRIVATE_COMMENT_LIST =
  'REQUEST_NEXT_PRIVATE_COMMENT_LIST';
export const SUCCESS_NEXT_PRIVATE_COMMENT_LIST =
  'SUCCESS_NEXT_PRIVATE_COMMENT_LIST';
export const requestNextPrivateCommentList = createAction(
  REQUEST_NEXT_PRIVATE_COMMENT_LIST
);
export const successNextPrivateCommentList = createAction(
  SUCCESS_NEXT_PRIVATE_COMMENT_LIST
);

// タスクのプライベートコメントのテキストをリデューサーに追加
export const SET_PRIVATE_COMMENT = 'SET_PRIVATE_COMMENT';
export const setPrivateComment = createAction(SET_PRIVATE_COMMENT);

// タスクのプライベートコメントの添付ファイルをリデューサーに追加
export const PUSH_PRIVATE_ATTACHMENT = 'PUSH_PRIVATE_ATTACHMENT';
export const pushPrivateAttachment = createAction(PUSH_PRIVATE_ATTACHMENT);

// タスクのプライベートコメントを投稿
export const REQUEST_PRIVATE_COMMENT_CREATE = 'REQUEST_PRIVATE_COMMENT_CREATE';
export const SUCCESS_PRIVATE_COMMENT_CREATE = 'SUCCESS_PRIVATE_COMMENT_CREATE';
export const requestPrivateCommentCreate = createAction(
  REQUEST_PRIVATE_COMMENT_CREATE
);
export const successPrivateCommentCreate = createAction(
  SUCCESS_PRIVATE_COMMENT_CREATE
);

// プライベートコメントの添付ファイルの削除
export const DELETE_PRIVATE_ATTACHMENT = 'DELETE_PRIVATE_ATTACHMENT';
export const deletePrivateAttachment = createAction(DELETE_PRIVATE_ATTACHMENT);

// プライベートコメントの添付ファイルの一括削除
export const CLEAR_PRIVATE_ATTACHMENT = 'CLEAR_PRIVATE_ATTACHMENT';
export const clearPrivateAttachment = createAction(CLEAR_PRIVATE_ATTACHMENT);

// 公開コメントの現在位置を更新
export const UPDATE_PRIVATE_OFFSET = 'UPDATE_PRIVATE_OFFSET';
export const updatePrivateOffset = createAction(UPDATE_PRIVATE_OFFSET);

// タスクの追記を投稿
export const REQUEST_CREATE_ADDITIONAL_TEXT = 'REQUEST_CREATE_ADDITIONAL_TEXT';
export const SUCCESS_CREATE_ADDITIONAL_TEXT = 'SUCCESS_CREATE_ADDITIONAL_TEXT';
export const requestCreateAdditionalText = createAction(
  REQUEST_CREATE_ADDITIONAL_TEXT
);
export const successCreateAdditionalText = createAction(
  SUCCESS_CREATE_ADDITIONAL_TEXT
);

// プロジェクトコメント一覧を取得
export const REQUEST_PROJECT_COMMENT_LIST = 'REQUEST_PROJECT_COMMENT_LIST';
export const SUCCESS_PROJECT_COMMENT_LIST = 'SUCCESS_PROJECT_COMMENT_LIST';
export const requestProjectCommentList = createAction(
  REQUEST_PROJECT_COMMENT_LIST
);
export const successProjectCommentList = createAction(
  SUCCESS_PROJECT_COMMENT_LIST
);

// プロジェクトコメントの作成
export const REQUEST_CREATE_PROJECT_COMMENT = 'REQUEST_CREATE_PROJECT_COMMENT';
export const requestCreateProjectComment = createAction(
  REQUEST_CREATE_PROJECT_COMMENT
);
