import React from 'react';
import {useHistory} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
// component
import TaskCancelConfirm from './TaskCancelConfirm';
import PaySuccessInfo from './PaySuccessInfo';
import PayFailedInfo from './PayFailedInfo';
import CommonConfirm from './CommonConfirm';
import PreAssignConfirm from './PreAssignConfirm';
import PreAssignConfirmCo from './PreAssignConfirmCo';
import PrePaymentModal from './PrePaymentModal';
import {setModalSetting} from '../../../actions/task';
import {requestDeleteEntry} from '../../../actions/entry';
import {
  requestAcceptPreAssign,
  requestRejectPreAssign
} from '../../../actions/assign';
import {setIsSubmitting} from '../../../actions/common';
import {userType} from '../../../common';
import {patchConfirm} from '../../../actions/confirm';
import {TASK_STATUS} from '../../../common';
// material-ui
import Dialog from '@material-ui/core/Dialog';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles({
  background: {
    backgroundColor: 'rgba(0, 33, 99, 0.9)'
  }
});

// TODO:Task 詳細画面で使用するモーダルはできるだけここにまとめるリファクタリングがしたい。
const CommonModal = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const {type, isVisible, params, onCloseHandler} = useSelector(
    state => state.task.modalSetting
  );
  return (
    <Dialog
      className={classes.background}
      onClose={() => {
        if (onCloseHandler) {
          onCloseHandler();
        }
        dispatch(
          setModalSetting({
            type: type,
            isVisible: false,
            params: {},
            onCloseHandler: null
          })
        );
      }}
      open={isVisible}
      maxWidth={false}
    >
      {/*TODO: 全て true のパターンに修正予定*/}
      {params &&
      (params.buttonProps ||
        params.leftButtonProps ||
        params.rightButtonProps) ? (
        <CommonConfirm {...params} />
      ) : (
        <Modal type={type} dispatch={dispatch} params={params} />
      )}
    </Dialog>
  );
};

const Modal = ({type, dispatch, params}) => {
  const {detail} = useSelector(state => state.task);
  const {me, isEngineer} = useSelector(state => state.setting);
  const {isSubmitting} = useSelector(state => state.common);
  const history = useHistory();

  switch (type) {
    case 'cancelConfirm':
      return <TaskCancelConfirm />;
    case 'didTaskCancel':
      return (
        <CommonConfirm
          title={'募集を取り下げました'}
          message={'またのご利用をお待ちしております。'}
          buttonProps={{
            text: 'トップページに戻る',
            onClick: () => {
              history.push(`/co`);
              dispatch(setModalSetting({type: type, isVisible: false}));
            }
          }}
        />
      );
    case 'ACCEPT_ASSIGN_OFFER':
      return (
        <CommonConfirm
          title={'アサイン依頼の承諾'}
          message={
            'このタスクのアサイン依頼を承諾しますか？\n一度承諾すると取り消しはできません。'
          }
          leftButtonProps={{
            text: '閉じる',
            onClick: () =>
              dispatch(setModalSetting({type: type, isVisible: false}))
          }}
          rightButtonProps={{
            text: '承諾',
            disabled: isSubmitting,
            onClick: () => {
              dispatch(requestAcceptPreAssign({taskId: detail.id}));
              dispatch(setIsSubmitting(true));
            }
          }}
        />
      );
    case 'REJECT_ASSIGN_OFFER':
      return (
        <CommonConfirm
          title={'アサイン依頼の辞退'}
          message={
            'このタスクのアサイン依頼を辞退しますか？\n一度辞退すると取り消しはできません。'
          }
          leftButtonProps={{
            text: '閉じる',
            onClick: () =>
              dispatch(setModalSetting({type: type, isVisible: false}))
          }}
          rightButtonProps={{
            text: '辞退',
            onClick: () => {
              dispatch(
                requestRejectPreAssign({
                  taskId: detail.id,
                  entryId: detail.entry_no
                })
              );
            }
          }}
        />
      );
    case 'NEED_INPUT_ID':
      return (
        <CommonConfirm
          title={'本人確認情報の登録が必要です'}
          message={
            'タスクの受注には本人確認情報の登録が必要です。\n下記のボタンから登録画面へ移動できます。'
          }
          leftButtonProps={{
            text: '閉じる',
            onClick: () =>
              dispatch(setModalSetting({type: type, isVisible: false}))
          }}
          rightButtonProps={{
            text: '登録画面へ',
            onClick: () => {
              history.push('/en/user/edit/id-info');
              dispatch(setModalSetting({type: type, isVisible: false}));
            }
          }}
        />
      );
    case 'SUCCESS_SAVE_PROFILE':
      const userStr = userType(isEngineer);
      return (
        <CommonConfirm
          title={'保存しました'}
          message={'入力頂いた情報を保存しました'}
          buttonProps={{
            text: '閉じる',
            onClick: () => {
              history.push(`/${userStr}/${userStr}-user/${me.id}`);
              dispatch(setModalSetting({type: type, isVisible: false}));
            }
          }}
        />
      );
    case 'SUCCESS_SAVE_ID_INFO':
      return (
        <CommonConfirm
          title={'保存しました'}
          message={'入力頂いた情報を保存しました'}
          buttonProps={{
            text: '閉じる',
            onClick: () => {
              history.push(`/en/setting`);
              dispatch(setModalSetting({type: type, isVisible: false}));
            }
          }}
        />
      );
    case 'ACCEPT_ERROR':
      //バックエンドから返ってきたエラーの詳細文
      const message = params.detail;
      return (
        <CommonConfirm
          title={'エラーです'}
          message={message}
          buttonProps={{
            text: '閉じる',
            onClick: () => {
              dispatch(
                setModalSetting({type: type, isVisible: false, params: []})
              );
              window.location.reload();
            }
          }}
        />
      );
    case 'REQUEST_TASK_REVIEW':
      return (
        <CommonConfirm
          title={'成果物確認依頼を実行します'}
          message={
            '成果物確認依頼を行うと、作業が完了したものとして企業が成果物の状態を確認します\n本当によろしいですか？？'
          }
          leftButtonProps={{
            text: '閉じる',
            onClick: () =>
              dispatch(setModalSetting({type: type, isVisible: false}))
          }}
          rightButtonProps={{
            text: '実行',
            onClick: () => {
              dispatch(setModalSetting({type: type, isVisible: false}));
              dispatch(
                patchConfirm({
                  task: params.taskId,
                  status: {status: TASK_STATUS.confirming}
                })
              );
            }
          }}
        />
      );
    case 'SUCCESS_SUBMIT_TASK':
      return (
        <CommonConfirm
          title={'新規タスクを申請しました'}
          message={
            '作成して頂いたタスクの内容を審査しております\n審査は翌営業日までに完了致します\n確認が完了致しましたら、メールにてご連絡致します。\n審査中のタスクは、TOPページよりご確認頂けます。'
          }
          buttonProps={{
            text: '完了',
            onClick: () => {
              dispatch(setModalSetting({type: type, isVisible: false}));
              history.push(`/co`);
            }
          }}
        />
      );
    case 'SUCCESS_COMPANY_EDIT':
      return (
        <CommonConfirm
          title={'保存しました'}
          message={'入力頂いた情報を保存しました'}
          buttonProps={{
            text: '閉じる',
            onClick: () => {
              history.push(`/co/info`);
              dispatch(setModalSetting({type: type, isVisible: false}));
            }
          }}
        />
      );
    case 'SUCCESS_PAYMENT_PAID':
      return <PaySuccessInfo paymentMethod={'請求書'} />;
    case 'SUCCESS_PAYMENT_CREDIT':
      return <PaySuccessInfo paymentMethod={'クレジットカード'} />;
    case 'FAILED_PAYMENT_PAID':
      return <PayFailedInfo paymentMethod={'請求書'} />;
    case 'FAILED_PAYMENT_CREDIT':
      return <PayFailedInfo paymentMethod={'クレジットカード'} />;
    case 'UNREAD_PRE_ASSIGN_CONFIRM':
      return <PreAssignConfirm params={params} />;
    case 'UNREAD_PRE_ASSIGN_CONFIRM_CO':
      return <PreAssignConfirmCo params={params} />;
    case 'REQUIRED_PRE_PAYMENT':
      return <PrePaymentModal params={params} />;
    default:
      return null;
  }
};

export default CommonModal;
