import React, {useState} from 'react';
import {connect, useDispatch, useSelector} from 'react-redux';
import {Field, FieldArray, reduxForm, getFormValues} from 'redux-form';
import {
  renderFile,
  renderText,
  renderTextArea,
  renderFiles
} from '../../../components/Field';
import style from '../co/form.module.scss';
import CommonCommentFormStyle from './CommonCommentForm.module.scss';
import ClipBlue from '../../../assets/icon_clip_blue.svg';
import Cross from '../../../assets/icon_cross.svg';
import CommonButton from '../../CommonButton';
// material-ui
import IconButton from '@material-ui/core/IconButton';
import * as Validator from '../utils/validate';
import {makeStyles} from '@material-ui/core/styles';
import FormError from '../FormError';
import Blank from '../../Blank';

const CommonCommentForm = ({
  handleSubmit,
  error,
  pristine,
  submitting,
  useFile = true
}) => {
  // 作業メモ / 追記 / プロジェクトコメント で共有している Form
  const [fields, setFields] = useState(null);
  const useStyles = makeStyles(theme => ({
    iconButtonRoot: {
      width: 'unset',
      marginLeft: '1rem'
    }
  }));
  const classes = useStyles();

  const commonCommentForm = useSelector(state => {
    return getFormValues('commonCommentForm')(state);
  });

  if (!commonCommentForm) {
    return <></>;
  }

  const {commonCommentText, attachedFiles} = commonCommentForm;

  return (
    <form onSubmit={handleSubmit}>
      <div className={style.zIndex}>
        <div className={style.boxContainer}>
          <Field
            name="text"
            required={true}
            component={renderTextArea}
            fullWidth={true}
            placeholder="案件に関する相談を入力してください。"
            validate={[Validator.RequiredItem]}
            rows={2}
            rowsMax={6}
          />

          {attachedFiles.length > 0 ? (
            <div className={style.attachmentContainer}>
              <div>
                {attachedFiles.map((file, index, array) => (
                  <div className={style.attachmentItem} key={index.toString()}>
                    <img src={ClipBlue} />
                    <span className={style.attachmentText}>
                      {`${file.name}(${file.size}Byte)`}
                    </span>
                    <IconButton
                      classes={{root: classes.iconButtonRoot}}
                      onClick={() => {
                        fields.remove(index);
                      }}
                    >
                      <img src={Cross} />
                    </IconButton>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
        <Blank height={'2.4rem'} />
        <FormError error={error} />
        <div className={CommonCommentFormStyle.buttonsContainer}>
          {useFile && (
            <div>
              <FieldArray
                name="attachedFiles"
                component={renderFiles}
                setFields={setFields}
              />
            </div>
          )}
          <div
            className={
              useFile
                ? CommonCommentFormStyle.submit
                : CommonCommentFormStyle.textSubmit
            }
          >
            <CommonButton
              type={'submit'}
              variant={'contained'}
              color={'blue'}
              size={'small'}
              text={'送信'}
              shape={'normal'}
              disabled={pristine || submitting}
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default reduxForm({
  form: 'commonCommentForm'
})(CommonCommentForm);
