import {fetchPostJson, fetchPatch, fetchGet, fetchDelete} from './utills/fetch';
import {take, put, fork, call} from 'redux-saga/effects';
import {requestSuggestList, successSuggestList} from '../actions/suggest';

// カテゴリ一覧取得
export function* getSuggestList() {
  while (true) {
    const action = yield take(requestSuggestList);
    const url = action.payload
      ? `suggest/?search=${action.payload}`
      : 'suggest/';
    const [data, error] = yield call(fetchGet, url, false);
    yield put(successSuggestList(data.results));
  }
}

const Suggest = [fork(getSuggestList)];

export default Suggest;
