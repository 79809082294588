import React, {FC, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import List from '@material-ui/core/List';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';

import {requestTaskList, requestPublicTaskList} from '../../../../actions/task';
import {updatePageOffset} from '../../../../actions/paginate';
import EnTaskList from '../../../../components/Task/EnTaskList';
import PageTitle from '../../../../components/PageTitle';
import Paginate from '../../../../components/Paginate';
import NotAvailable from '../../../../components/NotAvailable';
import styles from './searchTask.module.scss';
import Text from '../../../../components/Text';
import TaskSearch from '../../../../components/TaskSearch';
import NoDataContainer from '../../../../components/NoDataContainer';
import noDataStyles from '../../../../components/NoDataContainer/noDataContainer.module.scss';
import {RootState} from '../../../../models/RootModels';

type State = {
  onlyWanted: boolean;
};

const SearchTask: FC = () => {
  const dispatch = useDispatch();
  const [searchOptions, setSearchOptions] = useState<State>({
    onlyWanted: false
  });
  const {list, count, isLoadingTaskList} = useSelector(
    (state: RootState) => state.task
  );
  const {isEngineer, isAnonymousUser} = useSelector(
    (state: RootState) => state.setting
  );

  useEffect(() => {
    // 1は1ページ目という意味
    dispatch(requestPublicTaskList({page: 1, ...searchOptions}));
    //他のページにいったらoffsetを初期化
    dispatch(updatePageOffset(0));
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const values = {
      ...searchOptions,
      [event.target.name]: event.target.checked
    };
    setSearchOptions(values);
    dispatch(requestPublicTaskList({page: 1, ...values}));
    dispatch(updatePageOffset(0));
  };

  return (
    <>
      <div className={styles.titleContainer}>
        <PageTitle title={'仕事を探す'} />
      </div>
      <div className={styles.countContainer}>
        <div className={styles.countItemContainer}>
          <Text component="h2">該当案件</Text>
          <p className={styles.countText}>{count ? count : 0}</p>
          <Text component="h2">件</Text>
        </div>
        <div className={styles.switchContainer}>
          <Typography variant="h2" component="h2">
            募集中のタスクのみ表示
          </Typography>
          <Switch
            checked={searchOptions.onlyWanted}
            onChange={handleChange}
            name="onlyWanted"
            color="primary"
          />
        </div>
      </div>
      <TaskSearch searchOptions={searchOptions} />
      <div className={styles.container}>
        <TaskListDisplay
          list={list}
          isAnonymousUser={isAnonymousUser}
          isLoadingTaskList={isLoadingTaskList}
          isEngineer={isEngineer}
        />
        <Paginate
          // @ts-ignore
          limit={10}
          total={count}
          method={(page: number) =>
            dispatch(requestPublicTaskList({page: page, ...searchOptions}))
          }
        />
      </div>
    </>
  );
};

type TaskListDisplayProps = {
  list: [];
  isAnonymousUser: boolean;
  isLoadingTaskList: boolean;
  isEngineer: boolean;
};

const TaskListDisplay: FC<TaskListDisplayProps> = ({
  list,
  isAnonymousUser,
  isLoadingTaskList,
  isEngineer
}) => {
  if (isLoadingTaskList) {
    return (
      <NoDataContainer>
        <p className={noDataStyles.title}>読み込み中です</p>
      </NoDataContainer>
    );
  }

  return (
    <>
      {list.length > 0 ? (
        <List component="ul">
          <EnTaskList
            contents={list}
            isAnonymousUser={isAnonymousUser}
            isEngineer={isEngineer}
          />
        </List>
      ) : (
        <NotAvailable kind="notExistsTasks" />
      )}
    </>
  );
};

export default SearchTask;
