import {
  SET_IS_IMG_CHANGED,
  SET_IS_SUBMITTING,
  SET_MODAL_ERROR_MSG
} from '../actions/common';

const initialState = {
  isImgChanged: false,
  isSubmitting: false,
  page: 1,
  modalErrorMsg: null
};

function common(state = initialState, action) {
  switch (action.type) {
    case SET_IS_IMG_CHANGED:
      return {
        ...state,
        isImgChanged: action.payload
      };
    case SET_IS_SUBMITTING:
      return {
        ...state,
        isSubmitting: action.payload
      };
    case SET_MODAL_ERROR_MSG:
      return {
        ...state,
        modalErrorMsg: action.payload
      };
    default:
      return state;
  }
}

export default common;
