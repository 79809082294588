import React from 'react';
import {useHistory} from 'react-router-dom';
import classNames from 'classnames';
import styles from '../../taskList.module.scss';
import rewardStyle from '../rewards.module.scss';
import CommonLabel from '../../../../CommonLabel';
import Text from '../../../../Text';
import formatDate from '../../../../FormatDate/index';
import TaskListTheme from '../../../../TaskListTheme';
import Reward from '../../../Reward';
import {PAYMENT_METHODS} from '../../../../../constants';

// material ui
import ListItem from '@material-ui/core/ListItem';

const PaidRewardTask = ({item, index}) => {
  const history = useHistory();
  const {
    id,
    updated_at,
    sales_at,
    title,
    payment_method,
    company_name,
    project,
    paid_detail: {reward, sum, system_fee, tax}
  } = item;

  const text = payment_method === PAYMENT_METHODS.credit ? 'カード' : 'Paid';
  const color = payment_method === PAYMENT_METHODS.credit ? 'orange' : 'green';

  return (
    <TaskListTheme key={index} index={index}>
      <ListItem
        button={true}
        onClick={() => history.push(`/co/project/${project}/task/${id}`)}
      >
        <div className={classNames(styles.container)}>
          <div className={styles.title}>
            <div className={styles.flex}>
              <div className={styles.tag}>
                <CommonLabel
                  text={text}
                  shape={'normal'}
                  size={'normal'}
                  color={color}
                />
              </div>
              <Text
                children={`売上日 ${formatDate(
                  new Date(sales_at),
                  'date',
                  'str'
                )}`}
                component="text01"
              />
            </div>
            <Text className={styles.body} children={title} component="text02" />
            <div className={styles.companyName}>
              <Text
                children={company_name ? company_name : 'loading'}
                component="text01"
              />
            </div>
          </div>
          <Reward reward={reward} />
          <p className={rewardStyle.operator}>+</p>
          <Reward
            reward={system_fee}
            title={'システム手数料'}
            isNormal={false}
          />
          <p className={rewardStyle.operator}>+</p>
          <Reward reward={tax} title={'消費税'} isNormal={false} />
          <p className={rewardStyle.operator}>=</p>
          <Reward reward={sum} title={'合計'} />
        </div>
      </ListItem>
    </TaskListTheme>
  );
};

export default PaidRewardTask;
