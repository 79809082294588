import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {reduxForm, getFormSubmitErrors} from 'redux-form'
import ScoreForm from "../../../components/Form/ScoreForm"
import { requestTaskScore, requestTaskDetail } from '../../../actions/task'
import CoMenuLayout from '../../layout/CoMenuLayout'
import Title from "../../../components/Title";

class Index extends Component {

  componentDidMount() {
    id = this.props.match.params.id
    this.props.requestTaskDetail()
  }

  render() {
    return (
      <CoMenuLayout>
        <Title component="h2" text='評価'/>
        <ScoreForm {...this.props} />
      </CoMenuLayout>
    )
  }
}

let id = ''
const mapDispatchToProps = dispatch => ({
  requestTaskDetail() {
    dispatch(requestTaskDetail({id: id}));
  },
  onSubmit(value) {
    dispatch(requestTaskScore(Object.assign({id: id}, value)));
  }
});

Index = reduxForm({
  enableReinitialize: true,
  form: 'score'
})(Index);

const mapStateToProps = (state) => {
  return {
    initialValues: state.task.detail,
    project: state.task.detail,
    buttonText: '更新',
    submitErrors: getFormSubmitErrors('score')(state)
  };
};

const option = {areMergePropsEqual: () => false};
export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  option
)(Index));
