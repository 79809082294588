import React, {useEffect} from 'react';
import CommonLayout from '../layout/CommonLayout';
import MenuAppBar from '../../components/MenuAppBar';
import styles from '../../components/SCTL/sctl.module.scss';
import style from './contact.module.scss';
import PageSubTitle from '../../components/PageSubTitle';
import FooterPage from '../../components/Footer/FooterPage';
import ContactForm from '../../components/Form/contacts/ContactForm';
import DocDownloadForm from '../../components/Form/contacts/DocDownloadForm';
import {requestContact} from '../../actions/common';
import {useDispatch} from 'react-redux';
import {Switch, Route, useLocation} from 'react-router-dom';
import {DOCUMENT_URL} from '../../constants';

const textBind = {
  '/contact/main': {
    title: 'お問い合わせ'
  },
  '/contact/result': {
    title: 'お問い合わせ'
  },
  '/contact/download': {
    title: '資料請求'
  },
  '/contact/download-result': {
    title: '資料請求'
  }
};

const Contact = () => {
  const {pathname} = useLocation();
  const dispatch = useDispatch();
  return (
    <CommonLayout>
      <MenuAppBar />
      <div className={styles.pageTitleContainer}>
        <PageSubTitle title={pathname && textBind[pathname].title} />
      </div>
      <main className={styles.main}>
        <div className={styles.tableContainer}>
          <Switch>
            <Route
              exact
              path="/contact/main"
              render={() => (
                <ContactForm
                  onSubmit={values => dispatch(requestContact(values))}
                  initialValues={{contact_type: 'default'}}
                />
              )}
            />
            <Route exact path="/contact/result" render={() => <MainResult />} />
            <Route
              exact
              path="/contact/download"
              render={() => (
                <DocDownloadForm
                  onSubmit={values => {
                    dispatch(requestContact({...values, contact_type: 0}));
                  }}
                />
              )}
            />
            <Route
              exact
              path="/contact/download-result"
              render={() => <DownloadResult />}
            />
          </Switch>
        </div>
      </main>
      <FooterPage />
    </CommonLayout>
  );
};

const MainResult = () => {
  return (
    <div className={style.resultContainer}>
      <p>この度はお問い合わせありがとうございます。</p>
      <p>後ほど、担当者よりご連絡させていただきます。</p>
      <p>今しばらくお待ちください。</p>
    </div>
  );
};

const DownloadResult = () => {
  return (
    <div className={style.resultContainer}>
      <p>この度は資料のダウンロードありがとうございます。</p>
      <p>資料をご覧になり、ご不明点やお困りの点等がございましたら</p>
      <p>お気軽にお問い合わせ下さい。</p>
    </div>
  );
};

export default Contact;
