import {SUCCESS_CUSTOM_TOKEN, SET_LOGGEDIN} from '../actions/firebase';
import store from 'store';

const initialState = {
  token: '',
  isLoggedIn: store.get('isLoggedIn')
};

function firebase(state = initialState, action) {
  switch (action.type) {
    case SUCCESS_CUSTOM_TOKEN:
      return {
        ...state,
        token: action.payload
      };
    case SET_LOGGEDIN:
      return {
        ...state,
        isLoggedIn: action.payload
      };
    default:
      return state;
  }
}

export default firebase;
