import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import {makeStyles} from '@material-ui/core/styles';

import styles from './nav.module.scss';

const useStyles = makeStyles({
  background: {
    backgroundColor: '#e0e0e0'
  }
});

const Nav = ({contents}) => {
  const classes = useStyles();
  const location = useLocation();

  const activeLink = path => {
    const currentUrl = location.pathname;
    return currentUrl === path && classes.background;
  };

  return (
    <nav className={styles.background}>
      <List className={styles.container}>
        {contents.map((content, index) => (
          <Link
            className={activeLink(content.path)}
            key={index.toString()}
            to={content.path}
          >
            <ListItem className={styles.listItem} button>
              <p className={styles.text}>
                <span className={styles.questionMark}>Q. </span>
                {content.text}
              </p>
            </ListItem>
          </Link>
        ))}
      </List>
    </nav>
  );
};

export default Nav;
