import React from 'react'
import {Field} from 'redux-form'
import {renderPassword,renderText} from "@/components/Field/index"
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button';
import style from './form.module.scss'

const PasswordReset = (props) => {

  const {error, handleSubmit, submitErrors, pristine, submitting } = props;

  return (

    <div className={style.panel}>
      <div className={style.items}>
        <Typography variant="body1" gutterBottom>
          登録されているメールアドレスを入力してください。
        </Typography>
      </div>
      {error && <p className={style.error}>{error}</p>}
      <form onSubmit={handleSubmit}>
        <div className={style.items}>
          <Field name="username"
                 id="username"
                 required={true}
                 label="メールアドレス"
                 fullWidth={true}
                 component={renderText}/>
          {submitErrors && submitErrors.username  && (<FormHelperText><span className={style.error}>{submitErrors.email}</span></FormHelperText>)}
        </div>
         <div className={style.submit}>
          <input
            id="submit"
            type='submit'
            value={''}
          />
          <label htmlFor="submit">
            <Button
              disabled={pristine || submitting}
              variant="contained" component="span" color="secondary">
              送信
            </Button>
          </label>
        </div>
      </form>
    </div>
  )
};

export default PasswordReset
