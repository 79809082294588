import {createAction} from 'redux-actions';

// github login :エンジニア
export const REQUEST_GITHUB_SIGN_IN = 'REQUEST_GITHUB_SIGN_IN';
export const SUCCESS_GITHUB_SIGN_IN = 'SUCCESS_GITHUB_SIGN_IN';
export const requestGithubSignIn = createAction(REQUEST_GITHUB_SIGN_IN);
export const successGithubSignIn = createAction(SUCCESS_GITHUB_SIGN_IN);

// サインイン :企業
export const REQUEST_SIGN_IN = 'REQUEST_SIGN_IN';
export const SUCCESS_SIGN_IN = 'SUCCESS_SIGN_IN';
export const requestSignIn = createAction(REQUEST_SIGN_IN);
export const successSignIn = createAction(SUCCESS_SIGN_IN);

// アクセストークンの更新
export const REQUEST_REFRESH_TOKEN = 'REQUEST_REFRESH_TOKEN';
export const SUCCESS_REFRESH_TOKEN = 'SUCCESS_REFRESH_TOKEN';
export const requestRefreshToken = createAction(REQUEST_REFRESH_TOKEN);
export const successRefreshToken = createAction(SUCCESS_REFRESH_TOKEN);

// 新規会員登録 :企業
export const REQUEST_SIGN_UP = 'REQUEST_SIGN_UP';
export const SUCCESS_SIGN_UP = 'SUCCESS_SIGN_UP';
export const requestSignUp = createAction(REQUEST_SIGN_UP);
export const successSignUp = createAction(SUCCESS_SIGN_UP);

// 招待ユーザー新規会員登録 :企業
export const REQUEST_MEMBER_ACTIVATION = 'REQUEST_MEMBER_ACTIVATION';
export const requestMemberActivation = createAction(REQUEST_MEMBER_ACTIVATION);

export const REQUEST_SIGNOUT = 'REQUEST_SIGNOUT';
export const requestSignout = createAction(REQUEST_SIGNOUT);

//  アクセストークン
export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';
export const setAccessToken = createAction(SET_ACCESS_TOKEN);

export const SET_OPEN_TWO_FACTOR_AUTH_INPUT = 'SET_OPEN_TWO_FACTOR_AUTH_INPUT';
export const setOpenTwoFactorAuthInput = createAction(
  SET_OPEN_TWO_FACTOR_AUTH_INPUT
);

export const REQUEST_TWO_FACTOR_AUTH_URI = 'REQUEST_TWO_FACTOR_AUTH_URI';
export const requestTwoFactorAuthUri = createAction(
  REQUEST_TWO_FACTOR_AUTH_URI
);
export const SUCCESS_TWO_FACTOR_AUTH_URI = 'SUCCESS_TWO_FACTOR_AUTH_URI';
export const successTwoFactorAuthUri = createAction(
  SUCCESS_TWO_FACTOR_AUTH_URI
);
export const FAILED_TWO_FACTOR_AUTH_URI = 'FAILED_TWO_FACTOR_AUTH_URI';
export const failedTwoFactorAuthUri = createAction(FAILED_TWO_FACTOR_AUTH_URI);
