import {deleteEffect, isLoadingFlag, setEffect} from '../actions/sagaMonitor';
import {store} from '../store';

// この二つの F は saga 全体を監視して、通信中か否かを判断する為に使用
const watchEffectEnd = (effectId, res) => {
  store.dispatch(deleteEffect(effectId));
};

//↓sagaMonitorで監視するcallされるAPI達の配列を外側で定義
let calledUrls = [];

const sagaMonitor = {
  actionDispatched: action => {},
  effectRejected: e => {
    store.dispatch(deleteEffect(e.effectId));
  },
  effectTriggered: event => {
    if (event.effect.type === 'CALL') {
      //callされるAPI達を外で定義している配列に追加
      calledUrls.push(event.effect.payload.args[0]);

      //↓最初の1回目のAPI通信をスタートとし、一律で1.5秒間だけloadingを表示させる。
      if (calledUrls.length === 1) {
        store.dispatch(isLoadingFlag(true));
        setTimeout(() => {
          store.dispatch(isLoadingFlag(false));
        }, 1500);
      }
      store.dispatch(setEffect(event.effectId));
    }
  },
  effectCancelled: e => {
    store.dispatch(deleteEffect(e.effectId));
  },
  effectResolved: watchEffectEnd
};

export default sagaMonitor;
