import {createAction} from 'redux-actions';

export const SUCCESS_PERSONAL_HEAD_NEWS_LIST =
  'SUCCESS_PERSONAL_HEAD_NEWS_LIST';
export const successPersonalHeadNewsList = createAction(
  SUCCESS_PERSONAL_HEAD_NEWS_LIST
);

export const REQUEST_PERSONAL_NEWS_LIST = 'REQUEST_PERSONAL_NEWS_LIST';
export const requestPersonalNewsList = createAction(REQUEST_PERSONAL_NEWS_LIST);
export const SUCCESS_PERSONAL_NEWS_LIST = 'SUCCESS_PERSONAL_NEWS_LIST';
export const successPersonalNewsList = createAction(SUCCESS_PERSONAL_NEWS_LIST);

// 最後に取得したドキュメントを保存
export const SET_PERSONAL_NEWS_LAST_SNAPSHOT =
  'SET_PERSONAL_NEWS_LAST_SNAPSHOT';
export const setPersonalNewsLastSnapshot = createAction(
  SET_PERSONAL_NEWS_LAST_SNAPSHOT
);

// リッスンで継続的に取得するスナップショットをハンドリング
export const REQUEST_HANDLE_PERSONAL_ONSNAPSHOT =
  'REQUEST_HANDLE_PERSONAL_ONSNAPSHOT';
export const requestHandlePersonalOnSnapshot = createAction(
  REQUEST_HANDLE_PERSONAL_ONSNAPSHOT
);

// 全て読んだかどうか
export const SET_IS_READ_ALL_PERSONAL_NOTIFICATION =
  'SET_IS_READ_ALL_PERSONAL_NOTIFICATION';
export const setIsReadAllPersonalNotification = createAction(
  SET_IS_READ_ALL_PERSONAL_NOTIFICATION
);
