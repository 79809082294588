import {fetchPostJson, fetchPatch, fetchGet, fetchDelete} from './utills/fetch';
import {take, put, fork, call} from 'redux-saga/effects'
import {
  requestFormatList,
  successFormatList
} from '../actions/format'


// 納品形式一覧取得
export function* getFormatList() {
  while(true) {
    const action = yield take(requestFormatList);
    const [data, error] = yield call(fetchGet, 'formats/');
    yield put(successFormatList(data.results))
  }
}

const Format = [
  fork(getFormatList),
];

export default Format;
