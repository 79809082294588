import React from 'react';
import {createMuiTheme, makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {MuiThemeProvider} from '@material-ui/core';
import style from './requestButton.module.scss';
import {withRouter} from 'react-router-dom';
import ShakeHandsPink from '../../../assets/icon_shakehands_pink.svg';

const Index = props => {
  const {kind, userId, match, onClick} = props;
  let applyBgColor = '';
  let applyHoverColor = '';
  let applyBorderColor = 'none';
  let variant = 'contained';
  let applyColor = '#FFFFFF';
  let applyFontWeight = 'bold';
  let text = '';
  let srcImg = ShakeHandsPink;
  let applyFontSize = '1.2rem';
  let disabled = false;
  let switchStyle = style.buttonContainer;

  switch (kind) {
    case 'pink':
      applyBgColor = '#EA397D';
      applyHoverColor = '#b12a5e';
      applyFontWeight = 'bold';
      text = '依頼中';
      srcImg = null;
      break;
    case 'pinkOutlined':
      variant = 'outlined';
      applyBorderColor = '0.1rem solid #EA397D';
      applyColor = '#EA397D';
      applyBgColor = '#FFFFFF';
      text = '依頼する';
      applyHoverColor = '#ffe7ef';
      srcImg = ShakeHandsPink;
      switchStyle = style.buttonContainerWithIcon;
      break;
    case 'disabled':
      variant = 'outlined';
      applyBorderColor = '0.1rem solid';
      applyColor = '#EA397D';
      applyBgColor = '';
      text = '依頼する';
      applyHoverColor = '#ffe7ef';
      disabled = true;
      srcImg = null;
      // default:
      //   applyBgColor = '';
      //   applyHoverColor = '';
      //   variant = '';
      break;
  }

  const useStyles = makeStyles(theme => ({
    button: {
      backgroundColor: applyBgColor,
      border: applyBorderColor,
      color: applyColor,
      fontWeight: applyFontWeight,
      width: '16rem',
      height: '3rem',
      fontSize: applyFontSize,
      '&:hover': {
        backgroundColor: applyHoverColor
      }
    }
  }));

  const classes = useStyles();

  return (
    <div className="stage">
      <Button
        variant={variant}
        className={classes.button}
        onClick={onClick}
        disabled={disabled}
      >
        <div className={switchStyle}>
          <img src={srcImg} />
          <div>{text}</div>
        </div>
      </Button>
    </div>
  );
};

export default withRouter(Index);
