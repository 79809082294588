import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Switch, withRouter, Route} from 'react-router-dom';
import {reduxForm, getFormSubmitErrors, getFormValues} from 'redux-form';
import PanelLayout from '../../../layout/PanelLayout';
import SignUpForm from '../../../../components/Form/SignupForm';
import {requestSignUp} from '../../../../actions/sign';
import style from './signup.module.scss';
import CoSignUpResult from '../CoSignupResult';
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';

const theme = createMuiTheme({
  overrides: {
    MuiButton: {
      outlined: {
        border: '0.1rem solid #FFFFFF'
      }
    }
  }
});

class Signup extends Component {
  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <PanelLayout>
          <article className={style.main}>
            <div className={style.signUpText}>新規会員登録</div>
          </article>
          <Switch>
            <Route
              exact
              path="/co-signup"
              render={() => <SignUpForm {...this.props} />}
            />
            <Route exact path="/co-signup/result" component={CoSignUpResult} />
          </Switch>
        </PanelLayout>
      </MuiThemeProvider>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  onSubmit(value) {
    dispatch(requestSignUp(value));
  }
});

Signup = reduxForm({
  form: 'signup'
})(Signup);

const mapStateToProps = state => {
  return {
    submitErrors: getFormSubmitErrors('signup')(state),
    formValues: getFormValues('signup')(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Signup);
