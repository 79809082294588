import React from 'react';
import TextField from '@material-ui/core/TextField';
import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: '5rem',
    width: '100%'
  },
  inputMultiline: {color: '#222222'}
}));

const ReviewTextField = field => {
  const classes = useStyles();
  const {value, onChange} = field.input;

  return (
    <TextField
      id="outlined-multiline-static"
      multiline
      rows="4"
      placeholder={'特に何か優れていたか詳細にレビューをお願いいたします。'}
      className={classes.textField}
      InputProps={{
        classes: {inputMultiline: classes.inputMultiline}
      }}
      margin="normal"
      variant="outlined"
      value={value}
      onChange={e => onChange(e.target.value)}
    />
  );
};

export default ReviewTextField;
