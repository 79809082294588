import React from 'react';
import styles from '../mainTermsOfService.module.scss';

const TermsOfServiceEn = () => {
  return (
    <main className={styles.main}>
      <div className={styles.inner}>
        <h1 className={styles.title}>利用規約</h1>
        <p className={styles.text}>
          利用規約（以下「本規約」といいます。）には、株式会社m-Lab（以下「当社」といいます。）の提供する本サービスのご利用にあたり、利用希望者及びメンバーの皆様に遵守していただかなければならない事項及び当社と利用希望者及びメンバーの皆様との間の権利義務関係が定められております。本サービスをご利用になる方は、本規約に同意する前に、必ず全文をお読み下さいますようお願い致します。
        </p>
        <h3 className={styles.header}>第1条 適用</h3>
        <p className={styles.text}>
          1.本規約は、本サービスの利用に関する当社と利用希望者及びメンバーとの間の権利義務関係を定めることを目的とし、利用希望者及びメンバーと当社の間の本サービスの利用に関わる一切の関係に適用されます。
        </p>
        <p className={styles.text}>
          2.当社が当社ウェブサイト上で随時掲載する本サービスに関するルール、諸規定等は本規約の一部を構成するものとします。
        </p>
        <h3 className={styles.header}>第2条 定義</h3>
        <p className={styles.text}>
          本規約において使用する以下の用語は、各々に定める意味を有するものとします。
        </p>
        <p className={styles.text}>
          (1)本サービスとは、当社が提供する「Task」という名称のサービス（理由の如何を問わずサービスの名称又は内容が変更された場合は、当該変更後のサービスを含みます。）で、業務委託に関する情報提供及び業務委託を遂行するための機能を提供することにより事業者間の直接取引を支援するサービスを意味します。
        </p>
        <p className={styles.text}>
          (2)当社ウェブサイトとは、そのドメインが「task.site」である当社が運営するウェブサイト（理由の如何を問わず当社のウェブサイトのドメイン又は内容が変更された場合は、当該変更後のウェブサイトを含みます。）及び付随するウェブサイトを意味します。
        </p>
        <p className={styles.text}>
          (3)利用希望者とは、第3条において定義された「利用希望者」を意味します。
        </p>
        <p className={styles.text}>
          (4)登録情報とは、第3条において定義された「登録情報」を意味します。
        </p>
        <p className={styles.text}>
          (5)メンバーとは、第3条に基づき本サービスの利用登録がなされた個人、法人又は組織を意味します。
        </p>
        <p className={styles.text}>
          (6)クライアントとは、本サービスを通してメンバーに対し業務を委託し、又は委託しようとする個人、法人又は組織を意味します。
        </p>
        <p className={styles.text}>
          (7)プロジェクトとは、クライアントが設定した目的についての総称を意味します。
        </p>
        <p className={styles.text}>
          (8)タスクとはプロジェクトを遂行するための、1つ1つの作業であり、クライアントがメンバーに依頼するために本サービスに掲載される業務を意味します。
        </p>
        <p className={styles.text}>
          (9)エントリーとは、メンバーが本サービスに掲載されるタスクに対して応募することを意味します。
        </p>
        <p className={styles.text}>
          (10)知的財産権とは、著作権、特許権、実用新案権、商標権、意匠権その他の知的財産権（それらの権利を取得し、又はそれらの権利につき登録等を出願する権利を含みます。）を意味します。
        </p>
        <p className={styles.text}>
          (11)「GitHub」とは、GitHub社（日本においては、ギットハブ・ジャパン合同会社）が運営するサービスをいいます。
        </p>
        <h3 className={styles.header}>第3条 登録</h3>

        <p className={styles.text}>
          1.本サービスの利用を希望する者（以下「利用希望者」といいます。）は、本規約を遵守することに同意し、かつ当社の定める一定の情報（以下「登録情報」といいます。）を当社の定める方法で当社に提供することにより、当社に対し、本サービスの利用の登録を申請することができます。登録にはGitHubで登録されたアカウントを本サービスのアカウントとして使用します。登録希望者は登録申請時にGitHubに登録している個人情報の一部について開示及び共有されることに同意されたものとみなされます。
        </p>
        <p className={styles.text}>
          2.利用希望者は、登録の申請にあたり、真実、正確かつ最新の情報を当社に提供しなければなりません。
        </p>
        <p className={styles.text}>
          3.18歳未満の方は本サービスをご利用いただけません。また、18歳以上の未成年である場合は、メンバーとなること及び本規約に従って本サイトを利用することを、登録申請する前に親権者の同意を得なければなりません。
        </p>
        <p className={styles.text}>
          4.当社は、第1項に基づき申込を行った利用希望者が、以下の各号のいずれかの事由に該当する場合は、登録を拒否することがあります。
        </p>
        <p className={styles.text}>
          (1)本規約に違反するおそれがあると当社が判断した場合
        </p>
        <p className={styles.text}>
          (2)当社に提供された登録情報の全部又は一部につき虚偽、誤記又は記載漏れがあった場合
        </p>
        <p className={styles.text}>
          (3)過去に本サービスの利用の登録を取り消された者である場合
        </p>
        <p className={styles.text}>
          (4)反社会的勢力等（暴力団、暴力団員、右翼団体、反社会的勢力、その他これに準ずる者を意味します。以下同じ。）である、又は資金提供その他を通じて反社会的勢力等の維持、運営若しくは経営に協力若しくは関与する等反社会的勢力等との何らかの交流若しくは関与を行っていると当社が判断した場合
        </p>
        <p className={styles.text}>
          (5)その他、当社が登録を適当でないと判断した場合
        </p>
        <p className={styles.text}>
          5.利用希望者の登録の申請手続きにおいて利用希望者のGitHubアカウントを利用した認証が完了した時点で、利用希望者をメンバーとみなします。また、アカウントの認証が完了した時点において、サービス利用契約（以下「利用契約」といいます。）がメンバーと当社の間に成立します。
        </p>
        <p className={styles.text}>
          6.メンバーが登録申請した日を利用開始日とします。
          <br />
          7.メンバーは、利用開始後も、銀行口座など本サービスを受けるために必要な情報を登録情報として追加入力する必要があります。メンバーは、登録情報に変更があった場合は、遅滞なく、当社の定める方法により、当該変更事項を当社に通知し、当社から要求された情報を提出するものとします。
        </p>
        <h3 className={styles.header}>第4条 契約期間</h3>

        <p className={styles.text}>
          メンバーに対する本サービスの利用期限は、本サービスの終了日又はユーザーに対する本契約の解約若しくは解除の日までとします。
        </p>
        <h3 className={styles.header}>第5条 サービスの利用</h3>

        <p className={styles.text}>
          1.メンバーは、利用契約の有効期間中、本規約に従って、当社の定める方法に従い、本サービスを利用することができます。
        </p>
        <p className={styles.text}>
          2.メンバーと求人企業間の契約について、当社は当該契約の当事者とならず、当該契約について一切責任を負わないものとします。
        </p>
        <h3 className={styles.header}>第6条 アカウントの管理</h3>

        <p className={styles.text}>
          1.メンバーは、本サービスにおけるアカウント情報を含む登録情報について、メンバーの責任で管理及び保管するものとします。
        </p>
        <p className={styles.text}>
          2.アカウント情報の管理不十分、使用上の過誤、第三者の使用等による損害の責任はメンバーが負うものとし、当社は一切の責任を負いません。
        </p>
        <h3 className={styles.header}>第7条 メンバー情報、個人情報の取扱い</h3>

        <p className={styles.text}>
          1.当社は原則として、本サービスで開示されるメンバー情報以外のメンバー情報について、本利用規約に別段の定めがある場合を除き、事前の同意なく第三者に対し開示することはありません。ただし、公的機関からの照会及び当社が法令によって開示義務を負う場合などはこの限りではありません。
        </p>
        <p className={styles.text}>
          2.当社は、本サービスの向上、請求及び当社のマーケティングなどの目的でメンバー情報の集計及び分析などをすることができるものとします。
        </p>
        <p className={styles.text}>
          3.当社は、当社のプライバシーポリシーに従い、メンバー情報及びメンバー情報を管理します。
        </p>
        <p className={styles.text}>
          4.メンバーは、本サービスで取得した個人情報について、本サービスのためだけに使用するものとし、個人情報保護法の定めに従い個人情報を管理するものとします。また、必要に応じて適切に本人の同意を得るものとします。
        </p>
        <p className={styles.text}>
          5.メンバーが本サービスの利用を終了した場合、当社は当該メンバーのアカウントを削除します。ただし、メンバーの情報のうち、第2項のマーケティングなどの目的で使用する、個人及び法人を特定できない情報については、利用の終了後もなお当社が保有し、使用し続けるものとし、メンバーはこれを予め承諾します。
        </p>
        <p className={styles.text}>
          6.本条の規定は、本サービス終了後も有効に存続するものとします。
        </p>
        <h3 className={styles.header}>第8条 外部委託</h3>

        <p className={styles.text}>
          当社は、本サービスの運営について、必要に応じて当社の業務の全部又は一部を外部委託することができるものとします。
        </p>
        <h3 className={styles.header}>第9条 契約の成立</h3>

        <p className={styles.text}>
          1.メンバーは、クライアントが本サービスに掲載した業務内容、報酬及び期限等のタスクに関する情報を閲覧し、希望するものにエントリーすることができます。その後メンバーとクライアントとの間で交わされる契約によって、業務委託契約が締結されるものとします。
        </p>
        <p className={styles.text}>
          2.前項の契約締結に際して、当社はその合意の存否及び内容について関知せず、その結果生じた損害について一切の責任を負わないものとします。
        </p>
        <h3 className={styles.header}>第10条 タスクの遂行</h3>

        <p className={styles.text}>
          1.メンバーは、クライアントとの契約に従った成果物の引渡し義務を負います。
        </p>
        <p className={styles.text}>
          2.メンバーは、本サービスにおいて受託したタスクについて、法律上の規定その他業務委託に関する法律を遵守しなければなりません。
        </p>
        <p className={styles.text}>
          3.メンバーは、当該成果物を定められた期限までに作業を完了しなければなりません。また、メンバーは、作業が終了した時点で本サービスを通じて納品を行い、クライアントに対して作業の報告を行わなくてはいけません。納品についてはクライアントが指定した形式で行ってください。
        </p>
        <p className={styles.text}>
          4.クライアントは納品された成果物を検収し、メンバーに対して検収結果（合格または不合格）を通知する義務を負うものとします。成果物の納品後、30日以内に、クライアントが検収結果を合理的な理由なく報告しない場合、当該成果物の検収の結果は、クライアントによって合格とされたものとみなします。
        </p>
        <p className={styles.text}>
          5.メンバーは、クライアントが修正依頼をした場合は、その依頼に応じて修正を行う必要があります。修正後の納品と報告に関する規定については、第3項に準じます。
        </p>
        <p className={styles.text}>
          6.メンバーは、納品が完了し案件が終了したときは、クライアントに対して評価を行わなくてはいけません。メンバーとクライアントの相互の評価に伴い、タスクは完了となります。
        </p>
        <h3 className={styles.header}>第11条 報酬及び支払方法</h3>

        <p className={styles.text}>
          1.本サービスでは、15日、月末の月2回の締日を設けており、タスクが完了して報酬が確定した日が当月の1日から15日までの場合は当月末に、報酬が確定した日が当月の16日から月末までの場合は、翌月15日にメンバーの皆さまにご登録いただいている銀行口座に報酬額のお振り込みをいたします。
        </p>
        <p className={styles.text}>
          2.振込手数料は、報酬金額から引かれます。そのため、本サービスの報酬管理画面では、あらかじめ未出金の報酬金額より振込手数料分が引かれた金額が「未出金合計金額」に記載されるようにしております。
        </p>
        <p className={styles.text}>
          3.ご登録されている口座情報に相違があり、振込ができなかった場合も振込手数料をご負担いただきます。そのため、必ずご入力いただいた口座情報に相違がないことをご確認の上、ご登録をお願いいたします。
        </p>
        <h3 className={styles.header}>第12条 禁止行為等</h3>

        <p className={styles.text}>
          1.メンバーは、本サービスの利用にあたり、以下の各号のいずれかに該当する行為をしてはなりません。
        </p>
        <p className={styles.text}>(1)本規約の規定に違反する行為</p>
        <p className={styles.text}>
          (2)クライアントと個人情報をやり取りする行為
        </p>
        <p className={styles.text}>
          (3)本サービスを通じて知り合ったクライアントから、本サービス外で業務を直接受託する行為、又は直接受託することを目的として誘導する行為
        </p>
        <p className={styles.text}>
          (4)当社、他のメンバーその他の第三者の知的財産権、肖像権、プライバシーの権利、名誉、著作権、その他の権利又は利益を侵害する行為（かかる侵害を直接又は間接に惹起する行為を含みます。）
        </p>
        <p className={styles.text}>
          (5)メンバーが、社外の第三者に本サービスを使用させる行為
        </p>
        <p className={styles.text}>(6)当社又は第三者を誹謗中傷する行為</p>
        <p className={styles.text}>
          (7)犯罪行為に関連する行為又は公序良俗に反する行為
        </p>
        <p className={styles.text}>
          (8)猥褻な情報又は青少年に有害な情報を送信する行為
        </p>
        <p className={styles.text}>
          (9)法令又はメンバー若しくはメンバーが所属する業界団体の内部規則に違反する行為
        </p>
        <p className={styles.text}>
          (10)コンピューター・ウィルスその他の有害なコンピューター・プログラムを含む情報を送信する行為
        </p>
        <p className={styles.text}>
          (11)本サービスに関し利用しうる情報を改ざんする行為
        </p>
        <p className={styles.text}>
          (12)当社が定める一定のデータ容量以上のデータを、本サービスを通じて送信する行為
        </p>
        <p className={styles.text}>
          (13)当社による本サービスの運営を妨害するおそれのある行為
        </p>
        <p className={styles.text}>(14)その他当社が不適切と判断する行為</p>
        <p className={styles.text}>
          2.メンバー又はメンバーが作成したアカウントによる情報の送信行為が前項第1号から第13号までの各号のいずれかに該当し、又は該当するおそれがあると当社が判断した場合には、メンバーに通知し改善を依頼します。改善依頼後もメンバーに改善が見られない場合、又はメンバーの改善を待つ時間的猶予がない場合、当社は、メンバーによる本サービス上の情報の全部又は一部を削除し、またアカウントを使用できなくすることができるものとします。当社は、本項に基づき当社が行った措置によってメンバー及び第三者に生じた損害について一切の責任を負いません。
        </p>
        <h3 className={styles.header}>第13条 本サービスの停止等</h3>

        <p className={styles.text}>
          1.当社は、以下のいずれかに該当する場合には、メンバーに事前に通知することなく、本サービスの利用の全部又は一部を停止又は中断することができるものとします。
        </p>
        <p className={styles.text}>
          (1)本サービスに係るコンピューター・システムの点検又は保守作業を定期的又は緊急に行う場合
        </p>
        <p className={styles.text}>
          (2)コンピューター、通信回線等が事故により停止した場合
        </p>
        <p className={styles.text}>
          (3)火災、停電、天災地変などの不可抗力により本サービスの運営ができなくなった場合
        </p>
        <p className={styles.text}>
          (4)外部サービスに、トラブル、サービス提供の中断又は停止、本サービスとの連携の停止、仕様変更等が生じた場合
        </p>
        <p className={styles.text}>
          (5)その他、当社が停止又は中断を必要と判断した場合
        </p>
        <p className={styles.text}>
          2.当社は、本条に基づき当社が行った措置に基づきメンバーに生じた損害について一切の責任を負いません。
        </p>
        <h3 className={styles.header}>第14条 設備の負担等</h3>

        <p className={styles.text}>
          1.本サービスの提供を受けるために必要な、コンピューター、ソフトウェアその他の機器、通信回線その他の通信環境等の準備及び維持は、メンバーの費用と責任において行うものとします。
        </p>
        <p className={styles.text}>
          2.メンバーは自己の本サービスの利用環境に応じて、コンピューター・ウィルスの感染の防止、不正アクセス及び情報漏洩の防止等のセキュリティ対策を自らの費用と責任において講じるものとします。
        </p>
        <p className={styles.text}>
          3.メンバーは、本サービスの利用中に、メンバーが保有する情報の消滅若しくは改変又は機器の故障、損傷等が生じないよう十分な注意を払うものとし、当社はメンバーに発生したかかる損害について一切責任を負わないものとします。
        </p>
        <h3 className={styles.header}>第15条 権利帰属</h3>

        <p className={styles.text}>
          1.本サービスに関する知的財産権は全て当社又は当社にライセンスを許諾している者に帰属しており、本規約に基づく本サービスの利用許諾は、当社ウェブサイト又は本サービスに関する当社又は当社にライセンスを許諾している者の知的財産権の使用許諾を意味するものではありません。
        </p>
        <p className={styles.text}>
          2.本サービスを通じてメンバーがクライアントに対して納品した成果物に関する知的財産権は、タスクが完了するまでの間はメンバーに帰属するものとし、タスクが完了した段階でクライアントに移転するものとします。ただし、メンバーが本取引開始前より有している知的財産権（以下「留保知的財産権」といいます。）は移転しないものとします。留保知的財産権について、メンバーはクライアントに対し、当該成果物を利用するために必要な範囲で留保知的財産権の利用（第三者への使用許諾を含みます。）を無償で許諾するものとします。
        </p>
        <p className={styles.text}>
          3.第三者の保有する知的財産権について、第三者の許可を得た上でメンバーが成果物に利用した場合、該当する知的財産権は、第三者に帰属し、クライアントに移転しないものとします。また、メンバーはクライアントに対して、当該成果物にかかる著作者人格権を行使しないものとします。
        </p>
        <p className={styles.text}>
          4.本条の第2項及び第3項について、メンバーとクライアント間において別途の取決めがある場合は、同取決めを優先するものとします。
        </p>
        <h3 className={styles.header}>第16条 反社会的勢力の排除について</h3>

        <p className={styles.text}>
          1.メンバーは本サービスを利用するにあたって、自身が反社会的勢力（「暴力団員による不当な行為の防止等に関する法律」に定義する暴力団及びその関係団体をいうほか、総会屋、社会運動や政治運動を標ぼうするゴロ、特殊知能暴力団等、その他反社会的勢力の総称をいい、本規約では以後「反社会的勢力」といいます）ではないことを表明し、将来にわたっても、所属もしくは該当、又は関与していないことを確約するものとします。
        </p>
        <p className={styles.text}>
          2.当社は、メンバーが反社会的勢力に所属もしくは該当する、又は関与していると判断した場合、当該メンバーに事前に通知等を行うことなく、当該メンバーによる本サービスの利用停止、当該メンバーのメンバー資格の取消しなど必要な措置をとることができるものとします。
        </p>
        <h3 className={styles.header}>第17条 退会</h3>

        <p className={styles.text}>
          1.本サービスにおいて、メンバーはいつでも退会できます。また、退会により登録の抹消が行われた時点で、本サービスの全部が利用できなくなるものとします。
        </p>
        <p className={styles.text}>
          2.メンバーがタスクを請け負っている場合は、タスクが完了するまで退会をすることはできません。退会後に、未払いの報酬がある場合は第11条（報酬及び支払方法）第1項に従って支払われます。
        </p>
        <p className={styles.text}>
          3.退会後のメンバー情報の取扱については、第7条（メンバー情報、個人情報の取扱い）の規定に従うものとします。
        </p>
        <h3 className={styles.header}>第18条 登録取消等</h3>

        <p className={styles.text}>
          1.当社は、メンバーが、以下の各号のいずれかの事由に該当する場合は、事前に通知又は催告することなく、当該メンバーについて本サービスの利用を一時的に停止し、又はメンバーとしての登録を取り消すことができます。
        </p>
        <p className={styles.text}>(1)本規約のいずれかの条項に違反した場合</p>
        <p className={styles.text}>
          (2)登録情報に虚偽の事実があることが判明した場合
        </p>
        <p className={styles.text}>
          (3)手段の如何を問わず、本サービスの運営を妨害した場合
        </p>
        <p className={styles.text}>
          (4)支払停止若しくは支払不能となり、又は破産手続開始、民事再生手続開始、会社更生手続開始、特別清算開始若しくはこれらに類する手続の開始の申立てがあった場合
        </p>
        <p className={styles.text}>
          (5)自ら振出し、若しくは引受けた手形若しくは小切手につき、不渡りの処分を受けた場合、又は手形交換所の取引停止処分その他これに類する措置を受けたとき
        </p>
        <p className={styles.text}>
          (6)差押、仮差押、仮処分、強制執行又は競売の申立てがあった場合
        </p>
        <p className={styles.text}>(7)租税公課の滞納処分を受けた場合</p>
        <p className={styles.text}>
          (8)メンバーが個人である場合又は取締役が代表取締役一名のみの法人において、その個人もしくは代表取締役が死亡した場合又は後見開始、保佐開始若しくは補助開始の審判を受けた場合
        </p>
        <p className={styles.text}>(9)第3条（登録）第4項各号に該当する場合</p>
        <p className={styles.text}>
          (10)その他、当社がメンバーとしての登録の継続を適当でないと判断した場合
        </p>
        <p className={styles.text}>
          2.前項各号のいずれかの事由に該当した場合、メンバーは、当社に対して負っている債務の一切について当然に期限の利益を失います。また、メンバーが債務の支払を遅滞した場合、メンバーは登録抹消された日の翌日から起算して年14.6％の割合による遅延損害金を当社に支払うものとします。
        </p>
        <p className={styles.text}>
          3.当社は、本条に基づき当社が行った行為によりメンバー及び第三者に生じた損害について一切の責任を負いません。
        </p>
        <p className={styles.text}>
          4.当社は、第1項に定める措置をなした時点で当該メンバーに支払われることとなっていた金銭について、当社の判断により、一定期間その支払を留保し、又は支払をせずに当社が指定する他の方法により精算することができるものとします。
        </p>
        <p className={styles.text}>
          5.本条に基づく登録抹消後のメンバー情報の取扱については、第7条（メンバー情報、個人情報の取扱い）の規定に従うものとします。
        </p>
        <h3 className={styles.header}>第19条 保証の否認及び免責</h3>

        <p className={styles.text}>
          1.当社は、メンバーが本サービスを利用することについて如何なる保証も行うものではありません。本サービスは現状有姿で提供されるものであり、当社は本サービスについて、特定の目的への適合性、商業的有用性、完全性、継続性等を含め、一切保証を致しません。
        </p>
        <p className={styles.text}>
          2.メンバーは、本サービスを利用することが、メンバーに適用のある法令、業界団体の内部規則等に違反するか否かを自己の責任と費用に基づいて調査するものとし、当社は、メンバーによる本サービスの利用が、メンバーに適用のある法令、業界団体の内部規則等に適合することを何ら保証するものではありません。
        </p>
        <p className={styles.text}>
          3.当社は、本サービス上で行われるメンバーとクライアント間の取引を管理する責任を負わず、メンバーとクライアント間の取引によって生じた不利益・損害について一切の責任を負いません。
        </p>
        <p className={styles.text}>
          4.当社は、メンバー及びクライアントが本サービス上で取引を完了することを保証するものでもありません。当社は、本サービスを利用するクライアントの業務遂行について、適法性、有用性、完全性及び正確性等の確認及び保証を行わないとともに、その瑕疵に関して一切の責任を負いません。
        </p>
        <p className={styles.text}>
          5.本サービス又は当社ウェブサイトに関連してメンバーと第三者との間において生じた取引、連絡、紛争等については、メンバーの責任において処理及び解決するものとし、当社はかかる事項について一切責任を負いません。
        </p>
        <p className={styles.text}>
          6.本サービス上でやりとりされるメッセージや送受信されるファイルに個人情報等が含まれていた場合、それによって会員が被った不利益・損害について、当社は一切責任を負いません。
        </p>
        <p className={styles.text}>
          7.本サービスは、インターネット環境に合わせるため及び利便性の向上を目的として、日々仕様が変化していきます。仕様が日々変わっていくことについて、メンバーは予め承知するものとします。
        </p>
        <p className={styles.text}>
          8.当社は、本サービスに関連してメンバー及び第三者が被った損害について、一切賠償の責任を負いません。
        </p>
        <h3 className={styles.header}>第20条 メンバーの賠償等の責任</h3>

        <p className={styles.text}>
          1.メンバーは、本規約に違反することにより、又は本サービスの利用に関連して当社に損害を与えた場合、当社に対しその損害を賠償しなければなりません。
        </p>
        <p className={styles.text}>
          2.メンバーが、本サービスに関連して他のメンバーその他の第三者からクレームを受け又はそれらの者との間で紛争を生じた場合には、直ちにその内容を当社に通知するとともに、メンバーの費用と責任において当該クレーム又は紛争を処理し、当社からの要請に基づき、その経過及び結果を当社に報告するものとします。
        </p>
        <p className={styles.text}>
          3.メンバーによる本サービスの利用に関連して、当社が、他のメンバーその他の第三者から権利侵害その他の理由により何らかの請求を受けた場合は、メンバーは当該請求に基づき当社が当該第三者に支払を余儀なくされた金額を賠償しなければなりません。
        </p>
        <h3 className={styles.header}>第21条 秘密保持</h3>

        <p className={styles.text}>
          当社及びメンバーは本サービスに関連して相手方が自己に対して秘密に取り扱うことを求めて開示した非公知の情報について、相手方の事前の書面による承諾がある場合を除き、秘密に取り扱うものとします。
        </p>
        <h3 className={styles.header}>
          第22条 本規約等の変更及び本サービスの終了
        </h3>

        <p className={styles.text}>
          1.当社は、必要が生じたときに、民法（制定：明治29年4月27日法律第89号
          改正：令和元年6月14日法律第34号）第548条の4の規定に基づいて、本規約（当社ウェブサイトに掲載する本サービスに関するルール、諸規定等を含みます。以下本項において同じ。）を随時変更することがあります。なお、この場合には、メンバーの利用条件その他利用契約の内容は、変更後の新しい本規約を適用するものとします。
        </p>
        <p className={styles.text}>
          2.当社は、前項の変更を行う場合は、その旨及び変更後の規定の内容並びにその効力発生時期は、効力発生時期が到来するまでに本サービス上に掲載すること、電子メールの送信又はその他相当の方法により周知して、変更後の新しい本規約の内容をメンバーに通知するものとします。
        </p>
        <p className={styles.text}>
          3.当社は、当社の都合により、本サービスの提供を、一定期間の予告をもって終了することができます。当社が本サービスの提供を終了する場合、当社は事前にメンバーに対して通知するものとします。
        </p>
        <p className={styles.text}>
          4.本規約の変更及び終了によりメンバー又は第三者に発生したすべての損害について、当社は一切の責任を負いません。
        </p>
        <h3 className={styles.header}>第23条 連絡/通知</h3>

        <p className={styles.text}>
          1.本サービスに関する問い合わせその他メンバーから当社に対する連絡又は通知、及び本規約の変更に関する通知その他当社からメンバーに対する連絡又は通知は、当社の定める方法で行うものとします。
        </p>
        <p className={styles.text}>
          2.メンバーは、本サービスへの登録申請の際に当社に提供したメンバーのメールアドレスまたは電話番号がメンバーに対する連絡先として有効であることを当社に対し保証するものとします。当社が当該メールアドレス宛てにメンバーに対する通知を行った場合、又は当該電話番号宛に電話した場合において、当社の責に帰さない事由により連絡が到達しなかったとしても、メンバーはこれについて何ら異議を述べないものとします。
        </p>
        <h3 className={styles.header}>第24条 本サービスに係る譲渡等</h3>

        <p className={styles.text}>
          1.メンバーは、当社の書面による事前の承諾なく、利用契約上の地位又は本規約に基づくメンバーとしての権利若しくは義務につき、第三者に対し、譲渡、移転、担保設定、その他の処分をすることはできません。
        </p>
        <p className={styles.text}>
          2.当社は本サービスにかかる事業を第三者に譲渡（事業譲渡、会社分割その他態様の如何を問わないものとします。）した場合には、当該譲渡に伴い利用契約上の地位、本規約に基づく権利及び義務並びにメンバーの登録情報その他の情報を当該譲渡の譲受人に譲渡することができるものとし、メンバーは、かかる譲渡につき本項において予め同意したものとします。
        </p>
        <h3 className={styles.header}>第25条 分離可能性</h3>
        <p className={styles.text}>
          本規約のいずれかの条項又はその一部が、法令等により無効又は執行不能と判断された場合であっても、本規約の残りの規定及び一部が無効又は執行不能と判断された規定の残りの部分は、継続して完全に効力を有し、当社及びメンバーは、当該無効若しくは執行不能の条項又は部分を適法とし、執行力を持たせるために必要な範囲で修正し、当該無効若しくは執行不能な条項又は部分の趣旨並びに法律的及び経済的に同等の効果を確保できるように努めるものとします。
        </p>
        <h3 className={styles.header}>第26条 準拠法及び管轄裁判所</h3>

        <p className={styles.text}>
          本規約の準拠法は日本法とし、本規約に起因し又は関連する一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
        </p>
        <h3 className={styles.header}>第27条 協議解決</h3>

        <p className={styles.text}>
          当社及びメンバーは、本規約に定めのない事項又は本規約の解釈に疑義が生じた場合には、互いに信義誠実の原則に従って協議の上速やかに解決を図るものとします。
        </p>
        <br />
        <p className={styles.text}>2020年09月24日　制定</p>
      </div>
    </main>
  );
};

export default TermsOfServiceEn;
