import firebaseApp from 'firebase';
import {history, store as reduxStore} from '../store';
import store from 'store';
import {
  requestCustomToken,
  setLoggedIn,
  requestNotificationListeners
} from '../actions/firebase';

export const firebaseSignIn = data => {
  firebaseApp
    .auth()
    .signInWithCustomToken(data.token)
    .catch(function (error) {
      // Handle Errors here.
      let errorCode = error.code;
      let errorMessage = error.message;
      console.log(errorCode);
      console.log(errorMessage);
      history.push('/signin');
      // ...
    });
};

export const firebaseSignOut = data => {
  return firebaseApp
    .auth()
    .signOut()
    .then(function () {
      reduxStore.dispatch(setLoggedIn(false));
      return ['success', null];
    })
    .catch(function (error) {
      // An error happened.
      let errorCode = error.code;
      let errorMessage = error.message;
      console.log(errorMessage);
      console.log(errorCode);
      return [null, 'failed'];
    });
};

export const getFirebaseLoginStatus = () => {
  return new Promise((resolve, reject) => {
    try {
      firebaseApp.auth().onAuthStateChanged(res => {
        if (res !== null) {
          store.set('isLoggedIn', true);
          reduxStore.dispatch(setLoggedIn(true));
          reduxStore.dispatch(requestNotificationListeners());
          resolve('ログインできています');
        } else {
          store.set('isLoggedIn', false);
          reduxStore.dispatch(setLoggedIn(false));
          resolve('ログインできていません');
        }
      });
    } catch (e) {
      reject('ログインチェック中にエラーが発生しました。');
    }
  });
};

export const firebaseGetAdditionalClaims = () => {
  return getAdditionalClaims()
    .then(result => {
      if (result !== null) {
        return [result, null];
      }
      return [null, 'claims が取得できませんでした'];
    })
    .catch(err => {
      return [null, err];
    });
};

// onAuthStateChanged が無いと user の取得漏れが起きる）
const getAdditionalClaims = () =>
  new Promise((resolve, reject) =>
    firebaseApp.auth().onAuthStateChanged(res => {
      if (res !== null) {
        store.set('isLoggedIn', true);
        reduxStore.dispatch(setLoggedIn(true));
        reduxStore.dispatch(requestNotificationListeners());
        resolve(getClaims(res));
      } else {
        store.set('isLoggedIn', false);
        reduxStore.dispatch(setLoggedIn(false));
      }
    })
  );

const getClaims = user => {
  return user
    .getIdTokenResult()
    .then(idTokenResult => {
      if (idTokenResult.claims) {
        return idTokenResult.claims;
      } else {
        return null;
      }
    })
    .catch(error => {
      return error;
    });
};

export const setNotificationListeners = user => {
  return user
    .getIdTokenResult()
    .then(idTokenResult => {
      if (idTokenResult.claims) {
        return idTokenResult.claims;
      } else {
        return null;
      }
    })
    .catch(error => {
      return error;
    });
};

export const getLastSnapshot = async query => {
  const querySnapshot = await query.get();
  return [querySnapshot, querySnapshot.docs[querySnapshot.docs.length - 1]];
};

export const formatQuerySnapshot = querySnapshot => {
  return querySnapshot.docs.map(doc => doc.data());
};
