import {
  SUCCESS_PERSONAL_HEAD_NEWS_LIST,
  SET_PERSONAL_NEWS_LAST_SNAPSHOT,
  SUCCESS_PERSONAL_NEWS_LIST,
  SET_IS_READ_ALL_PERSONAL_NOTIFICATION
} from '../actions/personalNews';
import {MAX_GET_DOCS_COUNT} from '../common';

const initialState = {
  headPersonalDocument: [],
  personalDocument: [],
  hasMorePersonalDocument: true,
  personalNewsLastSnapshot: null,
  isReadAllPersonalNotification: null
};

const personalNews = (state = initialState, action) => {
  switch (action.type) {
    case SUCCESS_PERSONAL_HEAD_NEWS_LIST:
      return {
        ...state,
        headPersonalDocument: action.payload,
        hasMorePersonalDocument: action.payload.length === MAX_GET_DOCS_COUNT
      };

    case SUCCESS_PERSONAL_NEWS_LIST:
      return {
        ...state,
        personalDocument: [...state.personalDocument, ...action.payload],
        hasMorePersonalDocument: action.payload.length === MAX_GET_DOCS_COUNT
      };
    case SET_PERSONAL_NEWS_LAST_SNAPSHOT:
      return {
        ...state,
        personalNewsLastSnapshot: action.payload
      };
    case SET_IS_READ_ALL_PERSONAL_NOTIFICATION:
      return {
        ...state,
        isReadAllPersonalNotification: action.payload
      };
    default:
      return state;
  }
};

export default personalNews;
