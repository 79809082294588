import React from 'react';

import MenuAppBar from '../MenuAppBar';
import PageSubTitle from '../PageSubTitle';
import Breadcrumbs from '../Breadcrumbs';
import FooterPage from '../Footer/FooterPage';
import useScrollToTop from '../../hooks/useScrollToTop';

import styles from './policy.module.scss';

const Policy = () => {
  useScrollToTop();
  return (
    <>
      <MenuAppBar />
      <div className={styles.pageTitleContainer}>
        <PageSubTitle title="プライバシーポリシー" />
      </div>
      <main className={styles.main}>
        <div className={styles.inner}>
          <h1 className={styles.title}>プライバシーポリシー</h1>
          <h3 className={styles.header}>第1条 総則</h3>
          <p className={styles.text}>
            株式会社
            m‑Lab（以下「当社」といいます。）は、個人情報保護を企業における重要な社会的使命・責務と認識し、本サービスの利用する全ての方（以下「利用者」といいます）のプライバシーを尊重し、利用者の個人情報を適切に管理運用するために遵守するべき基本事項として本ポリシーを定めます。
          </p>
          <h3 className={styles.header}>第2条 個人情報の定義</h3>
          <p className={styles.text}>
            個人情報とは、ユーザー個人に関する情報であって、当該情報を構成する氏名、住所、電話番号、メールアドレスその他の記述等により当該ユーザーを識別できるものをいいます。
            また、その情報のみでは識別できない場合でも、他の情報と容易に照合することができ、結果的にユーザー個人を識別できるものも個人情報に含まれます。
          </p>
          <h3 className={styles.header}>第3条 個人情報の取得</h3>
          <p className={styles.text}>
            1.当社は、適法かつ公正な手段によって、当社のサービスで取り扱う個人情報（以下個人情報といいます）を取得します。
            <br />
            2.インターネット経由で取得した個人情報はSSLにて暗号化され、サーバに送信されます。
            <br />
            3.要配慮個人情報については、当社の業務の遂行上必要な範囲外のものを取得しません。また、取得する場合にはご本人の同意をいただいたうえで取得します。
            <br />
            4.当社が取得した個人情報が不足している場合には、当社のサービスの全部または一部を提供できない場合があります。
          </p>
          <h3 className={styles.header}>第4条 個人情報の利用目的</h3>
          <p className={styles.text}>
            当社は、利用者からご提供いただいた情報(以下「個人情報等」といいます。)を、以下に掲げる目的で使用いたします。
            <br />
            (1)当社のサービスに関する登録の受付及び本人確認のため
            <br />
            (2)当社のサービス提供、維持、保護及び改善のため
            <br />
            (3)当社のサービスに関するお知らせや関連サービスのご案内のため
            <br />
            (4)当社のマーケティング調査のため
            <br />
            (5)当社の利用規約やポリシー等に違反する行為に対応するため
            <br />
            (6)お問い合わせ、その他ご依頼等への対応のため
          </p>
          <h3 className={styles.header}>第5条 個人情報の第三者提供について</h3>
          <p className={styles.text}>
            当社で保管した個人情報等については、以下のいずれかに該当する場合を除き、第三者へ提供、取扱いの委託をすることはありません。
            <br />
            (1)当社が前条第1項ないし第5項に定める利用目的の達成に必要な範囲内において個人情報等の取扱いを委託する場合
            <br />
            (2)合併その他の事由による事業の承継に伴って個人情報等が提供される場合
            <br />
            (3)国の機関もしくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、利用者の同意を得ることによって当該事務の遂行に支障を及ぼすおそれがある場合
            <br />
            (4)その他、個人情報保護法その他の法令で認められる場合
          </p>
          <h3 className={styles.header}>第6条 外部委託について</h3>
          <p className={styles.text}>
            当社は、情報処理などの業務を外部に委託する際に、個人情報を必要最小限の範囲で委託する場合があります。その場合には、委託先が個人情報保護体制等において当社と同等以上の水準を確保していることを条件とし、当社は委託先に対し秘密を保持させるために、適正な監督を行います。
          </p>
          <h3 className={styles.header}>第7条 個人情報の管理</h3>
          <p className={styles.text}>
            1.当社は、個人情報の正確性を保ち、これを安全に管理します。
            <br />
            2.当社は、個人情報の紛失、破壊、改ざん及び漏えいなどを防止するため、不正アクセス、コンピュータウィルス等に対する適正な情報セキュリティ対策を講じます。
            <br />
            3.当社は、個人情報を持ち出し、本ポリシー及び利用規約に定めのない目的で外部へ送信する等により漏えいさせません。
            <br />
            4.当社は、その従業者に個人データを取り扱わせるに当たっては、個人情報の安全管理が図られるよう、当該従業者に対する必要かつ適切な監督を行います。
          </p>
          <h3 className={styles.header}>第8条 個人情報の開示</h3>
          <p className={styles.text}>
            1.当社は、利用者から、個人情報保護法の定めに基づき個人情報等の開示を求められたときは、利用者ご本人からのご請求であることを確認の上で、利用者に対し、遅滞なく開示を行います(当該個人情報等が存在しないときにはその旨を通知いたします。)。但し、個人情報保護法その他の法令により、当社が開示の義務を負わない場合は、この限りではありません
            <br />
            2.当社の個人情報の取扱いにつきましてご意見、ご質問等のお問い合せにつきましては、末尾に記載しているメールアドレスにご連絡ください。
          </p>
          <h3 className={styles.header}>第9条 個人情報の訂正及び停止等</h3>
          <p className={styles.text}>
            1.当社は、利用者から、1個人情報等が真実でないという理由によって個人情報保護法の定めに基づきその内容の訂正を求められた場合、及び2あらかじめ公表された利用目的の範囲を超えて取り扱われているという理由又は偽りその他不正の手段により収集されたものであるという理由により、個人情報保護法の定めに基づきその利用の停止を求められた場合には、利用者ご本人からのご請求であることを確認の上で、遅滞なく必要な調査を行い、その結果に基づき、個人情報等の内容の訂正または利用停止を行い、その旨を利用者に通知します。なお、合理的な理由に基づいて訂正または利用停止を行わない旨の決定をしたときは、利用者に対しその旨を通知いたします。
            <br />
            2.当社は、利用者から、利用者の個人情報等について消去を求められた場合、当社が当該請求に応じる必要があると判断した場合は、利用者ご本人からのご請求であることを確認
            の上で、個人情報等の消去を行います。なお、合理的な理由に基づいて消去を行わない旨の決定をしたときは、利用者に対しその旨を通知いたします。
            <br />
            3.個人情報保護法その他の法令により、当社が訂正等または利用停止等の義務を負わない場合は、前2項の規定は適用されません。
          </p>
          <h3 className={styles.header}>
            第10条　Cookie及びlocalStorageについて
          </h3>
          <p className={styles.text}>
            当社のサービスでは、利用者の利便性の向上、統計データの取得等のため、Cookie及びlocalStorage
            を使用します。また、CookieやlocalStorage、JavaScript等の技術を利用して、ご提供い
            ただいた情報のうち、個人が特定できない属性情報（組み合わせることによっても個人が
            特定できないものに限られます）や端末情報、利用者の行動履歴を取得することがありま
            す。ただし、Cookie及びlocalStorage、行動履歴等には個人情報は一切含まれません。ブ
            ラウザの設定により、Cookie及びlocalStorageはブラウザの設定により無効にすることが
            できますが、その結果、本サービスの全部または一部の機能がご利用いただけなくなるこ
            とがあります。
          </p>
          <h3 className={styles.header}>第11条 Google Analyticsについて</h3>
          <p className={styles.text}>
            当社のウェブサービスでは、Googleによるアクセス解析ツール「Google
            Analytics (Googleアナリティクス)」を使用しています。このGoogle
            Analyticsはデータの収集のためにCookieを使用しています。このデータは匿名で収集されており、個人を特定するものではありません。
            この機能はCookieを無効にすることで収集を拒否することが出来ますので、お使いのブラウザの設定をご確認ください。この規約に関しての詳細はのページやページをご覧ください。
          </p>
          <h3 className={styles.header}>第12条 統計処理データの利用</h3>
          <p className={styles.text}>
            当社は、提供を受けた個人情報をもとに、個人を特定できないよう加工した統計データを作成することがあります。個人を特定できない統計データについては、当社は何ら制限なく利用することができるものとします。
          </p>
          <h3 className={styles.header}>第13条 免責</h3>
          <p className={styles.text}>
            以下の場合において、当社は個人情報の取扱いに関しての責任を一切負いません。
            <br />
            (1)利用者本人が、自らの意思もしくは自らの過失により、当社の提供するウェブサービスの使用又はその他別の手段を用いて第三者に個人情報を明らかにした場合
            <br />
            (2)当社のサービスからリンクされる当社が管理していない外部のウェブサイト等において、利用者が個人情報を提供した場合、又は意図せず個人情報を抜き取られてしまった場合
            <br />
            (3)通常講ずるべき対策を講じた上では防止できないマルウェア、天変地異、その他当社の責によらない事由により個人情報が漏洩、損傷又は滅失した場合
          </p>
          <h3 className={styles.header}>第14条 本ポリシーの変更について</h3>
          <p className={styles.text}>
            当社は、保有する個人情報に関して適用される日本の法令、その他規範を遵守するとともに、個人情報等の取扱いに関する運用状況を適宜見直し、継続的な改善に努めるものとし、
            必要に応じて、本ポリシーを変更することがあります。
            <br />
            変更した場合には、当社のウェブサイトに掲載する方法で利用者に通知いたします。但し、法令上利用者の同意が必要となるような内容の変更の場合は、当社所定の方法で利用者の同意を得るものとします。
            <br />
            本規約の変更時には、当社のウェブサイト上に変更の旨を表示し、変更点が有効となる日付を明示するものとします。
            <br />
            <br />
            個人情報管理責任者
            <br />
            株式会社 m‑Lab 個人情報保護管理責任者
            <br />
            <br />
            ご意見、ご質問等のお申出、その他個人情報等の取扱いに関するお問い合わせは、
            以下のメールアドレスまでご連絡をお願い致します。 <br />
            info@task.site
            <br />
            <br />
            2020年7月1日 制定
          </p>
        </div>
      </main>
      <FooterPage />
    </>
  );
};

export default Policy;
