import React from 'react';
import style from './label.module.scss';

const Index = ({text, isActive, status}) => {
  const stylingObject = {
    1: style.draft,
    2: style.judge,
    3: style.hiring,
    4: style.progress,
    5: style.evaluation,
    6: style.request,
    7: style.confirmed,
    8: style.finished,
    9: style.withdraw,
    10: style.paymentFailed
  };

  return (
    <div className={isActive ? stylingObject[status] : style.nonactive}>
      <div className={style.labelText}> {text} </div>
    </div>
  );
};

export default Index;
