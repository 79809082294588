import {
  SUCCESS_RECEIVED_REWARD_ADMIN,
  SUCCESS_SCHEDULED_REWARD_ADMIN,
  SUCCESS_PAID_REWARD_TASKS
} from '../actions/reward';

const initialState = {
  receivedReward: {results: [], offset: 0},
  scheduledReward: {results: [], offset: 0},
  paidRewardTasks: {results: []}
};

function rewardAdmin(state = initialState, action) {
  switch (action.type) {
    case SUCCESS_RECEIVED_REWARD_ADMIN:
      return {
        ...state,
        receivedReward: action.payload
      };
    case SUCCESS_SCHEDULED_REWARD_ADMIN:
      return {
        ...state,
        scheduledReward: action.payload
      };
    case SUCCESS_PAID_REWARD_TASKS:
      return {
        ...state,
        paidRewardTasks: action.payload
      };
    default:
      return state;
  }
}

export default rewardAdmin;
