import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import {
  requestProjectDetail,
  requestProjectDelete,
  setProjectDetail
} from '../../../actions/project';
import {requestProjectTasks} from '../../../actions/task';

import style from './project.module.scss';
import ProjectInfo from '../../../components/Project/Info';

import CoTaskList from '../../../components/Task/CoTaskList';
import EnTaskList from '../../../components/Task/EnTaskList';
import Title from '../../../components/Title';
import MainTitle from '../../../components/MainTitle';
import AddNewButton from '../../../components/Button/AddNewButton';
import NotAvailable from '../../../components/NotAvailable';
import ProjectMessage from '../../../components/ProjectMessage';
import CommonTabs from '../../../components/CommonTabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViewsWrapper from '../../../components/SwipeableViewsWrapper';
import Blank from '../../../components/Blank';

// TO DO
// エンジニアと企業側共通で使用することになったので、ディレクトリと命名の変更する!
// 共通使用するのはProjectディレクトリの中の↓のコンポーネント(プロジェクト詳細)だけ。
const Detail = () => {
  const dispatch = useDispatch();
  const {id} = useParams();
  const {detail} = useSelector(state => state.project);
  const {projectTasks} = useSelector(state => state.task);
  const {isEngineer} = useSelector(state => state.setting);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    dispatch(requestProjectDetail(id));
    dispatch(requestProjectTasks(id));
  }, []);

  const handleChange = (event, value) => {
    setIndex(value);
  };

  const handleSwipeableChange = value => {
    setIndex(value);
  };

  return (
    <div className={style.rootContainer}>
      <MainTitle titleStr={'プロジェクト詳細'} pattern={false} />
      <CommonTabs onChange={handleChange} index={index}>
        <Tab label="タスク一覧" />
        <Tab label="プロジェクトメッセージ" />
      </CommonTabs>
      <Blank height={'4rem'} />
      <SwipeableViewsWrapper
        index={index}
        onChangeIndex={handleSwipeableChange}
        animateHeight={true}
      >
        <div>
          <div className={style.header}>
            <Title component="h2" text="タスク一覧" />
            <div className={style.button}>
              {isEngineer ? (
                <></>
              ) : (
                <AddNewButton
                  text="新規タスク"
                  path={`/co/project/${id}/task/create`}
                />
              )}
            </div>
          </div>
          {!isEngineer && projectTasks.length === 0 && (
            <NotAvailable kind="task-create" />
          )}
          <div className={style.taskContainer}>
            {isEngineer ? (
              <EnTaskList contents={projectTasks} />
            ) : (
              <CoTaskList task={projectTasks} />
            )}
          </div>
        </div>
        <ProjectMessage detail={detail} id={id} />
      </SwipeableViewsWrapper>
      <Blank height={'4rem'} />
      <ProjectInfo detail={detail} projectId={id} isEngineer={isEngineer} />
    </div>
  );
};

export default Detail;
