import React from 'react';
import {useSelector} from 'react-redux';

import UserInformation from '../../../components/UserInformation/Profile';
import Skill from '../../../components/UserInformation/Skill';
import Level from '../../../components/UserInformation/Level';
import Others from '../../../components/UserInformation/Others';

const User = () => {
  const me = useSelector(state => state.setting.me);

  return (
    <>
      <UserInformation dict={me} />
      <Skill me={me} />
      <Others />
      <Level />
    </>
  );
};

export default User;
