import React from 'react';
import style from './entryDetail.module.scss';
import commonStyle from '../../common/common.module.scss';
import Button from './Button';
import Divider from '@material-ui/core/Divider';
import Avatar from '@material-ui/core/Avatar';
import faker from 'faker';
import classNames from 'classnames';
import StarRating from '../StarRating';
import format from 'date-fns/format';
import BookmarkButton from '../Buttons/BookmarkButton';
import EntryButton from '../Buttons/EntryButton';
import {withStyles} from '@material-ui/core/styles/index';
import {requestMyEntryList} from '../../actions/entry';
import {requestBookmarkList} from '../../actions/bookmark';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import jaLocale from 'date-fns/locale/ja';
import {TaskDetailButtons} from '../Buttons/TaskDetailbuttons/index.js';
import store from 'store';
import {AvatarImg, userType, NumberWithDelimiter} from '../../common';

const styles = {
  root: {
    margin: '1.5rem 1rem 1rem'
  },
  minimumAvatar: {
    marginRight: '1rem'
  }
};

class Index extends React.Component {
  componentDidMount() {
    id = this.props.match.params.id;
    const isEngineer = this.props.isEngineer;
  }

  render() {
    const {
      entryList,
      detail,
      status,
      entries,
      effectIdList,
      bookmarkList,
      classes,
      isEngineer,
      isAnonymousUser,
      isMyCompanyTask
    } = this.props;

    const transitionPage = (id, user) => {
      this.props.history.push(`/${userType(isEngineer)}/${user}-user/${id}`);
    };

    const numberOfApplicants = () => {
      return isEngineer ? detail.entries + '人' : detail.entries.length + '人';
    };

    return (
      <div className={style.container}>
        <div className={style.content}>
          <div className={style.locateCenter}>
            <TaskDetailButtons
              id={id}
              isEngineer={isEngineer}
              isAnonymousUser={isAnonymousUser}
              isMyCompanyTask={isMyCompanyTask}
            />
          </div>
          <Divider className={classes.root} />
          <div className={style.item}>
            <div className={style.itemContainer}>
              <p className={style.itemTitle}>応募期限</p>
              <p className={style.limit}>
                {detail.closing_date
                  ? format(
                      new Date(detail.closing_date),
                      'yyyy年MM月dd日 HH:MM'
                    )
                  : ''}
              </p>
            </div>
          </div>
          <Divider className={classes.root} />
          <div className={style.item}>
            <div className={style.itemContainer}>
              <p className={style.itemTitle}>応募人数</p>
              <p className={style.general}>
                {detail.entries !== null ? numberOfApplicants() : ''}
              </p>
            </div>
          </div>
          <Divider className={classes.root} />
          {!isAnonymousUser && (
            <>
              <div className={style.activeItem}>
                <div className={style.itemContainer}>
                  <p className={style.itemTitle}>企業管理者</p>
                  <div
                    className={style.staffInfoContainer}
                    onClick={() => {
                      transitionPage(detail.owner.id, userType(false));
                    }}
                  >
                    <div className={classes.minimumAvatar}>
                      <AvatarImg img={detail.owner.img} size="minimum" />
                    </div>

                    <div className={style.staffInfoItems}>
                      <p className={style.staffName}>
                        {detail.owner ? detail.owner.nick_name : ''}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <Divider className={classes.root} />
            </>
          )}
          <div>
            {status > 3 && detail.worker && !isAnonymousUser ? (
              <div>
                <div className={style.activeItem}>
                  <div className={style.itemContainer}>
                    <p className={style.itemTitle}>担当エンジニア</p>
                    <div
                      className={style.staffInfoContainer}
                      onClick={() => {
                        transitionPage(detail.worker_info.id, userType(true));
                      }}
                    >
                      <div className={classes.minimumAvatar}>
                        <AvatarImg
                          img={detail.worker_info.img}
                          size="minimum"
                        />
                      </div>

                      <div className={style.staffInfoItems}>
                        <p className={style.staffName}>
                          {detail.worker ? detail.worker_info.nick_name : ''}
                        </p>
                        <p className={style.staffLevel}>
                          {detail.worker ? detail.worker_info.name : ''}
                        </p>
                        <div className={style.staffReview}>
                          <div className={style.reviewTitle}>総合評価</div>
                          <StarRating
                            evalValue={detail.worker_info.summary_rating * 20}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Divider className={classes.root} />
              </div>
            ) : null}
          </div>
          <div className={classNames(style.item, style.amount)}>
            <div className={style.rewardItemContainer}>
              <p className={commonStyle.itemTitleAmount}>報酬金額</p>
              <p className={style.amountContent}>
                {detail.reward ? NumberWithDelimiter(detail.reward) + '円' : ''}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let id = '';

const mapDispatchToProps = dispatch => ({
  requestMyEntryList() {
    dispatch(requestMyEntryList());
  },
  requestBookmarkList(bookmarkList) {
    dispatch(requestBookmarkList(bookmarkList));
  }
});

const mapStateToProps = state => {
  return {
    entryList: state.entry.entryList,
    entries: state.task.detail.entries,
    status: state.task.detail.status,
    bookmarkList: state.bookmark.bookmarkList,
    effectIdList: state.sagaMonitor.list
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Index))
);
