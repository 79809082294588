import React, {FC} from 'react';
import {useHistory} from 'react-router-dom';
import clsx from 'clsx';
import taskListStyles from '../taskList.module.scss';
import styles from './assignedTask.module.scss';
import Label from '../../../Label';
import Text from '../../../Text';
import formatDate from '../../../FormatDate/index';
import {AvatarImg, makeTagsText, userType} from '../../../../common';
import Blank from '../../../Blank';
import MoveToMessageButton from '../../../MoveToMessageButton';
import CommonButton from '../../../CommonButton';
import {PreAssignTaskItemState} from '../../../../models/TaskModels';
import TaskItemDetail from '../../TaskItemDetail';
import Reward from '../../Reward';
import Deadline from '../../../Deadline';
import TaskItemContainer from '../../../TaskItemContainer';
// material ui
import {Divider} from '@material-ui/core';

export type Props = {
  item: PreAssignTaskItemState;
  index: number;
  isEngineer: boolean;
  isListItem?: boolean;
};

const AssignedTask: FC<Props> = props => {
  const {item, index, isEngineer, isListItem} = props;

  const history = useHistory();

  const {
    id,
    status_info,
    status,
    created_at,
    title,
    project_title,
    closing_date,
    project,
    reward,
    entries,
    tags_info,
    company,
    pre_worker_info
  } = item;

  let tagsText = tags_info.length > 0 ? makeTagsText(tags_info) : null;

  const isExpired = Date.now() > Date.parse(closing_date);

  const handleOnclick = () => {
    history.push(`/${userType(isEngineer)}/project/${project}/task/${id}`);
  };

  return (
    <TaskItemContainer
      key={index}
      index={index}
      onClick={handleOnclick}
      disabled={isExpired}
      useOddStyle={false}
      isListItem={isListItem}
    >
      <div className={styles.rootContainer}>
        <div className={clsx(styles.container)}>
          <div className={taskListStyles.title}>
            <div className={taskListStyles.flex}>
              <div className={taskListStyles.tag}>
                {isExpired ? (
                  <Label text={'応募終了'} status={status} isActive={false} />
                ) : (
                  <Label text={status_info} status={status} isActive={true} />
                )}
              </div>
              <Text
                children={`作成日 ${formatDate(
                  new Date(created_at),
                  'date',
                  'str'
                )}`}
                component="text01"
              />
            </div>
            <Text
              className={taskListStyles.body}
              children={title}
              component="text02"
            />
            <div className={taskListStyles.companyName}>
              <Text
                children={company ? company : 'loading'}
                component="text01"
              />
            </div>
          </div>
          <div className={taskListStyles.taskItemDetail}>
            <TaskItemDetail project_title={project_title} tagsText={tagsText} />
            <Reward reward={reward} />
            {isExpired ? (
              <>
                <div className={taskListStyles.deadlineContainer}>
                  <div className={taskListStyles.expired}>応募終了</div>
                </div>
              </>
            ) : (
              <>
                <div className={taskListStyles.deadlineContainer}>
                  <div className={taskListStyles.deadline}>応募締切</div>
                  <Deadline deadline={closing_date} />
                  <div className={taskListStyles.apply}>
                    <div>
                      応募者数
                      <span className={taskListStyles.num}>
                        {entries !== null ? entries : '-'}
                      </span>
                      人
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <Divider variant="middle" />
        <div className={styles.engineerContainer}>
          <p className={styles.blueText}>エンジニア</p>
          <div className={styles.engineerItem}>
            <div className={styles.avatarContainer}>
              <AvatarImg img={pre_worker_info.image} size="minimum" />
              <Blank width={'1.6rem'} height={null} />
              <p className={styles.blueText}>{pre_worker_info.nick_name}</p>
            </div>
            <div className={styles.buttons}>
              <MoveToMessageButton
                userId={pre_worker_info.id}
                size={'preMinimum'}
              />
              <Blank height={null} width={'1rem'} />
              <CommonButton
                icon={null}
                text={'プロフィール'}
                size={'preMinimum'}
                color={'blue'}
                variant={'contained'}
                shape={'normal'}
                onClick={e => {
                  history.push(`/co/en-user/${pre_worker_info.id}`);
                  e.stopPropagation();
                }}
                disabled={false}
              />
            </div>
          </div>
        </div>
      </div>
    </TaskItemContainer>
  );
};

export default AssignedTask;
