import {
  SET_MENU_OPEN,
  SET_SNS_MENU_OPEN,
  SET_MEDIA_MENU_OPEN,
  SET_SETTING_MENU_OPEN
} from '../actions/menu';

const initialState = {
  isMenuOpen: false,
  isSnsMenuOpen: true,
  isMediaMenuOpen: true,
  isSettingMenuOpen: true,
};

function menu(state = initialState, action) {
  switch (action.type) {
    case SET_MENU_OPEN:
      return {
        ...state,
        isMenuOpen: !state.isMenuOpen,
      }
    case SET_SNS_MENU_OPEN:
      return {
        ...state,
        isSnsMenuOpen: !state.isSnsMenuOpen,
      }
    case SET_MEDIA_MENU_OPEN:
      return {
        ...state,
        isMediaMenuOpen: !state.isMediaMenuOpen,
      }
    case SET_SETTING_MENU_OPEN:
      return {
        ...state,
        isSettingMenuOpen: !state.isSettingMenuOpen,
      }
    default:
      return state
  }
}

export default menu;
