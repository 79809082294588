import React, {useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {requestRemoveMember, setSecondModalFlag} from '../../../actions/member';

import styles from './removeMember.module.scss';
import IconMember from '../../../assets/icon_member.svg';
import IconEmail from '../../../assets/icon_email_black.svg';
import classNames from 'classnames';
import SubmitButton from '../../Button/SubmitButton';
import formatDate from '../../FormatDate';

import Email from '../../../assets/icon_email_into_circle.svg';
const InviteMember = ({disable}) => {
  const dispatch = useDispatch();
  const removeCandidate = useSelector(state => {
    return state.member.removeCandidateMember;
  });

  const setBackgroundColor = name => {
    if (name === '') {
      return styles.backgroundMail;
    } else {
      return styles.backgroundName;
    }
  };

  const text = disable
    ? 'メンバーを０名にする事はできません'
    : '本当にこのメンバーを削除しますか?';

  return (
    <div className={styles.inner}>
      <div className={styles.header}>
        <img src={IconMember} />
        <div className={styles.subTitle}>{text}</div>
      </div>
      {disable ? null : (
        <div
          className={classNames(
            styles.container,
            setBackgroundColor(removeCandidate.name)
          )}
        >
          <div className={styles.contentItem}>
            {/*TODO: backgroundColorはimageが入るまでの仮*/}
            <div className={styles.profileImage}>
              {removeCandidate.name == '' ? (
                <img src={Email} alt="mailアイコン" />
              ) : (
                <img src="" />
              )}
            </div>
            <p className={styles.name}>
              {removeCandidate.name == ''
                ? removeCandidate.email
                : removeCandidate.name}
            </p>
          </div>
          <div
            className={classNames(styles.contentItem, styles.contentItemRight)}
          >
            {removeCandidate.name == '' ? (
              <div />
            ) : (
              <p className={styles.date}>
                参加日時{' '}
                {formatDate(removeCandidate.date_joined, 'date', 'str')}
              </p>
            )}
          </div>
        </div>
      )}

      <div className={styles.button}>
        {disable ? (
          <SubmitButton
            text="閉じる"
            onClick={() => dispatch(setSecondModalFlag(false))}
          />
        ) : (
          <SubmitButton
            text="メンバー削除"
            onClick={() => dispatch(requestRemoveMember(removeCandidate.id))}
          />
        )}
      </div>
    </div>
  );
};

export default InviteMember;
