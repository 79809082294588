import React from 'react';
import SearchTaskIcon from '../../../assets/icon_task_search.svg';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import styles from './outlineButton.module.scss';
import {useHistory} from 'react-router-dom';
import classNames from 'classnames';
import {COLOR_PALLET} from '../../../constants';

const useStyles = makeStyles({
  baseButton: {
    fontSize: '1.5rem',
    lineHeight: '2rem',
    textAlign: 'center',
    height: '4rem'
  },
  button: {
    fontSize: '1.5rem',
    lineHeight: '2rem',
    textAlign: 'center',
    width: '15rem',
    height: '4rem'
  },
  button2: {
    fontSize: '1.5rem',
    lineHeight: '2rem',
    textAlign: 'center',
    width: '20rem',
    height: '4rem'
  },
  limitButton: {
    fontSize: '1.5rem',
    lineHeight: '2rem',
    textAlign: 'center',
    height: '4rem'
  },
  limitButtonWidth: {
    width: '18rem'
  },
  applicationButton: {
    fontSize: '1.5rem',
    lineHeight: '2rem',
    textAlign: 'center',
    height: '6.8rem',
    width: '40rem'
  },
  colorBlue: {
    backgroundColor: COLOR_PALLET.blue900
  }
});

const displayContent = (
  kind,
  classes,
  history,
  link = null,
  onClickHandler,
  disabled = false
) => {
  switch (kind) {
    case 'checkCredit':
      return (
        <>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => window.open(link, '_blank')}
          >
            <span className={styles.text}>確認・編集</span>
          </Button>
        </>
      );
    case 'initializePaid':
      return (
        <>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => onClickHandler()}
          >
            <span className={styles.text}>Paid利用申請</span>
          </Button>
        </>
      );
    case 'applicationPaid':
      return (
        <>
          <Button
            variant="contained"
            color="secondary"
            type={'submit'}
            className={classes.applicationButton}
            disabled={disabled}
          >
            <span className={styles.text}>申請する</span>
          </Button>
        </>
      );
    case 'secureCreditLimit':
      return (
        <>
          <Button
            variant="contained"
            color="primary"
            className={classNames(classes.baseButton, classes.limitButtonWidth)}
            onClick={() => onClickHandler()}
          >
            <span className={styles.text}>決済画面を開く</span>
          </Button>
        </>
      );
    case 'moveToPaymentSetting':
      return (
        <>
          <Button
            variant="contained"
            color="primary"
            className={classNames(classes.colorBlue)}
            onClick={onClickHandler}
          >
            <span className={styles.text}>お支払い方法の設定</span>
          </Button>
        </>
      );
  }
};

const RoundedButton = ({kind, link, onClickHandler, disabled = false}) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <>
      {displayContent(kind, classes, history, link, onClickHandler, disabled)}
    </>
  );
};

export default RoundedButton;
