import React, {Component} from 'react';
import {connect} from 'react-redux';
import styles from './errorBoundary.module.scss';
import {NavLink} from 'react-router-dom';

class NotFound extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <section className={styles.background}>
          <div className={styles.container}>
            <h3 className={styles.notFound}>
              <div className={styles.notFoundMessage}>エラーが発生しました</div>
            </h3>
            <h2 className={styles.header}>
              しばらくしてから <br />
              再度ログインしてください
            </h2>
            <div className={styles.buttons}>
              <NavLink exact to="/signin" className={styles.link}>
                ログイン画面に戻る
              </NavLink>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = state => state;

export default connect(mapStateToProps)(NotFound);
