import {
  SUCCESS_NOTIFICATION_LIST,
  SUCCESS_IMPORTANT_NOTIFICATIONS,
  SUCCESS_DELETE_IMPORTANT_NOTIFICATION
} from '../actions/notification';

// Example:
// importantNotifications: [
//   {notice: '個人情報が未登録です。お仕事を受注するには個人情報の登録を行いましょう。', link: '/co/task/1'},
//   {notice: '支払い先のカードが未設定です、登録をお願い致します。', link: '/co/task/1'}
// ]

const initialState = {
  list: [],
  importantNotifications: []
};

function notification(state = initialState, action) {
  switch (action.type) {
    case SUCCESS_NOTIFICATION_LIST:
      return {
        ...state,
        list: action.payload.results
      };
    case SUCCESS_IMPORTANT_NOTIFICATIONS:
      return {
        ...state,
        importantNotifications: [
          ...state.importantNotifications,
          ...action.payload
        ]
      };
    case SUCCESS_DELETE_IMPORTANT_NOTIFICATION:
      return {
        ...state,
        importantNotifications: action.payload
      };
    default:
      return state;
  }
}

export default notification;
