import React from 'react';
import {useHistory} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles({
  button: {
    color: '#FFF',
    fontSize: '1.6rem',
    border: '0.1rem solid #FFF',
    borderRadius: '0.4rem',
    fontWeight: 'bold',
    width: '100%',
    height: '5rem'
  }
});

const JumpPage = ({path, text}) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Button
      onClick={() => history.push(`${path}`)}
      className={classes.button}
      variant="outlined"
    >
      {text}
    </Button>
  );
};

export default JumpPage;
