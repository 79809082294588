import firebase from 'firebase';
import {requestHandleOnSnapshot} from '../actions/systemNews';
import {store} from '../store';
import {FIRESTORE_BATCH_LIMIT_NUMBER, MAX_GET_DOCS_COUNT} from '../common';
import {getLastSnapshot, formatQuerySnapshot} from './common';

export const ListenSystemNotification = async () => {
  try {
    const query = getBaseQuery();
    const [_, lastSnapshot] = await getLastSnapshot(query);
    const detachFunction = await query.onSnapshot(
      querySnapshot => {
        store.dispatch(requestHandleOnSnapshot(querySnapshot));
      },
      err => {
        console.error(`Encountered error: ${err}`);
      }
    );
    return [detachFunction, lastSnapshot, null];
  } catch (err) {
    return [null, null, err];
  }
};

export const fetchSystemNotification = async prevLastSnapShot => {
  try {
    const firebaseDb = firebase.firestore();
    const query = firebaseDb
      .collection('Notification')
      .orderBy('created_at', 'desc')
      .startAfter(prevLastSnapShot)
      .limit(MAX_GET_DOCS_COUNT);

    const [querySnapshot, lastSnapshot] = await getLastSnapshot(query);
    return [formatQuerySnapshot(querySnapshot), lastSnapshot, null];
  } catch (err) {
    return [null, null, err];
  }
};

export const readSystemNotification = async () => {
  try {
    const firebaseDb = firebase.firestore();
    // TODO: システム通知も既読・未読機能をつける
  } catch (err) {
    console.error(err);
    return [null, err];
  }
};

const getBaseQuery = () => {
  const firebaseDb = firebase.firestore();
  return firebaseDb
    .collection('Notification')
    .orderBy('created_at', 'desc')
    .limit(MAX_GET_DOCS_COUNT);
};
