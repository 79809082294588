import React from 'react';
import {Field, reduxForm} from 'redux-form';

import styles from './coLogin.module.scss';
import ICON_PEOPLE from '../../assets/icon_people.svg';
import ICON_KEY from '../../assets/icon_key.svg';

import {renderText, renderPassword} from '../Field';
import SmallButton from '../Button/SmallButton';
import * as Validator from '../Form/utils/validate';
import FormError from '../Form/FormError';
import Blank from '../Blank';

const CoLogin = ({handleSubmit, error, pristine, submitting, isMember}) => {
  return (
    <form onSubmit={handleSubmit}>
      <div className={styles.container}>
        {isMember ? <NameInput /> : null}
        <div className={styles.fieldTitle}>
          <img src={ICON_KEY} />
          <p className={styles.fieldTitleText}>パスワード</p>
        </div>
        <div className={styles.textFieldContainer}>
          <Field
            name="password"
            validate={[Validator.RequiredItem]}
            component={renderPassword}
          />
        </div>
        <div className={styles.fieldTitle}>
          <img src={ICON_KEY} />
          <p className={styles.fieldTitleText}>パスワード(確認用)</p>
        </div>
        <div className={styles.textFieldContainer}>
          <Field
            name="passwordConfirm"
            validate={[Validator.RequiredItem, Validator.RequiredSamePassword]}
            component={renderPassword}
          />
        </div>
        <Blank height={'2.4rem'} />
        <FormError error={error} />
        <div className={styles.submitButton}>
          <SmallButton
            pristine={pristine}
            submitting={submitting}
            text="Taskを始める"
          />
        </div>
      </div>
    </form>
  );
};

const NameInput = () => {
  return (
    <>
      <div className={styles.fieldTitle}>
        <img src={ICON_PEOPLE} />
        <p className={styles.fieldTitleText}>氏名</p>
      </div>
      <div className={styles.nameField}>
        <Field
          name="last_name_kanji"
          validate={[Validator.RequiredItem]}
          component={renderText}
          placeholder="姓"
        />
        <div style={{width: '1rem'}} />
        <Field
          name="first_name_kanji"
          validate={[Validator.RequiredItem]}
          component={renderText}
          placeholder="名"
        />
      </div>
      <div style={{height: '1rem'}} />
      <div className={styles.nameField}>
        <Field
          name="last_name_kana"
          validate={[Validator.RequiredItem]}
          component={renderText}
          placeholder="セイ"
        />
        <div style={{width: '1rem'}} />
        <Field
          name="first_name_kana"
          validate={[Validator.RequiredItem]}
          component={renderText}
          placeholder="メイ"
        />
      </div>
    </>
  );
};

export default reduxForm({
  form: 'invitedUser'
})(CoLogin);
