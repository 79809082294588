import {call, put, take, fork, select} from 'redux-saga/effects';
import {fetchGet, fetchPostJson} from './utills/fetch';
import {selectSetting, selectMessage} from '../sagas/selectors';
import {
  startSubmit,
  stopSubmit,
  reset,
  submit,
  SubmissionError
} from 'redux-form';
import {successMe} from '../actions/setting';
import {
  requestRoomList,
  successRoomList,
  setRoomCurrentPage,
  requestMessageList,
  setMessageCurrentPage,
  successMessageList,
  requestInitRooms,
  resetMessages,
  setSelectedRoomInfo,
  setOtherPersonId,
  setMessageText,
  requestPostMessage,
  requestPostNewMessage,
  requestCheckRoomStatus,
  successCheckRoomStatus
} from '../actions/message';
import {
  requestEnUserInfo,
  requestCoUserInfo,
  requestCoUsersInfo,
  requestEnUsersInfo
} from '../actions/user';
import {fetchRoom, fetchRooms, fetchMessages} from '../firebase/message';
import {history} from '../store';
import {makeFormErrorFormat} from '../common';

export function* checkRoomStatus() {
  while (true) {
    const action = yield take(requestCheckRoomStatus);
    const {meId, msgToId} = action.payload;
    const [isExists, roomList, err] = yield call(fetchRoom, meId, msgToId);
    if (!err) {
      yield put(successCheckRoomStatus(isExists));
    }
  }
}

// 自身の情報を元に、自分が所属している部屋一覧を取得する。
export function* getRooms() {
  while (true) {
    const action = yield take(requestRoomList);

    const settingState = yield select(selectSetting);
    const messageState = yield select(selectMessage);

    try {
      // me が存在しない時は、ここの処理に入らない様に修正した
      const myId = settingState.me.id;
      const [roomList, fetchRoomsError, hasMore] = yield call(
        fetchRooms,
        myId,
        messageState.roomsCurrent,
        action.payload.useRefresh
      );

      // 同じroomの相手ユーザ情報の取得(件名に使用するための)
      if (settingState.isEngineer) {
        yield put(requestCoUsersInfo({roomList: roomList}));
      } else {
        yield put(requestEnUsersInfo({roomList: roomList}));
      }

      // 取得できる部屋が一件も無いと落ちる
      if (roomList.length > 0) {
        yield put(setRoomCurrentPage(roomList[roomList.length - 1].doc));
      }

      yield put(
        successRoomList({
          roomList: roomList,
          hasMore: hasMore,
          useRefresh: action.payload.useRefresh
        })
      );
    } catch (e) {
      console.error(e);
    }
  }
}

export function* getMessages() {
  while (true) {
    const action = yield take(requestMessageList);
    const state = yield select(selectMessage);
    try {
      const [messageList, fetchMessagesError] = yield call(
        fetchMessages,
        state.selectedRoomId,
        state.messagesCurrent
      );
      if (messageList.length === 0) {
        // これ以上過去のメッセージが無いです
        continue;
      }

      if (messageList && !fetchMessagesError) {
        yield put(
          setMessageCurrentPage(messageList[messageList.length - 1].doc)
        );
        yield put(successMessageList(messageList));
      } else {
        console.log('error!');
      }
    } catch (error) {
      console.log('An error has occurred in getMessages');
    }
  }
}

export function* postMessage() {
  while (true) {
    const action = yield take(requestPostMessage);
    action.payload['to_id'] = action.payload.toId;
    action.payload['room_id'] = action.payload.selectedRoomId;

    const [data, error] = yield call(fetchPostJson, `message`, action.payload);

    if (data && !error) {
      yield put(resetMessages());
      yield put(reset('messageReplyForm'));

      yield put(
        requestMessageList({
          roomId: action.payload['room_id'],
          messagesCurrent: null
        })
      );
    } else {
      yield put(stopSubmit('messageReplyForm', makeFormErrorFormat(error)));
    }
  }
}

export function* initRooms() {
  // 今見ているルームとは別のトークルームを押下した際に発火する
  while (true) {
    const action = yield take(requestInitRooms);

    try {
      // 現在保持してるメッセージの破棄・テキストボックスのクリア
      yield put(resetMessages());
      // 新しく取得したルームに紐付くメッセージを取得
      yield put(
        setSelectedRoomInfo({
          roomId: action.payload.roomId,
          title: action.payload.title
        })
      );
      yield put(
        requestMessageList({
          roomId: action.payload.roomId,
          messagesCurrent: null
        })
      );
      yield put(setOtherPersonId(action.payload.toId));
    } catch (error) {
      console.log('An error has occurred in getMessages');

      history.push('/sorry');
    }
  }
}

export function* postNewMessage() {
  while (true) {
    const action = yield take(requestPostNewMessage);
    const settingState = yield select(selectSetting);
    yield put(startSubmit('createMessage'));
    const [data, error] = yield call(fetchPostJson, `message`, {
      ...action.payload,
      to_id: Number(action.payload.to_id)
    });
    yield put(reset('createMessage'));
    if (data && !error) {
      yield put(
        requestInitRooms({roomId: data.data[0], toId: action.payload.to_id})
      );
      yield put(requestRoomList({useRefresh: true}));
      settingState.isEngineer
        ? history.push('/en/message')
        : history.push('/co/message');
    } else {
      yield put(stopSubmit('createMessage', makeFormErrorFormat(error)));
    }
  }
}

const Message = [
  fork(getRooms),
  fork(getMessages),
  fork(initRooms),
  fork(postMessage),
  fork(postNewMessage),
  fork(checkRoomStatus)
];

export default Message;
