import React from 'react';
import style from './PublicCommentForm.module.scss';
import CommonButton from '../../CommonButton';
import {Field, reduxForm} from 'redux-form';
import {renderTextArea} from '../../Field';
import * as Validator from '../utils/validate';
import FormError from '../FormError';
import Blank from '../../Blank';

const PublicCommentForm = React.memo(
  props => {
    const {parent, handleSubmit, pristine, submitting, error} = props;

    return (
      <form onSubmit={handleSubmit}>
        <div>
          <div className={style.items}>
            <Field
              name="content"
              required={true}
              component={renderTextArea}
              fullWidth={true}
              placeholder="案件に関する質問を入力してください。"
              validate={[Validator.RequiredItem]}
              rows={2}
            />
          </div>
          <Blank height={'2.4rem'} />
          <FormError error={error} />
          <div className={style.underTextArea}>
            <span className={style.greyText}>
              この投稿は全てのユーザーに公開されます。
            </span>
            <div className={style.submit}>
              <CommonButton
                type={'submit'}
                text={parent ? '返信する' : '投稿する'}
                size={'small'}
                color={'blue'}
                variant={'contained'}
                shape={'normal'}
                disabled={pristine || submitting}
              />
            </div>
          </div>
        </div>
      </form>
    );
  },
  (prevProps, nextProps) =>
    prevProps.formValues === nextProps.formValues &&
    prevProps.error === nextProps.error
);

export default reduxForm({})(PublicCommentForm);
