// 企業・エンジニアを 評価するためのモーダルです。
import React from 'react';
import PropTypes from 'prop-types';
import style from './review.module.scss';
import faker from 'faker';
import {useDispatch, useSelector} from 'react-redux';
import {
  requestPostReviewCo,
  requestPostReviewEn,
  setReviewModalFlag
} from '../../actions/review';
import ReviewForm from '../../components/Form/ReviewForm';
import format from 'date-fns/format';
import {
  createMuiTheme,
  makeStyles,
  MuiThemeProvider
} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import {AvatarImg, TASK_STATUS} from '../../common';
import {patchStatus} from '../../actions/patchStatus';

const useStyles = makeStyles(theme => ({
  paperWidthSm: {
    width: '60rem'
  },
  verySmallAvatar: {
    width: '5rem',
    height: '5rem'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: '5rem',
    width: '100%'
  }
}));

const OnHandleSubmit = (isEngineer, task, values, dispatch) => {
  if (isEngineer) {
    dispatch(
      requestPostReviewCo({
        task: task.id,
        companyId: task.owner.company,
        ...values
      })
    );
    dispatch(setReviewModalFlag(false));
  } else {
    dispatch(
      requestPostReviewEn({
        task: task.id,
        userId: task.worker,
        ...values
      })
    );
    dispatch(setReviewModalFlag(false));
  }
};

const Index = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const task = useSelector(state => state.task.detail);
  const modalFlag = useSelector(state => state.review.modalFlag);
  const isEngineer = useSelector(state => state.setting.isEngineer);
  // TODO: [ワーカーの名前] の部分はバックエンドを修正したら対応する、タスク詳細に「ワーカー」の詳細情報も詰めてもらう
  return (
    <div>
      <Dialog
        aria-labelledby="simple-dialog-title"
        open={modalFlag}
        scroll={'body'}
        onBackdropClick={() => {
          dispatch(setReviewModalFlag(false));
        }}
      >
        <div className={style.dialog}>
          <div className={style.mainText}>
            <p>納品が完了しました</p>
            <p>{isEngineer ? '企業' : 'エンジニア'}の評価をしてください</p>
          </div>
          <div className={style.taskBar}>評価するタスク</div>
          <p className={style.taskName}>{task.title}</p>
          <div className={style.userInfoContainer}>
            <div className={classes.verySmallAvatar}>
              <AvatarImg img={task.owner.img} size="verySmall" />
            </div>
            <div className={style.username}>
              {makeUsername(isEngineer, task)}
            </div>
          </div>
          <div className={style.divider} />
          <ReviewForm
            isEngineer={isEngineer}
            onSubmit={values =>
              OnHandleSubmit(isEngineer, task, values, dispatch)
            }
          />
        </div>
      </Dialog>
    </div>
  );
};

const makeUsername = (isEngineer, task) => {
  if (isEngineer) {
    return task.owner.nick_name;
  } else {
    return task.worker_info ? task.worker_info.nick_name : '読み込み中';
  }
};

export default Index;
