import React from 'react';

import styles from './requestModal.module.scss';

import Request from './Request';
import Send from './Send';

const RequestModal = ({name, onClick, userId, taskId, requested, userImg}) => {
  return (
    <div className={styles.background} onClick={() => onClick()}>
      <div className={styles.container}>
        <div className={styles.inner}>
          {requested ? (
            <Send onClick={onClick} nickName={name} userId={userId} />
          ) : (
            <Request
              name={name}
              onClick={onClick}
              userId={userId}
              userImg={userImg}
              taskId={taskId}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default RequestModal;
