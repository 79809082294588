import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles({
  flexContainer: {
    justifyContent: 'space-around'
  }
});

const CommonTabs = ({index, onChange, children}) => {
  const classes = useStyles();
  return (
    <Tabs
      value={index}
      variant={'fullWidth'}
      onChange={onChange}
      indicatorColor="primary"
      classes={{
        flexContainer: classes.flexContainer
      }}
      TabIndicatorProps={{
        style: {
          backgroundColor: '#00A8F0'
        }
      }}
    >
      {children}
    </Tabs>
  );
};

export default CommonTabs;
