import React from 'react';
import styles from '../../commonModal.module.scss';
import IconSearch from '../../../../assets/icon_task_search_blue.svg';
import {requestTaskEdit, setModalSetting} from '../../../../actions/task';
import {moveToMessage} from '../../../../common';

import CommonTaskButton from '../../../Buttons/CommonTaskButton';
import {TASK_STATUS} from '../../../../common';
import {useDispatch, useSelector} from 'react-redux';
import store from 'store';
import MoveToMessageButton from '../../../MoveToMessageButton';
const PreAssignConfirm = ({params: {unReadPreAssignList}}) => {
  const dispatch = useDispatch();
  const {detail} = useSelector(state => state.task);

  return (
    <div className={styles.inner}>
      <div className={styles.header}>
        <img className={styles.image} src={IconSearch} />
        <div className={styles.subTitle}>仮アサインされました</div>
      </div>
      <div className={styles.message}>
        <p>下記のタスクにアサイン依頼が来ました。</p>
        <p>契約についてタスクの担当者とメッセージで相談し、</p>
        <p>
          タスク詳細画面から アサインを承諾/辞退 のいずれかを実行して下さい。
        </p>
      </div>
      {unReadPreAssignList &&
        unReadPreAssignList.map((item, index) => (
          <Content task={item} key={index.toString()} />
        ))}
    </div>
  );
};

const Content = ({task}) => {
  const roomList = useSelector(state => state.message.rooms);
  const dispatch = useDispatch();
  const params = {
    title: 'アサイン依頼について',
    content: 'アサイン依頼ありがとう御座います、よろしくお願い致します'
  };
  const readPreAssignTasks =
    store.get('readPreAssignTasks') === undefined
      ? []
      : store.get('readPreAssignTasks');
  return (
    <div className={styles.row}>
      <p className={styles.rowTitle}>{task.title}</p>
      <MoveToMessageButton
        userId={task.owner_id}
        params={params}
        onClickSideEffect={() => {
          readPreAssignTasks.push(task.id);
          store.set('readPreAssignTasks', readPreAssignTasks);
          dispatch(
            setModalSetting({
              type: 'UNREAD_PRE_ASSIGN_CONFIRM',
              isVisible: false,
              params: []
            })
          );
        }}
      />
    </div>
  );
};

export default PreAssignConfirm;
