import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionActions from '@material-ui/core/AccordionActions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CommonButton from '../CommonButton';
import Blank from '../Blank';
import ProjectDetailImage from '../../assets/project_description.png';
import styles from './aboutProject.module.scss';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  accordionActions: {
    justifyContent: 'center'
  }
}));

export default function AboutProject() {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  return (
    <div className={classes.root}>
      <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1c-content"
          id="panel1c-header"
        >
          <div className={styles.title}>
            <p>プロジェクトとは？</p>
          </div>
        </AccordionSummary>
        <div className={styles.innerContainer}>
          <p className={styles.text}>
            タスク(案件)をプロジェクトで管理すると、お仕事発注・進行管理が楽になります★
          </p>
          <br />
          <p className={styles.text}>
            <strong>メリット</strong>
          </p>
          <p className={styles.text}>
            ・同一プロジェクトでまとまるから、タスクの管理しやすい！
          </p>
          <div className={styles.texts}>
            <p className={styles.text}>
              ・プロジェクト間での、グループメッセージで進行がスムーズに！
            </p>
            <p className={styles.caption}>（coming soon）</p>
          </div>
          <Blank height={'3rem'} />
          <div className={styles.imgContainer}>
            <img
              alt={'プロジェクトイメージ'}
              src={ProjectDetailImage}
              className={styles.projectImg}
            />
          </div>
        </div>
        <Blank height={'1.5rem'} />
        <AccordionActions classes={{root: classes.accordionActions}}>
          <CommonButton
            text={'閉じる'}
            size={'medium'}
            color={'blue'}
            variant={'contained'}
            shape={'normal'}
            onClick={() => {
              setExpanded(false);
              window.scrollTo(0, 0);
            }}
            disabled={false}
          />
        </AccordionActions>
      </Accordion>
    </div>
  );
}
