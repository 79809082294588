import React from 'react';
import {useSelector} from 'react-redux';
import {NavLink} from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {makeStyles} from '@material-ui/core/styles';
import {MENUES} from '../../../constants';
import {userType} from '../../../common';
import DashBoardIcon from '../../../assets/icon_home_menu.svg';
import ProjectIcon from '../../../assets/icon_project.svg';
import SerchEngineerIcon from '../../../assets/icon_handshake.svg';
import BuildingIcon from '../../../assets/icon_building_menu.svg';
import CompanyMember from '../../../assets/icon_company_member.svg';
import Email from '../../../assets/icon_email.svg';
import JobHuntingIcon from '../../../assets/icon_job_hunting.svg';
import RewardIcon from '../../../assets/icon_project.svg';
import IdCardIcon from '../../../assets/icon_white_id.svg';
import PinIcon from '../../../assets/icon_pin_white.svg';

const useStyles = makeStyles({
  button: {
    borderBottom: '0.1rem rgba(255, 255, 255, 0.2) solid',
    height: '6rem'
  },
  activeButton: {
    borderBottom: '0.1rem rgba(255, 255, 255, 0.2) solid',
    height: '6rem',
    backgroundColor: 'rgba(0, 0, 0, 0.08)'
  },
  root: {
    marginLeft: '1rem',
    marginTop: '0.7rem',
    width: '24rem',
    color: '#FFF',
    '& span': {
      fontSize: '1.6rem',
      width: '24rem'
    }
  },
  icon: {
    width: '2rem'
  }
});

const MenuButton = ({name, page, kind}) => {
  const classes = useStyles();
  const isEngineer = useSelector(state => state.setting.isEngineer);

  const makeDynamicValues = () => {
    switch (page) {
      case MENUES.dashboard:
        return [DashBoardIcon, `/${kind}`];
      case MENUES.project:
        return [ProjectIcon, `/${kind}/project`];
      case MENUES.search:
        return [SerchEngineerIcon, `/${kind}/engineers`];
      case MENUES.company:
        return [BuildingIcon, `/${kind}/info`];
      case MENUES.member:
        return [CompanyMember, `/${kind}/member`];
      case MENUES.email:
        return [Email, `/${kind}/message`];
      case MENUES.job:
        return [JobHuntingIcon, `/${kind}/task`];
      case MENUES.rewardAdmin:
        return [RewardIcon, `/${userType(isEngineer)}/rewardAdmin`];
      case MENUES.paymentMethod:
        return [IdCardIcon, `/${kind}/setting/payment`];
      case MENUES.offerAdmin:
        return [PinIcon, `/${kind}/offers`];
      default:
        return null;
    }
  };

  const [icon, url] = makeDynamicValues();
  return (
    <NavLink
      exact
      to={url}
      activeStyle={{
        backgroundColor: 'rgba(0, 0, 0, 0.2)'
      }}
    >
      <ListItem button className={classes.button}>
        <img src={icon} alt={'image'} className={classes.icon} />
        <ListItemText primary={name} className={classes.root} />
      </ListItem>
    </NavLink>
  );
};

export default MenuButton;
