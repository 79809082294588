import React, {useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {requestEnUserInfo} from '../../actions/user';
import {requestEnReviews} from '../../actions/review';
import styles from './engineerInfo.module.scss';
import PageTitle from '../PageTitle';
import ContentTitle from '../ContentTitle';
import Text from '../Text';
import MoveToMessageButton from '../MoveToMessageButton';
import ValuationItem from '../ValuationItem';
import StarRating from '../StarRating';
import {useHistory} from 'react-router-dom';
import {AvatarImg, userType} from '../../common';
import UserInfoItem from '../../components/UserInfoItem';
import CommonButton from '../../components/CommonButton';
import Blank from '../../components/Blank';
import GitHubIcon from '../../assets/img_github.svg';
import TagLabel from '../../components/TagLabel';
import useScrollToTop from '../../hooks/useScrollToTop';
// material-ui
import {withStyles} from '@material-ui/core/styles/index';
import Divider from '@material-ui/core/Divider';

const style = {
  middleAvatar: {
    display: 'inline-block',
    marginTop: '3rem',
    marginLeft: '1rem'
  }
};

const Info = props => {
  const {classes, match} = props;
  const engineerId = Number(match.params.id);
  const dispatch = useDispatch();
  const history = useHistory();
  useScrollToTop();
  const engineerInfo = useSelector(state => state.user.enUser);
  const engineerReviews = useSelector(state => state.review.enReviews);
  const roomList = useSelector(state => state.message.rooms);
  const setting = useSelector(state => state.setting);
  const {me, isEngineer} = setting;

  useEffect(() => {
    if (me.id === engineerId || !isEngineer) {
      dispatch(requestEnUserInfo({id: engineerId}));
      dispatch(requestEnReviews({id: engineerId}));
    }
  }, [me]);

  const {
    id,
    username,
    nick_name,
    tel,
    status,
    tags,
    career,
    introduction,
    img,
    github_id
  } = engineerInfo;

  const {average_rating} = engineerReviews.length > 0 ? engineerReviews[0] : 0;
  const EVAL_SCORE = average_rating * 20;
  let GitHubButtonDisabled = false;

  const onClickHandler = () => {
    GitHubButtonDisabled = true;
    window.open(`https://github.com/${github_id}`);
  };

  return (
    <>
      {me.id === engineerId || !isEngineer ? (
        <div className={styles.flex}>
          <div className={styles.mainInformations}>
            <PageTitle title="ユーザー情報" />

            <div className={styles.informationItem}>
              <ContentTitle
                title="基本情報"
                isButton={id == me.id}
                link={'/en/user/edit/profile'}
              />
              <div className={styles.flex}>
                <div
                  className={
                    isEngineer ? styles.rightContents : styles.coRightContents
                  }
                >
                  <div className={classes.middleAvatar}>
                    <AvatarImg img={img} size="middle" />
                  </div>
                  <div className={styles.buttons}>
                    <div className={styles.githubButton}>
                      {id !== me.id && !isEngineer && (
                        <CommonButton
                          text={'GitHub'}
                          size={'preMinimum'}
                          color={'blue'}
                          variant={'contained'}
                          shape={'normal'}
                          onClick={onClickHandler}
                          icon={GitHubIcon}
                          disabled={GitHubButtonDisabled}
                        />
                      )}
                    </div>
                    <div className={styles.messageButton}>
                      {id !== me.id && (
                        <MoveToMessageButton
                          userId={engineerId}
                          size={'preMinimum'}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div>
                  <UserInfoItem
                    title={'受付状態'}
                    mainContent={status}
                    style={{margin: '4rem 0 2rem 0'}}
                  />
                  <Divider />
                  <UserInfoItem
                    title={'表示用の名前'}
                    mainContent={nick_name}
                    style={{margin: '2rem 0 2rem 0'}}
                  />
                  <Divider />
                  <UserInfoItem
                    title={'スキル'}
                    mainContent={tags !== undefined && <Tags tags={tags} />}
                    style={{margin: '2rem 0 2rem 0'}}
                  />
                  <Divider />
                  <UserInfoItem
                    title={'紹介文'}
                    mainContent={introduction ? introduction : '未設定'}
                    style={{margin: '2rem 0'}}
                  />
                  <Divider />
                  <UserInfoItem
                    title={'経歴'}
                    mainContent={career}
                    style={{margin: '2rem 0'}}
                  />
                  <Divider />
                </div>
              </div>
            </div>

            <div className={styles.informationItem}>
              <ContentTitle title="評価・実績" />
              <UserInfoItem title={'平均評価'} style={{maxHeight: '3.5rem'}} />
              <StarRating evalValue={EVAL_SCORE} />
              {engineerReviews.length === 0 ? (
                <p className={styles.noEvaluation}>
                  まだ、お仕事のやりとりがありません。
                </p>
              ) : (
                engineerReviews.map((item, index) => {
                  return <ValuationItem item={item} key={index.toString()} />;
                })
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.unavailableContainer}>
          <div className={styles.unavailable}>閲覧することができません</div>
        </div>
      )}
    </>
  );
};

const Tags = ({tags}) => {
  return (
    <div className={styles.tagContainer}>
      {tags.map(tag => (
        <TagLabel tag={tag} />
      ))}
    </div>
  );
};

export default withStyles(style)(Info);
