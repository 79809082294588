import {call, fork, put, select, take, all} from 'redux-saga/effects';
import {reset, startSubmit, stopSubmit} from 'redux-form';
import {push} from 'connected-react-router';
import {
  fetchDelete,
  fetchGet,
  fetchPatch,
  fetchPostJson,
  fetchPostTask
} from './utills/fetch';
import {
  deleteTaskTags,
  requestDraftTasks,
  requestFailedTasks,
  requestProcessingTasks,
  requestProjectTasks,
  requestTaskCommentCreate,
  requestTaskCreate,
  requestTaskDelete,
  requestTaskDetail,
  requestTaskEdit,
  requestTaskCancel,
  requestTaskList,
  requestUnderReviewTasks,
  requestWantedTasks,
  setModalFlag,
  setTaskFiles,
  creanTaskAttached,
  successDraftTasks,
  successFailedTasks,
  successProcessingTasks,
  successProjectTasks,
  successTaskDetail,
  successTaskList,
  successUnderReviewTasks,
  successWantedTasks,
  requestAllTasksCo,
  successAllTasksCo,
  requestPreAssignedTasks,
  successPreAssignedTasks,
  setModalSetting,
  requestRewardDetail,
  successRewardDetail,
  setRequiredPayTasks,
  requestPublicTaskList,
  requestWantedTaskEdit,
  requestExtendClosingDate
} from '../actions/task';
import {setPublicComment, setPublicCommentList} from '../actions/comment';
import {setMessageType} from '../actions/dialog';
import {cleanFields, makeFormErrorFormat, TASK_STATUS} from '../common';
import {
  requestDeleteImportantNotification,
  successImportantNotifications
} from '../actions/notification';
import {history, store as reduxStore} from '../store';
import store from 'store';
import {useSelector} from 'react-redux';

const RECEPTION_ALLOW = true;
const RECEPTION_DENY = 0;
const STATUS_PRE_SAVE = 1;
const STATUS_SAVE = 2;

// タスク一覧取得
export function* getTaskList() {
  while (true) {
    const action = yield take(requestTaskList);
    const searchParams = makeSearchParams({...action.payload});
    const [data, error] = yield call(
      fetchGet,
      `tasks/?page=${action.payload.page}&status=${TASK_STATUS.wanted}${searchParams}`
    );
    if (data && !error) {
      yield put(successTaskList(data));
    } else {
      // TODO: エラーハンドリング
    }
  }
}

// 公開タスク一覧取得
export function* getPublicTaskList() {
  while (true) {
    const action = yield take(requestPublicTaskList);
    const searchParams = makeSearchParams({...action.payload});
    const [data, error] = yield call(
      fetchGet,
      `tasks/?page=${action.payload.page}&only_wanted=${action.payload.onlyWanted}${searchParams}`,
      false
    );
    if (data && !error) {
      yield put(successTaskList(data));
    }
  }
}

const makeSearchParams = payload => {
  delete payload.page;
  if (payload.tags) payload['tags'] = payload.tags.map(item => item.name);
  const keyList = Object.keys(payload);
  const paramList = keyList.map(key => {
    return `&${key}=${payload[key]}`;
  });
  return paramList.join('');
};

// タスク詳細取得
export function* getTaskDetail() {
  while (true) {
    const action = yield take(requestTaskDetail);
    const [data, error] = yield call(fetchGet, `tasks/${action.payload.id}/`);
    if (data && !error) {
      yield put(successTaskDetail(data));
      // 公開コメントもこのGETレスポンスに内包されているため、ここでSETしておく
      yield put(setPublicCommentList(data.public_comments));
      yield put(setPublicComment({commentList: data.public_comments}));
    } else {
      console.log('error!');
    }
  }
}

// タスク新規作成
export function* postTask() {
  while (true) {
    const action = yield take(requestTaskCreate);
    const formatedPayload = formatTaskPayload(action.payload);
    yield put(startSubmit('task'));
    const [data, error] = yield call(fetchPostTask, `tasks`, formatedPayload);
    if (data && !error) {
      yield put(reset('task'));
      if (action.payload.isMoveToPaymentSetting) {
        history.push('/co/setting/payment', {TaskId: data.id});
      } else if (action.payload.status === TASK_STATUS.draft) {
        yield put(
          setModalSetting({
            type: 'SUCCESS_DRAFT_TASK',
            isVisible: true,
            params: {
              title: '下書き保存完了',
              message:
                'タスクを下書き保存しました。\nTOPからいつでも再編集できます',
              buttonProps: {
                text: 'TOPへ',
                onClick: () => {
                  reduxStore.dispatch(
                    setModalSetting({
                      type: 'SUCCESS_DRAFT_TASK',
                      isVisible: false
                    })
                  );
                  history.push(`/co`);
                }
              }
            }
          })
        );
      } else {
        yield put(
          setModalSetting({
            type: 'SUCCESS_SUBMIT_TASK',
            isVisible: true,
            onCloseHandler: () => {
              history.push(`/co`);
            }
          })
        );
      }
    } else {
      yield put(stopSubmit('task', makeFormErrorFormat(error)));
    }
  }
}

// 下書きタスクの編集
export function* patchTask() {
  while (true) {
    const action = yield take(requestTaskEdit);
    const detail = yield select(state => state.task.detail);
    const formatedPayload = {...cleanFields(formatTaskPayload(action.payload))};
    yield put(startSubmit('tasks'));
    const [data, error] = yield call(
      fetchPostTask,
      `tasks/${action.payload.id}`,
      formatedPayload,
      'PATCH'
    );
    if (data && !error) {
      const prevStatus = detail.status;
      const nextStatus = action.payload.status;

      yield put(reset('tasks'));
      if (action.payload.isMoveToPaymentSetting) {
        history.push('/co/setting/payment', {TaskId: data.id});
      } else if (nextStatus === TASK_STATUS.draft) {
        yield put(
          setModalSetting({
            type: 'SUCCESS_DRAFT_TASK',
            isVisible: true,
            params: {
              title: '下書き保存完了',
              message:
                'タスクを下書き保存しました。\nTOPからいつでも再編集できます',
              buttonProps: {
                text: 'TOPへ',
                onClick: () => {
                  reduxStore.dispatch(
                    setModalSetting({
                      type: 'SUCCESS_DRAFT_TASK',
                      isVisible: false
                    })
                  );
                  history.push(`/co`);
                }
              }
            }
          })
        );
      } else if (
        prevStatus === TASK_STATUS.draft &&
        nextStatus === TASK_STATUS.under_review
      ) {
        yield put(
          setModalSetting({
            type: 'SUCCESS_SUBMIT_TASK',
            isVisible: true,
            params: {
              title: '新規タスクを申請しました',
              message:
                '作成して頂いたタスクの内容を審査しております\n審査は翌営業日までに完了致します\n確認が完了致しましたら、メールにてご連絡致します。\n審査中のタスクは、TOPページよりご確認頂けます。',
              buttonProps: {
                text: '閉じる',
                onClick: () => {
                  reduxStore.dispatch(
                    setModalSetting({
                      type: 'SUCCESS_SUBMIT_TASK',
                      isVisible: false
                    })
                  );
                  history.push(`/co`);
                }
              }
            }
          })
        );
      } else if (
        prevStatus === TASK_STATUS.under_review &&
        nextStatus === TASK_STATUS.under_review
      ) {
        yield put(
          setModalSetting({
            type: 'SUCCESS_REVIEW_TASK',
            isVisible: true,
            params: {
              title: '再申請完了',
              message:
                'タスクの再申請が完了しました。\n通常、審査は翌営業日までに完了致します。今しばらくお待ちください。',
              buttonProps: {
                text: 'TOPへ',
                onClick: () => {
                  reduxStore.dispatch(
                    setModalSetting({
                      type: 'SUCCESS_REVIEW_TASK',
                      isVisible: false
                    })
                  );
                  history.push(`/co`);
                }
              }
            }
          })
        );
      }
    } else {
      yield put(stopSubmit('task', makeFormErrorFormat(error)));
    }
  }
}

// 募集中タスクの編集
export function* patchWantedTask() {
  while (true) {
    const action = yield take(requestWantedTaskEdit);
    const formatedPayload = {...cleanFields(formatTaskPayload(action.payload))};
    yield put(startSubmit('task'));
    const [data, error] = yield call(
      fetchPostTask,
      `tasks/${action.payload.id}/edit`,
      formatedPayload
    );
    if (data && !error) {
      yield put(reset('task'));
      yield put(
        setModalSetting({
          type: 'SUCCESS_WANTED_TASK',
          isVisible: true,
          params: {
            title: '修正完了',
            message:
              'タスクの内容を修正しました。\nTOPからいつでも確認が可能です。',
            buttonProps: {
              text: 'TOPへ',
              onClick: () => {
                reduxStore.dispatch(
                  setModalSetting({
                    type: 'SUCCESS_WANTED_TASK',
                    isVisible: false
                  })
                );
                history.push(`/co`);
              }
            }
          }
        })
      );
    } else {
      yield put(stopSubmit('task', makeFormErrorFormat(error)));
    }
  }
}

const formatTaskPayload = payload => {
  let formatedPayload = {...payload};
  if (payload.tags) {
    formatedPayload['tags'] = payload.tags.map(item => item.name);
  }
  return formatedPayload;
};

// タスク募集取り下げ
export function* patchTaskCancel() {
  while (true) {
    const action = yield take(requestTaskCancel);
    yield put(startSubmit('tasks'));
    const [data, error] = yield call(
      fetchGet,
      `tasks/${action.payload}/cancel`
    );
    if (data && !error) {
      yield put(reset('tasks'));
      yield put(setModalSetting({type: 'didTaskCancel', isVisible: true}));
    } else {
      yield put(
        stopSubmit(
          'tasks',
          Object.assign(error, {_error: error.non_field_errors})
        )
      );
    }
  }
}

// タスク削除
export function* deleteTask() {
  while (true) {
    const action = yield take(requestTaskDelete);
    yield put(startSubmit('tasks'));
    const [data, error] = yield call(
      fetchDelete,
      `tasks/${action.payload.id}`,
      action.payload
    );
    if (data && !error) {
      yield put(reset('tasks'));
      yield put(push(`/co/tasks/`));
    } else {
      yield put(
        stopSubmit(
          'Tasks',
          Object.assign(error, {_error: error.non_field_errors})
        )
      );
    }
  }
}

// タスクコメント
export function* postComment() {
  while (true) {
    const action = yield take(requestTaskCommentCreate);
    yield put(startSubmit('commnet'));
    const [data, error] = yield call(
      fetchPostJson,
      `tasks/${action.payload.id}/commnents`,
      action.payload
    );
    if (data && !error) {
      yield put(reset('commnet'));
    } else {
      yield put(
        stopSubmit(
          'commnet',
          Object.assign(error, {_error: error.non_field_errors})
        )
      );
    }
  }
}

// タスク評価
export function* postScore() {
  while (true) {
    const action = yield take(requestTaskCommentCreate);
    yield put(startSubmit('score'));
    const [data, error] = yield call(
      fetchPostJson,
      `tasks/${action.payload.id}/score`,
      action.payload
    );
    if (data && !error) {
      yield put(reset('score'));
      yield put(push(`/co/tasks/${action.payload.id}`));
      yield put(setMessageType('post')); //dialog表示
    } else {
      yield put(
        stopSubmit(
          'score',
          Object.assign(error, {_error: error.non_field_errors})
        )
      );
    }
  }
}

// 進行中タスク一覧取得（企業用）
export function* getProcessingTasks() {
  while (true) {
    const action = yield take(requestProcessingTasks);
    // 作業中 / 確認中 / 修正依頼
    const targets = `${TASK_STATUS.working},${TASK_STATUS.confirming},${TASK_STATUS.reject}`;

    const [data, error] = yield call(
      fetchGet,
      `own-company-tasks?status=${targets}`
    );
    if (data && !error) {
      yield put(successProcessingTasks(data.results));
    } else {
      // TODO: エラーハンドリング
    }
  }
}

// 募集中タスク一覧取得（企業用）
export function* getWantedTasks() {
  while (true) {
    const action = yield take(requestWantedTasks);
    const [data, error] = yield call(fetchGet, `own-company-tasks?status=3`);
    if (data && !error) {
      // 既にアサイン済みのエンジニアがいるのに募集中なタスクは「仮払いが必要なタスク」
      const requiredPaymentTasks = data.results.filter(
        task => task.worker !== null
      );
      yield put(successWantedTasks(data.results));
      yield put(setRequiredPayTasks(requiredPaymentTasks));
    } else {
      // TODO: エラーハンドリング
    }
  }
}

// 審査中タスク一覧取得（企業用）
export function* getUnderReviewTasks() {
  while (true) {
    const action = yield take(requestUnderReviewTasks);
    const [data, error] = yield call(fetchGet, `own-company-tasks?status=2`);
    if (data && !error) {
      yield put(successUnderReviewTasks(data.results));
    } else {
      console.error('審査中タスクの一覧取得中にエラーが発生しました。');
    }
  }
}

// 下書き中タスク一覧取得（企業用）
export function* getDraftTasks() {
  while (true) {
    const action = yield take(requestDraftTasks);
    const [data, error] = yield call(fetchGet, `own-company-tasks?status=1`);
    if (data && !error) {
      yield put(successDraftTasks(data.results));
    } else {
      // TODO: エラーハンドリング
    }
  }
}

// 仮売上げに失敗したタスクの取得
export function* getFailedTasks() {
  while (true) {
    yield take(requestFailedTasks);
    const [data, error] = yield call(
      fetchGet,
      `own-company-tasks?status=${TASK_STATUS.failed}`
    );
    const {importantNotifications} = yield select(state => state.notification);

    if (data && !error) {
      let newNotifications = [];
      if (data.results.length > 0) {
        data.results.map(item => {
          const notice = {
            notice: `仮売上げに失敗したタスクがあります タスク名：${item.title}`,
            type: 'failedTaskAvailable',
            taskId: item.id,
            onClick: () => {
              history.push(`/co/project/${item.project}/task/${item.id}`);
            }
          };
          newNotifications.push(notice);
        });
        if (importantNotifications.length > 0) {
          // 再マウント時、まだ importantNotifications に存在しないお知らせのみを追加する。
          newNotifications = importantNotifications.filter(item => {
            const isContain = newNotifications.find(
              elem => Number(elem.taskId) === Number(item.taskId)
            );
            return isContain === undefined;
          });
        }
        yield put(successImportantNotifications(newNotifications));
      } else {
        yield put(requestDeleteImportantNotification('failedTaskAvailable'));
      }
      yield put(successFailedTasks(data.results));
    } else {
      // TODO: エラーハンドリング
    }
  }
}

export function* getProjectTasks() {
  while (true) {
    const action = yield take(requestProjectTasks);
    const [data, err] = yield call(
      fetchGet,
      `tasks/?project=${action.payload}`
    );
    if (data && !err) {
      yield put(successProjectTasks(data.results));
    } else {
      // TODO: エラーハンドリング（多分 history.push）
    }
  }
}

// 全てのタスク一覧取得（企業用）
export function* getAllTasksCo() {
  while (true) {
    const action = yield take(requestAllTasksCo);
    const [data, error] = yield call(fetchGet, `own-company-tasks`);
    if (data && !error) {
      yield put(successAllTasksCo(data.results));
    } else {
      // TODO: エラーハンドリング
    }
  }
}

// 仮アサイン状態のタスク一覧を取得し、既読かどうかチェックしモーダルを出現させる
export function* preAssignedTasks() {
  while (true) {
    const {
      payload: {openModal}
    } = yield take(requestPreAssignedTasks);
    const [data, error] = yield call(fetchGet, `pre-assigned-tasks/`);
    if (data && !error) {
      yield put(successPreAssignedTasks(data.results));
      if (openModal) {
        const readPreAssignTasks =
          store.get('readPreAssignTasks') === undefined
            ? []
            : store.get('readPreAssignTasks');
        // モーダルから遷移したことが無い、仮アサイン済みタスク達を抽出
        const unReadPreAssignList = data.results.filter(item => {
          return !readPreAssignTasks.some(id => id === item.id);
        });
        if (unReadPreAssignList.length > 0) {
          //  モーダル処理
          yield put(
            setModalSetting({
              type: 'UNREAD_PRE_ASSIGN_CONFIRM_CO',
              isVisible: true,
              params: unReadPreAssignList
            })
          );
        }
      }
    } else {
    }
  }
}

// 支払い内訳の取得
export function* getRewardDetail() {
  while (true) {
    const action = yield take(requestRewardDetail);
    const [data, error] = yield call(fetchPostJson, `task_reward_detail`, {
      reward: Number(action.payload.reward),
      coupon_id: Number(action.payload.coupon)
    });
    if (data && !error) {
      yield put(successRewardDetail(data));
    } else {
    }
  }
}

export function* patchClosingDate() {
  while (true) {
    const {payload} = yield take(requestExtendClosingDate);
    yield put(startSubmit('ExtendClosingDateForm'));
    const [data, err] = yield call(
      fetchPatch,
      `tasks/${payload.taskId}`,
      payload.values
    );

    if (data && !err) {
      yield put(stopSubmit('ExtendClosingDateForm'));
      yield put(requestTaskDetail({id: payload.taskId}));
    } else {
      yield put(stopSubmit('ExtendClosingDateForm', makeFormErrorFormat(err)));
    }
  }
}

const Task = [
  fork(getTaskList),
  fork(getTaskDetail),
  fork(postTask),
  fork(patchTask),
  fork(deleteTask),
  fork(postComment),
  fork(postScore),
  fork(getProcessingTasks),
  fork(getWantedTasks),
  fork(getProjectTasks),
  fork(getUnderReviewTasks),
  fork(getDraftTasks),
  fork(getFailedTasks),
  fork(getAllTasksCo),
  fork(preAssignedTasks),
  fork(patchTaskCancel),
  fork(getRewardDetail),
  fork(getPublicTaskList),
  fork(patchWantedTask),
  fork(patchClosingDate)
];

export default Task;
