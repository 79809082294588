import {
  SUCCESS_MEMBER,
  SUCCESS_SPECIFIC_MEMBER,
  REMOVE_CANDIDATE_MEMBER,
  SET_MODAL_FLAG,
  SET_SECOND_MODAL_FLAG
} from '../actions/member';

const initialState = {
  member: {},
  members: [],
  removeCandidateMember: null,
  isOpenModal: false,
  isOpenSecondModal: false
};

const member = (state = initialState, action) => {
  switch (action.type) {
    case SUCCESS_MEMBER:
      const member = action.payload;
      let members = member.map(item => {
        return {
          id: item.id,
          name: item.last_name_kanji + ' ' + item.first_name_kanji,
          nick_name: item.nick_name,
          position: item.introduction,
          email: item.email,
          date_joined: item.date_joined,
          img: item.img,
          permission: item.permission,
          isActive: item.last_name_kanji !== null
        };
      });
      return {
        ...state,
        members: members
      };

    case SUCCESS_SPECIFIC_MEMBER:
      const user = action.payload;
      return {
        name: user.last_name + user.first_name,
        // TODO: APIできたら入れる
        kana: 'カナ',
        position: '職業',
        company: user.name
      };

    case REMOVE_CANDIDATE_MEMBER:
      return {
        ...state,
        removeCandidateMember: action.payload
      };

    case SET_MODAL_FLAG:
      return {
        ...state,
        isOpenModal: action.payload
      };
    case SET_SECOND_MODAL_FLAG:
      return {
        ...state,
        isOpenSecondModal: action.payload
      };

    default:
      return state;
  }
};

export default member;
