import React from 'react';
import {useHistory} from 'react-router-dom';

import {RecruitmentTask} from '../TaskLists/recruitmentTask/index.js';
import {ProceedingTask} from '../TaskLists/proceedingTask/index.js';
import {ConfirmingTask} from '../TaskLists/confirmingTask/index.js';
import {RejectTask} from '../TaskLists/rejectTask';
import {CheckedTask} from '../TaskLists/checkedTask';
import {FailedTask} from '../TaskLists/failedTask';
import {FinishedTask} from '../TaskLists/finishedTask';
import {TASK_STATUS} from '../../../common';

const EnTaskList = ({contents, isAnonymousUser, isEngineer = true}) => {
  const history = useHistory();
  // TODO: エンジニア名は仮です

  return contents.map((item, index) => {
    const {
      status_info,
      status,
      updated_at,
      title,
      project_title,
      engineer = 'エンジニアの名前',
      //使うかわからないのでとりあえずコメントアウトしておきます！
      // messageCount = item.private_comments.length,
      closing_date,
      id
    } = item;

    switch (status) {
      case TASK_STATUS.wanted:
        return (
          <div key={index}>
            <RecruitmentTask
              item={item}
              index={index}
              isEngineer={isEngineer}
              isAnonymousUser={isAnonymousUser}
            />
          </div>
        );
        break;
      case TASK_STATUS.working:
        return (
          <div key={index}>
            <ProceedingTask item={item} index={index} isEngineer={true} />
          </div>
        );
        break;
      case TASK_STATUS.confirming:
        return (
          <div key={index}>
            <ConfirmingTask item={item} index={index} isEngineer={true} />
          </div>
        );
        break;

      case TASK_STATUS.reject:
        return (
          <div key={index}>
            <RejectTask item={item} index={index} isEngineer={true} />
          </div>
        );
        break;
      case TASK_STATUS.checked:
        return (
          <div key={index}>
            <CheckedTask item={item} index={index} isEngineer={true} />
          </div>
        );
        break;
      case TASK_STATUS.closed:
        return (
          <div key={index}>
            <FinishedTask item={item} index={index} isEngineer={true} />
          </div>
        );
        break;
    }
  });
};

export default EnTaskList;
