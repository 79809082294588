import React, {FC} from 'react';
import {
  Field,
  FieldArray,
  InjectedFormProps,
  reduxForm,
  reset
} from 'redux-form';
import styles from './engineerSearch.module.scss';
import {renderSelect, renderText} from '../../Field';
import Blank from '../../Blank';
import TagInput from '../../TagInput';
import CommonButton from '../../CommonButton';
import {useDispatch, useSelector} from 'react-redux';
import {requestSearchEngineers} from '../../../actions/user';

type Props = {
  handleSubmit: (val: any) => void;
  pristine?: boolean;
  submitting?: boolean;
};

const EngineerSearchForm: FC<InjectedFormProps<{}, {}, string>> = ({
  handleSubmit,
  pristine,
  submitting
}) => {
  const dispatch = useDispatch();
  return (
    <form onSubmit={handleSubmit}>
      <div className={styles.rootContainer}>
        <div className={styles.upperContainer}>
          <div className={styles.blankWidth}>{''}</div>
          <div className={styles.itemSet}>
            <p className={styles.name}>スキル</p>
            {/*@ts-ignore*/}
            <FieldArray name="tags" component={TagInput} fullWidth={true} />
          </div>
          <div className={styles.itemSet}>
            <p className={styles.name}>実績件数</p>
            <div>
              <Field
                name="exp"
                required={true}
                component={renderText}
                fullWidth={true}
                placeholder={'半角数字で入力'}
                size={'small'}
              />
            </div>
            <Blank height={null} width={'1rem'} />
            <p className={styles.name}>件以上</p>
          </div>
          <div className={styles.buttons}>
            {/*@ts-ignore*/}
            <CommonButton
              onClick={() => handleReset(dispatch)}
              text={'条件をクリア'}
              size={'minimum'}
              color={'blue'}
              variant={'outlined'}
              shape={'normal'}
              disabled={pristine || submitting}
            />
            <div className={styles.blankWidth} />
            {/*@ts-ignore*/}
            <CommonButton
              type={'submit'}
              text={'検索'}
              size={'minimum'}
              color={'blue'}
              variant={'contained'}
              shape={'normal'}
              disabled={pristine || submitting}
            />
          </div>
        </div>
        <Blank height={'1.6rem'} width={null} />
      </div>
    </form>
  );
};

const handleReset = (dispatch: any) => {
  dispatch(reset('engineerSearch'));
  dispatch(requestSearchEngineers({page: 1}));
};

export default reduxForm({form: 'engineerSearch'})(EngineerSearchForm);
