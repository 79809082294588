import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {requestSignout} from '../../actions/sign';

import styles from './signLoading.module.scss';

const signOutLoading = () => {
  // Firebaseサインアウトが完了するまで待機する企業用コンポーネント
  const history = useHistory();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(state => state.firebase.isLoggedIn);

  useEffect(() => {
    dispatch(requestSignout());
  }, []);

  useEffect(() => {
    if (!isLoggedIn) {
      history.push('/signin');
    }
  }, [isLoggedIn]);

  return (
    <section className={styles.background}>
      <div className={styles.container}>
        <h3 className={styles.notFound}>ログアウト中</h3>
        <h2 className={styles.header}>少々お待ちください</h2>
      </div>
    </section>
  );
};

export default signOutLoading;
