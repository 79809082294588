import React, {Component, useEffect} from 'react';
import {connect, useDispatch, useSelector} from 'react-redux';
import {useLocation, useParams, withRouter} from 'react-router-dom';
import {reduxForm, getFormSubmitErrors} from 'redux-form';
// component
import TaskForm from '../../../components/Form/co/taskForms/TaskForm';
import {
  requestTaskEdit,
  requestTaskDetail,
  requestResetTask
} from '../../../actions/task';
import PageTitle from '../../../components/PageTitle';
import styles from './task.module.scss';
import useScrollToTop from '../../../hooks/useScrollToTop';
import {requestFormatList} from '../../../actions/format';
import useRequestCompanyInfo from '../../../hooks/useRequestCompanyInfo';
import {cleanFields, selectCheapCouponId, TASK_STATUS} from '../../../common';
import {NEW_TASK_PHRASE} from '../../../constants/phrase';

const DraftEdit = () => {
  useScrollToTop();
  const dispatch = useDispatch();

  const {id} = useParams();

  useEffect(() => {
    dispatch(requestTaskDetail({id: id}));
    dispatch(requestFormatList());
    return () => dispatch(requestResetTask());
  }, []);

  useRequestCompanyInfo();
  const {coupons} = useSelector(state => state.myCompany);
  const paymentState = useSelector(state => state.payment.paymentState);
  const detail = useSelector(state => state.task.detail);

  // クーポン情報の読み込み待ち
  if (coupons == null) return <></>;

  let initialValues = {
    ...detail,
    format_choose: (detail.format_info && detail.format_info.id) || 'default',
    tags: detail.tags_info.map(item => {
      return {
        name: item.name
      };
    })
  };

  if (selectCheapCouponId(coupons)) {
    initialValues['coupon'] = selectCheapCouponId(coupons);
  }

  return (
    <>
      <div className={styles.editTitle}>
        <PageTitle title={'タスクの編集'} />
      </div>
      {detail.id ? (
        <TaskForm
          paymentState={paymentState}
          initialValues={initialValues}
          onSubmit={values => {
            dispatch(requestTaskEdit({...cleanFields(values)}));
          }}
          preSaveBehavior={'PATCH'}
        />
      ) : null}
    </>
  );
};

export default DraftEdit;
