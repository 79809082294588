import React from 'react';
import IconAvater from '../assets/icon_header_avater.svg';
import Avatar from '@material-ui/core/Avatar/index';
import {createMuiTheme, makeStyles} from '@material-ui/core/styles';
import store from 'store';
import {requestInitRooms} from '../actions/message';
import {history, store as reduxStore} from '../store';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import ja from 'date-fns/locale/ja';
import {DEFAULT_SWITCH_DATE_TYPE_LIMIT} from '../constants';
import FormatDate from '../components/FormatDate';

//引数に渡されたimgが入っているか否かでsrcプロパティに入れるURLを分けて返すコンポーネント。imgの中身がない場合は共通のIconAvaterを出力
export const AvatarImg = ({img, size}) => {
  // デフォルトのサイズを用意しておく
  let applyWidth = '4rem';
  let applyHeight = '4rem';
  let applyColor = 'white';
  //色は今の所whiteで固定

  switch (size) {
    case 'big':
      applyWidth = '18rem';
      applyHeight = '18rem';
      break;

    case 'middle':
      applyWidth = '16rem';
      applyHeight = '16rem';
      break;

    case 'small':
      applyWidth = '6rem';
      applyHeight = '6rem';
      break;

    case 'verySmall':
      applyWidth = '5rem';
      applyHeight = '5rem';
      break;

    case 'minimum':
      applyWidth = '4rem';
      applyHeight = '4rem';
      break;
    case 'smallest':
      applyWidth = '3rem';
      applyHeight = '3rem';
      break;
  }

  const useStyles = makeStyles(theme => ({
    Avatar: {
      width: applyWidth,
      height: applyHeight,
      color: applyColor
    }
  }));

  const classes = useStyles();
  const imgSrc = img ? img : IconAvater;

  return (
    <>
      <Avatar alt="" src={imgSrc} className={classes.Avatar} />
    </>
  );
};

export const TASK_STATUS = {
  draft: 1,
  under_review: 2,
  wanted: 3,
  working: 4,
  confirming: 5,
  reject: 6,
  checked: 7,
  closed: 8,
  canceled: 9,
  failed: 10
};

export const downloadAuthFile = (url, fileName) => {
  fetch(url, {
    method: 'GET',
    headers: new Headers([['Authorization', `Bearer ${store.get('token')}`]])
  })
    .then(response => response.blob())
    .then(blob => {
      let anchor = document.createElement('a');
      anchor.href = window.URL.createObjectURL(blob);
      anchor.download = fileName;
      anchor.click();
    });
};

export const userType = isEngineer => {
  if (isEngineer) {
    return 'en';
  } else if (isEngineer === false) {
    return 'co';
  } else {
    return '';
  }
};

export const makeTagsText = tagsInfo => {
  const tagNames = tagsInfo.map(item => item.name);
  let text = '';
  text = tagNames.join(' / ');
  return text;
};
// 色一覧
export const BASE_COLOR = {
  yellowGreen: '#91D720'
  // 必要に応じてどんどん追加、既存の色もここで管理
};

//severity (snackbarやAlertで表示する種類)
export const SEVERITY = {
  success: 'success',
  error: 'error',
  warning: 'warning',
  info: 'info'
  // 必要に応じてどんどん追加、
};

// ボタン丸ごと使いたいときはMoveToMessageButtonコンポーネントを用いる。
// 他のボタンにmessage遷移の機能持たせたいときはここのcommon.jsにある共通関数moveToMessageを用いる。
export const moveToMessage = (e, userId, roomList, params = null) => {
  const isEngineer = store.get('isEngineer');
  // "アサインされているエンジニアのuserId"と"既存のroomListの要素のuser情報"を１つずつ見る。
  roomList.some(ele => {
    return ele.data.users.includes(userId);
  })
    ? handleRequestInitRooms(
        roomList.find(ele => ele.data.users.includes(userId)),
        userId,
        isEngineer
      ) //2回目以降のDMやりとり
    : history.push(`/${userType(isEngineer)}/message/create/${userId}`, params); //初のDMやりとり
  // e.stopPropagation();
};

const handleRequestInitRooms = (room, userId, isEngineer) => {
  // あらかじめルームを発火させて、message一覧ページへ遷移
  reduxStore.dispatch(
    requestInitRooms({roomId: room.id, toId: userId, title: room.data.title})
  );
  history.push(`/${userType(isEngineer)}/message`);
};

export const postTime = datetime => {
  // In: "2020-08-19T18:31:11.810005+09:00"
  const ms = Date.now() - Date.parse(datetime);
  let days = Math.floor(ms / (1000 * 60 * 60 * 24));

  if (days >= DEFAULT_SWITCH_DATE_TYPE_LIMIT) {
    return FormatDate(datetime, 'minute', 'str');
  } else {
    return (
      formatDistanceToNow(Date.parse(datetime), {
        locale: ja
      }).replace('未満', '') + '前'
    );
  }
};

// イメージのPatchの際に、イメージ自体に変更がなければPatchしない
export const handleRemoveImg = (values, isImgChanged) => {
  if (!isImgChanged) delete values.img;
  return values;
};
//金額、3桁ずつ , をうつ。
export const NumberWithDelimiter = number => {
  return String(number).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
};

//日時・時刻関係
export const currentTime = new Date();

//基準の日時からずらす関数
//dateは基準の日時、numはずらしたい日数、timeは過去に後ろにずらすか、未来にずらすか
export const changeDate = (date, num, time) => {
  if (time === 'future') {
    return new Date(date.setDate(date.getDate() + num));
  } else if (time === 'past') {
    return new Date(date.setDate(date.getDate() - num));
  } else {
  }
};

export const joinHyphen = baseArray => {
  if (baseArray === null || baseArray === undefined) return '';
  return baseArray.join('-');
};

export const splitHyphen = baseText => {
  if (baseText === null || baseText === undefined) return '';
  return baseText.split('-');
};
export const FIRESTORE_BATCH_LIMIT_NUMBER = 500;

export const isGcsUrl = img => {
  if (typeof img === 'string') {
    return img.includes('https://storage.googleapis.com');
  }
  return false;
};

export const selectCheapCouponId = coupons => {
  if (coupons !== null && coupons !== undefined && coupons.length > 0) {
    return coupons.sort((a, b) => a.ratio - b.ratio)[0].id;
  } else {
    return null;
  }
};

export const makeDefaultClosingDate = () => {
  const future = new Date();
  future.setDate(future.getDate() + 14);
  future.setMinutes(0);
  return future;
};

export const cleanFields = values => {
  const keys = Object.keys(values);
  keys.forEach(key => {
    if (
      values[key] === null ||
      values[key] === 'default' ||
      values[key] === undefined ||
      values[key] === []
    )
      delete values[key];
    if (Array.isArray(values[key]) && values[key].length === 0)
      delete values[key];
  });
  return values;
};

export const numberPerPage = {
  five: 5,
  nine: 9,
  ten: 10,
  fifteen: 15,
  twenty: 20
};

export const MAX_GET_DOCS_COUNT = 10;

export const getProjectId = projects => {
  if (projects.length === 1) {
    return projects[0].project_id;
  }
  return null;
};

export const formatErrorResponse = error => {
  let errResponse = {...error};
  if (errResponse.zip_code) {
    errResponse['zipCode1'] = errResponse.zip_code;
  }
  if (errResponse.tel) {
    errResponse['tel1'] = errResponse.tel;
  }
  return errResponse;
};

export const makeFormErrorFormat = error => {
  const formatedError = formatErrorResponse(error);
  return {
    ...formatedError,
    _error: formatedError.detail || formatedError.non_field_errors
  };
};

export const makeRoomId = (meId, msgToId) => {
  const users = [meId, msgToId];
  users.sort();
  return `${users[0]}?${users[1]}`;
};
