import React, {FC} from 'react';
import SubTitle from '../../../components/SubTitle';
import Attached from '../../../components/Attached';
import style from '../../../components/Task/TaskDetail/taskDetail.module.scss';
import StarRating from '../../../components/StarRating';
import Blank from '../../../components/Blank';
import FormatDate from '../../../components/FormatDate';
import AllowIndentionArea from '../../../components/AllowIndentionArea';
import Link from '@material-ui/core/Link';
import TagLabel from '../../../components/TagLabel';
import tag from '../../../reducers/tag';

type Props = {
  detail: any;
  isEngineer: boolean | undefined;
  isAnonymousUser: boolean;
  isMyCompanyTask: boolean;
};

const TaskDetail: FC<Props> = ({
  detail,
  isEngineer,
  isAnonymousUser,
  isMyCompanyTask
}) => {
  const makeCompanyLink = () => {
    if (isEngineer) {
      return `/en/co-info/${detail.owner.company}`;
    } else if (isEngineer === false) {
      return isMyCompanyTask
        ? `/co/info`
        : `/co/co-info/${detail.owner.company}`;
    }
  };

  const clientLink = makeCompanyLink();

  return (
    <div>
      <SubTitle headerText={'概要'} />
      <AllowIndentionArea>
        <p className={style.category}>{detail.description}</p>
      </AllowIndentionArea>
      <Blank height={'5rem'} width={null} />
      {detail.file_info.length > 0 ? (
        <>
          <SubTitle headerText={'添付ファイル'} />
          <Attached fileInfo={detail.file_info} />
        </>
      ) : null}
      <SubTitle headerText={'納品形式'} />
      <p className={style.category}>{detail.format_info.name}で受け取り</p>
      <Blank height={'5rem'} width={null} />
      <Tags tagsInfo={detail.tags_info} />
      {!isAnonymousUser && (
        <>
          <SubTitle headerText={'クライアント'} />
          <Blank height={'2rem'} width={null} />
          <Link href={clientLink} className={style.link}>
            <p className={style.corpName}>{detail.owner.company_name}</p>
          </Link>
          <div className={style.ratingContainer}>
            <p className={style.ratingTitle}>総合評価</p>
            <StarRating evalValue={detail.owner.company_summary_rating * 20} />
          </div>
        </>
      )}
      <Blank height={'5rem'} width={null} />
      <AdditionalTexts additional_texts={detail.additional_texts} />
    </div>
  );
};

type AdditionalTextsProps = {
  additional_texts: [];
};

const AdditionalTexts: FC<AdditionalTextsProps> = ({additional_texts}) => {
  if (additional_texts.length > 0) {
    return (
      <>
        <SubTitle headerText={'追記'} />
        {additional_texts.map(item => {
          return <RenderItem item={item} />;
        })}
      </>
    );
  }
  return <></>;
};

type TagsProps = {
  tagsInfo: {name: string}[];
};

const Tags: FC<TagsProps> = ({tagsInfo}) => {
  if (tagsInfo.length > 0) {
    return (
      <>
        <SubTitle headerText={'使用言語・開発環境'} />
        <div className={style.flexContainer}>
          {tagsInfo.map((tag, index) => (
            <div className={style.tagContainer} key={index}>
              <TagLabel tag={tag.name} />
            </div>
          ))}
        </div>
      </>
    );
  }
  return <></>;
};

type RenderItemProps = {
  item: any;
};

const RenderItem: FC<RenderItemProps> = ({item}) => {
  return (
    <AllowIndentionArea>
      <Blank height={'2rem'} width={null} />
      <p className={style.date}>{FormatDate(item.created_at, 'minute')}</p>
      <Blank height={'0.5rem'} width={null} />
      {item.text}
      <Blank height={'0.5rem'} width={null} />
      <Attached fileInfo={item.files_info} />
    </AllowIndentionArea>
  );
};

export default TaskDetail;
